import React, { useContext } from 'react';
import LuxonUtils from '@date-io/luxon';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SettingsContext } from '../contexts/SettingsContext';
import { isEqual, isUndefined } from 'lodash';
import { IconButton, makeStyles } from '@material-ui/core';
import { DateTime } from 'luxon';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  pickerStyling: {
    "& .MuiInputBase-input": {
      padding: 0,
      paddingTop: 10,
      paddingBottom: 10
    },
  }
}));

export default function DatePickerComponent(props) {

  const { disabled, handleDateChange, style, inputProps, clearTimesheetData, formatDate = 'dd LLL y' } = props;

  const settingsContext = useContext(SettingsContext);
  const classes = useStyles();

  const onHandleChange = (date) => {
    clearTimesheetData && clearTimesheetData();
    setTimeout(() => {
      settingsContext.onChangeTimeSheetDate(date);
      // setSelectedDate(date)
      if (!isUndefined(handleDateChange))
      handleDateChange(date);
    }, 100);
  };

  const onHandleArrows = (action) => {
    clearTimesheetData && clearTimesheetData();
    switch (action) {
      case 'prev':
        settingsContext.onChangeTimeSheetDate(DateTime.fromISO(settingsContext.timesheetDate).plus({ days: -1 }));
        return;
      case 'next':
        settingsContext.onChangeTimeSheetDate(DateTime.fromISO(settingsContext.timesheetDate).plus({ days: 1 }));
        return;
      default:
    }
  };

  return (
      <Grid alignItems="center" spacing={1} container>

        <Grid xs={'auto'} item>
          <IconButton
              style={{ '&:hover': { borderRadius: '50%', padding: '3px', backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
              disabled={disabled || settingsContext.fetchingData}
              onClick={(event) => {
                onHandleArrows('prev');
              }}
              size="small"
          >
            <NavigateBeforeIcon />
          </IconButton>
        </Grid>

        <Grid xs={true} item>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <DatePicker
                disabled={disabled}
                inputVariant="outlined"
                inputProps={inputProps}
                value={settingsContext.timesheetDate}
                onChange={onHandleChange}
                format={formatDate}
                autoOk
                showTodayButton
                className={classes.pickerStyling}
                style={style}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid xs={'auto'} item>
          <IconButton
              disabled={disabled || settingsContext.fetchingData || isEqual(settingsContext.onGetTimeSheetDateFormat('DD'), DateTime.now().toFormat('DD'))}
              onClick={(event) => {
                onHandleArrows('next');
              }}
              size="small">
            <NavigateNextIcon />
          </IconButton>
        </Grid>
      </Grid>
  );

}
