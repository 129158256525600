import React, { useContext, useState } from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { axiosConfig } from '../../../../../helpers';
import IconButton from '@material-ui/core/IconButton';
import { ProjectContext } from '../../ProjectMetrics';

const DialogRemoveItem = ({ id, onRemove }) => {
  const { config: { project_workSummary_endpoint: endpoint } } = useContext(ProjectContext);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRemove = async () => {
    setLoading(true);
    const uri = `${endpoint}/${id}`;

    return axios.delete(uri, axiosConfig())
        .then(() => {
          setOpen(false);
          onRemove && onRemove(id);
        })
        .finally(() => setLoading(false));
  };

  return (
      <>
        <IconButton className="text-danger" component={'span'} onClick={handleOpen}>
          <DeleteIcon />
        </IconButton>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Delete item</DialogTitle>
          {loading && <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CircularProgress />
          </DialogContent>}
          {!loading && <>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this item?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button color={'secondary'} onClick={handleRemove}>Yes, Delete</Button>
            </DialogActions>
          </>}
        </Dialog>
      </>
  );
};

export default DialogRemoveItem;
