import React, { useLayoutEffect } from 'react'
import { ProgressBar } from "react-bootstrap";

export default function TableMetrics({ rows }) {

  useLayoutEffect(() => {
    window.dispatchEvent(new Event('react-finished', {}))
    return () => { };
  }, [])

  const formatNumber = (n) => {
    if (typeof (n) == 'number') {
      return n.toLocaleString("en-US")
    }
    return ""
  }
  
  const formatPercent = (n) => {
    if (typeof (n) == 'number') {
      return Math.round(n, 0).toLocaleString("en-US")
    }
    return ""
  }

  const decimalRound = (n, d) => {
    if (typeof (n) == 'number') {
      return parseFloat(n.toFixed(d))
    } else return n
  }

  const renderMetrics = row => {

    if (row.metric == null || row.metric == "") {
      return (
        <>
          <td className="left-bordered"></td>
          <td className=""></td>
          <td className=""></td>
          <td className=""></td>
        </>
      )
    }

    let budgetRational = 0
    let actualRational = 0
    let variance = 0
    if (row.quantity) {
      actualRational = row.actual / row.quantity
      actualRational = decimalRound(actualRational, 1)
      budgetRational = row.budget / row.quantity
      budgetRational = decimalRound(budgetRational, 1)

      variance = budgetRational - actualRational
    }

    return (
      <>
        <td className="left-bordered">
          <strong>
            {row.quantity}
            {' hours/'}
            {row.metric}
          </strong>
        </td>
        <td className="">
          <strong>
            {budgetRational}
            {' hours/'}
            {row.metric}
          </strong>
        </td>
        <td className="">
          <strong>
            {actualRational}
            {' hours/'}
            {row.metric}
          </strong>
        </td>
        <td className="">
          <strong>
            {variance}
          </strong>
        </td>
      </>
    )
  }

  const renderHeaders = () => {
    return (
      <>
        <th>Cost Code</th>
        <th>Cost Code Name</th>
        <th>Group</th>
        <th>Budget</th>
        <th>Actual</th>
        <th>Variance</th>
        <th>Workers</th>
        <th className="left-bordered">Unit of Measure</th>
        <th className="">Budget Production Rate</th>
        <th className="">Actual Production Rate</th>
        <th className="">Production Variance</th>
        <th className="left-bordered progress-header">Progress</th>
        <th>Percent</th>
      </>
    );
  }

  const renderBody = () => {
    const rowsValue = Object.values(rows);
    return rowsValue.map(row => {
      let statusClass = ''
      if (row.actual >= row.budget) {
        statusClass = "danger"
      } else {
        statusClass = "success"
      }
      const percent = row.actual * 100 / row.budget;
      return (
        <tr key={row.id} className={statusClass}>
          <td>
            <strong>
              {row.code}
            </strong>
          </td>
          <td>
            <strong>
              {row.name}
            </strong>
          </td>
          <td>
            <strong>
              {row.group}
            </strong>
          </td>
          <td>
            <strong>
              {formatNumber(decimalRound(row.budget, 1))}
            </strong>
          </td>
          <td>
            <strong>
              {formatNumber(decimalRound(row.actual, 1))}
            </strong>
          </td>
          <td>
            <strong>
              {formatNumber(decimalRound(row.variance, 1))}
            </strong>
          </td>
          <td>
            <strong>
              {formatNumber(row.workers)}
            </strong>
          </td>
          {renderMetrics(row)}
          <td className="left-bordered progress-td">
            <ProgressBar striped animated variant={statusClass} now={percent}></ProgressBar>
          </td>
          <td>
            <strong>
              {formatPercent(percent)} %
            </strong>
          </td>
        </tr>
      )
    })
  }

  return (
    <div className="table-responsive">
      <table className="table" id="budgets-table">
        <thead>
          <tr>
            {renderHeaders()}
          </tr>
        </thead>
        <tbody>
          {renderBody()}
        </tbody>
      </table>
    </div>
  )
}
