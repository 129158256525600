import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Countries from 'i18n-iso-countries';
import { from, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { fromFetch } from 'rxjs/fetch';
import axios from "axios";

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Close from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { makeStyles, ThemeProvider } from "@material-ui/core/styles";

import { axiosConfig, myTheme } from '../helpers';
import { notificationCenter } from '../helpers/notifications';
import { SBSwitch } from '../reusableComponents/index'
import clsx from 'clsx';

import { GoogleAutoComplete, GoogleMap } from './components/index';

Countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
const countriesObjectToPairs = _.toPairs(Countries.getNames("en", { select: "official" }));
const countriesArray = countriesObjectToPairs.map(onePair => ({ value: onePair[0], label: onePair[1] }));

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    height: 600,
  },
  dialogTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  titleStyles: {
    fontWeight: 600,
    fontSize: '1.5rem'
  },
  googleMapsStyles: {
    transition: 'height 0.5s ease-out',
    width: 580,
    height: 580,
    border: '1px solid #cecece',
    borderRadius: 4,
  },
  dialogActionsWrapper: {
    justifyContent: 'space-between',
    padding: 12
  },
  stepperStyles: {
    padding: 0
  },
  coordinatesPopoverContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent:'space-between',
    alignItems: 'center',
    padding: 16
  },
  coordinatesPopoverPaper: {
    position: 'relative',
    backgroundColor: '#fff',
    borderRadius: 4,
    width: 150,
    overflowX: 'unset',
    overflowY: 'unset',
    "&::before": {
      content: '""',
      position: 'absolute',
      top: -8,
      left: 'calc(50% - 8px)',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 8px 8px 8px',
      borderColor: 'transparent transparent #fff transparent',
    }
  },
  autoFilledSeparator: {
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      color: '#9e9e9e',
      fontSize: 10,
      justifyContent: 'center',
      "&::after": {
        marginLeft: 8,
        content: '""',
        display: 'inline-block',
        verticalAlign: 'middle',
        width: '39%',
        borderTop: '1px solid #c8c8c8'
      },
      "&::before": {
        marginRight: 8,
        content: '""',
        display: 'inline-block',
        verticalAlign: 'middle',
        width: '39%',
        borderTop: '1px solid #c8c8c8'
      },
    },
    projectInfoWrapper: {
      borderRadius: 4,
      border: '1px solid #cecece',
      backgroundColor: 'white',
      marginTop: 0,
      height: 137,
    },
    selectedContractorsWrapper: {
      borderRadius: 4,
      border: '1px solid #cecece',
      backgroundColor: 'white',
      marginTop: 16,
    },
    nearbyProjectsWrapper: {
      borderRadius: 4,
      border: '1px solid #cecece',
      backgroundColor: 'white',
      marginTop: 16,
      maxWidth: 470,
      width: '100%',
      maxHeight: 434,
    },
    projectSettingsAndTimeRulesWrapper: {
      borderRadius: 4,
      border: '1px solid #cecece',
      backgroundColor: 'white',
      width: '100%',
      maxHeight: 439,
    },
    projectNameStyles: {
      borderBottom: '1px solid #cecece',
      padding: 8,
      fontWeight: 600,
      color: 'white',
      backgroundColor: '#f15922',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      lineHeight: 1.5,
      textTransform: 'uppercase',
      letterSpacing: 1
    },
    cardTitleStyles: {
      borderBottom: '1px solid #cecece',
      padding: 8,
      fontWeight: 700,
      color: '#969b9f',
      backgroundColor: 'snow',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: 4,
      paddingTop: 4
    },
    settingsTimeRulesTitleStyles: {
      borderBottom: '2px solid #cecece',
      fontWeight: 700,
      color: '#969b9f',
      backgroundColor: 'snow',
      borderRadius: '4px 4px 0px 0px',
      display: 'flex',
      alignItems: 'center',
      paddingTop: 4
    },
    contractorTitleStyles: {
      borderBottom: '2px solid #cecece',
      fontWeight: 700,
      color: '#969b9f',
      backgroundColor: 'snow',
      display: 'flex',
      alignItems: 'center',
      paddingBottom: 4,
      paddingTop: 4
    },
    tab: {
      borderRadius: '4px 4px 0px 0px',
      border: '2px solid #cecece',
      borderBottom: 0,
      padding: 4,
      marginTop: 5,
      paddingLeft: 8,
      paddingRight: 8,
      marginBottom: -3,
      marginRight: 2,
      paddingBottom: 3
    },
    tabSelected: {
      backgroundColor: 'white',
      color: 'black',
    },
    projectInfoColumnsWrapper: {
      fontWeight: 600,
      padding: 8,
      lineHeight: 1.2
    },
    nearbyProjectsColumnsWrapper: {
      fontWeight: 600,
      overflow: 'scroll'
    },
    projectInfoData: {
      color: '#6f6f6f',
      maxHeight: 60,
      overflow: 'scroll'
    },
    projectInfoTitle: {
      color: '#f15922',
      fontWeight: 600,
      width: 98
    },
    projectInfoContent: {
      fontWeight: 600,
      overflowWrap: 'break-word',
      width: 355
    },
    nearbyProjectsListWrapper: {
      padding: 0,
      overflowY: 'scroll',
    },
    noSettingsWrapper: {
      textAlign: 'center',
      padding: 8,
      color: 'slategray'
    },
    nearbyProjectListItem: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 8,
      borderBottom: '1px solid #cecece'
    },
    noNearbyProjectsMessage: {
      fontWeight: 600,
      color: '#969b9f',
      margin: 'auto'
    },
    nearbyLoaderWrapper: {
      width: '100%',
      padding: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    noNearbyWrapper: {
      textAlign: 'center',
      padding: 16,
      color: 'slategray'
    },
    timeRuleMenuItemRoot: {
      borderBottom: '1px solid #cecece'
    },
    timeRuleMenuItemGutter: {
      paddingLeft: 8,
      paddingRight: 8,
    },
    noNearbySelected: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 90,
      fontWeight: 600,
      fontSize: '1.4rem',
      color: 'slategray'
    },
    nearbyProjectsList: {
      width: '90%',
      margin: '2px 0 0',
      padding: 0,
      position: 'absolute',
      listStyle: 'none',
      backgroundColor: '#fff',
      overflow: 'auto',
      maxHeight: 250,
      borderRadius: 4,
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      zIndex: 999999,

      '& li': {
        padding: '5px 12px',
        display: 'flex',
      },

      '& li[data-focus="true"]': {
        backgroundColor: 'rgba(241, 89, 34, 0.1)',
        cursor: 'pointer',
      },
    },
    radiusSelect: {
      width: 90
    },
    accordionRoot: {
      width: '100%',
      border: 'none'
    },
    radioGroup: {
      display: 'flex',
      flexDirection: 'row'
    },
    textField: {
      marginRight: '10px',
      width: '100px'
    },
    mainContainer: {
      display: 'flex',
      alignItems: 'end'
    },
    punchType: {
      width: '100px'
    },
    itemsList: {
      padding: 0,
      width: '100%',
      minHeight: 150,
      maxHeight: 150,
      border: "1px solid #9c9c9c2e",
      overflow: 'scroll',
      backgroundColor: theme.palette.background.paper,
    },
    servicesHeaderStyle: {
      display: 'flex',
      width: '100%'
    },
    listItemStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%'
    },
}));

const getSteps = () => {
  return(
    ["Name & Address", "Nearby Projects & Settings", "Contractors", "Overview"]
  )
}

const ProjectByUserWizard = (props) => {

  const { project, handleClose, availableStates, availableStatesCanada, googleTimezoneEndpoint, googleTimezoneApiKey, projectEndpoint, companiesEndpoint, companyId } = props;
  const classes = useStyles();

  const statesArray = availableStates.map(oneState => ({ value: oneState[1], label: oneState[0] }))
  const canadaStatesArray = availableStatesCanada.map(oneState => ({ value: oneState[1], label: oneState[0] }))

  const [projectName, setProjectName] = useState('');
  const [projectNameError, setProjectNameError] = useState(null);

  const [address, setAddress] = useState({
    formatted_address: '',
    postal_code: '',
    streetAddress: '',
    locality: '',
    administrative_area_level_1: '',
    country: null,
    latLng: {},
  })

  const [timezone, setTimezone] = useState({
    iana: '',
    offset: 0
  })

  const [radius, setRadius] = useState(10);

  const [step, setStep] = useState(0);
  const [open, setOpen] = useState(true);
  const [loadingNearbyProjects, setLoadingNearbyProjects] = useState(false);
  const [loadingContractors, setLoadingContractors] = useState(false);
  const [nearbyProjects, setNearbyProjects] = useState([]);
  const [selectedNearbyProject, setSelectedNearbyProject] = useState(null);
  const [contractors, setContractors] = useState([]);
  const [creatingNewProject, setCreatingNewProject] = useState(false);
  const [settingsTimeRules, setSettingsTimeRules] = useState('settings');

  const countryToFlag = (isoCode) => {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  }

  const convertMilitaryToRegularTime = (militaryTime) => {
    //Note: some time rules in the DB have broken data - will return 00:00 if thats the case.
    if (!militaryTime) return '00:00'

    let militaryTimeString = militaryTime.toString().padStart(4, "0");

    // Extract the hours and minutes from the military time
    var hours = parseInt(militaryTimeString.substr(0, 2), 10);
    var minutes = parseInt(militaryTimeString.substr(2, 4), 10);

    // Format the time as a string with leading zeros
    var regularTime = hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0");

    return regularTime;
  }

  const getTimezone = window.getTimezone = (location) => {
    let timestampInSeconds = Math.floor(Date.now() / 1000);
    let timezoneURL = `${googleTimezoneEndpoint}?location=${location.lat},${location.lng}&timestamp=${timestampInSeconds}&key=${googleTimezoneApiKey}`

    fromFetch(`${timezoneURL}`)
      .pipe(
        switchMap(response => {
            if (!response.ok) {
                notificationCenter('error', "No timezone detected. Please choose another address.");
                return of({ error: true, message: `Error ${response.status}` });
            }

            return response.json();
        }),
        catchError(err => {
          notificationCenter('error', "No timezone detected. Please choose another address.")
          return of({ error: true, message: err.message });
        })
      )
      .subscribe(result => {
        if (!result.timeZoneId) notificationCenter('error', "No timezone detected. Please choose another address.");

        setTimezone({
          iana: result.timeZoneId || '',
          offset: result.rawOffset / 60 / 60 || 0
        })
      });
  }

  const handleRadiusChange = (event) => {
    setRadius(event.target.value);
  }

  const checkForNearbyProject = () => {
    setLoadingNearbyProjects(true);
    setSelectedNearbyProject(null);

    let apiPath = `${projectEndpoint}/getNearbyProjects/${_.get(address, 'latLng.lat')}/${_.get(address, 'latLng.lng')}/${radius}?name=${encodeURIComponent(projectName)}&uniqueName=true`

    axios.get(apiPath, axiosConfig()).then(response => {
      setNearbyProjects(_.get(response, 'data'));
      setLoadingNearbyProjects(false);

    }).catch(error => {
      if (_.get(error, 'response.status') === 409) {
        setProjectNameError({ reason: 'Name already in use'})
      } else {
        notificationCenter('error', _.get(error, 'response.data', 'Error checking for nearby projects.'));
      }

      setStep(prevStep => prevStep-1);
      window.allowMapClick = true;
      setLoadingNearbyProjects(false);
    })
  }

  const getContractors = () => {
    let apiPath = `${companiesEndpoint}/getManagedSubcontractors/${companyId}`
    setLoadingContractors(true);

    axios.get(apiPath, axiosConfig()).then(response => {
      setContractors(_.get(response, 'data', []).map(oneContractor => ({ ...oneContractor, isSelected: project && _.get(project, 'contractors', []).map(projectContractor => _.get(projectContractor, 'company_id')).includes(_.get(oneContractor, 'company._id')) })))
      setLoadingContractors(false);
    }).catch(error => {
      setLoadingContractors(false);
      console.log('error loading contractors: ', error);
      notificationCenter('error', _.get(error, 'response.data', 'Could not load managed subcontractors.'));
    })

  }

  const getFormattedTimezone = () => {
    if (timezone.iana !== '') return `${timezone.iana} (UTC${timezone.offset >= 0 ? '+' + timezone.offset : timezone.offset})`
    return ''
  }

  const closeDialog = () => {
    setOpen(false);
    handleClose();
  }

  const closeDialogAndRefresh = () => {
    setOpen(false);
    handleClose(null, true);
  }

  const renderStepContent = () => {
    switch (step) {
      case 0:
        return renderStep0()
        break;
      case 1:
        return renderStep1()
        break;
      case 2:
        return renderStep2()
        break;
      case 3:
        return renderStep3()
        break;
      default:

    }
  }

  const handleCountrySelection = (event, value) => {
    setAddress({ ...address, country: value });
  }

  const handleStateSelection = (event, value) => {
    setAddress({ ...address, administrative_area_level_1: value });
  }

  const handleChange = (ev) => {
    address[ev.target.name] = ev.target.value;
    setAddress({ ...address });
  }

  const handleBack = () => {
    setStep(prevStep => {
      if (step === 1) {
        window.allowMapClick = true
        setNearbyProjects([])
        setSelectedNearbyProject(null)
      }

      if (step === 2) {
        if (project) {
            window.allowMapClick = true;
            return prevStep-2
        }
      }

      return prevStep-1
    });
  }

  const handleNext = () => {
    window.allowMapClick = false;

    switch (step) {
        case 0:
          if (!project) {
            setStep((prevStep) => prevStep + 1);
            checkForNearbyProject();
          }

          if (project) setStep((prevStep) => prevStep + 2);
          break;
        case 1:
        case 2:
          setStep((prevStep) => prevStep + 1);
          break;
        case 3:
          if (!project) handleCreate();

          handleUpdate();
          break;
        default:
          break;
      }
  }

  const handleCreate = () => {
    const endpoint = `${projectEndpoint}/createByUser`

    let payload = {
      name: projectName,
      address: {
        fullAddress: _.get(address, 'formatted_address'),
        address: _.get(address, 'streetAddress'),
        city: _.get(address, 'locality'),
        state: ['US', 'CA'].includes(_.get(address, 'country.value', '')) ? _.get(address, 'administrative_area_level_1.value') : _.get(address, 'administrative_area_level_1.label'),
        zip: _.get(address, 'postal_code'),
        addressLineTwo: _.get(address, 'addressLineTwo', ''),
        country: _.get(address, 'country.value'),
      },
      latLng: {
        lat: _.get(address, 'latLng.lat'),
        lng: _.get(address, 'latLng.lng'),
      },
      timezone: _.get(timezone, 'iana'),
    }

    let imports = {
      project_id: _.get(selectedNearbyProject, '_id'),
    }

    // import settings

    let settings = []

    if (_.get(selectedNearbyProject, 'selectedSettings.workerSmsServices')) {
      settings.push('smsFlows')
    }
    if (_.get(selectedNearbyProject, 'selectedSettings.workShiftCustomization')) {
      settings.push('maxShiftLength')
    }
    if (_.get(selectedNearbyProject, 'selectedSettings.projectCustomDisplay')) {
      settings.push('projectCustomDisplay')
    }
    if (_.get(selectedNearbyProject, 'selectedSettings.mobileAppSettings')) {
      settings.push('mobileAppSettings')
    }
    if (_.get(selectedNearbyProject, 'selectedSettings.roundupRule')) {
      settings.push('round_up_rule')
    }

    if (!_.isEmpty(settings)) {
      imports.settings = settings
    }

    // import timerules

    let timeRules = []

    if (_.get(selectedNearbyProject, 'selectedSettings.roundupRules')) {
      timeRules = timeRules.concat(_.get(selectedNearbyProject, 'timeRules', []).filter(oneRule => _.get(oneRule, 'modifies') === 'punch').map(oneRule => _.get(oneRule, '_id')))
    }
    if (_.get(selectedNearbyProject, 'selectedSettings.breakTimeRules')) {
      timeRules = timeRules.concat(_.get(selectedNearbyProject, 'timeRules', []).filter(oneRule => _.get(oneRule, 'modifies') === 'shift').map(oneRule => _.get(oneRule, '_id')))
    }

    if (!_.isEmpty(timeRules)) {
      imports.timeRules = timeRules
    }

    if (_.size(Object.keys(imports)) > 1) {
      payload.import = imports
    }

    // add subcontractors

    let selectedContractors = contractors.filter(oneContractor => oneContractor.isSelected)

    if (!_.isEmpty(selectedContractors)) {
      selectedContractors = selectedContractors.map(oneContractor => _.get(oneContractor, 'company._id'))
      payload.contractors = selectedContractors
    }

    setCreatingNewProject(true);

    axios.post(endpoint, payload, axiosConfig()).then(response => {
      notificationCenter('success', "Project created successfully.")
      closeDialogAndRefresh()
    }).catch(error => {
      notificationCenter('error', "Error creating project. Please try again.")
      setCreatingNewProject(false);
    })
  }

  const handleUpdate = () => {
    const endpoint = `${projectEndpoint}/updateByUser/${_.get(project, '_id')}`

    let payload = {
      name: projectName,
      address: {
        fullAddress: _.get(address, 'formatted_address'),
        address: _.get(address, 'streetAddress'),
        city: _.get(address, 'locality'),
        state: ['US', 'CA'].includes(_.get(address, 'country.value', '')) ? _.get(address, 'administrative_area_level_1.value') : _.get(address, 'administrative_area_level_1.label'),
        zip: _.get(address, 'postal_code'),
        addressLineTwo: _.get(address, 'addressLineTwo', ''),
        country: _.get(address, 'country.value'),
      },
      latLng: {
        lat: _.get(address, 'latLng.lat'),
        lng: _.get(address, 'latLng.lng'),
      },
      timezone: _.get(timezone, 'iana'),
    }

    // add subcontractors
    // let selectedContractors = contractors.filter(oneContractor => oneContractor.isSelected)

    // if (!_.isEmpty(selectedContractors)) {
    //   selectedContractors = selectedContractors.map(oneContractor => _.get(oneContractor, 'company._id'))
    //   payload.contractors = selectedContractors
    // }

    setCreatingNewProject(true);

    axios.patch(endpoint, payload, axiosConfig()).then(response => {
      notificationCenter('success', "Project updated successfully.")
      closeDialogAndRefresh()
    }).catch(error => {
      notificationCenter('error', "Error updating project. Please try again.")
      setCreatingNewProject(false);
    })
  }

  const handleSelectSetting = (setting) => {
    setSelectedNearbyProject(prevSelectedProject => ({
      ...prevSelectedProject,
      selectedSettings: {
        ..._.get(prevSelectedProject, 'selectedSettings'),
        [setting]: !_.get(prevSelectedProject, `selectedSettings[${setting}]`)
      }
    }))
  }

  const handleSelectContractor = (id) => {
    setContractors(prevContractors => prevContractors.map(oneContractor => {
      if (_.get(oneContractor, '_id') === id) {
        oneContractor.isSelected = !oneContractor.isSelected
      }
      return oneContractor
    }))
  }

  const handleSelectAll = () => {
    if (step === 1) {
      // NOTE: if settingsTimeRules is settings -> select all settings / other select all timerules
      if (settingsTimeRules === 'timeRules') {
        setSelectedNearbyProject(prevSelectedProject => ({
          ...prevSelectedProject,
          selectedSettings: {
            ..._.get(prevSelectedProject, 'selectedSettings'),
            roundupRules: _.get(prevSelectedProject, 'selectedSettings.roundupRules') && _.get(prevSelectedProject, 'selectedSettings.breakTimeRules') ? false : true,
            breakTimeRules: _.get(prevSelectedProject, 'selectedSettings.roundupRules') && _.get(prevSelectedProject, 'selectedSettings.breakTimeRules') ? false : true,
          }
        }))
      } else if (settingsTimeRules === 'settings') {
        let check =  _.get(selectedNearbyProject, 'selectedSettings.workerSmsServices') && _.get(selectedNearbyProject, 'selectedSettings.workShiftCustomization') && _.get(selectedNearbyProject, 'selectedSettings.projectCustomDisplay') && _.get(selectedNearbyProject, 'selectedSettings.mobileAppSettings') && _.get(selectedNearbyProject, 'selectedSettings.roundupRule')

        setSelectedNearbyProject(prevSelectedProject => ({
          ...prevSelectedProject,
          selectedSettings: {
            ..._.get(prevSelectedProject, 'selectedSettings'),
            workerSmsServices: check ? false : true,
            workShiftCustomization: check ? false : true,
            projectCustomDisplay: check ? false : true,
            mobileAppSettings: check ? false : true,
            roundupRule: check ? false : true,
          }
        }))
      }
    }

    if (step === 2) {
      let selectedContractors = contractors.filter(oneContractor => oneContractor.isSelected)

      let setSelected = _.isEqual(_.size(selectedContractors), _.size(contractors)) ? false : true

      setContractors(prevContractors => prevContractors.map(oneContractor => { oneContractor.isSelected = setSelected; return oneContractor }))
    }
  }

  const checkAllSelected = () => {
    if (step === 1) {
      if (settingsTimeRules === 'timeRules') {
        return _.get(selectedNearbyProject, 'selectedSettings.roundupRules') && _.get(selectedNearbyProject, 'selectedSettings.breakTimeRules')
      } else if (settingsTimeRules === 'settings') {
        return _.get(selectedNearbyProject, 'selectedSettings.workerSmsServices') && _.get(selectedNearbyProject, 'selectedSettings.workShiftCustomization') && _.get(selectedNearbyProject, 'selectedSettings.projectCustomDisplay') && _.get(selectedNearbyProject, 'selectedSettings.mobileAppSettings') && _.get(selectedNearbyProject, 'selectedSettings.roundupRule')
      }
    }

    if (step === 2) {
      let selectedContractors = contractors.filter(oneContractor => oneContractor.isSelected)
      return  _.isEqual(_.size(selectedContractors), _.size(contractors))
    }
  }

  const checkIndeterminate = () => {
    if (step === 1) {
      if (settingsTimeRules === 'timeRules') {
        let countSelected = _.get(selectedNearbyProject, 'selectedSettings.roundupRules') ? 1 : 0
        _.get(selectedNearbyProject, 'selectedSettings.breakTimeRules') && countSelected++

        return countSelected > 0 && countSelected < 2
      } else if (settingsTimeRules === 'settings') {
        let countSelected = _.get(selectedNearbyProject, 'selectedSettings.workerSmsServices') ? 1 : 0

        _.get(selectedNearbyProject, 'selectedSettings.workShiftCustomization') && countSelected++
        _.get(selectedNearbyProject, 'selectedSettings.projectCustomDisplay') && countSelected++
        _.get(selectedNearbyProject, 'selectedSettings.mobileAppSettings') && countSelected++
        _.get(selectedNearbyProject, 'selectedSettings.roundupRule') && countSelected++

        return countSelected > 0 && countSelected < 5
      }
    }

    if (step === 2) {
      let selectedContractors = contractors.filter(oneContractors => oneContractors.isSelected)
      return  _.size(selectedContractors) < _.size(contractors) && _.size(selectedContractors) > 0
    }
  }

  const disableNext = () => {
    return _.isEmpty(projectName.trim())
      || _.values(address).filter(addressItem => _.isEmpty(addressItem) || _.isNull(addressItem)).length > 0
      || _.isEmpty(_.get(timezone, 'iana'))
      || loadingNearbyProjects
      || creatingNewProject
  }

  const renderGoogleMap = () => {
    return(
      <GoogleMap project={project} compactMap={step !== 0} setAddress={setAddress} classes={classes} nearbyProjects={nearbyProjects} />
    )
  }

  const renderStep0 = () => {
    return(
      <Grid container spacing={2} wrap='nowrap'>
        <Grid direction='column' item container justifyContent='space-between' style={{ minWidth: 450, maxWidth: 450 }}>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                name="projectName"
                id="projectName"
                required
                label="Project Name"
                color="primary"
                value={projectName}
                onChange={handleNameChange}
                inputProps={{
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                error={!!projectNameError}
                helperText={projectNameError ? _.get(projectNameError, 'reason') : ''}
              />
            </FormControl>
            <GoogleAutoComplete address={address} setAddress={setAddress} setTimezone={setTimezone} classes={classes} setNearbyProjects={setNearbyProjects} />
            <FormControl fullWidth>
              <TextField
                id="addressLineTwo"
                name="addressLineTwo"
                label="Address Line 2"
                value={address.addressLineTwo}
                color="primary"
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <div>
            <div className={classes.autoFilledSeparator}>
              Autofilled Section
            </div>
            <Grid container>
              <Grid item container padding={1}>
                <FormControl fullWidth>
                  <TextField
                    id="streetAddress"
                    name="streetAddress"
                    label="Street Address"
                    value={address.streetAddress}
                    disabled={true}
                    />
                </FormControl>
              </Grid>
              <Grid item container wrap='nowrap' spacing={1}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <TextField
                      id="postal_code"
                      name="postal_code"
                      label="Zip/Postal Code"
                      value={address.postal_code}
                      disabled={true}
                      />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <TextField
                      id="locality"
                      name="locality"
                      label="City"
                      value={address.locality}
                      disabled={true}
                      />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    {
                      ['US', 'CA'].includes(_.get(address, 'country.value', '')) ?
                      <Autocomplete
                        id="projectState"
                        options={_.get(address, 'country.value', '') === 'US' ? statesArray : canadaStatesArray}
                        value={address.administrative_area_level_1}
                        onChange={handleStateSelection}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="State"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                        disabled={!_.isEmpty(_.get(address, 'formatted_address')) && _.has(_.get(address, 'latLng', {}), 'lat') && _.get(address.country, 'value', '') === 'US' && _.isEmpty(_.get(address, 'administrative_area_level_1')) ? false : true}
                      /> :
                      <TextField
                        id="administrative_area_level_1"
                        name="administrative_area_level_1"
                        label="State/Province"
                        value={_.get(address, 'administrative_area_level_1.label', '')}
                        disabled={true}
                      />
                  }
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item container wrap='nowrap' spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      id="projectCountry"
                      fullWidth
                      options={countriesArray}
                      value={address.country}
                      onChange={handleCountrySelection}
                      classes={{
                        option: classes.option,
                      }}
                      autoHighlight
                      getOptionLabel={(option) => option.label}
                      getOptionSelected={(option, value) => _.get(option, 'value') === _.get(value, 'value')}
                      renderOption={(option) => (
                        <React.Fragment>
                          <span style={{ marginRight: 8 }}>{countryToFlag(option.value)}</span>
                          {option.label} ({option.value})
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                          />
                      )}
                      disabled={!_.isEmpty(_.get(address, 'formatted_address')) && _.has(_.get(address, 'latLng', {}), 'lat') && _.isEmpty(_.get(address, 'country')) ? false : true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} style={{ minWidth: 240 }}>
                  <FormControl fullWidth>
                    <TextField
                      id="timezone"
                      name="timezone"
                      label="Timezone"
                      value={getFormattedTimezone()}
                      color="primary"
                      disabled
                      fullWidth
                      required
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid direction='column' item container style={{ minWidth: 600, maxWidth: 600 }}>
          <Grid item>
            <FormControl fullWidth>
              {renderGoogleMap()}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderProjectInfo = () => {
    return(
      <Box display='flex' flexDirection='column' className={classes.projectInfoWrapper} style={{ borderColor: '#f15922' }}>
        <Box className={classes.projectNameStyles}>{projectName}</Box>
        <Box display='flex' flexDirection='column' className={classes.projectInfoColumnsWrapper}>
          {/*<Grid item container style={{ marginTop: 8 }}>
            <Grid item xs={3}>
              Name
            </Grid>
            <Grid id='nameElement'  item xs={9} className={classes.projectInfoData}>
              {projectName}
            </Grid>
          </Grid>*/}
          <Grid item container>
            <Grid item xs={3}>
              Address
            </Grid>
            <Grid id='formattedAddressElement' item xs={9} className={classes.projectInfoData}>
              {_.get(address, 'formatted_address')}
            </Grid>
          </Grid>
          <Grid item container style={{ marginTop: 8 }}>
            <Grid item xs={3}>
              Coordinates
            </Grid>
            <Grid item xs={9} className={classes.projectInfoData}>
              {_.get(address, 'latLng.lat')} , {_.get(address, 'latLng.lng')}
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  }

  const renderSelectedContractors = () => {
    let selectedContractors = contractors.filter(oneContractor => oneContractor.isSelected)

    if (_.isEmpty(selectedContractors)) {
      return (
        <div className={classes.noNearbySelected}>No contractors were selected for this project</div>
      )
    }

    return (
        <Box display='flex' flexDirection='column' className={classes.selectedContractorsWrapper}>
          <Box className={classes.cardTitleStyles}>Selected Contractors</Box>
          <Box display='flex' flexDirection='column'>
            <List className={classes.nearbyProjectsListWrapper} style={{ maxHeight: 390 }}>
              { selectedContractors.map(oneContractor =>
                  <MenuItem classes={{ root: classes.timeRuleMenuItemRoot, gutters: classes.timeRuleMenuItemGutter }} key={_.get(oneContractor, '_id')}>
                    { _.get(oneContractor, 'company.name') }
                  </MenuItem>
                )
              }
            </List>
          </Box>
        </Box>
      )
  }

  const renderSelectedSettings = () => {
    let render = false

    _.forEach(_.get(selectedNearbyProject, 'selectedSettings', {}), (value, key) => {
      if (value) {
        render = true
        return
      }
    });

    if (!render) {
        return(
            <div className={classes.noNearbySelected}>No settings were selected to be imported</div>
        )
    }

    return (
        <Box display='flex' flexDirection='column' className={classes.selectedContractorsWrapper}>
          <Box className={classes.cardTitleStyles}>Selected Settings</Box>
          <Box display='flex' flexDirection='column'>
            <List className={classes.nearbyProjectsListWrapper} style={{ maxHeight: 390 }}>
              {_.get(selectedNearbyProject, 'selectedSettings.workerSmsServices') && renderWorkerSmsServices()}
              {_.get(selectedNearbyProject, 'selectedSettings.workShiftCustomization') && renderWorkShiftCustomization()}
              {_.get(selectedNearbyProject, 'selectedSettings.projectCustomDisplay') && renderProjectCustomDisplay()}
              {_.get(selectedNearbyProject, 'selectedSettings.mobileAppSettings') && renderProjectMobileAppSettings()}
              {_.get(selectedNearbyProject, 'selectedSettings.roundupRule') && renderRoundUpRule()}
              {_.get(selectedNearbyProject, 'selectedSettings.roundupRules') && renderProjectRoundUpRules()}
              {_.get(selectedNearbyProject, 'selectedSettings.breakTimeRules') && renderProjectBreakTimeRules()}
            </List>
          </Box>
        </Box>
      )
  }

  const renderNearbyProjectList = () => {
    return(
      <Box display='flex' flexDirection='column' className={classes.nearbyProjectsWrapper}>
        <Box className={classes.cardTitleStyles}>
          Nearby Projects
          { renderRadiusSelect() }
        </Box>
        <Box display='flex' flexDirection='column' className={classes.nearbyProjectsColumnsWrapper}>
            { loadingNearbyProjects && <div className={classes.nearbyLoaderWrapper}><CircularProgress color='primary' size={24} /></div> }
            { !loadingNearbyProjects &&
              <List className={classes.nearbyProjectsListWrapper}>
                { nearbyProjects.map((oneNearbyProject, index) =>
                    <MenuItem key={_.get(selectedNearbyProject, '_id')+'_'+index} selected={_.get(selectedNearbyProject, '_id') === _.get(oneNearbyProject, '_id')} className={classes.nearbyProjectListItem} onClick={(e) => { setSelectedNearbyProject(oneNearbyProject) }}>
                      <Typography variant='subtitle2'>{_.get(oneNearbyProject, 'name')}</Typography>
                      <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <DirectionsCarIcon style={{ color: 'grey', marginRight: 8 }} />
                          <Typography variant='caption' style={{ color: 'grey', marginRight: 8 }}>{Math.round(_.get( oneNearbyProject, 'Distance'))} mi.</Typography>
                        </div>
                        <Link color="inherit" href={`/projects/${_.get(oneNearbyProject, '_id')}`} target="_blank">
                          <IconButton size='small' color='primary'>
                            <Tooltip arrow title='Go To Project Dashboard'>
                              <OpenInNewIcon />
                            </Tooltip>
                          </IconButton>
                        </Link>
                      </div>
                    </MenuItem>
                ) }
              </List>
            }
            { !loadingNearbyProjects && _.isEmpty(nearbyProjects) && <div className={classes.noNearbyWrapper}>No projects within the selected radius</div> }
        </Box>
      </Box>
    )
  }

  const renderNearbyProjectTimeRules = () => {
    if (_.isEmpty(_.get(selectedNearbyProject, 'timeRules', []))) {
        return(
            <div className={classes.noNearbySelected}>No time rules to import from the selected project</div>
          )
    }

    return (
        <Box>
          {_.has(selectedNearbyProject, 'settings.round_up_rule') && renderRoundUpRule()}
          {!_.isEmpty(_.get(selectedNearbyProject, 'timeRules', []).filter(oneTimeRule => _.get(oneTimeRule, 'modifies') === 'punch')) && renderProjectRoundUpRules()}
          {!_.isEmpty(_.get(selectedNearbyProject, 'timeRules', []).filter(oneTimeRule => _.get(oneTimeRule, 'modifies') === 'shift')) && renderProjectBreakTimeRules()}
        </Box>
      )
  }

  const renderNearbyProjectSettings = () => {
    let settingsFound = false

    _.forEach(_.get(selectedNearbyProject, 'settings', {}), (value, key) => {
      if (['smsFlows', 'maxShiftLength', 'uiSettings', 'mobileAppSettings', 'round_up_rule'].indexOf(key) !== -1 && value) {
        settingsFound = true
        return
      }
    });

    if (!settingsFound) {
        return(
            <div className={classes.noNearbySelected}>No settings to import from the selected project</div>
          )
    }

    return (
        <Box>
          {_.has(selectedNearbyProject, 'settings.smsFlows') && !_.isEmpty(_.get(selectedNearbyProject, 'settings.smsFlows')) && renderWorkerSmsServices()}
          {_.has(selectedNearbyProject, 'settings.maxShiftLength') && renderWorkShiftCustomization()}
          {_.has(selectedNearbyProject, 'settings.uiSettings') && renderProjectCustomDisplay()}
          {_.has(selectedNearbyProject, 'settings.mobileAppSettings') && renderProjectMobileAppSettings()}
        </Box>
    )
  }

  const renderWorkerSmsServices = () => {
    return (
      <Box display='flex' borderBottom='1px solid #cecece'>
        { step === 1 &&
          <FormControlLabel
            style={{ marginLeft: 4 }}
            onClick={(e) => e.stopPropagation()}
            control={
              <Checkbox
                size='small'
                color="primary"
                checked={_.get(selectedNearbyProject, 'selectedSettings.workerSmsServices', false)}
                onChange={(e) => { e.stopPropagation(); handleSelectSetting('workerSmsServices') }}
              />
            }
          />
        }
        <Accordion classes={{ root: classes.accordionRoot }} elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div style={{ display: "flex" }}>
              <strong>Worker SMS Services</strong>
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column', paddingTop: 0 }}>
            <List dense className={classes.itemsList}>
              <ListSubheader className={classes.servicesHeaderStyle}>
                <span style={{ marginRight: 110 }}>Service</span>
                <span style={{ marginRight: 80 }}>Happens At</span>
                <span>Days</span></ListSubheader>
                {
                  _.get(selectedNearbyProject, 'settings.smsFlows', []).map((oneService) =>
                    <ListItem button divider key={_.get(oneService, 'id')} onClick={() => handleServiceClick(oneService)} style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: _.get(oneService, 'active') ? '#78bd5d6b' : '#dc354538' }}>
                      <div className={classes.listItemStyle}>
                        <span style={{ width: 110 }}>{_.isEqual(_.get(oneService, 'service'), 'claim') ? 'Submit Claim' : _.isEqual(_.get(oneService, 'service'), 'lunchbreak') ? 'Lunch Break' : 'Request Per Diem'}</span>
                        <span style={{ width: 105 }}>{_.isEqual(_.get(oneService, 'at'), 'punch-in') ? 'Punch-In' : 'Punch-Out'}</span>
                        <span style={{ width: 165 }}>{_.isEqual(_.size(_.get(oneService, 'dayOfWeek')), 7) ? 'Every day' : _.get(oneService, 'dayOfWeek').map(oneDay => <span>{oneDay} </span>)}</span>
                      </div>
                    </ListItem>
                  )
                }
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  }

  const renderWorkShiftCustomization = () => {
    return (
      <Box display='flex' borderBottom='1px solid #cecece'>
        { step === 1 &&
          <FormControlLabel
            style={{ marginLeft: 4 }}
            onClick={(e) => e.stopPropagation()}
            control={
              <Checkbox
                size='small'
                color="primary"
                checked={_.get(selectedNearbyProject, 'selectedSettings.workShiftCustomization', false)}
                onChange={(e) => { e.stopPropagation(); handleSelectSetting('workShiftCustomization') }}
                />
            }
            />
        }
        <Accordion classes={{ root: classes.accordionRoot }} elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div style={{ display: "flex" }}>
              <strong>Work Shift Customization</strong>
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            <div style={{ display: 'flex', marginRight: '10px', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControl>
                  <FormLabel>Max Shift Duration</FormLabel>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Slider readOnly disabled min={8} max={168} value={_.get(selectedNearbyProject, 'settings.maxShiftLength')} aria-labelledby="maxShiftLength-slider" style={{ width: 300 }} />
                    <Input
                      disabled
                      style={{ marginLeft: 16, marginRight: 8 }}
                      value={_.get(selectedNearbyProject, 'settings.maxShiftLength')}
                      margin="dense"
                      inputProps={{
                        min: 8,
                        max: 168,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }}
                      />
                    <span>hours</span>
                  </div>
                </FormControl>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  }

  const renderProjectCustomDisplay = () => {
    return (
      <Box display='flex' borderBottom='1px solid #cecece'>
        { step === 1 &&
          <FormControlLabel
            style={{ marginLeft: 4 }}
            onClick={(e) => e.stopPropagation()}
            control={
              <Checkbox
                size='small'
                color="primary"
                checked={_.get(selectedNearbyProject, 'selectedSettings.projectCustomDisplay', false)}
                onChange={(e) => { e.stopPropagation(); handleSelectSetting('projectCustomDisplay') }}
                />
            }
            />
        }
        <Accordion classes={{ root: classes.accordionRoot }} elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div style={{ display: "flex" }}>
              <strong>Project Custom Display</strong>
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            <div style={{ display: 'flex', marginRight: '10px', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className={classes.crewFilterFormControl}>Timesheet</div>
                <FormControlLabel
                  style={{ marginLeft: 8 }}
                  control={<SBSwitch checked={_.get(selectedNearbyProject, 'settings.uiSettings.timesheet.forceCrewFilter', false)} name='crew filter' />}
                  label='Enable Crew Filter'
                  disabled={true}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  }

  const renderProjectMobileAppSettings = () => {
    return (
      <Box display='flex' borderBottom='1px solid #cecece'>
        { step === 1 &&
          <FormControlLabel
            style={{ marginLeft: 4 }}
            onClick={(e) => e.stopPropagation()}
            control={
              <Checkbox
                size='small'
                color="primary"
                checked={_.get(selectedNearbyProject, 'selectedSettings.mobileAppSettings', false)}
                onChange={(e) => { e.stopPropagation(); handleSelectSetting('mobileAppSettings') }}
                />
            }
            />
        }
        <Accordion classes={{ root: classes.accordionRoot }} elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div style={{ display: "flex" }}>
              <strong>Project MobileApp Settings</strong>
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            <div style={{ display: 'flex', marginRight: '10px', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                  control={<SBSwitch checked={_.get(selectedNearbyProject, 'settings.mobileAppSettings.geofence.disabled', false)} name='geofence' />}
                  label='Disable Geofence'
                  disabled={true}
                />
                <FormControl style={{ paddingLeft: 8 }}>
                  <TextField
                    label='Geofence Radius (miles)'
                    color='primary'
                    disabled={true}
                    value={_.get(selectedNearbyProject, 'settings.mobileAppSettings.geofence.radius', 0)}
                    type='number'
                  />
                </FormControl>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  }

  const renderRoundUpRule = () => {
    return (
      <Box display='flex' borderBottom='1px solid #cecece'>
        { step === 1 &&
          <FormControlLabel
            style={{ marginLeft: 4 }}
            onClick={(e) => e.stopPropagation()}
            control={
              <Checkbox
                size='small'
                color="primary"
                checked={_.get(selectedNearbyProject, 'selectedSettings.roundupRule', false)}
                onChange={(e) => { e.stopPropagation(); handleSelectSetting('roundupRule') }}
                />
            }
            />
        }
        <Accordion classes={{ root: classes.accordionRoot }} elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div style={{ display: "flex" }}>
              <strong>Default Round Up Rule</strong>
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            <RadioGroup className={classes.radioGroup} aria-label="gender" name="round_up_rule" value={_.get(selectedNearbyProject, 'settings.round_up_rule').toString()}>
              <FormControlLabel value="0" control={<Radio disabled color='primary' />} label="None" />
              <FormControlLabel value="1" control={<Radio disabled color='primary' />} label="7 Minutes" />
              <FormControlLabel value="2" control={<Radio disabled color='primary' />} label="15 Minutes" />
            </RadioGroup>
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  }

  const renderNearbyProjectSettingsAndTimeRules = () => {
    return(
      <Box display='flex' flexDirection='column' className={classes.projectSettingsAndTimeRulesWrapper}>
        <Box className={classes.settingsTimeRulesTitleStyles}>
          <Box display='flex' alignItems='center'>
            <Checkbox
              size='small'
              color="primary"
              checked={checkAllSelected()}
              indeterminate={checkIndeterminate()}
              onChange={handleSelectAll}
              style={{ margin: '0px 4px', marginBottom: -2 }}
            />
          <div onClick={() => setSettingsTimeRules('settings')} className={clsx(classes.tab, { [classes.tabSelected]: settingsTimeRules === 'settings' })}>
              Settings
            </div>
            <div onClick={() => setSettingsTimeRules('timeRules')} className={clsx(classes.tab, { [classes.tabSelected]: settingsTimeRules === 'timeRules' })}>
              Time Rules
            </div>
          </Box>
        </Box>
        <Box display='flex' flexDirection='column' className={classes.nearbyProjectsColumnsWrapper}>
          <List className={classes.nearbyProjectsListWrapper}>
            { settingsTimeRules === 'settings' &&
              renderNearbyProjectSettings()
            }
            { settingsTimeRules === 'timeRules' &&
              renderNearbyProjectTimeRules()
            }
          </List>
        </Box>
      </Box>
    )
  }

  const renderProjectRoundUpRules = () => {
    return (
      <Box display='flex' borderBottom='1px solid #cecece'>
        { step === 1 &&
          <FormControlLabel
            style={{ marginLeft: 4 }}
            onClick={(e) => e.stopPropagation()}
            control={
              <Checkbox
                size='small'
                color="primary"
                checked={_.get(selectedNearbyProject, 'selectedSettings.roundupRules', false)}
                onChange={(e) => { e.stopPropagation(); handleSelectSetting('roundupRules') }}
                />
            }
            />
        }
        <Accordion classes={{ root: classes.accordionRoot }} elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div style={{ display: "flex" }}>
              <strong>Round Up Rules</strong>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {_.get(selectedNearbyProject, 'timeRules', []).filter(oneRule => _.get(oneRule, 'modifies') === 'punch').map((item, idx) => (
                <div key={idx} className={classes.mainContainer}>
                  <TextField
                    id="from"
                    label="From"
                    name="from"
                    type="time"
                    value={convertMilitaryToRegularTime(_.get(item, 'rule.from', 0))}
                    className={classes.textField}
                    disabled={true}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                  <TextField
                    id="to"
                    label="To"
                    name="to"
                    type="time"
                    disabled={true}
                    value={convertMilitaryToRegularTime(_.get(item, 'rule.to', 0))}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                  <TextField
                    id="changeTo"
                    label="Change To"
                    disabled={true}
                    name="change_to"
                    type="time"
                    value={_.get(item, 'rule.change_to')}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  <FormControl>
                    <InputLabel>Punch Type</InputLabel>
                    <Select className={classes.punchType} disabled={true} value={!_.isNull(_.get(item, 'rule.applies_to')) ? _.get(item, 'rule.applies_to', 'all') : 'all'}>
                      <MenuItem value='all'>All</MenuItem>
                      <MenuItem value='punch-in'>Punch In</MenuItem>
                      <MenuItem value='punch-out'>Punch Out</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  }

  // NOTE: the <Select /> above on line:1429 generates an error when rule.applies_to doesnt exist - is it safe to default to 'all' in this case?

  const renderProjectBreakTimeRules = () => {
    return (
      <Box display='flex' borderBottom='1px solid #cecece'>
        { step === 1 &&
          <FormControlLabel
            style={{ marginLeft: 4 }}
            onClick={(e) => e.stopPropagation()}
            control={
              <Checkbox
                size='small'
                color="primary"
                checked={_.get(selectedNearbyProject, 'selectedSettings.breakTimeRules', false)}
                onChange={(e) => { e.stopPropagation(); handleSelectSetting('breakTimeRules') }}
                />
            }
            />
        }
        <Accordion classes={{ root: classes.accordionRoot }} elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div style={{ display: "flex" }}>
              <strong>Break Time Rules</strong>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {_.get(selectedNearbyProject, 'timeRules', []).filter(oneRule => _.get(oneRule, 'modifies') === 'shift').map((item, idx) => (
                <div key={idx} className={classes.mainContainer}>
                  <TextField
                    disabled={true}
                    className={classes.textField}
                    value={_.get(item, 'rule.from', 0)}
                    label="From (Hours)"
                    name='from'
                    type='number' />
                  <TextField
                    disabled={true}
                    className={classes.textField}
                    value={_.get(item, 'rule.to', 0)}
                    label="To (Hours)"
                    name='to'
                    type='number' />
                  <TextField
                    disabled={true}
                    className={classes.textField}
                    value={_.get(item, 'rule.break_time', 0)}
                    label="Break Time (Minutes)"
                    name='break_time'
                    type='number' />
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  }

  const renderContractors = () => {
    if (loadingContractors) {
        return (<div className={classes.nearbyLoaderWrapper}><CircularProgress color='primary' size={24} /></div>)
    }

    if (_.isEmpty(contractors)) {
        return(
            <div className={classes.noNearbySelected}>There are no contractors that can be assigned to this project</div>
          )
    }

    return(
        <Box display='flex' flexDirection='column' className={classes.nearbyProjectsWrapper}>
          <Box className={classes.contractorTitleStyles}>
              <Checkbox
                size='small'
                color="primary"
                checked={checkAllSelected()}
                disabled={!!project}
                indeterminate={checkIndeterminate()}
                onChange={handleSelectAll}
                style={{ margin: '0px 4px', marginBottom: -2 }}
              />
              Contractors
          </Box>
          <Box display='flex' flexDirection='column' className={classes.nearbyProjectsColumnsWrapper}>
            <List className={classes.nearbyProjectsListWrapper}>
              { contractors.map(oneContractor =>
                  <MenuItem
                        disabled={!!project}
                        classes={{ root: classes.timeRuleMenuItemRoot, gutters: classes.timeRuleMenuItemGutter }}
                        onClick={(e) => { handleSelectContractor(_.get(oneContractor, '_id')) }}
                        key={_.get(oneContractor, '_id')}>
                    <FormControlLabel
                    control={
                        <Checkbox
                        size='small'
                        color="primary"
                        checked={_.get(oneContractor, 'isSelected', false)}
                        onChange={(e) => { e.stopPropagation(); handleSelectContractor(_.get(oneContractor, '_id')) }}
                        />
                    }
                    disabled={!!project}
                    onClick={(e) => e.stopPropagation()}
                    style={{ margin: 0, marginRight: 4 }}
                    />
                { _.get(oneContractor, 'company.name') }
                  </MenuItem>
                )
              }
            </List>
          </Box>
        </Box>
      )
  }

  const renderStep1 = () => {
    return(
      <Grid container spacing={2} wrap='nowrap'>
        <Grid direction='column' item container style={{ minWidth: 450, maxWidth: 450 }}>
          <Grid item>
            {renderProjectInfo()}
          </Grid>
          <Grid direction='row' wrap='nowrap' container item>
            { renderNearbyProjectList() }
          </Grid>
        </Grid>
        <Grid direction='column' item container style={{ minWidth: 600, maxWidth: 600 }}>
          <Grid item>
            <FormControl fullWidth>
              {renderGoogleMap()}
            </FormControl>
          </Grid>
          <Grid item style={{ marginTop: 16 }}>
            { !loadingNearbyProjects && !_.isEmpty(nearbyProjects) && !selectedNearbyProject &&
              <div className={classes.noNearbySelected}>Click on a nearby project for settings and timerules</div>
            }
            { !loadingNearbyProjects && selectedNearbyProject &&
              <div>{ renderNearbyProjectSettingsAndTimeRules() }</div>
            }
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderRadiusSelect = () => {
    return (
      <FormControl>
        <Select
          disabled={loadingNearbyProjects}
          className={classes.radiusSelect}
          value={radius}
          onChange={handleRadiusChange}>
          {
            [10,20,30,40,50,60,70,80,90,100,110,120,130,140,150,1000].map(item =>
              <MenuItem key={item} value={item}>
                <div>
                  {item} mi.
                </div>
              </MenuItem>
            )
          }
        </Select>
      </FormControl>
    )
  }

  const renderStep2 = () => {
    return(
      <Grid container spacing={2} wrap='nowrap'>
        <Grid direction='column' item container style={{ minWidth: 400 }}>
          <Grid item>
            {renderProjectInfo()}
          </Grid>
          <Grid item style={{ marginTop: 16 }}>
            { renderContractors() }
          </Grid>
        </Grid>
        <Grid direction='column' item container style={{ minWidth: 600, maxWidth: 600 }}>
          <Grid item>
            <FormControl fullWidth>
              {renderGoogleMap()}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderStep3 = () => {
    return(
      <Grid container spacing={2} wrap='nowrap'>
        <Grid direction='column' item container style={{ minWidth: 400 }}>
          {renderProjectInfo()}
          <Grid item style={{ marginTop: 16 }}>
            {renderSelectedContractors()}
          </Grid>
        </Grid>
        <Grid direction='column' item container style={{ minWidth: 600, maxWidth: 600 }}>
          <Grid item>
            <FormControl fullWidth>
              {renderGoogleMap()}
            </FormControl>
          </Grid>
          <Grid item>
            { !project && renderSelectedSettings()}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const handleNameChange = (event) => {
    setProjectNameError(null);
    setProjectName(_.get(event, 'target.value'));
  }

  useEffect(() => {
    getContractors();

    return () => { };
  }, [])

  useEffect(() => {

    if (project && _.isEmpty(projectName)) {
        setProjectName(_.get(project, 'name'));

        setAddress({
            formatted_address: _.get(project, 'fullAddress'),
            postal_code: _.get(project, 'zip'),
            streetAddress: _.get(project, 'address'),
            locality: _.get(project, 'city'),
            administrative_area_level_1: ['US', 'CA'].includes(_.get(project, 'country')) ? _.get(project, 'country') === 'US' ? statesArray.find(state => state.value === _.get(project, 'state')) : canadaStatesArray.find(state => state.value === _.get(project, 'state')) : _.get(project, 'state'),
            country: countriesArray.find(country => country.value === _.get(project, 'country')),
            latLng: {
                lat: _.get(project, 'latitude'),
                lng: _.get(project, 'longitude')
            }
        })

        window.getTimezone({ lat: _.get(project, 'latitude'), lng: _.get(project, 'longitude') });
    }

    return () => { };
  }, [contractors])

  useEffect(() => {
    if (step === 1) checkForNearbyProject();
  }, [radius]);

  return (
    <ThemeProvider theme={myTheme}>
      <Dialog open={true} onClose={closeDialog} maxWidth={false}>
        <DialogTitle disableTypography={true} className={classes.dialogTitleWrapper}>
          <div className={classes.titleStyles}>{ project ? 'Update' : 'Create New'} Project</div>
          <IconButton size='small' onClick={closeDialog}>
            <Close size='20' color='primary' />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          { renderStepContent() }
        </DialogContent>
        <DialogActions className={classes.dialogActionsWrapper}>
          {step !== 0 ?
            <Button disabled={step === 0 || loadingNearbyProjects || creatingNewProject} onClick={handleBack}>
              Back
            </Button> : <Box></Box>
          }
          <Stepper className={classes.stepperStyles} activeStep={step}>
            {getSteps().map((label) => {
                if (!(_.isEqual(label, 'Nearby Projects & Settings') && project)) {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
                }
            })}
          </Stepper>
          <Button color="primary" onClick={handleNext} disabled={disableNext()}>
            { creatingNewProject ? <CircularProgress size={18} color='primary' /> : step === (getSteps().length - 1) ? project ? "Update" : "Create" : "Next"}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default ProjectByUserWizard;
