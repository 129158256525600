import React, { useState } from 'react';
import { Fab, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Button, Avatar, Chip, Table, TableHead, TableRow, TableCell, TableBody, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { get } from 'lodash'


const WorkersBroadCastDetail = ({ workers }) => {
  console.log(workers)
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align='left'>Picture</TableCell>
          <TableCell align='left'>First Name</TableCell>
          <TableCell align='left'>Last Name</TableCell>
          <TableCell align='left'>Phone Number</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {workers.map((row) => (
          <TableRow key={row.name}>
            <TableCell component="th" scope="row">
              <Avatar alt="Remy Sharp" variant='circle' src={get(row, 'image_path', 'https://s3.us-west-2.amazonaws.com/static-files.connectedbarrel.com/project-employees/no-profile-image.png')} />
            </TableCell>
            <TableCell>{row.first_name}</TableCell>
            <TableCell>{row.last_name}</TableCell>
            <TableCell>{row.phone_number}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )

}

const MessageTableCell = ({ data, classes }) => {

  const [openDetails, setOpenDetails] = useState(false);

  const openModal = () => setOpenDetails(true)
  const closeModal = () => setOpenDetails(false)

  const color = 'bg-light-blue'

  const useStyles = makeStyles((theme) => ({
    root: {
      maxHeight: '400px'
    }
  }));

  const dialogClasses = useStyles();
  const momentParsed = moment(data.created_at)
  const datetime = momentParsed.format("MM/DD/YYYY hh:mm a")

  let colorClass = ''

  switch (data.status) {
    case 'processing':
    case 'requested':
      colorClass = 'bg-warning'
      break;
    case 'executed':
      colorClass = 'bg-success'
      break;
    case 'failed':
      colorClass = 'bg-danger'
      break;

    default:
      break;
  }

  const formatPhoneNumber = (nbr) => {
    var match = nbr.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return nbr
  }

  const getWorkersFormat = () => {
    return data.targets.map(target => {
      return {
        key: target._id,
        label: `${target.first_name} ${target.last_name} ${formatPhoneNumber(target.phone_number)}`
      }
    })
  }

  const getTargetFmt = (targets) => {
    const label = targets > 1 ? 'worker' : 'workers'
    return `Targeted ${targets.length} ${label}`
  }

  return (
    <tr
      tabIndex={-1}
    >
      <td>
        <div className="user-profile py-2 d-flex flex-row align-items-center">
          <Avatar className={`size-40 align-self-start mr-3 ${color}`}>
            <i className="far fa-comment-dots"></i>
          </Avatar>
        </div>
      </td>
      <td className={classes.td}>
        {`${data.user.first_name} ${data.user.last_name}`}
      </td>
      {/* <td className={classes.td}>
                {data.company[0].name}
            </td> */}
      <td className={classes.td}>
        {datetime}
      </td>
      <td className={classes.td}>
        <span className={`badge text-upercase text-white ${colorClass}`}>
          {data.status}
        </span>
      </td>
      <td className={`${classes.td} text-right`}>
        {getTargetFmt(data.targets)}

        <Fab onClick={openModal} className="jr-fab-btn jr-btn-fab-xs bg-light-green text-white ml-4" style={{ borderRadius: "50%" }}>
          <span className="fas fa-info-circle fa-4x" />
        </Fab>

        <Dialog open={openDetails} onClose={closeModal} classes={{ paper: dialogClasses.root }}>
          <DialogTitle>
            Broadcasted Details
            {/* {
              getWorkersFormat().map(rc => <Chip {...rc} />)
            } */}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {data.message}
            </DialogContentText>
            <span>{data.targets.length} workers target</span>
            <WorkersBroadCastDetail workers={data.targets} classes={dialogClasses} />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </td>
    </tr>

  );
};

export default MessageTableCell;