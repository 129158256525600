import React, { useState, useEffect } from 'react';
import { CircularProgress, Accordion, AccordionDetails, AccordionSummary, FormControlLabel, makeStyles, Grid, Checkbox } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { get, startCase, size } from 'lodash';
import { SBSwitch } from '../../../../reusableComponents';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(19),
    fontWeight: 600
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    // paddingLeft: 0
    paddingTop: 0
  },
  permissionMainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    paddingTop: 0
  },
  permissionGroupCheckboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  permissionGroup: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600,
    color: '#7a7a7a',
    cursor: 'pointer',
    textTransform: 'capitalize',
  },
  permissionTogglesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 320,
    padding: 8
  },
  permissionTogglesGrid: {
    maxWidth: 410,
    paddingLeft: 20,
    paddingTop: 16
  },
  loaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  noPermissionsWrapper: {
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#676A6C',
  }
}));

export default function PermissionsSection(props) {

  const { userPermissions, updateState } = props;
  const classes = useStyles();

  const [permissions, setPermissions] = useState([])

  useEffect(() => {
    if (!permissions.length) {
      setPermissions(get(props, 'userPermissions'))
    }
  }, [props.userPermissions]);

  const handlePermissionGroupCheckboxClick = (e, permissionGroupScope) => {
    e.stopPropagation();

    setPermissions(prevPermissions => {
      return prevPermissions.map(onePerm => {
        let actions = get(onePerm, `actions`, [])
        let enabled = get(onePerm, `enabled`)
        let enabledCount = 0

        actions.map(oneAction => {
          if (get(oneAction, 'value')) enabledCount ++
        })

        if (get(onePerm, 'scope') === permissionGroupScope) {
          if ((enabledCount === 0) || (enabledCount > 0 && enabledCount < actions.filter(oneAction => !get(oneAction, 'inmutable')).length)) {
            enabled = true
            actions.map(oneAction => { if(!oneAction.inmutable) { oneAction.value = true } })
            enabledCount = size(actions.filter(oneAction => oneAction.value))
          } else {
            enabled = false
            actions.map(oneAction => { if(!oneAction.inmutable) { oneAction.value = false } })
            enabledCount = size(actions.filter(oneAction => oneAction.value))
          }
        }

        return { ...onePerm, enabled, enabledCount }
      })
    })

    updateState(prevState => ({
      ...prevState,
      permissions,
    }))
  }

  const handleTogglePermission = (permissionGroupScope, toggleScope, value) => {
    setPermissions(prevPerm => {
      return prevPerm.map(onePerm => {
        let enabledCount = get(onePerm, 'enabledCount')

        if (get(onePerm, 'scope') === permissionGroupScope) {

          if (value) {
            enabledCount++
          } else {
            enabledCount--
          }

          get(onePerm, 'actions', []).map(oneAction => {
            if (get(oneAction, 'scope') === toggleScope) {
              oneAction.value = value
            } else if (get(oneAction, 'parent') === toggleScope && !get(oneAction, 'inmutable')) {
              if (value) {
                enabledCount++
                oneAction.value = true
                oneAction.disabled = false
              } else {
                enabledCount--
                oneAction.value = false
                oneAction.disabled = true
              }
            }

            return oneAction
          })
        }

        return onePerm
        // return { ...onePerm, enabledCount }
      })
    })

    updateState(prevState => ({
      ...prevState,
      permissions,
    }))
  }

  const renderLoader = () => {
    return(
      <div className={classes.loaderWrapper}>
        <CircularProgress color="primary" size={21} />
      </div>
    )
  }

  const renderPermissions = () => {
    if (permissions.length !== 0) {
      let temp = []

      const renderInputs = (permissionName, permissionObject, enabled) => {
        let temp = []

        // return this to disabled assertion below: || get(permissionObject, 'inmutable') || get(oneAction, 'inmutable')
        get(permissionObject, 'actions', []).map(oneAction => {
          temp.push(
            <Grid item xs={6}>
              <FormControlLabel control={<SBSwitch height={24} width={40} size={14} disabled={!enabled || get(oneAction, 'disabled')} color="primary" handleToggle={() => handleTogglePermission(permissionName, get(oneAction, 'scope'), !get(oneAction, 'value'))} checked={get(oneAction, 'value')} />} label={startCase(get(oneAction, 'label').toLowerCase()).replace('Can ', '')} />
            </Grid>
          )
        })

        return temp
      }

      permissions.map((onePermissionGroup, index) => {
        let permissionGroupScope = get(onePermissionGroup, 'scope')
        let enabled = get(onePermissionGroup, 'enabled', false)
        let disabled = get(onePermissionGroup, 'disabled', false)
        // let inmutable = get(onePermissionGroup, 'inmutable', false)
        let inmutable = false

        temp.push(
          (
            <div key={`${permissionGroupScope}_permissionWrapper`} className={classes.permissionMainWrapper}>
              <div className={classes.permissionGroupCheckboxWrapper}>
                <div className={classes.permissionGroup} style={{ color: enabled ? '#f15922' : '#7a7a7a' }} onClick={(e) => { handlePermissionGroupCheckboxClick(e, permissionGroupScope)} }>
                  {permissionGroupScope}
                </div>
                <FormControlLabel style={{ marginBottom: -1 }} control={<SBSwitch color="primary" noMargin handleToggle={(e) => { handlePermissionGroupCheckboxClick(e, permissionGroupScope)}} checked={enabled} disabled={disabled || inmutable} />} />
              </div>
              <Grid container className={classes.permissionTogglesGrid}>
                { renderInputs(permissionGroupScope, onePermissionGroup, enabled) }
              </Grid>
            </div>
          )
        )

        if (index < size(permissions)-1) {
          temp.push(<hr style={{ borderColor: '#ccc', margin: 8, marginTop: 4, marginBottom: 16 }} />)
        }
      })

      return temp
    } else {
      return(<div className={classes.noPermissionsWrapper}>There are no permissions to set</div>)
    }
  }

  return (
    <div className={classes.root}>
      <Accordion style={{ backgroundColor: "#f3f3f4" }}>
        <AccordionSummary className={classes.heading} expandIcon={<ExpandMoreIcon />}>
          Permissions
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          { renderPermissions() }
        </AccordionDetails>
      </Accordion>
    </div>
  )
};
