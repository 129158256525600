import React, { useEffect, useState, useContext } from 'react';
import * as _ from 'lodash';
import { WorkerTablePagination } from '../../../employees/projectEmployees/components';
import { Switch, FormControlLabel, Button, Dialog, DialogActions, DialogContent, Drawer, MenuList, IconButton, lighten, withStyles, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Toolbar, Typography, Popover, MenuItem, ListSubheader, ListItemText } from '@material-ui/core';
import { Add, Edit, Done, Close, Delete } from '@material-ui/icons';
import { allColumns } from '../../constants/tableHeaders'
import { CreateClassifier } from '../index'
import { SettingsContext } from '../../../employees/contexts/SettingsContext';
import { gettingCompanyClassifiers, deletingClassifier, editClassifier } from '../../services'

const EnhancedTableToolbar = (props) => {

  const { onClassifier, toggleCreateMenu, handleCreateNewMenuItemClick } = props;

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    highlight:
      theme.palette.type === "light"
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
    title: {
      flex: "1 1 100%"
    }
  }));

  const classes = useToolbarStyles();

  return (
    <Toolbar>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Classifiers
      </Typography>
      <IconButton aria-label="add" onClick={(event) => toggleCreateMenu(event, true)} >
        <Add />
      </IconButton>
    </Toolbar>
  );
};

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort, columns } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align="left"
            padding={column.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === column.id ? order : false}
          >
            {column.sortOption ?
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : "asc"}
                onClick={createSortHandler(column.id)}
              >
                {column.label}
                {orderBy === column.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel> :
              column.label
            }

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const pxToRem = (px, oneRemPx = 17) => `${px / oneRemPx}rem`;
const borderWidth = 2;
const width = pxToRem(56);
const height = pxToRem(34);
const size = pxToRem(22);
const gap = (34 - 22) / 2;

const LovelySwitch = withStyles((theme) => ({
  root: {
      width,
      height,
      padding: 0,
      margin: theme.spacing(1),
      overflow: 'unset',
    },
    switchBase: {
      padding: pxToRem(gap),
      '&$checked': {
        color: '#fff',
        transform: `translateX(calc(${width} - ${size} - ${pxToRem(2 * gap)}))`,
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 'none',
        },
        '& $thumb': {
          backgroundColor: '#fff',
        },
      },
    },
    track: {
      borderRadius: 40,
      border: `solid ${theme.palette.grey[400]}`,
      borderWidth,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
      boxSizing: 'border-box',
    },
    thumb: {
      boxShadow: 'none',
      backgroundColor: theme.palette.grey[400],
      width: size,
      height: size,
    },
    checked: {},
}))(Switch);

export default function TableComponent(props) {

  const settingsContext = useContext(SettingsContext)

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%"
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 750
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1
    },
    drawerRoot: {
      width: `${settingsContext.isMobile ? '75%' : '35%'}`
    },
    createNewMenuItem: {
      fontSize: 30,
      '&:hover': {
        color: theme.palette.primary.main,
        '& $createNewMenuItem': {
          color: theme.palette.primary.main,
        },
      },
    },
    createNewGroupHeader: {
      color: 'rgb(240 88 34 / 80%)',
      backgroundColor: 'rgb(240 88 34 / 10%)'
    },
    builtInTag: {
      backgroundColor: 'rgb(40 208 40 / 70%)',
      color: 'white',
      borderRadius: 5,
      textAlign: 'center',
      padding: 2,
      fontWeight: '600',
      position: 'absolute',
      left: -50,
      top: 23,
      paddingLeft: 5,
      paddingRight: 5,
    }
  }));

  const { company, onLoading, currentCompany, applications, classifierTypes, classifiersEndpoint, builtInClassifiers } = props;

  const classes = useStyles();

  const [state, setState] = useState({
    classifiers: [],
    order: "asc",
    orderBy: '',
    addClassifier: {
      status: false,
      classifier: null
    },
    pagination: {
      page: 0,
      rowsPerPage: 25
    },
    currentClassifier: null,
    toggleCreateMenu: false
  });

  const [createNewMenuAnchor, setCreateNewMenuAnchor] = useState(null);
  const [deleteClassifier, setDeleteClassifier] = useState(false);
  const [selectedPresetClassifier, setSelectedPresetClassifier] = useState(null);

  const getClassifiers = async (payload, companyId = _.get(company, '_id.$oid')) => {
    try {
      onLoading()

      const res = await gettingCompanyClassifiers(companyId, payload, classifiersEndpoint);

      setState(prevState => ({
        ...prevState,
        classifiers: _.get(res, 'data', [])
      }))

      onLoading(false)
    } catch (error) {

    }
  }

  useEffect(() => {
    let payload = {
      pagination: {
        ...state.pagination
      }
    }
    getClassifiers(payload, _.get(currentCompany, 'value'));
  }, [currentCompany])

  const handleChangePage = (step) => {

    let page = 0;
    if (step === 'next') {
      page = state.pagination.page + 1;
    } else {
      page = state.pagination.page - 1;
    }

    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      }
    }))

    let payload = {
      // filters: sendingFilters,
      pagination: {
        rowsPerPage: state.pagination.rowsPerPage,
        page
      }
    }

    getClassifiers(payload)
  };

  const handleRequestSort = (event, property) => {
    const isAsc = state.orderBy === property && state.order === "asc";

    setState(prevState => ({
      ...prevState,
      order: isAsc ? "desc" : "asc",
      orderBy: property
    }))

    let payload = {
      sort: {
        property,
        isAsc
      }
    }

    getClassifiers(payload);
  };

  const handleChangeRowsPerPage = (e) => {
    setState(prevState => ({
      ...prevState,
      pagination: {
        page: 0,
        rowsPerPage: e.target.value
      }
    }))

    let payload = {
      // filters: sendingFilters,
      pagination: {
        rowsPerPage: e.target.value,
        page: 0
      }
    }
    getClassifiers(payload);
  };

  const onClassifier = (classifier = null) => {
    setState(prevState => ({
      ...prevState,
      addClassifier: {
        status: true,
        classifier
      }
    }))

    if (classifier) {
      const preset = builtInClassifiers.filter(oneClassifier => oneClassifier.name === classifier.name)
      if (!_.isEmpty(preset)) {
        setSelectedPresetClassifier(preset[0])
      }
    }
  }

  const toggleCreateMenu = (event, on) => {
    setCreateNewMenuAnchor(_.get(event, 'currentTarget', null))
    setState(prevState => ({
      ...prevState,
      toggleCreateMenu: on
    }))
  }

  const handleCreateNewMenuItemClick = (builtInClassifier) => {
    if (builtInClassifier === null) {
      setSelectedPresetClassifier(null)
    } else {
      setSelectedPresetClassifier(builtInClassifier)
    }
    onClassifier(null)
    toggleCreateMenu(null, false)
  }

  const onCloseClassifier = (flag) => {
    setState(prevState => ({
      ...prevState,
      addClassifier: {
        status: false,
        profile: null
      }
    }))

    setSelectedPresetClassifier(null)

    if (_.isBoolean(flag) && flag) {
      let payload = {
        pagination: {
          ...state.pagination
        }
      }
      getClassifiers(payload);
    }
  }

  const onDeleteClassifier = async (flag, row = null) => {
    if (_.isNull(flag)) {
      setState(prevState => ({
        ...prevState,
        currentClassifier: row
      }));
      setDeleteClassifier(true)
    } else {
      try {
        if (flag) {
          onLoading();
          await deletingClassifier(_.get(state.currentClassifier, 'company._id'), classifiersEndpoint, _.get(state.currentClassifier, '_id'))
          setDeleteClassifier(false)
          let payload = {
            pagination: {
              ...state.pagination
            }
          }

          getClassifiers(payload);
        } else {
          setDeleteClassifier(false)
        }

      } catch (error) {

      }
    }
  }

  const onToggleClassifierActive = async (classifier) => {
    try {

      onLoading();

      let updatePayload = {
        ...classifier,
        active: !classifier.active
      }

      await editClassifier(_.get(company, '_id.$oid'), updatePayload, classifiersEndpoint, _.get(classifier, '_id'));

      onLoading(false);

      let payload = {
        pagination: {
          ...state.pagination
        }
      }

      getClassifiers(payload);
    } catch (error) {
      console.log("ERROR ", error)
    }
  }

  const renderBuiltInTag = (classifier) => {
    const preset = builtInClassifiers.filter(oneClassifier => oneClassifier.name === classifier.name)

    if (!_.isEmpty(preset)) {
      return(<div className={classes.builtInTag}>Built-in</div>)
    }
  }

  return (
    <>
      {/* <TableFilters filters={filters} onFilter={onFilter} onSendingFilters={onSendingFilters} onClearFilters={onClearFilters} /> */}
      <WorkerTablePagination
        pagination={state.pagination}
        onChangePage={handleChangePage}
        hasNext={_.size(state.classifiers) === state.pagination.rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Paper className={classes.paper}>

        <EnhancedTableToolbar onClassifier={onClassifier} toggleCreateMenu={toggleCreateMenu} handleCreateNewMenuItemClick={handleCreateNewMenuItemClick} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            style={{ position: 'relative' }}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={state.order}
              orderBy={state.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={state.classifiers.length}
              columns={allColumns}
            />
            <TableBody>
              {state.classifiers.map((row, index) => {
                return (
                  <TableRow key={_.get(row, '_id')}>

                    <TableCell align="left">
                      <FormControlLabel
                        control={ <LovelySwitch color="primary" checked={_.get(row, 'active', false)} onChange={() => onToggleClassifierActive(row)} />}
                      />
                    </TableCell>
                    <TableCell align="left" style={{ position: 'relative' }}>{renderBuiltInTag(row)}{_.get(row, 'name', '')}</TableCell>
                    <TableCell align="left">{_.get(row, 'code', '')}</TableCell>
                    <TableCell align="left">{_.size(_.get(row, 'items', ''))}</TableCell>
                    <TableCell align="left" style={{ textTransform: 'capitalize'}}>{_.get(row, 'appliesTo', '') === 'shift[type=pto]' ? 'PTO' : _.get(row, 'appliesTo', '') === 'shift[type=equipment]' ? 'Equipment' : _.get(row, 'appliesTo', '')}</TableCell>
                    <TableCell align="left">
                      <Edit onClick={() => onClassifier(row)} className='mr-2' style={{ cursor: 'pointer' }} />
                      <Delete onClick={() => onDeleteClassifier(null, row)} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <WorkerTablePagination
          pagination={state.pagination}
          onChangePage={handleChangePage}
          hasNext={_.size(state.classifiers) === state.pagination.rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Drawer disableBackdropClick={true} classes={{ paper: classes.drawerRoot }} anchor={'right'} open={state.addClassifier.status} onClose={onCloseClassifier}>
        <CreateClassifier
          company={company}
          classifier={state.addClassifier.classifier}
          onCloseClassifier={onCloseClassifier}
          applications={applications}
          classifierTypes={classifierTypes}
          classifiersEndpoint={classifiersEndpoint}
          builtInClassifiers={builtInClassifiers}
          selectedPreset={selectedPresetClassifier}
        />
      </Drawer>

      <Popover
        open={_.get(state, 'toggleCreateMenu', false)}
        onClose={() => toggleCreateMenu(null, false)}
        anchorEl={createNewMenuAnchor}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}>
        <MenuList>
          <MenuItem className={classes.createNewMenuItem} onClick={(event) => handleCreateNewMenuItemClick()} >
            <ListItemText>Custom Classifier</ListItemText>
          </MenuItem>
          { _.size(builtInClassifiers) && <ListSubheader className={classes.createNewGroupHeader}>Built in</ListSubheader> }
          {
            builtInClassifiers.map(oneClassifier =>
              <MenuItem className={classes.createNewMenuItem} onClick={(event) => handleCreateNewMenuItemClick(oneClassifier)}>
                <ListItemText>{_.get(oneClassifier, 'name')}</ListItemText>
              </MenuItem>
            )
          }
      </MenuList>
    </Popover>

      <Dialog
        open={deleteClassifier}
        onClose={() => onDeleteClassifier(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {`Are you sure you want to delete this classifier ${_.get(state.currentClassifier, 'name')}?`}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onDeleteClassifier(false)} color="primary">
            Disagree
          </Button>
          <Button onClick={() => onDeleteClassifier(true)} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
};
