import React, { useState, useEffect } from 'react'
import PhoneField from "../PhoneField";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import {
  TextField, FormControlLabel, Checkbox
} from "@material-ui/core";
import { SignalCellularNullSharp, Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  badge: {
    backgroundColor: '#939393 !important',
    top: "-16px !important",
    left: "60px !important",
    color: 'white !important',
    cursor: 'pointer'
  },
  successWorkerMessage: {
    marginBottom: '0px',
    position: 'relative'
  },
  successIcon: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    cursor: 'pointer',
    fontSize: '15px'
  },
  acknowledged: {
    marginTop: '15px'
  },
}));

const DisclaimerComponent = ({ disclaimer, labelText }) => {
  return (
    <span>{labelText} <i>{disclaimer !== '' ? `${disclaimer}` : ''}</i></span>
  )
}

export default function SafetyInfo(props) {

  const { company, userCompany, project, disclaimer, classes, state, onChange, type, onSafetyAgree, workerCompleteMessage } = props;
  // const [companyProjectSettings, setCompanyProjectSettings] = useState(SignalCellularNullSharp);
  const [successMessage, setSuccessMessage] = useState(workerCompleteMessage !== undefined && workerCompleteMessage !== '');
  const safetyClasses = useStyles();

  const handleChange = (ev) => {
    const name = ev.target.name;
    let value = '';
    if (["acknowledged"].indexOf(name) != -1) {
      value = ev.target.checked;
      if (props.onSafetyAgree) onSafetyAgree();

    } else {
      value = ev.target.value;
    }
    onChange({ prop: name, val: value })
  }

  if (type == 'sms') {
    return <p className="text-center">Safety info will be collected thru SMS</p>
  }

  if (type == 'form') {

    // useEffect(() => {
    //   console.log("project ", project)
    //   const url = `${location.origin}/projects/${project._id.$oid}/companies/${company._id.$oid}/settings.json`;
    //   axios.get(url).then(response => {
    //     setCompanyProjectSettings(response.data)
    //   }).catch(error => {
    //     console.error({ errorLoadingSettings });
    //     notificationCenter("error", get(error, 'response.data.error', 'Error'))

    //   })
    //   return () => { }
    // }, [])

    const labelTxt = state.language_code == 'en' ? "I Agree" : "Acepto";

    return (
      <div>
        {successMessage &&
          <div className={`alert alert-success alert-dismissible ${safetyClasses.successWorkerMessage}`} role="alert">
            {workerCompleteMessage}
            <Close className={safetyClasses.successIcon} onClick={() => setSuccessMessage(false)} />
          </div>
        }

        <form>
          <div>
            <TextField
              type="text"
              label="Address"
              name="address"
              className={classes.input}
              onChange={handleChange}
              value={state.address} />
          </div>
          <div>
            <TextField
              type="text"
              label="Emergency Name"
              name="emergency_name"
              className={classes.input}
              onChange={handleChange}
              value={state.emergency_name} />
          </div>
          <div>
            <PhoneField
              required={false}
              label="Emergency Phone Number"
              name="emergency_phone_number"
              className={classes.input}
              onChange={handleChange}
              value={state.emergency_phone_number} />
          </div>
          <div>
            <TextField
              type="text"
              label="Emergency Email"
              name="emergency_email"
              className={classes.input}
              onChange={handleChange}
              value={state.emergency_email} />
          </div>
          <div>
            <TextField
              type="text"
              label="Car Make"
              name="car_make"
              className={classes.input}
              onChange={handleChange}
              value={state.car_make} />
          </div>
          <div>
            <TextField
              type="text"
              label="Car Model"
              name="car_model"
              className={classes.input}
              onChange={handleChange}
              value={state.car_model} />
          </div>
          <div>
            <TextField
              type="text"
              label="Car Color"
              name="car_color"
              className={classes.input}
              onChange={handleChange}
              value={state.car_color} />
          </div>
          <div className='mb-3'>
            <TextField
              type="text"
              label="Car Tag"
              name="car_tag"
              className={classes.input}
              onChange={handleChange}
              value={state.car_tag} />
          </div>
          <div className={safetyClasses.acknowledged}>
            {/* {state.language == 'es' && (
              <p>{companyProjectSettings.acknowledgment_en}</p>
            )}
            {state.language == 'en' && (
              <p>{companyProjectSettings.acknowledgment_es}</p>
            )} */}

            <FormControlLabel
              value="end"
              control={<Checkbox color="primary" checked={state.acknowledged} name="acknowledged" onChange={handleChange} />}
              label={<DisclaimerComponent labelText={labelTxt} disclaimer={disclaimer} />}
              labelPlacement="end"
            />

          </div>
        </form>
      </div>
    )
  }

  return ""
}
