import React, { useState } from 'react'
import { Form, ToastHeader } from "react-bootstrap";
import axios from "axios";

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, withStyles, ThemeProvider } from "@material-ui/core/styles";
import { myTheme } from './helpers';

export default function PasswordModal({ worker }) {

  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const defaultState = { password: '', password_confirmation: '' }
  const [data, setData] = useState(defaultState);

  const handleClose = () => setShow(false);
  const handleOpen = () => {
    setData(defaultState)
    setShow(true)
    setSaving(false)
  };

  const disabledState = () => {
    return saving || data.password.length == 0 || data.password != data.password_confirmation
  }

  const handleChangeFactory = (field) => {
    return (ev) => {
      const _data = data
      _data[field] = ev.target.value
      setData({ ..._data })
    }
  }


  const save = () => {
    let token = document.querySelector("meta[name='csrf-token']").content
    const url = `${location.origin}/employees/${worker._id.$oid}/password_reset.json`
    const payload = data
    payload["authenticity_token"] = token
    setSaving(true)
    axios.put(url, payload).then(response => {
      setSaving(false)
      toastr.success("Successfully saved.")
      setShow(false)
    }).catch(errorSavingPassword => {
      setSaving(false)
      console.error({ errorSavingPassword })
      toastr.error(errorSavingPassword.response.data.error)
    })
  }

  return (
    <>
      <a href="#" onClick={handleOpen}>Change Password</a>
      <ThemeProvider theme={myTheme}>
        <Dialog open={show} maxWidth='sm' fullWidth>
          <DialogTitle>
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
            Change Passwrod
          </DialogTitle>
          <DialogContent>
            <Box display="flex">
              <Box>
                <FormControl fullWidth>
                  <TextField
                    name="password"
                    id="password"
                    label="New Password"
                    color="primary"
                    type="password"
                    onChange={handleChangeFactory('password')}
                    helperText="At least 8 characters. Must contain at least 1 number, 1 uppercase, and 1 lowercase letter."
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    name="passwordConfirmation"
                    id="passwordConfirmation"
                    label="Confirm Password"
                    color="primary"
                    type="password"
                    onChange={handleChangeFactory('password_confirmation')}
                  />
                </FormControl>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>Close</Button>
            <Button variant="outlined" onClick={save} color="primary">Change Password</Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>

  )
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
