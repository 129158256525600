import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { get, set, each, cloneDeep } from 'lodash';
export default function AddingColumns(props) {

  const { addColumns, onChangeAddColumns, totalColumns, onUpdateColumns } = props;

  const [tempColumns, setTempColumns] = useState(cloneDeep(totalColumns));

  const onActiveChange = (e, key) => {
    set(tempColumns[key], 'isActive', e);
    setTempColumns([...tempColumns]);
  }

  return (
    <Dialog open={addColumns} onClose={onChangeAddColumns}>
      <DialogTitle>
        Add Columns
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {tempColumns.map((item, key) => (
            <FormControlLabel
              key={get(item, 'id')}
              control={
                <Checkbox
                  checked={item.isActive}
                  onChange={(e) => onActiveChange(e.target.checked, key)}
                  name={get(item, 'id')}
                  color="primary"
                />
              }
              label={get(item, 'label')}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onUpdateColumns(tempColumns)} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
};