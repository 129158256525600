import { createTheme } from '@material-ui/core/styles';
import axios from 'axios';

export const myTheme = createTheme({
  palette: {
    primary: {
      main: '#f15922',
    },
    success: {
      main: '#78bd5d'
    },
    typography: {
      fontSize: 20,
      fontWeight: 800,
    },
    danger: {
      main: '#dc3545',
    },
  },
  typography: {
    fontSize: 20,
    fontWeight: 800,
  },
});


export const Auxiliary = (props) => props.children;

export const axiosConfig = () => {

  const token = document.querySelector("input[name='aws_token']").value
  // const token = "44e0b11ff339c68c61c273423f8aca063e15a6c56ce133587de2f5de2902b1b4060d2d963d898b199dfed12083563dcce6403a0d3b6e4b15a8c8adf0f6f5c9d3"
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  };
};

export const $axios = {
  $get: (url) => axios.get(url, axiosConfig())
    .then(({ data }) => data),
  $post: (url, data) => axios.post(url, data, axiosConfig())
    .then(({ data }) => data),
  $patch: (url, data) => axios.patch(url, data, axiosConfig())
    .then(({ data }) => data),
  $put: (url, data) => axios.put(url, data, axiosConfig())
    .then(({ data }) => data),
  $delete: (url, data) => axios.delete(url, { ...axiosConfig(), data })
    .then(({ data }) => data),
};

export const noop = () => {
};
