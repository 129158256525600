import React, { useContext, useState } from 'react'
import Select from 'react-select'
import { SearchBox, Widget } from '../../../projectEmployees/components'
import { makeStyles } from '@material-ui/core/styles'
import { get } from 'lodash'
import { SettingsContext } from '../../../contexts/SettingsContext'
import { Link } from '@material-ui/core'

const IconsList = ({
  company,
  classes,
  permissions,
  onCreateWorker,
  onBulkUploadWorkers,
  onShowDepartmentsPositions
}) => {
  const getS3Asset = (name) => `https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/project-employees/${name}`

  return (
    <div className={classes.iconList} style={{ display: 'flex', alignItems: 'center' }}>

      <Link className={classes.link} href="/certifications">
        <img className={classes.img} src={getS3Asset('documents.png')} alt={""} />
      </Link>
      {get(permissions, 'canCreate', false) ? <div className={classes.link} onClick={() => onCreateWorker()}>
        <img className={classes.img} src={getS3Asset('add-worker.png')} />
      </div> : null}
      {get(permissions, 'canCreate', false) ? <div className={classes.link} onClick={() => onBulkUploadWorkers()}>
        <img className={classes.img} src={require('../../../../../../assets/images/workers/add-worker-3-people.png')} />
      </div> : null}

      <Link className={classes.link} href={`/companies/${get(company, '_id.$oid')}/costcodes`}>
        <img className={classes.img} src={getS3Asset('costcodes.png')} />
      </Link>
      <Link style={{ marginRight: '10px' }} className={classes.link} onClick={onShowDepartmentsPositions}>
        <img className={classes.img} src={getS3Asset('settings.png')} />
      </Link>
    </div>
  )
}

export default function HeaderCompanySearch(props) {
  const {
    userCompany,
    onCompanyFilterChange,
    currentCompany,
    companies,
    permissions,
    onCreateWorker,
    onShowDepartmentsPositions,
    onBulkUploadWorkers
  } = props

  const settingsContext = useContext(SettingsContext)

  const selectStyles = {
    container: (provided, state) => ({
      ...provided,
      width: 200,
      marginBottom: `${settingsContext.isMobile ? '10px' : '0px'}`,
      marginRight: `${settingsContext.isMobile ? '0px' : '10px'}`
    })
  }

  const topMenuStyles = makeStyles((theme) => ({
    img: {
      width: '35px !important',
      height: '35px !important'
    },
    link: {
      marginLeft: '5px',
      cursor: 'pointer',
      marginTop: '0px'
    },
    iconList: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: `${settingsContext.isMobile ? '10px' : '0px'}`
    },
    headerWidgetMobile: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column'
    },
    topHeaderLeftWrapper: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '1.7rem'
    },
    topHeaderRightWrapper: {
      width: 700,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    }
  }))

  const classes = topMenuStyles()

  return (
    <div className='MuiPaper-root makeStyles-paper MuiPaper-elevation1 MuiPaper-rounded' style={{ padding: 12, marginBottom: 16}}>
      <div className={`${settingsContext.isMobile ? classes.headerWidgetMobile : 'headerWidget'}`}>
        <div className={classes.topHeaderLeftWrapper}>
          <span className="text-muted font-weight-light" style={{ marginRight: 4 }} color="textPrimary">{get(currentCompany, 'label')} / </span>
          Workers
        </div>

        <div className={classes.topHeaderRightWrapper}>

          <Select
            className="basic-single"
            classNamePrefix="select"
            isSearchable={true}
            onChange={onCompanyFilterChange}
            name="companies"
            value={currentCompany}
            styles={selectStyles}
            options={companies}
          />

          <SearchBox
            onSearchChange={settingsContext.onSearchChange}
            value={settingsContext.searchValue}
            onSearch={settingsContext.onSearch}
          />

          <IconsList
            onCreateWorker={onCreateWorker}
            onBulkUploadWorkers={onBulkUploadWorkers}
            company={userCompany}
            classes={classes}
            settingsContext={settingsContext}
            permissions={permissions}
            onShowDepartmentsPositions={onShowDepartmentsPositions}
          />
        </div>

      </div>
    </div>
  )
};
