import React, { useContext, useEffect } from 'react';
import { DailyActivityChart, HeadcountChart, WelcomeCard } from './components/index';
import { Tab } from '@material-ui/core';
import { $axios } from '../../../helpers';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { SettingsContext } from '../../contexts/SettingsContext';
import Grid from '@material-ui/core/Grid';
import { CardDash } from '../../components';

export default function TopBanner(props) {

  const settingsContext = useContext(SettingsContext);
  const { user, companyEndpoint, project, timesheetEndpoint, clearTimesheetData, disableChartTrigger, resetDisableChartTrigger } = props;
  const [activeTab, setActiveTab] = React.useState('head_counts');
  const [dailyActivity, setDailyActivity] = React.useState([]);
  const [headCount, setHeadCount] = React.useState([]);
  const handleTabChange = (event, newValue) => { resetDisableChartTrigger(); setActiveTab(newValue); };

  function fetchDailyActivity() {
    $axios.$get(`${companyEndpoint}/project-activity/${project._id.$oid}/${settingsContext.onGetTimeSheetDateFormat()}`)
        .then(setDailyActivity);
  }

  function fetchHeadCount() {
    $axios.$get(`${timesheetEndpoint}/activityTrack/${project._id.$oid}/${settingsContext.onGetTimeSheetDateFormat()}/15`)
        .then(setHeadCount);
  }

  useEffect(fetchDailyActivity, [settingsContext.timesheetDate]);
  useEffect(fetchHeadCount, [settingsContext.timesheetDate]);

  return (
      <TabContext value={activeTab}>
        <CardDash
            title={'Welcome ' + user.first_name}
            actions={
              <TabList indicatorColor={'primary'} onChange={handleTabChange}>
                <Tab label="HeadCounts" value="head_counts" classes={{ root: 'p-a-0' }} />
                <Tab label="Daily Activity" value="daily_activity" classes={{ root: 'p-a-0' }} />
              </TabList>
            }
            accordionDetailStyles={{ padding: 16}}
          >
          <Grid alignContent={'center'} alignItems={'center'} container wrap='nowrap'>
            <Grid xs={1} item style={{ minWidth: 140 }}>
              <WelcomeCard user={user}  gaugeData={settingsContext.stats} />
            </Grid>
            <Grid xs={true} item>
              <TabPanel value="head_counts" style={{ padding: 0 }}>
                <HeadcountChart data={headCount} clearTimesheetData={clearTimesheetData} disableChartTrigger={disableChartTrigger} />
              </TabPanel>
              <TabPanel value="daily_activity">
                <DailyActivityChart data={dailyActivity} time={settingsContext.onGetTimeSheetDateFormat()} />
              </TabPanel>
            </Grid>
          </Grid>
        </CardDash>
      </TabContext>
  );
}
