import React, { useContext, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { get, has, isEmpty } from 'lodash';
import { ProjectSettings, SyncTimeSheetsCMIC, TimeRules, TopHeaderPage } from './components/index';
import { ProjectMetrics, ProjectTimesheet, TopBanner, VendorList } from './mainComponents/index';
import GoogleMapWidget from '../GoogleMapWidget';
import WeatherWidget from '../WeatherWidget';
import { NotificationContainer } from 'react-notifications';
import RevokeList from '../workers/RevokeList';
import BroadcastingTool from './mainComponents/broadcastingTool/broadcastingTool';
import { SettingsContext } from './contexts/SettingsContext';
import Grid from '@material-ui/core/Grid';
import { ProjectDevices } from './mainComponents/projectDevices/projectDevices';
import { OrganizationChart } from "./mainComponents/orgChart/orgChart";

export default function ProjectDashboard({ props }) {

  const {
    project,
    user,
    company,
    config: {
      companyEndpoint,
      timesheetEndpoint,
      project_endpoint,
      weatherIconBaseURL,
      images_path,
      integrations_endpoint,
      webSocketsUrl,
      project_workSummary_endpoint,
      project_integrations_endpoint
    },
  } = props;

  const settingsContext = useContext(SettingsContext);

  const [state, setState] = useState({
    loading: false,
    showSettings: false,
    showTimeRules: false,
    clearTimesheetTrigger: null,
    disableChartTrigger: null
  });

  const clearTimesheetData = () => {
    setState(prevState => ({
      ...prevState,
      clearTimesheetTrigger: !prevState.clearTimesheetTrigger,
      disableChartTrigger: !prevState.disableChartTrigger,
    }));
  }

  const onShowSettings = (flag = false, reload = false) => {
    setState(prevState => ({
      ...prevState,
      showSettings: flag,
    }));

    if (reload) {
      window.location.reload();
    }
  };

  const onShowTimeRules = (flag = false) => {
    setState(prevState => ({
      ...prevState,
      showTimeRules: flag,
    }));
  };

  const resetDisableChartTrigger = () => {
    setState(prevState => ({
      ...prevState,
      disableChartTrigger: null
    }));
  }

  return (<>
    {state.loading && (<div>
      <CircularProgress color="primary" />
    </div>)}

    <TopHeaderPage
      clearTimesheetData={clearTimesheetData}
      project={project}
      company={company}
      onShowSettings={onShowSettings}
      onShowTimeRules={onShowTimeRules}
    />

    {state.showSettings &&
      <ProjectSettings
        project_endpoint={project_endpoint}
        project={get(settingsContext, 'uiConfig.data.project', {})}
        user={user}
        company={company}
        openSettings={state.showSettings}
        onShowSettings={onShowSettings}
      />}
    {state.showTimeRules &&
      <TimeRules
        project_endpoint={project_endpoint}
        project={project}
        user={user}
        company={company}
        config={props.config}
        images_path={images_path}
        openRules={state.showTimeRules}
        onShowTimeRules={onShowTimeRules}
      />}

    <Grid spacing={2} container className="m-b-3">
      <Grid xs={12} item>
        <TopBanner
          user={user}
          companyEndpoint={companyEndpoint}
          timesheetEndpoint={timesheetEndpoint}
          project={project}
          clearTimesheetData={clearTimesheetData}
          disableChartTrigger={state.disableChartTrigger}
          resetDisableChartTrigger={resetDisableChartTrigger}
        />
      </Grid>

      <Grid xs={12} md={6} item>
        {get(settingsContext, 'uiConfig.data.project') && <GoogleMapWidget project={get(settingsContext, 'uiConfig.data.project')} />}
      </Grid>
      <Grid xs={12} md={6} item>
        <WeatherWidget
          marginTopIndicators={true}
          projectData={project}
          weatherApiBaseURL={project_endpoint}
          weatherIconBaseURL={weatherIconBaseURL}
          class="weather_widget panel panel-hover" />
      </Grid>

      {(get(settingsContext, 'uiConfig.ui.modules.incidents.permissions.canEdit') || get(settingsContext, 'uiConfig.ui.modules.toolBoxTalk.permissions.canEdit') || get(settingsContext, 'uiConfig.ui.modules.workSummary.permissions.canEdit')) &&
        <Grid xs={12} item>
          <ProjectMetrics
            project={project}
            project_endpoint={project_workSummary_endpoint}
            company={company}
            config={props.config}
            images_path={images_path}
            user={user} />
        </Grid>
      }

      {
        !isEmpty(settingsContext.revokeList) &&
        <Grid xs={12} item>
          <RevokeList config={props.config} project={project} company={company} revokeList={settingsContext.revokeList} />
        </Grid>
      }

      {
        get(project, 'integrations.procore', false) &&
        <Grid xs={12} item>
          <VendorList
            user={user}
            timesheet_date={sessionStorage.getItem(get(project, '_id.$oid'))}
            project={project}
            company={company}
            integrations_endpoint={integrations_endpoint}
            webSocketsUrl={webSocketsUrl}
          />
        </Grid>
      }

      {
        has(project, 'integrations.cmic') &&
        <Grid xs={12} item>
          <SyncTimeSheetsCMIC
            user={user}
            timesheet_date={settingsContext.timesheetDate}
            project={project}
            company={company}
            integrations_endpoint={integrations_endpoint}
            project_integrations_endpoint={project_integrations_endpoint}
            webSocketsUrl={webSocketsUrl}
          />
        </Grid>
      }

      {
        get(settingsContext, 'uiConfig.ui.modules.timesheet.enabled') &&
        <Grid xs={12} item>
          <ProjectTimesheet
            project={project}
            employeeCompany={get(company, '_id.$oid')}
            config={props.config}
            user={user}
            clearTimesheetTrigger={state.clearTimesheetTrigger}
          />
        </Grid>
      }

      {
        get(settingsContext, 'uiConfig.ui.modules.broadcast.enabled') &&
        <Grid xs={12} md={6} item>
          <BroadcastingTool height="400px" project={project} config={props.config} />
        </Grid>
      }

      {
        get(settingsContext, 'uiConfig.ui.modules.devices.enabled') &&
        <Grid xs={12} md={6} item>
          <ProjectDevices height="400px" project={project} config={props.config} />
        </Grid>
      }

      {
        <Grid xs={12} item>
          <OrganizationChart height="400px" project={project} config={props.config} />
        </Grid>
      }
    </Grid>

    <NotificationContainer />
  </>);
};
