
import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import SettingsContextProvider from './contexts/SettingsContext';
import { myTheme } from '../helpers';
import { notificationCenter } from '../helpers/notifications';
import ProjectDashboard from './ProjectDashboard';
import { getUiConfiguration, getRevokeList } from './services';
import { get, isNull, isUndefined } from 'lodash';
import { DateTime } from 'luxon';

export default function RenderProjectDashboard(props) {

  const [uiConfig, setUiConfig] = useState({});
  const [revokeList, setRevokeList] = useState([]);

  const getUiConfig = async () => {
    try {
      const res = await getUiConfiguration(get(props, 'config.dashboardEndpoint'), get(props, 'project._id.$oid'));

      if (get(res, 'data.data.crews', []).length) {
        get(res, 'data.data.crews', []).push({ name: 'Non-crew workers', _id: 'NON_CREW' })
      }

      setUiConfig(get(res, 'data'));
    } catch (error) {
      notificationCenter("error", get(error, 'response.data.error', 'Error getting UI configuration.'));
    }
  }

  const getInitRevokeList = async () => {
    try {
      const res = await getRevokeList(get(props, 'config.revoke_workers_endpoint'));
      setRevokeList(get(res, 'data'));
    } catch (error) {
      notificationCenter("error", get(error, 'response.data.error', 'Error getting revoke list.'));
    }
  }

  useEffect(() => {
    getUiConfig()
    getInitRevokeList()

    if (isNull(sessionStorage.getItem(get(props, 'project._id.$oid'))))
      sessionStorage.setItem(get(props, 'project._id.$oid'), DateTime.now())
  }, [])

  return (
    <ThemeProvider theme={myTheme}>
      <SettingsContextProvider projectId={get(props, 'project._id.$oid')} uiConfig={uiConfig} revokeList={revokeList}>
        <ProjectDashboard props={props} />
      </SettingsContextProvider>
    </ThemeProvider>
  )

}
