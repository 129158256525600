import React, { useState } from 'react'
import { Modal } from "react-bootstrap";

const ModalInfo = ({ title, data, usePre }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleOpen = () => {
    setShow(true)
  };



  if (!show) {
    return <i className="fa fa-info" onClick={handleOpen}></i>
  } else {
    let jsx = data
    if (usePre) {
      jsx = (
        <pre>
          {JSON.stringify(data, null, 1)}
        </pre>
      )
    }
    return (
      <>
        <i className="fa fa-info" onClick={handleOpen}></i>
        <Modal size="sm" show={show} onHide={handleClose} animation={false} backdrop={true} backdropClassName="modal-backdrop fade in">
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {jsx}
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default ModalInfo;