import React, { useState } from 'react';
import { Avatar, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import workerProfileIcon from '../../../../../assets/images/worker_types/constructionWorkerIcon-2023.svg'

import { get, has } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 280,
    border: "1px solid #9c9c9c2e",
    overflow: 'scroll',
    backgroundColor: theme.palette.background.paper,
  },
  actionRoot: {
    top: '60%'
  }
}));

export default function CrewWorkers(props) {

  const { workers, title = "Crew Workers", onDeleteFinalCrew } = props;
  const classes = useStyles();

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
      <strong>{title}</strong>
      <List dense className={classes.root}>
        {workers.map((value, idx) => (
          <ListItem key={idx} button>
            <Avatar src={has(value, 'image_path') ? get(value, 'image_path') : workerProfileIcon} />
            <ListItemText style={{ marginLeft: '5px', width: '78px' }} primary={`${get(value, 'first_name')} ${get(value, 'last_name')}`} />
            <ListItemText primary={get(value, 'phone_number')} />
            <ListItemText primary={get(value, 'company.name')} />

            <ListItemSecondaryAction className={classes.actionRoot}>
              <RemoveCircleIcon onClick={() => onDeleteFinalCrew(value, get(value, 'company._id'))} style={{ color: "#d0021c", cursor: 'pointer', fontSize: '20px' }} />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  )
};
