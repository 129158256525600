import React, { useState, useEffect, useContext } from 'react'
import { get, size, isEmpty, has, isNull, isEqual } from 'lodash'
import { DateTime } from 'luxon'
import { makeStyles } from '@material-ui/core/styles'
import Axios from 'axios'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import useAutocomplete from '@material-ui/lab/useAutocomplete'
import ToggleButton from '@material-ui/lab/ToggleButton'

import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import LuxonUtils from '@date-io/luxon'

import Close from '@material-ui/icons/Close'
import ClockIcon from '@material-ui/icons/AccessTime'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'

import { axiosConfig } from '../../../../../helpers/index'
import { notificationCenter } from '../../../../../helpers/notifications'
import { SettingsContext } from '../../../../contexts/SettingsContext'

export default function MigrateShiftToProject(props) {

  const settingsContext = useContext(SettingsContext)

  const { config, project, showMigrateShiftToProjectDialog, setShowMigrateShiftToProjectDialog, handleConfirmMigrateShiftToProject } = props

  const useStyles = makeStyles((theme) => ({
    newWorkerPunchDialogTitle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: 'center',
      fontSize: '17px',
      backgroundColor: '#f6f5f7',
      transition: 'height 0.15s ease-out'
    },
    newWorkerPunchDialogActions: {
      justifyContent: 'flex-end',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    newWorkerPunchDialogBody: {
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingTop: 0,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    closeModalButton: {
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    projectsDropDownList: {
      margin: '2px 0 0',
      padding: 0,
      listStyle: 'none',
      backgroundColor: '#fff',
      overflow: 'auto',
      maxHeight: 150,
      borderRadius: 4,
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      zIndex: 999999,
      position: 'absolute',

      '& li': {
        padding: '5px 12px',
        display: 'flex',
        justifyContent: 'space-between'
      },

      '& li[data-focus="true"]': {
        backgroundColor: 'rgba(241, 89, 34, 0.1)',
        cursor: 'pointer',
      }
    },
    projectToMigrateSelect: {
      width: 300
    },
    radiusSelect: {
      width: 100
    },
    projectSelect: {
      width: 400,
      marginRight: 15,
      '& .MuiOutlinedInput-input': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      },
    },
    fetchingMigrateToProjectsLoader: {
      width: 'calc(100% - 105px)',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center'
    }
  }))

  const classes = useStyles();

  const [selectedMigrateProject, setSelectedMigrateProject] = useState("")
  const [selectedRadius, setSelectedRadius] = useState(10)
  const [migrateToProjects, setMigrateToProjects] = useState([])
  const [fetchingMigrateToProjects, setFetchingMigrateToProjects] = useState(true)
  const [showMigrateConfirmation, setShowMigrateConfirmation] = useState(false)

  useEffect(() => {
    setSelectedMigrateProject("")
    getMigrateToProjects()
    return () => { }
  }, [selectedRadius])

  const getMigrateToProjects = async () => {
    setFetchingMigrateToProjects(true)

    try {
      const res = await Axios.get(`${get( config, 'timesheetEndpoint')}/migrateDestinations/${get(project, '_id.$oid')}/${selectedRadius}`, axiosConfig());

      setMigrateToProjects(get( res, 'data', []))
      setFetchingMigrateToProjects(false)
    } catch (error) {
      setFetchingMigrateToProjects(false)
      notificationCenter("error", `Error getting project to migrate to. ${get(error, 'response.data.error')}`)
    }
  }


  const renderProjectSelect = () => {
    return (
      <Select
        InputProps={{
            startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
          }}
        disabled={fetchingMigrateToProjects}
        value={selectedMigrateProject}
        className={classes.projectSelect}
        variant='outlined'
        onChange={(event) => setSelectedMigrateProject(event.target.value)}>
        {
          migrateToProjects.map(item =>
            <MenuItem key={item} value={item._id} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant='subtitle2'>{get( item, 'name')}</Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <DirectionsCarIcon style={{ color: 'grey', marginRight: 8 }} />
                <Typography variant='caption' style={{ color: 'grey', width: 60 }}>{Math.round(get( item, 'distance'))} mi.</Typography>
              </div>
            </MenuItem>
          )
        }
        { size(migrateToProjects) <= 0 &&
          <MenuItem disabled>
            <Typography variant='subtitle2'>No Projects Found</Typography>
          </MenuItem>
        }
      </Select>
    )

    const {
      getRootProps,
      getInputProps,
      getListboxProps,
      getOptionProps,
      getClearProps,
      groupedOptions,
      value,
      focused,
      setAnchorEl,
    } = useAutocomplete({
      id: 'migrateToProjectSelect',
      multiple: false,
      options: migrateToProjects,
      getOptionLabel: (option) => get( option, 'name', ''),
      onChange: (event, value) => setSelectedMigrateProject(value)
    })

    return (
      <FormControl style={{ padding: 16 }} fullWidth>
        <div>
          <div {...getRootProps()}>
            <div ref={setAnchorEl} className={focused ? 'focused' : ''}>
              <TextField
                {...getInputProps()}
                variant="outlined"
                label="Select project"
                style={{ width: '100%' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      onClick={(e) => { getInputProps().onBlur(); getClearProps().onClick(); setSelectedMigrateProject('') }}
                      position='end'>
                      { fetchingMigrateToProjects ?
                        <CircularProgress size={15} color='primary' /> :
                          selectedMigrateProject !== '' && !isNull(selectedMigrateProject) ?
                          <Close /> : <div />
                          }
                        </InputAdornment>
                      )
                    }}
                    />
                </div>
              </div>
              { groupedOptions.length > 0 ? (
                <ul {...getListboxProps()} className={classes.projectsDropDownList}>
                  {groupedOptions.map((option, index) => (
                    <li {...getOptionProps({ option, index })}>
                      <Typography variant='subtitle2'>{get( option, 'name')}</Typography>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <DirectionsCarIcon style={{ color: 'grey', marginRight: 8 }} />
                        <Typography variant='caption' style={{ color: 'grey', width: 60 }}>{Math.round(get( option, 'distance'))} mi.</Typography>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : null }
            </div>
          </FormControl>
        )
      }

  const renderRadiusSelect = () => {

    return (
      <FormControl style={{ width: 50 }}>
        <Select
          disabled={fetchingMigrateToProjects}
          variant="outlined"
          className={classes.radiusSelect}
          value={selectedRadius}
          onChange={(e) => setSelectedRadius(e.target.value)}>
          {
            [10,20,30,40,50,60,70,80,90,100,110,120,130,140,150,1000].map(item =>
              <MenuItem key={item} value={item}>
                <div>
                  {item} mi.
                </div>
              </MenuItem>
            )
          }
        </Select>
      </FormControl>
        )
      }

      const handleCloseMigrateShiftToProjectDialog = () => {
        if (showMigrateConfirmation) {
          setShowMigrateConfirmation(false)
        } else {
          setShowMigrateShiftToProjectDialog(false)
        }
      }

      const handleConfirm = () => {
        if (showMigrateConfirmation) {
          handleConfirmMigrateShiftToProject(selectedMigrateProject)
        } else {
          setShowMigrateConfirmation(true)
        }
      }

      return (
        <Dialog maxWidth='sm' open={showMigrateShiftToProjectDialog} onClose={handleCloseMigrateShiftToProjectDialog} fullWidth>
          <DialogTitle disableTypography={true} className={classes.newWorkerPunchDialogTitle}>
            <Typography variant="h6">Migrate Shift To Project</Typography>
            <IconButton onClick={handleCloseMigrateShiftToProjectDialog} size='small' className={classes.closeModalButton}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.newWorkerPunchDialogBody} style={{ position: 'relative' }}>
            <div style={{ width: '100%', height: 40, zIndex: 0, position: 'absolute', top: 0, left: 0, backgroundColor: "#f6f5f7" }} />
            <Paper style={{ zIndex: 2 }}>
              { !showMigrateConfirmation &&
                <div style={{ width: '100%' }}>
                  <div style={{ display: 'flex', alignItems: 'center', borderBottom: "2px solid #f6f5f7", padding: 16 }}>
                    { fetchingMigrateToProjects ? <div className={classes.fetchingMigrateToProjectsLoader}><CircularProgress size={20} color='primary' /></div> : renderProjectSelect() }
                    { renderRadiusSelect() }
                  </div>
                </div>
              }
              { showMigrateConfirmation &&
                <div style={{ width: '100%' }}>
                  <div style={{ display: 'flex', alignItems: 'center', borderBottom: "2px solid #f6f5f7", padding: 16 }}>
                    <span>This will migrate the shift to project <strong>{get( migrateToProjects.filter(oneProject => isEqual( get( oneProject, '_id'), selectedMigrateProject))[0], 'name')}</strong> on the date <strong>{DateTime.fromISO(settingsContext.timesheetDate).toFormat('DDD')}</strong></span>
                  </div>
                </div>
              }
            </Paper>
          </DialogContent>
          <DialogActions className={classes.newWorkerPunchDialogActions}>
            <Button variant="outlined" onClick={handleCloseMigrateShiftToProjectDialog}>
              { showMigrateConfirmation ? 'Back' : 'Cancel' }
            </Button>
            <Button disabled={isEmpty(selectedMigrateProject)} variant="outlined" onClick={handleConfirm} color="primary">
              { showMigrateConfirmation ? 'Confirm' : 'Migrate' }
            </Button>
          </DialogActions>
        </Dialog>
      )
    }
