import React, { useState, useContext, useEffect } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import { isEmpty, get, isEqual, isNull } from 'lodash';
import { SettingsContext } from '../../../contexts/SettingsContext';
import { DateTime } from 'luxon';

const generateArrayOfHexColors = (size) => {
  let hexColorsArray = []

  let randomInteger = (max) => {
    return Math.floor(Math.random()*(max + 1))
  }

  let randomRgbColor = () => {
    let r = randomInteger(255)
    let g = randomInteger(255)
    let b = randomInteger(255)

    return [r,g,b]
  }

  let randomHexColor = () => {
    let [r,g,b] = randomRgbColor()

    let hr = r.toString(16).padStart(2, '0')
    let hg = g.toString(16).padStart(2, '0')
    let hb = b.toString(16).padStart(2, '0')

    return "#" + hr + hg + hb
  }

  for (let i = 0; i < size; i++) {
    hexColorsArray.push(randomHexColor())
  }

  return hexColorsArray
}

// generate array of 100 hex colors (can be changed to any size later)
const palette = generateArrayOfHexColors(100)

export default function HeadcountChart({ data, clearTimesheetData, disableChartTrigger }) {

  const useStyles = makeStyles(() => ({
    barChartFilterInput: {
      '& .MuiOutlinedInput-input': {
        padding: 8
      },
    }
  }));

  const classes = useStyles();
  const settingsContext = useContext(SettingsContext);

  const [selectedCompanies, setSelectedCompanies] = React.useState({});
  const [barDataSet, setBarDataSet] = React.useState([])
  const [colorMap, setColorMap] = React.useState({})
  const [barChartFilter, setBarChartFilter] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const onHandleClick = e => { };

  const handleBarClick = bar => {
    if (!isEqual(settingsContext.onGetTimeSheetDateFormat('yyyyMMdd'), get(bar, 'payload.originalDate'))) {
      setLoading(true)
      clearTimesheetData()
      settingsContext.onChangeTimeSheetDate(DateTime.fromFormat(get(bar, 'payload.originalDate'), 'yyyyMMdd'));
    }
  };

  const handleCompanyChartCheckboxClick = (event) => {
    setSelectedCompanies(prevSelectedCompanies => ({
      ...prevSelectedCompanies,
      [get(event, 'target.value', '')]: !prevSelectedCompanies[get(event, 'target.value', '')]
    }))
  }

  const handleCompanyChartNameClick = (companyName) => {
    setSelectedCompanies(prevSelectedCompanies => ({
      ...prevSelectedCompanies,
      [companyName]: !prevSelectedCompanies[companyName]
    }))
  }

  const handleFilterBarChartChange = (event) => {
    setBarChartFilter(event.target.value)
  }

  const generateCompanyStackedBars = () => {
      let array = []

      Object.keys(selectedCompanies)
      .filter(oneKey => selectedCompanies[oneKey])
      .map(oneCompanyName => get(data, 'attendance', []).filter(oneAttendanceCompany => oneAttendanceCompany.name === oneCompanyName))
      .map(oneCompany => array.push(<Bar dataKey={get(oneCompany[0], 'name', '')} stackId="a" stroke={get(colorMap, get(oneCompany[0], 'name'))} fill={get(colorMap, get(oneCompany[0], 'name'))} cursor="pointer" onClick={handleBarClick} />))

      return array
  }

  const createBarDataSet = () => {
    const barDataSet = []
    const colorsMap = {}

    Object.keys(get(data, 'datesMap', {})).map(oneDate => {
      let Total = 0
      let tempObj = {
        ...get(data, `datesMap.${oneDate}`)
      }

      get(data, 'attendance', []).map((oneAttendanceCompany, index) => {
        Total += get(oneAttendanceCompany, `datesMap.${oneDate}.employees`, 0)
        tempObj[get(oneAttendanceCompany, 'name')] = get(oneAttendanceCompany, `datesMap.${oneDate}.employees`, 0)
        colorsMap[get(oneAttendanceCompany, 'name')] = palette[index]
      })

      tempObj.Total = Total
      tempObj.originalDate = oneDate
      barDataSet.push(tempObj)
    })

    setSelectedCompanies(prevSelectedCompanies => {
      Object.keys(prevSelectedCompanies).map(oneKey => {
        prevSelectedCompanies[oneKey] = false
      })

      return prevSelectedCompanies
    })

    setLoading(false)
    setColorMap(colorsMap)
    setBarDataSet(barDataSet)
  }

  useEffect(createBarDataSet, [data])
  useEffect(() => { if(!isNull(disableChartTrigger)) setLoading(true) }, [disableChartTrigger])

  return (<Grid container spacing={2} wrap='nowrap' style={{ pointerEvents: loading ? 'none' : 'auto', filter: loading ? 'grayscale(70%)' : 'none', transition: 'filter 0.3s ease-in-out' }}>
    {/*div className="text-uppercase4">HEADCOUNT LAST 30 DAYS</div>*/}

      <Grid item xs={10}>
        <ResponsiveContainer height={230}>
          <BarChart data={barDataSet} margin={{ top: 10 }}>
            <CartesianGrid strokeDasharray="2 4" />
            <XAxis dataKey="label" height={20} />
            <YAxis width={30} />
            <Tooltip />
            {
              isEmpty(Object.keys(selectedCompanies).map(oneCompanyName => selectedCompanies[oneCompanyName]).filter(oneCompanySelected => oneCompanySelected === true)) ?
              <Bar dataKey="Total" stroke="#f15922" fill="#f15922" cursor="pointer" onClick={handleBarClick} /> : generateCompanyStackedBars()
            }
          </BarChart>
        </ResponsiveContainer>
      </Grid>
      <Grid item xs={true}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField onChange={handleFilterBarChartChange} variant="outlined" value={barChartFilter} className={classes.barChartFilterInput} placeholder='Search'/>
          <div style={{ maxHeight: 195, overflow: 'scroll' }}>
            {
              get(data, 'attendance', []).filter(oneCompany => get(oneCompany, 'name').toLowerCase().indexOf(barChartFilter) !== -1).map(oneCompany =>
                <MenuItem key={get(oneCompany, 'name')} dense style={{ padding: 0 }}>
                  <div style={{ width: '100%', display: 'flex', alignItems: 'center' }} onClick={() => { handleCompanyChartNameClick(get(oneCompany, 'name')) }}>
                    <Checkbox value={get(oneCompany, 'name')} style={{ color: get(colorMap, get(oneCompany, 'name')) }} checked={get(selectedCompanies, get(oneCompany, 'name'), false)} />
                    <ListItemText primary={get(oneCompany, 'name')} />
                  </div>
                </MenuItem>
              )
            }
          </div>
        </div>
      </Grid>
  </Grid>)
}
