import React, { memo, useContext, useEffect, useState } from 'react';

import { cloneDeep, get, has, isEmpty, isNull, isUndefined, size } from 'lodash';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { DateTime } from 'luxon'
import momentTimezone from 'moment-timezone';
import Carousel from 'react-material-ui-carousel';
import Axios from 'axios';
import { axiosConfig } from '../helpers';
import { SettingsContext } from '../projectDashboard/contexts/SettingsContext';

export default memo(function WeatherWidget({ projectData, weatherApiBaseURL, weatherIconBaseURL, date, marginTopIndicators, mainWrapperStyles = {}, loadingSectionStyles = {} }) {
    const backgroundColor = '#334F64';

    let useStyles = makeStyles((theme) => ({
        root: {},
        mainWrapper: {
            backgroundColor,
            //day
            // background: 'rgb(64,136,180)',
            // background: 'linear-gradient(180deg, rgba(64,136,180,1) 0%, rgba(117,168,200,1) 100%)',
            //night
            // background: 'rgb(1,5,32)',
            // background: 'linear-gradient(180deg, rgba(1,5,32,1) 0%, rgba(50,59,86,1) 100%)',
            borderRadius: 4,
            height: 269, // TODO: confirm this later
            minWidth: 290, // TODO:  confirm this later
            ...mainWrapperStyles
        },
        loadingSection: {
            width: '100%',
            height: 269,
            display: 'flex',
            backgroundColor,
            // zIndex: '9999999',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: 13,
            ...loadingSectionStyles
        },
        hourlyWeatherWrapper: {
            cursor: 'pointer',
            height: 100, // TODO: confirm this later
            width: 90, // TODO: confirm this later
        },
        smallText: {
            color: '#f5f5f6',
            fontSize: 13,
            fontWeight: 600,
            fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
            textAlign: 'center',
        },
        smallerText: {
            color: '#f5f5f6',
            fontSize: 11,
            fontWeight: 500,
            fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
            textAlign: 'center',
        },
        projectTimeText: {
            color: '#f5f5f6',
            fontSize: 13,
            fontWeight: 600,
            fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
            '&:hover': {
                // textDecoration: 'underline',
            },
        },
        currentWeatherText: {
            fontSize: 14,
            marginTop: -20,
            marginBottom: -5,
            color: '#f5f5f6',
            fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
        },
        currentTempText: {
            fontSize: 15,
            color: 'white',
            fontFamily: 'Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
            position: 'absolute',
            top: '20%',
            right: '-10%',
            // left: '45%',
            // width: '100%',
            fontWeight: 600,
        },
        windDirectionCirlce: {
            width: 60,
            height: 60,
            border: '3px solid white',
            borderRadius: '100%',
        },
        windDirectionArrow: {
            fontSize: 52,
            color: 'white',
            transform: `rotate(${windArrowTranslationMapping[has(weatherData, 'hourly_weather') ? get(nowWeatherData, 'wind_direction', '') : '']}deg)`,
            animation: `$swivelWindDirection 3000ms ${theme.transitions.easing.easeInOut}`,
            animationDelay: '1s',
        },
        '@keyframes swivelWindDirection': {
            '50%': {
                transform: `rotate(${windArrowTranslationMapping[has(weatherData, 'hourly_weather') ? get(nowWeatherData, 'wind_direction', '') : ''] - 35}deg)`,
            },
            '70%': {
                transform: `rotate(${windArrowTranslationMapping[has(weatherData, 'hourly_weather') ? get(nowWeatherData, 'wind_direction', '') : ''] + 25}deg)`,
            },
            '85%': {
                transform: `rotate(${windArrowTranslationMapping[has(weatherData, 'hourly_weather') ? get(nowWeatherData, 'wind_direction', '') : ''] - 15}deg)`,
            },
        },
    }));

    const [weatherData, setWeatherData] = useState({});
    const [nowWeatherData, setNowWeatherData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [futureWeatherClicked, setFutureWeatherClicked] = useState(false);

    const settingContext = useContext(SettingsContext);

    const formattingDate = () => {
        return isNull(get(settingContext, 'timesheetDate')) || isUndefined(get(settingContext, 'timesheetDate')) ? moment().format('YYYYMMDD') : settingContext.onGetTimeSheetDateFormat('yLLdd');
    };

    useEffect(() => {

        if (!isNull(get(settingContext, 'timesheetDate'))) {
            getWeatherData();
        }
    }, [get(settingContext, 'timesheetDate')]);

    const getWeatherData = () => {
        setLoading(true);
        setError(false);

        const id = has(projectData, '_id.$oid') ? get(projectData, '_id.$oid') : projectData._id
        let apiURL = `${weatherApiBaseURL}/getWeather/${id}/${formattingDate()}`

        Axios.get(apiURL, axiosConfig()).then(response => {
            setNowWeatherData(response.data.hourly_weather[0]);
            setWeatherData(response.data);
            setLoading(false);
        }).catch(error => {
            console.log('Error fetching weather data: ', error);
            setLoading(false);
            setError(true);
        });
    };

    const getWindspeedAndDirection = () => {
        return `${get(nowWeatherData, 'wind')} ${get(nowWeatherData, 'wind_unit') === 'mi/h' ? 'mph' : 'kph'} ${get(windDirectionMapping, get(nowWeatherData, 'wind_direction'))}`;
    };

    const buildWeatherIconUrl = (weather_number) => {
        return weatherIconBaseURL + get(weatherData, 'weather_image_path') + `/${weather_number}.svg`;
    };

    const renderSingleHourlyWeather = (hourlyWeatherData) => {
        return (
            <Box display="flex" flexDirection="column" alignItems="center" onClick={() => {
                setFutureWeatherClicked(true);
                setNowWeatherData(hourlyWeatherData);
            }} className={classes.hourlyWeatherWrapper}>
                <Typography
                    className={classes.smallerText}>{DateTime.fromSeconds(get(hourlyWeatherData, 'time'), { zone: 'utc' }).setZone(get(projectData, 'timezone', 'America/New_York')).toFormat('h a')}</Typography>
                <img src={buildWeatherIconUrl(get(hourlyWeatherData, 'weather_number'))} width="34px" />
                <Typography className={classes.smallerText}
                    style={{ height: 60 }}>{get(hourlyWeatherData, 'weather', '')}</Typography>
                <Typography
                    className={classes.smallerText}>{`${get(hourlyWeatherData, 'temp')}° ${get(hourlyWeatherData, 'temp_unit')}`}</Typography>
            </Box>
        );
    };

    const loadingSection = () => (
        <Box className={classes.loadingSection}>
            <CircularProgress style={{ color: 'white' }} />
        </Box>
    );

    const renderError = () => (
        <Box display="flex" flexDirection="column" className={classes.loadingSection}>
            <ErrorOutlineIcon style={{ fontSize: '54px' }} color="error" />
            <Box mb={1} />
            <Typography className={classes.smallText}>Failed to fetch weather data</Typography>
            <Box mb={1} />
            <Typography onClick={() => {
                getWeatherData();
            }} className={classes.smallText}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}>Retry</Typography>
        </Box>
    );

    const renderNoWeatherData = () => (
        <Box display="flex" flexDirection="column" className={classes.loadingSection}>
            <Box mb={1} />
            <Typography className={classes.smallText}>No weather data</Typography>
            <Box mb={1} />
        </Box>
    );

    const getCarouselData = () => {
        let hourlyWeather = cloneDeep(weatherData).hourly_weather;
        let carouselData = [];

        hourlyWeather.splice(0, 1);

        while (!isEmpty(hourlyWeather) && size(hourlyWeather) >= 3) {
            carouselData.push(
                <Box key={hourlyWeather[0].time} display="flex" justifyContent="space-between">
                    <Box />
                    {renderSingleHourlyWeather(hourlyWeather[0])}
                    <Box borderLeft="1px solid #5e707f" />
                    {renderSingleHourlyWeather(hourlyWeather[1])}
                    <Box borderLeft="1px solid #5e707f" />
                    {renderSingleHourlyWeather(hourlyWeather[2])}
                    <Box />
                </Box>,
            );

            hourlyWeather.splice(0, 3);
        }

        return carouselData;
    };

    useEffect(() => {
        getWeatherData();
        return () => {
        };
    }, []);

    const classes = useStyles();

    return (
        <Box display="flex" flexDirection="column" className={classes.mainWrapper}>
            {loading && loadingSection()}
            {!loading && error && renderError()}
            {!loading && !error && isEmpty(weatherData.hourly_weather) && renderNoWeatherData()}
            {!loading && !error && !isEmpty(weatherData.hourly_weather) &&
                <Box p={1}>
                    <Box display="flex" position="relative" justifyContent="center">
                        <Box position="absolute" top={0} left={0}>
                            <Box display="flex">
                                {futureWeatherClicked &&
                                    <Box mr={0.5} mt={-0.25} onClick={() => {
                                        setFutureWeatherClicked(false);
                                        setNowWeatherData(weatherData.hourly_weather[0]);
                                    }} style={{ cursor: 'pointer' }}>
                                        <img
                                            src="https://static-files.connectedbarrel.com/icons/smartbarrel-v1/clockRewind.svg"
                                            width="15px" />
                                    </Box>
                                }
                                {
                                    (has(projectData, 'timezone') && !futureWeatherClicked) && <Typography
                                        className={classes.projectTimeText}>{momentTimezone().tz(get(projectData, 'timezone', 'America/New_York')).format('hh:mm a')}</Typography>
                                }
                                {
                                    futureWeatherClicked && <Typography
                                        className={classes.projectTimeText}>{momentTimezone.unix(get(nowWeatherData, 'time')).tz(get(projectData, 'timezone', 'America/New_York')).format('h a')}</Typography>
                                }
                            </Box>
                        </Box>
                        {!isEmpty(nowWeatherData) &&
                            <Box display="flex" flexDirection="column" alignItems="center" position="absolute" top={0}
                                right={0} width="90px">
                                <Box display="flex" alignItems="center" justifyContent="center"
                                    className={classes.windDirectionCirlce} mb={1.5}>
                                    <ArrowRightAltIcon className={classes.windDirectionArrow} />
                                </Box>
                                <Typography className={classes.smallText}>{getWindspeedAndDirection()}</Typography>
                            </Box>
                        }
                        {!isEmpty(nowWeatherData) &&
                            <Box textAlign="center" position="relative" style={{ marginLeft: '-40%' }}>
                                <Box>
                                    <img width="120px"
                                        src={buildWeatherIconUrl(get(nowWeatherData, 'weather_number'))} />
                                    <Typography
                                        className={classes.currentTempText}>{`${get(nowWeatherData, 'temp')}° F`}</Typography>
                                </Box>
                                <Typography
                                    className={classes.currentWeatherText}>{get(nowWeatherData, 'weather', '')}</Typography>
                            </Box>
                        }
                    </Box>
                </Box>
            }
            {!loading && !error && has(weatherData, 'hourly_weather') && !isEmpty(weatherData.hourly_weather) &&
                <Box borderTop="1px solid #5e707f" p={1}>
                    <CustomCarousel
                        navButtonsAlwaysVisible={false}
                        animation="slide"
                        autoPlay={false}
                        timeout={200}
                        classes={ marginTopIndicators ? { indicators: 'm-t-3' } : {} }
                        NavButton={({ onClick, className, style, next, prev }) => {
                            return (
                                <NavigateNextIcon onClick={onClick} className={className}
                                    style={{ ...style, transform: prev && 'rotate(180deg)' }}>
                                    {next && 'Next'}
                                    {prev && 'Previous'}
                                </NavigateNextIcon>
                            );
                        }}>
                        {getCarouselData()}
                    </CustomCarousel>
                </Box>
            }
        </Box>
    );
});


const windArrowTranslationMapping = {
    E: 0,
    SE: 45,
    SSE: 45,
    ESE: 45,
    S: 90,
    SW: 135,
    SSW: 135,
    WSW: 135,
    W: 180,
    NW: 225,
    WNW: 225,
    NNW: 225,
    N: 270,
    NE: 315,
    NNE: 315,
    ENE: 315,
};

const windDirectionMapping = {
    E: 'E',
    SE: 'SE',
    SSE: 'SE',
    ESE: 'SE',
    S: 'S',
    SW: 'SW',
    SSW: 'SW',
    WSW: 'SW',
    W: 'W',
    NW: 'NW',
    WNW: 'NW',
    NNW: 'NW',
    N: 'N',
    NE: 'NE',
    NNE: 'NE',
    ENE: 'NE',
};

const CustomCarousel = withStyles((theme) => ({
    root: {
        position: 'relative',
    },
    indicators: {
        width: '100%',
        marginTop: 3,
        marginBottom: -4,
        textAlign: 'center',
    },
    indicator: {
        cursor: 'pointer',
        transition: '200ms',
        padding: 0,
        color: '#f5f5f6',
        '&:hover': {
            color: '#f15922',
        },
        '&:active': {
            color: '#f15922',
        },
    },
    indicatorIcon: {
        fontSize: '12px',
    },
    active: {
        color: '#f15922',
    },
    buttonWrapper: {
        position: 'absolute',
        height: '100px',
        backgroundColor: 'transparent',
        top: 'calc(50% - 70px)',
        '&:hover': {
            '& $button': {
                backgroundColor: 'transparent',
                filter: 'brightness(120%)',
                opacity: '0.4',
            },
        },
    },
    fullHeightHoverWrapper: {
        height: '100%',
        top: '0',
    },
    buttonVisible: {
        opacity: '1',
    },
    buttonHidden: {
        opacity: '0',
    },
    button: {
        margin: '0 0px',
        position: 'relative',
        backgroundColor: 'transparent',
        top: 'calc(50% - 20px) !important',
        color: 'white',
        fontSize: '24px',
        transition: '200ms',
        cursor: 'pointer',
        '&:hover': {
            opacity: '0.6 !important',
        },
    },
    next: {
        right: -5,
    },
    prev: {
        left: -5,
    },
}))(Carousel);

// WeatherWidget.propTypes = {
//   projectData: PropTypes.object,
//   weatherApiBaseURL: PropTypes.string,
//   weatherIconBaseURL: PropTypes.string,
// }
