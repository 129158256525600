import axios from 'axios';
import { axiosConfig } from '../../helpers/index';
import { get } from 'lodash';
const employeeCards = [
  {
    cardColor: 'primary',
    imageIcon: require('../../../../assets/images/projectEmployees/project-icon.png'),
    title: '09',
    subTitle: 'Projects'
  }, {
    cardColor: 'secondary',
    imageIcon: require('../../../../assets/images/projectEmployees/tasks-icon.png'),
    title: '457',
    subTitle: 'Tasks'
  }, {
    cardColor: 'info',
    imageIcon: require('../../../../assets/images/projectEmployees/teams-icon.png'),
    title: '247',
    subTitle: 'Teams'
  }, {
    cardColor: 'warning',
    imageIcon: require('../../../../assets/images/projectEmployees/files-icon.png'),
    title: '09',
    subTitle: 'Files'
  },

]

export const gettingEmployeeCards = () => {
  return new Promise((resolve, reject) => {

    resolve(employeeCards);
  })
};

export const gettingFilters = (projectDomain) => {

  return axios.get(`${projectDomain}/listSettings`, axiosConfig())

}
export const updatingWorker = (worker, projectDomain) => {
  return axios.patch(`${projectDomain}/${get(worker, '_id')}`, { worker }, axiosConfig())
}

export const gettingWorkers = (payload, projectDomain) => {

  return axios.post(`${projectDomain}/list`, payload, axiosConfig());

}

export const promotingWorker = (payload, workerId, promoteWorkerDomain) => {

  return axios.patch(`${promoteWorkerDomain}/${workerId}/promote`, payload, axiosConfig());

}

export const demoteWorker = (workerId, promoteWorkerDomain) => {
  return axios.delete(`${promoteWorkerDomain}/${workerId}/demote`, axiosConfig());

}

export const gettingPunchDetails = (projectDomain, workerId) => {
  return axios.get(`${projectDomain}/${workerId}/activity/simple`, axiosConfig())
}

export const gettingCompanyEmployeeSettings = (companyWorkersDomain) => {
  return axios.get(`${companyWorkersDomain}/workers/listSettings`, axiosConfig())
}
export const gettingCompanyEmployees = (payload, company, companyWorkersDomain) => {

  return axios.post(`${companyWorkersDomain}/${company}/workers/list`, payload, axiosConfig());

}


export const deleteCompanyWorker = (companyWorkersDomain, workerId) => {
  return axios.delete(`${companyWorkersDomain}/workers/${workerId}`, axiosConfig());
}

export const getProjectByCompany = (projectEndpoint, companyId) => {
  return axios.get(`${projectEndpoint}/getCompanyProjects/${companyId}?limit=0&format=compact`, axiosConfig());
}

export const deleteWorkerImageProfile = (employeeSettings, dbid) => {
  return axios.delete(`${employeeSettings}/${dbid}/idCard`, axiosConfig());
}

export const deleteWorkerRfid = (employeeSettings, dbid) => {
  return axios.delete(`${employeeSettings}/${dbid}/rfid`, axiosConfig());
}

export const generateMobileAuthKey = (employeeSettings, dbid) => {
  return axios.patch(`${employeeSettings}/workers/${dbid}/mobileAuthorization`, {}, axiosConfig());
}

export const getWorkerDetails = (companyWorkersDomain, _id) => {
  return axios.get(`${companyWorkersDomain}/workers/${_id}/profile`, axiosConfig());
}

export const createWorkerCompany = (companyWorkersDomain, worker) => {
  return axios.post(`${companyWorkersDomain}/workers/`, { ...worker }, axiosConfig());
}
export const editWorkerCompany = (companyWorkersDomain, worker) => {
  return axios.patch(`${companyWorkersDomain}/workers/${get(worker, 'dbid')}`, { ...worker }, axiosConfig());
}

export const editWorkerRate = (domain, settings, workerId, project_id = null) => {
  return axios.patch(`${domain}/workers/settings/${workerId}`, { ...settings, project_id }, axiosConfig());
}

export const getPaymentProfiles = (companyId, payment_profile_endpoint) => {
  return axios.post(`${payment_profile_endpoint}/${companyId}/list/select`, {}, axiosConfig());
}

export const getUploadUrl = (fileUploadEndpoint, companyId) => {
  return axios.get(`${fileUploadEndpoint}/${companyId}/workers`, axiosConfig());
}

export const getProjectOnboardingUploadUrl = (fileUploadEndpoint, companyId, projectId) => {
  return axios.get(`${fileUploadEndpoint}/${companyId}/onboarding/${projectId}`, axiosConfig());
}

export const uploadEmployeesCsv = (signedUrl, file, options) => {
  return axios.put(signedUrl, file, options);
}

export const downloadSessionResults = (sessionResultsEndpoint, sessionId) => {
  return axios.get(`${sessionResultsEndpoint}/${sessionId}`, axiosConfig());
}

export const getUserPermissions = (promoteWorkerEndpoint, workerId) => {
  return axios.get(`${promoteWorkerEndpoint}/${workerId}/permissions`, axiosConfig());
}

export const saveUserPermissions = (promoteWorkerEndpoint, workerId, payload) => {
  return axios.patch(`${promoteWorkerEndpoint}/${workerId}/permissions`, payload, axiosConfig());
}

export const recoverWorker = (employeeSettingsEndpoint, payload) => {
  return axios.patch(`${employeeSettingsEndpoint}/recover`, payload, axiosConfig());
}
