import React, { useContext, useState } from 'react';
import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { ProjectContext } from '../../ProjectMetrics';
import { Group } from '@material-ui/icons';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const DialogEmployeeList = ({ employees = [] }) => {
  const { config: { project_workSummary_endpoint: endpoint } } = useContext(ProjectContext);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
      <>
        <Badge badgeContent={employees.length} color="primary" classes={{ badge: 'DayPicker-Month' }}>
          <IconButton component={'span'} onClick={handleOpen}>
            <Group />
          </IconButton>
        </Badge>

        <Dialog maxWidth={'sm'} open={open} onClose={handleClose} fullWidth>
          <DialogTitle className="bg-primary">Employee List</DialogTitle>
          {loading &&
              <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <CircularProgress />
              </DialogContent>
          }
          {!loading && <>
            <DialogContent>
              {employees.map(employee => (
                  <Grid key={employee._id} className="p-y-1" container>
                    <Grid xs={'auto'} className="p-r-4" item>
                      <Avatar src={employee.profilePic} />
                    </Grid>

                    <Grid alignItems="center" xs={true} style={{ display: 'flex' }} item>
                      <Typography>
                        <span>{`${employee.first_name} ${employee.last_name}`}</span>
                      </Typography>
                    </Grid>

                    <Grid alignItems="center" xs={'auto'} style={{ display: 'flex' }} item>
                      <Typography>
                        <span>{employee.phone_number}</span>
                      </Typography>
                    </Grid>
                  </Grid>
              ))}
            </DialogContent>
          </>}
        </Dialog>
      </>
  );
};

export default DialogEmployeeList;
