import React, { useContext, useEffect, useState } from 'react';
import WeatherWidget from './reusableComponents/WeatherWidget';
import { Avatar, Button, CircularProgress, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import axios from 'axios';
import { axiosConfig } from './helpers';
import { get, has, isNull } from 'lodash';
import moment from 'moment';
import GridView from '@material-ui/icons/GridOn';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { SettingsContext } from './projectDashboard/contexts/SettingsContext';
import { CardDash } from './projectDashboard/components';

const DownloadWeatherComponents = ({ onDownloadWeather, downloading }) => {

  return (
      <>
        {downloading ?
            <CircularProgress
                size={15}
                style={{ marginLeft: '5px', cursor: 'pointer', color: '#f15922' }}
                color="primary" /> :
            <CloudDownloadIcon onClick={() => onDownloadWeather()} style={{
              fontSize: '20px',
              marginLeft: '5px',
              cursor: 'pointer',
              color: '#f15922',
            }} />
        }
      </>

  );

};

export default ({ projectData, weatherApiBaseURL, weatherIconBaseURL, marginTopIndicators }) => {
  const [showList, setShowList] = useState(false);
  const [weatherData, setWeatherData] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState('');

  const settingContext = useContext(SettingsContext);


  const toggleList = () => setShowList(!showList);

  const id = has(projectData, '_id.$oid') ? get(projectData, '_id.$oid') : projectData._id;

  const loadWeatherList = (formatedDate, exportWeather = false) => {
    return axios.get(`${weatherApiBaseURL}/getWeatherList/${id}/${formatedDate}${exportWeather ? '/export_weather' : ''}`, axiosConfig())
        .then(({ data }) => {
          !exportWeather ? setWeatherData(data) : setDownloadUrl(data), setDownloading(false);

        })
        .catch(console.warn);
  };

  const weatherIcon = (number) => (`https://s3.us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/weather-widget/${number}.svg`);

  useEffect(() => {
    loadWeatherList(moment().format('YYYYMMDD'));
  }, []);

  useEffect(() => {

    if (!isNull(get(settingContext, 'timesheetDate'))) {
      loadWeatherList(settingContext.onGetTimeSheetDateFormat());
    }
  }, [settingContext.timesheetDate]);

  useEffect(() => {

    if (downloadUrl !== '') {
      var element = document.createElement('a');
      element.setAttribute('href', downloadUrl);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }

  }, [downloadUrl]);

  const onDownloadWeather = () => {
    try {
      setDownloading(true);
      loadWeatherList(settingContext.onGetTimeSheetDateFormat(), true);
    } catch (error) {

    }
  };

  return (<>
    <CardDash title={<div style={{ display: 'flex', alignItems: 'center' }}>
          <span>Weather</span>
          {showList && <DownloadWeatherComponents onDownloadWeather={onDownloadWeather} downloading={downloading} />}
        </div>}
        accordionDetailStyles={{ paddingTop: 16 }}
        actions={
          <Button classes={{ root: 'root-class' }} color="primary" onClick={toggleList}>{showList
              ? (<><GridView style={{ marginRight: '5px' }} /> Grid View</>)
              : <><FormatListBulletedIcon style={{ marginRight: '5px' }} /> List View</>
          }</Button>
        }
    >
      {showList
          ? <List style={{ maxHeight: 292, overflowY: 'auto' }}>
            {weatherData.map((item, key) => (
                <ListItem key={key}>
                  <ListItemAvatar>
                    <Avatar src={weatherIcon(item.weatherIcon)} />
                  </ListItemAvatar>
                  <ListItemText primary={item.weather.iconPhrase} secondary={item.time}
                                style={{ width: '100px' }} />
                  <ListItemText primary="Temperature"
                                secondary={`${item.weather.temperature.Value} ${item.weather.temperature.Unit}`} />
                  {/*<ListItemText primary={item.weather.iconPhrase} secondary={item.time} />*/}
                </ListItem>
            ))}
          </List>
          : <WeatherWidget
              projectData={projectData}
              weatherApiBaseURL={weatherApiBaseURL}
              weatherIconBaseURL={weatherIconBaseURL}
              marginTopIndicators={marginTopIndicators}
              mainWrapperStyles={{ height: 292 }}
              loadingSectionStyles={{ height: 292 }}
          />
      }
    </CardDash>
  </>);
};
