import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, ThemeProvider } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    userSelect: 'auto',
    borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
    backgroundColor: '#fff7f773',
    minHeight: '45px !important',
    '& > .Mui-expanded': {
      margin: '5px auto',
    },
  },
  content: {
    marginTop: 5,
    marginBottom: 5
  }
}));

export const CardDash = ({ title, actions, children, height, accordionSummaryStyles = {}, accordionDetailStyles = {}, expandable = false, defaultExpanded = true }) => {
  const [isExpanded, setExpanded] = useState(defaultExpanded);
  const classes = useStyles();

  function onExpand() {
    return expandable && setExpanded(!isExpanded);
  }

  return (
    <Accordion expanded={isExpanded} defaultExpanded={defaultExpanded}>
      <AccordionSummary classes={classes} style={accordionSummaryStyles}>
        <Grid alignContent="center" alignItems="center" container>
          <Grid xs={true} item onClick={onExpand}>
            <Typography variant="h6" component="h2" className="m-b-0 text-muted">{title}</Typography>
          </Grid>
          <Grid xs={'auto'} item>{actions}</Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column', height, overflow: 'auto', ...accordionDetailStyles }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
