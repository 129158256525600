import React, { useState } from "react"
import axios from "axios"
import moment from 'moment';

const Position = ({ data, isNew, onDelete }) => {

  // so the id and the modifies doesnt get changed by this widget
  const [position, setPosition] = useState(data);
  const [needsUpdate, setNeedsUpdate] = useState(isNew);


  const handleChange = e => {
    position[e.target.name] = e.target.value;
    setPosition({ ...position })
    setNeedsUpdate(true)
  }

  const axiosClient = () => {
    return axios.create({
      withCredentials: true
    })
  }

  const handleDelete = ev => {
    if(confirm("Are you sure?")) {
      // Asking wether its delete a new one or one which already exists in the server
      if (data._id.$oid) {
        axiosClient().delete(`/companies/${data.company_id.$oid}/positions/${data._id.$oid}.json`, {data: buildPayload()}).then(response => {
          onDelete(data)
        }).catch(err => {
          toastr.error("An Error Ocurred", "")
        })
      } else {
        onDelete(data)
      }
    }
  }

  const buildPayload = () => {
    let token = document.querySelector("meta[name='csrf-token']").content
    return {
      authenticity_token: token,
      position
    }
  }
  const handleCreate = e => {
    axiosClient().post(`/companies/${data.company_id.$oid}/positions.json`, buildPayload()).then(response => {
      data._id = response.data._id
      toastr.success("Successfully Saved", "")
      setNeedsUpdate(false)
    }).catch(err => {
      toastr.error("", "An Error Ocurred")
      toastr.error(err.response.data.base.join(" "), "Validation Error")
      console.error(err)
    })
  }

  const handleSave = e => {
    // new one
    if (data._id.$oid == undefined) {
      return handleCreate(e)
    } else {
      axiosClient().put(`/companies/${data.company_id.$oid}/positions/${data._id.$oid}.json`, buildPayload()).then(response => {
        toastr.success("Successfully Saved", "")
        setNeedsUpdate(false)
      }).catch(err => {
        toastr.error("", "An Error Ocurred")
        toastr.error(err.response.data.base.join(" "), "Validation Error")
        console.error(err)
      })
    }
  }

  const formatDate = date => {
    moment.locale('en')
    return moment(date).format('MM/DD/YYYY')
  }

  return (
    <tr>
      <td>
        <input type="text" name="name" onChange={handleChange} value={position.name} className="form-control" />
      </td>
      <td>
        <input type="text" name="number" onChange={handleChange} value={position.number} className="form-control" />
      </td>
      <td>
        <input type="text" name="description" onChange={handleChange} value={position.description} className="form-control" />
      </td>
      <td>
        <strong>{formatDate(position.created_at)}</strong>
      </td>
      <td>
        <strong>{formatDate(position.updated_at)}</strong>
      </td>
      <td>
        {(() => {
          if (needsUpdate) {
            return (
              <a className="btn btn-success btn-xs btn-outline btn-3d" onClick={handleSave}>Save</a>
            )
          }
        })()}
      </td>
      <td>
        <a className="btn btn-danger btn-xs btn-outline btn-3d" onClick={handleDelete}>Delete</a>
      </td>
    </tr>
  );
}

export default Position;