import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { TableFilters, Widget } from '../../../projectEmployees/components'
import { get } from 'lodash';
import { SettingsContext } from '../../../contexts/SettingsContext';
import { Filters } from '../index'

export default function HeaderMenu(props) {

  const { company, filters, onFilter, onSendingFilters, onClearFilters, downloadButtonText, onDownloadData, userTypes } = props;

  const settingsContext = useContext(SettingsContext);

  const topMenuStyles = makeStyles((theme) => ({
    filtersWrapper: {
       display: 'flex',
       alignItems: 'flex-start'
    },
    title: {
      fontSize: '1.6rem',
      fontWeight: 500,
      marginRight: 24,
      paddingTop: 6
    },
  }));

  const classes = topMenuStyles();


  return (
    <div className='MuiPaper-root makeStyles-paper MuiPaper-elevation1 MuiPaper-rounded' style={{ padding: 12 }}>
      <div className={classes.filtersWrapper}>
        <div className={classes.title}>Filters</div>
        <Filters filters={filters} onFilter={onFilter} onSendingFilters={onSendingFilters} onClearFilters={onClearFilters} company={company} downloadButtonText={downloadButtonText} onDownloadData={onDownloadData} userTypes={userTypes} />
      </div>
    </div>
  )
};
