import React, { useEffect, useState } from 'react';
import { size } from 'lodash';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import { get } from 'lodash';
import { myTheme } from '../../../../../helpers';
import workerProfileIcon from '../../../../../../../assets/images/worker_types/constructionWorkerIcon-2023.svg'

export default function EventLogs({ fetch }) {

  const useStyles = makeStyles((theme) => ({
    widget: {
      width: 500,
      height: 150,
      overflow: 'auto',
      border: '2px solid rgba(241, 89, 34)',
      borderRadius: 10,
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 0,
      paddingRight: 0,
      fontWeight: 'bold',
    },
    avatar: {
      width: '40px !important',
    },
    reloadButton: {
      marginTop: 10,
      color: '#f15922',
      borderColor: '#f15922',
      fontSize: 12,
      width: 70,
      height: 30,
    },
    activityLogAvatar: {
      width: 30,
      height: 30,
    },
  }));
  const classes = useStyles();
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!fetch) {
      return;
    }

    setLoading(true);

    fetch()
      .then(setLogs)
      .catch(setError)
      .finally(() => setLoading(false));

  }, []);

  const renderLogs = () => {

    if (size(logs) > 0) {
      return (
        <Timeline align="left" style={{ marginBottom: 0, padding: 0, paddingLeft: 0 }}>
          {logs.map((log, index) =>
            <EventRow key={index} log={log} classes={classes} isLast={index === size(logs) - 1} />,
          )}
        </Timeline>
      );
    } else {
      return (
        <div style={{ height: 124, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span style={{ color: 'grey', fontWeight: 'normal' }}>There are no actions logged for this shift</span>
        </div>
      );
    }
  };

  return (
    <ThemeProvider theme={myTheme}>
      <div className={classes.widget}
        style={{ backgroundColor: !loading && !error ? 'rgba(241, 89, 34, 0.1)' : '' }}>
        {loading &&
          <div style={{ display: 'flex', justifyContent: 'center', height: 120, alignItems: 'center' }}>
            <CircularProgress color="primary" />
          </div>
        }
        {!loading && loading &&
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: 124,
            alignItems: 'center',
          }}>
            <ReportProblemRoundedIcon color="error" />
            <span style={{ color: '#f44336', fontWeight: 'normal' }}>Could not load events logs</span>
            <Button size="small" onClick={fetch} className={classes.reloadButton}>
              {'RELOAD'}
            </Button>
          </div>
        }
        <div style={{ maxWidth: 500 }}>
          {!loading && !error && renderLogs()}
        </div>
      </div>
    </ThemeProvider>
  );
}

const EventRow = ({ classes, log, isLast }) => <>
  <TimelineItem>
    <TimelineOppositeContent style={{ maxWidth: 160, marginTop: '8px' }}>
      <Typography variant="h6" component="h1" style={{ fontSize: 12 }}>
        {log.modelname}
      </Typography>
      <Typography variant="body2" color="textSecondary" style={{ fontSize: 8 }}>
        {log.user.first_name} {log.user.last_name}
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator>
      <TimelineDot color="primary" variant="outlined">
        <Avatar className={classes.activityLogAvatar} src={get(log, 'user.profilePic', workerProfileIcon)} />
      </TimelineDot>
      {!isLast && <TimelineConnector sx={{ color: 'red' }} />}
    </TimelineSeparator>
    <TimelineContent style={{ fontSize: 10, fontWeight: 'normal', paddingTop: 8, color: 'rgba(0, 0, 0, 0.8)' }}>
      {log.description}
    </TimelineContent>
  </TimelineItem>
</>;
