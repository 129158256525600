import React, { useState, useLayoutEffect } from 'react'
import { Button, Modal } from "react-bootstrap";

export default function AddressAutocomplete({ addressSelector, citySelector, stateSelector, zipSelector }) {

  const [show, setShow] = useState(false)
  const [address, setAddress] = useState({
    street_number: '',
    route: '',
    locality: '',
    administrative_area_level_1: '',
    country: '',
    postal_code: ''
  })

  const handleClose = () => {
    document.getElementById(addressSelector).value = `${address.street_number} ${address.route}`
    if (document.getElementById(citySelector)) {
      document.getElementById(citySelector).value = address.locality
    }
    $(document.getElementById(stateSelector)).select2().val(address.administrative_area_level_1).trigger("change")
    document.getElementById(zipSelector).value = address.postal_code

    setShow(false)
  }
  const handleOpen = () => {
    setShow(true)
  }

  const handleChange = (ev) => {
    address[ev.target.name] = ev.target.value
    setAddress({ ...address })
  }

  const handleNewAddress = (newAddress) => {
    console.log({ newAddress})
    setAddress({ ...newAddress })
  }

  return (
    <>
      <Button variant="secondary" onClick={handleOpen} className="btn-lg">
        Autofill
      </Button>

      <Modal show={show} onHide={handleClose} size="lg" animation={false} backdrop={true} backdropClassName="modal-backdrop fade in">
        <Modal.Header closeButton>
          <Modal.Title>Autocomplete using Google Maps.</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <fieldset className="form-group form-group-lg">
                    <GoogleAutocomplete onNewAddress={handleNewAddress} />
                  </fieldset>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-4">
                  <fieldset className="form-group form-group-lg">
                    <label>Street Number</label>
                    <input type="text" className="form-control" name="street_number" value={address.street_number} onChange={handleChange}/>
                  </fieldset>

                </div>
                <div className="col-md-8">
                  <fieldset className="form-group form-group-lg">
                    <label>Street Address</label>
                    <input type="text" className="form-control" name="route" value={address.route} onChange={handleChange}/>
                  </fieldset>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <fieldset className="form-group form-group-lg">
                    <label>City</label>
                    <input type="text" className="form-control" name="locality" value={address.locality} onChange={handleChange}/>
                  </fieldset>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <fieldset className="form-group form-group-lg">
                    <label>State/Province</label>
                    <input type="text" className="form-control" name="administrative_area_level_1" value={address.administrative_area_level_1} onChange={handleChange}/>
                  </fieldset>
                </div>
                <div className="col-md-6">
                  <fieldset className="form-group form-group-lg">
                    <label>Zip Code/Postal Code</label>
                    <input type="text" className="form-control" name="postal_code" value={address.postal_code} onChange={handleChange}/>
                  </fieldset>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <fieldset className="form-group form-group-lg">
                    <label>Country</label>
                    <input type="text" className="form-control" name="country" value={address.country} onChange={handleChange}/>
                  </fieldset>
                </div>
              </div>
            </form>
          </div>


        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" onClick={handleClose} className="btn">
            Use this address
          </Button>
        </Modal.Footer>
      </Modal>
    </>

  )
}




const GoogleAutocomplete = ({ onNewAddress }) => {


  let componentForm = {
    street_number: 'short_name',
    route: 'short_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };

  function fillInAddress() {
    // Get the place details from the autocomplete object.
    console.log("FIlling address");
    var place = window.autocomplete.getPlace();

    let eventToSend = {}

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];
      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];
        eventToSend[addressType] = val;
      }
    }

    onNewAddress(eventToSend)
  }


  const initAutocomplete = () => {
    window.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('google_maps_autocomplete'), { types: ['geocode'] });

    window.autocomplete.setFields(['address_component']);

    window.autocomplete.addListener('place_changed', fillInAddress);
  }

  const geolocate = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        var circle = new google.maps.Circle(
          { center: geolocation, radius: position.coords.accuracy });
        console.log({ circle });

        let bounds = circle.getBounds()
        window.autocomplete.setBounds(bounds);
      });
    }
  }

  useLayoutEffect(() => {
    initAutocomplete()
    return () => { };
  }, [])

  return (
    <input type="text" id="google_maps_autocomplete" onFocus={geolocate} className="form-control" placeholder="Type Here.." />
  );
}
