import React from 'react';
import GaugeMeter from '../../../../reusableComponents/GaugeMeter';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

export default function WelcomeCard({  gaugeData }) {
  const { workers, companies } = gaugeData;

  return (<>
    <Grid spacing={1} container>
      <Grid item container direction="column" xs={11} spacing={1}>
        <Grid item>
          <GaugeMeter fullGaugeFontSize={'2.5em'} title="Workers" values={{ current: workers.current, total: workers.total }} size={85} noActivity={workers.current === 0 && workers.total === 0} activityCompleted={workers.current === 0 && workers.total !== 0} />
        </Grid>
        <Grid item>
          <GaugeMeter fullGaugeFontSize={'2.5em'} title="Companies" values={{ current: companies.current, total: companies.total }} size={85} noActivity={companies.current === 0 && companies.total === 0} activityCompleted={companies.current === companies.total && companies.total !== 0} />
        </Grid>
      </Grid>
    </Grid>

  </>);
}
