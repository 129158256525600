import React, { useContext } from 'react';
import { get } from 'lodash';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { FilterList, DeleteForever, GetApp } from '@material-ui/icons';
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { SettingsContext } from '../../../contexts/SettingsContext';


import {
  UserTypes,
  RFID,
  PaymentProfiles,
  Crews,
  Positions,
  Departments,
  ProfilePicture,
  Deleted
} from './components/index'
// import { ProfilePicture } from "../../../projectEmployees/components/tableFilters/Filters";


export default function Filters(props) {

  const { filters, onFilter, onSendingFilters, onClearFilters, company, downloadButtonText, onDownloadData, userTypes } = props;
  const settingsContext = useContext(SettingsContext);

  const useStyles = makeStyles((theme) => ({

    buttonGenerate: {
      backgroundColor: '#009687 !important',
      color: 'white !important',
      fontWeight: 'bold',
      borderRadius: '5px !important'
    },
    buttonClearFilter: {
      fontWeight: 'bold',
      // marginLeft: '10px !important',
      borderRadius: '5px !important'
    },
    filters: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    filtersMobile: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
    }
  }));

  const classes = useStyles();

  const onClearFiltersLocal = () => {
    settingsContext.onCleaningFilter(true);
    onClearFilters();
  }

  const selectStyles = {
    container: (provided, state) => ({
      ...provided,
      width: 150
    })
  }

  return (
    <>
      <div style={{ width: '100%', display: 'flex' }}>
        <div className={`${get(settingsContext, 'isMobile') ? classes.filtersMobile : classes.filters}`} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <Grid spacing={1} container>

            {filters.map((item, idx) => (
              <Grid item key={idx}>
                {/* <div> */}
                {get(item, 'key') === 'user_type' ? <UserTypes onFilter={onFilter} filter={item} selectStyles={selectStyles} userTypes={userTypes} /> : null}
                {get(item, 'key') === 'paymentProfiles' ? <PaymentProfiles selectStyles={selectStyles} onFilter={onFilter} filter={item} paymentProfiles={get(company, 'paymentProfiles', [])} /> : null}
                {get(item, 'key') === 'crews' ? <Crews selectStyles={selectStyles} onFilter={onFilter} filter={item} crews={get(company, 'crews', [])} /> : null}
                {/* </div>
                <div> */}
                {get(item, 'key') === 'positions' ? <Positions selectStyles={selectStyles} onFilter={onFilter} filter={item} positions={get(company, 'positions', [])} /> : null}
                {get(item, 'key') === 'departments' ? <Departments selectStyles={selectStyles} onFilter={onFilter} filter={item} departments={get(company, 'departments', [])} /> : null}
                {/* </div> */}
                {/* <div style={{ display: 'flex', flexWrap: 'wrap' }}> */}
                {get(item, 'key') === 'rfid' ? <RFID onFilter={onFilter} filter={item} selectStyles={selectStyles} /> : null}
                {get(item, 'key') === 'profilePicture' ? <ProfilePicture selectWidth='200px' onFilter={onFilter} filter={item} selectStyles={selectStyles} /> : null}
                {get(item, 'key') === 'deleted' ? <Deleted onFilter={onFilter} filter={item} selectStyles={selectStyles} /> : null}
                {/* </div> */}
              </Grid>
            ))}

          </Grid>
          <div className="filter-buttons" style={{ width: `${get(settingsContext, 'isMobile') ? '100%' : 330}`, display: "flex", alignItems: 'flex-start', justifyContent: `${get(settingsContext, 'isMobile') ? 'center' : 'flex-end'}`, marginTop: `${get(settingsContext, 'isMobile') ? '20px' : '0px'}`, height: '100%' }}>
            <Button
              disabled={downloadButtonText !== 'Download'}
              style={{ marginLeft: '5px' }}
              variant="contained"
              className={classes.buttonGenerate}
              onClick={() => onDownloadData()}
              startIcon={downloadButtonText === 'Download' ? <GetApp /> : <CircularProgress size={20} color="primary" />}>
              {downloadButtonText}
            </Button>
            <Button
              style={{ marginLeft: '5px' }}
              variant="contained"
              className={classes.buttonGenerate}
              onClick={() => onSendingFilters()}
              startIcon={<FilterList />}>
              Filter
            </Button>
            <Button
              style={{ marginLeft: '5px' }}
              variant="contained"
              className={classes.buttonClearFilter}
              onClick={() => onClearFiltersLocal()}
              startIcon={<DeleteForever />}>
              Clear
            </Button>
          </div>
        </div>
      </div>
    </>

  )
};
