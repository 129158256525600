import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import { $axios } from '../../../helpers';

export function DeviceRetireDialog({ open = false, endpoint, device = {}, onClose = () => ({}) }) {
  const [saving, setSaving] = useState(false);

  function retireDevice() {
    setSaving(true);

    const payload = { 'current_status': 'retired' };

    return $axios.$patch(`${endpoint}/update/${device._id}`, payload)
        .then(onClose)
        .finally(() => setSaving(false));
  }

  return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span>Are you sure you want to retire</span>
            <strong>{` ${device.name} - ${device.device_id}`}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={saving} onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={saving} onClick={retireDevice} color="primary" autoFocus>
            Yes, Retire
          </Button>
        </DialogActions>
        {saving && <LinearProgress />}
      </Dialog>
  );
}
