import React, { useState, useEffect, useContext } from 'react'
// import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@material-ui/core'
import { get } from 'lodash';
import Select from 'react-select';

import { SettingsContext } from '../../../../contexts/SettingsContext';

export default function RFID(props) {

  const { filter, onFilter, selectStyles } = props;
  const [value, setValue] = useState(-1);

  const settingsContext = useContext(SettingsContext);

  const onLocalChange = (e) => {
    settingsContext.onCleaningFilter(false)
    setValue(e);
    onFilter(get(filter, 'key'), get(e, 'value'));
  }

  useEffect(() => {
    if (settingsContext.clearFilter)
      setValue(-1);
  }, [settingsContext.clearFilter])


  return (
    <div className='d-flex justify-content-center align-items-center mr-4' style={{ marginTop: `${get(settingsContext, 'isMobile') ? '10px' : '0px'}` }}>
      <Select
        onChange={onLocalChange}
        options={[
          { value: -1, label: 'All' },
          { value: 1, label: 'With RFID' },
          { value: 0, label: 'Without RFID' }
        ]}
        placeholder='RFID...'
        value={value}
        styles={selectStyles}
      />
    </div>

  )
};