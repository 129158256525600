import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IconButton, Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {
  getLastSeen,
  renderConnection,
  renderLocation,
  renderOperator,
  renderPower,
  renderStatus,
} from '../../../../devices/list/components/DeviceListItem';
import Grid from '@material-ui/core/Grid';

export default function DevicesList({ items = [], onAliasChange = () => ({}) }) {

  function hasItems() {
    return !!items.length;
  }

  const renderLastSeen = (device) => getLastSeen(device.seen.timestamp).fromNow();

  return (<>
        <Table>
          <TableHead style={{ position: 'sticky', top: 0, background: 'white', zIndex: 2 }}>
            <TableRow>
              <TableCell style={{ minWidth: '12em' }}>ID</TableCell>
              <TableCell style={{ minWidth: '12em' }}>Name</TableCell>
              <TableCell style={{ minWidth: '12em' }}>Last seen</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Connection</TableCell>
              <TableCell>Power</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((device) => (
                <TableRow key={device._id} style={{ height: '78px' }}>
                  <TableCell className="p-y-0" component="th" scope="row">
                    <Tooltip title={
                      <>
                        <div>
                          <span>Firmware: </span>
                          <span>{device.attributes.firmware || 'No Firmware Available'}</span>
                        </div>
                        <div>
                          <span>Version: </span>
                          <span>{device.attributes.version || 'No Version Available'}</span>
                        </div>
                      </>}>
                        <span className={`label label-${renderStatus(device)}`}>
                          {device.name}
                        </span>
                    </Tooltip>
                  </TableCell>

                  <TableCell className="p-y-0">
                    <TextField
                        variant={'outlined'}
                        size="small"
                        placeholder="Enter device name"
                        defaultValue={device.alias}
                        onBlur={({ target }) => onAliasChange(device._id, target.value)}
                    />
                  </TableCell>

                  <TableCell className="p-y-0">
                    <span>{renderLastSeen(device)}</span>
                  </TableCell>

                  <TableCell className="p-y-0">
                    <Tooltip title={device.location.source}>
                      <IconButton>
                        {renderLocation(device)}
                      </IconButton>
                    </Tooltip>
                  </TableCell>

                  <TableCell className="p-y-0">
                    <div style={{ display: 'flex' }}>
                      {renderOperator(device)}
                      {renderConnection(device)}
                    </div>
                  </TableCell>

                  <TableCell className="p-y-0">
                    {renderPower(device)}
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>

        {!hasItems() && (<Grid justifyContent={'center'} className="p-t-4" container>
          {(<span>No items to show</span>)}
        </Grid>)}
      </>
  );
}
