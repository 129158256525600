import React, { useEffect } from 'react'
import { DialogContentText } from "@material-ui/core";
import RevokeCode from "../RevokeCode";

export default function WorkerVerification(props) {

  const { isCompleted, isRevoke, state, onVerified, content, onSetLoading, loading, onDialogClose, onDisclaimer } = props;

  useEffect(() => {
    if (isCompleted) {
      setTimeout(() => {
        onDialogClose()
      }, 2000)
    }

  }, [])

  return (
    <>
      <DialogContentText>{content}</DialogContentText>
      {isRevoke && (
        <form className="sb-inline-form">
          <RevokeCode {...props} loading={loading} onDisclaimer={onDisclaimer} onVerify={onVerified} onSetLoading={onSetLoading} record={{ phoneNumber: state.phone_number }} />
        </form>
      )}
    </>
  )
}
