export const allColumns = [
  {
    id: "image_path",
    isActive: true,
    isImage: true,
    sortOption: false,
    label: "Image"
  },
  {
    id: "worker_name",
    sortOption: true,
    isActive: true,
    label: "Name"
  },
  {
    id: "phone_number",
    sortOption: true,
    isActive: true,
    label: "Phone"
  },
  {
    id: "decal_number",
    sortOption: true,
    isActive: true,
    label: "Decal Number"
  },
  {
    id: "company",
    sortOption: true,
    isActive: true,
    label: "Company"
  },
  {
    id: "rate",
    sortOption: true,
    label: "Rate",
    isActive: false
  },
  {
    id: "per_diem",
    sortOption: true,
    label: "Per Diem",
    isActive: false
  },
  // {
  //   id: "position",
  //   sortOption: true,
  //   label: "Position",
  //   isActive: false
  // },
  {
    id: "has_access",
    sortOption: true,
    label: "Access",
    isActive: true
  },
  {
    id: "last_seen",
    sortOption: true,
    label: "Last Seen",
    isActive: true
  },
  {
    id: "idCard",
    sortOption: false,
    label: "ID Card",
    isActive: true
  },
  {
    id: "rfid",
    sortOption: false,
    label: "RFID",
    isActive: true
  },

  {
    id: "onboarded",
    sortOption: false,
    label: "Safety Form",
    isActive: true
  },
  {
    id: "documents",
    sortOption: false,
    label: "documents",
    isActive: false
  },

  {
    id: "actions",
    sortOption: false,
    label: "Actions",
    isActive: true
  }
];

export const defaultColumns = [
  {
    id: "image_path",
    isImage: true,
    sortOption: false,
    label: "Image",
    isActive: true

  },
  {
    id: "worker_name",
    sortOption: true,
    label: "Name",
    isActive: true

  },
  {
    id: "phone_number",
    sortOption: true,
    label: "Phone",
    isActive: true

  },

  {
    id: "decal_number",
    sortOption: true,
    label: "Decal Number",
    isActive: true

  },
  {
    id: "company",
    sortOption: true,
    label: "Company",
    isActive: true

  },
  {
    id: "has_access",
    sortOption: true,
    label: "Access",
    isActive: true
  },
  {
    id: "last_seen",
    sortOption: true,
    label: "Last Seen",
    isActive: true

  },
  {
    id: "idCard",
    sortOption: false,
    label: "ID Card",
    isActive: true
  },
  {
    id: "rfid",
    sortOption: false,
    label: "RFID",
    isActive: true

  },



  {
    id: "onboarded",
    sortOption: false,
    label: "Safety Form",
    isActive: true

  },
  {
    id: "actions",
    sortOption: false,
    label: "Actions",
    isActive: true
  }
]