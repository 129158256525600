import React, { useState, useContext, useEffect } from 'react'
import { get } from 'lodash';
import { SettingsContext } from '../../../../contexts/SettingsContext';
import Select from 'react-select';


export default function Departments(props) {

  const { filter, onFilter, selectStyles, departments } = props;
  const settingsContext = useContext(SettingsContext);

  const [state, setState] = useState({
    value: get(filter, 'value', [])
  });

  const onLocalChange = (e) => {
    settingsContext.onCleaningFilter(false)
    setState({ e });

    onFilter(get(filter, 'key'), get(e, 'value'));

  };

  useEffect(() => {
    if (settingsContext.clearFilter) {
      setState({
        value: []
      })
    }

  }, [settingsContext.clearFilter])

  return (
    <div className='d-flex justify-content-center align-items-center mr-4' style={{ marginTop: `${get(settingsContext, 'isMobile') ? '10px' : '0px'}` }}>
      <Select
        onChange={onLocalChange}
        options={departments}
        placeholder='Department'
        value={state.value}
        styles={selectStyles}
      />
    </div>
  )
};
