import React, { useState } from 'react'
import { AutoRotatingCarousel, Slide } from "../jumbo-components/material-carousel";
import PunchPPE from './PunchPPE';

// import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Avatar } from "@material-ui/core";
// import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";


const imageClassMap = {
  valid: 'img-valid',
  invalid: 'img-invalid',
  unconfirmed: 'img-unconfirmed',
}


export default function PunchImages(props) {

  // console.log("PunchImages", props);

  const { punch, worker, config } = props

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const buildImageUrl = (imageSuffix) => {
    return `${config.imageBasePath}${imageSuffix}`
  }

  const shouldDisplayModal = () => punch && punch.images && punch.images.length > 0

  const thumbnailImage = (() => {
    if (punch.images && punch.images.length > 0) {
      return `${config.imageBasePath}${punch.images[0].name}`
    }

    return config.noImagePath
  })()

  const imageClasses = `thumb-circle shift-images-btn ${imageClassMap[punch.identity_status]}`

  return (
    <>
      <img className={imageClasses} onClick={handleOpen} src={thumbnailImage}></img>

      {shouldDisplayModal() && (() => (
        <AutoRotatingCarousel
          open={open}
          onClose={handleClose}
          onStart={handleClose}
          autoplay={false}
          style={{ position: 'absolute' }}
          extra={
            <PunchPPE {...props} />
          }
        >
          {
            punch.images.map((image, id) => (
              <Slide
                mediaBackgroundStyle={{ backgroundColor: "#f15922" }}
                style={{ backgroundColor: "#f15922" }}
                title=""
                subtitle=""
                key={id} media={
                    <img src={buildImageUrl(image.name)} style={{ height: "100%" }}></img>
                }>
              </Slide>
            ))
          }
        </AutoRotatingCarousel>
      ))()}
    </>
  )
}
