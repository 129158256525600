import React, { useState, useEffect } from 'react';
import { makeStyles, Button, CircularProgress } from '@material-ui/core';
import { get, isEmpty, mapKeys, startCase } from 'lodash';
import { Security } from '@material-ui/icons';
import { getProjectByCompany, getUserPermissions, saveUserPermissions } from '../../../services/employeeService';
import { WorkerInfoSection, UserSection, PermissionsSection } from '../index';
import { notificationCenter } from '../../../../helpers/notifications';
import { SBSwitch } from '../../../../reusableComponents';

const useStyles = makeStyles((theme) => ({
  mainContent: {
    backgroundColor: '#f3f3f4',
    height: "100%",
    width: "100%",
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  workerForm: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  backgroundIcon: {
    zIndex: -1,
    position: 'absolute',
    right: '-5rem',
    fontSize: '11rem',
    color: '#c8c8c8'
  },
  drawerActionButtonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    alignItems: 'center',
    marginTop: 24
  },
  loaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: 8
  },
}));


export default function EditCompanyWorkerSettingsAndPermissions(props) {

  const { worker, companies, company, companyWorkersDomain, onEditClose, onShowHireRate, projectEndpoint, promoteWorkerEndpoint, userTypes } = props;

  const [isValid, setIsValid] = useState(true)
  const [showMessage, setShowMessage] = useState({
    status: false,
    message: ""
  });

  const [state, setState] = useState({
    showHireRate: false,
    editing: false,
    workerEdited: {
      ...worker,
      company_id: get(company, 'value'),
      confirm_phone_number: get(worker, 'phone_number'),
      mobileAppSettings: {
        enableMobileApp: get(worker, 'mobileAppSettings.enableMobileApp', true),
        kioskModeEnabled: get(worker, 'mobileAppSettings.kioskModeEnabled', false),
        checkWorkerLogged: get(worker, 'mobileAppSettings.checkWorkerLogged', false)
      }
    },
    permissions: [],
    companyProjects: [],
    project_ids: [],
    // companyCrews: get(companies.filter(oneCompany => get(oneCompany, 'isUser')), '[0].crews', []),
    // crew_ids: []
  });

  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)

  const getProjects = async () => {
    try {
      const res = await getProjectByCompany(projectEndpoint, get(company, 'value'));

      setState(prevState => ({
        ...prevState,
        companyProjects: get(res, 'data', [])
      }))

      setLoading(false)

    } catch (error) {
      // console.log('xx error: ', error)
      onEditClose(true, { ...get(state, 'workerEdited'), warning: true, reason: 'Error getting company projects.'})
    }
  }

  const getSettingsAndPermissions = async () => {
    try {

      const res = await getUserPermissions(promoteWorkerEndpoint, get(worker, '_id'));

      get(res, 'data.permissions', []).map(permissionGroup => {
        let enabledCount = 0

        get(permissionGroup, 'actions').map(oneAction => {
          // NOTE: now only boolean is rendered
          // NOTE: add more cases for other types like select or text input
          if (get(oneAction, 'type') === 'boolean') {
            if (get(oneAction, 'value')) enabledCount++
          }
        })

        permissionGroup['enabledCount'] = enabledCount
        permissionGroup['enabled'] = get(permissionGroup, 'enabled', enabledCount > 0)
      })

      setState(prevState => ({
        ...prevState,
        permissions: get(res, 'data.permissions', []),
        project_ids: get(res, 'data.project_ids', []),
        // crew_ids: get(res, 'data.crew_ids', []),
      }))

      getProjects()

    } catch (error) {
      // console.log('xx error: ', error)
      onEditClose(true, { ...get(state, 'workerEdited'), warning: true, reason: 'Error getting user settings and permissions.'})
    }
  }

  useEffect(() => {
    getSettingsAndPermissions();
    // getProjects();
  }, []);


  const onChangeBasic = (key, value) => {

    switch (key) {
      case 'phone_number':
        setIsValid(!isEmpty(value))
        break;
      case 'first_name':
        setIsValid(!isEmpty(value))
        break;
      case 'last_name':
        setIsValid(!isEmpty(value))
        break;
      case 'confirm_phone_number':
        setIsValid(!isEmpty(value) && state.workerEdited['phone_number'] === value)
        break;
      default:
        break;
    }

    setState(prevState => ({
      ...prevState,
      workerEdited: {
        ...prevState.workerEdited,
        [key]: value
      }
    }))

    // apply the template permissions
    if (key === 'permissions') {
      let temp = get(state, 'permissions')
      value.map(oneTemplatePermission => {
        temp.map(oneStatePermission => {

          if (oneTemplatePermission.scope === oneStatePermission.scope) {
            // todo: re-enable below line to activate immutable effect
            // oneStatePermission.disabled = get(oneTemplatePermission, 'inmutable', false)

            oneTemplatePermission.actions.map(oneTemplateAction => {
              oneStatePermission.actions.map(oneStateAction => {

                if (oneTemplateAction.scope === oneStateAction.scope) {
                  oneStateAction.value = oneTemplateAction.value
                  // todo: re-enable below line to activate immutable effect
                  // oneStateAction.disabled = get( oneTemplatePermission, 'inmutable', get( oneTemplateAction, 'inmutable', false))
                }
              })
            })
          }
        })
      })

      setState(prevState => ({
        ...prevState,
        permissions: temp
      }))
    }
  }

  const onSave = async () => {
    setSaving(true)

    try {
      let payload = {
        user_type: get(state, 'workerEdited.user_type'),
        permissions: get(state, 'permissions'),
        project_ids: get(state, 'workerEdited.project_ids'),
        // crew_ids: get(state, 'workerEdited.crew_ids')
      }

      const res = await saveUserPermissions(promoteWorkerEndpoint, get(worker, '_id'), payload);

      onEditClose(false, { ...get(state, 'workerEdited'), message: 'Saved.'})

    } catch (error) {
      onEditClose(true, { ...get(state, 'workerEdited'), warning: true, reason: 'Error saving changes.'})
      // console.log('xx error: ', error)
    }

  }

  const classes = useStyles();

  const renderLoader = () => {
    return(
      <div className={classes.loaderWrapper}>
        <CircularProgress color="primary" size={21} />
      </div>
    )
  }

  return (
    <div className={classes.mainContent}>
      <WorkerInfoSection {...props} disableCompanySelect={true} backgroundIcon={<Security className={classes.backgroundIcon} />} />
      <hr />
      <div className={classes.workerForm}>
        <div>
          { loading && renderLoader() }
          { !loading &&
            <UserSection
              worker={state.workerEdited}
              projectEndpoint={projectEndpoint}
              onChangeBasic={onChangeBasic}
              project_ids={get(state, 'project_ids')}
              companyProjects={get(state, 'companyProjects')}
              userTypes={userTypes}
              // crew_ids={get(state, 'crew_ids')}
              // companyCrews={get(state, 'companyCrews')}
            />
          }
          { !loading &&
            <PermissionsSection
              userPermissions={get(state, 'permissions')}
              updateState={setState} />
          }
        </div>
        <div className={classes.drawerActionButtonsWrapper}>
          <Button
            variant="outlined"
            onClick={() => onEditClose(false, null)}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            disabled={!isValid || saving}
            style={{ marginLeft: '5px' }}
            onClick={onSave}>
            { saving && <CircularProgress size={12} style={{ marginLeft: -4, marginRight: 4 }}/> } Save
          </Button>
        </div>
      </div>
    </div>
  )
};
