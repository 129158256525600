import React, { useRef, useEffect } from 'react';
import moment from 'moment';

const DatePicker = ({ value, onChange }) => {

  const reference = useRef(null)

  useEffect(() => {
    let picker = $(reference.current).datepicker()

    picker.on('changeDate', e => {
      const parsed = moment(e.date)
      onChange(parsed)
    })

    return () => { };
  }, [])

  return (

    <div ref={reference} className="input-group date manualPunchDateTimePicker">
      <input type="text" value={value} onChange={onChange} className="form-control" />
      <span className="input-group-addon"><span className="far fa-calendar"></span></span>
    </div>

  );
}

export default DatePicker;
