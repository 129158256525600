import React, { useEffect, useState } from 'react';
import { Bar, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import moment from 'moment';

export default function DailyActivityChart({ data, time }) {
  const [chartData, setChartData] = useState(data || []);
  useEffect(() => setChartData(data), [data]);

  const parsedData = chartData.map((punch) => ({
    label: punch.label,
    active: Boolean(punch.deviceActive),
    deviceAwake: Boolean(punch.deviceActive) ? 'YES' : 'NO',
    IN: punch.in || 0,
    OUT: punch.out || 0,
  }));

  const defineBackgroundColor = entry => {
    entry.fill = entry.active ? '#00800033' : '#EEEEEE3F';

    return (
        <path className="recharts-rectangle recharts-bar-background-rectangle" x={entry.x} y={entry.y} width="16"
              height="79" fill={entry.fill} radius="0" d={`M ${entry.x},${entry.y} h 16 v 79 h -16 Z`} />);
  };

  return (<>
    <div className="text-uppercase">
      {moment(time).format('dddd')}
    </div>
    <ResponsiveContainer width="100%" height={140}>
      <ComposedChart data={parsedData} barSize={16}>
        <XAxis dataKey="label" />
        <Tooltip />
        <Legend />

        <Bar dataKey="OUT" stackId="a" fill="#FB645C" background={defineBackgroundColor} />
        <Bar dataKey="IN" stackId="a" fill="#54D454" />
        <Bar
            dataKey="deviceAwake"
            legendType="triangle"
            name="Device Awake"
            fill="#00800033"
        />
      </ComposedChart>
    </ResponsiveContainer>
  </>);
}
