import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { IconButton, Tooltip, CircularProgress } from '@material-ui/core';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import PowerIcon from '@material-ui/icons/Power';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import BatteryAlert from '@material-ui/icons/BatteryAlert';
import Battery90Icon from '@material-ui/icons/Battery90';
import Battery80Icon from '@material-ui/icons/Battery80';
import Battery60Icon from '@material-ui/icons/Battery60';
import Battery50Icon from '@material-ui/icons/Battery50';
import Battery30Icon from '@material-ui/icons/Battery30';
import Battery20Icon from '@material-ui/icons/Battery20';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import WifiIcon from '@material-ui/icons/Wifi';
import SignalCellularNoInternetBarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet0Bar';
import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar';
import SignalCellular3BarIcon from '@material-ui/icons/SignalCellular3Bar';
import SignalCellular2BarIcon from '@material-ui/icons/SignalCellular2Bar';
import SignalCellular1BarIcon from '@material-ui/icons/SignalCellular1Bar';
import moment from 'moment';
import CloudIcon from '@material-ui/icons/Cloud';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import EyeIcon from '@material-ui/icons/Visibility';
import AppsIcon from '@material-ui/icons/Apps';
import EjectIcon from '@material-ui/icons/Eject';
import SyncIcon from '@material-ui/icons/Sync';

export function DeviceListItem({ device, onEdit = (device) => (device), onRetire = (device) => (device), forceDbSync }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const goToStatus = () => (location.href = `/devices/${device._id}/status`);

  const showEdit = () => {
    onEdit(device);
    closeMenu();
  };

  const showRetire = () => {
    onRetire(device);
    closeMenu();
  };

  const goToPunches = () => (location.href = `/devices/${device._id}/punches`);

  const handleForceDbSync = () => {
    closeMenu();
    forceDbSync(device);
  }

  return (
      <TableRow>
        <TableCell>{device.device_id}</TableCell>
        <TableCell>
          <a className={`btn btn-xs btn-${renderStatus(device)}`} href={`/devices/${device._id}/status`}>
            {device.name}
          </a>
        </TableCell>
        <TableCell>
          {device.authorized && <i className="fas fa-check" />}
        </TableCell>
        <TableCell>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {renderOperator(device)}
            {renderConnection(device)}
          </div>
        </TableCell>
        <TableCell>{renderLocation(device)}</TableCell>
        <TableCell>{renderPower(device)}</TableCell>
        <TableCell>{device.attributes.version}</TableCell>
        <TableCell>{device.current_status}</TableCell>
        <TableCell>{device.attributes.firmware}</TableCell>
        <TableCell>{device.device_type}</TableCell>
        <TableCell>{device.wakeup && moment(device.wakeup).fromNow()}</TableCell>
        <TableCell>{renderStayAwake(device)}</TableCell>
        <TableCell>{device.project && (
            <a href={`/projects/${device.project._id}`}>{device.project.name}</a>
        )}</TableCell>
        <TableCell>{device.company && (
            <a href={`/companies/${device.company._id}`}>{device.company.name}</a>
        )}</TableCell>
        <TableCell align="right">
          <IconButton onClick={device.syncing ? null : openMenu}>
            { device.syncing ? <CircularProgress size={18} color='primary' /> : <MoreVertIcon /> }
          </IconButton>

          <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={closeMenu}
              keepMounted
          >
            <MenuItem onClick={goToStatus}>
              <EyeIcon className="m-r-1" />
              <span>Details</span>
            </MenuItem>
            <MenuItem onClick={showEdit}>
              <EditIcon className="m-r-1" />
              <span>Edit</span>
            </MenuItem>
            <MenuItem onClick={goToPunches}>
              <AppsIcon className="m-r-1" />
              <span>Punches</span>
            </MenuItem>
            <MenuItem onClick={handleForceDbSync}>
              <SyncIcon className="m-r-1" />
              <span>Force DbSync</span>
            </MenuItem>
            {!device.project && (<MenuItem onClick={showRetire}>
              <EjectIcon className="m-r-1" />
              <span>Retire</span>
            </MenuItem>)}
          </Menu>
        </TableCell>
      </TableRow>
  );

}

export function renderStatus(device) {
  if (!device.seen) {
    return 'danger';
  }

  const lastSeen = getLastSeen(device.seen.timestamp);
  const diff = moment().diff(lastSeen, 'minutes');

  switch (true) {
    case diff <= 5:
      return `success`;
    case diff <= 75:
      return `warning`;
    default:
      return `danger`;
  }
}

export function renderPower(device) {
  const { icon: BatteryIcon } = getBatteryPercent(device.power);

  const renderSolar = (power) => {
    if (!power) {
      return <BatteryAlert />;
    }

    const sunPanel = () => (
        <Tooltip title="SolarBox">
          <ViewColumnIcon style={{ marginTop: 1 }} />
        </Tooltip>
    );

    if (power.volt >= 13) {
      return (<>
        {sunPanel()}
        <Tooltip title={`${power.volt}v`}>
          <BatteryChargingFullIcon />
        </Tooltip>
      </>);
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {sunPanel()}
          <Tooltip title={`${power.volt}v`}>
            <BatteryIcon />
          </Tooltip>
        </div>);
  };

  switch (device.attributes.powerSource) {
    case 'solar':
      return renderSolar(device.power);
    case 'adapter':
      return <PowerIcon />;
    default:
      return device.attributes.powerSource;
  }
}

export function renderConnection(device) {
  if (!device.interfaces) {
    return '';
  }

  const interfaces = device.interfaces.split(',');

  return interfaces.map(connection => {
    if (connection === 'eth0') {
      return (
          <Tooltip key={connection} title="LAN">
            <SettingsEthernetIcon key={connection} />
          </Tooltip>
      );
    }

    if (connection === 'wlan0') {
      return <WifiIcon key={connection} />;
    }

    return (
        <Tooltip title={device.signalStrength ? `${device.signalStrength.val} dBm` : 'No Signal'} key={connection}>
          {getSignalStrenght(device.signalStrength)}
        </Tooltip>
    );
  });
}

export function renderOperator(device) {

  return device.operatorIcon && (
      <Tooltip title={device.operator}>
        <img alt={device.operator} src={device.operatorIcon} width={21} />
      </Tooltip>

  );
}

export function renderLocation(device) {
  if (!device.location) {
    return '';
  }

  if (device.location.source === 'cloud') {
    return <CloudIcon />;
  }

  return <GpsFixedIcon />;
}

export function renderStayAwake(device) {
  const variant = device.stay_awake ? 'success-bs' : 'default-bs';

  return <i className={`fa fa-circle text-${variant}`} />;
}

export function getLastSeen(timestamp) {
  return moment(timestamp * 1000);
}


const getBatteryPercent = (power = {}) => {
  const { volt } = power;

  if (!volt) {
    return {
      value: 0,
      icon: BatteryAlert,
    };
  }

  switch (true) {
    case volt > 12.6:
      return {
        value: 100,
        icon: BatteryFullIcon,
      };
    case volt > 12.5:
      return {
        value: 90,
        icon: Battery90Icon,
      };
    case volt > 12.42:
      return {
        value: 80,
        icon: Battery80Icon,
      };
    case volt > 12.32:
      return {
        value: 70,
        icon: Battery80Icon,
      };
    case volt > 12.20:
      return {
        value: 60,
        icon: Battery60Icon,
      };
    case volt > 12.06:
      return {
        value: 50,
        icon: Battery50Icon,
      };
    case volt > 11.9:
      return {
        value: 40,
        icon: Battery50Icon,
      };
    case volt > 11.75:
      return {
        value: 30,
        icon: Battery30Icon,
      };
    case volt > 11.58:
      return {
        value: 20,
        icon: Battery20Icon,
      };
    case volt > 11.31:
      return {
        value: 10,
        icon: Battery20Icon,
      };
    default:
      return {
        value: 0,
        icon: BatteryAlert,
      };
  }
};

const getSignalStrenght = (signalStrength) => {

  if (!signalStrength) {
    return <SignalCellularNoInternetBarIcon />;
  }

  const strength = signalStrength.val;

  switch (true) {
    case strength > -70:
      return <SignalCellular4BarIcon />;
    case strength > -85:
      return <SignalCellular3BarIcon />;
    case strength > -100:
      return <SignalCellular2BarIcon />;
    case strength > -110:
      return <SignalCellular1BarIcon />;
    default:
      return <SignalCellularNoInternetBarIcon />;
  }
};
