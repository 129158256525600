import React, { useState, useEffect } from 'react';
import { Slider, FormControl, Select, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputLabel, makeStyles, TextField, MenuItem, FormGroup, CircularProgress, FormControlLabel, Checkbox, Switch, RadioGroup, Radio, FormLabel, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { get, isNull, isEmpty, omit, isUndefined, filter } from 'lodash';
import { Cancel, Send, ExpandMore } from '@material-ui/icons';
import { saveCompanyProfiles, editCompanyProfiles } from '../../services/profileServices';
import { HolidaysPicker, HolidaysPickerList } from '../index';
import { DateUtils } from 'react-day-picker';
import { notificationCenter } from '../../../helpers/notifications';
import moment from 'moment';

const breakouts = [
  {
    value: 'daily',
    label: 'Daily'
  },
  {
    value: 'weekly',
    label: 'Weekly'
  }
]

const useStyles = makeStyles((theme) => ({
  input: {
    width: '150px'
  },
  inputName: {
    width: '280px'
  },
  inputSwitch: {
    width: '100px'
  },
  select: {
    width: '150px'
  },
  formControl: {
    margin: "0px 20px 20px 0px"
  },
  accordion: {
    width: "100%"
  },
  fieldSet: {
    border: "1px groove #ddd !important",
    padding: "0 1.4em 1.4em 1.4em!important",
    margin: "0 0 1.5em 0 !important",
    boxShadow: "0px 0px 0px 0px #000",
    width: "80%"
  },
  legend: {
    border: 'none',
    paddingLeft: '6px',
    paddingRight: '6px',

    width: 'auto'
  }

}));


const WeekDaysHourType = (props) => {

  const { day, currentProfile, onWeekDay } = props;

  return (
    <FormControl style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '18px' }}>
      <FormLabel style={{ marginRight: '15px' }}>{get(day, 'value', '')}</FormLabel>
      <RadioGroup row aria-label="quiz" name="quiz" value={get(currentProfile, `days[${day.key}]`, '')} onChange={(e) => onWeekDay(get(day, 'key'), e.target.value)} onClick={(e) => onWeekDay(get(day, 'key'), e.target.value, 'click')} >
        <FormControlLabel value="OT" control={<Radio color="primary" />} label="OT" />
        <FormControlLabel value="DT" control={<Radio color="primary" />} label="DT" />
      </RadioGroup>
    </FormControl>
  )

}

export default function Profile(props) {

  const { profile, onCloseProfile, company, payment_profile_endpoint } = props;
  const [isValid, setIsValid] = useState(false)
  const classes = useStyles();

  const days = [
    {
      value: 'Monday',
      key: 'monday'
    },
    {
      value: 'Tuesday',
      key: 'tuesday'
    },
    {
      value: 'Wednesday',
      key: 'wednesday'
    },
    {
      value: 'Thursday',
      key: 'thursday'
    },
    {
      value: 'Friday',
      key: 'friday'
    },
    {
      value: 'Saturday',
      key: 'saturday'
    },
    {
      value: 'Sunday',
      key: 'Sunday'
    }
  ]

  const [state, setState] = useState({
    currentProfile: {
      ...profile,
      earlyStart: {
        status: !isNull(get(profile, 'earlyStart', null)),
        value: get(profile, 'earlyStart', '')
      },
      minimumHours: {
        status: !isNull(get(profile, 'minimumHours', null)),
        value: get(profile, 'minimumHours', 0)
      }
    },
    editing: false,
    isValid: false,
    otHolidaysSelected: get(profile, 'otHolidays', []).map(item => moment(item).toDate()),
    dtHolidaysSelected: get(profile, 'dtHolidays', []).map(item => moment(item).toDate()),
    slider: get(profile, 'range', []),
    maxSlider: get(profile, 'breakout', 'weekly') ? 168 : 24,
    range: {
      reg: 0,
      ot: 0,
      dt: 0
    }
  });

  useEffect(() => {

    const getRange = () => {
      if (get(state.currentProfile, 'overtime', false) && get(state.currentProfile, 'doubletime', false)) {
        return [get(state, 'slider.0', state.maxSlider), get(state, 'slider.1', state.maxSlider)]
      } else if ((get(state.currentProfile, 'overtime', false) && !get(state.currentProfile, 'doubletime', false)) || !get(state.currentProfile, 'overtime', false) && get(state.currentProfile, 'doubletime', false)) {
        return [isEmpty(get(state, 'slider')) ? state.maxSlider : get(state, 'slider.0')]
      } else if (!get(state.currentProfile, 'overtime', false) && !get(state.currentProfile, 'doubletime', false)) {
        return []
      }
    }

    console.log(getRange())

    setState(prevState => ({
      ...prevState,
      slider: getRange()
    }))
  }, [get(state.currentProfile, 'overtime'), get(state.currentProfile, 'doubletime')]);


  useEffect(() => {
    let tempRange = {};
    if (get(state.currentProfile, 'overtime') && !get(state.currentProfile, 'doubletime')) {
      tempRange = {
        reg: get(state.slider, '0') < state.maxSlider ? `0 - ${get(state.slider, '0')}` : `0 - ${state.maxSlider}`,
        ot: get(state.slider, '0') < state.maxSlider ? `${get(state.slider, '0')} - ${state.maxSlider}` : 0,
        dt: 0
      }
      // console.log("tempRange ", tempRange, ' ', state)

    } else if (!get(state.currentProfile, 'overtime') && get(state.currentProfile, 'doubletime')) {
      tempRange = {
        reg: get(state.slider, '0') < state.maxSlider ? `0 - ${get(state.slider, '0')}` : `0 - ${state.maxSlider}`,
        dt: get(state.slider, '0') < state.maxSlider ? `${get(state.slider, '0')} - ${state.maxSlider}` : 0,
        ot: 0
      }

    } else if (get(state.currentProfile, 'overtime') && get(state.currentProfile, 'doubletime')) {
      tempRange = {
        reg: get(state.slider, '0') < state.maxSlider ? `0 - ${get(state.slider, '0')}` : `0 - ${state.maxSlider}`,
        ot: get(state.slider, '0') < state.maxSlider ? `${get(state.slider, '0')} - ${get(state.slider, '1')}` : 0,
        dt: get(state.slider, '1') < state.maxSlider ? `${get(state.slider, '1')} - ${state.maxSlider}` : 0
      }


    } else if (!get(state.currentProfile, 'overtime') && !get(state.currentProfile, 'doubletime')) {
      tempRange = {
        reg: get(state.slider, '0') < state.maxSlider ? `0 - ${get(state.slider, '0')}` : `0 - ${state.maxSlider}`,
        ot: 0,
        dt: 0
      }

    }


    setState(prevState => ({
      ...prevState,
      range: tempRange
    }))

  }, [state.slider])


  const handleClose = async (flag = false) => {
    if (flag) {
      try {
        if (!isEmpty(get(state.currentProfile, 'name')) && !isEmpty(state.currentProfile, 'breakout') && !isEmpty(state.currentProfile, 'company_id')) {
          let payload = {
            ...state.currentProfile,
            otHolidays: state.otHolidaysSelected.map(item => item.toLocaleDateString()),
            dtHolidays: state.dtHolidaysSelected.map(item => item.toLocaleDateString()),
            range: get(state, 'slider')
          }

          if (!get(state.currentProfile, 'overtime', false) && !get(state.currentProfile, 'doubletime', false)) {
            payload = {
              ...payload,
              regular: true
            }
          }

          setState(prevState => ({
            ...prevState,
            editing: true
          }))

          if (isNull(profile)) {
            await saveCompanyProfiles(get(company, '_id.$oid'), payload, payment_profile_endpoint);
          } else {
            await editCompanyProfiles(get(company, '_id.$oid'), get(profile, '_id'), payload, payment_profile_endpoint);
          }

          onCloseProfile(true);
        } else {
          setState(prevState => ({
            ...prevState,
            editing: false
          }))
          notificationCenter("warning", "Some fields are required");

        }

      } catch (error) {
        setState(prevState => ({
          ...prevState,
          editing: false
        }))
        notificationCenter("warning", get(error, 'response.data.error', 'Error'))
      }
    } else {
      onCloseProfile(false)
    }

  }

  const valueText = (value) => {
    return value
  }

  const onBasicChange = (field, value) => {
    setState(prevState => ({
      ...prevState,
      currentProfile: {
        ...prevState.currentProfile,
        [field]: value
      }
    }))
  }

  const handleChangeSlider = (event, newValue) => {

    setState(prevState => ({
      ...prevState,
      slider: newValue
    }))
  }

  const onChangeBreakout = (value) => {
    setState(prevState => ({
      ...prevState,
      currentProfile: {
        ...prevState.currentProfile,
        breakout: value,
        overtime: false,
        doubletime: false,
      },
      range: {
        ...prevState.range,
        reg: `0 - ${value === 'weekly' ? 168 : 24}`
      },
      slider: value === 'weekly' ? [get(profile, 'maxRange', 168)] : [get(profile, 'maxRange', 24)],
      maxSlider: value === 'weekly' ? 168 : 24
    }))
  }

  const onChangeHourType = (type, checked) => {

    console.log(type, state.currentProfile)
    if (type === 'doubletime' && !get(state.currentProfile, 'overtime', false)) {
      notificationCenter("warning", "Payment profile requiere overtime to have doubletime");
    } else if (type === 'overtime' && !checked && get(state.currentProfile, 'doubletime', false)) {
      notificationCenter("warning", "unCheck doubletime before uncheck overtime");
    } else {
      setState(prevState => ({
        ...prevState,
        currentProfile: {
          ...prevState.currentProfile,
          [type]: checked
        }
      }));
    }
  }


  const onChangeSwitch = (type, status) => {
    setState(prevState => ({
      ...prevState,
      currentProfile: {
        ...prevState.currentProfile,
        [type]: {
          ...prevState.currentProfile[type],
          status
        }
      }
    }))
  }

  const onChangeBasicHours = (type, value) => {
    setState(prevState => ({
      ...prevState,
      currentProfile: {
        ...prevState.currentProfile,
        [type]: {
          ...prevState.currentProfile[type],
          value
        }
      }
    }))
  }

  const onWorkedLunchBreak = (e) => {
    setState(prevState => ({
      ...prevState,
      currentProfile: {
        ...prevState.currentProfile,
        'workedLunchBreak': e.target.value
      }
    }))
  }

  const onChangeRatios = (type, value) => {
    setState(prevState => ({
      ...prevState,
      currentProfile: {
        ...prevState.currentProfile,
        'ratios': {
          ...get(prevState.currentProfile, 'ratios', {}),
          [type]: value
        }
      }
    }))
  }

  const onWeekDay = (day, value, actionType = 'change') => {

    const currentDay = get(state.currentProfile, `days.${day}`);

    if (!isUndefined(currentDay) && currentDay === value) {

      setState(prevState => ({
        ...prevState,
        currentProfile: {
          ...prevState.currentProfile,
          days: omit(get(prevState.currentProfile, 'days'), day)
        }
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        currentProfile: {
          ...prevState.currentProfile,
          days: {
            ...prevState.currentProfile.days,
            [day]: value
          }
        }
      }))
    }


  }

  const onHandelOTHolidays = (day, { selected }) => {

    const selectedDays = state.otHolidaysSelected.concat();

    if (selected) {
      const selectedIndex = selectedDays.findIndex(selectedDay => {
        return DateUtils.isSameDay(selectedDay, day)
      });


      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }

    setState(prevState => ({
      ...prevState,
      otHolidaysSelected: selectedDays
    }))
  }
  const onHandelDTHolidays = (day, { selected }) => {
    const selectedDays = state.dtHolidaysSelected.concat();
    if (selected) {
      const selectedIndex = selectedDays.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }

    setState(prevState => ({
      ...prevState,
      dtHolidaysSelected: selectedDays
    }))
  }

  const onEarlyStartBreakout = (e) => {
    setState(prevState => ({
      ...prevState,
      currentProfile: {
        ...prevState.currentProfile,
        earlyStart: {
          status: true,
          breakout: e
        }
      }
    }))
  }

  return (


    <div style={{ padding: '30px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <div>
        <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Add Profile</span>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField label="Profile Name" required onChange={(e) => onBasicChange('name', e.target.value)} className={classes.inputName} value={get(state.currentProfile, 'name', '')} />
          <div style={{ marginTop: '13px', marginLeft: '9px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={get(state.currentProfile, 'default', false)}
                  onChange={(e) => onBasicChange('default', e.target.checked)}
                  name="default"
                  color="primary"
                />
              }
              label="Default"
            />
          </div>
        </div>
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Breakout</InputLabel>
            <Select
              required
              classes={{ root: classes.select }}
              id="demo-simple-select"
              value={get(state.currentProfile, 'breakout', '')}
              onChange={(e) => onChangeBreakout(e.target.value)}
            >
              {breakouts.map(item => (
                <MenuItem key={get(item, 'value')} value={get(item, 'value')}>{get(item, 'label')}</MenuItem>
              ))}

            </Select>
          </FormControl>
        </div>
        <hr style={{ margin: '0px' }} />
        <div>
          <div style={{ marginBottom: '50px' }}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isNull(get(state.currentProfile, 'breakout', null))}
                    checked={get(state.currentProfile, 'overtime', false)}
                    onChange={(e) => onChangeHourType('overtime', e.target.checked)}
                    name="Overtime"
                    color="primary"
                  />
                }
                label="Overtime"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isNull(get(state.currentProfile, 'breakout', null))}
                    checked={get(state.currentProfile, 'doubletime', false)}
                    onChange={(e) => onChangeHourType('doubletime', e.target.checked)}
                    name="Doubletime"
                    color="primary"
                  />
                }
                label="Doubletime"
              />
            </FormGroup>
          </div>
          <Slider
            value={state.slider}
            disabled={isNull(get(state.currentProfile, 'breakout', null)) || isEmpty(get(state, 'slider'))}
            min={0}
            max={state.maxSlider}
            valueLabelDisplay="on"
            onChange={handleChangeSlider}
            aria-labelledby="range-slider"
            getAriaValueText={valueText}
          />


          <fieldset className={classes.fieldSet}>
            <legend className={classes.legend}>Range</legend>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span><strong>Regular:</strong> {get(state.range, 'reg', "-")}</span>
              <span><strong>Overtime:</strong> {get(state.range, 'ot', "-")}</span>
              <span><strong>Doubletime:</strong> {get(state.range, 'dt', "-")}</span>
            </div>
          </fieldset>

        </div>
        <hr style={{ margin: '0px' }} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div >
            <fieldset className={classes.fieldSet}>
              <legend className={classes.legend}>Rates</legend>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <span style={{ fontWeight: 'bold', marginRight: '7px' }}>REG:</span>
                <TextField type="number" onChange={(e) => onChangeRatios('regular', e.target.value)} className={classes.inputSwitch} value={get(state.currentProfile.ratios, 'regular', 1)} />
              </div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <span style={{ fontWeight: 'bold', marginRight: '7px' }}>OT:</span>
                <TextField type="number" onChange={(e) => onChangeRatios('overtime', e.target.value)} className={classes.inputSwitch} value={get(state.currentProfile.ratios, 'overtime', 1.5)} />
              </div>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <span style={{ fontWeight: 'bold', marginRight: '7px' }}>DT:</span>
                <TextField type="number" onChange={(e) => onChangeRatios('doubletime', e.target.value)} className={classes.inputSwitch} value={get(state.currentProfile.ratios, 'doubletime', 2)} />
              </div>
            </fieldset>
          </div>


          <div style={{ marginTop: '18px' }}>
            <fieldset className={classes.fieldSet}>
              <legend className={classes.legend}>Early Start</legend>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <FormControl>
                  <FormLabel >Breakout</FormLabel>
                  <RadioGroup row aria-label="quiz" name="quiz" value={get(state.currentProfile, 'earlyStart.breakout', '')} onChange={(e) => onEarlyStartBreakout(e.target.value)} >
                    <FormControlLabel value="OT" control={<Radio color="primary" />} label="OT" />
                    <FormControlLabel value="DT" control={<Radio color="primary" />} label="DT" />
                    <FormControlLabel value="REG" control={<Radio color="primary" />} label="REG" />
                  </RadioGroup>
                </FormControl>
                <TextField disabled={!get(state.currentProfile, 'earlyStart.status', false)} type="time" onChange={(e) => onChangeBasicHours('earlyStart', e.target.value)} className={classes.inputSwitch} value={get(state.currentProfile, 'earlyStart.value', '')} />
              </div>
            </fieldset>

            <div>
              <fieldset className={classes.fieldSet}>
                <legend className={classes.legend}>Guaranteed Minimum Hours</legend>
                <FormControlLabel
                  control={
                    <Switch
                      checked={get(state.currentProfile, 'minimumHours.status', false)}
                      onChange={(e) => onChangeSwitch('minimumHours', e.target.checked)}
                      color='primary'
                      name="guaranteedHours" />
                  }
                />
                <TextField disabled={!get(state.currentProfile, 'minimumHours.status', false)} type="number" onChange={(e) => onChangeBasicHours('minimumHours', e.target.value)} className={classes.inputSwitch} value={get(state.currentProfile, 'minimumHours.value', '')} />
              </fieldset>

            </div>

          </div>

        </div>
        <div style={{ marginTop: '15px' }}>
          <fieldset className={classes.fieldSet}>
            <legend className={classes.legend}>Worked Lunch Break</legend>
            <FormControl>
              <RadioGroup row aria-label="quiz" name="quiz" value={get(state.currentProfile, 'workedLunchBreak', 'REG')} onChange={onWorkedLunchBreak} >
                <FormControlLabel value="OT" control={<Radio color="primary" />} label="OT" />
                <FormControlLabel value="DT" control={<Radio color="primary" />} label="DT" />
                <FormControlLabel value="REG" control={<Radio color="primary" />} label="REG" />
              </RadioGroup>
            </FormControl>
          </fieldset>
        </div>
        <hr />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'inherit' }}>
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <strong>Weekday Configuration</strong>
              </AccordionSummary>
              {days.map((day, index) => (
                <WeekDaysHourType key={index} day={day} currentProfile={state.currentProfile} onWeekDay={onWeekDay} />
              ))}
            </Accordion>
          </div>
        </div>
        <hr />

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <strong>Holidays Configuration</strong>
            </AccordionSummary>
            <div style={{ display: 'flex', width: '100%' }}>
              <HolidaysPicker pickerTitle="OT Holidays" selectedDays={state.otHolidaysSelected} handleDayClick={onHandelOTHolidays} />
              <HolidaysPickerList days={state.otHolidaysSelected} handleDayClick={onHandelOTHolidays} />
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <HolidaysPicker pickerTitle="DT Holidays" selectedDays={state.dtHolidaysSelected} handleDayClick={onHandelDTHolidays} />
              <HolidaysPickerList days={state.dtHolidaysSelected} handleDayClick={onHandelDTHolidays} />
            </div>
          </Accordion>

        </div>


      </div>
      <div style={{ marginTop: '20px' }}>
        <Button
          variant="contained"
          onClick={() => handleClose(false)}
          startIcon={<Cancel />}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className='ml-1'
          // disabled={!isValid}
          style={{ marginLeft: '5px', color: '#009687' }}
          onClick={() => handleClose(true)}
          startIcon={state.editing ? <CircularProgress size={10} color="primary" /> : <Send />}>
          Save
        </Button>

      </div>
    </div>
  )
};
