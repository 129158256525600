import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CloudDownload from '@material-ui/icons/CloudDownload';
import BroadcastDialog, { formatUSNumber } from './broadcastDialog';
import { $axios } from '../../../helpers';
import { SettingsContext } from '../../contexts/SettingsContext';
import { CircularProgress, IconButton } from '@material-ui/core';
import { CardDash } from '../../components';
import ReplayIcon from '@material-ui/icons/Autorenew';
import moment from 'moment/moment';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import BroadcastTargetsDialog from './broadcastTargetsDialog';
import workerProfileIcon from '../../../../../assets/images/worker_types/constructionWorkerIcon-2023.svg';
import { get } from 'lodash';

const useStyles = makeStyles(() => ({
  avatar: {
    width: 35,
    height: 35,
    borderRadius: '50%',
    minWidth: 35,
    minHeight: 35,
  },
  profileBox: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    lineHeight: 1,
  },
}));

const BroadcastingTool = ({ project: { _id: { $oid: projectID } }, config, height }) => {
  const settingContext = useContext(SettingsContext);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadBroadcastItems = () => {
    const date = settingContext.onGetTimeSheetDateFormat();
    setLoading(true);

    $axios.$get(`${config.broadcastEndpoint}/list/${projectID}/${date}`)
        .then(setItems)
        .catch(console.warn)
        .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadBroadcastItems();
  }, [settingContext.timesheetDate]);

  const broadcastStatus = (status) => {
    if (status === 'executed') {
      return 'success';
    }

    return 'primary';
  };

  return (
      <CardDash
          accordionDetailStyles={{ padding: 0 }}
          title="Broadcasting Tool"
          height={height}
          actions={
            <Grid alignItems={'center'} spacing={1} container>
              <Grid item>
                <BroadcastDialog
                    title="Add"
                    config={config}
                    project={projectID}
                    onBroadcast={loadBroadcastItems}
                />
              </Grid>
              <Grid item>
                <IconButton
                    disabled={loading}
                    onClick={loadBroadcastItems}>
                  <ReplayIcon className={loading ? 'fa-spin' : ''} />
                </IconButton>
              </Grid>
            </Grid>
          }>
        {!loading && (<Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: '12em' }}>Sender</TableCell>
              <TableCell style={{ minWidth: '12em' }}>Date Time</TableCell>
              <TableCell style={{ minWidth: '12em' }}>Status</TableCell>
              <TableCell style={{ minWidth: '12em' }}>Target</TableCell>
              <TableCell align="right">
                {/*<span>Report</span>*/}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(({ _id, user, created_at, status, targets }) => (
                <TableRow key={_id}>
                  <TableCell>
                    <BroadcastSenderProfile user={user} />
                  </TableCell>
                  <TableCell>{moment(created_at).calendar()}</TableCell>
                  <TableCell>
                    <Chip label={status} className={`bg-${broadcastStatus(status)}`} />
                  </TableCell>
                  <TableCell>
                    <BroadcastTargetsDialog broadcast={_id} targets={targets} endpoint={config.broadcastEndpoint} />
                  </TableCell>
                  <TableCell align="right">
                    {/*<CloudDownload />*/}
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>)}

        {!loading && !items.length && (<Grid justifyContent={'center'} className="p-t-4" container>
          {(<span>No items to show</span>)}
        </Grid>)}

        {loading && (<Grid justifyContent={'center'} className="p-t-4" container>
          {(<CircularProgress />)}
        </Grid>)}
      </CardDash>);
};

const BroadcastSenderProfile = ({ user }) => {
  const classes = useStyles();

  return (
      <div className={classes.profileBox}>
        <object data={workerProfileIcon} type="image/svg+xml" style={{ width: 32 }}>
          <img alt="workerPic" src={get(user, 'image_path')} className={classes.avatar} />
        </object>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }}>
          <Typography variant="subtitle2">
            <span>{user.first_name} {user.last_name}</span>
          </Typography>
          <a href={`tel:+1${user.phone_number}`} style={{ fontSize: 10, marginTop: 2 }}>{formatUSNumber(user.phone_number)}</a>
        </div>
      </div>
  );
};

export default BroadcastingTool;
