import React, { useState } from 'react'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import Editable from "./support/Editable";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@material-ui/core";
import axios from "axios";

export default function PunchTime({ punch, onUpdate }) {

  const [punchState, setPunchState] = useState({ ...punch });
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const buildPayload = () => {
    var csrfToken = $('meta[name="csrf-token"]').attr("content");

    let fd = new FormData()
    fd.append("authenticity_token", csrfToken)
    const formLabel = punch.type == 'punch-in' ? 'punch_in_time' : 'punch_out_time'
    fd.append(`shifts[${formLabel}[${punch.shift_id.$oid}]]`, formatTime(true, punchState))
    return fd
  }
  const handleSave = () => {
    const endpoint = `${location.origin}/projects/${punch.project_id.$oid}/edit_timesheet/${punch.employee_id.$oid}`
    console.log({ endpoint });
    axios.post(endpoint, buildPayload()).then(response => {
      onUpdate(punchState)
      handleClose()
    }).catch(errorSavingPunchtime => {
      console.error({ errorSavingPunchtime })
      handleClose()
    })
  }

  const handleDateChange = (val, ev) => {
    punchState.punch_time = val.getTime() / 1000
    setPunchState({ ...punchState })
  }

  const formatTime = (useYear, state) => {
    let punchTimeParsed = new Date(state.punch_time * 1000)
    let fmt = "MM/DD"
    if (useYear) {
      fmt += "/YYYY"
    }
    fmt += " hh:mm A"
    return moment(punchTimeParsed).format(fmt)
  }


  return (
    <Editable onEdit={handleOpen}>
      {formatTime(false, punch)}
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle>Change Punch Time</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Change punch time below
            </DialogContentText>
          <KeyboardDateTimePicker

            margin="normal"
            id="date-picker-dialog"
            label="select"
            format="MM/dd/yyyy hh:mm a"
            value={formatTime(true, punchState)}
            name="datetime"
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change punch time',
            }}

          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
            </Button>
          <Button onClick={handleSave} color="primary">
            Save
            </Button>
        </DialogActions>
      </Dialog>
    </Editable>
  )
}
