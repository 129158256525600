import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { myTheme } from '../helpers';
import SettingsContextProvider from '../employees/contexts/SettingsContext';
import { CircularProgress, makeStyles } from '@material-ui/core';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import { gettingCompanyEmployeeSettings } from '../employees/services/employeeService';
import { HeaderComponent, TableComponent } from './components/index';
import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
  dialogLoading: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    backgroundColor: '#ffffffa6',
    zIndex: '9999999',
    justifyContent: "center",
    alignItems: "center"
  }
  // drawerRoot: {
  //   width: `${isMobile ? '75%' : '30%'}`
  // }

}));

export default function PaymentProfiles(props) {

  const { company, config } = props;

  const companyWorkersDomain = get(config, 'companyWorkersDomain');
  const payment_profile_endpoint = get(config, 'payment_profile_endpoint');

  const [state, setState] = useState({
    loading: false,
    currentCompany: {
      value: get(company, '_id.$oid'),
      label: get(company, 'name')
    },
    companies: []
  });

  const onLoading = (flag = true) => {
    setState(prevState => ({
      ...prevState,
      loading: flag
    }));
  }

  const getSettings = async () => {
    try {
      onLoading()
      const res = await gettingCompanyEmployeeSettings(companyWorkersDomain);
      setState(prevState => ({
        ...prevState,
        companies: get(res, 'data.companies'),
        currentCompany: get(res, 'data.companies').find(item => get(item, 'value') === get(company, '_id.$oid'))
      }))

      onLoading(false)

    } catch (error) {

    }
  }

  const onCompanyFilterChange = value => {
    setState(prevState => ({
      ...prevState,
      currentCompany: value
    }))
  }

  const classes = useStyles();

  useEffect(() => {
    getSettings()
  }, [])

  return (
    <ThemeProvider theme={myTheme}>
      <SettingsContextProvider>
        {state.loading &&
          <div className={classes.dialogLoading}>
            <CircularProgress color="primary" />
          </div>}
        <div id="jumbo-styles-container">
          <HeaderComponent currentCompany={state.currentCompany} />
          <TableComponent company={company} currentCompany={state.currentCompany} onLoading={onLoading} companies={state.companies} payment_profile_endpoint={payment_profile_endpoint} />

        </div>
      </SettingsContextProvider>
      <NotificationContainer />
    </ThemeProvider >
  )

};
