import React, { useState, useLayoutEffect } from 'react';
import axios from "axios";
import DeviceComment from "./DeviceComment";


const DeviceComments = ({ deviceId }) => {
  
  const [comments, setComments] = useState([]);

  const loadComments = () => {
    const url = `${location.protocol}//${location.host}/devices/${deviceId}/device_comments.json`
    axios.get(url).then(response => {
      const loaded = response.data
      console.log({ loaded })
      setComments([...loaded])
    })
  }

  const addNew = (ev) => {
    ev.preventDefault()
    comments.push({
      text: '',
      created_by: null,
      id: null,
    })
    setComments([...comments])
  }

  useLayoutEffect(() => {
    loadComments()
    return () => { };
  }, [])
  
  return (

  <div className="table-responsive">
    <table className="table table-hover table-condensed-x table-striped" style={{whiteSpace: "nowrap"}}>
      <thead>
        <tr>
          <th>User</th>
          <th>Last Updated</th>
          <th>Text</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          comments.map(comment => {
            if (comment.id) {
              return <DeviceComment  key={comment.id.$oid} deviceId={deviceId} onSaved={loadComments} comment={comment} isEditing={false}/>
            } else {
              return <DeviceComment  key={-1} deviceId={deviceId} onSaved={loadComments} comment={comment} isEditing={true}/>
            }

          })
        }
      </tbody>
    </table>
    <button className="btn btn-sm btn-outline" onClick={addNew}>Add</button>
  </div>
  );
}

export default DeviceComments;
