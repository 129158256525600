import React, { useState } from 'react'
import { Button, Modal } from "react-bootstrap";
import axios from "axios"
import moment from 'moment';

const PunchDetails = ({ punchId }) => {

  const [show, setShow] = useState(false);
  const [punch, setPunch] = useState(null);

  const handleClose = () => setShow(false);
  const handleOpen = () => {
      setShow(true)
      load()
  } ;
  
  const load = () => {
    let url = `${window.location.protocol}//${window.location.host}/punches/${punchId}.json`
    return axios.get(url).catch(console.error).then(resp => {
        console.log({ resp })
        setPunch(resp.data)
    })
  }
  
  
  if (!punch) {
     return  <i className="fa fa-info" onClick={handleOpen}></i>
  } else {
    let duration = "N/A"
    if (punch.duration && punch.duration.finished && punch.duration.initiated) {
         duration = punch.duration.finished - punch.duration.initiated
    }
    return (
        <>
     <i className="fa fa-info" onClick={handleOpen}></i>
      <Modal show={show} onHide={handleClose} size="lg" animation={false} backdrop={true} backdropClassName="modal-backdrop fade in">
        <Modal.Header closeButton>
          <Modal.Title>Worker: <strong>{`${punch.employee.first_name} ${punch.employee.last_name}`}</strong> </Modal.Title>
          UUID: <strong>{punch.uuid}</strong>
        </Modal.Header>
        <Modal.Body>

          <table className="table" style={{whiteSpace: "nowrap"}}>
            <thead>
              <tr>
                <th scope="col">Employee</th>
                <th scope="col">Punch</th>
                <th scope="col">Punched At</th>
                <th scope="col">Cost Code</th>
                <th scope="col">Type</th>
                <th scope="col">Duration</th>
                <th scope="col">Project</th>
                <th scope="col">Company</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="col">{`${punch.employee.first_name} ${punch.employee.last_name}`}</th>
                <th scope="col">{punch.type}</th>
                <th scope="col">{moment.unix(punch.punch_time).format("MM/DD/YYYY hh:mm A")}</th>
                <th scope="col">{punch.cost_code}</th>
                <th scope="col">{punch.entry_type}</th>
                <th scope="col">{duration}</th>
                <th scope="col">{punch.project ? punch.project.name : 'N/A'}</th>
                <th scope="col">{punch.company ? punch.company.name : 'N/A'}</th>
              </tr>
            </tbody>
          </table>


        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
      </>
    )
  }
}

export default PunchDetails;