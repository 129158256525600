import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContentText, TextField, Button, DialogContent, DialogActions } from '@material-ui/core'
import axios from "axios";
import { axiosConfig } from "./helpers"
import Editable from "./support/Editable";

export default function Breaktime({ shift }) {

  const [shiftState, setShiftState] = useState(shift);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setShiftState({ ...shift })
  }
  const handleChange = (ev) => {
    shiftState.break_time = ev.target.value
    setShiftState({ ...shiftState })
  }
  const buildPayload = () => {
    var csrfToken = $('meta[name="csrf-token"]').attr("content");

    let fd = new FormData()
    fd.append("authenticity_token", csrfToken)
    fd.append(`shifts[break[${shift._id.$oid}]]`, shiftState.break_time)
    return fd
  }
  const handleSave = (ev) => {
    const endpoint = `${location.origin}/projects/${shift.project_id.$oid}/edit_timesheet/${shift.employee_id.$oid}`
    console.log({ endpoint });
    axios.post(endpoint, buildPayload(), axiosConfig()).then(response => {
      shift.break_time = shiftState.break_time
      handleClose()
    }).catch(errorSavingBreaktime => {
      console.error({ errorSavingBreaktime })
      handleClose()
    })
  }

  const breakTime = shiftState.break_time ? `${shiftState.break_time} minutes` : ''

  return (
    <Editable onEdit={handleOpen}>
      {breakTime}
      {open && (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle>Change Breaktime</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Change shift breaktime below
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Breaktime"
              value={shiftState.break_time}
              type="number"
              onChange={handleChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Editable>
  )
}
