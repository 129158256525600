import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, makeStyles, MenuItem, TextField, FormControlLabel, FormHelperText } from '@material-ui/core';
import _ from 'lodash';
import { promotingWorker } from '../../../services/employeeService'
import { notificationCenter } from '../../../../helpers/notifications';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SBSwitch } from '../../../../reusableComponents';

const useStyles = makeStyles((theme) => ({
  mainContent: {
    backgroundColor: '#f3f3f4',
    height: "100%",
    width: "100%",
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  inputStyle: {
    width: '100%'
  },
  inputDiv: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  formControl: {
    // margin: theme.spacing(1),
    width: "100%",
    marginTop: '16px'
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));


export default function PromoteWorker(props) {

  const { worker, open, projects, promoteWorkerEndpoint, onClosePromoteWorker, userTypes } = props;

  const [state, setState] = useState({
    ...worker
  });

  const [saving, setSaving] = useState(false);
  const [projectList, setProjectList] = useState(true);

  useEffect(() => {
    if (!_.isNull(_.get(state, 'user_type')))
      setProjectList(_.get(state, 'user_type') === 'admin')
  }, [state.user_type])


  const handleClose = async (flag) => {

    if (!flag) {
      onClosePromoteWorker()
    } else {

      if (_.isEmpty(_.get(state, 'email')) || _.isEmpty(_.get(state, 'user_type'))) {
        notificationCenter('error', `You are missing some required fields`);
      } else {
        try {
          setSaving(true);
          const res = await promotingWorker(state, _.get(state, '_id'), promoteWorkerEndpoint);
          notificationCenter('success', _.get(res, 'data.message', 'Employee has been promoted'));
          setSaving(false);
          onClosePromoteWorker()
        } catch (error) {
          notificationCenter('error', _.get(error.response, 'data.error'));
          onClosePromoteWorker()

        }
      }

    }
  }

  const onBasicChange = (field, value) => {
    if (field === 'user_type') {
      setState(prevState => ({
        ...prevState,
        [field]: _.get(value, 'value', ''),
        helperText: _.get(value, 'description')
        // readOnly: true
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        [field]: value
      }))
    }
  }

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`Promoting worker ${_.get(state, 'first_name')} ${_.get(state, 'last_name')}`}</DialogTitle>
      <div>
        <div className='' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>

          <TextField id="email" required onChange={(e) => onBasicChange('email', e.target.value)} value={_.get(state, 'email', '')} label="Email" className={classes.inputStyle} />

          <FormControl className={classes.formControl}>
            <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'center' }}>
              <Autocomplete
                id="combo-box-demo"
                options={userTypes}
                getOptionLabel={(option) => option.label}
                onChange={(e, t) => onBasicChange('user_type', t)}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="User Type" />}
              />
            <FormHelperText style={{ maxWidth: 300 }}>{_.get(state, 'helperText', '')}</FormHelperText>
            {/*<FormControlLabel
                control={<SBSwitch color="primary" handleToggle={(e) => onBasicChange('readOnly', !_.get(state, 'readOnly'))} checked={_.get(state, 'readOnly', true)} disabled={_.get(state, 'user_type') !== 'user'} />}
                label="Read-Only"
                style={{ marginLeft: 8, marginTop: 16 }}
              />*/}
            </div>
          </FormControl>


          <FormControl className={classes.formControl}>
            <Autocomplete
              disabled={projectList}
              id="combo-box-demo"
              size="small"
              options={projects}
              multiple={true}
              getOptionLabel={(option) => option.label}
              onChange={(e, t) => onBasicChange('projects', t)}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Projects" />}
            />
          </FormControl>

        </div>
      </div>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          Cancel
        </Button>
        <Button disabled={saving} onClick={() => handleClose(true)} color="primary" >
          {saving && <CircularProgress color='primary' size={10} style={{ marginRight: '5px' }} />} Save
        </Button>
      </DialogActions>
    </Dialog>
  )
};
