import React, { useState, useEffect, useContext } from 'react';
import { get, isNull, cloneDeep } from 'lodash';
import { CircularProgress, ThemeProvider, Drawer } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { myTheme } from '../../helpers/index';
import SettingsContextProvider from '../contexts/SettingsContext';
import 'react-notifications/src/notifications.scss';
import { NotificationContainer } from 'react-notifications';
import { allColumns, defaultColumns } from '../constants/workerTableColumns';
import { useMediaQuery } from 'react-responsive';
import { BulkUploadWorkers } from './components/index'

import {
  TopHeaderPage,
  EmployeeCards,
  TableFilters,
  TableWorkers,
  ProjectSettings
} from './components/index'
import {
  gettingEmployeeCards,
  gettingFilters,
  gettingCompanyEmployeeSettings
} from '../services/employeeService';
import { notificationCenter } from '../../helpers/notifications';

export default function ProjectEmployees(props) {

  const drawerSize = (deskTopSize = '30%') => {
    const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 480 });
    const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 768 });
    const isLaptop = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
    const isDesktop = useMediaQuery({ minWidth: 1025 });

    switch (true) {
      case isMobile:
        return '100%'
      case isTablet:
        return '90%'
      case isLaptop:
        return '75%';
      case isDesktop:
        return deskTopSize;
      default:
        return "80%";
    }
  }

  const useStyles = makeStyles((theme) => ({
    dialogLoading: {
      position: "absolute",
      width: "100%",
      height: "100%",
      display: "flex",
      backgroundColor: '#ffffffa6',
      zIndex: '9999999',
      justifyContent: "center",
      alignItems: "center"
    },
    drawerRoot: {
      width: drawerSize('40%')
    },
    bulkUploadDrawer: {
      width: drawerSize('30%')
    }
  }));

  const { project, config, currentEmployee } = props;

  const classes = useStyles();

  const projectDomain = `${get(config, 'projectWorkersDomain')}/${project}/workers`;
  const employee_settings_endpoint = get(config, 'employee_settings_endpoint');
  const companyWorkersDomain = `${get(config, 'companyWorkersDomain')}`;
  const bulkUploadWorkersWebsocketUrl = get(config, 'bulkUploadWorkersWebsocketUrl');
  const fileUploadEndpoint = get(config, 'fileUploadEndpoint');
  const sessionResultsEndpoint = get(config, 'sessionResultsEndpoint');


  const [state, setState] = useState({
    totalWorkers: 30,
    employeeCards: [],
    showCharts: false,
    loading: true,
    revoke: false,
    searchValue: '',
    project: null,
    company: null,
    filters: null,
    permissions: null

  });

  const getFilters = async () => {
    try {
      const res = await gettingFilters(projectDomain);
      let tempProject = {
        ...get(res, 'data.project'),
        _id: {
          $oid: get(res, 'data.project._id')
        }
      };

      let tempCompany = {
        ...get(res, 'data.company'),
        _id: {
          $oid: get(res, 'data.company._id')
        }
      };

      setState(prevState => ({
        ...prevState,
        project: tempProject,
        company: tempCompany,
        filters: get(res, 'data.filters'),
        permissions: get(res, 'data.permissions'),
        companies: get(get(res, 'data.filters').find(oneFilter => oneFilter.key === 'company'), 'data', []).filter(oneCompany => !oneCompany.readOnly)
      }))
    } catch (error) {
      onLoading(false)
      notificationCenter("error", get(error, 'response.data.error', 'Error'))
    }
  }

  useEffect(() => {
    getFilters()
  }, []);

  const onLoading = (flag = true) => {
    setState(prevState => ({
      ...prevState,
      loading: flag
    }));
  }

  const onShowCharts = () => {
    // console.log("object")
    setState(prevState => ({
      ...prevState,
      showCharts: !prevState.showCharts
    }))
  }

  const onShowBulkUploadProjectWorkers = (status = true) => {
    setState(prevState => ({
      ...prevState,
      showBulkUploadProjectWorkers: status
    }))
  }


  return (
    <ThemeProvider theme={myTheme}>
      <SettingsContextProvider>
        {state.loading &&
          <div className={classes.dialogLoading}>
            <CircularProgress color="primary" />
          </div>}
        <div>
          <TopHeaderPage project={state.project} company={state.company} permissions={state.permissions} onShowCharts={onShowCharts} onShowBulkUploadProjectWorkers={onShowBulkUploadProjectWorkers} />
          {/* <Charts /> */}
          {/* <EmployeeCards data={state.employeeCards} /> */}
          {!isNull(state.filters) &&
            <TableWorkers currentEmployee={currentEmployee} bulkUploadWorkersWebsocketUrl={bulkUploadWorkersWebsocketUrl} companyWorkersDomain={companyWorkersDomain} images_path={get(config, 'images_path')} company={state.company} filters={state.filters} config={config} onLoading={onLoading} projectDomain={projectDomain} project={state.project} allColumns={allColumns} defaultColumns={defaultColumns} employee_settings_endpoint={employee_settings_endpoint} permissions={state.permissions} />
          }
          <Drawer disableBackdropClick={true} classes={{ paper: classes.bulkUploadDrawer }} anchor={'right'} open={state.showBulkUploadProjectWorkers} onClose={() => onShowBulkUploadProjectWorkers(false)}>
            <BulkUploadWorkers
              project={state.project}
              currentEmployee={currentEmployee}
              company={state.company}
              companies={state.companies}
              companyWorkersDomain={companyWorkersDomain}
              bulkUploadWorkersWebsocketUrl={bulkUploadWorkersWebsocketUrl}
              fileUploadEndpoint={fileUploadEndpoint}
              sessionResultsEndpoint={sessionResultsEndpoint}
              onShowBulkUploadProjectWorkers={onShowBulkUploadProjectWorkers} />
          </Drawer>
        </div>
      </SettingsContextProvider>
      <NotificationContainer />
    </ThemeProvider>
  )
};
