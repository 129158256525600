import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import WarningIcon from '@material-ui/icons/Warning';
import { Badge, Button, CircularProgress, FormControl, TextField } from '@material-ui/core';
import { Sync } from '@material-ui/icons';
import { each, get, groupBy, isEmpty, isNull, isUndefined, set, size, has, uniqBy } from 'lodash';
import { errorHandle } from '../../../helpers/errorHelper';
import { getErrorVendors, syncingVendors, syncProcoreVendor, importingCostCodes, syncingProcoreDailyLogs } from '../../services';
import { CardDash, SyncTimeSheetsProcore } from '../../components';
import { Alert, Autocomplete } from '@material-ui/lab';
import { notificationCenter } from '../../../helpers/notifications';
import { SettingsContext } from '../../contexts/SettingsContext';
import moment from 'moment';

const W3CWebSocket = require('websocket').w3cwebsocket;

const EnhancedTableHead = (props) => {
  const { onSelectAllClick, numSelected, rowCount, hasDailyConstruction } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        <TableCell>
          Vendor Name
        </TableCell>
        <TableCell>
          EIN
        </TableCell>
        <TableCell>
          Status
        </TableCell>
        {hasDailyConstruction ? <TableCell>Trade</TableCell> : null}
        <TableCell>

        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
  {
    color: 'white',
    backgroundColor: "#f79172",
    display: 'flex',
    justifyContent: 'space-between'
  },
  notSelected: {
    color: '#f15922',
    backgroundColor: "#e0e0e0",
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    flex: '1 1 100%',
    color: "white"
  },
  button: {
    padding: "3px 10px",
    marginRight: '10px',
    textTransform: 'capitalize'
  }
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, onUpdate, updatingVendors } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
        [classes.notSelected]: numSelected === 0,
      })}
    >

      <Typography style={{ color: numSelected === 0 ? '#f15922' : 'white' }} variant="subtitle1" component="div">
        {numSelected} selected
      </Typography>

      <Button
        variant="contained"
        color="default"
        disabled={numSelected === 0 || updatingVendors}
        onClick={() => onUpdate()}
        style={{ fontSize: '12px', marginRight: '10px', width: '180px' }}
        className={classes.button}
      >
        Apply all changes
      </Button>
    </Toolbar>
  );
};


const TradeList = props => {

  const { trades, onChangeTrade, vendor } = props;
  return (

    <FormControl >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Autocomplete
          disabled={get(vendor, 'syncing', false)}
          id="combo-box-demo"
          size="small"
          value={get(vendor, "trade", '')}
          options={trades}
          getOptionLabel={(option) => option.label}
          onChange={(e, t) => onChangeTrade(vendor, t)}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} variant="outlined" label="Trades" />}
        />

      </div>
    </FormControl>
  );
};


const VendorStatus = ({ vendor }) => {

  let message = '';

  switch (get(vendor, 'status')) {
    case 'error':
      message = 'Missing EIN';
      break;
    case 'synced':
      message = 'Synced';
      break;
    case 'readyToSync':
      message = 'Ready To Sync';
      break;
    default:
      break;
  }

  return (
    get(vendor, 'status') === 'synced' ? <span style={{
      backgroundColor: '#78bd5d',
      color: 'white',
      borderRadius: '5%',
      padding: '5px',
    }}>Synced</span> : message
  );
};


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',

  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  vendorListTop: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  panelTitle: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
  },
  accordion: {
    borderRadius: '4px',
    padding: 0,
  },
  accordionDisabled: {
    backgroundColor: '#ffffff !important',
  },
  accordionSummary: {
    borderBottom: '1px solid #efefef',
  },
  accordionSummaryContent: {
    margin: '0px',
  },
  accordionDetails: {
    padding: '0px',
  },
  button: {
    padding: "3px 10px",
    marginRight: '10px',
    textTransform: 'capitalize'
  }
}));

export default function VendorList(props) {

  const { integrations_endpoint, webSocketsUrl, project, company, timesheet_date, user } = props;

  const settingsContext = useContext(SettingsContext);

  const classes = useStyles();

  const [state, setState] = useState({
    vendors: [],
    trades: [],
    selected: [],
    hasDailyConstruction: false,
    hasTokenExpired: false,
    isDisabled: true,
    integrationDisabled: false,
    proc_proj_id: null,
    syncingVendorsStatus: false,
    updatingVendors: false,
    expanded: true,
    updateButton: false,
    totalUpdate: 0,
    hasResponseError: false,
    timesheetSyncStatus: true,
    syncDailyLogs: false,
    importCostCode: {
      syncStatus: false,
      permission: false
    },
    syncVendorConfirmation: {
      status: false,
      data: null,
      currentVendor: null,
    },
  });


  useEffect(() => {
    getVendorList();
    openWebSocketConnection();
  }, []);


  const getVendorList = async () => {
    try {
      const res = await getErrorVendors(integrations_endpoint, project, company);
      if (!get(res, 'data.hasIntegration', true)) {
        setState(prevState => ({
          ...prevState,
          isDisabled: true,
        }));
      } else {

        const projectPermissions = get(res, 'data.projectPermissions.settings', {})

        setState(prevState => ({
          ...prevState,
          vendors: get(res, 'data.vendors', []),
          trades: get(res, 'data.trades', []),
          hasDailyConstruction: get(res, 'data.hasDailyConstruction'),
          proc_proj_id: get(res, 'data.proc_proj_id'),
          isDisabled: false,
          integrationDisabled: get(res, 'data.isDisabled', false),
          hasTokenExpired: get(res, 'data.hasTokenExpired', false),
          importCostCode: {
            syncStatus: false,
            permission: get(projectPermissions, 'import_costCode_sync.status', false) || get(projectPermissions, 'budgeted_costCode_sync.status', false)
          }
        }));
      }
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        hasResponseError: true
      }))
    }
  }


  useEffect(() => {

    if (state.totalUpdate !== 0 && state.totalUpdate === state.selected.length) {
      setState(prevState => ({
        ...prevState,
        selected: [],
        totalUpdate: 0,
        updatingVendors: false
      }))
    }

  }, [state.totalUpdate])


  const openWebSocketConnection = () => {

    let client = new W3CWebSocket(`${webSocketsUrl}?userId=${get(user, '_id.$oid')}&companyId=${get(user, 'company_ids[0].$oid')}`);

    client.onopen = (e) => {
      console.log("OPEN ", e);
    };

    client.onclose = (e) => {
      console.log("ONCLOSE ", e);
      client = null;

      openWebSocketConnection();
    };

    client.onmessage = (e) => {

      const res = JSON.parse(get(e, "data"));


      switch (get(res, 'section.key')) {
        case "vendorSync":
          setState(prevState => ({
            ...prevState,
            totalUpdate: prevState.totalUpdate + 1,
            // selected: prevState.selected.filter(item => item !== get(res, 'section.data.id')),
            vendors: prevState.vendors.map(item => {
              if (get(item, 'id') === get(res, 'section.data.id')) {
                return get(res, 'section.data')
              }
              return item
            })
          }));

          break;
        case "timesheetStatus":
          setState(prevState => ({
            ...prevState,
            timesheetSyncStatus: false
          }))

          notificationCenter("success", "Timesheets Synced")
          break;
        case "costCodeSynced":
          setState(prevState => ({
            ...prevState,
            importCostCode: {
              ...prevState.importCostCode,
              syncStatus: false
            }
          }))
          notificationCenter("success", "CostCodes Synced")
          break;

        case "manpowerSynced":
          setState(prevState => ({
            ...prevState,
            syncDailyLogs: false
          }))
          notificationCenter("success", "Manpower Synced")
          break;

        case "dailyConstructionSynced":
          setState(prevState => ({
            ...prevState,
            syncDailyLogs: false
          }))
          notificationCenter("success", "Daily Construction Report Synced")
          break;
        default:
          break;
      }

    };
  };

  const onSetSyncTimesheetStatus = flag => {
    setState(prevState => ({
      ...prevState,
      timesheetSyncStatus: flag
    }))
  }


  const onSyncDailyLog = async () => {
    try {

      setState(prevState => ({
        ...prevState,
        syncDailyLogs: true
      }))

      await syncingProcoreDailyLogs(integrations_endpoint, get(project, '_id.$oid'), moment(timesheet_date).format("YYYYMMDD"));

    } catch (error) {
      notificationCenter('error', get(error, 'response.data.error', "Error"))
    }

  }


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = state.vendors.map((n) => n.id);

      setState(prevState => ({
        ...prevState,
        selected: newSelecteds
      }))

      return;
    }
    setState(prevState => ({
      ...prevState,
      selected: []
    }))
  };

  const handleClick = (event, id) => {
    const selectedIndex = state.selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(state.selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(state.selected.slice(1));
    } else if (selectedIndex === state.selected.length - 1) {
      newSelected = newSelected.concat(state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        state.selected.slice(0, selectedIndex),
        state.selected.slice(selectedIndex + 1),
      );
    }

    setState(prevState => ({
      ...prevState,
      selected: newSelected
    }))
  };

  const onChangeEIN = (vendor, value) => {
    setState(prevState => ({
      ...prevState,
      vendors: prevState.vendors.map(item => {
        if (get(item, 'id') === get(vendor, 'id')) {
          set(item, 'ein', value.replace(/\D/g, '').slice(0, 9));
        }
        return item
      })
    }));
  };

  const onChangeTrade = (vendor, trade) => {
    setState(prevState => ({
      ...prevState,
      vendors: prevState.vendors.map(item => {
        if (get(item, 'id') === get(vendor, 'id')) {
          set(item, 'trade', trade);
        }
        return item
      })
    }));
  };

  const onUpdate = async () => {

    let hasDuplicate = null;

    const vendorsToUpdate = state.selected.map(item => {
      const vendor = { ...state.vendors.find(elem => get(elem, 'id') === item) }

      hasDuplicate = state.vendors.filter(elem => {
        return get(elem, 'ein').replace(/\D/g, '') === get(vendor, 'ein').replace(/\D/g, '').slice(0, 9)
      });
      vendor.ein = vendor.ein.replace(/\D/g, '').slice(0, 9)

      return vendor
    });


    let hasError = false;

    each(vendorsToUpdate, vendor => {
      const ein = get(vendor, 'ein', '')

      if (ein === '') {
        hasError = true;
        notificationCenter('error', `EIN is a required field on vendor ${get(vendor, 'name')}`);
      } else if (ein.length !== 9) {
        hasError = true;
        notificationCenter('error', `EIN doesn\'t have the correct format on vendor ${get(vendor, 'name')}`);
      }
    });

    if (size(hasDuplicate) > 1) {
      notificationCenter('error', `EIN ${get(hasDuplicate, '0.ein')} already exists!`);
      hasDuplicate = null;
      hasError = true;
    }

    if (!hasError) {

      setState(prevState => ({
        ...prevState,
        updatingVendors: true,
        vendors: prevState.vendors.map(item => {
          const tempVendor = vendorsToUpdate.find(elem => get(elem, 'id') === get(item, 'id'));
          if (!isUndefined(tempVendor)) {
            set(tempVendor, 'syncing', true);
            return tempVendor;
          } else
            return item
        })
      }))

      await syncProcoreVendor(integrations_endpoint, project, company, vendorsToUpdate);
    }
  };

  const syncProcoreVendors = async () => {

    try {

      if (!state.hasTokenExpired && !state.integrationDisabled) {
        setState(prevState => ({
          ...prevState,
          syncingVendorsStatus: true,
        }));

        await syncingVendors(integrations_endpoint, state.proc_proj_id);

        setState(prevState => ({
          ...prevState,
          syncingVendorsStatus: false,
        }));
        getVendorList();
      }
    } catch (error) {
      notificationCenter('error', get(error, 'response.data.error', "Error"))
    }

  };

  const onSyncImportCostCodes = async () => {
    try {
      let payload = {
        sbProjectId: get(project, '_id.$oid'),
        procId: get(project, 'integrations.procore')
      }
      const res = await importingCostCodes(integrations_endpoint, payload);
      setState(prevState => ({
        ...prevState,
        importCostCode: {
          ...prevState.importCostCode,
          syncStatus: true
        }
      }))
    } catch (error) {
      notificationCenter('error', get(error, 'response.data.error', "Error"))
    }
  }


  const isSelected = (id) => state.selected.indexOf(id) !== -1;

  const procoreLogo = 'https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/project-employees/procoreLogo.png';


  return (
    <div className={classes.root}>

      {has(settingsContext, 'uiConfig.data.project.integrations') &&
        <CardDash
          accordionDetailStyles={{ padding: 0 }}
          expandable={!state.hasTokenExpired && !state.integrationDisabled}
          defaultExpanded={false}
          title={
            <div className={`${classes.vendorListTop}`}>
              <img width="25px" src={procoreLogo} alt="PROCORE LOGO" />
              <span>Vendor List</span>
              {state.hasTokenExpired && !state.hasResponseError && <div>
                <Alert style={{ marginLeft: '32px', paddingTop: '0px', paddingBottom: '0px' }} severity="warning"><b>Your token has expired</b></Alert>
              </div>}

              {state.integrationDisabled && <div>
                <Alert style={{ marginLeft: '32px', paddingTop: '0px', paddingBottom: '0px' }} severity="warning"><b>Your integration is disabled</b></Alert>
              </div>}

              {state.hasResponseError && <div>
                <Alert style={{ marginLeft: '32px', paddingTop: '0px', paddingBottom: '0px' }} severity="error"><b>Oops something went wrong. Please contact support</b></Alert>
              </div>}
            </div>
          }
          actions={<>


            <div style={{ display: 'flex', alignItems: 'center' }}>

              {state.importCostCode.permission &&
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => onSyncImportCostCodes()}
                  className={classes.button}
                  style={{ fontSize: '12px', marginRight: '10px' }}
                  disabled={state.importCostCode.syncStatus}
                  startIcon={<img width="18px" src='https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/project-employees/procoreLogo.png' />}
                >
                  {state.importCostCode.syncStatus && <CircularProgress size={15} style={{ marginRight: '8px' }} color="primary" />} Import CostCodes
                </Button>
              }


              <SyncTimeSheetsProcore
                integrations_endpoint={integrations_endpoint}
                onSetSyncTimesheetStatus={onSetSyncTimesheetStatus}
                timesheetSyncStatus={state.timesheetSyncStatus}
                webSocketsUrl={webSocketsUrl}
                hasTokenExpired={state.hasTokenExpired}
                hasResponseError={state.hasResponseError}
                integrationDisabled={state.integrationDisabled}
                onSyncDailyLog={onSyncDailyLog}
                syncDailyLogs={state.syncDailyLogs}
                user={user}
                project={project}
                company={company}
                timesheet_date={timesheet_date} />
              {state.syncingVendorsStatus ?
                <CircularProgress style={{ cursor: 'auto' }} size={24} color="primary" /> :
                <Sync onClick={() => syncProcoreVendors()}
                  style={{ fontSize: 28, cursor: `${state.hasTokenExpired || state.hasResponseError || state.integrationDisabled ? 'not-allowed' : 'pointer'}` }} />}

              <div style={{ paddingLeft: '15px' }}>
                <Badge badgeContent={get(state, 'vendors.length', 0)} color="primary" />
              </div>


            </div>

          </>}
        >

          <Paper className={classes.paper}>
            <EnhancedTableToolbar numSelected={state.selected.length} onUpdate={onUpdate} updatingVendors={state.updatingVendors} />
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={state.selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  hasDailyConstruction={state.hasDailyConstruction}
                  rowCount={state.vendors.length}
                />
                <TableBody>
                  {get(state, 'vendors', []).map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color='primary'
                            checked={isItemSelected}
                            disabled={get(row, 'syncing', true)}
                            onClick={(event) => handleClick(event, row.id)}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.name}
                        </TableCell>
                        <TableCell >
                          <input
                            style={{ padding: '7px', border: '1px solid #cccccc', borderRadius: '3px' }}
                            id={index}
                            disabled={!isEmpty(get(row, 'ein')) && get(row, 'status') !== 'error'}
                            value={get(row, 'ein', '')}
                            maxLength={10}
                            onChange={(e) => onChangeEIN(row, e.target.value)}
                            placeholder="EIN Number"
                          />

                        </TableCell>
                        <TableCell ><VendorStatus vendor={row} /></TableCell>
                        {get(state, 'hasDailyConstruction') ? <TableCell >
                          <TradeList trades={state.trades} onChangeTrade={onChangeTrade} vendor={row} />
                        </TableCell> : null}

                        <TableCell>
                          {get(row, 'syncing', false) ? <CircularProgress style={{ cursor: 'auto' }} size={24} color="primary" /> : null}
                        </TableCell>


                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

          </Paper>
        </CardDash>
      }
    </div>
  );
}
