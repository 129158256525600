import React, { useState, createContext } from 'react';
import * as _ from 'lodash';
import { useMediaQuery } from 'react-responsive';


export const SettingsContext = createContext();

export default function SettingsContextProvider(props) {

  const [state, setState] = useState({
    clearFilter: false,
    currentFilters: [],
    searchValue: '',
    searchEnable: false,
    search: false,
    isMobile: useMediaQuery({ query: `(max-width: 770px)` }),
    isIpad: useMediaQuery({ query: `(max-width: 770x)` }),
    addWorker: {
      status: false,
      resource: ''
    }
  })

  const isDesktopOrLaptop = useMediaQuery(
    { maxDeviceWidth: 900 }, undefined, (matches) => {
      setState(prevState => ({
        ...prevState,
        isMobile: matches
      }))
    }
  );

  const onAddWorker = (resource = '') => {
    setState(prevState => ({
      ...prevState,
      addWorker: {
        resource,
        status: !prevState.addWorker.status
      }
    }));
  }


  const onCleaningFilter = (flag) => {
    setState(prevState => ({
      ...prevState,
      clearFilter: flag,
      searchValue: '',
      search: false
    }))
  }

  const onSearchChange = (value) => {


    setState(prevState => ({
      ...prevState,
      searchValue: value,
      search: false
    }))
  }

  const onSearch = () => {
    setState(prevState => ({
      ...prevState,
      search: true,
      searchEnable: true
    }))
  }

  return (
    <SettingsContext.Provider
      value={{
        ...state,
        onCleaningFilter,
        onSearchChange,
        onSearch,
        onAddWorker
      }}>
      {props.children}
    </SettingsContext.Provider>
  )
};