import React, { useState } from 'react'
import PunchTimeRule from "./PunchTimeRule"

const PunchTimeRules = ({ data, companyId, projectId, readOnly }) => {

  const [rules, setRules] = useState(data);


  const defaultValue = {
    from: null,
    to: null,
    change_to: null,
    applies_to: null,
  }

  const handleDelete = removed => {
    const index = rules.indexOf(removed)

    rules.splice(index, 1)
    setRules([...rules])
  }

  const addNew = ev => {
    const newElement = {
      _id: {},
      company_id: companyId,
      project_id: projectId,
      rule: defaultValue,
      modifies: 'punch'
    };
    setRules([...rules, newElement])
  }


  return (
    <div className="panel">
      <div className="panel-heading">
        <span className="panel-title">
          Round Up Time Rules
        </span>
      </div>
      <div className="panel-body">
        <div className="table-responsive">
          <table className="table table-hover table-condensed-x table-striped">
            <thead>
              <tr>
                <th>From (Time)</th>
                <th>To (Time)</th>
                <th>Change To (Time)</th>
                <th>Applies To (Punch Type)</th>
              </tr>
            </thead>
            <tbody>
              {rules.map(rule => {
                return <PunchTimeRule key={rule._id.$oid} data={rule} isNew={rule._id.$oid == undefined} onDelete={handleDelete} readOnly={readOnly} />
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="panel-footer">
        {(() => {
          if (!readOnly) {
            return <button className="btn btn-success btn-xs btn-outline btn-3d" onClick={addNew}>Add New</button>
          }
        })()}
      </div>
    </div>
  )
}

export default PunchTimeRules;