import React, { useState } from 'react';
import { Popover, IconButton } from '@material-ui/core';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import { CoordinatePopoverContent } from '../index';
import _ from 'lodash';
import { notificationCenter } from '../../../helpers/notifications';

export default function CoordinatesButtonAndInput({ classes, fillInAddress, address }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleSubmit = (coordinates) => {
        window.geocoder.geocode({ location: { lat: parseFloat(coordinates.lat), lng: parseFloat(coordinates.lng) }}, (results, status) => {
            if (status !== "OK") notificationCenter('error', "Google Geocode failed. Please try again.");

            let result = _.get(results, 0);

            if (!result) notificationCenter('error', "No result found. Please try again.");

            fillInAddress(result);
        });
    };

    const open = Boolean(anchorEl);
    const id = open ? 'coordinate-popover' : undefined;

    return (
      <div>
        <IconButton size='small' onClick={handleClick} color={!_.isEmpty(_.get(address, 'latLng')) ? 'primary' : 'default'}>
          <GpsFixedIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            paper: classes.coordinatesPopoverPaper,
          }}
        >
          <CoordinatePopoverContent onSubmit={handleSubmit} onClose={handleClose} classes={classes} coordinates={_.get(address, 'latLng')} />
        </Popover>
      </div>
    );
  };
