import React from 'react'
import CertificationActions from './CertificationActions'

export default function CertificationList({ certs, baseUrl, dbid, onDelete }) {
    return (
        <div className="row">
            <div className="col-md-12">
                <table className="table table-hover table-condensed-x table-striped">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Public</th>
                            <th>Issued</th>
                            <th>Expiration</th>
                            <th>Last Updated</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {certs.map(cert => {
                            let expiration = "N/A"
                            let issued = "N/A"
                            let lastUpdated = "N/A"
                            let expired = false
                            if (cert.expiration_date) {
                                const expires = moment(cert.expiration_date)
                                expiration = expires.format("MM/DD/YYYY")
                                expired = expires.isBefore(moment.now())
                            }
                            if (cert.issue_date) {
                                const date = moment(cert.issue_date)
                                issued = date.format("MM/DD/YYYY")
                            }
                            if (cert.updated_at) {
                                lastUpdated = moment(cert.updated_at).format("MM/DD/YYYY")
                            }
                            return (
                                <tr key={cert._id} className={(expired ? "danger" : "")}>
                                    <td>{cert.type[0].name}</td>
                                    <td>
                                        {
                                            (() => {
                                                if (cert.is_public) {
                                                    return (
                                                        <i className="fas fa-check"></i>
                                                    )
                                                } else {
                                                    return (
                                                        <i className="fas fa-times"></i>
                                                    )
                                                }
                                            })()
                                        }
                                    </td>
                                    <td>{issued}</td>
                                    <td>{expiration}</td>
                                    <td>{lastUpdated}</td>
                                    <CertificationActions onDelete={onDelete} cert={cert} baseUrl={baseUrl} dbid={dbid} />
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
