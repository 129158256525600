import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, ThemeProvider } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import { NotificationContainer } from 'react-notifications';
import {
  Dialog, DialogActions, DialogTitle, DialogContent
} from "@material-ui/core";
import { get, isUndefined, isNull } from 'lodash';
import Typography from '@material-ui/core/Typography';
import { myTheme } from '../../helpers';
import WorkerInfo from './WorkerInfo';
import WorkerVerification from './WorkerVerification';
import SafetyInfo from './SafetyInfo';
import { onBoardWorker, patchSafetyInfo } from "./http";
import { notificationCenter } from '../../helpers/notifications'


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  dialogLoading: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    backgroundColor: '#d2d2d2a6',
    zIndex: '9999999',
    justifyContent: "center",
    alignItems: "center"
  },
  button: {
    marginRight: '2px',
  },
  safetyButtons: {
    marginRight: "10px",
    paddingLeft: "20px",
    paddingRight: "10px",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.12)"
    },
    boxShadow: 'none'
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: 'center',
    fontSize: '17px'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "center"
  },
  ml: {
    marginLeft: "10px"
  },
  mb: {
    marginBottom: "10px"
  },
  input: {
    width: "100%"
  },
  inputGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  onBoardNewWorkerButton: {
      '&:hover': {
        color: theme.palette.primary.main,
      },
  }
}));

function getSteps() {
  return ['Worker Info', 'Worker Verification', 'Safety Info'];
}

export default function Wizard(props) {

  const { company, project, config, defaultOpen, addWorkerCompanies, onHandleRevokeClose, worker, disabled } = props;

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(isUndefined(defaultOpen) ? false : defaultOpen);
  const [loading, setLoading] = useState(false);
  const [workerVerified, setWorkerVerified] = useState(false);
  const [safetyAgree, setSafetyAgree] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);
  const [companyTitle, setCompanyTitle] = useState(get(company, 'name', ''));
  const [safetyButton, setSafetyButton] = useState({
    form: 'https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/safety-form/safety-form-out.svg',
    sms: 'https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/safety-form/safety-sms-out.svg',
    quick: 'https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/safety-form/safety-quick-out.svg'
  })

  const safetyFormsIcons = {
    form: {
      in: 'https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/safety-form/safety-form-in.svg',
      out: 'https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/safety-form/safety-form-out.svg'
    },
    sms: {
      in: 'https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/safety-form/safety-sms-in.svg',
      out: 'https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/safety-form/safety-sms-out.svg'
    },
    quick: {
      in: 'https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/safety-form/safety-quick-in.svg',
      out: 'https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/safety-form/safety-quick-out.svg'
    }
  }

  const steps = getSteps();

  const revokeFlag = "revoke";

  const defaultWorkerState = {
    first_name: get(worker, 'first_name', ''),
    last_name: get(worker, 'last_name', ''),
    phone_number: get(worker, 'phone_number', ''),
    phone_number_confirm: '',
    synthetic_phone: false,
    decal: get(worker, 'decal', ''),
    language_code: 'en',
    company_ids: [company._id],
    project_id: project._id,
    needs_safety: false,
    safety_process: '',
    worker_level: 'journeyman',
    email: get(worker, 'email', '')
  }

  const defaultWorkerResponse = {
    "worker": {
      "dbid": "",
      "first_name": "",
      "last_name": "",
      "phone_number": "",
      "active": false
    },
    "flags": [
    ],
    "message": ""
  }

  const defaultSafetyState = {
    acknowledged: false,
    address: "",
    emergency_name: "",
    emergency_phone_number: "",
    emergency_email: "",
    car_make: "",
    car_model: "",
    car_color: "",
    car_tag: ""
  }

  const [workerState, setWorkerState] = useState(defaultWorkerState);
  const [workerResponse, setWorkerResponse] = useState(defaultWorkerResponse);
  const [workerCompleteMessage, setWorkerCompleteMessage] = useState('');

  const handleOpen = () => {
    handleReset();
    setOpen(true)
  }
  const handleClose = () => {
    if (!isUndefined(onHandleRevokeClose)) onHandleRevokeClose()
    setOpen(false)
  }

  const onDisclaimer = (disclaimer) => {
    setDisclaimer(disclaimer);
  }

  const lockedActions = (step) => {
    if (loading) {
      return true;
    }
    // Validation rules of each step
    switch (step) {
      case 0:
        if (workerState.first_name.length == 0 || workerState.last_name.length == 0 || (workerState.phone_number.length === 0 && !workerState.synthetic_phone)) {
          notificationCenter('error', 'Some required fields are empty');
        } else {
          handleNext()
        }
        break;
      case 1:
        return workerResponse.flags.indexOf(revokeFlag) === -1 ? false : !workerVerified;
      case 2:
        return !safetyAgree
      default:
        break;
    }

    return false;
  }

  const onSetLoading = flag => setLoading(flag);

  const onSafetyAction = (value) => {
    workerState['safety_process'] = value;
    setWorkerState({ ...workerState })
    lockedActions(0);
  }

  const onSafetyHover = (type, action) => {
    setSafetyButton(prevState => ({
      ...prevState,
      [type]: safetyFormsIcons[type][action]
    }))
  }

  const performStepAction = (step, callback) => {
    switch (step) {
      case 0:
        setLoading(true);
        onBoardWorker(workerState, config).then(response => {
          setLoading(false);
          notificationCenter("success", "The worker has been onboarded")

          if (response.data.flags.indexOf('completed') > -1 && get(response.data, 'safety_process', '') === 'form') {
            setWorkerCompleteMessage(get(response.data, 'message', ''));
            setDisclaimer(get(response, 'data.onboard.disclaimer', ''));
            setWorkerResponse(response.data);
            setActiveStep(2);
          } else {
            setWorkerResponse(response.data);
            callback();
          }

        }).catch(error => {
          setLoading(false);
          notificationCenter("error", get(error, 'response.data.error', 'Error'))
        })
        break;
      case 1:
        callback()
        if (workerState.safety_process == 'form') {
          setWorkerState(ws => ({ ...ws, ...defaultSafetyState }))
        } else if (workerState.needs_safety && workerState.safety_process == 'form') {
          // Do nothing, just display notice about info going to be collected thru sms
        } else {
          // If safety info not needed, skip step
          callback()
        }
        break;
      case 2:

        setLoading(true);
        patchSafetyInfo(workerState, workerResponse.onboard._id, config).then(response => {
          setLoading(false);
          if (!isNull(worker)) handleClose();
          callback();

        }).catch(error => {
          setLoading(false);
          notificationCenter("error", get(error, 'response.data.error', 'Error'))
        })
        break;
      default:
        break;
    }
  }

  const onSafetyAgree = () => {
    setSafetyAgree(!safetyAgree);
  }

  const handleNext = () => {
    performStepAction(activeStep, () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    })
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setWorkerState(defaultWorkerState);
    setWorkerResponse(defaultWorkerResponse);

    setActiveStep(0);
  };

  const handleChange = ({ prop, val }) => {
    workerState[prop] = val;
    setWorkerState({ ...workerState })
  }

  const handleVerified = (ev) => {
    const newResponse = { ...ev };
    newResponse.flags = workerResponse.flags.filter(f => f != revokeFlag);
    setWorkerVerified(true);
    setWorkerResponse(newResponse);
    if (newResponse.safety_process === 'form') {
      handleNext()
    } else {
      handleClose();
    }
  }

  const onChangeCompanyTitle = name => {
    setCompanyTitle(name)
  };

  const handleDialogClick = e => {
    e.stopPropagation();
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <WorkerInfo {...props} worker={worker} onChangeCompanyTitle={onChangeCompanyTitle} classes={classes} addWorkerCompanies={addWorkerCompanies} state={workerState} onChange={handleChange} />;
      case 1:
        return <WorkerVerification {...props} onDisclaimer={onDisclaimer} onDialogClose={handleClose} loading={loading} state={workerState} onSetLoading={onSetLoading} onVerified={handleVerified} isCompleted={workerResponse.flags.indexOf('completed') != -1} isRevoke={workerResponse.flags.indexOf(revokeFlag) != -1} content={<span> {workerResponse.message} <br /> {workerResponse.reason} </span>} />;
      case 2:
        return <SafetyInfo {...props} disclaimer={disclaimer} company={company} workerCompleteMessage={workerCompleteMessage} classes={classes} onSafetyAgree={onSafetyAgree} onChange={handleChange} state={workerState} type={workerState.safety_process} />;
      default:
        return 'Unknown step';
    }
  }

  return (
      <ThemeProvider theme={myTheme}>
          <Tooltip title='Onboard New Worker' arrow>
            <span>
                <IconButton
                disabled={disabled}
                className={classes.onBoardNewWorkerButton}
                onClick={(event) => { event.stopPropagation(); handleOpen(); }}
                size="small">
                    <PersonAddIcon style={{ fontSize: 30 }} />
                </IconButton>
            </span>
          </Tooltip>
        <Dialog open={open} onClose={handleClose} onClick={handleDialogClick}>
          {loading &&
            <div className={classes.dialogLoading}>
              <CircularProgress color="primary" />
            </div>}
          <DialogTitle disableTypography={true} classes={{ root: classes.dialogTitle }} >
            <span>Onboard new worker on <strong>{companyTitle}</strong></span>
            <Close style={{ cursor: 'pointer' }} size='20' color='primary' onClick={handleClose} />
          </DialogTitle>
          <DialogContent>
            <div className={classes.root}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <div>
                {activeStep === steps.length ? (
                  <div>
                    <Typography className={classes.instructions}>
                      All steps completed - you&apos;re finished
                    </Typography>

                    <div className="text-center">
                      <Button onClick={handleReset} className={classes.button} variant="outlined" color="primary">
                        OnBoard New Worker
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div>
                    {getStepContent(activeStep)}
                    <br />


                    {activeStep === 0 &&

                      <Fragment >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span>Collect Safety Information</span>
                            <div>
                              <Button
                                variant="contained"
                                onMouseEnter={() => onSafetyHover('form', 'in')}
                                onMouseLeave={() => onSafetyHover('form', 'out')}
                                className={classes.safetyButtons}
                                startIcon={
                                  <img
                                    src={safetyButton.form}
                                    width="50"
                                  />
                                }
                                onClick={() => onSafetyAction('form')}
                              />

                              <Button
                                variant="contained"
                                disabled={workerState.synthetic_phone}
                                className={classes.safetyButtons}
                                onMouseEnter={() => onSafetyHover('sms', 'in')}
                                onMouseLeave={() => onSafetyHover('sms', 'out')}
                                startIcon={
                                  <img
                                    src={safetyButton.sms}
                                    width="50"
                                  />}
                                onClick={() => onSafetyAction('sms')}
                              />
                            </div>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span>Quick Add</span>
                            <Button
                              variant="contained"
                              onMouseEnter={() => onSafetyHover('quick', 'in')}
                              onMouseLeave={() => onSafetyHover('quick', 'out')}
                              className={classes.safetyButtons}
                              startIcon={
                                <img
                                  src={safetyButton.quick}
                                  width="50"
                                />}
                              onClick={() => onSafetyAction('')}
                            />
                          </div>
                        </div>
                      </Fragment>
                    }
                    {
                      activeStep === 2 &&
                      <div>
                        <Button
                          variant="outlined"
                          color="primary"
                          disabled={lockedActions(activeStep)}
                          onClick={handleNext}
                          className={classes.button}
                        >
                          Finish
                            {/* {activeStep === steps.length - 1 ? 'Finish' : 'Next'} */}
                        </Button>
                      </div>
                    }
                    {/* <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                          Back
                        </Button> */}



                  </div>
                )}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose} color="primary">
              Cancel
            </Button> */}
          </DialogActions>
        </Dialog>
        {/* <NotificationContainer /> */}
      </ThemeProvider>
  );
}
