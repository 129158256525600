import React, { useState, useEffect } from 'react';
import { makeStyles, TextField, Button, CircularProgress } from '@material-ui/core';
import { get, isNull, each, isUndefined, set, isEmpty } from 'lodash';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { notificationCenter } from '../../helpers/notifications';

export default function RoundUpTimeRulesShift(props) {

  const useStyles = makeStyles((theme) => ({
    textField: {
      marginRight: '10px',
      width: '140px'
    },
    mainContainer: {
      display: 'flex',
      alignItems: 'end'
    },
    actions: {
      cursor: 'pointer',
      marginLeft: '5px'
    }
  }));

  const classes = useStyles();

  const { rules, onDeleteRule, onEnableEditRule, onSaveRule } = props;

  const newRule = { from: '0', to: '0', break_time: '0' };

  const [state, setState] = useState({
    newRule,
    shiftRules: [],
    createRule: false,
    saving: false,
    loading: false,
    currentEdit: false
  })

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      newRule,
      createRule: false,
      currentEdit: false,
      loading: false,
      saving: false,
      shiftRules: rules
    }))
  }, [rules])


  const onDeleteShiftRule = (rule = null) => {
    if (isNull(rule)) {
      setState(prevState => ({
        ...prevState,
        newRule,
        createRule: false,
        currentEdit: false
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        shiftRules: prevState.shiftRules.filter(item => get(item, '_id') !== get(rule, '_id'))
      }))

      onDeleteRule(get(rule, '_id'), 'shift')
    }
  }

  const onCreateShiftRule = (name, value) => {

    setState(prevState => ({
      ...prevState,
      newRule: {
        ...prevState.newRule,
        [name]: value
      }
    }))

  }

  const onAddNew = () => {
    setState(prevState => ({
      ...prevState,
      createRule: true
    }))
  }

  const onEditShiftRule = (id) => {

    setState(prevState => ({
      ...prevState,
      saving: true,
      currentEdit: true,
      shiftRules: prevState.shiftRules.map(item => {
        if (get(item, '_id') === id) {
          set(item, 'editStatus', true)
        }

        return item;
      })

    }))
  }

  const onEditRule = (id, name, value) => {
    setState(prevState => ({
      ...prevState,
      saving: true,
      shiftRules: prevState.shiftRules.map(item => {
        if (get(item, '_id') === id) {
          item = {
            ...item,
            rule: {
              ...item.rule,
              [name]: parseInt(value)
            }
          }

        }
        return item;
      })
    }))
  }

  const onValidation = (rule, id = null) => {

    const rules = isNull(id) ? state.shiftRules : state.shiftRules.filter(item => get(item, '_id') !== id)

    const from = parseInt(get(rule, 'from', ''));
    const to = parseInt(get(rule, 'to', ''));
    const break_time = parseInt(get(rule, 'break_time', ''));

    const isNotFormatted = () => {
      return from < 0 || to < 0 || break_time < 0;
    }

    const isNotInRange = () => {
      if (to <= from) return true;
      if (isEmpty(rules)) return false;

      const aux = rules.find(item => {
        const fromTemp = parseInt(get(item, 'rule.from', ''));
        const toTemp = parseInt(get(item, 'rule.to', ''));

        return (from < toTemp && to >= fromTemp)
      })

      return !isUndefined(aux)
    }

    if (isNotFormatted()) {
      notificationCenter('error', 'You have to enter a valid format time!')
    } else if (isNotInRange()) {
      notificationCenter('error', "New rule can't be in range with others")
    } else {
      setState(prevState => ({
        ...prevState,
        loading: true
      }))
      return true;
    }

    return false;

  }

  const onSavingShiftRule = (item = null) => {
    if (isNull(item)) {
      if (onValidation(state.newRule)) {
        let payload = {
          rule: { ...state.newRule },
          modifies: 'shift',
          status: 'new'
        }

        onSaveRule(payload, 'shift');
      }
    } else {
      if (onValidation(get(item, 'rule'), get(item, '_id'))) {
        set(item, 'onSaveStatus', true)
        let payload = {
          ...item
        }
        onSaveRule(payload, 'shift')
      }
    }


  }

  return (
    <>
      {get(state, 'shiftRules', []).map((item, idx) => (
        <div key={idx} className={classes.mainContainer}>
          <TextField
            disabled={!get(item, 'editStatus', false)}
            className={classes.textField}
            onChange={e => onEditRule(get(item, '_id'), 'from', e.target.value)}
            value={get(item, 'rule.from', 0)}
            label="From (Hours)"
            name='from'
            type='number' />
          <TextField
            disabled={!get(item, 'editStatus', false)}
            className={classes.textField}
            onChange={e => onEditRule(get(item, '_id'), 'to', e.target.value)}
            value={get(item, 'rule.to', 0)}
            label="To (Hours)"
            name='to'
            type='number' />
          <TextField
            disabled={!get(item, 'editStatus', false)}
            onChange={e => onEditRule(get(item, '_id'), 'break_time', e.target.value)}
            className={classes.textField}
            value={get(item, 'rule.break_time', 0)}
            label="Break Time (Minutes)"
            name='break_time'
            type='number' />
          <div>
            {get(item, 'onSaveStatus', false) ? <CircularProgress size={15} color="primary" /> : <CheckBoxIcon className={classes.actions} style={{ color: `${get(item, 'editStatus', false) ? 'rgb(1, 181, 0)' : 'rgb(64, 64, 64)'}`, cursor: `${!get(item, 'editStatus', false) ? 'not-allowed' : 'pointer'}` }} onClick={() => get(item, 'editStatus', false) ? onSavingShiftRule(item) : null} />}

            <EditIcon className={classes.actions} onClick={() => onEditShiftRule(get(item, '_id'))} />
            <DeleteForeverIcon onClick={() => onDeleteShiftRule(item)} className={classes.actions} />
          </div>
        </div>
      ))}

      {state.createRule ?
        <div className={classes.mainContainer}>
          <TextField
            className={classes.textField}
            onChange={(e) => onCreateShiftRule('from', e.target.value)}
            value={get(state, 'newRule.from')}
            id="standard-basic"
            label="From (Hours)"
            name='from'
            type='number' />
          <TextField
            className={classes.textField}
            onChange={(e) => onCreateShiftRule('to', e.target.value)}
            value={get(state, 'newRule.to')}
            label="To (Hours)"
            name='to'
            type='number' />
          <TextField
            className={classes.textField}
            onChange={(e) => onCreateShiftRule('break_time', e.target.value)}
            value={get(state, 'newRule.break_time')}
            label="Break Time (Minutes)"
            name='break_time'
            type='number' />
          <div>
            {state.loading ? <CircularProgress size={15} color="primary" /> : <CheckBoxIcon className={classes.actions} onClick={() => onSavingShiftRule()} style={{ color: 'rgb(1, 181, 0)' }} />}
            {/* <EditIcon className={classes.actions} /> */}
            <DeleteForeverIcon onClick={() => onDeleteShiftRule()} className={classes.actions} />
          </div>
        </div> : null

      }

      <Button style={{ marginTop: '20px' }} onClick={() => onAddNew()} disabled={state.createRule || state.currentEdit} variant="contained" color="primary">
        Add New
      </Button>

    </>
  )
};