import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { get, has, isEmpty, isEqual, isNull, size } from 'lodash';
import { DateTime } from 'luxon';

import { BulkActionDialog, NewWorkerShift, WorkerRow } from '../index';

import {
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  LinearProgress,
  MenuList,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  InputAdornment,
  Grid
} from '@material-ui/core';

import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import GridOnIcon from '@material-ui/icons/GridOn';

import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SettingsIcon from '@material-ui/icons/Settings';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import ClockIcon from '@material-ui/icons/AccessTime';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Close from '@material-ui/icons/Close';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import LockIcon from '@material-ui/icons/Lock';

import { useMediaQuery } from 'react-responsive';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import { notificationCenter } from '../../../../../helpers/notifications';
import { GaugeMeter } from '../../../../../reusableComponents/index';
import Wizard from '../../../../../workers/onboard/Wizard';
import { SettingsContext } from '../../../../contexts/SettingsContext';
import { AccordionItem } from '../../../../../reusableComponents/AccordionItem';
import useAutocomplete from '@material-ui/lab/useAutocomplete';

const CompanyTimesheet = (props) => {

  const {
    isFirst,
    config,
    companyData,
    loadingData,
    loadingMoreData,
    loadingPage,
    allDataLoaded,
    classifiers,
    project,
    preloadedCostCodes,
    timesheetData,
    handleActionDialogPopover,
    selectedCrew,
    user
  } = props;

  const useStylesTimesheet = makeStyles((theme) => ({

    headerStyle: {
      backgroundColor: 'transparent',
    },
    vendorListTop: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    panelTitle: {
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
    },
    accordionSummary: {
      borderBottom: '0px solid #efefef',
      // '& .MuiOutlinedInput-input': {
      //   padding: 14,
      // },
    },
    accordionDetails: {
      padding: 0,
    },
    companyNameAndEinWrapper: {
      '&:hover': {
        '& $copyIcon': {
          opacity: 1,
        },
      },
    },
    companyRowActions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    addWorkerPunchButton: {
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    newWorkerPunchDialogTitle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '17px',
      backgroundColor: '#f6f5f7',
      transition: 'height 0.15s ease-out',
    },
    newWorkerPunchDialogActions: {
      justifyContent: 'flex-end',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    newWorkerPunchDialogBody: {
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingTop: 0,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    closeModalButton: {
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    drawerHeader: {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 8,
      paddingBottom: 8,
      backgroundColor: '#f6f5f7',
    },
    drawerHeaderFont: {
      ...theme.typography.subtitle2,
    },
    drawerBody: {
      padding: 12,
    },
    settingsActionsWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: 12,
      paddingRight: 12,
    },
    approveAllButton: {
      color: '#81cd74',
      borderColor: '#81cd74',
      width: 105,
    },
    dialogTitle: {
      padding: 16,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      fontSize: '17px',
      // backgroundColor: '#f6f5f7'
    },
    dialogActions: {
      justifyContent: 'flex-end',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    modalBody: {
      position: 'relative',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingTop: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    accordionRoot: {
      backgroundColor: '#fafafa',
    },
    accordionExpandIcon: {
      order: -1,
    },
    accordionSummaryContent: {
      margin: 0,
    },
    accordionSummaryExpanded: {
      margin: '0px !important',
      paddingLeft: 0,
    },
    companyOptionsMenuItem: {
      fontSize: 30,
      '&:hover': {
        color: theme.palette.primary.main,
        '& $companyOptionsMenuItem': {
          color: theme.palette.primary.main,
        },
      },
    },
    noShiftsWrapper: {
      width: '100%',
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: '#676A6C',
    },
    timesheetFooterWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: '#676A6C',
    },
    workersDropDownList: {
      width: 252,
      margin: '2px 0 0',
      padding: 0,
      position: 'absolute',
      listStyle: 'none',
      backgroundColor: '#fff',
      overflow: 'auto',
      maxHeight: 250,
      borderRadius: 4,
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      zIndex: 999999,

      '& li': {
        padding: '5px 12px',
        display: 'flex',
      },

      '& li[data-focus="true"]': {
        backgroundColor: 'rgba(241, 89, 34, 0.1)',
        cursor: 'pointer',
      },
    },
    projectsDropDownList: {
      width: 'calc(100% - 32px)',
      margin: '2px 0 0',
      padding: 0,
      position: 'absolute',
      listStyle: 'none',
      backgroundColor: '#fff',
      overflow: 'auto',
      maxHeight: 200,
      borderRadius: 4,
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      zIndex: 999999,

      '& li': {
        padding: '5px 12px',
        display: 'flex',
        justifyContent: 'space-between',
      },

      '& li[data-focus="true"]': {
        backgroundColor: 'rgba(241, 89, 34, 0.1)',
        cursor: 'pointer',
      },
    },
    filterByPositionDropDown: {
      width: '100%',
      margin: '2px 0 0',
      padding: 0,
      position: 'absolute',
      listStyle: 'none',
      backgroundColor: '#fff',
      overflow: 'auto',
      maxHeight: 200,
      borderRadius: 4,
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      zIndex: 999999,

      '& li': {
        padding: '5px 12px',
        display: 'flex',
        justifyContent: 'space-between',
      },

      '& li[data-focus="true"]': {
        backgroundColor: 'rgba(241, 89, 34, 0.1)',
        cursor: 'pointer',
      },
    },
    copyIcon: {
      fontSize: 10,
      color: 'lightslategrey',
      marginLeft: 4,
      opacity: 0,
      transition: 'opacity 0.3s ease-in-out'
    },
    ptoSummary: {
      padding: 8,
      backgroundColor: '#a3ccec',
      color: '#3b75a2',
      borderRadius: 4,
      width: '100%',
      fontWeight: 600
    },
    paginationWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    loadingPageWrapper: {
      width: '100%',
      height: '100%',
      opacity: 0.75,
      backgroundColor: 'white',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 99999,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }));

  const classes = useStylesTimesheet();

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

  const settingsContext = useContext(SettingsContext);

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [editMode, setEditMode] = useState(get(settingsContext, 'uiConfig.ui.modules.timesheet.permissions.canEdit'));
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedDrawer, setselectedDrawer] = useState('');
  const [timesheetOptions, setTimesheetOptions] = useState(isTabletOrMobile ? ['Productivity'] : ['PPE', 'Productivity']);
  const [productivityView, setProductivityView] = useState(false);
  const [showAddNewWorkerShift, setShowAddNewWorkerShift] = useState(false);
  const [isTransferShift, setIsTransferShift] = useState(false);
  const [showBulkActionDialog, setShowBulkActionDialog] = useState(false);
  const [showApproveAllDialog, setShowApproveAllDialog] = useState(false);
  const [positionsArray, setPositionsArray] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [departmentArray, setDepartmentArray] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);


  const [pagination, setPagination] = useState(1);
  const pageSize = get(settingsContext, 'uiConfig.ui.modules.timesheet.pageSize', 15);

  const [newWorkerPunchType, setNewWorkerPunchType] = useState('in');

  const [companyMenuAnchorEl, setCompanyMenuAnchorEl] = useState(null);

  const companyNameRef = React.useRef(null);

  const handleCompanyOptionsMenuBlur = () => {
    setCompanyMenuAnchorEl(null);
  };

  const toggleCompanyOptionsMenu = (event) => {
    setCompanyMenuAnchorEl(event.currentTarget);
  };

  const columnWidthConfig = useMemo(() => ({
    'worker': 14,
    'workerInProdView': 14,
    'checkbox': 2,
    'in': 13,
    'ppeIn': 6,
    'out': 13,
    'ppeOut': 6,
    'break': 13,
    'hours': 8,
    'hoursInProdView': 7,
    'costCodes': 38,
    'remainingHours': 10,
    'productivity': 9,
    'classifiers': 9,
  }), []);

  const handleAccordionChange = () => {
    setExpanded(!expanded)

    let paginationType = 'infiniteScroll'

    if (paginationType === 'paged') {

    } else if (paginationType === 'infiniteScroll') {
      if (size(timesheetData) <= get(companyData, 'attendance.total') && !isLoadingMore && !allDataLoaded) {
        setIsLoadingMore(true)
        handleActionDialogPopover('getAllCompanyTimesheetData', DateTime.fromISO(settingsContext.timesheetDate), {
          companyId: companyData._id,
          pageNumber: 2,
          selectedCrew: get(selectedCrew, '_id')
        });
      }
    }
  };

  const copyToClipboard = (text) => {
    try {
      navigator.clipboard.writeText(text);
      notificationCenter('success', 'Copied successfully.');
    } catch (err) {
      console.log('xx copy error ~> ', err)
      notificationCenter('error', 'Copy error.');
    }
  }

  const toggleProductivityView = useCallback((e) => {
    e.stopPropagation();
    // selectAllShifts(companyData._id, false, true); // NOTE: revert this line when unhide the checkboxes
    setProductivityView(prevProductivityView => {
      window[`${get(companyData, '_id')}_productivityView`] = !prevProductivityView;
      return (!prevProductivityView);
    });
  }, []);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleBulkAction = (event) => {
    // alert('bulk action!')
    setShowBulkActionDialog(true);
  };

  const handleAddNewWorkerShift = (transferShiftMode) => {
    unstable_batchedUpdates(() => {
      if (transferShiftMode) {
        setIsTransferShift(true);
      }
      setShowAddNewWorkerShift(true);
    });
  };

  const handleCancelBulkActionDialog = () => {
    setShowBulkActionDialog(false);
  };

  const handleConfirmBulkActionDialog = () => {
    setShowBulkActionDialog(false);
  };

  const handleCloseApproveAllDialog = () => {
    setShowApproveAllDialog(false);
  };

  const handleCancelSettingsDrawer = () => {
    // TODO: revert any changes the use has made to any fields in the settings drawer
    setOpenDrawer(false);
  };

  const handleSaveSettingsDrawer = () => {
    // TODO: call api to save any changes the user has made to any fields in the settings drawer
    setOpenDrawer(false);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      handleActionDialogPopover('selectAllShifts', companyData._id, { productivityView, unselectAll: false });
      return;
    }

    handleActionDialogPopover('selectAllShifts', companyData._id, { productivityView: false, unselectAll: true });
  };

  const handleTimesheetOptionsCheckbox = (value) => () => {
    const currentIndex = timesheetOptions.indexOf(value);
    const newChecked = [...timesheetOptions];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setTimesheetOptions(newChecked);
  };

  const handleOptionsMenuClick = (drawerName) => {
    handleCompanyOptionsMenuBlur();
    setselectedDrawer(drawerName);
    setOpenDrawer(true);
  };

  const handlePunchTypeToggle = () => {
    setNewWorkerPunchType(newWorkerPunchType === 'in' ? 'out' : 'in');
  };

  const handlePrevPageClick = () => {
    if (pagination > 1) {
      handleActionDialogPopover('getPrevPage', companyData._id, { page: pagination - 1 });
      setPagination(pagination - 1)
    }
  };

  const handleNextPageClick = () => {
    if (pagination < Math.ceil(get(companyData, 'attendance.total') / pageSize)) {
      handleActionDialogPopover('getNextPage', companyData._id, { page: pagination + 1 });
      setPagination(pagination + 1)
    }
  };

  const countSelectedShifts = () => timesheetData.map(oneWorker => get(oneWorker, 'shift', []).filter((oneShift) => oneShift.isSelected)).flat().length;
  const countAllShifts = () => timesheetData.map(oneWorker => get(oneWorker, 'shift', []).filter((oneShift) => ((!productivityView || has(oneShift, 'punchInInfo') && has(oneShift, 'punchOutInfo')) && !oneShift.approved))).flat().length;

  const renderCompanyTimesheetTable = () => {
    return (

      <TableContainer>
        <Table id={`companyTable_${get(companyData, '_id')}`} size="small">
          {loadingPage && <div className={classes.loadingPageWrapper}> <CircularProgress size={'5rem'} color='primary' /> </div>}
          <TableHead>
            <TableRow id={`${get(companyData, '_id')}_shiftViewHeaders`} style={{ display: 'table-row', height: 55 }}>
              <TableCell className={classes.headerStyle}
                style={{ width: `${productivityView ? columnWidthConfig.workerInProdView : columnWidthConfig.worker}%` }}>Worker</TableCell>
              { /* <TableCell className={classes.headerStyle} style={{ width: `${columnWidthConfig.checkbox}%`, paddingRight: 16 }}>

                <Checkbox
                  disabled={!editMode}
                  color="primary"
                  indeterminate={countSelectedShifts() > 0 && countSelectedShifts() < countAllShifts()}
                  checked={countSelectedShifts() === countAllShifts()}
                  onChange={handleSelectAllClick}
                  inputProps={{ 'aria-label': 'select all workers' }}
                />
              </TableCell> */}
              <TableCell className={classes.headerStyle} style={{ width: `${columnWidthConfig.in}%`, minWidth: 170 }}
                align="left">In</TableCell>
              {timesheetOptions.indexOf('PPE') !== -1 &&
                <TableCell className={classes.headerStyle} style={{ width: `${columnWidthConfig.ppeIn}%` }}
                  align="left">PPE</TableCell>}
              <TableCell className={classes.headerStyle} style={{ width: `${columnWidthConfig.out}%`, minWidth: 170 }}
                align="left">Out</TableCell>
              {timesheetOptions.indexOf('PPE') !== -1 &&
                <TableCell className={classes.headerStyle} style={{ width: `${columnWidthConfig.ppeOut}%` }}
                  align="left">PPE</TableCell>}
              <TableCell className={classes.headerStyle} align="left"
                style={{ width: `${columnWidthConfig.break}%`, minWidth: 170 }}>Break (mins)</TableCell>
              <TableCell className={classes.headerStyle} align="left"
                style={{ width: `${columnWidthConfig.hours}%` }}>Hours</TableCell>
              {timesheetOptions.indexOf('Productivity') !== -1 &&
                <TableCell className={classes.headerStyle} align="left" onClick={toggleProductivityView}
                  style={{ width: `${columnWidthConfig.productivity}%`, padding: 0 }}>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    width: `100%`,
                    height: 55,
                    border: '1px solid #e0e0e0',
                    borderBottom: 0,
                    borderRadius: '6px 6px 0px 0px',
                    padding: '1px 24px 2px 16px',
                    marginTop: '4px'
                  }}>
                    <Tooltip title={'Toggle Productivity View'} arrow style={{ position: 'relative' }}>
                      <IconButton
                        onClick={toggleProductivityView}
                        size="small"
                        color="primary"
                        style={{ marginRight: '8px' }}>
                        <GridOnIcon color={'disabled'} />
                        {!get(settingsContext, 'uiConfig.ui.modules.timesheet.permissions.canEditProductivity') && <LockIcon style={{ position: 'absolute', bottom: -4, right: -4, color: '#7a7a7a', fontSize: 12 }} />}
                      </IconButton>
                    </Tooltip>
                    Productivity
                  </div>
                </TableCell>
              }
              {false && <TableCell className={classes.headerStyle} align="left"
                style={{ width: `${columnWidthConfig.classifiers}%` }}>Classifiers</TableCell>}
              {true && <TableCell className={classes.headerStyle} align="right">
                {
                  countSelectedShifts() > 1 &&
                  <Button variant="outlined" size="small" disabled={false}
                    onClick={() => setShowApproveAllDialog(true)} className={classes.approveAllButton}>
                    {companyData.approveAllLoading ?
                      <CircularProgress size={22} style={{ color: '#81cd74' }} /> :
                      countSelectedShifts() === countAllShifts() ? 'APPROVE ALL' :
                        <div>
                          APPROVE <span
                            style={{ textDecoration: 'underline' }}>{`(${countSelectedShifts()})`}</span>
                        </div>
                    }
                  </Button>
                }
                {false && renderPagination()}
              </TableCell>}
            </TableRow>
            <TableRow id={`${get(companyData, '_id')}_productivityViewHeaders`}
              style={{ display: 'none', height: 55 }}>
              <TableCell className={classes.headerStyle}
                style={{ width: `${columnWidthConfig.workerInProdView}%` }}>Worker</TableCell>
              { /* <TableCell className={classes.headerStyle} style={{ width: `${columnWidthConfig.checkbox}%`, paddingRight: 16 }}>
                <Checkbox
                  disabled={!editMode}
                  color="primary"
                  indeterminate={countSelectedShifts() > 0 && countSelectedShifts() < countAllShifts()}
                  checked={countSelectedShifts() === countAllShifts()}
                  onChange={handleSelectAllClick}
                  inputProps={{ 'aria-label': 'select all workers' }}
                />
              </TableCell> */}
              <TableCell className={classes.headerStyle} align="left"
                style={{ width: `${columnWidthConfig.hoursInProdView}%` }}>Hours</TableCell>
              <TableCell className={classes.headerStyle} align="left"
                style={{ width: `${columnWidthConfig.costCodes}%` }}>Cost Codes</TableCell>
              <TableCell className={classes.headerStyle} align="left"
                style={{ width: `${columnWidthConfig.remainingHours}%` }}>Remaining Hours</TableCell>
              {timesheetOptions.indexOf('Productivity') !== -1 &&
                <TableCell className={classes.headerStyle} align="left" onClick={toggleProductivityView} style={{
                  width: `${columnWidthConfig.productivity}%`,
                  padding: 0,
                  borderBottom: '1px solid #f9f9f9',
                }}>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    width: `100%`,
                    height: 55,
                    border: '1px solid #e0e0e0',
                    borderBottom: '1px solid #f9f9f9',
                    borderRadius: '6px 6px 0px 0px',
                    padding: '2px 24px 2px 16px',
                    backgroundColor: '#f9f9f9',
                    marginTop: '4px'
                  }}>
                    <Tooltip title={'Toggle Shift View'} arrow>
                      <IconButton
                        onClick={toggleProductivityView}
                        size="small"
                        color="primary"
                        style={{ marginRight: '8px' }}>
                        <GridOnIcon color={'primary'} />
                        {!get(settingsContext, 'uiConfig.ui.modules.timesheet.permissions.canEditProductivity') && <LockIcon style={{ position: 'absolute', bottom: -4, right: -4, color: '#7a7a7a', fontSize: 12 }} />}
                      </IconButton>
                    </Tooltip>
                    Productivity
                  </div>
                </TableCell>
              }
              {false && <TableCell className={classes.headerStyle} align="left"
                style={{ width: `${columnWidthConfig.classifiers}%` }}>Classifiers</TableCell>}
              {true && <TableCell className={classes.headerStyle} align="right">
                {
                  countSelectedShifts() > 1 &&
                  <Button variant="outlined" size="small" disabled={false}
                    onClick={() => setShowApproveAllDialog(true)} className={classes.approveAllButton}>
                    {companyData.approveAllLoading ?
                      <CircularProgress size={22} style={{ color: '#81cd74' }} /> :
                      countSelectedShifts() === countAllShifts() ? 'APPROVE ALL' :
                        <div>
                          APPROVE <span
                            style={{ textDecoration: 'underline' }}>{`(${countSelectedShifts()})`}</span>
                        </div>
                    }
                  </Button>
                }
                {false && renderPagination()}
              </TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              timesheetData.map((oneWorker, workerIndex) =>
                <TableRow key={oneWorker._id + '_row'} id={oneWorker._id + '_row'}>
                  <TableCell
                    colSpan={9 + (timesheetOptions.indexOf('PPE') !== 0 ? 2 : 0) + (timesheetOptions.indexOf('Productivity') !== 0 ? 1 : 0)}
                    style={{ padding: 0 }}>
                    <WorkerRow
                      key={oneWorker._id}
                      companyData={companyData}
                      project={project}
                      config={config}
                      workerData={timesheetData[workerIndex]}
                      timesheetOptions={timesheetOptions}
                      columnWidthConfig={columnWidthConfig}
                      toggleProductivityView={toggleProductivityView}
                      preloadedCostCodes={preloadedCostCodes}
                      editMode={editMode}
                      classifiers={classifiers}
                      handleActionDialogPopover={handleActionDialogPopover}
                    />
                  </TableCell>
                </TableRow>,
              )
            }
            {loadingMoreData &&
              <TableRow>
                <TableCell style={{ textAlign: 'center' }} colSpan={9 + (timesheetOptions.indexOf('PPE') !== 0 ? 2 : 0) + (timesheetOptions.indexOf('Productivity') !== 0 ? 1 : 0)}>
                  <CircularProgress size={22} color='primary' />
                </TableCell>
              </TableRow>
            }
            {allDataLoaded &&
              <TableRow>
                <TableCell style={{ textAlign: 'center' }} colSpan={9 + (timesheetOptions.indexOf('PPE') !== 0 ? 2 : 0) + (timesheetOptions.indexOf('Productivity') !== 0 ? 1 : 0)}>
                  <div className={classes.timesheetFooterWrapper}>
                    <Typography variant="subtitle2">Displaying {timesheetData.length} {timesheetData.length === 1 ? 'worker' : 'workers'}</Typography>
                  </div>
                </TableCell>
              </TableRow>
            }
            {false &&
              <TableRow>
                <TableCell align='center' colSpan={9 + (timesheetOptions.indexOf('PPE') !== 0 ? 2 : 0) + (timesheetOptions.indexOf('Productivity') !== 0 ? 1 : 0)}>
                  {renderPagination()}
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderDrawer = () => {
    return (
      <Drawer anchor="right" open={openDrawer} onClose={() => {
        setOpenDrawer(false);
      }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '200px' }}>
          {renderDrawerHeader()}
          {renderDrawerBody()}
          {renderDrawerActions()}
        </div>
      </Drawer>
    );
  };

  const renderDrawerHeader = () => {
    return (
      <div className={classes.drawerHeader}>
        <div className={classes.drawerHeaderFont}>{`${get(companyData, 'name', '')}`} <br />{selectedDrawer}</div>
      </div>
    );
  };

  const renderDrawerBody = () => {
    switch (selectedDrawer) {
      case 'Show/Hide Columns':
        return (
          <div>
            <ListItem button onClick={handleTimesheetOptionsCheckbox('PPE')}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  onChange={handleTimesheetOptionsCheckbox('PPE')}
                  checked={timesheetOptions.indexOf('PPE') !== -1}
                  inputProps={{ 'aria-labelledby': 'ppeCheckbox' }}
                  color="primary"
                />
              </ListItemIcon>
              <ListItemText id="ppeCheckbox" primary={'PPE'} />
            </ListItem>
            <ListItem button onClick={handleTimesheetOptionsCheckbox('Productivity')}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  onChange={handleTimesheetOptionsCheckbox('Productivity')}
                  checked={timesheetOptions.indexOf('Productivity') !== -1}
                  inputProps={{ 'aria-labelledby': 'productivityCheckbox' }}
                  color="primary"
                />
              </ListItemIcon>
              <ListItemText id="productivityCheckbox" primary={'Productivity'} />
            </ListItem>
          </div>
        );
      case 'Settings':
        return (
          <div className={classes.drawerBody}>
            <FormControl fullWidth>
              <TextField
                id="payroll_id"
                name="payroll_id"
                label="Payroll ID"
                color="primary"
                variant="filled"
                size="small"
              // value={companyData.payroll_id}
              // onChange={handlePayrollIdChange}
              />
            </FormControl>
          </div>
        );
      default:
        return null;
    }
  };

  const renderDrawerActions = () => {
    switch (selectedDrawer) {
      case 'Settings':
        return (
          <div className={classes.settingsActionsWrapper}>
            <Button variant="outlined" onClick={handleCancelSettingsDrawer} style={{ width: 80 }}>
              Cancel
            </Button>
            <Button variant="outlined" color="primary" onClick={handleSaveSettingsDrawer} style={{ width: 80 }}>
              Save
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  const renderAttendanceGaugeMeter = () => {
    let current = get(companyData, 'attendance.current');
    let total = get(companyData, 'attendance.total');

    return (
      <GaugeMeter
        title=""
        disableBackground
        thickMeter
        values={{ current, total }}
        size={50}
        noActivity={total === 0 && current === 0}
        noActivityIcon={{ type: 'localFile', name: 'smartBarrelWorker.svg' }}
        activityCompleted={current === 0 && total !== 0}
      />
    );
  };

  const CustomLinearProgress = withStyles((theme) => ({
    root: {
      height: 2,
    },
    colorPrimary: {
      // backgroundColor: "rgb(241,89,34, 0.4)",
    },
    bar: {
      // borderRadius: 5,
      backgroundColor: 'rgb(241,89,34, 0.6)',
    },
  }))(LinearProgress);

  const renderFilterByPositionDropDown = () => {

    const {
      getRootProps,
      getInputProps,
      getListboxProps,
      getOptionProps,
      getClearProps,
      groupedOptions,
      value,
      focused,
      setAnchorEl,
    } = useAutocomplete({
      id: 'selectPositionDropdown',
      defaultValue: null,
      multiple: false,
      options: positionsArray,
      getOptionLabel: (option) => option.name,
      onChange: (event, value) => setSelectedPosition(value),
    });

    const handlePositionClick = (e) => {
      e.stopPropagation();
    };

    if (positionsArray.length === 0 || !expanded) {
      return (
        <div></div>
      );
    } else {
      return (
          <FormControl fullWidth style={{ width: 200 }}>
            <div>
              <div {...getRootProps()}>
                <div ref={setAnchorEl} className={focused ? 'focused' : ''}>
                  <TextField
                    {...getInputProps()}
                    variant="outlined"
                    placeholder="Filter by position"
                    size="small"
                    onClick={handlePositionClick}
                    InputProps={!isNull(selectedPosition) ? {
                      endAdornment: (
                        <InputAdornment
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            getInputProps().onBlur();
                            getClearProps().onClick();
                            setSelectedPosition(null);
                          }}
                          position="end">
                          <Close />
                        </InputAdornment>
                      ),
                    } : {}}
                  />
                </div>
              </div>
              {groupedOptions.length > 0 ? (
                <ul {...getListboxProps()} className={classes.filterByPositionDropDown}>
                  {groupedOptions.map((option, index) => (
                    <li {...getOptionProps({ option, index })} onClick={(event) => { event.stopPropagation(); getOptionProps({ option, index }).onClick(event); }}>
                      <Typography variant="subtitle2">{get(option, 'name')}</Typography>
                    </li>
                  ))}
                </ul>
              ) : null}

            </div>
          </FormControl>
      );
    }
  };


  const renderFilterByDepartmentDropDown = () => {

    const {
      getRootProps,
      getInputProps,
      getListboxProps,
      getOptionProps,
      getClearProps,
      groupedOptions,
      value,
      focused,
      setAnchorEl,
    } = useAutocomplete({
      id: 'selectDepartmentDropdown',
      defaultValue: null,
      multiple: false,
      options: departmentArray,
      getOptionLabel: (option) => option.name,
      onChange: (event, value) => setSelectedDepartment(value),
    });

    const handleDepartmentClick = (e) => {
      e.stopPropagation();
    };

    if (departmentArray.length === 0 || !expanded) {
      return (
        <div></div>
      );
    } else {
      return (
        <FormControl fullWidth style={{ width: 200 }}>
          <div>
            <div {...getRootProps()}>
              <div ref={setAnchorEl} className={focused ? 'focused' : ''}>
                <TextField
                  {...getInputProps()}
                  variant="outlined"
                  placeholder="Filter by department"
                  size="small"
                  onClick={handleDepartmentClick}
                  InputProps={!isNull(selectedDepartment) ? {
                    endAdornment: (
                      <InputAdornment
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          getInputProps().onBlur();
                          getClearProps().onClick();
                          setSelectedDepartment(null);
                        }}
                        position="end">
                        <Close />
                      </InputAdornment>
                    ),
                  } : {}}
                />
              </div>
            </div>
            {groupedOptions.length > 0 ? (
              <ul {...getListboxProps()} className={classes.filterByPositionDropDown}>
                {groupedOptions.map((option, index) => (
                  <li {...getOptionProps({ option, index })} onClick={(event) => { event.stopPropagation(); getOptionProps({ option, index }).onClick(event); }}>
                    <Typography variant="subtitle2">{get(option, 'name')}</Typography>
                  </li>
                ))}
              </ul>
            ) : null}

          </div>
        </FormControl>
      );
    }
  };

  const renderPagination = () => {
    return (
      <div className={classes.paginationWrapper} style={{}}>
        <IconButton onClick={handlePrevPageClick} small disabled={pagination === 1}>
          <NavigateBeforeIcon />
        </IconButton>
        Page {pagination}
        <IconButton onClick={handleNextPageClick} small disabled={timesheetData.length < pageSize}>
          <NavigateNextIcon />
        </IconButton>
      </div>
    )
  }
  useEffect(() => {
    // logic to hide/show productivityView/shiftView
    const shiftViewTableHead = document.getElementById(`${get(companyData, '_id')}_shiftViewHeaders`);
    const productivityViewTableHead = document.getElementById(`${get(companyData, '_id')}_productivityViewHeaders`);

    if (!isNull(shiftViewTableHead) && !isNull(productivityViewTableHead)) {
      if (productivityView) {
        shiftViewTableHead.style.display = 'none';
        productivityViewTableHead.style.display = 'table-row';
      } else {
        shiftViewTableHead.style.display = 'table-row';
        productivityViewTableHead.style.display = 'none';
      }
    }

    timesheetData.map(oneWorker => {
      const shiftViewWrapper = document.getElementById(`${get(companyData, '_id')}_${get(oneWorker, '_id')}_shiftViewWrapper`);
      const productivityViewWrapper = document.getElementById(`${get(companyData, '_id')}_${get(oneWorker, '_id')}_productivityViewWrapper`);

      if (!isNull(productivityViewWrapper) && !isNull(shiftViewWrapper)) {
        if (productivityView) {
          shiftViewWrapper.style.display = 'none';
          productivityViewWrapper.style.display = 'block';
        } else {
          shiftViewWrapper.style.display = 'block';
          productivityViewWrapper.style.display = 'none';
        }
      }
    });
  }, [productivityView]);

  useEffect(() => {
    if (!isNull(selectedPosition)) {
      timesheetData.map(oneWorker => {
        const workerRowRoot = document.getElementById(`${get(oneWorker, '_id')}_row`);

        if (isEqual(get(oneWorker, 'position._id', '000'), get(selectedPosition, '_id'))) {
          workerRowRoot.style.display = 'table-row'
        } else {
          workerRowRoot.style.display = 'none'
        }
      })
    } else {
      timesheetData.map(oneWorker => {
        const workerRowRoot = document.getElementById(`${get(oneWorker, '_id')}_row`)
        workerRowRoot.style.display = 'table-row'
      })
    }
  }, [selectedPosition]);


  useEffect(() => {
    if (!isNull(selectedDepartment)) {
      timesheetData.map(oneWorker => {
        const workerRowRoot = document.getElementById(`${get(oneWorker, '_id')}_row`);

        if (isEqual(get(oneWorker, 'department._id', '000'), get(selectedDepartment, '_id'))) {
          workerRowRoot.style.display = 'table-row'
        } else {
          workerRowRoot.style.display = 'none'
        }
      })
    } else {
      timesheetData.map(oneWorker => {
        const workerRowRoot = document.getElementById(`${get(oneWorker, '_id')}_row`)
        workerRowRoot.style.display = 'table-row'
      })
    }
  }, [selectedDepartment]);

  useEffect(() => {
    setEditMode(loadingMoreData ? false : get(settingsContext, 'uiConfig.ui.modules.timesheet.permissions.canEdit'))
  }, [loadingMoreData]);

  useEffect(() => {
    setPositionsArray(prevPositions => {
      let temp = []

      timesheetData.map(oneWorker => {
        let filtered = positionsArray.filter(onePosition => get(onePosition, '_id') === get(oneWorker, 'position._id', '000'))

        if (isEmpty((filtered))) {
          prevPositions.push(get(oneWorker, 'position', { name: 'No position', _id: '000' }))
        }
      })

      return prevPositions.concat(temp)
    })

    if (!isNull(selectedPosition)) {
      timesheetData.map(oneWorker => {
        const workerRowRoot = document.getElementById(`${get(oneWorker, '_id')}_row`);

        if (isEqual(get(oneWorker, 'position._id', '000'), get(selectedPosition, '_id'))) {
          workerRowRoot.style.display = 'table-row'
        } else {
          workerRowRoot.style.display = 'none'
        }
      })
    }
// Departments
    setDepartmentArray(prevDepartments => {
      let tempDepartment = []

      timesheetData.map(oneWorker => {
        let filteredDepartment = departmentArray.filter(oneDepartment => get(oneDepartment, '_id') === get(oneWorker, 'department._id', '000'))

        if (isEmpty((filteredDepartment))) {
          prevDepartments.push(get(oneWorker, 'department', { name: 'No department', _id: '000' }))
        }
      })

      return prevDepartments.concat(tempDepartment)
    })

    if (!isNull(selectedDepartment)) {
      timesheetData.map(oneWorker => {
        const workerRowRoot = document.getElementById(`${get(oneWorker, '_id')}_row`);

        if (isEqual(get(oneWorker, 'department._id', '000'), get(selectedDepartment, '_id'))) {
          workerRowRoot.style.display = 'table-row'
        } else {
          workerRowRoot.style.display = 'none'
        }
      })
    }

  }, [timesheetData]);
  

  const updates = React.useRef(0);

  return (
    <div id={`${get(companyData, '_id')}_companyTimesheet`}>
      {settingsContext.showRedrawCounters && updates.current++}
      {renderDrawer()}
      <AccordionItem
        onAccodionItemChange={handleAccordionChange}
        style={{ marginTop: isFirst ? 8 : 16 }}
        title={
          <div className={classes.companyNameAndEinWrapper}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography ref={companyNameRef} variant="subtitle2">{get(companyData, 'name')}</Typography>
              <Tooltip title="Copy Company Name" arrow>
                <FileCopyOutlinedIcon className={classes.copyIcon} onClick={(e) => { e.stopPropagation(); copyToClipboard(get(companyData, 'name')) }} />
              </Tooltip>
            </div>
            {has(companyData, 'ein') && !isEmpty(get(companyData, 'ein')) &&
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="caption">
                  {get(companyData, 'ein').substr(0, 2) + '-' + get(companyData, 'ein').substr(2)}
                </Typography>
                <Tooltip title="Copy Company EIN" arrow>
                  <FileCopyOutlinedIcon className={classes.copyIcon} onClick={(e) => { e.stopPropagation(); copyToClipboard(get(companyData, 'ein')) }} />
                </Tooltip>
              </div>
            }
          </div>
        }
        detailsPadding={0}
        loading={loadingData}
        renderToolbar={<>
          {
            /* countSelectedShifts() > 1 &&
            <div style={{ display: 'flex' }} onClick={(e) => { e.stopPropagation(); handleBulkAction() }}>
              <div style={{ marginRight: 8 }}>
                <DoneAllIcon color="primary" />
              </div>
              <Typography variant='subtitle1' color="primary">{`Bulk Action (${countSelectedShifts()} selected)`}</Typography>
            </div> */
          }
          <div className={classes.companyRowActions}>
            {/*<Tooltip title='Add Transfer Shift' arrow>
                      <IconButton
                        disabled={(isEmpty( get( companyData, 'projects', [])) || isEmpty( get( companyData, 'workers', []))) || !editMode}
                        className={classes.addWorkerPunchButton}
                        onClick={() => {handleAddNewWorkerShift(true)}}
                        size="small">
                          <DirectionsCarIcon style={{ fontSize: 30 }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Add Shift' arrow>
                      <IconButton
                        disabled={isEmpty( get( companyData, 'workers', [])) || !editMode}
                        className={classes.addWorkerPunchButton}
                        onClick={() => {handleAddNewWorkerShift(false)}}
                        size="small">
                            <SvgIcon {...props} style={{ fontSize: 30 }}>
                                <path
                                    d="M17.5,10c1.93,0,3.5-1.57,3.5-3.5c0-0.58-0.16-1.12-0.41-1.6l-2.7,2.7L16.4,6.11l2.7-2.7C18.62,3.16,18.08,3,17.5,3 C15.57,3,14,4.57,14,6.5c0,0.41,0.08,0.8,0.21,1.16l-1.85,1.85l-1.78-1.78l0.71-0.71L9.88,5.61L12,3.49 c-1.17-1.17-3.07-1.17-4.24,0L4.22,7.03l1.41,1.41H2.81L2.1,9.15l3.54,3.54l0.71-0.71V9.15l1.41,1.41l0.71-0.71l1.78,1.78 l-7.41,7.41l2.12,2.12L16.34,9.79C16.7,9.92,17.09,10,17.5,10z"/>
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>*/}
            <Grid alignItems="center" spacing={2} container>
              <Grid item>
                {renderFilterByPositionDropDown()}
              </Grid>
              <Grid item>
                {renderFilterByDepartmentDropDown()}
              </Grid>
              <Grid item>
                {renderAttendanceGaugeMeter()}
              </Grid>
              {!isEmpty(get(settingsContext, 'uiConfig.data.project')) &&
                <Grid item>
                  <Wizard
                    disabled={!get(settingsContext, 'uiConfig.ui.modules.timesheet.permissions.canAddWorker')}
                    worker={null}
                    company={companyData}
                    project={get(settingsContext, 'uiConfig.data.project')}
                    config={config}
                    defaultOpen={false}
                  />
                </Grid>
              }
              <Grid item>
                <Tooltip title={editMode ? 'Toggle View Mode' : 'Toggle Edit Mode'} arrow>
                  <span>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleEditMode();
                      }}
                      disabled={!get(settingsContext, 'uiConfig.ui.modules.timesheet.permissions.canEdit')}
                      size="small"
                      color={editMode ? 'primary' : 'default'}>
                      <EditIcon style={{ fontSize: 30 }} />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Options" arrow>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleCompanyOptionsMenu(e);
                    }}>
                    <MoreVertIcon style={{ fontSize: 30 }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Popover
                  id="companyTimesheetMenuPopover"
                  open={companyMenuAnchorEl !== null}
                  onClose={handleCompanyOptionsMenuBlur}
                  anchorEl={companyMenuAnchorEl}
                  anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'center', horizontal: 'right' }}>
                  <MenuList id="companyTimesheetMenu" aria-labelledby="companyTimesheetMenu">
                    <MenuItem
                      disabled={!editMode || !get(settingsContext, 'uiConfig.ui.modules.timesheet.permissions.canAddShift')}
                      onClick={() => {
                        handleCompanyOptionsMenuBlur();
                        handleAddNewWorkerShift(false);
                      }} className={classes.companyOptionsMenuItem}>
                      <ListItemIcon>
                        <ClockIcon className={classes.companyOptionsMenuItem} />
                      </ListItemIcon>
                      <ListItemText>Add Shift</ListItemText>
                    </MenuItem>
                    <MenuItem
                      disabled={!editMode || !get(settingsContext, 'uiConfig.ui.modules.timesheet.permissions.canAddShift')}
                      onClick={() => {
                        handleCompanyOptionsMenuBlur();
                        handleAddNewWorkerShift(true);
                      }} className={classes.companyOptionsMenuItem}>
                      <ListItemIcon>
                        <DirectionsCarIcon className={classes.companyOptionsMenuItem} />
                      </ListItemIcon>
                      <ListItemText>Add Transfer Shift</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => handleOptionsMenuClick('Show/Hide Columns')}
                      className={classes.companyOptionsMenuItem}>
                      <ListItemIcon>
                        <VisibilityIcon className={classes.companyOptionsMenuItem} />
                      </ListItemIcon>
                      <ListItemText>Show/Hide Columns</ListItemText>
                    </MenuItem>
                    {false && <MenuItem onClick={() => handleOptionsMenuClick('Settings')}
                      className={classes.companyOptionsMenuItem}>
                      <ListItemIcon>
                        <SettingsIcon className={classes.companyOptionsMenuItem} />
                      </ListItemIcon>
                      <ListItemText>Settings</ListItemText>
                    </MenuItem>}
                  </MenuList>
                </Popover>
              </Grid>
            </Grid>
          </div>
        </>}
      >
        {!isEmpty(timesheetData)
          ? renderCompanyTimesheetTable() :
          <div className={classes.noShiftsWrapper}>
            <Typography variant="subtitle2">No shifts to display</Typography>
          </div>}
      </AccordionItem>

      {showAddNewWorkerShift && <NewWorkerShift
        classes={classes}
        config={config}
        companyData={companyData}
        project={project}
        showAddNewWorkerShift={showAddNewWorkerShift}
        setShowAddNewWorkerShift={setShowAddNewWorkerShift}
        handleActionDialogPopover={handleActionDialogPopover}
        isTransferShift={isTransferShift}
        setIsTransferShift={setIsTransferShift}
        selectedCrew={selectedCrew}
        user={user}
      />
      }

      <BulkActionDialog
        classes={classes}
        showBulkActionDialog={showBulkActionDialog}
        confirmBulkActionDialog={handleConfirmBulkActionDialog}
        cancelBulkActionDialog={handleCancelBulkActionDialog}
        productivityView={productivityView}
      />

    </div>
  );
};

// const customCompare = (prevProps, nextProps) => {
//     // console.log('xxx: ', prevProps);
//     // console.log('yyy: ', nextProps);
//   if (prevProps.loadingData !== nextProps.loadingData) {
//     return false;
//   }
//
//   if (prevProps.expanded !== nextProps.expanded) {
//     return false;
//   }
//
//   if (!isEqual(prevProps.timesheetData, nextProps.timesheetData)) {
//     // console.log('prevProps: ', prevProps);
//     // console.log('nextProps: ', nextProps);
//     return false;
//   }
//
//   // return false; // props are not equal -> update the component
//   return true; // props are equal -> dont update the component
// }

// CompanyTimesheet.whyDidYouRender = true

export default memo(CompanyTimesheet);
