import React, { useState } from 'react';
import axios from "axios";
import { axiosConfig } from "../helpers/index";
import { myTheme } from "../helpers/index";
import { get } from 'lodash';
import { ThemeProvider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress, TextField } from '@material-ui/core';
import { notificationCenter } from '../helpers/notifications';
export default function RevokeCode(props) {

  const { config, record, onCompleteSafety, loading, onDisclaimer, loadRecords } = props;

  const [verifying, setVerifying] = useState(false);
  const [code, setCode] = useState('');
  const [response, setResponse] = useState(null);
  const [message, setMessage] = useState({
    title: '',
    body: ''
  });

  const buildPayload = () => {
    return {
      phone_number: record.phoneNumber,
      code: code
    }
  }

  const verify = (ev) => {
    ev.preventDefault();

    setVerifying(true);
    axios.patch(config.revoke_workers_endpoint, buildPayload(), axiosConfig()).then(response => {
      loadRecords()
      setCode('');
      setVerifying(false)
      onDisclaimer(get(response, 'data.onboard.disclaimer', ''))
      setResponse(response.data);
      setMessage({
        title: 'Success',
        body: response.data.message
      })
    }).catch(errorVerifyingCode => {
      console.log({ errorVerifyingCode });
      notificationCenter("error", get(errorVerifyingCode, 'error', 'Error'))
      setVerifying(false);
      setCode('');
      setMessage({
        title: 'Error',
        body: errorVerifyingCode.response.data.error
      })
    });
  }

  const handleClose = () => {
    setMessage({
      title: '',
      body: ''
    })

    if (props.onCompleteSafety) onCompleteSafety(response)
  }

  const handleCodeChange = () => {
    const numbersRegex = new RegExp(/^$|^[0-9]/, 'g')

    if (numbersRegex.test(event.target.value)) {
      setCode(event.target.value)
    }
  }

  return (
    <div>
      <TextField placeholder="Verification Code" variant="outlined" onChange={handleCodeChange} value={code} style={{ marginTop: -2 }} inputProps={{ maxLength: 6 }} />

      <Button disabled={code === '' || code.length < 6 || loading} onClick={verify} color="primary" style={{ borderRadius: 4 }}>
        { verifying ?
          <div style={{ display: 'flex', width: 20, alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress size={16} color='primary' />
          </div> : 'Verify'
        }
      </Button>
      <Dialog open={message.title.length > 0} onClose={handleClose} >
        <DialogTitle>{message.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
