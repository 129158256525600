import React, { useContext, useEffect, useState } from 'react';

import {
  Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,
} from '@material-ui/core';
import axios from 'axios';
import { axiosConfig } from '../../../../../helpers';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { ProjectContext } from '../../ProjectMetrics';
import { get } from 'lodash';

const DialogEditItem = ({ id, value, onChange }) => {
  const { config: { project_workSummary_endpoint: endpoint }, project } = useContext(ProjectContext);

  const [open, setOpen] = useState(false);
  const [text, setText] = useState(value);
  const [loading, setLoading] = useState(false);

  useEffect(() => setText(text), [value]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = async () => {
    setLoading(true);
    const uri = `${endpoint}/${id}`;

    return axios.patch(uri, { text, type: 'work_summary', projectId: get(project, '_id.$oid') }, axiosConfig())
      .then(() => {
        setOpen(false);
        onChange && onChange();
      })
      .finally(() => setLoading(false));
  };

  return (<>
    <IconButton component={'span'} onClick={handleOpen}>
      <EditIcon />
    </IconButton>

    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit message</DialogTitle>
      {loading && <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress />
      </DialogContent>}
      {!loading && <>
        <DialogContent>
          <DialogContentText>This change cannot be undone, are you sure ?</DialogContentText>
          <TextField
            minRows={3}
            multiline={true}
            defaultValue={text}
            className={'w-100'}
            onChange={({ target }) => setText(target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button color={'default'} onClick={handleClose}>Cancel</Button>
          <Button color={'primary'} onClick={handleChange}>Change</Button>
        </DialogActions>
      </>}
    </Dialog>
  </>);
};

export default DialogEditItem;
