import axios from 'axios';
import { axiosConfig } from '../helpers/index';
import { get } from 'lodash';

export const gettingCompanyEmployeeSettings = (companyWorkersDomain) => {
  return axios.get(`${companyWorkersDomain}/workers/listSettings`, axiosConfig())
}

export const createClassifier = (companyId, payload, classifiersEndpoint) => {
  return axios.post(`${classifiersEndpoint}/${companyId}`, payload, axiosConfig())
}

export const editClassifier = (companyId, payload, classifiersEndpoint, classifierId) => {
  return axios.patch(`${classifiersEndpoint}/${companyId}/${classifierId}`, payload, axiosConfig())
}

export const deletingClassifier = (companyId, classifiersEndpoint, classifierId) => {
  return axios.delete(`${classifiersEndpoint}/${companyId}/${classifierId}`, axiosConfig())
}

export const gettingCompanyClassifiers = (companyId, payload, classifiersEndpoint) => {
  return axios.post(`${classifiersEndpoint}/${companyId}/list`, payload, axiosConfig())
}

export const getBuiltInClassifiers = (dashboardEndpoint) => {
  return axios.get(`${dashboardEndpoint}/uiSettings/classifiers`, axiosConfig())
}
