import React, { useEffect, useState } from 'react';
import { makeStyles, TextField, Button, FormControl, InputLabel, MenuItem, Select, CircularProgress } from '@material-ui/core';
import { get, isEmpty, isNull, split, each, join, isUndefined, set } from 'lodash';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { notificationCenter } from '../../helpers/notifications';



export default function RoundUpTimeRulesPunch(props) {

  const useStyles = makeStyles((theme) => ({
    textField: {
      marginRight: '10px',
      width: '100px'
    },
    mainContainer: {
      display: 'flex',
      alignItems: 'end'
    },
    punchType: {
      width: '100px'
    },
    actions: {
      cursor: 'pointer',
      marginLeft: '5px'
    }
  }));

  const classes = useStyles();

  const { rules, onDeleteRule, onEnableEditRule, onSaveRule } = props;

  const [state, setState] = useState({
    newRule: null,
    punchRules: [],
    createRule: false,
    saving: false,
    loading: false,
    currentEdit: false
  })

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      newRule: null,
      createRule: false,
      currentEdit: false,
      loading: false,
      saving: false,
      punchRules: rules
    }))
  }, [rules])


  const onDeletePunchRule = (rule = null) => {

    if (isNull(rule)) {
      setState(prevState => ({
        ...prevState,
        newRule: null,
        createRule: false,
      }))
    } else {

      setState(prevState => ({
        ...prevState,
        punchRules: prevState.punchRules.filter(item => get(item, '_id') !== get(rule, '_id'))
      }))

      onDeleteRule(get(rule, '_id'), 'punch')
    }
  }

  const onCreatePunchRule = (e) => {

    const name = e.target.name;
    const value = e.target.value;

    setState(prevState => ({
      ...prevState,
      newRule: {
        ...prevState.newRule,
        [name]: value
      }
    }))

  }

  const onAddNew = () => {
    setState(prevState => ({
      ...prevState,
      createRule: true
    }))
  }

  const onEditPunchRule = (id) => {

    setState(prevState => ({
      ...prevState,
      saving: true,
      currentEdit: true,
      punchRules: prevState.punchRules.map(item => {
        if (get(item, '_id') === id) {
          set(item, 'editStatus', true)
        }
        return item;
      })
    }))
  }


  const onValidation = (rule, id = null) => {

    const from = split(get(rule, 'from', ''), ':');
    const to = split(get(rule, 'to', ''), ':');
    const change_to = split(get(rule, 'change_to', ''), ':');

    const intFrom = parseInt(join(split(get(rule, 'from', ''), ':'), ''));
    const intTo = parseInt(join(split(get(rule, 'to', ''), ':'), ''));


    const hasEmptyValues = () => {
      return isEmpty(get(rule, 'from', '')) || isEmpty(get(rule, 'to', '')) || isEmpty(get(rule, 'change_to', ''));
    }

    const isNotFormatted = () => {
      return (parseInt(get(from, '0')) > 23) || (parseInt(get(from, '1')) > 60) || (parseInt(get(to, '0')) > 23) || (parseInt(get(to, '1')) > 60) || (parseInt(get(change_to, '0')) > 23) || (parseInt(get(change_to, '1')) > 60) || parseInt(get(from, '0')) > parseInt(get(to, '0'));
    }

    const isNotInRange = () => {
      let flag = true;
      let rules = [];

      if (get(rule, 'applies_to') === 'all') {
        rules = isNull(id) ? state.punchRules : state.punchRules.filter(item => get(item, '_id') !== id)
      } else {
        rules = isNull(id) ? state.punchRules.filter(item => get(item, 'rule.applies_to') === get(rule, 'applies_to')) : state.punchRules.filter(item => get(item, '_id') !== id && get(item, 'rule.applies_to') === get(rule, 'applies_to'))
      }

      if (intTo <= intFrom) return flag;
      if (isEmpty(rules)) return false;

      const aux = rules.find(item => {
        const fromTemp = parseInt(join(split(get(item, 'rule.from', ''), ':'), ''));
        const toTemp = parseInt(join(split(get(item, 'rule.to', ''), ':'), ''));

        return (intFrom < toTemp && intFrom >= fromTemp)
      })

      return !isUndefined(aux)
    }

    if (hasEmptyValues()) {
      notificationCenter('error', 'You are missing some required fields!')
    } else if (isNotFormatted()) {
      notificationCenter('error', 'You have to enter a valid format time!')
    } else if (isNotInRange()) {
      notificationCenter('error', "New rule can't be in range with others")
    } else {

      setState(prevState => ({
        ...prevState,
        loading: true,

      }))
      return true;
    }

    return false;

  }

  const onEditRule = (id, name, value) => {
    setState(prevState => ({
      ...prevState,
      punchRules: prevState.punchRules.map(item => {
        if (get(item, '_id') === id) {
          item = {
            ...item,
            rule: {
              ...item.rule,
              [name]: value
            }
          }

        }
        return item;
      })
    }))
  }

  const onSavingPunchRule = (item = null) => {
    if (isNull(item)) {
      if (onValidation(state.newRule)) {
        let payload = {
          rule: { ...state.newRule },
          modifies: 'punch',
          status: 'new'
        }


        onSaveRule(payload), 'punch';
      }
    } else {
      if (onValidation(get(item, 'rule'), get(item, '_id'))) {
        set(item, 'onSaveStatus', true)
        let payload = {
          ...item
        }

        onSaveRule(payload, 'punch')
      }
    }
  }

  return (
    <>
      {get(state, 'punchRules', []).map((item, idx) => (
        <div key={idx} className={classes.mainContainer}>
          <TextField
            id="time"
            label="From"
            name="from"
            type="time"
            value={get(item, 'rule.from')}
            className={classes.textField}
            disabled={!get(item, 'editStatus', false)}
            onChange={e => onEditRule(get(item, '_id'), 'from', e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
          />
          <TextField
            id="time"
            label="To"
            name="to"
            type="time"
            disabled={!get(item, 'editStatus', false)}
            value={get(item, 'rule.to')}
            className={classes.textField}
            onChange={e => onEditRule(get(item, '_id'), 'to', e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
          />

          <TextField
            id="time"
            label="Change To"
            disabled={!get(item, 'editStatus', false)}
            name="change_to"
            type="time"
            value={get(item, 'rule.change_to')}
            className={classes.textField}
            onChange={e => onEditRule(get(item, '_id'), 'change_to', e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300,
            }}
          />
          <FormControl>
            <InputLabel id="demo-simple-select-label">Punch Type</InputLabel>
            <Select
              className={classes.punchType}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disabled={!get(item, 'editStatus', false)}
              value={get(item, 'rule.applies_to')}
              onChange={e => onEditRule(get(item, '_id'), 'applies_to', e.target.value)}
            >
              <MenuItem value='all'>All</MenuItem>
              <MenuItem value='punch-in'>Punch In</MenuItem>
              <MenuItem value='punch-out'>Punch Out</MenuItem>
            </Select>
          </FormControl>
          <div>
            {get(item, 'onSaveStatus', false) ? <CircularProgress size={15} color="primary" /> : <CheckBoxIcon className={classes.actions} style={{ color: `${get(item, 'editStatus', false) ? 'rgb(1, 181, 0)' : 'rgb(64, 64, 64)'}`, cursor: `${!get(item, 'editStatus', false) ? 'not-allowed' : 'pointer'}` }} onClick={() => get(item, 'editStatus', false) ? onSavingPunchRule(item) : null} />}

            <EditIcon className={classes.actions} onClick={() => onEditPunchRule(get(item, '_id'))} />
            <DeleteForeverIcon onClick={() => onDeletePunchRule(item)} className={classes.actions} />
          </div>
        </div>
      ))}

      {state.createRule ?
        <div className={classes.mainContainer}>

          <TextField
            id="time"
            label="From"
            name="from"
            type="time"
            value={get(state, 'newRule.from')}
            className={classes.textField}
            onChange={onCreatePunchRule}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
          />
          <TextField
            id="time"
            label="To"
            name="to"
            type="time"
            value={get(state, 'newRule.to')}
            className={classes.textField}
            onChange={onCreatePunchRule}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
          />
          <TextField
            id="time"
            label="Change To"
            name="change_to"
            type="time"
            value={get(state, 'newRule.change_to')}
            className={classes.textField}
            onChange={onCreatePunchRule}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
          />
          <FormControl>
            <InputLabel id="demo-simple-select-label">Punch Type</InputLabel>
            <Select
              className={classes.punchType}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="applies_to"
              value={get(state, 'newRule.applies_to')}
              onChange={onCreatePunchRule}
            >
              <MenuItem value='all'>All</MenuItem>
              <MenuItem value='punch-in'>Punch In</MenuItem>
              <MenuItem value='punch-out'>Punch Out</MenuItem>
            </Select>
          </FormControl>
          <div>
            {state.loading ? <CircularProgress size={15} color="primary" /> : <CheckBoxIcon className={classes.actions} onClick={() => onSavingPunchRule()} style={{ color: 'rgb(1, 181, 0)' }} />}
            <DeleteForeverIcon onClick={() => onDeletePunchRule()} className={classes.actions} />
          </div>
        </div> : null
      }

      <Button style={{ marginTop: '20px' }} onClick={() => onAddNew()} disabled={state.createRule || state.currentEdit} variant="contained" color="primary">
        Add New
      </Button>

    </>
  )
};