import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';

const SingleSelect = ({ value, onChange, options, pluginOptions }) => {
  
  const ref = useRef(null)
  
  const [selected, setSelected] = useState(null)
  
  useLayoutEffect(() => {
    $(ref.current).select2(pluginOptions || {})
    $(ref.current).on('select2:select', function (e) {
        const data = e.params.data.id;
        console.log({ data, e, selected })
        onChange(data)
        setSelected(data)
    });
    return () => {};
  }, [])
  
  return (
    <select ref={ref}>
      <option value={""} ></option>
      {
        options.map(opt => {
          return <option key={opt.id} value={opt.id}>{opt.name}</option>
        })
      }
    </select>
  );
}

export default SingleSelect;