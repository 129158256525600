import React, { useContext, useState } from 'react';

import {
  Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';

import axios from 'axios';
import moment from 'moment';
import { axiosConfig } from '../../../../../helpers';
import { ProjectContext } from '../../ProjectMetrics';

const DialogCreateItem = ({ company: { _id: companyId }, onCreate }) => {
  const {
    config: { project_workSummary_endpoint: endpoint },
    project: { _id: { $oid: projectId } },
    date,
  } = useContext(ProjectContext);

  const [text, setText] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSend = async () => {
    setLoading(true);
    const createdAt = moment(date).format('YYYY-MM-DD');
    const uri = `${endpoint}/list/${projectId}/${companyId}/${createdAt}`;

    return axios.post(uri, { text }, axiosConfig())
      .then(() => {
        onCreate && onCreate();
        setOpen(false);
      })
      .finally(() => setLoading(false));
  };

  return (<>
    <IconButton
      className="m-r-2 border border-blue-grey"
      size="small"
      component="span"
      onClick={handleOpen}>
      <Add />
    </IconButton>

    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <div style={{ display: 'flex', width: "100%", justifyContent: 'space-between', alignItems: 'center' }}>
          <b>Add new work summary</b>
          <CloseIcon style={{ cursor: 'pointer', color: '#a7a7a7' }} onClick={handleClose} />
        </div>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>To add a new summary, please enter your message here.</DialogContentText>
        <TextField
          minRows={3}
          multiline={true}
          className={'w-100'}
          onChange={({ target }) => setText(target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleSend}>{loading ? <CircularProgress size={20} /> : 'Save'}</Button>
      </DialogActions>

    </Dialog>
  </>);
};

export default DialogCreateItem;
