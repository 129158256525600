import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  ThemeProvider,
  TextField,
  FormControl,
  MenuItem,
  FormControlLabel,
  Switch,
  Select,
  InputLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { myTheme } from "../helpers/index";
import axios from "axios";
import { axiosConfig } from "../helpers/index";
import PhoneField from './PhoneField';
import RevokeCode from './RevokeCode';
import RevokeDelete from './RevokeDelete';
import { formatPhoneNumber } from '../helpers/formatters';

const useStyles = makeStyles(theme => ({
  ml: {
    marginLeft: "10px"
  },
  mb: {
    marginBottom: "10px"
  }
}))

export default function (props) {

  const classes = useStyles();
  const { company, project, config } = props;
  const languages = [['en', 'English'], ['es', 'Español']];

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState({});
  const [saving, setSaving] = useState(false);
  const defaultState = {
    first_name: '',
    last_name: '',
    phone_number: '',
    phone_number_confirm: '',
    synthetic_phone: false,
    decal: '',
    language: languages[0][0],
    company_ids: [company._id.$oid],
    project_id: project._id.$oid,
    needs_safety: false,
    safety_process: '',
    worker_level: 'journeyman'
  }
  const [workerState, setWorkerState] = useState({ ...defaultState });

  const buildPayload = () => {
    const payload = { ...workerState }
    if (payload.synthetic_phone) {
      delete payload.phone_number;
      delete payload.phone_number_confirm;
    } else {
      delete payload.synthetic_phone;
      delete payload.phone_number_confirm;
    }
    if (!payload.needs_safety) {
      delete payload.safety_process;
    }

    return payload;
  }

  const handleOpen = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();

    setOpen(true);
    setSaving(false);
    setSuccess(false);

    setResponse({});
    setWorkerState({ ...defaultState });
  }

  const workerLineFormat = () => {
    return `${response.worker.first_name} ${response.worker.last_name} - ${formatPhoneNumber(response.worker.phone_number)}`
  }

  const handleClose = (ev) => {
    setOpen(false)
  }

  const handleChange = (ev) => {
    const name = ev.target.name;
    if (["synthetic_phone", "needs_safety"].indexOf(name) != -1) {
      workerState[name] = ev.target.checked;
    } else {
      workerState[name] = ev.target.value;
    }
    setWorkerState({ ...workerState })
  }

  const handleSave = () => {
    const url = config.onboarding_webhook_url;
    setSaving(true);
    axios.post(url, buildPayload(), axiosConfig()).then(response => {
      setSaving(false);
      setTimeout(() => {
        setResponse(response.data);
        setSuccess(true);
      });
      handleClose();
    }).catch(errorOnboardingWorker => {
      setSaving(false);
      console.error({ errorOnboardingWorker });
    })
  }

  const revokeFlag = "revoke";

  const isRevoke = (resp) => {
    return resp.flags.indexOf(revokeFlag) != -1;
  }

  const handleVerified = (record = null) => {
    const newResponse = { ...response };
    newResponse.flags = response.flags.filter(f => f != revokeFlag);
    setResponse(newResponse);
  }

  const incomplete = () => {
    const validPhoneSelection = workerState.synthetic_phone || (workerState.phone_number.length > 0 && (workerState.phone_number == workerState.phone_number_confirm))
    return workerState.first_name.length == 0 || workerState.last_name.length == 0 || !validPhoneSelection
  }

  return (
    <div className="btn btn-default m-r-1">
      <a className="" onClick={handleOpen}>
        <i className="px-nav-icon fa fa-user-plus"></i>
      </a>

      {success && (
        <ThemeProvider theme={myTheme}>
          <Dialog open={success} onClose={() => setSuccess(false)} >
            <DialogTitle>{workerLineFormat()}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {response.message}
                <br />
                {response.reason}
              </DialogContentText>
              {isRevoke(response) && (
                <form className="sb-inline-form">
                  <RevokeCode {...props} onVerify={handleVerified} record={{ phoneNumber: workerState.phone_number }} />
                </form>
              )}
              <div>
                <FormControlLabel
                  control={<Switch checked={workerState.needs_safety} onChange={handleChange} name="needs_safety" />}
                  label="Need Safety Process?"
                />
              </div>

              {workerState.needs_safety && (
                <FormControl component="fieldset">
                  <RadioGroup aria-label="safety process" name="safety_process" value={workerState.safety_process} onChange={handleChange}>
                    <FormControlLabel value="sms" control={<Radio />} label="Use SMS" />
                    <FormControlLabel value="form" control={<Radio />} label="Use Form" />
                  </RadioGroup>
                </FormControl>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleOpen} variant="outlined" color="primary">
                Next Worker
              </Button>
              <Button onClick={() => setSuccess(false)} variant="outlined" color="inherit">
                Close
              </Button>
            </DialogActions>
          </Dialog>

        </ThemeProvider>
      )}

      {open && (
        <ThemeProvider theme={myTheme}>
          <Dialog open={open} onClose={handleClose} >
            <DialogTitle>OnBoard New Worker on <strong>{company.name}</strong></DialogTitle>
            <DialogContent>
              <DialogContentText>

              </DialogContentText>
              <form>
                <div className={classes.mb}>
                  <TextField
                    type="text"
                    label="First Name"
                    name="first_name"
                    onChange={handleChange}
                    value={workerState.first_name} />

                  <TextField
                    type="text"
                    label="Last Name"
                    name="last_name"
                    onChange={handleChange}
                    value={workerState.last_name}
                    className={classes.ml} />
                </div>

                <div>
                  <FormControlLabel
                    control={<Switch checked={workerState.synthetic_phone} onChange={handleChange} name="synthetic_phone" />}
                    label="Autogenerate Phone Number"
                  />
                </div>
                {!workerState.synthetic_phone && (
                  <div className={classes.mb}>
                    <PhoneField
                      required={true}
                      label="Phone Number"
                      name="phone_number"
                      onChange={handleChange}
                      value={workerState.phone_number} />
                    <PhoneField
                      required={true}
                      label="Confirm Number"
                      name="phone_number_confirm"
                      onChange={handleChange}
                      value={workerState.phone_number_confirm} />
                  </div>

                )}
                <div className={classes.mb}>
                  <TextField
                    type="text"
                    label="Decal"
                    name="decal"
                    onChange={handleChange}
                    value={workerState.decal} />
                  <FormControl className={`${classes.formControl} ${classes.ml}`}>
                    <InputLabel id="onboard-language">Language</InputLabel>
                    <Select
                      labelId="onboard-language"
                      name="language"
                      value={workerState.language}
                      onChange={handleChange}
                    >
                      {languages.map((record, idx) => {
                        const [value, label] = record;
                        return (
                          <MenuItem key={idx} value={value}>{label}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </div>

              </form>
            </DialogContent>
            <DialogActions>
              <Button disabled={saving || incomplete()} onClick={handleSave} color="primary" variant="outlined">
                Add Worker
              </Button>
              <Button disabled={saving} onClick={handleClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      )}

    </div>
  );
}
