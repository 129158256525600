import React, { useLayoutEffect } from 'react';

const Select = ({ options, id, name, onChange }) => {
    
    const sref = React.createRef()
    
    useLayoutEffect(() => {
        console.log(sref)
        $(sref.current).on("change", ev => {
            console.log({ ev });
            
            onChange(ev.target.selectedOptions)
        })
        return () => { };
    }, [])
    
    return (
        <select ref={sref} name={name} id={id} className="select2" multiple={true}>
            {options.map(opt => {
                return <option key={opt.id} value={opt.id}>{opt.name}</option>
            })}
        </select>
    );
}

export default Select;
