import React, { useState } from 'react';
import { myTheme } from '../../../../helpers';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel, MenuItem,
  Radio,
  RadioGroup, Select,
  Switch,
  TextField,
  ThemeProvider,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get, has, isNull, isEmpty, set } from 'lodash';
import { Close, Cancel } from '@material-ui/icons';
import { statusColors } from '../../../constants/workerStatusColor'
import AddressAutocomplete from '../../../../addressAutocomplete/AddressAutocomplete'
import { updatingWorker, deleteWorkerImageProfile } from '../../../services/employeeService';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhoneField from '../../../../workers/PhoneField';
import { notificationCenter } from '../../../../helpers/notifications';

import enIcon from '../../../../../../assets/images/en-checked.svg';
import enIconOff from '../../../../../../assets/images/en-unchecked.svg';
import esIcon from '../../../../../../assets/images/es-checked.svg';
import esIconOff from '../../../../../../assets/images/es-unchecked.svg';

export default function EditWorker(props) {

  const { openEditWorker, onCloseEditWorker, worker, images_path, projectDomain, employee_settings_endpoint } = props;
  // console.log(employee_settings_endpoint);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%'
    },
    dialogTitle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: 'center',
      fontSize: '17px'
    },
    input: {
      margin: theme.spacing(1),
      width: '200px'
    },
    avatarLarge: {
      width: '150px',
      height: '150px',
      border: `3px solid ${get(statusColors, get(worker, 'status.activity', 0))}`
    },
    emergencyEmail: {
      margin: theme.spacing(1),
      width: '100%'
    },

    userType: {
      position: "absolute",
      right: 0,
      bottom: "10px",
      backgroundColor: "white",
      borderRadius: "14px"
    },
    flexColumnCenter: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    accordionDetails: {
      display: 'flex',
      justifyContent: 'center',
      padding: "8px 16px 16px"
    },
    workerLevel: {
      width: '200px',
      marginRight: '8px',
      marginTop: '12px'
    }
  }));

  const [editedWorker, setEditedWorker] = useState({
    ...worker
  });

  const [updateWorkerLoading, setUpdateWorkerLoading] = useState(false)
  const [isValid, setIsValid] = useState(true)


  const onAddressSelected = address => {
    setEditedWorker({
      ...editedWorker,
      onboarding: {
        ...editedWorker.onboarding,
        safety: {
          ...editedWorker.onboarding.safety,
          address
        }
      }
    })
  }

  const onHandleChangeInfo = (key, value) => {

    switch (key) {
      case 'phone_number':
        setIsValid(!isEmpty(value))
        break;
      case 'first_name':
        setIsValid(!isEmpty(value))
        break;
      case 'last_name':
        setIsValid(!isEmpty(value))
        break;
      default:
        break;
    }

    if (key === 'phone_number') value = value.replaceAll(/(\(|\)|\-)/g, "")

    console.log(key, value);

    setEditedWorker({
      ...editedWorker,
      [key]: value
    })
  }

  const onHandleChangeSafety = (key, value) => {
    setEditedWorker(prevState => ({
      ...prevState,
      onboarding: {
        ...prevState.onboarding,
        safety: {
          ...prevState.onboarding.safety,
          [key]: value
        }
      }
    }))

  }
  const onHandleChangeCarInfo = (key, value) => {
    setEditedWorker(prevState => ({
      ...prevState,
      onboarding: {
        ...prevState.onboarding,
        safety: {
          ...prevState.onboarding.safety,
          car_info: {
            ...prevState.onboarding.safety.car_info,
            [key]: value
          }
        }
      }
    }))

  }

  const onHandleChangeOnboarding = (key, value) => {
    setEditedWorker(prevState => ({
      ...prevState,
      onboarding: {
        ...prevState.onboarding,
        [key]: value
      }
    }))
  }

  const onUpdateWorker = async () => {
    try {
      setUpdateWorkerLoading(true);
      const res = await updatingWorker(editedWorker, projectDomain);
      setUpdateWorkerLoading(false);
      onCloseEditWorker(true);
    } catch (error) {
      notificationCenter('error', get(error, 'response.data.error', 'Error'));
      setTimeout(() => {
        onCloseEditWorker(true);
      }, 2000)
    }
  }

  const isDisabled = () => !get(worker, 'permissions.canEditProfile');

  const onClearImageProfile = async () => {
    try {

      setEditedWorker({
        ...editedWorker,
        image_path: null
      })

      const res = await deleteWorkerImageProfile(employee_settings_endpoint, get(editedWorker, 'dbid'))

    } catch (error) {

    }
  }

  const classes = useStyles();

  return (
    <ThemeProvider theme={myTheme}>
      <Dialog maxWidth='lg' open={openEditWorker} onClose={() => onCloseEditWorker(false)} >
        <DialogTitle disableTypography={true} classes={{ root: classes.dialogTitle }} >
          <span>Editing Worker Information</span>
          <Close style={{ cursor: 'pointer' }} size='20' color='primary' onClick={() => onCloseEditWorker(false)} />
        </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <div className='worker-info' >
              <div className='left-worker-info' style={{ display: 'flex' }}>
                <div className={classes.flexColumnCenter} style={{ marginRight: '50px' }}>
                  <div style={{ position: 'relative' }}>
                    <div style={{ position: 'relative' }}>
                      <Avatar atl='Worker' src={has(editedWorker, 'image_path') && !isNull(get(editedWorker, 'image_path')) ? `${images_path}${get(editedWorker, 'image_path')}` : 'https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/project-employees/no-profile-image.png'} classes={{ root: classes.avatarLarge }} />
                      {has(editedWorker, 'image_path') && !isNull(get(editedWorker, 'image_path')) ? <img onClick={() => onClearImageProfile()} src="https://s3.us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/project_workers/closedIcon.svg" style={{ position: 'absolute', right: '6%', top: '3%', width: '30px', color: '#f16734', cursor: 'pointer' }} /> : null}

                    </div>
                    {isNull(get(worker, 'user_type')) ? null : <img className={classes.userType} src={`https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/project_workers/${get(worker, 'user_type', 'worker')}.svg`} width='30' />}
                  </div>
                  <div style={{ marginTop: '20px' }} className={classes.flexColumnCenter}>
                    {has(worker, 'last_seen') ? <span>Last Seen: <strong>{get(worker, 'last_seen')}</strong></span> : null}
                    <span style={{ color: '#777777' }}>DBID: {get(worker, 'dbid')}</span>

                    <FormControlLabel
                      control={
                        <Switch
                          checked={get(editedWorker, 'hasAccess', true)}
                          onChange={(e) => onHandleChangeInfo('hasAccess', e.target.checked)}
                          color="primary"
                          name="hasAccess"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      }
                      labelPlacement="start"
                      label="Access"
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >
                  <div>
                    <TextField
                      InputProps={{
                        readOnly: isDisabled(),
                      }}
                      onChange={(e) => onHandleChangeInfo('first_name', e.target.value)}
                      className={classes.input}
                      label="First Name"
                      required
                      value={get(editedWorker, 'first_name')} />
                    <TextField
                      onChange={(e) => onHandleChangeInfo('last_name', e.target.value)}
                      className={classes.input}
                      InputProps={{
                        readOnly: isDisabled(),
                      }}
                      label="Last Name"
                      required
                      value={get(editedWorker, 'last_name')} />
                  </div>
                  <div>
                    <PhoneField
                      readOnly={isDisabled()}
                      required={true}
                      label="Phone Number"
                      name="phone_number"
                      onChange={e => onHandleChangeInfo('phone_number', e.target.value)}
                      className={classes.input}
                      value={get(editedWorker, 'phone_number')} />
                    <TextField
                      onChange={(e) => onHandleChangeInfo('email', e.target.value)}
                      className={classes.input}
                      InputProps={{
                        readOnly: isDisabled(),
                      }}
                      label="Email"
                      value={get(editedWorker, 'email')} />
                  </div>
                  {get(worker, 'onboarded') ? <div style={{ marginLeft: "9px", marginTop: "10px", display: "flex", justifyContent: "space-between" }}>

                    <FormControl disabled={isDisabled()}>
                      <FormLabel>Select language</FormLabel>

                      <RadioGroup
                        name="language_code"

                        row
                        value={get(editedWorker, 'onboarding.language_code', 'en')}
                        onChange={(e) => onHandleChangeOnboarding('language_code', e.target.value)}
                      >
                        <FormControlLabel
                          value="en"
                          control={
                            <Radio
                              icon={<img src={enIconOff} width='35' />}
                              checkedIcon={<img src={enIcon} width='35' />}
                              checked={get(editedWorker, 'onboarding.language_code', 'en') === 'en'} />
                          }
                        />
                        <FormControlLabel
                          value="es"
                          control={
                            <Radio
                              icon={<img src={esIconOff} width='35' />}
                              checkedIcon={<img src={esIcon} width='35' />}
                              checked={get(editedWorker, 'onboarding.language_code', 'en') === 'es'} />
                          }
                        />
                      </RadioGroup>


                    </FormControl>
                    <FormControl className={classes.workerLevel}>
                      <InputLabel>Worker Level</InputLabel>
                      <Select
                        value={get(editedWorker, 'onboarding.worker_level')}
                        onChange={(e) => onHandleChangeOnboarding("worker_level", e.target.value)}
                      >
                        <MenuItem key='journeyman' value='journeyman'>Journeyman</MenuItem>
                        <MenuItem key='foreman' value='foreman'>Foreman</MenuItem>
                        <MenuItem key='apprentice' value='apprentice'>Apprentice</MenuItem>
                        <MenuItem key='other' value='other'>Other</MenuItem>
                      </Select>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >
                        <div>
                          <TextField
                            type='number'
                            onChange={(e) => onHandleChangeOnboarding('decal', e.target.value)}
                            style={{ width: '200px' }}
                            // className={classes.input}
                            label="Decal"
                            value={get(editedWorker, 'onboarding.decal')} />
                        </div>
                      </div>
                    </FormControl>


                  </div> : null}

                </div>
              </div>
            </div>
            {get(worker, 'onboarded') ?
              <>
                <hr style={{ marginBottom: '10px' }} />
                <div className='address-worker-info'>
                  <AddressAutocomplete onAddressSelected={onAddressSelected} currentAddress={get(editedWorker, 'onboarding.safety.address', '')} />
                </div>
                <div className='emergency-worker-info' style={{ marginTop: '10px' }}>
                  <TextField
                    onChange={(e) => onHandleChangeSafety('other_info', e.target.value)}
                    rows={4}
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Comments..."
                    multiline
                    value={get(editedWorker, 'onboarding.safety.other_info', '')} />
                </div>
                <div style={{ marginTop: '20px' }}>
                  <Accordion className={classes.emergencyContact}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>Emergency Contact</Typography>
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.accordionDetails }}>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div>
                          <TextField onChange={(e) => onHandleChangeSafety('emergency_name', e.target.value)} className={classes.input} id="outlined-basic" label="Emergency Name" value={get(editedWorker, 'onboarding.safety.emergency_name', '')} />
                          <TextField onChange={(e) => onHandleChangeSafety('emergency_phone', e.target.value)} className={classes.input} id="outlined-basic" label="Emergency Phone" value={get(editedWorker, 'onboarding.safety.emergency_phone', '')} />
                        </div>
                        <div >
                          <TextField onChange={(e) => onHandleChangeSafety('emergency_email', e.target.value)} className={classes.emergencyEmail} id="outlined-basic" label="Emergency Email" value={get(editedWorker, 'onboarding.safety.emergency_email', '')} />
                        </div>
                        <div>
                          <TextField onChange={(e) => onHandleChangeCarInfo('car_make', e.target.value)} className={classes.input} id="outlined-basic" label="Car Make" value={get(editedWorker, 'onboarding.safety.car_info.car_make', '')} />
                          <TextField onChange={(e) => onHandleChangeCarInfo('car_model', e.target.value)} className={classes.input} id="outlined-basic" label="Car Model" value={get(editedWorker, 'onboarding.safety.car_info.car_model')} />
                        </div>
                        <div>
                          <TextField onChange={(e) => onHandleChangeCarInfo('car_color', e.target.value)} className={classes.input} id="outlined-basic" label="Car Color" value={get(editedWorker, 'onboarding.safety.car_info.car_color', '')} />
                          <TextField onChange={(e) => onHandleChangeCarInfo('car_tag', e.target.value)} className={classes.input} id="outlined-basic" label="Car Tag" value={get(editedWorker, 'onboarding.safety.car_info.car_tag')} />
                        </div>
                      </div>

                    </AccordionDetails>
                  </Accordion>
                </div>
              </>
              : null
            }

          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" disabled={updateWorkerLoading || !isValid} onClick={() => onUpdateWorker()}>
            {updateWorkerLoading && <CircularProgress color='primary' size={10} style={{ marginRight: '5px' }} />}  Update
          </Button>
          <Button onClick={() => onCloseEditWorker(false)} color="inherit">
            Cancel
            </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>

  )
};
