import React, { useContext, useEffect, useState } from 'react';
import { BroadcastMessageWizard, MessageTableCell } from './components/index';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TableFooter, TablePagination, TableRow } from '@material-ui/core';

import Widget from '../../../../jumbo-components/Widget';
import { axiosConfig } from '../../../helpers';
import Axios from 'axios';
import { SettingsContext } from '../../contexts/SettingsContext';


const BroadcastMessages = (props) => {

    const { broadcastEndpoint, project, companies, workersEndpoint } = props

    const [openWizard, setOpenWizard] = useState(false);
    const [messages, setMessages] = useState([])
    const [page, setPage] = useState(0)


    const settingContext = useContext(SettingsContext);

    const formattingDate = settingContext.onGetTimeSheetDateFormat;

    const handleChangePage = (ev, newPage) => {
        if (messages.length < 5 && newPage > page) {
            return;
        }
        setPage(newPage)
    }


    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        labels: {
            whiteSpace: "nowrap"
        },
        chip: {
            margin: 2,
        },
        td: {
            verticalAlign: "middle !important"
        },
        container: {
            padding: 24
        },
        buttonWizard: {
            borderRadius: '4px'
        },
        paginationCaption: {
            marginBotton: '0px'
        }
    }));

    const classes = useStyles();

    const loadMessages = () => {
        const url = `${broadcastEndpoint}/list/${project._id.$oid}/${formattingDate()}`; //?count=5&page=${page}
        Axios.get(url, axiosConfig()).then(response => {
            setMessages(response.data)
        }).catch(loadBroadcastError => console.error({ loadBroadcastError }))
    }

    useEffect(() => {
        loadMessages()
        return () => { };
    }, [page]);

    const showWizard = () => {
        setOpenWizard(true)
    }
    const hideWizard = () => {
        // loadMessages()
        setOpenWizard(false)
        setPage(0)
    }

    const messagesTable = () => (

        <>
            <div className="jr-card-header d-flex">
                <h1 className="mb-0 card-heading jr-font-weight-bold">Most Recent Broadcasted Messages</h1>
                <div className="ml-auto">
                    <Button color="primary" size="medium" variant="contained" onClick={showWizard} style={{ borderRadius: '4px' }}>
                        <i className="fas fa-plus-square"></i>
                    </Button>
                    {/* <span className="text-white badge badge-success">Most Recent {messages.length}</span> */}
                </div>
            </div>


            <div className="table-responsive-material markt-table">
                <table className="table default-table table-sm full-table remove-table-border table-hover mb-0">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Sender</th>
                            {/* <th>Company</th> */}
                            <th>Datetime</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {messages.map(data => {
                            return (
                                <MessageTableCell key={data._id} data={data} classes={classes} />
                            );
                        })}
                    </tbody>

                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                classes={{ caption: classes.paginationCaption }}
                                count={-1}
                                rowsPerPage={5}
                                rowsPerPageOptions={[5]}
                                page={page}
                                onChangePage={handleChangePage}
                            />
                        </TableRow>
                    </TableFooter>
                </table>
            </div>
        </>
    )

    let jsx = ""
    // // project, companies, classes, onDone, broadcastEndpoint
    if (openWizard) {
        jsx = <BroadcastMessageWizard classes={classes} project={project} companies={companies} broadcastEndpoint={broadcastEndpoint} onDone={hideWizard} workersEndpoint={workersEndpoint
        } />
    } else {
        jsx = messagesTable()
    }

    return (
        <div id="jumbo-styles-container">
            <Widget styleName="jr-card">
                {jsx}
            </Widget>
        </div>
    )

}

export default BroadcastMessages;
