import React, { useState, useEffect } from 'react';
import { Grid, FormControl, MenuItem, InputLabel, makeStyles, Select, TextField, FormControlLabel, Radio, FormLabel, RadioGroup, Button, CircularProgress, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import AddressAutocomplete from '../../../../addressAutocomplete/AddressAutocomplete';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { editWorkerCompany, getWorkerDetails } from '../../../services/employeeService';
import { WorkerInfoSection} from '../index';
import { notificationCenter } from '../../../../helpers/notifications';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SBSwitch } from '../../../../reusableComponents';

import enIcon from '../../../../../../assets/images/en-checked.svg';
import enIconOff from '../../../../../../assets/images/en-unchecked.svg';
import esIcon from '../../../../../../assets/images/es-checked.svg';
import esIconOff from '../../../../../../assets/images/es-unchecked.svg';

const useStyles = makeStyles((theme) => ({
  mainContent: {
    backgroundColor: '#f3f3f4',
    height: "100%",
    width: "100%",
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  workerForm: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  avatar: {
    width: '100px',
    height: '100px',
    marginRight: '10%'
  },
  workerBasicInfoTop: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderRadius: '5px',
    // backgroundColor: 'white',
    justifyContent: 'center'
  },
  workerInfo: {
    display: 'flex',
    flexDirection: 'column'
  },
  inputStyle: {
    width: '250px'
  },
  inputDiv: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  accordion: {
    backgroundColor: '#f3f3f4'
  },
  formControlLabelPicker: {
    width: '100%',
    marginTop: '0px !important',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  requiredFields: {
    fontSize: '12px',
    color: "red"
  },
  drawerActionButtonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    alignItems: 'center'
  },
  loaderWrapper: {
    zIndex: 999999,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    margin: -20,
    backgroundColor: 'rgba(255,255,255,0.6)'
  }
}));

export default function EditCompanyWorker(props) {

  const { currentEmployee, worker, companies, companyWorkersDomain, onEditClose } = props;

  const [isValid, setIsValid] = useState(true)
  const [loading, setLoading] = useState(true)
  const [showMessage, setShowMessage] = useState({
    status: false,
    message: ""
  });

  const [state, setState] = useState({
    // currentCompany: company,
    showHireRate: false,
    editing: false,
    workerEdited: {
      ...worker,
      confirm_phone_number: get(worker, 'phone_number'),
      mobileAppSettings: {
        enableMobileApp: get(worker, 'mobileAppSettings.enableMobileApp', true),
        kioskModeEnabled: get(worker, 'mobileAppSettings.kioskModeEnabled', false),
        checkWorkerLogged: get(worker, 'mobileAppSettings.checkWorkerLogged', false),
        disableGeofence: get(worker, 'mobileAppSettings.disableGeofence', false)
      }
    }
  });

  const gettingWorkerDetails = async () => {
    try {
      setLoading(true)

      const res = await getWorkerDetails(companyWorkersDomain, get(worker, '_id'));

      setLoading(false);
      setState(prevState => ({
        ...prevState,
        currentCompany: get(companies.filter(oneCompany => get(oneCompany, 'value') === get(res, 'data.company._id')), 0, {}),
        workerEdited: {
          ...prevState.workerEdited,
          ...get(res, 'data')
        }
      }));

    } catch (error) {
      notificationCenter('error', "Error loading worker profile.");
      onEditClose(true, state.workerEdited);
    }
  }

  useEffect(() => {
    gettingWorkerDetails();
  }, []);


  const onChangeBasic = (key, value) => {

    switch (key) {
      case 'phone_number':
        setIsValid(!isEmpty(value))
        break;
      case 'first_name':
        setIsValid(!isEmpty(value))
        break;
      case 'last_name':
        setIsValid(!isEmpty(value))
        break;
      case 'confirm_phone_number':
        setIsValid(!isEmpty(value) && state.workerEdited['phone_number'] === value)
        break;
      default:
        break;
    }

    setState(prevState => ({
      ...prevState,
      workerEdited: {
        ...prevState.workerEdited,
        [key]: value
      }
    }))
  }

  const handleActiveToggle = (event) => {
    setState(prevState => ({
      ...prevState,
      workerEdited: {
        ...prevState.workerEdited,
        active: event.target.checked
      }
    }))
  }

  const handleUseSalaryToggle = (event) => {
    setState(prevState => ({
      ...prevState,
      workerEdited: {
        ...prevState.workerEdited,
        use_salary: event.target.checked
      }
    }))
  }

  const onAddressSelected = address => {
    setState(prevState => ({
      ...prevState,
      workerEdited: {
        ...prevState.workerEdited,
        address
      }
    }))
  }

  const onChangeDOB = (e) => {
    if (moment().diff(moment(e).format('MM/DD/YYYY'), 'years') >= 16) {
      onChangeBasic('dob', e)
    } else {
      notificationCenter('warning', "Age should be more than 16 years.Please enter a valid Date of Birth")
    }

  }

  const onEdit = async () => {

    try {

      if (!isValid) {
        setShowMessage({
          status: true,
          message: "Some fields are required"
        })
      } else {
        setShowMessage({
          status: false,
          message: "Some fields are required"
        });
        setState(prevState => ({
          ...prevState,
          editing: true
        }));

        setIsValid(false);
        const res = await editWorkerCompany(companyWorkersDomain, state.workerEdited);

        setState(prevState => ({
          ...prevState,
          editing: false
        }))
        onEditClose(false, res.data);
      }

    } catch (error) {
      onEditClose(true, state.workerEdited);
    }

  }

  const handleToggle = (event) => {
    setState(prevState => ({
      ...prevState,
      workerEdited: {
        ...prevState.workerEdited,
        mobileAppSettings: {
          ...prevState.workerEdited.mobileAppSettings,
          [event.target.name]: event.target.checked
        }
      }
    }))
  }

  const classes = useStyles();

  const disableSelfProjectToggle = get(state, 'workerEdited.user_type') === 'admin' || !get(state, 'workerEdited.user_type');

  return (
    <div className={classes.mainContent}>
      { loading &&
        <div className={classes.loaderWrapper}>
          <CircularProgress />
        </div>
      }
      <WorkerInfoSection {...props} company={state.currentCompany} onChangeBasic={onChangeBasic} />
      <hr />
      <div className={classes.workerForm}>
        <div className="worker-form-information">
          <h3 style={{ marginTop: '0px', marginBottom: '5px' }}>Worker Information {get(showMessage, 'status') ? <i className={classes.requiredFields}>{get(showMessage, 'message')}</i> : ''}</h3>
          <div className=''>
            <div className={classes.inputDiv}>
              <TextField id="firstName" required onChange={(e) => onChangeBasic('first_name', e.target.value)} value={get(state, 'workerEdited.first_name')} label="First Name" className={classes.inputStyle} />
              <TextField id="lastName" required onChange={(e) => onChangeBasic('last_name', e.target.value)} value={get(state, 'workerEdited.last_name')} label="Last Name" className={classes.inputStyle} />
            </div>
            <div className={classes.inputDiv}>
              <TextField id="phoneNumber" required label="Phone Number" onChange={(e) => onChangeBasic('phone_number', e.target.value)} value={get(state, 'workerEdited.phone_number')} className={classes.inputStyle} />
              <TextField id="confirmPhoneNumber" required onChange={(e) => onChangeBasic('confirm_phone_number', e.target.value)} value={get(state, 'workerEdited.confirm_phone_number')} label="Confirm Phone Number" className={classes.inputStyle} />
            </div>
            <div className={classes.inputDiv}>
              <TextField id="email" onChange={(e) => onChangeBasic('email', e.target.value)} value={get(state, 'workerEdited.email')} label="Email" className={classes.inputStyle} />
            </div>
            {<div className={classes.inputDiv}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Department</InputLabel>
                <Select
                  className={classes.inputStyle}
                  displayEmpty={true}
                  id="demo-simple-select"
                  value={get(state, 'workerEdited.department_id', '')}
                  onChange={(e) => onChangeBasic('department_id', e.target.value)}
                >
                  <MenuItem style={{ height: '30px' }} key={null} value=""></MenuItem>
                  {get(state, 'currentCompany.departments', []).map(department => <MenuItem key={get(department, 'value')} value={get(department, 'value')}>{get(department, 'label')}</MenuItem>)}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Position</InputLabel>
                <Select
                  displayEmpty={true}
                  className={classes.inputStyle}
                  id="demo-simple-select"
                  value={get(state, 'workerEdited.company_position_id', '')}
                  onChange={(e) => onChangeBasic('company_position_id', e.target.value)}
                >
                  <MenuItem style={{ height: '30px' }} key={null} value=""></MenuItem>
                  {get(state, 'currentCompany.positions', []).map(position => <MenuItem key={get(position, 'value')} value={get(position, 'value')}>{get(position, 'label')}</MenuItem>)}

                </Select>
              </FormControl>

            </div>}
            <Grid container style={{ paddingTop: 16 }}>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel>Select language</FormLabel>

                  <RadioGroup
                    name="language_code"
                    row
                    value={get(state, 'workerEdited.language_code', 'en')}
                    onChange={(e) => onChangeBasic('language_code', e.target.value)}
                    style={{ paddingLeft: 4, minWidth: 140 }}>
                    <FormControlLabel
                      value="en"
                      control={
                        <Radio
                          icon={<img src={enIconOff} width='40' />}
                          checkedIcon={<img src={enIcon} width='40' />}
                          checked={get(state, 'workerEdited.language_code', 'en') === 'en'} />
                      }
                    />
                    <FormControlLabel
                      value="es"
                      control={
                        <Radio
                          icon={<img src={esIconOff} width='40' />}
                          checkedIcon={<img src={esIcon} width='40' />}
                          checked={get(state, 'workerEdited.language_code') === 'es'} />
                      }
                    />

                  </RadioGroup>

                </FormControl>
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: 20 }}>
                <FormControl style={{ display: 'flex', flexDirection: 'column' }} fullWidth>
                  <FormControlLabel
                    disabled={get(state, 'workerEdited._id') === get(currentEmployee, '_id.$oid')}
                    control={<SBSwitch color="primary" handleToggle={handleActiveToggle} checked={get(state, 'workerEdited.active', false)} />}
                    label="Active"
                    />
                  <FormControlLabel
                    control={<SBSwitch color="primary" handleToggle={handleUseSalaryToggle} checked={get(state, 'workerEdited.use_salary', false)} />}
                    label="Use Salary"
                    />
                </FormControl>
              </Grid>
            </Grid>
            <div>
              <AddressAutocomplete onAddressSelected={onAddressSelected} currentAddress={get(state, 'workerEdited.address')} />
            </div>
            <div >
              <FormControl style={{ width: '100%' }} classes={{ root: classes.formControlPicker }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <KeyboardDatePicker
                      disableFuture={true}
                      views={["year", "month", "date"]}
                      className={classes.formControlLabelPicker}
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Date of Birth"
                      value={get(state, 'workerEdited.dob', null)}
                      onChange={(e) => onChangeDOB(e)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </FormControl>
              {/* <TextField id="id_number" onChange={(e) => onChangeBasic('id_number', e.target.value)} value={get(state, 'workerEdited.id_number')} label="ID Number" className={classes.inputStyle} /> */}
            </div>

            <div style={{ marginTop: '20px', marginBottom:'20px' }}>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  Emergency Contact Information
                </AccordionSummary>
                <AccordionDetails>

                  <div style={{ width: '100%' }}>
                    <div className={classes.inputDiv}>
                      <TextField id="emergency_name" onChange={(e) => onChangeBasic('emergency_name', e.target.value)} value={get(state, 'workerEdited.emergency_name', '')} label="Full Name" className={classes.inputStyle} />


                      <TextField id="emergency_phone_number" onChange={(e) => onChangeBasic('emergency_phone_number', e.target.value)} value={get(state, 'workerEdited.emergency_phone_number', '')} label="Phone Number" className={classes.inputStyle} />
                    </div>
                    <div className={classes.inputDiv}>
                      <TextField id="emergency_email" onChange={(e) => onChangeBasic('emergency_email', e.target.value)} value={get(state, 'workerEdited.emergency_email', '')} label="Email" className={classes.inputStyle} />

                      <TextField id="emergency_relation" onChange={(e) => onChangeBasic('emergency_relation', e.target.value)} value={get(state, 'workerEdited.emergency_relation', '')} label="Relationship" className={classes.inputStyle} />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  Mobile App Settings
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ display: "flex", flexDirection: 'column' }}>
                    <FormControlLabel
                      control={<SBSwitch color="primary" checked={get(state, 'workerEdited.mobileAppSettings.enableMobileApp', true)} handleToggle={handleToggle} name='enableMobileApp' />}
                      label='Enable mobile app'
                    />
                    <FormControlLabel
                      control={<SBSwitch color="primary" checked={get(state, 'workerEdited.mobileAppSettings.kioskModeEnabled', false)} handleToggle={handleToggle} name='kioskModeEnabled' />}
                      label='Enable kiosk mode'
                    />
                    <FormControlLabel
                      control={<SBSwitch color="primary" checked={get(state, 'workerEdited.mobileAppSettings.disableGeofence', false)} handleToggle={handleToggle} name='disableGeofence' />}
                      label='Disable geofence'
                    />
                    <FormControlLabel
                      control={<SBSwitch color="primary" checked={get(state, 'workerEdited.mobileAppSettings.selfProjectsOnly', false)} handleToggle={handleToggle} name='selfProjectsOnly' disabled={disableSelfProjectToggle} />}
                      label='Restrict to user projects'
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
        <div className={classes.drawerActionButtonsWrapper}>
          <Button
            variant="outlined"
            onClick={() => onEditClose(false, null)}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            disabled={!isValid}
            style={{ marginLeft: 5 }}
            onClick={onEdit}>
            Save
          </Button>
        </div>
      </div>
    </div>
  )
};
