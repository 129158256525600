import React, { useEffect, useState } from 'react'
import { CircularProgress, Drawer } from '@material-ui/core'
import { get, has, isNull } from 'lodash'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { myTheme } from '../../helpers'
import SettingsContextProvider from '../contexts/SettingsContext'
import NotificationContainer from 'react-notifications/lib/NotificationContainer'
import {
  BulkUploadWorkers,
  CompanyTableWorkers,
  CreateWorker,
  EditCompanyWorker,
  EditCompanyWorkerSettingsAndPermissions,
  HeaderCompanySearch,
  SetHireRate
} from './components/index'
import { editWorkerRate, getProjectByCompany, gettingCompanyEmployees, gettingCompanyEmployeeSettings } from '../services/employeeService'
import { allColumns, defaultColumns } from '../constants/companyWorkersColumns'
import { notificationCenter } from '../../helpers/notifications'
import { useMediaQuery } from 'react-responsive'
import CompanySettings from './components/companySettings/companySettings'

let client = null
const W3CWebSocket = require('websocket').w3cwebsocket;

const drawerSize = (deskTopSize = '30%') => {

  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 480 });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 768 });
  const isLaptop = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1025 });

  switch (true) {
    case isMobile:
      return '100%'
    case isTablet:
      return '90%'
    case isLaptop:
      return '75%';
    case isDesktop:
      return deskTopSize;
    default:
      return "80%";
  }

}

export default function CompanyEmployees(props) {

  const useStyles = makeStyles((theme) => ({
    dialogLoading: {
      position: "absolute",
      width: "100%",
      height: "100%",
      display: "flex",
      backgroundColor: '#ffffffa6',
      zIndex: '9999999',
      justifyContent: "center",
      alignItems: "center"
    },
    drawerRoot: {
      width: drawerSize('40%')
    },
    bulkUploadDrawer: {
      width: drawerSize('30%')
    },
    permissionsDrawer: {
      overflowX: 'hidden',
      width: 470
    }

  }));

  const { company, currentEmployee, config } = props;

  const companyWorkersDomain = get(config, 'companyWorkersDomain');
  const employee_settings_endpoint = get(config, 'employee_settings_endpoint');
  const companySettingsEndpoint = get(config, 'companySettingsEndpoint');
  const projectEndpoint = get(config, 'projectEndpoint');
  const promoteWorkerEndpoint = get(config, 'promoteWorkerEndpoint');
  const bulkUploadWorkersWebsocketUrl = get(config, 'bulkUploadWorkersWebsocketUrl');
  const fileUploadEndpoint = get(config, 'fileUploadEndpoint');
  const sessionResultsEndpoint = get(config, 'sessionResultsEndpoint');
  const payment_profile_endpoint = get(config, 'payment_profile_endpoint');
  const classes = useStyles();

  const [drawerDepartmentsPositions, setDrawerDepartmentsPositions] = useState(false)
  const [state, setState] = useState({
    loading: false,
    currentCompany: {
      value: get(company, '_id.$oid'),
      label: get(company, 'name')
    },
    showHireRate: {
      status: false,
      worker: null
    },
    updateCompanyWorkers: false,
    companies: [],
    filters: [],
    permissions: {},
    editWorker: {
      status: false,
      worker: null
    },
    createWorkerStatus: false,
    bulkUploadWorkersStatus: false,
    projectCompanies: [],
    websocketOpen: false,
    downloadButtonText: 'Download'
  });

  const [columns, setColumns] = useState(allColumns);
  const [showedColumns, setShowedColumns] = useState(defaultColumns);

  const onCompanyFilterChange = value => {
    setState(prevState => ({
      ...prevState,
      currentCompany: value
    }))
  }

  const onLoading = (flag = true) => {
    setState(prevState => ({
      ...prevState,
      updateCompanyWorkers: false,
      loading: flag
    }));
  }

  const getSettings = async () => {
    try {
      onLoading()
      const res = await gettingCompanyEmployeeSettings(companyWorkersDomain);
      setState(prevState => ({
        ...prevState,
        filters: get(res, 'data.filters'),
        companies: get(res, 'data.companies'),
        currentCompany: get(res, 'data.companies').find(item => get(item, 'value') === get(company, '_id.$oid')),
        permissions: get(res, 'data.permissions'),
        userTypes: get(res, 'data.userTypes')
      }))

      if (!get(res, 'data.permissions.canSeeMoney', false)) {
        setColumns(allColumns.filter(item => get(item, 'id') !== 'rate'))
        setShowedColumns(defaultColumns.filter(item => get(item, 'id') !== 'rate'))
      }

      onLoading(false)

    } catch (error) {
      onLoading(false)
      notificationCenter('error', _.get(error.response, 'data.message'));
    }
  }

  const openWebSocketConnection = (payload) => {

    client = new W3CWebSocket(`${bulkUploadWorkersWebsocketUrl}?userId=${get(currentEmployee, '_id.$oid')}&companyId=${get(company, 'value')}&token=${document.querySelector("input[name='aws_token']").value}`);
    // client = new W3CWebSocket(`${bulkUploadWorkersWebsocketUrl}?userId=${get(currentEmployee, '_id.$oid')}&companyId=${get(company, 'value')}&token=4fc9ab24b5555c8b4a99008da711ebdd6ebfbdb6727a29e12f1ac54c1820718ed48adea1d3525fb28eee0bc5d29d3225406c23bcff73c4f82b74993ac0c1e635`);

    client.onerror = (e) => {
      // console.log('websocket error: ', e);
      notificationCenter('error', "Websocket error.");

      setState(prevState => ({
        ...prevState,
        downloadButtonText: 'Download'
      }))
    }

    client.onopen = (e) => {
      // notificationCenter('success', "Websocket connected.");
      // console.log("OPEN WEBSOCKET", e);

      setState(prevState => ({
        ...prevState,
        downloadButtonText: 'Requesting'
      }))
      // NOTE: here we can have switch for different download requests
      requestDownload(payload);
    };

    client.onclose = (e) => {
      // console.log("fONCLOSE WEBSOCKET", e);
      client = null;
      // notificationCenter('success', 'Websocket connection closed.')
    };

    client.onmessage = (e) => {
      const res = JSON.parse(get(e, "data"));

      switch (get(res, 'event')) {

        case 'ExportWorkers':

          notificationCenter('success', 'File ready for download.')

          // download logic
          var element = document.createElement('a');
          element.setAttribute('href', res.data.downloadUrl);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);

          // close client and reset button text
          client.close()

          setState(prevState => ({
            ...prevState,
            downloadButtonText: 'Download'
          }))

          break;

        case 'ExportWorkersError':

          // notificationCenter('error', 'Error generating file.')
          notificationCenter('error', res.data.error)

          // close client and reset button text
          client.close()

          setState(prevState => ({
            ...prevState,
            downloadButtonText: 'Download'
          }))

          break;

        default:
          break;
      }
    };
  };

  const requestDownload = async (payload) => {
    try {
      const res = await gettingCompanyEmployees(payload, get(state.currentCompany, 'value'), companyWorkersDomain);
      // console.log('res: ', res);
      setState(prevState => ({
        ...prevState,
        downloadButtonText: 'Processing'
      }))
    } catch (error) {
      // console.log('request download error: ', error);
      setState(prevState => ({
        ...prevState,
        downloadButtonText: 'Download'
      }))

      notificationCenter('error', _.get(error.response, 'data.error', 'Error requesting download.'));
      client.close();
    }
  }

  const onDownloadData = (payload) => {
    setState(prevState => ({
      ...prevState,
      downloadButtonText: 'Connecting'
    }))

    openWebSocketConnection(payload)
  }

  const gettingProjects = async () => {
    try {
      const res = await getProjectByCompany(projectEndpoint, get(state.currentCompany, 'value'));
      setState(prevState => ({
        ...prevState,
        projectCompanies: get(res, 'data', [])
      }))
    } catch (error) {
      notificationCenter('error', get(error.response, 'data.error'));
    }
  }

  useEffect(() => {
    getSettings()
  }, [])

  useEffect(() => {
    gettingProjects();
  }, [state.currentCompany])

  const onEditClose = (hasError, worker) => {

    setState(prevState => ({
      ...prevState,
      editWorker: {
        worker: null,
        status: false,
        settingsAndPermissionsStatus: false
      },
      showHireRate: {
        status: false,
        worker: null
      }
    }))

    if (!hasError && !isNull(worker) && worker !== 'backdropClick') {
      notificationCenter(has(worker, 'warning') ? 'warning' : 'success', has(worker, 'warning') ? get(worker, 'reason') : get(worker, 'message'));
      setTimeout(() => {
        setState(prevState => ({
          ...prevState,
          updateCompanyWorkers: true
        }))
      }, 2000)

    } else if (hasError && !isNull(worker) && worker !== 'backdropClick') {
      notificationCenter('error', `The employee ${get(worker, 'first_name')} ${get(worker, 'last_name')} wasn't updated`)
    }
    setState(prevState => ({
      ...prevState,
      updateCompanyWorkers: false
    }))

  }

  const onEdit = (worker, editSettingsAndPermissions) => {

    setState(prevState => ({
      ...prevState,
      editWorker: {
        worker,
        status: editSettingsAndPermissions ? false : true,
        settingsAndPermissionsStatus: editSettingsAndPermissions ? true : false
      }
    }))
  }

  const onShowHireRate = (worker) => {
    setState(prevState => ({
      ...prevState,
      showHireRate: {
        status: true,
        worker: isNull(prevState.showHireRate.worker) ? worker : prevState.showHireRate.worker
      }
    }));
  }

  const onCloseHireRate = () => {
    setState(prevState => ({
      ...prevState,
      showHireRate: {
        status: false,
        worker: null
      }
    }));
  }

  const onEditRate = async (data, workerId) => {
    onLoading(true);

    try {
      const res = await editWorkerRate(companyWorkersDomain, { ...data }, workerId);

      setState(prevState => ({
        ...prevState,
        updateCompanyWorkers: true,
        showHireRate: {
          status: false,
          worker: null
        }
      }))
    } catch (error) {
      onLoading(false)
      notificationCenter('error', get(error.response, 'data.error'));
    }
  }

  const onCreateWorker = (status = true, worker = null) => {
    setState(prevState => ({
      ...prevState,
      createWorkerStatus: status,
      editWorker: {
        worker,
        status: !isNull(worker)
      }
    }))
  }

  const onBulkUploadWorkers = (status = true) => {
    setState(prevState => ({
      ...prevState,
      bulkUploadWorkersStatus: status
    }))
  }

  return (
    <ThemeProvider theme={myTheme}>
      <SettingsContextProvider>
        {state.loading &&
          <div className={classes.dialogLoading}>
            <CircularProgress color="primary" />
          </div>}
        <div>
          <HeaderCompanySearch
            companies={state.companies}
            userCompany={company}
            currentCompany={state.currentCompany}
            onBulkUploadWorkers={onBulkUploadWorkers}
            permissions={state.permissions}
            onCompanyFilterChange={onCompanyFilterChange}
            onCreateWorker={onCreateWorker}
            onShowDepartmentsPositions={() => setDrawerDepartmentsPositions(true)}
          />
          <CompanyTableWorkers
            currentEmployee={currentEmployee}
            updateCompanyWorkers={state.updateCompanyWorkers}
            permissions={state.permissions}
            onEdit={onEdit}
            companyWorkersDomain={companyWorkersDomain}
            company={state.currentCompany}
            images_path={get(config, 'images_path')}
            filters={state.filters} config={config}
            allColumns={columns}
            defaultColumns={showedColumns}
            onLoading={onLoading}
            projects={state.projectCompanies}
            promoteWorkerEndpoint={promoteWorkerEndpoint}
            employee_settings_endpoint={employee_settings_endpoint}
            onDownloadData={onDownloadData}
            downloadButtonText={state.downloadButtonText}
            onShowHireRate={onShowHireRate}
            userTypes={state.userTypes}
          />
          <Drawer anchor={'right'} open={state.editWorker.status} onClose={() => onEditClose(false, null)}>
            <EditCompanyWorker
              currentEmployee={currentEmployee}
              companies={state.companies}
              worker={state.editWorker.worker}
              companyWorkersDomain={companyWorkersDomain}
              employee_settings_endpoint={employee_settings_endpoint}
              projectEndpoint={projectEndpoint}
              onEditClose={onEditClose} />
          </Drawer>
          <Drawer classes={{ paper: classes.permissionsDrawer }} anchor={'right'} open={state.editWorker.settingsAndPermissionsStatus} onClose={() => onEditClose(false, null)}>
            <EditCompanyWorkerSettingsAndPermissions
              companies={state.companies}
              worker={state.editWorker.worker}
              company={state.currentCompany}
              promoteWorkerEndpoint={promoteWorkerEndpoint}
              employee_settings_endpoint={employee_settings_endpoint}
              projectEndpoint={projectEndpoint}
              onEditClose={onEditClose}
              userTypes={state.userTypes} />
          </Drawer>
          <Drawer classes={{ paper: classes.permissionsDrawer }} anchor={'right'} open={state.showHireRate.status} onClose={onCloseHireRate}>
            <SetHireRate
              companies={state.companies}
              payment_profile_endpoint={payment_profile_endpoint}
              company={state.currentCompany}
              onEditRate={onEditRate}
              open={state.showHireRate.status}
              worker={state.showHireRate.worker}
              employee_settings_endpoint={employee_settings_endpoint}
              onHandleClose={onCloseHireRate} />
          </Drawer>
          <Drawer anchor={'right'} open={state.createWorkerStatus} onClose={() => onCreateWorker(false)}>
            <CreateWorker
              companies={state.companies}
              worker={state.editWorker.worker}
              company={state.currentCompany}
              companyWorkersDomain={companyWorkersDomain}
              employee_settings_endpoint={employee_settings_endpoint}
              projectEndpoint={projectEndpoint}
              onCreateWorker={onCreateWorker} />
          </Drawer>
          <Drawer classes={{ paper: classes.bulkUploadDrawer }} anchor={'right'} open={state.bulkUploadWorkersStatus} onClose={() => onBulkUploadWorkers(false)}>
            <BulkUploadWorkers
              currentEmployee={currentEmployee}
              company={state.currentCompany}
              companies={state.companies}
              companyWorkersDomain={companyWorkersDomain}
              bulkUploadWorkersWebsocketUrl={bulkUploadWorkersWebsocketUrl}
              fileUploadEndpoint={fileUploadEndpoint}
              sessionResultsEndpoint={sessionResultsEndpoint}
              onBulkUploadWorkers={onBulkUploadWorkers} />
          </Drawer>
          <Drawer anchor={'right'} open={drawerDepartmentsPositions} classes={{ paper: classes.drawerRoot }} >
            <CompanySettings endpoint={companySettingsEndpoint} onCancel={() => setDrawerDepartmentsPositions(false)} company={company} />
          </Drawer>
        </div>
      </SettingsContextProvider>
      <NotificationContainer />
    </ThemeProvider >
  )
};
