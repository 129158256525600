import React, { useState, useEffect, useContext } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { set, get } from 'lodash';
import { SettingsContext } from '../../../../contexts/SettingsContext';

export default function ActiveWorkers(props) {

  const { filter, onFilter } = props;
  const [active, setActive] = useState(false);

  const settingsContext = useContext(SettingsContext);


  const onLocalChange = (e) => {
    settingsContext.onCleaningFilter(false)
    setActive(e);
    onFilter(get(filter, 'key'), e);
  }

  useEffect(() => {
    if (settingsContext.clearFilter)
      setActive(false);
  }, [settingsContext.clearFilter])




  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={active}
          onChange={(e) => onLocalChange(e.target.checked)}
          name="checkedB"
          color="primary"
        />
      }
      label="Active Workers"
    />
  )
};