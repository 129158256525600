import React from 'react'
import { Form } from 'react-bootstrap'
import SingleSelect from './SingleSelect';

export default function GlobalSearch(props) {

  const { searchTags, placeholder } = props

  const parentContainerMap = {}

  searchTags.forEach(tag => {
    parentContainerMap[tag.id] = tag.container
  })

  const optionSelected = newPath => {
    console.log({ newPath, parentContainerMap });
    if (newPath.length > 0) {
      $(parentContainerMap[newPath]).parent().show()
      $(parentContainerMap[newPath]).collapse("show")
      $(".navbar-collapse").collapse("hide")
      const newUrl = `${location.origin}${newPath}`
      location.href = newUrl
    }
  }

  return (
    <div className="input-group" style={{ maxWidth: "350px" }}>
      <span className="input-group-addon"><span className="fas fa-search"></span></span>
      {/* <input type="text" name="" id="" className="form-control" placeholder="Search Here"/> */}
      <SingleSelect options={searchTags} pluginOptions={{ tags: false, placeholder: placeholder }} onChange={optionSelected} />
    </div>
  )
}
