import React, { useState, useContext, useEffect } from 'react';
import * as _ from 'lodash';
import { AddingColumns, EnhancedTableHead, WorkerTablePagination } from '../../../projectEmployees/components';
import { HeaderMenu } from '../index';
import { Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, lighten, makeStyles, Paper, Table, TableBody, TableContainer, Toolbar, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { RenderingCompanyEmployeeRows, DeleteWorker, PromoteWorker } from "../index";
import { gettingCompanyEmployees, deleteCompanyWorker, editWorkerRate, demoteWorker } from '../../../services/employeeService';
import { SettingsContext } from '../../../contexts/SettingsContext';
import { notificationCenter } from '../../../../helpers/notifications';

const EnhancedTableToolbar = (props) => {

  const { onChangeAddColumns } = props;

  const useToolbarStyles = makeStyles(theme => ({
    title: {
      flex: "1 1 100%"
    }
  }));

  const classes = useToolbarStyles();

  return (
    <Toolbar>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Workers
      </Typography>
      <IconButton aria-label="delete" onClick={onChangeAddColumns}>
        <Add />
      </IconButton>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

export default function CompanyTableWorkers(props) {

  const { currentEmployee, companyWorkersDomain, permissions, updateCompanyWorkers, images_path, onLoading, company, filters, allColumns, defaultColumns, onEdit, projects, promoteWorkerEndpoint, employee_settings_endpoint, onDownloadData, downloadButtonText, onShowHireRate, userTypes } = props;

  const classes = useStyles();

  let settingsContext = useContext(SettingsContext);

  const [state, setState] = useState({
    pagination: {
      page: 0,
      rowsPerPage: 25
    },
    workers: [],
    columns: defaultColumns,
    order: 'asc',
    orderBy: 'worker_name',
    deleteWorker: {
      status: false,
      worker: null
    },

    addColumns: false,
    totalColumns: allColumns,
    sendingFilters: [],
    promote: {
      status: false,
      worker: null
    },
    demote: {
      status: false,
      worker: null
    }
  })

  useEffect(() => {

    if (!_.isEmpty(state.workers)) {
      let payload = {
        pagination: {
          ...state.pagination
        },
        filters: state.sendingFilters,
        sort: {
          property: state.orderBy,
          isAsc: state.order === "asc"
        }
      }

      getCompanyWorkers(payload);
    }

  }, [company]);

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      columns: defaultColumns,
      totalColumns: allColumns

    }))
  }, [allColumns, defaultColumns])


  useEffect(() => {
    if (updateCompanyWorkers) {
      let payload = {
        pagination: {
          ...state.pagination
        },
        filters: state.sendingFilters,
        sort: {
          property: state.orderBy,
          isAsc: state.order === "asc"
        }
      }

      getCompanyWorkers(payload);
    }
  }, [updateCompanyWorkers])


  useEffect(() => {



    if (settingsContext.search) onSearch();


    // if (settingsContext.searchValue && settingsContext.searchValue !== '') {
    if (settingsContext.searchValue.length > 1) {
      onFilter('search', settingsContext.searchValue);
    } else if (settingsContext.searchValue.length === 0 && settingsContext.searchEnable) {

      setState(prevState => ({
        ...prevState,
        sendingFilters: prevState.sendingFilters.filter(item => _.get(item, 'key') !== 'search')
      }))

      let payload = {
        pagination: {
          ...state.pagination
        },
        filters: state.sendingFilters.filter(item => _.get(item, 'key') !== 'search'),
        sort: {
          property: state.orderBy,
          isAsc: state.order === "asc"
        }
      }
      // console.log("PAYLOAD ", state)
      getCompanyWorkers(payload, false)
    }
    // }

  }, [settingsContext.search, settingsContext.searchValue])

  const getCompanyWorkers = async (payload, blockPage = true) => {
    try {
      if (blockPage) onLoading();
      const res = await gettingCompanyEmployees(payload, _.get(company, 'value'), companyWorkersDomain);

      setState(prevState => ({
        ...prevState,
        workers: _.cloneDeep(_.get(res, 'data', []))
      }))
      onLoading(false)
    } catch (error) {
      onLoading(false)
      notificationCenter('error', _.get(error.response, 'data.error'));
    }
  }

  useEffect(() => {
    let payload = {
      pagination: {
        ...state.pagination
      },
      sort: {
        property: state.orderBy,
        isAsc: state.order === "asc"
      }
    }

    getCompanyWorkers(payload);


  }, [])

  const handleChangePage = (step) => {

    let page = 0;
    if (step === 'next') {
      page = state.pagination.page + 1;
    } else {
      page = state.pagination.page - 1;
    }

    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page
      }
    }))

    let payload = {
      filters: state.sendingFilters,
      pagination: {
        rowsPerPage: state.pagination.rowsPerPage,
        page
      },
      sort: {
        property: state.orderBy,
        isAsc: state.order === "asc"
      }
    }

    getCompanyWorkers(payload)
  };

  const handleChangeRowsPerPage = (e) => {

    setState(prevState => ({
      ...prevState,
      pagination: {
        page: 0,
        rowsPerPage: e.target.value
      }
    }))

    let payload = {
      filters: state.sendingFilters,
      pagination: {
        rowsPerPage: e.target.value,
        page: 0
      }
    }
    getCompanyWorkers(payload);
  };

  const handleRequestSort = (event, property) => {

    setState(prevState => ({
      ...prevState,
      order: state.order === "asc" ? "desc" : "asc",
      orderBy: property
    }))


    let payload = {
      pagination: {
        ...state.pagination
      },
      filters: state.sendingFilters,
      sort: {
        property,
        isAsc: state.order === "asc" ? false : true
      }
    }

    getCompanyWorkers(payload);
  };

  const onChangeAddColumns = () => {
    setState(prevState => ({
      ...prevState,
      addColumns: !prevState.addColumns
    }));

  }

  const onUpdateColumns = (tempColumns) => {
    setState(prevState => ({
      ...prevState,
      totalColumns: tempColumns,
      columns: tempColumns.filter(item => _.get(item, 'isActive')),
      addColumns: false
    }))
  }

  const onFilter = (key, data) => {
    let aux = [
      {
        key,
        value: _.isArray(data) ? data.map(item => get(item, 'value')) : data
      },
      ...state.sendingFilters
    ];

    setState(prevState => ({
      ...prevState,
      sendingFilters: _.uniqBy(aux, 'key')
    }))
  }

  const onSendingFilters = () => {
    let payload = {
      filters: state.sendingFilters.filter(oneFilter => !(_.get(oneFilter, 'key') === 'deleted' && _.get(oneFilter, 'value') === 0) && !(_.get(oneFilter, 'key') === 'user_type' && _.get(oneFilter, 'value') === 'all') ), // remove the filter deleted=0 and user_type=all from the array of filters
      pagination: {
        rowsPerPage: state.pagination.rowsPerPage,
        page: 0
      },
      sort: {
        property: state.orderBy,
        isAsc: state.order === "asc"
      }
    }

    setState(prevState => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page: 0
      }
    }))

    onLoading();
    getCompanyWorkers(payload)
  }

  const onDownloadingData = () => {
    let payload = {
      requestType: "FILE_DOWNLOAD",
      filters: state.sendingFilters,
      sort: {
        property: state.orderBy,
        isAsc: state.order === "asc"
      }
    }

    onDownloadData(payload)
  }

  const onSearch = () => {
    let payload = {
      pagination: {
        ...state.pagination
      },
      filters: state.sendingFilters,
      sort: {
        property: state.orderBy,
        isAsc: state.order === "asc"
      }
    }
    getCompanyWorkers(payload, false)
  }

  const onClearFilters = () => {

    setState(prevState => ({
      ...prevState,
      sendingFilters: [],
      pagination: {
        ...prevState.pagination,
        page: 0
      }
    }))

    let payload = {
      pagination: {
        rowsPerPage: state.pagination.rowsPerPage,
        page: 0
      }
    }
    getCompanyWorkers(payload);
  }

  const onDeleteWorker = worker => {
    setState(prevState => ({
      ...prevState,
      deleteWorker: {
        status: true,
        worker
      }
    }))
  }

  const onClosePromoteWorker = () => {
    setState(prevState => ({
      ...prevState,
      promote: {
        status: false,
        worker: null
      }
    }))

    let payload = {
      pagination: {
        ...state.pagination
      },
      filters: state.sendingFilters,
      sort: {
        property: state.orderBy,
        isAsc: state.order === "asc"
      }
    }
    getCompanyWorkers(payload);
  }

  const onCloseDeleteWorker = async (flag = false) => {

    try {
      if (_.isBoolean(flag) && flag) {
        onLoading(true)
        const res = await deleteCompanyWorker(companyWorkersDomain, _.get(state, 'deleteWorker.worker._id'));
        notificationCenter('success', _.get(res, 'data.message', 'Employee has been remove'));
        setState(prevState => ({
          ...prevState,
          deleteWorker: {
            status: false,
            worker: null
          }
        }))
        let payload = {
          pagination: {
            ...state.pagination
          },
          filters: state.sendingFilters,
          sort: {
            property: state.orderBy,
            isAsc: state.order === "asc"
          }
        }

        getCompanyWorkers(payload);

      } else {
        setState(prevState => ({
          ...prevState,
          deleteWorker: {
            status: false,
            worker: null
          }
        }))
      }
    } catch (error) {
      onLoading(false)
      notificationCenter('error', _.get(error.response, 'data.error'));
      setState(prevState => ({
        ...prevState,
        deleteWorker: {
          status: false,
          worker: null
        }
      }))
    }

  }

  const onEditRate = async (hire_rate, workerId) => {
    try {
      onLoading(true);
      const res = await editWorkerRate(companyWorkersDomain, { hire_rate }, workerId);
      let payload = {
        pagination: {
          ...state.pagination
        },
        filters: state.sendingFilters,
        sort: {
          property: state.orderBy,
          isAsc: state.order === "asc"
        }
      }
      getCompanyWorkers(payload, false);
    } catch (error) {
      notificationCenter('error', _.get(error.response, 'data.error'));
      onLoading(false)
    }
  }

  const onRecoverWorker = async () => {
    let payload = {
      pagination: {
        ...state.pagination
      },
      filters: state.sendingFilters,
      sort: {
        property: state.orderBy,
        isAsc: state.order === "asc"
      }
    }
    getCompanyWorkers(payload);
  }

  const onEditPerDiemRate = async (per_diem_rate, workerId) => {
    try {
      onLoading(true);
      const res = await editWorkerRate(companyWorkersDomain, { per_diem_rate }, workerId);
      let payload = {
        pagination: {
          ...state.pagination
        },
        filters: state.sendingFilters,
        sort: {
          property: state.orderBy,
          isAsc: state.order === "asc"
        }
      }
      getCompanyWorkers(payload, false);
    } catch (error) {
      notificationCenter('error', _.get(error.response, 'data.error'));
      onLoading(false)
    }
  }

  const onPromoteWorker = (worker) => {
    setState(prevState => ({
      ...prevState,
      promote: {
        status: true,
        worker
      }
    }))
  }

  const onDemoteWorker = async (row, confirm = false, flag = false) => {

    if (confirm) {
      try {

        setState(prevState => ({
          ...prevState,
          demote: {
            status: false,
            worker: null
          }
        }))
        const res = await demoteWorker(_.get(row, "_id"), promoteWorkerEndpoint);
        notificationCenter('success', _.get(res, 'data.message', 'Employee has been promoted'));
        let payload = {
          pagination: {
            ...state.pagination
          },
          filters: state.sendingFilters,
          sort: {
            property: state.orderBy,
            isAsc: state.order === "asc"
          }
        }
        getCompanyWorkers(payload, false);
      } catch (error) {
        notificationCenter('error', _.get(error.response, 'data.error'));

      }
    } else {


      setState(prevState => ({
        ...prevState,
        demote: {
          status: flag,
          worker: row
        }
      }))
    }
  }

  return (
    <>
      <HeaderMenu
        company={company}
        filters={filters}
        onFilter={onFilter}
        onSendingFilters={onSendingFilters}
        onClearFilters={onClearFilters}
        onDownloadData={onDownloadingData}
        downloadButtonText={downloadButtonText}
        userTypes={userTypes}
      />

      <div>
        <WorkerTablePagination
          pagination={state.pagination}
          onChangePage={handleChangePage}
          hasNext={_.size(state.workers) === state.pagination.rowsPerPage}
          hasWorkers={_.size(state.workers) > 0}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

        <Paper className={classes.paper}>

          <EnhancedTableToolbar onChangeAddColumns={onChangeAddColumns} />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"medium"}
              style={{ position: 'relative' }}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={state.order}
                orderBy={state.orderBy}
                onRequestSort={handleRequestSort}
                rowCount={state.workers.length}
                columns={state.columns}
              />
              <TableBody>
                <RenderingCompanyEmployeeRows
                  currentEmployee={currentEmployee}
                  workers={state.workers}
                  permissions={permissions}
                  onEditRate={onEditRate}
                  onEditPerDiemRate={onEditPerDiemRate}
                  onDeleteWorker={onDeleteWorker}
                  onPromoteWorker={onPromoteWorker}
                  onDemoteWorker={onDemoteWorker}
                  onEdit={onEdit}
                  images_path={images_path}
                  employee_settings_endpoint={employee_settings_endpoint}
                  columns={state.columns.map(item => _.get(item, 'id'))}
                  companyWorkersDomain={companyWorkersDomain}
                  onShowHireRate={onShowHireRate}
                  onRecoverWorker={onRecoverWorker}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <WorkerTablePagination
          pagination={state.pagination}
          onChangePage={handleChangePage}
          hasNext={_.size(state.workers) === state.pagination.rowsPerPage}
          hasWorkers={_.size(state.workers) > 0}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

        {state.addColumns && <AddingColumns addColumns={state.addColumns} totalColumns={state.totalColumns} onUpdateColumns={onUpdateColumns} onChangeAddColumns={onChangeAddColumns} />}

        {state.deleteWorker.status && <DeleteWorker open={state.deleteWorker.status} onCloseDeleteWorker={onCloseDeleteWorker} worker={state.deleteWorker.worker} />}

        {state.promote.status && <PromoteWorker open={state.promote.status} onClosePromoteWorker={onClosePromoteWorker} worker={state.promote.worker} projects={projects} promoteWorkerEndpoint={promoteWorkerEndpoint} userTypes={userTypes} />}

        {state.demote.status &&

          <Dialog
            open={state.demote.status}
            onClose={() => onDemoteWorker(state.demote.worker, false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >

            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`Are you sure you want to demote to ${_.get(state.demote, 'worker.first_name')} ${_.get(state.demote, 'worker.last_name')}?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => onDemoteWorker(state.demote.worker, false, false)} color="primary">
                Cancel
              </Button>
              <Button onClick={() => onDemoteWorker(state.demote.worker, true)} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>

        }



      </div>
    </>
  )
};
