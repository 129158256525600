import React, { Component } from 'react';
import TableMetrics from "./TableMetrics";
import ChartMetrics from "./ChartMetrics";
import axios from "axios";
import moment from 'moment';

export default class BudgetMetrics extends Component {

  constructor(props) {
    super(props)
    this.state = {
      collapsed: true,
      rows: [],
      displayTable: false,
      ready: false,
      loading: false
    }
    this.fetchReport = this.fetchReport.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.collapse = this.collapse.bind(this);
    this.expand = this.expand.bind(this);
  }

  // componentDidMount() {
  //   this.fetchReport();
  // }

  downloadReport() {
    const urlEnpoint = window.location.pathname + "/budget-metrics/csv/" + window.location.search;
    return urlEnpoint
  }

  collapse() {
    this.setState({collapsed: true})
  }

  expand() {
    this.fetchReport()
    this.setState({collapsed: false})
  }

  fetchReport() {
    const urlEnpoint = window.location.pathname + "/budget-metrics" + window.location.search;
    this.setState({ loading: true})
    axios.get(urlEnpoint).then(response => {
      this.setState({ loading: false})
      if (!!response.data.loading) {
        this.setState({ ready: false })
      } else {
        this.setState({ rows: response.data.rows, updated_at: response.data.updated_at, ready: true })
      }
    }).catch(errorLoadingProductivity => {
      this.setState({ ready: false })
      console.log({ errorLoadingProductivity })
    })
  }

  handleToggle(e) {
    const val = this.state.displayTable;
    this.setState({ displayTable: !val });
  }

  render() {
    let updated = moment()
    if (this.state.updated_at) {
      updated = moment(this.state.updated_at)
    }
    if (this.state.collapsed) {
      return (
      <div className="panel panel-hover">
        <div className="panel-heading">
          <span className="panel-title">
            <span className="font-size">
              Labor Productivity
            </span>
                <button className="btn btn-secondary pull-right" onClick={this.expand}>
                  <i className="fa fa-plus" />
                </button>
          </span>
        </div>
        <div className="panel-body"></div>
      </div>
      )
    }

    return (

      <div className="panel panel-hover">
        <div className="panel-heading">
          <span className="panel-title">
            {/* {JSON.stringify(this.state)} */}
              {(()=>{
                if (!!this.state.ready) {
                  return (
                    <span className="font-size">Labor Productivity Report <strong>Updated {updated.from(moment.now())}</strong></span>
                  )
                } else {
                  return (
                    <span className="font-size">Labor Productivity Report will be ready in no more than 5 minutes </span>
                  )
                }
              })()}
                <button className="btn btn-secondary pull-right" onClick={this.handleToggle}>
                  {this.state.displayTable ? "Show Chart" : "Show Table"}
                </button>
                <button className="btn btn-secondary pull-right" onClick={this.fetchReport}>
                  <i className="fa fa-refresh" />
                </button>
                <a className="btn btn-secondary pull-right" target="_blank" href={this.downloadReport()}>
                  <i className="fa fa-save" />
                </a>
                <button className="btn btn-secondary pull-right" onClick={this.collapse}>
                  <i className="fa fa-minus" />
                </button>
          </span>
        </div>
        <div className="panel-body">
          <div className="row">
            <div className="col-md-12">
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {this.state.loading && (
                <div className="spinner-container">
                  <i className="fa fa-circle-o-notch fa-spin spinner"></i>
                </div>
              )}
              {(()=>{
                if (!!this.state.ready && !this.state.loading) {
                  return this.state.displayTable ? <TableMetrics rows={this.state.rows} /> : <ChartMetrics rows={this.state.rows} />
                } else if (!this.state.loading) {
                  return (
                    <div className="spinner-container">
                      <button className="btn btn-secondary pull-right" onClick={this.fetchReport}>
                        <i className="fa fa-refresh" />
                        Not ready click here to reload
                      </button>
                    </div>
                  )
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}