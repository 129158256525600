import React, { useContext } from 'react';
import { SettingsContext } from '../../../employees/contexts/SettingsContext';
import { SearchBox, Widget } from '../../../employees/projectEmployees/components/index';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core';
import { get } from 'lodash';

export default function HeaderComponent(props) {

  const { currentCompany } = props;
  const settingsContext = useContext(SettingsContext);

  const selectStyles = {
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      marginBottom: `${settingsContext.isMobile ? '10px' : '0px'}`,
      marginRight: `${settingsContext.isMobile ? '0px' : '10px'}`
    })
  }

  const topMenuStyles = makeStyles((theme) => ({
    headerWidgetMobile: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column'
    }
  }));

  const classes = topMenuStyles();


  return (
    <Widget >
      <div className={`${settingsContext.isMobile ? classes.headerWidgetMobile : 'headerWidget'}`} >
        <h3>
          <span className='text-muted font-weight-light' color="textPrimary">{get(currentCompany, 'label')} / </span>
          Payment Profile
        </h3>

        <div className={`headerRightPanel top-head-page-right d-flex align-items-center`} style={{ width: "20%" }}>
          <SearchBox onSearchChange={settingsContext.onSearchChange} value={settingsContext.searchValue} onSearch={settingsContext.onSearch} />
        </div>
      </div>
    </Widget>
  )
};
