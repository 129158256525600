import React, { useState, useEffect } from 'react'
import { get, isEqual } from 'lodash';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import { green, grey } from '@material-ui/core/colors';
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { myTheme } from './helpers';

import Receipt from '@material-ui/icons/Receipt';
import Dashboard from '@material-ui/icons/Dashboard';
import Search from '@material-ui/icons/Search';

import CreateEditCompany from './CreateEditCompany.js'

import Axios from "axios";
import { axiosConfig } from "./helpers"

export default function companiesTable({ availableStates, companiesEndpoint }) {

  const classes = useStyles();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");


  const defaultPagination = { companies: [], totalCount: 0 }
  const [companiesPagination, setCompaniesPagination] = useState(defaultPagination);
  const [availableCompanies, setAvailableCompanies] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [loading, setLoading] = useState(true);
  const [innerLoading, setInnerLoading] = useState(false);
  const [listError, setListError] = useState(false);
  const [searchTerm, setSearch] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (ev, newPage) => {
    setInnerLoading(true);
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (ev, val) => {
    setPage(0);
    setPerPage(val.props.value);
  }

  const handleSearchSubmit = (event) => {
    setPage(0);
    setInnerLoading(true);
    getCompanies();
  }

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  }

  useEffect(() => {
    setInnerLoading(true);
    getCompanies();
  }, [page, perPage, orderBy, order]);

  useEffect(() => {
    getCompanies(true);
  }, []);

  const paginationParams = () => ({ page: parseInt(page) + 1, limit: perPage })

  const getCompanies = (fetchAvailableCompanies) => {
    const paginationString = new URLSearchParams(paginationParams())
    let apiURL = `${companiesEndpoint}?${paginationString.toString()}`

    if( !isEqual( searchTerm, '')) {
      apiURL += `&search=${searchTerm}`
    }

    if( !isEqual( orderBy, '')) {
      apiURL += `&sort=${orderBy}&order=${order}`
    }

    if (fetchAvailableCompanies) getAvailableCompanies();

    Axios.get(apiURL, axiosConfig()).then(response => {
      setCompaniesPagination(response.data || defaultPagination)
      setLoading(false)
      setInnerLoading(false)
    }).catch(error => {
      setLoading(false)
      setInnerLoading(false)
      setListError(true)
      toastr.error(get(error, 'response.data.error', 'Error listing companies.'));
    })
  }

  const getAvailableCompanies = () => {
    let apiURL = `${companiesEndpoint}/listAvailableCompanies`

    Axios.get(apiURL, axiosConfig()).then(response => {
      setAvailableCompanies(response.data)
    }).catch(error => {
      toastr.error(get(error, 'response.data.error', 'Error fetching available companies.'));
    })
  }

  const columns = [
    {
      id: "name",
      sortOption: true,
      label: "Name",
      isActive: true
    },
    {
      id: "_id",
      sortOption: true,
      label: "C-DBID",
      isActive: true
    },
    {
      id: "ein",
      sortOption: true,
      label: "EIN",
      isActive: true
    },
    {
      id: "dbid",
      sortOption: true,
      label: "Company ID",
      isActive: true
    },
    {
      id: "payroll_mode",
      sortOption: true,
      label: "Payroll Mode",
      isActive: true
    },
    {
      id: "is_smartbarrel_client",
      sortOption: true,
      label: "Is Customer",
      isActive: true
    },
    {
      id: "client_id",
      sortOption: true,
      label: "Customer ID",
      isActive: true
    },
    {
      id: "projects",
      sortOption: false,
      label: "Projects",
      isActive: true
    },
    {
      id: "employees",
      sortOption: false,
      label: "Employees",
      isActive: true
    },
    // {
    //   id: "hours",
    //   sortOption: false,
    //   label: "Hours",
    //   isActive: false
    // },
    {
      id: "createdBy",
      sortOption: false,
      label: "Created By",
      isActive: true
    },
    {
      id: "createdDate",
      sortOption: false,
      label: "Created Date",
      isActive: true
    },
    {
      id: "actionButtons",
      sortOption: false,
      label: "",
      isActive: true
    },
  ]

  const loadingSection = () => (
    <Box className={classes.dialogLoading}>
      <ThemeProvider theme={myTheme}>
        <CircularProgress color="primary" />
      </ThemeProvider>
    </Box>
  )

  const EnhancedTableHead = (props) => {
    const { classes, order, orderBy, onRequestSort, columns } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TablePagination
            count={companiesPagination.totalCount}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={perPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableRow>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align="left"
              padding={column.disablePadding ? "none" : "default"}
              sortDirection={orderBy === column.id ? order : false}
              className={classes.headerStyle}
            >
              {column.sortOption ?
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : "asc"}
                  onClick={createSortHandler(column.id)}
                  className={classes.sortableHeader}
                >
                  {column.label}
                  {orderBy === column.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc" ? "sorted descending" : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel> :
                column.label
              }

            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  return (
    <>
        { loading && loadingSection() }
        { !loading && !listError &&
          <Box>
            <Box className={classes.createAndSearchWrapper}>
              <CreateEditCompany companiesEndpoint={companiesEndpoint} reloadTable={getCompanies} availableStates={availableStates} />
              <Box className={classes.search}>
                <InputBase
                  placeholder="Search"
                  onChange={handleSearchChange}
                  value={searchTerm}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      handleSearchSubmit()
                    }
                  }}
                  className={classes.inputBase}
                  classes={{
                    input: classes.inputInput
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
                <ThemeProvider theme={myTheme}>
                  <IconButton onClick={handleSearchSubmit} size="small" color="primary" aria-label="Search" component="span">
                    <Search color="primary" />
                  </IconButton>
                </ThemeProvider>
              </Box>
            </Box>
            <Box className={classes.root}>
              { innerLoading &&
                <Box className={classes.innerLoading}>
                  <ThemeProvider theme={myTheme}>
                    <CircularProgress color="primary" />
                  </ThemeProvider>
                </Box>
              }
              <Paper className={classes.paper}>
                <TableContainer>
                  <Table>
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={companiesPagination.companies.length}
                      columns={columns}
                    />
                    <TableBody>
                      {!loading && companiesPagination.companies.map( oneCompany => {
                        return (
                          <TableRow hover key={oneCompany._id}>
                            <TableCell>
                              {oneCompany.name}
                            </TableCell>
                            <TableCell>
                              {oneCompany._id}
                            </TableCell>
                            <TableCell>
                              {oneCompany.ein}
                            </TableCell>
                            <TableCell>
                              {oneCompany.dbid}
                            </TableCell>
                            <TableCell >
                              <Icon className="fa fa-circle" style={{ color: oneCompany.payroll_mode ? green[500] : grey[300] }} />
                            </TableCell>
                            <TableCell>
                              <Icon className="fa fa-circle" style={{ color: oneCompany.is_smartbarrel_client ? green[500] : grey[300] }} />
                            </TableCell>
                            <TableCell>
                              { get( oneCompany, 'client_id', '')}
                            </TableCell>
                            <TableCell>
                              {oneCompany.projects.length}
                            </TableCell>
                            <TableCell>
                              {oneCompany.employees}
                            </TableCell>
                            {/*<TableCell>
                              Hours
                            </TableCell>*/}
                            <TableCell>
                              {get( oneCompany, 'created_by.first_name', '') + ' ' + get( oneCompany, 'created_by.last_name', '')}
                            </TableCell>
                            <TableCell>
                              {moment(oneCompany.created_at).format("MM/DD/YYYY LT")}
                            </TableCell>
                            <TableCell align="right">
                              <Tooltip title="Billing" arrow>
                                <IconButton
                                  aria-label="chart"
                                  style={{ color: green[500] }}
                                  onClick={() => window.location.href = `/billing/${oneCompany._id}`}
                                  size="small"
                                  disabled={!oneCompany.is_smartbarrel_client}
                                >
                                  <Receipt />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Dashboard" arrow >
                                <span>
                                  <IconButton
                                    aria-label="chart"
                                    // style={{ color: oneCompany.is_smartbarrel_client ? '#4cbfe2' : '' }}
                                    style={{ color: '#4cbfe2' }}
                                    onClick={() => window.location.href = `/companies/${oneCompany._id}/dashboard`}
                                    size="small"
                                    //disabled={!oneCompany.is_smartbarrel_client}
                                  >
                                    <Dashboard />
                                  </IconButton>
                                </span>
                              </Tooltip>
                              <CreateEditCompany
                                companiesEndpoint={companiesEndpoint}
                                reloadTable={getCompanies}
                                availableStates={availableStates}
                                editMode={true}
                                companyToEdit={oneCompany}
                                availableCompanies={availableCompanies}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          count={companiesPagination.totalCount}
                          page={page}
                          onChangePage={handleChangePage}
                          rowsPerPage={perPage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Box>
        }
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: "100%",
    "& .MuiTableCell-root": {
      padding: '11px',

    },
    "& .MuiTableCell-body": {
      fontSize: '12px',
      color: '#444',
      fontFamily: "Open Sans"
    },
    "& .MuiTableSortLabel-root": {
      color: "#316498"
    },
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  headerStyle: {
    fontSize: '13px',
    color: '#444'
  },
  sortableHeader: {
    color: '#316498 !important'
  },
  createAndSearchWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #e0e0e0',
    backgroundColor: "#fff",
    marginLeft: 0,
    width: "300px",
    height: "30px",
  },
  inputBase: {
    height: '100%',
    width: '90%'
  },
  inputInput: {
    padding: theme.spacing(2.3, 2, 3, 1),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  dialogLoading: {
    width: "100%",
    height: "300px",
    display: "flex",
    backgroundColor: '#fafafa',
    zIndex: '9999999',
    justifyContent: "center",
    alignItems: "center"
  },
  innerLoading: {
    position: 'absolute',
    width: "100%",
    height: "100%",
    display: "flex",
    backgroundColor: 'rgb(250,250,250, 0.5)',
    zIndex: '9999999',
    justifyContent: "center",
    alignItems: "center",
  },
}));
