import React, { useEffect, useContext, useState } from 'react';
import { Checkbox, Drawer, makeStyles, Button, CircularProgress, Accordion, AccordionSummary, AccordionDetails, InputAdornment, FormControlLabel, TextField, Select, FormControl, Input, InputLabel, MenuItem, FormLabel, RadioGroup, Radio, List, FormHelperText, ListItem, ListItemText, ListSubheader, ListItemSecondaryAction, Slider, Tooltip } from '@material-ui/core';
import { SettingsContext } from '../contexts/SettingsContext';
import { updateProjectSettings, getProjectVendors, clearSetting } from '../services';
import { get, size, has, isEmpty, isEqual, unset } from 'lodash'
import { Cancel, Send } from '@material-ui/icons';
import { SBSwitch, SkeletonTypography } from '../../reusableComponents/index'
import ApartmentIcon from '@material-ui/icons/Apartment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { notificationCenter } from '../../helpers/notifications';
import moment from 'moment';
import { DateTime } from 'luxon';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useMediaQuery } from 'react-responsive';

const drawerSize = () => {

  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 480 });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 768 });
  const isLaptop = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1025 });

  switch (true) {
    case isMobile:
      return '100%'
    case isTablet:
      return '90%'
    case isLaptop:
      return '75%';
    case isDesktop:
      return '30%';
    default:
      return "80%";
  }

}

const defaultShiftLength = 14

export default function ProjectSettings(props) {

  const { openSettings, onShowSettings, company, project, project_endpoint, user } = props;

  const settingsContext = useContext(SettingsContext);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%"
    },
    drawerRoot: {
      width: drawerSize()
    },
    mainContent: {
      backgroundColor: '#f3f3f4',
      height: "100%",
      width: "100%",
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    avatarLarge: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    deviceList: {
      padding: "4px",
      borderRadius: "2px",
      fontWeight: "600",
      backgroundColor: "#818a91",
      color: "white",
      marginRight: "5px",
      marginBottom: '5px'
    },
    accordionTextField: {
      marginRight: '15px',
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: "100%",
      marginBottom: '20px'
    },
    daysOfTheWeek: {
      '& .MuiSelect-select': {
        height: 130
      }
    },
    radioLabel: {
      '& .MuiTypography-body1': {
        fontSize: 12
      }
    },
    radioFormControlWrapper: {
      '& .MuiFormControlLabel-root': {
        marginLeft: 0
      }
    },
    resetGeofenceLoader: {
      marginLeft: 20,
      marginRight: 20,
      marginTop: 1,
      marginBottom: 1
    },
    crewFilterFormControl: {
      fontSize: '1.4rem',
      marginLeft: 8,
      fontWeight: 600,
      '& .MuiFormControlLabel-root': {
        marginLeft: 8
      }
    },
    itemsList: {
      padding: 0,
      marginTop: 20,
      width: '100%',
      minHeight: 150,
      maxHeight: 150,
      border: "1px solid #9c9c9c2e",
      overflow: 'scroll',
      backgroundColor: theme.palette.background.paper,
      // '& li:nth-child(even)': {
      //   backgroundColor: '#e0e0e0'
      // }
    },
    servicesHeaderStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%'
      // width: 'calc(100% - 70px)'
    },
    listItemStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      // width: 'calc(100% - 65px)'
      width: '100%'
    },
    perdiemWeekendWrapper: {
      display: 'flex',
      alignItems: 'center',
      maxHeight: 0,
      transition: 'max-height 0.3s ease-in-out',
      overflow: 'hidden',
      paddingLeft: 25
    },
    settingsMainTogglesWrapper: {
      display: 'flex',
      flexDirection: 'column',
      // marginBottom: 16
    },
    oneMainToggleWRapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 600,
      color: '#7a7a7a',
      textTransform: 'capitalize',
      marginBottom: 8
    },
    drawerActionButtonsWrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%'
    },
    projectInfoWrapper: {
      display: "flex",
      padding: 12,
      borderBottom: `2px solid ${theme.palette.primary.main}`
    }
  }));

  const classes = useStyles();

  let smsServices = {}
  get(project, 'companyProjectSettings.smsFlows', []).map(oneSmsFlow => { smsServices[oneSmsFlow.id] = oneSmsFlow })

  const [state, setState] = useState({
    project,
    editing: false,
    openDrawer: false,
    subcontractors: [],
    companySuperAdmin: null,
    openCompanySelect: false,
    serviceRadio: '',
    happensAtRadio: '',
    smsActive: true,
    smsDaysOfTheWeek: [],
    smsServices,
    perdiemWeekend: {
      Sat: false,
      Sun: false
    },
  });

  const [maxShiftLength, setMaxShiftLength] = useState(1)
  // const [forceCheckInRange, setForceCheckInRange] = useState([1, 1439])

  const gettingProjectDetails = async () => {
    try {


      const resSubContractors = await getProjectVendors(project_endpoint, has(project, '_id.$oid') ? get(project, '_id.$oid') : get(project, '_id'));

      const rootCompany = get(resSubContractors, 'data', []).find(item => get(item, 'isRoot'));

      setState(prevState => ({
        ...prevState,
        openDrawer: true,
        subcontractors: get(resSubContractors, 'data', []),
        companySuperAdmin: get(rootCompany, '_id'),
      }))


      // let rangeFrom = 1
      // let rangeTo = 1439
      //
      // if (get(res, 'data.companyProjectSettings.forceCheckIn.from', '').length) {
      //     let durX =  new DateTime.fromFormat('00:00', 'HH:mm')
      //     let durY = new DateTime.fromFormat(get(res, 'data.companyProjectSettings.forceCheckIn.from'), 'HH:mm')
      //
      //     rangeFrom = durY.diff(durX, ['minutes']).toObject().minutes
      // }
      // if (get(res, 'data.companyProjectSettings.forceCheckIn.to', '').length) {
      //     let durX =  new DateTime.fromFormat('00:00', 'HH:mm')
      //     let durY = new DateTime.fromFormat(get(res, 'data.companyProjectSettings.forceCheckIn.to'), 'HH:mm')
      //
      //     rangeTo = durY.diff(durX, ['minutes']).toObject().minutes
      // }
      //
      // setForceCheckInRange([rangeFrom, rangeTo])
    } catch (error) {
      notificationCenter("error", get(error, 'response.data.error', 'Error'))
    }
  }

  useEffect(() => {
    gettingProjectDetails()
  }, []);

  const handleToggle = (event) => {

    setState(prevState => ({
      ...prevState,
      project: {
        ...prevState.project,
        companyProjectSettings: {
          ...prevState.project.companyProjectSettings,
          [event.target.name]: event.target.checked
        }
      }
    }));
  }

  const handleSmsActiveToggle = (event) => {
    setState(prevState => ({
      ...prevState,
      smsActive: event.target.checked
    }));
  }


  const handleChange = (name, value) => {

    if ((name === 'acknowledgment_en' && size(value) > 160) || (name === 'acknowledgment_es' && size(value) > 160)) return;
    setState(prevState => ({
      ...prevState,
      project: {
        ...prevState.project,
        companyProjectSettings: {
          ...prevState.project.companyProjectSettings,
          [name]: value
        }
      }
    }))
  }


  const onSave = async () => {
    let payload = get(state.project, 'companyProjectSettings')

    if (!payload.maxShiftLength) {
      payload.maxShiftLength = defaultShiftLength
    }

    payload.smsFlows = Object.keys(get(state, 'smsServices')).map(serviceId => get(get(state, 'smsServices'), serviceId))
    // payload.forceCheckIn = {
    //   from: forceCheckInRangeTime(0, 'HH:mm'),
    //   to: forceCheckInRangeTime(1, 'HH:mm'),
    // }

    try {

      setState(prevState => ({
        ...prevState,
        editing: true
      }));

      const res = await updateProjectSettings(project_endpoint, get(state.project, '_id'), payload, get(user, 'user_type') === 'super_admin' ? get(state, 'companySuperAdmin') : null);

      notificationCenter("success", "Project was updated")

      setState(prevState => ({
        ...prevState,
        editing: false
      }));

      onShowSettings(false, true)
    } catch (error) {
      notificationCenter("error", get(error, 'response.data.error', 'Error'))
    }
  }

  const handleResetGeofenceSettings = async () => {
    try {

      setState(prevState => ({
        ...prevState,
        resettingGeofence: true
      }));

      const res = await clearSetting(project_endpoint, 'mobileAppSettings.geofence', get(project, '_id'));

      // notificationCenter("success", "Project was updated")

      setState(prevState => ({
        ...prevState,
        resettingGeofence: false,
        project: {
          ...get(prevState, 'project'),
          companyProjectSettings: {
            ...get(prevState, 'project.companyProjectSettings'),
            mobileAppSettings: {
              ...get(prevState, 'project.companyProjectSettings.mobileAppSettings'),
              geofence: {
                disabled: false,
                radius: 0
              }
            }
          }
        }
      }));
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        resettingGeofence: false
      }));
      notificationCenter("error", get(error, 'response.data.error', 'Error'))
    }
  }

  const handleServiceRadioChange = (event) => {
    setState(prevState => ({
      ...prevState,
      serviceRadio: event.target.value
    }))
  }

  const handlePerdiemSat = (event) => {
    setState(prevState => ({
      ...prevState,
      perdiemWeekend: {
        ...prevState.perdiemWeekend,
        Sat: !get( get( state, 'perdiemWeekend'), 'Sat')
      }
    }))
  }

  const handlePerdiemSun = (event) => {
    setState(prevState => ({
      ...prevState,
      perdiemWeekend: {
        ...prevState.perdiemWeekend,
        Sun: !get( get( state, 'perdiemWeekend'), 'Sun')
      }
    }))
  }

  const handleHappensAtRadioChange = (event) => {
    setState(prevState => ({
      ...prevState,
      happensAtRadio: event.target.value
    }))
  }

  const handleChangeDaysOfTheWeek = (event, value) => {
    setState(prevState => ({
      ...prevState,
      smsDaysOfTheWeek: value
    }))
  }

  const handleAddUpdateService = () => {
    setState(prevState => {
      let tempObj = {
        id: `${get(state, 'serviceRadio')}_${get(state, 'happensAtRadio')}`,
        service: get(state, 'serviceRadio'),
        at: get(state, 'happensAtRadio'),
        dayOfWeek: get(state, 'smsDaysOfTheWeek'),
        active: get(state, 'smsActive')
      }

      if (get(state, 'serviceRadio') === 'perdiem') {
        tempObj.perdiemWeekend = get(state, 'perdiemWeekend')
      }

      let smsServices = {
        ...prevState.smsServices,
        [`${get(state, 'serviceRadio')}_${get(state, 'happensAtRadio')}`]: tempObj
      }

      return ({
        ...prevState,
        smsServices,
        smsDaysOfTheWeek: [],
        happensAtRadio: '',
        serviceRadio: '',
        smsActive: true,
        isUpdating: false,
        perdiemWeekend: { Sat: false, Sun: false }
      })
    })
  }

  const handleServiceClick = (service) => {
    setState(prevState => ({
      ...prevState,
      smsDaysOfTheWeek: get(service, 'dayOfWeek'),
      happensAtRadio: get(service, 'at'),
      serviceRadio: get(service, 'service'),
      smsActive: get(service, 'active'),
      perdiemWeekend: get(service, 'perdiemWeekend', { Sat: false, Sun: false }),
      isUpdating: true
    }))
  }

  const handleRemoveService = (service) => {
    setState(prevState => {
      unset(get(prevState, 'smsServices'), get(service, 'id'))

      return ({
        ...prevState,
        smsDaysOfTheWeek: [],
        happensAtRadio: '',
        serviceRadio: '',
        smsActive: true,
        isUpdating: false
      })
    })
  }

  const handleMaxShiftLengthInputChange = (event) => {
    if (event && event.target) {

      const { target } = event

      setState(prevState => ({
        ...prevState,
        project: {
          ...prevState.project,
          companyProjectSettings: {
            ...prevState.project.companyProjectSettings,
            maxShiftLength: target.value === '' ? '' : Number(target.value)
          }
        }
      }))
    }
  };

  const handleMaxShiftLengthInputBlur = () => {
    let value = get(state, 'project.companyProjectSettings.maxShiftLength', 0)
    let newValue

    if (value = 0) {
      newValue = defaultShiftLength
    } else if (value < 0) {
      newValue = 8
    } else if (value > 100) {
      newValue = 168
    }

    setState(prevState => ({
      ...prevState,
      project: {
        ...prevState.project,
        companyProjectSettings: {
          ...prevState.project.companyProjectSettings,
          maxShiftLength: newValue
        }
      }
    }))
  };

  const toggleForceCrewFilter = (event) => {
    setState(prevState => ({
      ...prevState,
      project: {
        ...get(prevState, 'project'),
        companyProjectSettings: {
          ...get(prevState, 'project.companyProjectSettings'),
          uiSettings: {
            ...get(prevState, 'project.companyProjectSettings.uiSettings'),
            timesheet: {
              ...get(prevState, 'project.companyProjectSettings.uiSettings.timesheet'),
              forceCrewFilter: event.target.checked
            }
          }
        }
      }
    }))
  };

  const toggleGeofence = (event) => {
    setState(prevState => ({
      ...prevState,
      project: {
        ...get(prevState, 'project'),
        companyProjectSettings: {
          ...get(prevState, 'project.companyProjectSettings'),
          mobileAppSettings: {
            ...get(prevState, 'project.companyProjectSettings.mobileAppSettings'),
            geofence: {
              ...get(prevState, 'project.companyProjectSettings.mobileAppSettings.geofence'),
              disabled: event.target.checked
            }
          }
        }
      }
    }))
  };

  const handleGeofenceRadiusChange = (e) => {

  // const handleForceCheckinRangeChange = (event, newValue) => {
  //   setForceCheckInRange(newValue)
  // };

  // const forceCheckInRangeTime = (index, format) => {
  //   let midnight = new DateTime.now().startOf('day')
  //
  //   return midnight.plus({ minutes: get(forceCheckInRange, index) }).toFormat(format)
  // }
    const floatRegex = /^[+]?(\d+(\.\d*)?|\.\d+)$/; // Regex for positive float number validation

     if (floatRegex.test(e.target.value) || e.target.value === '') {
       setState(prevState => ({
         ...prevState,
         project: {
           ...get(prevState, 'project'),
           companyProjectSettings: {
             ...get(prevState, 'project.companyProjectSettings'),
             mobileAppSettings: {
               ...get(prevState, 'project.companyProjectSettings.mobileAppSettings'),
               geofence: {
                 ...get(prevState, 'project.companyProjectSettings.mobileAppSettings.geofence'),
                 radius: e.target.value
               }
             }
           }
         }
       }))
     }
  };

  let resetGeofenceTooltip = 'Clicking the reset button erases the Geofence settings and applies the values defined at the company level. This operation cannot be undone.'

  return (
    <Drawer disableBackdropClick={true} classes={{ paper: classes.drawerRoot }} anchor={'right'} open={openSettings} onClose={onShowSettings}>

      <div className={classes.projectInfoWrapper}>
        <ApartmentIcon style={{ fontSize: "50px", color: '#ed5723' }} />
        <div style={{ display: "flex", flexDirection: 'column', marginLeft: '10px' }}>
          <strong style={{ fontSize: "16px" }}>{get(state.project, 'name', '')}</strong>
          <span>{`${get(state.project, 'address', '')} , ${get(state.project, 'city', '')} ${get(state.project, 'state', '')} ${get(state.project, 'zip',
            '')}`}</span>
        </div>
      </div>

      <div className={classes.mainContent}>
        <div className="worker-form-information">

          {!state.openDrawer ? <SkeletonTypography /> :
            <>
              {get(user, 'user_type') === 'super_admin' ?
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-controlled-open-select-label">Company</InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    open={state.openCompanySelect}
                    onClose={() => setState(prevState => ({ ...prevState, openCompanySelect: false }))}
                    onOpen={() => setState(prevState => ({ ...prevState, openCompanySelect: true }))}
                    value={state.companySuperAdmin}
                    onChange={(e) => setState(prevState => ({ ...prevState, companySuperAdmin: e.target.value }))}
                  >

                    {state.subcontractors.map((item, key) => (
                      <MenuItem value={get(item, '_id')}>
                        {get(item, 'name')}
                      </MenuItem>
                    ))}


                  </Select>
                </FormControl> : null}


              <div>
                <div className={classes.settingsMainTogglesWrapper}>
                  <div className={classes.oneMainToggleWRapper} style={{ color: get(state.project, 'companyProjectSettings.active', false) ? '#f15922' : '#7a7a7a' }}>
                    <span>Active</span>
                    <SBSwitch checked={get(state.project, 'companyProjectSettings.active', false)} handleToggle={handleToggle} noMargin name='active' />
                  </div>
                </div>

                <hr style={{ margin: '4px 0px 16px 0px' }}/>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div style={{ display: "flex" }}>
                      <strong>Project Customizations</strong>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextField
                      name="user_project_id"
                      id="user_project_id"
                      className={classes.accordionTextField}
                      label="Project ID"
                      color="primary"
                      value={get(state.project, 'companyProjectSettings.user_project_id', '')}
                      onChange={e => handleChange('user_project_id', e.target.value)}
                    />
                    <TextField
                      name="user_project_name"
                      id="user_project_name"
                      className={classes.accordionTextField}
                      label="Project Alias"
                      color="primary"
                      value={get(state.project, 'companyProjectSettings.user_project_name', '')}
                      onChange={e => handleChange('user_project_name', e.target.value)}
                    />
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div style={{ display: "flex" }}>
                      <strong>Safety Acknowledgment Message</strong>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '10px', width: '100%' }}>
                      <TextField
                        name="acknowledgment_en"
                        id="acknowledgment_en"
                        className={classes.accordionTextField}
                        label="English"
                        color="primary"
                        variant="outlined"

                        multiline
                        rows={4}
                        value={get(state.project, 'companyProjectSettings.acknowledgment_en', '')}
                        onChange={e => handleChange('acknowledgment_en', e.target.value)}
                      />
                      <span>{`${size(get(state.project, 'companyProjectSettings.acknowledgment_en', ''))} of 160`}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '20px' }}>

                      <TextField
                        name="acknowledgment_es"
                        id="acknowledgment_es"
                        className={classes.accordionTextField}
                        label="Spanish"
                        color="primary"
                        variant="outlined"
                        multiline
                        rows={4}
                        value={get(state.project, 'companyProjectSettings.acknowledgment_es', '')}
                        onChange={e => handleChange('acknowledgment_es', e.target.value)}
                      />
                      <span>{`${size(get(state.project, 'companyProjectSettings.acknowledgment_es', ''))} of 160`}</span>

                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div style={{ display: "flex" }}>
                      <strong>Worker SMS Services</strong>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ flexDirection: 'column' }}>
                    <div style={{ display: 'flex', marginRight: '10px', width: '100%' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormControl className={classes.radioFormControlWrapper}>
                          <FormLabel>Service</FormLabel>

                          <RadioGroup aria-label="serviceRadio" name="serviceRadio" value={get(state, 'serviceRadio')} onChange={handleServiceRadioChange}>
                            <FormControlLabel value="claim" disabled={get(state, 'isUpdating') || (has(get(state, 'smsServices'), 'claim_punch-in') && has(get(state, 'smsServices'), 'claim_punch-out'))} control={<Radio color='primary' size='small' />} className={classes.radioLabel} label="Submit Claim" />
                            <FormControlLabel value="perdiem" disabled={get(state, 'isUpdating') || (has(get(state, 'smsServices'), 'perdiem_punch-in') && has(get(state, 'smsServices'), 'perdiem_punch-out'))} control={<Radio color='primary' size='small' />} className={classes.radioLabel} label="Request Per Diem" />
                            <div className={classes.perdiemWeekendWrapper} style={{ maxHeight: isEqual( get(state, 'serviceRadio'), 'perdiem') ? 45 : 0 }}>
                              <FormControlLabel value="end" labelPlacement="end"
                                  control={<Checkbox
                                      color='primary'
                                      checked={state.perdiemWeekend.Sat}
                                      onClick={handlePerdiemSat}
                                  />}
                                  label='Sat'
                              />
                              <FormControlLabel value="end" labelPlacement="end"
                                  control={<Checkbox
                                      color='primary'
                                      checked={state.perdiemWeekend.Sun}
                                      onClick={handlePerdiemSun}
                                  />}
                                  label='Sun'
                              />
                            </div>
                            <FormControlLabel value="lunchbreak" disabled={get(state, 'isUpdating') || has(get(state, 'smsServices'), 'lunchbreak_punch-out')} control={<Radio color='primary' size='small' />} className={classes.radioLabel} label="Lunch Break" />
                          </RadioGroup>
                        </FormControl>
                        <FormControl className={classes.radioFormControlWrapper} style={{ marginTop: 20 }}>
                          <FormLabel>Happens at</FormLabel>
                          <RadioGroup aria-label="happensAtRadio" name="happensAtRadio" value={get(state, 'happensAtRadio')} onChange={handleHappensAtRadioChange}>
                            <FormControlLabel value="punch-in" disabled={isEmpty(get(state, 'serviceRadio')) || get(state, 'serviceRadio') === 'lunchbreak' || has(get(state, 'smsServices'), `${get(state, 'serviceRadio')}_punch-in`)} control={<Radio color='primary' size='small' />} className={classes.radioLabel} label="Punch In" />
                            <FormControlLabel value="punch-out" disabled={isEmpty(get(state, 'serviceRadio')) || has(get(state, 'smsServices'), `${get(state, 'serviceRadio')}_punch-out`)} control={<Radio color='primary' size='small' />} className={classes.radioLabel} label="Punch Out" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 30, width: 260 }}>
                        <FormControl fullWidth>
                          <Autocomplete
                            multiple
                            id="daysOfTheWeek"
                            options={['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']}
                            getOptionLabel={(option) => option}
                            getOptionSelected={(option, value) => option === value}
                            value={get(state, 'smsDaysOfTheWeek')}
                            onChange={handleChangeDaysOfTheWeek}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Days of the Week"
                              />
                            )}
                          />
                        </FormControl>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <FormControlLabel
                            control={<SBSwitch checked={get(state, 'smsActive')} handleToggle={handleSmsActiveToggle} name='active' />}
                            label='Active'
                            style={{ marginTop: 5 }}
                          />
                          <div>
                            <Button
                              // style={{ width: 164 }}
                              color='primary'
                              disabled={isEmpty(get(state, 'serviceRadio')) || isEmpty(get(state, 'happensAtRadio')) || isEmpty(get(state, 'smsDaysOfTheWeek', []))}
                              onClick={handleAddUpdateService}>
                              {state.isUpdating ? 'Update ' : 'Add '} Service
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!isEmpty(get(state, 'smsServices', [])) &&
                      <List dense className={classes.itemsList}>
                        <ListSubheader className={classes.servicesHeaderStyle}>
                          <span style={{ width: 75 }}>Service</span>
                          <span>Happens At</span>
                          <span style={{ width: 200 }}>Days</span></ListSubheader>
                        {
                          Object.keys(get(state, 'smsServices')).map(oneKey => get(get(state, 'smsServices'), oneKey)).map((oneService) =>
                            <ListItem button divider key={get(oneService, 'id')} onClick={() => handleServiceClick(oneService)} style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: get(oneService, 'active') ? '#78bd5d6b' : '#dc354538' }}>
                              <div className={classes.listItemStyle}>
                                <span style={{ width: 110 }}>{isEqual(get(oneService, 'service'), 'claim') ? 'Submit Claim' : isEqual(get(oneService, 'service'), 'lunchbreak') ? 'Lunch Break' : 'Request Per Diem'}</span>
                                <span style={{ width: 105 }}>{isEqual(get(oneService, 'at'), 'punch-in') ? 'Punch-In' : 'Punch-Out'}</span>
                                <span style={{ width: 165 }}>{isEqual(size(get(oneService, 'dayOfWeek')), 7) ? 'Every day' : get(oneService, 'dayOfWeek').map(oneDay => <span>{oneDay} </span>)}</span>
                              </div>
                              <ListItemSecondaryAction>
                                <RemoveCircleIcon onClick={() => handleRemoveService(oneService)} style={{ color: "#d0021c", cursor: 'pointer' }} />
                              </ListItemSecondaryAction>
                            </ListItem>
                          )
                        }
                      </List>
                    }
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div style={{ display: "flex" }}>
                      <strong>Work Shift Customization</strong>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ flexDirection: 'column' }}>
                    <div style={{ display: 'flex', marginRight: '10px', width: '100%' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormControl className={classes.radioFormControlWrapper}>
                          <FormLabel>Max Shift Duration</FormLabel>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Slider defaultValue={defaultShiftLength} min={8} max={168} value={get(state.project, 'companyProjectSettings.maxShiftLength', defaultShiftLength)} onChange={(e, value) => handleChange('maxShiftLength', value)} aria-labelledby="maxShiftLength-slider" style={{ width: 300 }} />
                            <Input
                              style={{ marginLeft: 16, marginRight: 8 }}
                              value={get(state.project, 'companyProjectSettings.maxShiftLength', defaultShiftLength)}
                              margin="dense"
                              onChange={handleMaxShiftLengthInputChange}
                              onBlur={handleMaxShiftLengthInputBlur}
                              inputProps={{
                                min: 8,
                                max: 168,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                              }}
                            />
                          <span>hours</span>
                          </div>
                        </FormControl>
                        {/*<FormControl className={classes.radioFormControlWrapper} style={{ marginTop: 20 }}>
                          <FormLabel>Force Check In</FormLabel>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Slider min={1} max={1439} step={5} value={forceCheckInRange} onChange={handleForceCheckinRangeChange} aria-labelledby="forceCheckinRange-slider" style={{ width: 300 }} />
                              <div style={{ display: 'flex', marginLeft: 16 }}>
                                <span style={{ marginRight: 8 }}> from </span>
                                <span style={{ marginRight: 8, fontWeight: 700 }}>{forceCheckInRangeTime(0, 'hh:mm a')}</span>
                                <span style={{ marginRight: 8 }}> to </span>
                                <span style={{ fontWeight: 700 }}>{forceCheckInRangeTime(1, 'hh:mm a')}</span>
                              </div>
                            </div>
                        </FormControl>*/}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div style={{ display: "flex" }}>
                      <strong>Project Custom Display</strong>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ flexDirection: 'column' }}>
                    <div style={{ display: 'flex', marginRight: '10px', width: '100%' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className={classes.crewFilterFormControl}>Timesheet</div>
                        <FormControlLabel
                          style={{ marginLeft: 8 }}
                          control={<SBSwitch checked={get(state, 'project.companyProjectSettings.uiSettings.timesheet.forceCrewFilter', false)} handleToggle={toggleForceCrewFilter} name='crew filter' />}
                          label='Enable Crew Filter'
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div style={{ display: "flex" }}>
                      <strong>Project MobileApp Settings</strong>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ flexDirection: 'column' }}>
                    <div style={{ display: 'flex', marginRight: '10px', width: '100%' }}>
                      <div className={classes.radioFormControlWrapper} style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormControlLabel
                          control={<SBSwitch checked={get(state, 'project.companyProjectSettings.mobileAppSettings.geofence.disabled', false)} handleToggle={toggleGeofence} name='geofence' />}
                          label='Disable Geofence'
                          disabled={get(state, 'resettingGeofence')}
                        />
                        <FormControl style={{ paddingLeft: 8 }}>
                          <TextField
                            label='Geofence Radius (miles)'
                            color='primary'
                            disabled={get(state, 'project.companyProjectSettings.mobileAppSettings.geofence.disabled') || get(state, 'resettingGeofence')}
                            value={get(state, 'project.companyProjectSettings.mobileAppSettings.geofence.radius', 0)}
                            onChange={handleGeofenceRadiusChange}
                            type='number'
                          />
                          <FormHelperText>Click on RESET to clear project geofence</FormHelperText>
                        </FormControl>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1, alignItems: 'flex-end' }}>
                        <Button variant="outlined" style={{ color: 'grey' }} disabled={get(state, 'resettingGeofence')} onClick={handleResetGeofenceSettings}>
                          {get(state, 'resettingGeofence') && <CircularProgress size={20} color="primary" className={classes.resetGeofenceLoader} />}
                          {!get(state, 'resettingGeofence') && <div style={{ display: 'flex', alignItems: 'center' }}>Reset <Tooltip arrow title={resetGeofenceTooltip}><HelpOutlineIcon style={{ fontSize: 16, marginLeft: 4 }} /></Tooltip></div>}
                        </Button>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <div style={{ margin: 12 }}></div>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >

                    <div style={{ display: "flex" }}>
                      <img src="https://s3.us-west-2.amazonaws.com/static-files.connectedbarrel.com/project-settings/Wallmount.svg" width='20px' height="20px" />
                      <strong>Devices</strong>
                    </div>
                  </AccordionSummary>
                  {/* <AccordionDetails> */}

                  <div className="table-responsive-material table-userdetail-mmin" id="jumbo-styles-container">
                    <table className={`default-table table-sm table full-table mb-0 full-table-last-sm`}>
                      <tbody>
                        {get(state.project, 'devices', []).map((data, key) => {
                          return (
                            <tr
                              tabIndex={-1}
                              key={key}>
                              <td className="border-bottom border-top-0">
                                <div className="user-profile d-flex flex-row align-items-center">
                                  <div className="user-detail">
                                    <h5 className="user-name text-capitalize">{data.name}</h5>
                                    <p className="user-description">{has(data, 'last_sync_at') ? moment(get(data, 'last_sync_at', '')).format('MM-DD-YYYY') : ''}</p>
                                  </div>
                                </div>
                              </td>
                              <td className="text-right border-bottom border-top-0">
                                <span className={` badge text-white text-uppercase bg-green`}>Active</span>
                              </td>

                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>


                </Accordion>
              </div>
            </>
          }
        </div>
        <div className={classes.drawerActionButtonsWrapper}>
          <Button
            variant="outlined"
            onClick={() => onShowSettings(false)}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            disabled={state.editing}
            style={{ marginLeft: 8 }}
            onClick={onSave}
            color='primary'
            startIcon={state.editing && <CircularProgress size={10} color="primary" />}>
            Save
          </Button>
        </div>
      </div>
    </Drawer>
  )
};
