import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormControlLabel, InputLabel, makeStyles, MenuItem, Select, TextField, FormHelperText } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { get, isNull, size } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getProjectByCompany } from '../../../services/employeeService';
import { SBSwitch } from '../../../../reusableComponents';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: 20
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    padding: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600
  },
}));

export default function UserSection(props) {

  const { worker, projectEndpoint, onChangeBasic, companyProjects, companyCrews, project_ids, crew_ids, updateState, userTypes } = props;

  const [selectedProjects, setSelectedProjects] = useState([]);
  const [helperText, setHelperText] = useState('');
  // const [selectedCrews, setSelectedCrews] = useState([]);

  const toggleIsReadOnly = () => {
    onChangeBasic('readOnly', !get(worker, 'readOnly'))
  }

  const classes = useStyles();

  useEffect(() => {
    let array = []

    project_ids.map(oneProjectId => {
      let found = companyProjects.find(oneProject => get(oneProject, 'value') === oneProjectId)

      if (found) array.push({ value: get(found, 'value'), label: get(found, 'label') })
    })

    userTypes.map(oneUserType => {
      if (get(oneUserType, 'value') === get(worker, 'user_type', '')) {
        setHelperText(get(oneUserType, 'description'))
      }
    })

    setSelectedProjects(array)
  }, [])

  // useEffect(() => {
  //   let array = []
  //
  //   crew_ids.map(oneCrewId => {
  //     let found = companyCrews.find(oneCrew => get(oneCrew, 'value') === oneCrewId)
  //
  //     if (found) array.push({ value: get(found, 'value'), label: get(found, 'label') })
  //   })
  //
  //   setSelectedCrews(array)
  // }, [])

  const onChangeProjects = (newValue) => {
    setSelectedProjects(newValue);
    const aux = newValue.map(item => get(item, 'value'));
    onChangeBasic('project_ids', aux)
  }

  // const onChangeCrews = (newValue) => {
  //   setSelectedCrews(newValue);
  //   const aux = newValue.map(item => get(item, 'value'));
  //   onChangeBasic('crew_ids', aux)
  // }

  const onChangeUserType = (item) => {
    onChangeBasic('user_type', item.value);
    onChangeBasic('permissions', item.template.permissions)
    setHelperText(item.description);



    if (item.value === 'admin') {
      onChangeBasic('project_ids', []);
      setSelectedProjects([])
    }
  }

  return (
    <div className={classes.root}>
      <Accordion style={{ backgroundColor: "#f3f3f4" }}>
        <AccordionSummary
          className={classes.heading}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          User Settings
        </AccordionSummary>
        <AccordionDetails >
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">User Type</InputLabel>
              <Select value={get(worker, 'user_type', '')}>
                {userTypes.map((item, idx) => (
                  <MenuItem key={idx} onClick={() => onChangeUserType(item)} value={get(item, 'value')}>{get(item, 'label')}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{helperText}</FormHelperText>
            </FormControl>

            <Autocomplete
              multiple
              disableCloseOnSelect
              filterSelectedOptions={true}
              disabled={!get(worker, 'user_type') || get(worker, 'user_type') === 'admin' || !size(companyProjects)}
              id="tags-standard"
              size="small"
              style={{ margin: '8px' }}
              options={companyProjects}
              value={selectedProjects}
              onChange={(e, t) => onChangeProjects(t)}
              getOptionSelected={(option, value) => option.value === value.value}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Projects"
                  placeholder="Select..."
                />
              )}
            />
            {/*<Autocomplete
              multiple
              filterSelectedOptions={true}
              disabled={!size(companyCrews)}
              id="tags-standard"
              size="small"
              style={{ margin: '8px' }}
              options={companyCrews}
              value={selectedCrews}
              onChange={(e, t) => onChangeCrews(t)}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Crews"
                  placeholder="Select..."
                />
              )}
            />*/}
            {
              get(worker, 'user_type', '') === 'user' &&
              <FormControl>
                <FormControlLabel
                  control={<SBSwitch color="primary" handleToggle={toggleIsReadOnly} checked={get(worker, 'readOnly', false)} />}
                  label="Read-Only"
                  style={{ paddingLeft: 10 }}
                />
              </FormControl>
            }
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
};
