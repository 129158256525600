import React, { useState, useEffect, useContext } from 'react';

import { get } from 'lodash';
import { SettingsContext } from '../../../../contexts/SettingsContext';
import Select from 'react-select';

export default function UserTypes(props) {

  const { filter, onFilter, selectStyles, userTypes } = props;
  const [value, setValue] = useState({ value: 'all', label: 'All' });

  const settingsContext = useContext(SettingsContext);

  const onLocalChange = (e) => {
    settingsContext.onCleaningFilter(false)
    setValue(e);
    onFilter(get(filter, 'key'), get(e, 'value'));
  }

  useEffect(() => {
    if (settingsContext.clearFilter)
      setValue({ value: 'all', label: 'All' });
  }, [settingsContext.clearFilter])


  return (
    <div className='d-flex justify-content-center align-items-center mr-4' style={{ marginTop: `${get(settingsContext, 'isMobile') ? '10px' : '0px'}` }}>
      <Select
        onChange={onLocalChange}
        options={[
          { value: 'all', label: 'All' }
        ].concat(userTypes)}
        placeholder='User Type'
        value={value}
        styles={selectStyles}
      />
    </div>
  )
};
