import React, { useCallback, useState, useLayoutEffect, useEffect } from 'react';
import axios from 'axios';
import CertificationList from './CertificationList';
import CertificationNew from './CertificationNew';
import CertificationWizard from './CertificationWizard';


const UploadCertification = ({ employeeId, dbid, tags, baseUrl, company }) => {

  const [certs, setCerts] = useState([])

  useEffect(() => {
    loadCertifications()
    return () => { }
  }, [])


  const loadCertifications = () => {
    const token = document.querySelector("input[name='aws_token'").value

    axios.get(`${baseUrl}/workers/${dbid}/certifications`, { headers: { Authorization: `Bearer ${token}` } }).then(response => {
      setCerts(response.data)
    })
  }

  const reload = () => {
    setTimeout(() => {
      loadCertifications()
    }, 3000);
  }

  return (
    <>
      <CertificationList certs={certs} baseUrl={baseUrl} dbid={dbid} onDelete={loadCertifications} tagOptions={tags} />
      {/* <CertificationNew expires={false} baseUrl={baseUrl} onCreate={reload} employeeId={employeeId} dbid={dbid} /> */}
      <CertificationWizard baseUrl={baseUrl} company={company} employeeId={employeeId} onNewCertification={loadCertifications} dbid={dbid}  />
    </>
  );
}

export default UploadCertification;