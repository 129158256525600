import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Button, makeStyles, TextField } from '@material-ui/core';
import SyncAltOutlined from "@material-ui/icons/SyncAltOutlined";
import moment from 'moment';
import { getDashboardCMICTimesheetStatus, syncCMICTimesheets, syncingCMICCostCodes, sync } from '../../workers/services/services'
import { notificationCenter } from '../../helpers/notifications';
import { syncWorkersCMIC, getRevokeCMICWorkerURL } from '../services';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
const W3CWebSocket = require('websocket').w3cwebsocket;

const useStyles = makeStyles(theme => ({
  button: {
    padding: "3px 10px !important",
    marginRight: '10px !important',
    textTransform: 'capitalize',
    borderRadius: '4px !important'
  },
  textFieldUrl: {
    height: '20px',
    width: "300px",
    marginRight: "14px"
  }
}))
export default function SyncTimeSheetCMICDate(props) {

  const { webSocketsUrl, integrations_endpoint, project, company, timesheet_date, user, project_integrations_endpoint } = props;

  const classes = useStyles();



  const [timesheetStatus, setTimesheetStatus] = useState(false);
  const [syncDialog, setSyncDialog] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [syncingCostCodes, setSyncingCostCodes] = useState(false);


  useEffect(() => {
    checkStatus()
    openWebSocketConnection()
  }, [])


  const openWebSocketConnection = () => {

    let client = new W3CWebSocket(`${webSocketsUrl}?userId=${_.get(user, '_id.$oid')}&companyId=${_.get(user, 'company_ids[0].$oid')}`);

    client.onopen = (e) => {
      console.log("OPEN ", e);
    };

    client.onclose = (e) => {
      console.log("ONCLOSE ", e);
      client = null;

      openWebSocketConnection();
    };

    client.onmessage = (e) => {

      const res = JSON.parse(_.get(e, "data"));

      console.log(res);

      switch (_.get(res, 'section.key')) {
        case "timesheetStatus":
          notificationCenter('success', "Timesheets synced")
          setTimesheetStatus(false);
          break;

        case 'costcodesSynced':
          notificationCenter('success', "Costcodes synced")
          setSyncingCostCodes(false);
          break;

        case 'notCostCode':
          notificationCenter('error', _.get(res, 'section.status'))
          setTimesheetStatus(false);
          break;

        case 'notSynced':
          notificationCenter('error', _.get(res, 'section.status'))
          setTimesheetStatus(false);
          break;

        case 'notPayrollId':
          notificationCenter('error', _.get(res, 'section.status'))
          setTimesheetStatus(false);
          break;

        default:
          break;
      }

    };
  };

  const checkStatus = async () => {
    try {
      let payload = {
        timesheet_date: sessionStorage.getItem(_.get(project, '_id.$oid'))
      }
      const res = await getDashboardCMICTimesheetStatus(integrations_endpoint, payload);
      if (_.get(res, 'data.status') === "SUCCEEDED" || _.get(res, 'data.status') === "FAILED")
        setTimesheetStatus(false);

    } catch (error) {
      // errorHandle(error)
      notificationCenter('error', _.get(error, 'response.data.error', "Error"))

    }
  };

  const onSyncCostCodes = async () => {
    try {
      setSyncingCostCodes(true);
      await syncingCMICCostCodes(integrations_endpoint, _.get(project, 'integrations.cmic'));
    } catch (error) {
      notificationCenter('error', _.get(error, 'response.data.error', "Error"))
    }
  }

  const onSyncTimesheets = async () => {
    try {
      setSyncing(true);

      let payload = {
        project: {
          _id: _.get(project, '_id.$oid'),
          timezone: _.get(project, 'timezone')
        },
        timesheet_date: sessionStorage.getItem(_.get(project, '_id.$oid'))
      };
      const res = await syncCMICTimesheets(integrations_endpoint, payload);
      setSyncing(false);
      setSyncDialog(false);

    } catch (error) {
      setSyncing(false);
      notificationCenter('error', _.get(error, 'response.data.error', "Error"))
    }
  };
  const onSyncDialog = () => {
    setTimesheetStatus(true);
    setSyncDialog(true)

  }

  const onCloseSyncDialog = () => {
    setSyncDialog(false);
    setTimesheetStatus(false);
  }


  return (
    <>

      <Button
        variant="contained"
        color="default"
        onClick={() => onSyncCostCodes()}
        disabled={syncingCostCodes}
        className={classes.button}
        startIcon={<SyncAltOutlined />}
      >
        Sync Costcodes
      </Button>
      <Button
        variant="contained"
        color="default"
        onClick={() => onSyncDialog()}
        disabled={timesheetStatus}
        className={classes.button}
        startIcon={<SyncAltOutlined />}
      >
        Sync Timesheet
      </Button>

      {syncDialog &&
        <Dialog
          open={syncDialog}
          onClose={() => onCloseSyncDialog()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Pushing all the timecard records for ${_.get(project, 'name')} on the ${moment(sessionStorage.getItem(_.get(project, '_id.$oid'))).format('MM-DD-YYYY')}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onCloseSyncDialog()} color="primary">
              Cancel
            </Button>
            <Button onClick={onSyncTimesheets} color="primary" disabled={syncing} >
              {syncing ? <CircularProgress size={15} color="primary" /> : "Yes"}
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  )
};
