import React, { useState, useEffect } from 'react'
import { get, size, isEmpty, has, isEqual } from 'lodash'
import { DateTime } from 'luxon'
import { makeStyles } from '@material-ui/core/styles'

import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import Autocomplete from '@material-ui/lab/Autocomplete'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import { MuiPickersUtilsProvider, KeyboardDatePicker, TimePicker } from '@material-ui/pickers'
import LuxonUtils from '@date-io/luxon'

import Close from "@material-ui/icons/Close"
import ClockIcon from "@material-ui/icons/AccessTime"

export default function BulkActionDialog(props) {
  const { classes, showBulkActionDialog, cancelBulkActionDialog, confirmBulkActionDialog, productivityView } = props

  const [newPunchObject, setNewPunchObject] = useState({})
  const [selectedBulkAction, setSelectedBulkAction] = useState('')
  const [note, setNote] = useState('')

  const handleCancelBulkActionDialog = () =>  {
    setNewPunchObject({})
    setNote('')
    cancelBulkActionDialog()
  }

  const handleConfirmBulkActionDialog = () =>  {
    // TODO: call function for api
    // TODO: switch case based on selected mode
  }

  const handleSelectBulkAction = (event) => {
    setSelectedBulkAction(event.target.value);
  };

  const handlePunchTypeToggle = () => {
    setNewPunchObject(prevNewPunchObject => ({
      ...prevNewPunchObject,
      type: isEqual( get( prevNewPunchObject, 'type'), 'in') ? 'out' : 'in'
    }))
  };

  const handleNewWorkerPunchDateChange = (date) => {
    setNewPunchObject(prevNewPunchObject => ({
      ...prevNewPunchObject,
      date,
    }))
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value)
  };

  const disableConfirmBulkAction = () => {
    switch (selectedBulkAction) {
      // case 'approveAll':
      //   return false
      //   break
      case 'applyPunchTime':
        return isEmpty( note.trim())
        break
      default:
        return false
    }
  };

  useEffect(() => {
    return () => { }
  }, [])

  const renderPunchDateAndTimeNote = () =>
    <div>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <div style={{ padding: '8px 16px 8px 16px', display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <KeyboardDatePicker
            style={{ width: 250 }}
            disableToolbar
            inputVariant="outlined"
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="punch-date-picker"
            label="Select Date"
            value={get( newPunchObject, 'date')}
            onChange={handleNewWorkerPunchDateChange}
            KeyboardButtonProps={{
              'aria-label': 'select date',
            }}
          />
      <TimePicker
            style={{ width: 250 }}
            format="hh:mm a"
            variant="inline"
            inputVariant="outlined"
            margin="normal"
            id="time-picker"
            label="Select Time"
            value={get( newPunchObject, 'date')}
            onChange={handleNewWorkerPunchDateChange}
            keyboardIcon={<ClockIcon />}
            // keyboardIcon={<ClockIcon color='primary' />}
            KeyboardButtonProps={{
              'aria-label': 'select time',
            }}
          />
      </div>
      <div style={{ padding: '0px 16px 16px 16px', display:"flex", alignItems:"center" }}>
        <TextField
          onChange={handleNoteChange}
          rows={2}
          style={{ width: '100%' }}
          id="outlined-basic"
          variant="outlined"
          label="Note"
          value={note}
          // value={get( newPunchObject, 'note')}
          multiline
          />
      </div>
      </MuiPickersUtilsProvider>
    </div>

  return (
    <Dialog maxWidth='sm' open={showBulkActionDialog} onClose={handleCancelBulkActionDialog} fullWidth>
        <DialogTitle disableTypography={true} className={classes.newWorkerPunchDialogTitle}>
          <Typography variant="h6">Bulk Action</Typography>
          <IconButton
            onClick={handleCancelBulkActionDialog}
            size='small'
            className={classes.closeModalButton}>
              <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.newWorkerPunchDialogBody} style={{ position: 'relative' }}>
          <div style={{ width: '100%', height: 40, zIndex: 0, position: 'absolute', top: 0, left: 0, backgroundColor: "#f6f5f7" }} />
          <Paper style={{ zIndex: 2 }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: "2px solid #f6f5f7", padding: 16 }}>
              <FormControl>
                  <Select
                    displayEmpty
                    variant='outlined'
                    labelId='select-worker'
                    id='select-worker'
                    name='select-worker'
                    value={selectedBulkAction}
                    onChange={handleSelectBulkAction}
                    style={{ height: '40px', width: '252px' }}>
                    { isEmpty(selectedBulkAction) &&
                      <MenuItem value="">
                        Select Action
                      </MenuItem>
                    }
                    { productivityView &&
                      <MenuItem value="applyCostCodes">
                        Apply Cost Code(s)
                      </MenuItem>
                    }
                    { !productivityView &&
                      <MenuItem value="approveAll">
                        Approve All
                      </MenuItem>
                    }
                    { !productivityView &&
                      <MenuItem value="applyPunchTime">
                        Apply Punch Time
                      </MenuItem>
                    }
                  </Select>
              </FormControl>
              { selectedBulkAction === "applyPunchTime" &&
                <ToggleButtonGroup
                  value={get( newPunchObject, 'type')}
                  exclusive
                  onChange={handlePunchTypeToggle}
                  aria-label="punch-type-toggle"
                  style={{ height: '40px' }}>
                  <ToggleButton value="in" aria-label="punch-in-toggle" style={{ width: '60px', color: get( newPunchObject, 'type') === 'in' ? '#f15922' : null }}>
                    IN
                  </ToggleButton>
                  <ToggleButton value="out" aria-label="punch-out-toggle" style={{ width: '60px', color: get( newPunchObject, 'type') === 'out' ? '#f15922' : null }}>
                    OUT
                  </ToggleButton>
                </ToggleButtonGroup>
              }
            </div>
            { selectedBulkAction === "applyPunchTime" && renderPunchDateAndTimeNote() }
            { selectedBulkAction === "approveAll" &&
              <div style={{ padding: 16 }}>
                Are you sure you want to approve the selected shifts?
              </div>
            }
          </Paper>
        </DialogContent>
        <DialogActions className={classes.newWorkerPunchDialogActions}>
          <Button variant="outlined" onClick={handleCancelBulkActionDialog}>
            Cancel
          </Button>
          <Button disabled={disableConfirmBulkAction()} variant="outlined" onClick={handleConfirmBulkActionDialog} color="primary">
            Confirm
          </Button>
        </DialogActions>
    </Dialog>
  )
}
