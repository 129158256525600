import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';

export const DeviceSnapshotList = ({ snapshots = [] }) => {

  return (<>
    {snapshots.map((item) =>
        <Accordion key={item._id.$oid}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{moment.unix(item.timestamp).format('lll')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <pre className="w-100">{JSON.stringify(item, null, 2)}</pre>
          </AccordionDetails>
        </Accordion>)
    }
  </>);
};
