import React from 'react';
import SyncTimeSheetCMICDate from './SyncTimeSheetCMICDate';
import { CardDash } from './CardDash';

export default function SyncTimeSheetsCMIC(props) {
  const { integrations_endpoint, webSocketsUrl, project, company, timesheet_date, user, project_integrations_endpoint } = props;

  const cmicLogo = 'https://s3.us-west-2.amazonaws.com/static-files.connectedbarrel.com/integrations-logos/cmicLogo.png';

  return (
    <CardDash
      expandable
      accordionDetailStyles={{ padding: 0 }}
      defaultExpanded={false}
      title={<img width="100px" src={cmicLogo} alt="CMIC" />}
      actions={
        <SyncTimeSheetCMICDate
          project_integrations_endpoint={project_integrations_endpoint}
          integrations_endpoint={integrations_endpoint}
          webSocketsUrl={webSocketsUrl}
          user={user}
          project={project}
          company={company}
          timesheet_date={timesheet_date}
        />
      }
    />
  );
};
3
