import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useContext, useEffect, useState } from 'react';
import Tab from '@material-ui/core/Tab';
import { ProjectContext } from '../ProjectMetrics';
import Skeleton from '@material-ui/lab/Skeleton';
import { get } from 'lodash';

const percentStyle = makeStyles(() => ({
  root: { position: 'relative' },
  bottom: { color: '#ececec' },
  top: { color: '#f15924', animationDuration: '1000ms', position: 'absolute', left: 0 },
  circle: { strokeLinecap: 'round' },
}));

export const CustomTab = ({ id, title = 'custom title', loading = false, items = [], fillColor, fillBackgroundColor }) => {
  const classes = percentStyle();
  const absoluteCenter = {
    margin: 0,
    display: 'flex',
    position: 'absolute',
    inset: 0,
    alignItems: 'center',
    justifyContent: 'center',
  };
  const { tab } = useContext(ProjectContext);

  const isActive = () => tab === id;

  const [circleCurrent, setCircleCurrent] = useState(0);
  const [circleTotal, setCircleTotal] = useState(items.length || []);
  const [circlePercent, setCirclePercent] = useState(0);

  useEffect(() => {
    const current = items.reduce((total, company) => {
      return total + get(company, 'totalItems', 0);
    }, 0);
    const total = items.length;

    setCircleCurrent(current);
    setCircleTotal(total);
    setCirclePercent(current / total * 100);

  }, [items]);

  return (
    <Grid alignItems={'center'} style={{ gap: '2em' }} container>
      <Grid xs={true} style={{ width: '100px' }} item>
        <Typography variant="h5" component="div"
          className={`text-left ${isActive() ? 'text-deep-orange' : 'text-body'}`}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={'auto'} style={{ position: 'relative' }}>
        <Typography className={'text-left'} style={absoluteCenter}>
          {loading
            ? <Skeleton width="35px" animation="wave" />
            : <>
              <strong>{circleCurrent}</strong>
              <span>/</span>
              <strong>{circleTotal}</strong>
            </>
          }
        </Typography>
        <div className={classes.root}>
          <CircularProgress
            variant="determinate"
            className={classes.bottom}
            size={80}
            thickness={7}
            value={100}
            style={{ color: fillBackgroundColor }}
          />
          <CircularProgress
            variant={loading ? 'indeterminate' : 'determinate'}
            disableShrink={loading}
            className={classes.top}
            classes={{ circle: classes.circle }}
            size={80}
            value={circlePercent}
            thickness={7}
            style={{ color: fillColor }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

const SectionTab = (props) => {

  const { value, children, isActive, isLeftMostTab, isRightMostTab, borderColor, tabBackgroundColor } = props;
  const { tab, setTab } = useContext(ProjectContext);
  const handleChange = () => setTab(tab === value ? '' : value);

  return <Tab
    {...props}
    value={value}
    icon={children}
    style={{
      borderRadius: isActive ? '4px 4px 0px 0px' : isLeftMostTab ? '4px 0px 0px 4px' : isRightMostTab ? '0px 4px 4px 0px' : 0,
      color: borderColor,
      border: isActive ? `2px solid ${borderColor}` : '2px solid #c4c4c42b',
      padding: '1em',
      paddingTop: isActive ? 'calc(1em - 2px)' : '1em',
      borderBottom: isActive ? 0 : '2px solid #c4c4c42b',
      backgroundColor: isActive ? tabBackgroundColor ? tabBackgroundColor : 'white' : 'transparent'
    }}
    onClick={handleChange}
  />;
};

export default SectionTab;
