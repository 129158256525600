import React, { useState } from 'react'
import { Button, Modal } from "react-bootstrap";
import axios from "axios"
import moment from 'moment';

const CompanyDetails = ({ company, createdBy }) => {

  console.log("CompanyDetails", { company });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleOpen = (ev) => {
    ev.preventDefault()
    setShow(true)
  };

  if (!show) {
    return <i className="fa fa-info" onClick={handleOpen}></i>
  } else {
    const createdAt = moment(company.created_at).format("MM/DD/YYYY h:mm A")
    const createdByStr = createdBy ? `${createdBy.first_name} ${createdBy.last_name}` : ""
    return (
      <>
        <i className="fa fa-info" onClick={handleOpen}></i>
        <Modal show={show} onHide={handleClose} size="lg" animation={false} backdrop={true} backdropClassName="modal-backdrop fade in">
          <Modal.Header closeButton>
            <Modal.Title><strong>{company.name}</strong> </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <table className="table" style={{ whiteSpace: "nowrap" }}>
              <thead>
                <tr>
                  <th scope="col">C-DBID</th>
                  <th scope="col">EIN</th>
                  <th scope="col">Company ID</th>
                  <th scope="col">Created By</th>
                  <th scope="col">Created Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="col">{company._id.$oid}</th>
                  <th scope="col">{company.ein}</th>
                  <th scope="col">{company.dbid}</th>
                  <th scope="col">{createdByStr}</th>
                  <th scope="col">{createdAt}</th>
                </tr>
              </tbody>
            </table>


          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default CompanyDetails;