import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useContext, useState } from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { axiosConfig } from '../../../../../helpers';
import { ProjectContext } from '../../ProjectMetrics';
import { CircularProgress, makeStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { get } from 'lodash'
import CloseIcon from '@material-ui/icons/Close';

const statusMapping = {
  open: "Open",
  closed: "Closed",
  claim_opened: "Claim Opened"
}

export const DialogChangeStatus = ({ item, onClose }) => {

  const { _id } = item;


  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(get(item, 'status', 'open'));
  const [saving, setSaving] = useState(false);
  const [comment, setComment] = useState('');
  const { config: { project_workSummary_endpoint: endpoint }, project } = useContext(ProjectContext);

  const handleClose = () => {

    setStatus(get(item, 'status', 'open'))
    setOpen(false);


    return onClose && onClose(false);
  };

  const onSetStatus = value => {

    setStatus(value);
    setOpen(true);

  }

  const handleSubmit = async () => {
    setSaving(true);

    const uri = `${endpoint}/${_id}`;
    const data = { status, type: 'incident', comment, projectId: get(project, '_id.$oid') };

    await axios.patch(uri, data, axiosConfig());

    setSaving(false);
    setOpen(false)
  };

  return <>
    <Select className="navbar-collapse" variant="outlined" value={status} onChange={(e) => onSetStatus(e.target.value)}>
      <MenuItem value="open" style={{ backgroundColor: "#fdeded" }}>
        Open
      </MenuItem>
      <MenuItem value="claim_opened" style={{ backgroundColor: "#e5f6fd" }}>
        Claim Opened
      </MenuItem>
      <MenuItem value="closed" style={{ backgroundColor: "#edf7ed" }}>
        Closed
      </MenuItem>
    </Select>
    <Dialog open={open} disableBackdropClick onClose={handleClose}>
      <DialogTitle >
        <div style={{ display: 'flex', width: "100%", justifyContent: 'space-between', alignItems: 'center' }}>
          <b>Change status</b>
          <CloseIcon style={{ cursor: 'pointer', color: '#a7a7a7' }} onClick={handleClose} />
        </div>

      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Add a comment to register as part of the new status <b>{statusMapping[status]}</b>
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          multiline
          rows={2}
          label="Comment"
          fullWidth
          variant="standard"
          onChange={({ target }) => setComment(target.value)} />
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose}>Cancel</Button> */}
        <Button color="primary" onClick={handleSubmit}> {saving ? <CircularProgress size={20} /> : 'Save'} </Button>
      </DialogActions>
    </Dialog>
  </>;
};
