import axios from 'axios';
import { axiosConfig } from '../../helpers/index';
import { get } from 'lodash';

export const gettingCompanyProfiles = (companyId, payload, payment_profile_endpoint) => {
  return axios.post(`${payment_profile_endpoint}/${companyId}/list/table`, payload, axiosConfig())
};

export const saveCompanyProfiles = (companyId, payload, payment_profile_endpoint) => {
  return axios.post(`${payment_profile_endpoint}/${companyId}`, payload, axiosConfig())
};

export const editCompanyProfiles = (companyId, profileId, payload, payment_profile_endpoint) => {
  return axios.patch(`${payment_profile_endpoint}/${companyId}/${profileId}`, payload, axiosConfig())
};

export const deleteCompanyProfiles = (companyId, profileId, payment_profile_endpoint) => {
  return axios.delete(`${payment_profile_endpoint}/${companyId}/${profileId}`, axiosConfig())
};