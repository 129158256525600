import React from 'react'

export default function Editable(props) {
    const { onEdit } = props
    return (
        <span style={{cursor: "pointer"}} onDoubleClick={onEdit}>
            {props.children}
        </span>
    )
}
