import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import { forkJoin, from, of } from 'rxjs';
import { catchError, concatMap, switchMap } from 'rxjs/operators';
import { unstable_batchedUpdates } from 'react-dom';
import { cloneDeep, get, has, isEmpty, isEqual, isNull, keysIn, size } from 'lodash';
import Axios from 'axios';
import { DateTime } from 'luxon';
import { axiosConfig } from '../../../helpers';

import { CompanyTimesheet, MigrateShiftToProject } from './components/index';
import { CardDash, DatePickerComponent } from '../../components/index';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteIcon from '@material-ui/icons/Delete';
import Close from '@material-ui/icons/Close';

import { SettingsContext } from '../../contexts/SettingsContext';

import { makeStyles } from '@material-ui/core/styles';

import useAutocomplete from '@material-ui/lab/useAutocomplete';

import { notificationCenter } from '../../../helpers/notifications';
import { SBSwitch } from '../../../reusableComponents';
import GaugeMeter from '../../../reusableComponents/GaugeMeter';
import Grid from '@material-ui/core/Grid';

const ProjectTimesheet = (props) => {

  const { project, employeeCompany, config, clearTimesheetTrigger, user } = props;
  const { project_endpoint, timesheetEndpoint, classifiersEndpoint, workersEndpoint, employeeSettingsEndpoint } = config;

  const useStyles = makeStyles((theme) => ({
    deletePunchMenuItem: {
      fontSize: 30,
      '&:hover': {
        color: '#dc3545',
        '& $deletePunchMenuItem': {
          color: '#dc3545',
        },
      },
    },
    dialogTitle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '17px',
      backgroundColor: '#f6f5f7',
      transition: 'height 0.15s ease-out',
    },
    dialogBody: {
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingTop: 0,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    dialogActions: {
      justifyContent: 'flex-end',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    closeModalButton: {
      zIndex: 2,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    projectsDropDownList: {
      width: '100%',
      padding: 0,
      position: 'absolute',
      listStyle: 'none',
      backgroundColor: '#fff',
      overflow: 'auto',
      maxHeight: 200,
      borderRadius: 4,
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      zIndex: 999999,

      '& li': {
        padding: '5px 12px',
        display: 'flex',
        justifyContent: 'space-between',
      },

      '& li[data-focus="true"]': {
        backgroundColor: 'rgba(241, 89, 34, 0.1)',
        cursor: 'pointer',
      },
    },
    smallAvatar: {
      width: 50,
      height: 50,
      borderRadius: '50%',
      minWidth: 50,
      minHeight: 50,
      marginRight: 16
    },
    pleaseSelectCrewWrapper: {
      textAlign: 'center',
      fontWeight: 600,
      fontSize: '1.5rem',
      color: '#666',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 16,
      marginBottom: 8,
      // marginTop: 32,
      // marginBottom: 8,
    },
    crewButton: {
      padding: 0,
      backgroundColor: '#e3e3e3',
      borderRadius: 4
    },
    crewGridItem: {
      marginBottom: 16,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    crewButtonInner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    noCrewButtonInner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 55,
      width: 210
    },
    crewButtonMembersWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      fontSize: '2.8rem',
      width: 70,
      height: 55,
      borderRadius: '3px 0px 0px 3px',
      letterSpacing: -1,
      fontWeight: 600
    },
    crewButtonNameWrapper: {
      paddingLeft: 16,
      paddingRight: 8,
      color: '#6e6e6e',
      width: 140,
      fontSize: '1.2rem',
      textAlign: 'left',
      fontWeight: 600,
      lineHeight: '16px'
    },
  }));

  const classes = useStyles();

  const settingsContext = useContext(SettingsContext);

  const [preloadedCompanies, setPreloadedCompanies] = useState({});
  const [preloadedCostCodes, setPreloadedCostCodes] = useState([]);
  const [unblockDatePicker, setUnblockDatePicker] = useState(false);
  const [timesheetData, setTimesheetData] = useState({});
  const [classifiers, setClassifiers] = useState([]);
  const [hideEmptyTimesheets, setHideEmptyTimesheets] = useState(false);
  const [preloadLoading, setPreloadLoading] = useState(false);

  const [popoverId, setPopoverId] = useState('');
  const [popoverPosition, setPopoverPosition] = useState(null);
  const [popoverData, setPopoverData] = useState({});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [showMigrateShiftToProjectDialog, setShowMigrateShiftToProjectDialog] = useState(false);
  const [actionPayload, setActionPayload] = useState(null);
  const [selectedCrew, setSelectedCrew] = useState(null);

  const pageSize = get(settingsContext, 'uiConfig.ui.modules.timesheet.pageSize', 15);

  const preloadSubscription = React.useRef();
  const loadClassifiersSubscription = React.useRef();
  const loadInitialSubscription = React.useRef();
  const updates = React.useRef(0);

  const handleDateChange = (date) => {
    initPreload();
  };

  const clearTimesheetData = () => {
    setPreloadedCompanies({});
    setPreloadedCostCodes([]);
    setTimesheetData({});
    settingsContext.updateStats({ workers: { current: 0, total: 0 }, companies: { current: 0, total: 0 } });
  };

  useEffect(() => {
    handleDateChange();
  }, [settingsContext.timesheetDate]);

  useEffect(() => {
    if (!isNull(clearTimesheetTrigger)) {
      clearTimesheetData();
    }
  }, [clearTimesheetTrigger]);

  const handleReloadTimesheet = () => {
    clearTimesheetData();
    initPreload();
    settingsContext.updateStats({ workers: { current: 0, total: 0 }, companies: { current: 0, total: 0 } });
  };

  const selectAllShifts = (companyId, productivityView, unselectAll) => {
    setTimesheetData(prevTimesheetData => ({
      ...prevTimesheetData,
      [companyId]: prevTimesheetData[companyId].map(oneWorker => ({
        ...oneWorker,
        shifts: oneWorker.shifts.map(oneShift => ({
          ...oneShift,
          isSelected: ((!productivityView || (has(oneShift, 'punchInInfo') && has(oneShift, 'punchOutInfo'))) && !oneShift.approved && !unselectAll) ? true : false,
        })),
      })),
    }));
  };

  const selectShift = (shiftId, workerId, companyId) => {
    setTimesheetData(prevTimesheetData => ({
      ...prevTimesheetData,
      [companyId]: prevTimesheetData[companyId].map(oneWorker =>
        oneWorker._id === workerId ? {
          ...oneWorker,
          shifts: oneWorker.shifts.map(oneShift =>
            oneShift._id === shiftId ? {
              ...oneShift,
              isSelected: !oneShift.isSelected,
            } : oneShift,
          ),
        } : oneWorker,
      ),
    }));
  };

  const toggleShiftAttribute = (attribute, shift, workerId, companyId) => {
    setTimesheetData(prevTimesheetData => ({
      ...prevTimesheetData,
      [companyId]: prevTimesheetData[companyId].map(oneWorker =>
        oneWorker._id === workerId ? {
          ...oneWorker,
          workerRowLoading: true,
          shifts: oneWorker.shifts.map(oneShift =>
            oneShift._id === shift._id ? {
              ...oneShift,
              [`${attribute}_loading`]: true,
            } : oneShift,
          ),
        } : oneWorker,
      ),
    }));

    let apiURL = `${timesheetEndpoint}/shift/${shift._id}/${get(project, '_id.$oid')}`;
    let apiOptions = { [attribute]: !shift[attribute] };

    Axios.patch(apiURL, apiOptions, axiosConfig()).then(response => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
            shifts: oneWorker.shifts.map(oneShift =>
              oneShift._id === shift._id ? {
                ...oneShift,
                [attribute]: response.data[attribute],
                [`${attribute}_loading`]: false,
              } : oneShift,
            ),
          } : oneWorker,
        ),
      }));

    }).catch(error => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
            shifts: oneWorker.shifts.map(oneShift =>
              oneShift._id === shift._id ? {
                ...oneShift,
                [`${attribute}_loading`]: false,
              } : oneShift,
            ),
          } : oneWorker,
        ),
      }));
      notificationCenter('error', `Error toggling worked lunch break. ${get(error, 'response.data.message', '')}`);
    });
  };

  const editPunchAttributes = (payload, punchData, shiftId, workerId, companyId) => {
    let punchType = get(punchData, 'type') === 'punch-in' ? 'punchInInfo' : 'punchOutInfo';

    setTimesheetData(prevTimesheetData => ({
      ...prevTimesheetData,
      [companyId]: prevTimesheetData[companyId].map(oneWorker =>
        oneWorker._id === workerId ? {
          ...oneWorker,
          workerRowLoading: true,
        } : oneWorker,
      ),
    }));

    let apiURL = `${timesheetEndpoint}/punch/${get(punchData, '_id')}/${get(project, '_id.$oid')}`;

    Axios.patch(apiURL, payload, axiosConfig()).then(response => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
            shifts: oneWorker.shifts.map(oneShift =>
              oneShift._id === shiftId ? {
                ...oneShift,
                [punchType]: {
                  ...oneShift[punchType],
                  identityCheck: response.data.identityCheck,
                  ppe: response.data.ppe,
                },
              } : oneShift,
            ),
          } : oneWorker,
        ),
      }));

      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
            shifts: oneWorker.shifts.map(oneShift =>
              oneShift._id === shiftId ? {
                ...oneShift,
                [punchType]: {
                  ...oneShift[punchType],
                  identityCheck: response.data.identityCheck,
                  ppe: response.data.ppe,
                },
              } : oneShift,
            ),
          } : oneWorker,
        ),
      }));

    }).catch(error => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
          } : oneWorker,
        ),
      }));
      notificationCenter('error', `Error toggling worked lunch break. ${get(error, 'response.data.message', '')}`);
    });
  };

  const handleBreakTimeChange = (newValue, shiftId, workerId, companyId) => {
    setTimesheetData(prevTimesheetData => ({
      ...prevTimesheetData,
      [companyId]: prevTimesheetData[companyId].map(oneWorker =>
        oneWorker._id === workerId ? {
          ...oneWorker,
          workerRowLoading: true,
          shifts: oneWorker.shifts.map(oneShift =>
            oneShift._id === shiftId ? {
              ...oneShift,
              breakTimeLoading: true,
            } : oneShift,
          ),
        } : oneWorker,
      ),
    }));

    let apiURL = `${timesheetEndpoint}/shift/${shiftId}/${get(project, '_id.$oid')}`;
    let apiOptions = { break_time: newValue };

    Axios.patch(apiURL, apiOptions, axiosConfig()).then(response => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...get(response, 'data')
          } : oneWorker,
        ),
      }));
    }).catch(error => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
            shifts: oneWorker.shifts.map(oneShift =>
              oneShift._id === shiftId ? {
                ...oneShift,
                breakTimeLoading: false,
              } : oneShift,
            ),
          } : oneWorker,
        ),
      }));
      notificationCenter('error', get(error, 'response.data.error', 'Error changing break time.'));
    });
  };

  const createTempShift = (worker, shiftId, command) => {
    let spliceIndex = 0;
    let newShiftSplit = {};

    worker.shifts = worker.shifts.map((oneShift, shiftIndex) => {
      if (isEqual(oneShift._id, shiftId)) {
        if (isEqual(get(command, 'action'), 'split')) {
          spliceIndex = shiftIndex;
          newShiftSplit = cloneDeep(oneShift);

          if (get(command, 'direction') >= 0) {
            newShiftSplit.productivity = [];
            newShiftSplit.break_time = 0;
            delete newShiftSplit.punchInInfo;

            oneShift.punchOutInfo = oneShift.punchInInfo;
            delete oneShift.punchInInfo;
            oneShift.productivity = [];
            oneShift.break_time = 0;
          } else {
            newShiftSplit.productivity = [];
            newShiftSplit.break_time = 0;
            newShiftSplit.punchInInfo = newShiftSplit.punchOutInfo;
            delete newShiftSplit.punchOutInfo;

            delete oneShift.punchOutInfo;
            oneShift.productivity = [];
            oneShift.break_time = 0;
          }
        } else if (isEqual(get(command, 'action'), 'flip')) {
          if (has(oneShift, 'punchInInfo')) {
            oneShift.punchOutInfo = cloneDeep(oneShift.punchInInfo);
            delete oneShift.punchInInfo;
          } else {
            oneShift.punchInInfo = cloneDeep(oneShift.punchOutInfo);
            delete oneShift.punchOutInfo;
          }
        }

        return oneShift;
      } else {
        return oneShift;
      }
    });

    if (isEqual(get(command, 'action'), 'split')) {
      worker.shifts.splice(spliceIndex + 1, 0, newShiftSplit);
    } else if (isEqual(get(command, 'action'), 'flip')) {
      // worker.shifts.splice(spliceIndex+1, 0, newShiftSplit)
    }

    worker.workerRowLoading = true;

    return worker;
  };

  const editShift = (payload, shiftId, workerId, companyId) => {
    let originalShifts = [];

    setTimesheetData(prevTimesheetData => ({
      ...prevTimesheetData,
      [companyId]: prevTimesheetData[companyId].map(oneWorker => {
        if (isEqual(oneWorker._id, workerId)) {
          originalShifts = cloneDeep(oneWorker.shifts);
          return createTempShift(oneWorker, shiftId, get(payload, 'command'));
        } else {
          return oneWorker;
        }
      }),
    }));


    let apiURL = `${timesheetEndpoint}/shift/${shiftId}/${get(project, '_id.$oid')}`;

    Axios.patch(apiURL, payload, axiosConfig()).then(response => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            shifts: [],
          } : oneWorker,
        ),
      }));
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...get(response, 'data', oneWorker),
          } : oneWorker,
        ),
      }));
    }).catch(error => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
            shifts: originalShifts,
          } : oneWorker,
        ),
      }));
      notificationCenter('error', get(error, 'response.data.error', 'Error editing shift.'));
    });
  };

  const addPunchToShift = (payload, shiftId, workerId, companyId) => {
    setTimesheetData(prevTimesheetData => ({
      ...prevTimesheetData,
      [companyId]: prevTimesheetData[companyId].map(oneWorker =>
        oneWorker._id === workerId ? {
          ...oneWorker,
          workerRowLoading: true,
        } : oneWorker,
      ),
    }));

    let apiURL = `${timesheetEndpoint}/shift/${shiftId}/${get(project, '_id.$oid')}`;

    Axios.patch(apiURL, payload, axiosConfig()).then(response => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...get(response, 'data', oneWorker),
          } : oneWorker,
        ),
      }));

    }).catch(error => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
          } : oneWorker,
        ),
      }));
      notificationCenter('error', get(error, 'response.data.error', 'Error adding punch to shift.'));
    });
  };

  const approveSelectedShifts = useCallback((companyId) => {
    setPreloadedCompanies(prevPreloadedCompanies => ({
      ...prevPreloadedCompanies,
      [companyId]: {
        ...prevPreloadedCompanies[companyId],
        approveAllLoading: true,
      },
    }));

    setTimeout(() => {
      setPreloadedCompanies(prevPreloadedCompanies => ({
        ...prevPreloadedCompanies,
        [companyId]: {
          ...prevPreloadedCompanies[companyId],
          approveAllLoading: false,
        },
      }));
    }, 3000);

    // let apiURL = `${timesheetEndpoint}/shift/${shiftId}/${get( project, '_id.$oid')}`
    //
    // Axios.patch(apiURL, {
    //   break_time: newValue
    // }, {
    //     headers: {
    //         Authorization: `Bearer 3e5b5969d245cdb709cf055bda4c9459412610aab50797fe2b8d8c0e943625bba2e8a8cadd6512c7016595f6840b06f4126e9572ce5f496a6dee30d0d13fd95c`,
    //         'Accept': 'application/json',
    //         "Content-Type": "application/json",
    //         'Access-Control-Allow-Origin': "*"
    //     }
    // }).then(response => {
    //
    //   setTimesheetData(prevTimesheetData => ({
    //     ...prevTimesheetData,
    //     [companyId]: prevTimesheetData[companyId].map(oneWorker =>
    //       oneWorker._id === workerId ? {
    //         ...oneWorker,
    //         workerRowLoading: false,
    //         shifts: oneWorker.shifts.map(oneShift =>
    //           oneShift._id === shiftId ? {
    //             ...oneShift,
    //             breakTimeLoading: false,
    //             break_time: response.data.break_time
    //           } : oneShift
    //         )
    //       } : oneWorker
    //     )
    //   }))
    //
    // }).catch(error => {
    //
    //   setTimesheetData(prevTimesheetData => ({
    //     ...prevTimesheetData,
    //     [companyId]: prevTimesheetData[companyId].map(oneWorker =>
    //       oneWorker._id === workerId ? {
    //         ...oneWorker,
    //         workerRowLoading: false,
    //         shifts: oneWorker.shifts.map(oneShift =>
    //           oneShift._id === shiftId ? {
    //             ...oneShift,
    //             breakTimeLoading: false,
    //           } : oneShift
    //         )
    //       } : oneWorker
    //     )
    //   }))
    //
    //   // toastr.error( get( error, 'response.data.error', 'Error fetching project gauge data.'));
    // })
  }, []);

  const applyClassifiers = (shift, workerId, companyId, payload) => {
    let shiftId = get(shift, '_id');

    setTimesheetData(prevTimesheetData => ({
      ...prevTimesheetData,
      [companyId]: prevTimesheetData[companyId].map(oneWorker =>
        oneWorker._id === workerId ? {
          ...oneWorker,
          workerRowLoading: true,
          shifts: oneWorker.shifts.map(oneShift =>
            oneShift._id === shiftId ? {
              ...oneShift,
              appliedClassifiers: {
                classifiers: get(payload, 'classifiers', []),
              },
            } : oneShift,
          ),
        } : oneWorker,
      ),
    }));

    let apiURL = `${classifiersEndpoint}/${companyId}/applyTo/shift/${shiftId}`;

    Axios.patch(apiURL, payload, axiosConfig()).then(response => {

      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
            triggerClassiferUpdate: !oneWorker.triggerClassiferUpdate,
            shifts: oneWorker.shifts.map(oneShift =>
              oneShift._id === shiftId ? {
                ...oneShift,
                appliedClassifiers: {
                  classifiers: get(response, 'data.classifiers', []),
                },
              } : oneShift,
            ),
          } : oneWorker,
        ),
      }));

    }).catch(error => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
            triggerClassiferUpdate: !oneWorker.triggerClassiferUpdate,
            shifts: oneWorker.shifts.map(oneShift =>
              oneShift._id === shiftId ? {
                ...oneShift,
                appliedClassifiers: get(shift, 'appliedClassifiers', {}),
              } : oneShift,
            ),
          } : oneWorker,
        ),
      }));
      notificationCenter('error', get(error, 'response.data.error', 'Error applying classifiers to shift.'));
    });
  };

  const applyProductivity = (shiftId, workerId, companyId, payload) => {
    setTimesheetData(prevTimesheetData => ({
      ...prevTimesheetData,
      [companyId]: prevTimesheetData[companyId].map(oneWorker =>
        oneWorker._id === workerId ? {
          ...oneWorker,
          workerRowLoading: true,
          shifts: oneWorker.shifts.map(oneShift => {
            if (isEqual(oneShift._id, shiftId)) {
              oneShift.productivity = get(payload, 'payload');
              return oneShift;
            } else {
              return oneShift;
            }
          }),
        } : oneWorker,
      ),
    }));

    let apiURL = `${timesheetEndpoint}/shift/${shiftId}/${get(project, '_id.$oid')}`;

    Axios.patch(apiURL, payload, axiosConfig()).then(response => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            shifts: []
          } : oneWorker,
        ),
      }))
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...get(response, 'data'),
          } : oneWorker,
        ),
      }));
    }).catch(error => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
            // shifts: oneWorker.shifts.map(oneShift =>
            //   oneShift._id === shiftId ? {
            //     ...oneShift,
            //     breakTimeLoading: false,
            //     break_time: response.data.break_time
            //   } : oneShift
            // )
          } : oneWorker,
        ),
      }));
      notificationCenter('error', get(error, 'response.data.error', 'Error applying productivity to shift.'));
    });
  };

  const addNewShift = (companyId, payload) => {
    setPreloadedCompanies(prevPreloadedCompanies => ({
      ...prevPreloadedCompanies,
      [companyId]: {
        ...prevPreloadedCompanies[companyId],
        loadingData: true,
      },
    }));

    let apiURL = `${timesheetEndpoint}/project/${get(project, '_id.$oid')}/shift`;

    Axios.post(apiURL, payload, axiosConfig()).then(response => {
      setTimesheetData(prevTimesheetData => {
        let found = false;
        let workersArray = prevTimesheetData[companyId].map(oneWorker => {
          if (isEqual(oneWorker._id, response.data._id)) {
            found = true;
            return response.data;
          } else {
            return oneWorker;
          }
        });
        if (!found) workersArray.unshift(response.data);

        return ({
          ...prevTimesheetData,
          [companyId]: workersArray,
        });
      });

      setPreloadedCompanies(prevPreloadedCompanies => ({
        ...prevPreloadedCompanies,
        [companyId]: {
          ...prevPreloadedCompanies[companyId],
          loadingData: false,
        },
      }));

    }).catch(error => {
      setPreloadedCompanies(prevPreloadedCompanies => ({
        ...prevPreloadedCompanies,
        [companyId]: {
          ...prevPreloadedCompanies[companyId],
          loadingData: false,
        },
      }));
      notificationCenter('error', get(error, 'response.data.error', 'Error adding new shift.'));
    });
  };

  const deleteShift = ({ shiftId, workerId, companyId }) => {
    setTimesheetData(prevTimesheetData => ({
      ...prevTimesheetData,
      [companyId]: prevTimesheetData[companyId].map(oneWorker =>
        oneWorker._id === workerId ? {
          ...oneWorker,
          workerRowLoading: true,
        } : oneWorker,
      ),
    }));

    let apiURL = `${timesheetEndpoint}/shift/${shiftId}/${get(project, '_id.$oid')}`;

    Axios.delete(apiURL, axiosConfig()).then(response => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...get(response, 'data'),
            workerRowLoading: false,
          } : oneWorker,
        ),
      }));
    }).catch(error => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
          } : oneWorker,
        ),
      }));
      notificationCenter('error', get(error, 'response.data.error', 'Error deleting shift.'));
    });

    setActionPayload(null);
  };

  const deleteProfilePic = ({ workerData, companyId }) => {
    let workerId = workerData._id

    setTimesheetData(prevTimesheetData => ({
      ...prevTimesheetData,
      [companyId]: prevTimesheetData[companyId].map(oneWorker =>
        oneWorker._id === workerId ? {
          ...oneWorker,
          workerRowLoading: true,
        } : oneWorker,
      ),
    }));

    let apiURL = `${employeeSettingsEndpoint}/${workerId}/idCard`;

    Axios.delete(apiURL, axiosConfig()).then(response => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker => {
          if (oneWorker._id === workerId) {
            let temp = { ...oneWorker }
            delete temp.profilePic
            temp.workerRowLoading = false
            return temp
          } else {
            return oneWorker
          }
        }),
      }));
    }).catch(error => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
          } : oneWorker,
        ),
      }));
      notificationCenter('error', get(error, 'response.data.error', 'Error deleting profile picture.'));
    });

    setActionPayload(null);
  };

  const deletePunch = ({ punchId, shiftId, workerId, companyId }) => {
    setTimesheetData(prevTimesheetData => ({
      ...prevTimesheetData,
      [companyId]: prevTimesheetData[companyId].map(oneWorker =>
        oneWorker._id === workerId ? {
          ...oneWorker,
          workerRowLoading: true,
        } : oneWorker,
      ),
    }));

    let apiURL = `${timesheetEndpoint}/punch/${punchId}/${get(project, '_id.$oid')}`;

    Axios.delete(apiURL, axiosConfig()).then(response => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...get(response, 'data'),
            workerRowLoading: false,
          } : oneWorker,
        ),
      }));
    }).catch(error => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
          } : oneWorker,
        ),
      }));
      notificationCenter('error', get(error, 'response.data.error', 'Error deleting punch.'));
    });

    setActionPayload(null);
  };

  const migrateShift = ({ projectId, shiftId, workerId, companyId }) => {
    setTimesheetData(prevTimesheetData => ({
      ...prevTimesheetData,
      [companyId]: prevTimesheetData[companyId].map(oneWorker =>
        oneWorker._id === workerId ? {
          ...oneWorker,
          workerRowLoading: true,
        } : oneWorker,
      ),
    }));

    let apiURL = `${timesheetEndpoint}/shift/${shiftId}/${get(project, '_id.$oid')}`;
    let apiPayload = {
      command: {
        action: 'migrate',
        date: DateTime.fromISO(settingsContext.timesheetDate).toFormat('yLLdd'),
      },
      payload: {
        destination: projectId,
      },
    };

    setShowMigrateShiftToProjectDialog(false);

    Axios.patch(apiURL, apiPayload, axiosConfig()).then(response => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
            shifts: get(response, 'data.shifts', []),
          } : oneWorker,
        ).filter(oneWorker => size(get(oneWorker, 'shifts', [])) > 0),
      }));
      notificationCenter('success', 'Shift migrated successfully.');
    }).catch(error => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
          } : oneWorker,
        ),
      }));
      notificationCenter('error', get(error, 'response.data.error', 'Error migrating shift to project.'));
    });

    setActionPayload(null);
  };

  const revertPunchTime = (punchType, punchId, shiftId, workerId, companyId) => {
    setTimesheetData(prevTimesheetData => ({
      ...prevTimesheetData,
      [companyId]: prevTimesheetData[companyId].map(oneWorker =>
        oneWorker._id === workerId ? {
          ...oneWorker,
          workerRowLoading: true,
          shifts: oneWorker.shifts.map(oneShift =>
            oneShift._id === shiftId ? {
              ...oneShift,
              revertPunchTimeLoading: true,
              [punchType]: {
                ...oneShift[punchType],
              },
            } : oneShift,
          ),
        } : oneWorker,
      ),
    }));

    let apiURL = `${timesheetEndpoint}/punch/${punchId}/${get(project, '_id.$oid')}`;
    let payload = { action: 'revert' };

    Axios.patch(apiURL, payload, axiosConfig()).then(response => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
            shifts: oneWorker.shifts.map(oneShift =>
              oneShift._id === shiftId ? {
                ...oneShift,
                revertPunchTimeLoading: false,
                [punchType]: {
                  ...oneShift[punchType],
                  ruled_by: get(response, 'data.ruled_by'),
                  punch_time: get(response, 'data.punch_time'),
                },
              } : oneShift,
            ),
          } : oneWorker,
        ),
      }));
    }).catch(error => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
            shifts: oneWorker.shifts.map(oneShift =>
              oneShift._id === shiftId ? {
                ...oneShift,
                revertPunchTimeLoading: false,
              } : oneShift,
            ),
          } : oneWorker,
        ),
      }));
      notificationCenter('error', get(error, 'response.data.error', 'Error reverting punch time.'));
    });
  };

  const approveShift = (approve, shift, workerId, companyId) => {
    setTimesheetData(prevTimesheetData => ({
      ...prevTimesheetData,
      [companyId]: prevTimesheetData[companyId].map(oneWorker =>
        oneWorker._id === workerId ? {
          ...oneWorker,
          workerRowLoading: true,
          shifts: oneWorker.shifts.map(oneShift =>
            oneShift._id === shift._id ? {
              ...oneShift,
              submitApproveLoading: true,
            } : oneShift,
          ),
        } : oneWorker,
      ),
    }));

    let apiURL = `${timesheetEndpoint}/shift/${shift._id}/${get(project, '_id.$oid')}`;
    let apiPayload = { approved: approve };

    Axios.patch(apiURL, apiPayload, axiosConfig()).then(response => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
            shifts: oneWorker.shifts.map(oneShift =>
              oneShift._id === shift._id ? {
                ...oneShift,
                approved: response.data.approved,
                submitApproveLoading: false,
              } : oneShift,
            ),
          } : oneWorker,
        ),
      }));
    }).catch(error => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
            shifts: oneWorker.shifts.map(oneShift =>
              oneShift._id === shift._id ? {
                ...oneShift,
                submitApproveLoading: false,
              } : oneShift,
            ),
          } : oneWorker,
        ),
      }));
      notificationCenter('error', get(error, 'response.data.error', 'Error approving shift.'));
    });
  }


  const applyPerdiem = (perdiem, shift, workerId, companyId) => {
    setTimesheetData(prevTimesheetData => ({
      ...prevTimesheetData,
      [companyId]: prevTimesheetData[companyId].map(oneWorker =>
        oneWorker._id === workerId ? {
          ...oneWorker,
          workerRowLoading: true,
          shifts: oneWorker.shifts.map(oneShift =>
            oneShift._id === shift._id ? {
              ...oneShift,
              perdiemLoading: true,
            } : oneShift,
          ),
        } : oneWorker,
      ),
    }));

    let apiURL = `${timesheetEndpoint}/shift/${shift._id}/${get(project, '_id.$oid')}`;
    let apiPayload = { perdiem };

    Axios.patch(apiURL, apiPayload, axiosConfig()).then(response => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
            shifts: oneWorker.shifts.map(oneShift =>
              oneShift._id === shift._id ? {
                ...oneShift,
                perdiem: response.data.perdiem,
                perdiemLoading: false,
              } : oneShift,
            ),
          } : oneWorker,
        ),
      }));
    }).catch(error => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: prevTimesheetData[companyId].map(oneWorker =>
          oneWorker._id === workerId ? {
            ...oneWorker,
            workerRowLoading: false,
            shifts: oneWorker.shifts.map(oneShift =>
              oneShift._id === shift._id ? {
                ...oneShift,
                perdiemLoading: false,
              } : oneShift,
            ),
          } : oneWorker,
        ),
      }));
      notificationCenter('error', get(error, 'response.data.error', 'Error applying perdiem.'));
    });
  };

  const loadTimesheetData = (date, companyId, pageNumber, selectedCrew) => {
    let apiURL = `${timesheetEndpoint}/load/${get(project, '_id.$oid')}/${companyId}/${DateTime.fromISO(date).toFormat('yLLdd')}/${pageNumber}/${pageSize}`

    if (selectedCrew) { apiURL += `/crew:${selectedCrew}` }

    return from(Axios.get(apiURL, axiosConfig()))
      .pipe(
        catchError(error => of({ error })),
      );
  };

  const loadClassifiers = () => {
    let payload = { appliesTo: 'shifts', active: true };

    return from(Axios.post(`${classifiersEndpoint}/${employeeCompany}/list`, payload, axiosConfig()))
      .pipe(
        catchError(error => of({ error })),
      );
  };

  const loadAllCompanyTimesheetData = (date, companyId, pageNumber, selectedCrew) => {
    setPreloadedCompanies(prevPreloadedCompanies => ({
      ...prevPreloadedCompanies,
      [companyId]: {
        ...prevPreloadedCompanies[companyId],
        loadingMoreData: true,
      },
    }))

    return loadTimesheetData(date, companyId, pageNumber, selectedCrew).pipe(
      switchMap(response => {
        if (has(response, 'error')) {
          notificationCenter('error', get(response, 'error.data.error', 'Error loading all company timesheet data.'));
        } else {
          setTimesheetData(prevTimesheetData => ({
            ...prevTimesheetData,
            [companyId]: prevTimesheetData[companyId].concat(response.data),
          }));

          if (isEqual(size(get(response, 'data', [])), pageSize)) {
            return loadAllCompanyTimesheetData(date, companyId, pageNumber + 1, selectedCrew);
          } else {
            setPreloadedCompanies(prevPreloadedCompanies => ({
              ...prevPreloadedCompanies,
              [companyId]: {
                ...prevPreloadedCompanies[companyId],
                allDataLoaded: true,
                loadingMoreData: false
              },
            }));
            return of(response);
          }
        }
      }),
    );
  };

  const getAllCompanyTimesheetData = (date, companyId, pageNumber, selectedCrew) => {
    const subscription = loadAllCompanyTimesheetData(date, companyId, pageNumber, selectedCrew).subscribe({
      next: result => {
        // console.log('xx loadAllCompanyTimesheetData result: ', result);
      },
      error: err => {
        // console.log('xx loadAllCompanyTimesheetData error: ', err);
        notificationCenter('error', get(err, 'error', 'Error loading company timesheets.'));
      },
      complete: () => subscription.unsubscribe(),
    });
  };

  const preload = () => {
    let apiURL = `${timesheetEndpoint}/preload/${get(project, '_id.$oid')}/${settingsContext.onGetTimeSheetDateFormat()}`

    if (selectedCrew) {
      apiURL += `/crew:${get(selectedCrew, '_id')}`
    }

    return from(Axios.get(apiURL, axiosConfig()))
      .pipe(
        catchError(error => {
          return of({ error });
        }),
      );
  };

  const loadInitialWorkerRows = (arrayOfCompanies, selectedCrew) => {
    return from(arrayOfCompanies.sort((a, b) => b.attendance.total - a.attendance.total))
      .pipe(
        concatMap(company => {
          return (
            forkJoin({
              companyId: of(company._id),
              companyName: of(company.name),
              response: company.loadingData ? loadTimesheetData(settingsContext.timesheetDate, company._id, 1, get(selectedCrew, '_id')) : of([]),
            })
          );
        }),
      );
  };

  const initLoad = (costCodes, arrayOfCompanies, selectedCrew) => {
    loadInitialSubscription.current = loadInitialWorkerRows(arrayOfCompanies, selectedCrew).subscribe({
      next: result => {
        let timesheetResponse = get(result, 'response');



        // check for any cost codes that have no been received by preload api and concat them to preloaded array
        let costCodesClone = cloneDeep(costCodes)

        get(timesheetResponse, 'data', []).map(oneWorker => {
          oneWorker.shifts.map(oneShift => {
            get(oneShift, 'productivity', []).map(oneProductivity => {
              if (costCodesClone.findIndex(oneCostCode => oneCostCode.costCode._id == oneProductivity.costCode._id) === -1) {
                costCodesClone.push(oneProductivity)
              }
            })
          })
        })

        unstable_batchedUpdates(() => {
          if (size(costCodesClone) !== size(costCodes)) {
            setPreloadedCostCodes(costCodesClone)
          }

          setTimesheetData(prevTimesheetData => (
            {
              ...prevTimesheetData,
              [result.companyId]: get(timesheetResponse, 'data', []),
            }
          ));

          setPreloadedCompanies(prevPreloadedCompanies => ({
            ...prevPreloadedCompanies,
            [result.companyId]: {
              ...prevPreloadedCompanies[result.companyId],
              loadingData: false,
              allDataLoaded: size(get(timesheetResponse, 'data', [])) < pageSize,
            },
          }));

        });


        if (has(timesheetResponse, 'error')) {
          notificationCenter('error', `Error getting timesheet data for ${get(result, 'companyName')}. ${get(timesheetResponse, 'error.response.data.message', '')}`);
        }
      },
      error: err => {
        // console.error('xx something wrong occurred: ' + err)
      },
      complete: () => {
        // loadInitialSubscription.current.unsubscribe()
      },
    });
  };

  const initPreload = () => {
    setPreloadLoading(true);
    setUnblockDatePicker(false)

    preloadSubscription.current = preload().subscribe({
      next: response => {
        setPreloadLoading(false);

        if (has(response, 'error')) {
          // console.log('xx preload error: ', get(response, 'error'));
          notificationCenter('error', get(response, 'error.data.error', 'Error preloading data.'));
        } else {
          let current = 0, total = 0, companiesWithAttendance = 0;

          get(response, 'data.companies', []).map(oneCompany => {
            current += get(oneCompany, 'attendance.current', 0);
            total += get(oneCompany, 'attendance.total', 0);

            if (get(oneCompany, 'attendance.total', 0)) {
              companiesWithAttendance++;
            }
          });

          unstable_batchedUpdates(() => {
            // // TODO: delete this line
            // setPreloadedCompanies(get(get(response, 'data', {}), 'companies', []).filter(oneCompany => ['General Contractor'].indexOf(oneCompany.name) !== -1).map(oneCompany => ({ ...oneCompany, loadingData: get( get( oneCompany, 'attendance', {}), 'current') !== 0 ? true : false, allDataLoaded: get( get( oneCompany, 'attendance', {}), 'current') === 0 ? true : false, workers: [] })).sort((a, b) => { return b.attendance.current - a.attendance.current }))
            // setPreloadedCompanies(get(get(response, 'data', {}), 'companies', []).filter(oneCompany => ['General Contractor', 'Company5', 'HV Electric', 'Smack HVAC'].indexOf(oneCompany.name) !== -1).map(oneCompany => ({ ...oneCompany, loadingData: get( get( oneCompany, 'attendance', {}), 'current') !== 0 ? true : false, allDataLoaded: get( get( oneCompany, 'attendance', {}), 'current') === 0 ? true : false, workers: [] })).sort((a, b) => { return b.attendance.current - a.attendance.current }))
            // setPreloadedCompanies(get(get(response, 'data', {}), 'companies', []).map(oneCompany =>
            // ({
            //   ...oneCompany,
            //   loadingData: get(get(oneCompany, 'attendance', {}), 'total') !== 0 ? true : false,
            //   allDataLoaded: get(get(oneCompany, 'attendance', {}), 'total') === 0 ? true : false,
            // })).sort((a, b) => { return b.attendance.total - a.attendance.total }))
            let tempObject = {};
            let tempObject2 = {};

            let arrayOfCompanies = get(response, 'data.companies', []).map(oneCompany => {
              tempObject[oneCompany._id] = {
                companyData: oneCompany,
                loadingData: get(oneCompany, 'attendance.total') !== 0 ? true : false,
                allDataLoaded: get(oneCompany, 'attendance.total') === 0 ? true : false,
              };
              tempObject2[oneCompany._id] = [];

              return ({
                ...oneCompany,
                loadingData: get(oneCompany, 'attendance.total') !== 0 ? true : false,
                allDataLoaded: get(oneCompany, 'attendance.total') === 0 ? true : false,
              });
            });

            setPreloadedCompanies(tempObject);
            setTimesheetData(tempObject2);
            setPreloadedCostCodes(get(response, 'data.costCodes', []));
            settingsContext.updateStats({
              workers: { current, total },
              companies: {
                current: companiesWithAttendance,
                total: size(get(response, 'data.companies', [])),
              },
            });
            if (!get(settingsContext, 'uiConfig.ui.modules.timesheet.forceCrewFilter') || selectedCrew) {
              initLoad(get(response, 'data.costCodes', []), arrayOfCompanies, selectedCrew);
            } else {
              setUnblockDatePicker(true)
            }
          });
        }
      },
      error: err => {
        // console.error('xx something wrong occurred: ' + err)
        notificationCenter('error', 'Preload error.');
      },
      complete: () => {
        // change code here to trigger loadinitial instead of listening to preloadedcompanies...
        setPreloadLoading(false);
        preloadSubscription.current.unsubscribe();
      },
    });
  };

  const renderTotalAttendanceGaugeMeter = () => {
    let current = 0, total = 0;

    Object.keys(preloadedCompanies).map(oneCompanyId => preloadedCompanies[oneCompanyId].companyData).map(oneCompany => {
      current += get(oneCompany, 'attendance.current', 0);
      total += get(oneCompany, 'attendance.total', 0);
    });

    return (
      <GaugeMeter
        title=""
        disableBackground
        thickMeter
        values={{ current, total }}
        size={50}
        noActivity={total === 0 && current === 0}
        activityCompleted={current === 0 && total !== 0}
      />
    );
  };

  const handlePunchContextMenuBlur = () => {
    setPopoverId('');
    setPopoverPosition(null);
  };

  const renderPunchContextMenu = () => (
    <Popover
      id="punchContextMenu"
      anchorReference="anchorPosition"
      open={popoverId === 'punchContextMenu'}
      onClose={handlePunchContextMenuBlur}
      anchorPosition={popoverPosition}>
      <MenuList id="punchContextMenu" aria-labelledby="punchContextMenu">
        <MenuItem onClick={handleDeletePunch} className={classes.deletePunchMenuItem}>
          <ListItemIcon>
            <DeleteIcon className={classes.deletePunchMenuItem} />
          </ListItemIcon>
          <ListItemText>Delete Punch</ListItemText>
        </MenuItem>
      </MenuList>
    </Popover>
  );

  const renderSelectCrewToBegin = () => {
    return (
      <div className={classes.pleaseSelectCrewWrapper}>
        <div>Please select a crew</div>
      </div>
    )
    // NOTE: the below is to be used later
    // return(
    //   <div className={classes.pleaseSelectCrewWrapper}>
    //     <Grid container spacing={1}>
    //       { get(settingsContext, 'uiConfig.data.crews', []).map(oneCrew => (
    //         <Grid xs item className={classes.crewGridItem} justifyContent='center'>
    //           <Button color='primary' onClick={() => setSelectedCrew(oneCrew)} className={classes.crewButton}>
    //             { get(oneCrew, '_id') !== 'NON_CREW' ?
    //               <div className={classes.crewButtonInner}>
    //                 <div className={classes.crewButtonMembersWrapper}>
    //                   <div style={{ transform: 'scaleY(1.2)' }}>
    //                     {get(oneCrew, 'members', 0)}
    //                   </div>
    //                 </div>
    //                 <div className={classes.crewButtonNameWrapper}>
    //                   {get(oneCrew, 'name', '')}
    //                 </div>
    //               </div> :
    //               <div className={classes.noCrewButtonInner}>
    //                 <div className={classes.crewButtonNameWrapper} style={{ textAlign: 'center' }}>
    //                   {get(oneCrew, 'name', '')}
    //                 </div>
    //               </div>
    //             }
    //           </Button>
    //         </Grid>
    //       ))}
    //     </Grid>
    //   </div>
    // )
  };

  const renderConfirmDeleteDialog = () => (
    <Dialog maxWidth="sm" open={showDeleteDialog} onClose={handleCancelDeleteDialog} fullWidth>
      <DialogTitle disableTypography={true} className={classes.dialogTitle}>
        <Typography variant="h6">Delete {deleteItem === 'shift' ? 'Shift' : deleteItem === 'punch' ? 'Punch' : 'Profile Picture'}</Typography>
        <IconButton
          onClick={handleCancelDeleteDialog}
          size="small"
          className={classes.closeModalButton}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogBody} style={{ position: 'relative' }}>
        <div style={{
          width: '100%',
          height: 45,
          zIndex: 0,
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: '#f6f5f7',
        }} />
        <Paper style={{ zIndex: 2 }}>
          <div style={{ padding: 16, display: 'flex', alignItems: 'center' }}>
            {deleteItem === 'profilePic' ? <img alt="workerPic" src={get(actionPayload, 'workerData.profilePic')} className={classes.smallAvatar} /> : <span style={{ fontSize: 30, marginRight: 16 }}>⚠️</span>} Are you sure you want to delete this {deleteItem === 'shift' ? 'shift' : deleteItem === 'punch' ? 'punch' : `profile picture for ${get(actionPayload, 'workerData.first_name')} ${get(actionPayload, 'workerData.last_name')}`}?
          </div>
        </Paper>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button variant="outlined" onClick={handleCancelDeleteDialog}>
          Cancel
        </Button>
        <Button variant="outlined" onClick={handleConfirmDeleteDialog} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderMigrateShiftToProjectDialog = () => (
    <MigrateShiftToProject
      config={config}
      project={project}
      showMigrateShiftToProjectDialog={showMigrateShiftToProjectDialog}
      setShowMigrateShiftToProjectDialog={setShowMigrateShiftToProjectDialog}
      handleConfirmMigrateShiftToProject={handleConfirmMigrateShiftToProject}
    />
  );

  const handleCancelDeleteDialog = () => {
    setShowDeleteDialog(false);
    setDeleteItem(null);
    setActionPayload(null);
  };

  const handleConfirmDeleteDialog = () => {
    if (deleteItem === 'shift') {
      deleteShift(actionPayload);
    } else if (deleteItem === 'punch') {
      deletePunch(actionPayload)
    } else if (deleteItem === 'profilePic') {
      deleteProfilePic(actionPayload)
    } else {

    }

    setShowDeleteDialog(false);
    setDeleteItem(null);
  };

  const handleDeletePunch = () => {
    setPopoverPosition(null);
    setPopoverId('');
    setShowDeleteDialog(true);
    setDeleteItem('punch');
  };

  const handleConfirmMigrateShiftToProject = (projectId) => {
    migrateShift({ ...actionPayload, projectId });
  };

  const handleActionDialogPopover = useCallback((action, payload, proxy) => {
    switch (action) {
      case 'deleteShift':
        setShowDeleteDialog(true);
        setDeleteItem('shift');
        setActionPayload(payload);
        break;
      case 'deleteProfilePic':
        setShowDeleteDialog(true);
        setDeleteItem('profilePic');
        setActionPayload(payload);
        break;
      case 'migrateShift':
        setShowMigrateShiftToProjectDialog(true);
        setActionPayload(payload);
        break;
      case 'openPunchContextMenu':
        setPopoverPosition({ top: proxy.clientY, left: proxy.clientX });
        setPopoverId('punchContextMenu');
        setActionPayload(payload);
        break;
      case 'addNewShift':
        addNewShift(proxy, payload);
        break;
      case 'addPunchToShift':
        addPunchToShift(payload, proxy.shiftId, proxy.workerId, proxy.companyId);
        break;
      case 'applyProductivity':
        applyProductivity(proxy.shiftId, proxy.workerId, proxy.companyId, payload);
        break;
      case 'applyClassifiers':
        applyClassifiers(proxy.shift, proxy.workerId, proxy.companyId, payload);
        break;
      case 'approveShift':
        approveShift(payload, proxy.shift, proxy.workerId, proxy.companyId);
        break;
      case 'editShift':
        editShift(payload, proxy.shiftId, proxy.workerId, proxy.companyId);
        break;
      case 'editPunchAttributes':
        editPunchAttributes(payload, proxy.punchData, proxy.shiftId, proxy.workerId, proxy.companyId);
        break;
      case 'handleBreakTimeChange':
        handleBreakTimeChange(payload, proxy.shiftId, proxy.workerId, proxy.companyId);
        break;
      case 'revertPunchTime':
        revertPunchTime(proxy.punchType, payload, proxy.shiftId, proxy.workerId, proxy.companyId);
        break;
      case 'toggleShiftAttribute':
        toggleShiftAttribute(payload, proxy.shift, proxy.workerId, proxy.companyId);
        break;
      case 'selectAllShifts':
        selectAllShifts(payload, proxy.productivityView, proxy.unselectAll);
        break;
      case 'selectShift':
        selectShift(payload, proxy.workerId, proxy.companyId);
        break;
      case 'getAllCompanyTimesheetData':
        getAllCompanyTimesheetData(payload, proxy.companyId, proxy.pageNumber, proxy.selectedCrew);
        break;
      case 'applyPerdiem':
        applyPerdiem(payload, proxy.shift, proxy.workerId, proxy.companyId);
        break
      case '/samplePopover/':
        setPopoverPosition(proxy.event.currentTarget);
        setPopoverId('shiftOptionsMenu');
        setPopoverData({ ...payload, editMode: proxy.editMode });
        break;
      case 'getPrevPage':
        getPreviousPage(payload, proxy.page)
        break;
      case 'getNextPage':
        getNextPage(payload, proxy.page)
        break;
      default:
        console.log('could not find action ', action);
    }
  }, []);

  const getPreviousPage = (companyId, pageNumber) => {
    setPreloadedCompanies(prevPreloadedCompanies => ({
      ...prevPreloadedCompanies,
      [companyId]: {
        ...prevPreloadedCompanies[companyId],
        loadingPage: true,
      },
    }))

    let apiURL = `${timesheetEndpoint}/load/${get(project, '_id.$oid')}/${companyId}/${DateTime.fromISO(settingsContext.timesheetDate).toFormat('yLLdd')}/${pageNumber}/${pageSize}`

    if (selectedCrew) { apiURL + `/crew:${get(selectedCrew, '_id')}` }

    Axios.get(apiURL, axiosConfig()).then(response => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: response.data,
      }));

      setPreloadedCompanies(prevPreloadedCompanies => ({
        ...prevPreloadedCompanies,
        [companyId]: {
          ...prevPreloadedCompanies[companyId],
          loadingPage: false
        },
      }));

    }).catch(error => {
      setPreloadedCompanies(prevPreloadedCompanies => ({
        ...prevPreloadedCompanies,
        [companyId]: {
          ...prevPreloadedCompanies[companyId],
          loadingPage: false
        },
      }));
      notificationCenter('error', get(error, 'response.data.error', 'Error getting previous page.'));
    });
  }

  const getNextPage = (companyId, pageNumber) => {
    setPreloadedCompanies(prevPreloadedCompanies => ({
      ...prevPreloadedCompanies,
      [companyId]: {
        ...prevPreloadedCompanies[companyId],
        loadingPage: true,
      },
    }))

    let apiURL = `${timesheetEndpoint}/load/${get(project, '_id.$oid')}/${companyId}/${DateTime.fromISO(settingsContext.timesheetDate).toFormat('yLLdd')}/${pageNumber}/${pageSize}`

    if (selectedCrew) { apiURL + `/crew:${get(selectedCrew, '_id')}` }

    Axios.get(apiURL, axiosConfig()).then(response => {
      setTimesheetData(prevTimesheetData => ({
        ...prevTimesheetData,
        [companyId]: response.data,
      }));

      setPreloadedCompanies(prevPreloadedCompanies => ({
        ...prevPreloadedCompanies,
        [companyId]: {
          ...prevPreloadedCompanies[companyId],
          loadingPage: false
        },
      }));

    }).catch(error => {
      setPreloadedCompanies(prevPreloadedCompanies => ({
        ...prevPreloadedCompanies,
        [companyId]: {
          ...prevPreloadedCompanies[companyId],
          loadingPage: false
        },
      }));
      notificationCenter('error', get(error, 'response.data.error', 'Error getting next page.'));
    });
  }

  useEffect(() => {
    if (isEmpty(classifiers)) {
      loadClassifiersSubscription.current = loadClassifiers().subscribe({
        next: response => {
          // console.log('xx response: ', response);
          if (has(response, 'error')) {
            // console.log('xx loadClassifiers error: ', get(response, 'error'));
            notificationCenter('error', `Error loading classifiers. ${get(response, 'error.response', '')}`);
          } else {
            setClassifiers(get(response, 'data'));
          }
        },
        error: err => {
          // console.error('xx something wrong occurred: ' + err)
        },
        complete: () => loadClassifiersSubscription.current.unsubscribe(),
      });
    }
    return () => {
    };
  }, []);

  useEffect(() => {
    // logic to hide/show empty timesheets
    Object.keys(preloadedCompanies).map(oneCompanyId => preloadedCompanies[oneCompanyId].companyData).sort((a, b) => b.attendance.total - a.attendance.total).map(oneCompany => {
      const x = document.getElementById(`${get(oneCompany, '_id')}_companyTimesheet`);

      if (hideEmptyTimesheets && isEmpty(timesheetData[get(oneCompany, '_id')])) {
        x.style.display = 'none';
      } else {
        x.style.display = 'block';
      }
    });
  }, [hideEmptyTimesheets]);

  useEffect(() => {
    if (!selectedCrew) {
      Object.keys(preloadedCompanies).map(oneKey => get(preloadedCompanies, oneKey)).sort((a, b) => b.companyData.attendance.total - a.companyData.attendance.total).map(oneCompany => {
        let companyId = get(oneCompany, '_id')

        if (companyId) {
          setTimesheetData(prevTimesheetData => ({
            ...prevTimesheetData,
            [companyId]: [],
          }));

          setPreloadedCompanies(prevPreloadedCompanies => ({
            ...prevPreloadedCompanies,
            [companyId]: {
              ...prevPreloadedCompanies[companyId],
              loadingData: false,
              allDataLoaded: false,
            },
          }));
        }
      })
    } else {
      initPreload()
      // Object.keys(preloadedCompanies).map(oneKey => get(preloadedCompanies, oneKey)).sort((a, b) => b.companyData.attendance.total - a.companyData.attendance.total).map(oneCompany => {
      //   if (oneCompany.loadingData || oneCompany.hasData) {
      //     setPreloadedCompanies(prevPreloadedCompanies => ({
      //       ...prevPreloadedCompanies,
      //       [companyId]: {
      //         ...prevPreloadedCompanies[companyId],
      //         loadingData: true
      //       },
      //     }));
      //
      //     let companyId = get(oneCompany, 'companyData._id')
      //
      //     let apiURL = `${timesheetEndpoint}/load/${get(project, '_id.$oid')}/${companyId}/${DateTime.fromISO(settingsContext.timesheetDate).toFormat('yLLdd')}/1/${pageSize}`
      //
      //     if (selectedCrew) { apiURL += `/crew:${get(selectedCrew, '_id')}`}
      //
      //     Axios.get(apiURL, axiosConfig()).then(response => {
      //       setTimesheetData(prevTimesheetData => ({
      //         ...prevTimesheetData,
      //         [companyId]: response.data,
      //       }));
      //
      //       setPreloadedCompanies(prevPreloadedCompanies => ({
      //         ...prevPreloadedCompanies,
      //         [companyId]: {
      //           ...prevPreloadedCompanies[companyId],
      //           loadingData: false,
      //           allDataLoaded: get(response, 'data', []).length < pageSize ? true : false
      //         },
      //       }));
      //
      //     }).catch(error => {
      //       // setPreloadedCompanies(prevPreloadedCompanies => ({
      //       //   ...prevPreloadedCompanies,
      //       //   [companyId]: {
      //       //     ...prevPreloadedCompanies[companyId],
      //       //     loadingPage: false
      //       //   },
      //       // }));
      //       notificationCenter('error', get(error, 'response.data.error', 'Error getting timesheet data.'));
      //     });
      //   }
      // })
    }
  }, [selectedCrew]);

  let isFetchingData = !isEmpty(Object.keys(preloadedCompanies).map(oneCompanyId => preloadedCompanies[oneCompanyId]).filter(oneCompany => oneCompany.loadingData || oneCompany.loadingMoreData));

  return (
    <>
      {settingsContext.showRedrawCounters && updates.current++}
      <CardDash
        expandable={true}
        title="Timesheets"
        actions={
          <Grid alignItems="center" spacing={2} container>
            <Grid item>
              {!preloadLoading && ((get(settingsContext, 'uiConfig.ui.modules.timesheet.forceCrewFilter') && selectedCrew) || !get(settingsContext, 'uiConfig.ui.modules.timesheet.forceCrewFilter')) &&
                <FormControlLabel
                  className="m-a-0"
                  label="Hide empty timesheets"
                  control={<SBSwitch
                    checked={hideEmptyTimesheets}
                    handleToggle={() => setHideEmptyTimesheets(!hideEmptyTimesheets)}
                    name="Hide empty timesheets" />}
                />
              }
            </Grid>

            <Grid item>
              {get(settingsContext, 'uiConfig.ui.modules.timesheet.forceCrewFilter') &&
                <FilterByCrewDropDown
                  settingsContext={settingsContext}
                  selectedCrew={selectedCrew}
                  setSelectedCrew={setSelectedCrew}
                  classes={classes}
                />
              }
            </Grid>

            <Grid style={{ padding: 0 }} item>
              {!isEmpty(preloadedCompanies) && renderTotalAttendanceGaugeMeter()}
            </Grid>

            <Grid item>
              <DatePickerComponent
                clearTimesheetData={clearTimesheetData}
                disabled={get(settingsContext, 'uiConfig.ui.modules.timesheet.forceCrewFilter') ? (isFetchingData && !unblockDatePicker) : isFetchingData}
                inputProps={{ style: { textAlign: 'center' } }}
                formatDate="DD"
                style={{ width: 125 }}
              />
            </Grid>

            <Grid item>
              <Tooltip title={'Reload'} arrow>
                <span>
                    <IconButton disabled={isFetchingData} onClick={handleReloadTimesheet} size="small">
                        <RefreshIcon color="primary" />
                    </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        }>
        {preloadLoading &&
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <CircularProgress color="primary" />
          </div>
        }
        {(!selectedCrew && get(settingsContext, 'uiConfig.ui.modules.timesheet.forceCrewFilter')) ? renderSelectCrewToBegin() : !preloadLoading ?
          Object.keys(preloadedCompanies).map(oneCompanyId => preloadedCompanies[oneCompanyId].companyData).sort((a, b) => b.attendance.total - a.attendance.total).map((oneCompany, index) => {
            if (oneCompany) {
            return (
              <CompanyTimesheet
                isFirst={index === 0}
                key={oneCompany._id}
                config={config}
                companyData={preloadedCompanies[oneCompany._id].companyData}
                loadingData={preloadedCompanies[oneCompany._id].loadingData}
                loadingMoreData={preloadedCompanies[oneCompany._id].loadingMoreData}
                loadingPage={preloadedCompanies[oneCompany._id].loadingPage}
                allDataLoaded={preloadedCompanies[oneCompany._id].allDataLoaded}
                classifiers={classifiers}
                project={project}
                preloadedCostCodes={preloadedCostCodes}
                timesheetData={timesheetData[oneCompany._id]}
                handleActionDialogPopover={handleActionDialogPopover}
                selectedCrew={selectedCrew}
                user={user}
              />
                  )
            };
          }) : null
        }
        {renderPunchContextMenu()}
        {renderConfirmDeleteDialog()}
        {showMigrateShiftToProjectDialog && renderMigrateShiftToProjectDialog()}
      </CardDash>
    </>
  );
};

const FilterByCrewDropDown = ({
  settingsContext,
  selectedCrew,
  setSelectedCrew,
  classes
}) => {
  let crewData = get(settingsContext, 'uiConfig.data.crews', [])

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    groupedOptions,
    value,
    inputValue,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'selectCrewDropdown',
    defaultValue: null,
    multiple: false,
    options: crewData,
    getOptionLabel: (option) => get(option, 'name', ''),
    onChange: (event, value) => setSelectedCrew(value),
  });

  const handleCrewClick = (e) => {
    e.stopPropagation();
  };

  if (crewData.length === 0) {
    return (
      <div></div>
    );
  } else {
    return (
      <FormControl fullWidth>
        <div>
          <div {...getRootProps()}>
            <div ref={setAnchorEl} className={focused ? 'focused' : ''}>
              <TextField
                {...getInputProps()}
                value={get(selectedCrew, 'name', '')}
                variant="outlined"
                placeholder="Select crew"
                size="small"
                onClick={handleCrewClick}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        getInputProps().onBlur();
                        getClearProps().onClick();
                        setSelectedCrew(null);
                      }}
                      position="end">{!isNull(selectedCrew) && selectedCrew !== '' && <Close />}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          {groupedOptions.length > 0 ? (
            <ul {...getListboxProps()} className={classes.projectsDropDownList}>
              {groupedOptions.map((option, index) => (
                <li {...getOptionProps({ option, index })}>
                  <Typography variant="subtitle2">{get(option, 'name')}</Typography>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </FormControl>
    );
  }
};

// const customCompare = (prevProps, nextProps) => {
//     // console.log('xxx: ', prevProps);
//     // console.log('yyy: ', nextProps);
//   if (prevProps.loadingData !== nextProps.loadingData) {
//     return false;
//   }
//
//   if (prevProps.expanded !== nextProps.expanded) {
//     return false;
//   }
//
//   if (!isEqual(prevProps.timesheetData, nextProps.timesheetData)) {
//     // console.log('prevProps: ', prevProps);
//     // console.log('nextProps: ', nextProps);
//     return false;
//   }
//
//   // return false; // props are not equal -> update the component
//   return true; // props are equal -> dont update the component
// }

// CompanyTimesheet.whyDidYouRender = true

export default memo(ProjectTimesheet);
