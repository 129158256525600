import React, { useEffect, useState } from 'react'
import { CircularProgress, ThemeProvider, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, InputAdornment, MenuItem, ListItemIcon, ListItemText, createStyles, makeStyles, withStyles, Tooltip, Typography } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import StopIcon from '@material-ui/icons/Stop';
import { isArray, isEmpty, get, values, keys, isEqual, size, clone } from 'lodash';
import axios from "axios"
import { myTheme, axiosConfig } from '../../helpers/index'
import { notificationCenter } from '../../helpers/notifications';

const useStyles = makeStyles((theme) => ({
  select: {
    width: '250px'
  },
  emptyClassifierSquare: {
    margin: 1,
    width: 20,
    height: 20,
    borderRadius: 2,
    backgroundColor: 'transparent',
    border: '2px solid rgba(151,151,151,0.5)'
  }
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(12),
  },
}))(Tooltip);

const ClassifierSelectDialog = ({ companyId, classifiersEndpoint, shift, appliedClassifiers }) => {

  const classes = useStyles();

  let _classifiers = isArray( get( appliedClassifiers, 'classifiers')) ? get ( appliedClassifiers, 'classifiers') : [];

  const [show, setShow] = useState(false);
  const [fetchingClassifiers, setFetchingClassifiers] = useState(false);
  const [applyingClassifiers, setApplyingClassifiers] = useState(false);
  const [classifiers, setClassifiers] = useState(null);
  const [selectedClassifiers, setSelectedClassifiers] = useState(Array(3).fill(''));
  const [revertSelectedClassifiers, setRevertSelectedClassifiers] = useState([]);

  const handleClose = () => {
    setShow(false)
    revertChanges()
  };

  const handleOpen = () => {
      if (companyId) {
        setShow(true)

        if (!classifiers) {
          setFetchingClassifiers(true)
          loadClassifiers()
        }
      }
  }

  const loadClassifiers = () => {
    return axios.post(`${classifiersEndpoint}/${companyId}/list`, { appliesTo: 'shifts', active: true }, axiosConfig())
    // .catch(console.error)
    .catch(error => {
      notificationCenter('error', get(error.response, 'data.message', 'Error fetching classifiers.'))
      setShow(false)
      setFetchingClassifiers(false)
    })
    .then(resp => {
      setClassifiers(resp.data)
    })
  }

  const handleClassifierSelect = (e, classifier, classifierIndex) => {
    let temp = clone(selectedClassifiers)

    temp[classifierIndex] = e.target.value

    setSelectedClassifiers(temp)
  }

  const returnToolTipContent = () => {
    return(
      <React.Fragment>
        <Typography color="inherit">Click to apply classifiers to this shift.</Typography>
      </React.Fragment>
    )
  }

  const handleApplyClassifiers = () => {
    setApplyingClassifiers(true);

    let payload = {
      classifiers: []
    }

    selectedClassifiers.map((oneSelectedClassifier, oneSelectedClassifierIndex) => {
      if (!isEmpty(oneSelectedClassifier)) {
        payload.classifiers.push({
          classifierName: get( classifiers[oneSelectedClassifierIndex], 'name'),
          classifierId: get( classifiers[oneSelectedClassifierIndex], '_id'),
          itemCode: oneSelectedClassifier.split('_&_')[0],
          itemColor: oneSelectedClassifier.split('_&_')[1] ? oneSelectedClassifier.split('_&_')[1] : ''
        })
      }
    })

    return axios.patch(`${classifiersEndpoint}/${companyId}/applyTo/shift/${get(shift, '_id.$oid')}`, payload, axiosConfig())
    // .catch(console.error)
    .catch(error => {
      notificationCenter('error', get(error.response, 'data.message', 'Error applying classifiers.'))
      setApplyingClassifiers(false)
    })
    .then(resp => {
      if ( isEqual( get(resp, 'status'), 200)) {
        setShow(false)
        setApplyingClassifiers(false)
        notificationCenter('success', 'Classifiers applied successfully.')
      } else {
        notificationCenter('error', 'Error applying classifiers.')
        setApplyingClassifiers(false)
      }
    })
  }

  const setInitSelectedClassifiers = () => {
    let selectedClassifiersClone = clone(selectedClassifiers)

    _classifiers.map((oneShiftClassifier) => {
      classifiers.map((oneClassifier, oneClassifierIndex) => {
        if (isEqual( get( oneShiftClassifier, 'classifierName'), get( oneClassifier, 'name'))) {
          selectedClassifiersClone[oneClassifierIndex] = `${get(oneShiftClassifier, 'itemCode', '')}_&_${get(oneShiftClassifier, 'itemColor', '')}`
        }
      })
    })

    setSelectedClassifiers(selectedClassifiersClone)
    setRevertSelectedClassifiers(selectedClassifiersClone)
    setFetchingClassifiers(false)
  }

  const revertChanges = () => {
    setSelectedClassifiers(revertSelectedClassifiers)
  }

  const renderEmptyClassifiersGrid = () =>
    <div style={{ display: 'flex', padding: '0px 4px 5px', minWidth: 80, maxWidth: 80, flexWrap: 'wrap', cursor: 'pointer' }} onClick={handleOpen}>
      { Array.apply(null, Array(3)).map(oneItem => <div className={classes.emptyClassifierSquare} /> ) }
    </div>

  const renderInitClassifiersGrid = () => {
    let tempClassifiersArray = clone(_classifiers)

    if (!isEmpty(_classifiers) && size(_classifiers) < 3) {
      tempClassifiersArray = tempClassifiersArray.concat(Array(3 - size(_classifiers)).fill({}))
    }

    return (
      <div style={{ display: 'flex', padding: '0px 4px 5px', minWidth: 80, maxWidth: 80, flexWrap: 'wrap', cursor: 'pointer' }} onClick={handleOpen}>
        {
          tempClassifiersArray.map(oneClassifier => {
            return (
                <CustomTooltip title={(oneClassifier.classifierName && oneClassifier.itemCode) ? `${oneClassifier.classifierName}: ${oneClassifier.itemCode}` : ''} arrow>
                  <div style={{
                    margin: 1,
                    width: 20,
                    height: 20,
                    borderRadius: 2,
                    backgroundColor: !isEmpty(oneClassifier.itemColor) ? oneClassifier.itemColor : 'transparent',
                    border: '2px solid rgba(151,151,151,0.5)'
                  }}>
                  </div>
                </CustomTooltip>
            )
          })
        }
      </div>
    )
  }

  const renderSelectedClassifiersGrid = () => {
    return (
      <div style={{ display: 'flex', padding: '0px 4px 5px', minWidth: 80, maxWidth: 80, flexWrap: 'wrap', cursor: 'pointer' }} onClick={handleOpen}>
        { selectedClassifiers.map((oneSelectedClassifier, oneSelectedClassifierIndex) => {
            if (!isEmpty(oneSelectedClassifier)) {
              return (
                <CustomTooltip title={`${get( classifiers[oneSelectedClassifierIndex], 'name')}: ${oneSelectedClassifier.split('_&_')[0]}`} arrow>
                  <div style={{ margin: 1, width: 20, height: 20, borderRadius: 2, backgroundColor: oneSelectedClassifier.split('_&_')[1] ? oneSelectedClassifier.split('_&_')[1] : 'transparent', border: '2px solid rgba(151,151,151,0.5)'}} />
                </CustomTooltip>
              )
            } else {
              return (
                <div className={classes.emptyClassifierSquare} />
              )
            }
          })
        }
      </div>
    )
  }

  useEffect(() => {
    if (!isEmpty(classifiers)) {
      setInitSelectedClassifiers()
    }
  }, [classifiers])

  useEffect(() => {
    if (show && !isEmpty(classifiers)) {
      setRevertSelectedClassifiers(selectedClassifiers)
    }
  }, [show])

  if (!classifiers) {
    return(
      <ThemeProvider theme={myTheme}>
        <div style={{ display: 'flex' }}>
          { fetchingClassifiers ? <CircularProgress color="primary" size={21} /> :
          !isEmpty(_classifiers) ?
            renderInitClassifiersGrid() :
            <HtmlTooltip title={returnToolTipContent()}>
              { renderEmptyClassifiersGrid() }
            </HtmlTooltip>
          }
        </div>
      </ThemeProvider>
    )
  } else {
    return (
      <ThemeProvider theme={myTheme}>
        <div style={{ display: 'flex' }}>
          { renderSelectedClassifiersGrid() }
        </div>
        <Dialog open={show} onClose={handleClose}>
          <DialogTitle>Apply Classifiers</DialogTitle>
          <DialogContent>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {
                !isEmpty(classifiers) && classifiers.map((oneClassifier, oneClassifierIndex) =>
                  <div key={oneClassifier._id} style={{ display: 'flex' }}>
                    <FormControl style={{ width: 260, marginBottom: 12 }}>
                      <InputLabel id={`${oneClassifier.name}-select-label`}>{oneClassifier.name}</InputLabel>
                      <Select
                        required
                        classes={{ root: classes.select }}
                        id={`${oneClassifier.name}-select`}
                        // value={selectedClassifiers[`${oneClassifier.name}_&_${oneClassifier._id}`]}
                        value={selectedClassifiers[oneClassifierIndex]}
                        onChange={(e) => { handleClassifierSelect(e, oneClassifier, oneClassifierIndex)}}>
                        { !isEmpty(selectedClassifiers[oneClassifierIndex]) && <MenuItem key={'000'} value="" style={{ color: 'grey' }}>None</MenuItem>}
                        {
                          oneClassifier.items.map(item =>
                            <MenuItem key={get(item, 'code')} value={`${get(item, 'code')}_&_${get(item, 'color', '')}`}>
                              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <ListItemText primary={get(item, 'name')} />
                                { item.color && <StopIcon style={{ color: item.color }} /> }
                              </div>
                            </MenuItem>
                          )
                        }
                      </Select>
                    </FormControl>
                  </div>
                )
              }
              {
                isEmpty(classifiers) &&
                <div>No active classifiers to select from.</div>
              }
            </div>
          </DialogContent>
          <DialogActions>
            { !isEmpty(classifiers) ?
              <><Button onClick={handleClose} disabled={applyingClassifiers}>
                Cancel
              </Button>
              <Button onClick={handleApplyClassifiers} color="primary" disabled={applyingClassifiers}>
                { applyingClassifiers ? <CircularProgress color="primary" size={18} /> : 'Apply' }
              </Button></> :
              <Button color="primary" onClick={handleClose}>
                Ok
              </Button>
            }
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    )
  }
}

export default ClassifierSelectDialog;
