import moment from "moment"

export const Worker = (_worker, config) => {
    const position = _worker.company_position

    return {
        fullName: `${_worker.first_name} ${_worker.last_name}`,
        position: position ? position.name : "",
        phoneNumber: formatPhoneNumber(_worker.phone_number),
        profileImage: () => {
            if (_worker.image_path && _worker.image_path.length > 0) {
                return `${config.imageBasePath}${_worker.image_path}`
            }

            return config.noImagePath
        }
    }
}

export const Punch = _punch => {

    return {
        punchTime: () => {

            if (_punch) {

                const punchTimeParsed = new Date(_punch.punch_time * 1000)
                return moment(punchTimeParsed).format("MM/DD hh:mm a")
            }

            return "N/A"
        }
    }
}

export const Shift = _shift => {
    const project = _shift.project
    const company = _shift.company
    return {
        projectName: project ? project.name : "N/A",
        companyName: company ? company.name : "N/A",
        breakTime: _shift.break_time ? `${_shift.break_time} minutes` : '',
        totalHours: () => {

            const a = new Date(_shift.punch_in.punch_time * 1000)
            const b = new Date(_shift.punch_out.punch_time * 1000)

            return moment(b).diff(moment(a), 'hours')
        }
    }
}

export const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return ""
}