import React from 'react';
import { noop } from './helpers';
import { ThemeProvider, Table, TableHead, TableCell, TableRow, TableBody, TableFooter, TablePagination, LinearProgress } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { myTheme, axiosConfig } from "./helpers";
import { Punch, Worker, Shift } from "./helpers/formatters";
import axios from "axios";
import PunchImages from "./PunchImages";
import ShiftcostcodeOutline from "./ShiftcostcodeOutline";
import Breaktime from './Breaktime';
import PunchTime from './PunchTime';
import Approved from './Approved';
import RoundupTime from './RoundupTime';
import PunchFlip from './PunchFlip';

const ShiftsTable = ({ loading, shiftsPagination, page, perPage, onChangePage, onChangeRowsPerPage, config, onShiftUpdate }) => {

  const makeOnShiftUpdate = (shift, index, punchType) => {
    return (punch) => {
      shift[punchType] = punch
      onShiftUpdate(shift, index)
    }
  }

  const makeOnFlip = (oldShift, index) => {
    return (newShift) => {
      onShiftUpdate(newShift, index)
    }
  }

  const makeEditLink = (_shift) => {
    const punchIn = _shift.punch_in
    if (punchIn) {
      const punchInTime = punchIn.punch_time
      const parsedTime = new Date(punchInTime * 1000)
      const fmt = encodeURI(`${parsedTime.getMonth() + 1}/${parsedTime.getDate()}/${parsedTime.getFullYear()}`)

      return `${location.origin}/projects/${_shift.project_id.$oid}?timesheet_date=${fmt}`
    }
  }

  const loadingSection = () => (
    <TableRow>
      <TableCell colSpan="10">
        <LinearProgress />
      </TableCell>
    </TableRow>
  )
  return (
    <div className="table-responsive-material" >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Worker Name</TableCell>
            <TableCell align="right">Shift Project</TableCell>
            <TableCell align="right">Shift Company</TableCell>
            <TableCell align="right">Phone Number</TableCell>
            <TableCell align="right">Breaktime</TableCell>
            <TableCell align="right">Time In</TableCell>
            <TableCell align="right">Time Out</TableCell>
            <TableCell align="left">Hours</TableCell>
            <TableCell align="right">Approved</TableCell>
            <TableCell colSpan="1" align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && loadingSection()}
          {!loading && shiftsPagination.data.map( (shift, index) => {
            const punchInFormatter = Punch(shift.punch_in)
            const punchOutFormatter = Punch(shift.punch_out)
            const workerFormatter = Worker(shift.employee, config)
            const shiftFormatter = Shift(shift)
            return (
              <TableRow key={shift._id.$oid} style={{ backgroundColor: shift.type == 'transfer' ? '#e4e8ec' : '' }}>
                <TableCell align="left">
                  <a href={`/employees/${shift.employee_id.$oid}/edit`}>
                    {workerFormatter.fullName}
                  </a>
                </TableCell>
                <TableCell align="right">
                  <a href={`/projects/${shift.project_id.$oid}`}>
                    {shiftFormatter.projectName}
                  </a>
                </TableCell>
                <TableCell align="right">{shiftFormatter.companyName}</TableCell>
                <TableCell align="right">{workerFormatter.phoneNumber}</TableCell>
                <TableCell align="right">
                  <Breaktime shift={shift} />
                </TableCell>
                <TableCell align="right">
                  {shift.punch_in && (
                    <>
                      <PunchFlip shift={shift} onUpdate={makeOnFlip(shift, index)} />
                      <RoundupTime punch={shift.punch_in} onUpdate={makeOnShiftUpdate(shift, index, "punch_in")} />
                      <PunchImages punch={shift.punch_in} worker={shift.employee} config={config} />
                      <PunchTime punch={shift.punch_in} onUpdate={makeOnShiftUpdate(shift, index, "punch_in")} />
                    </>
                  )}
                </TableCell>
                <TableCell align="right">
                  {shift.punch_out && (
                    <>
                      <PunchFlip shift={shift} onUpdate={makeOnFlip(shift, index)} />
                      <RoundupTime punch={shift.punch_out} onUpdate={makeOnShiftUpdate(shift, index, "punch_out")} />
                      <PunchImages punch={shift.punch_out} worker={shift.employee} config={config} />
                      <PunchTime punch={shift.punch_out} onUpdate={makeOnShiftUpdate(shift, index, "punch_out")} />
                    </>
                  )}
                </TableCell>
                <TableCell align="right">
                  {shift.punch_in && shift.punch_out && (
                    <ShiftcostcodeOutline shift={shift} worker={shift.employee} config={config} />
                  )}
                </TableCell>
                <TableCell align="right">
                  <Approved shift={shift} />
                </TableCell>
                <TableCell align="right">
                  <a href={makeEditLink(shift)}>
                    <Edit />
                  </a>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              align="left"
              count={shiftsPagination.count}
              page={page}
              onChangePage={onChangePage}
              rowsPerPage={perPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
}

export default ShiftsTable;
