export const allColumns = [
  {
    id: "active",
    isActive: true,
    // isImage: true,
    sortOption: true,
    label: "Active"
  },
  {
    id: "name",
    isActive: true,
    // isImage: true,
    sortOption: true,
    label: "Name"
  },
  {
    id: "code",
    sortOption: true,
    isActive: true,
    label: "Code"
  },
  {
    id: "numberOfItems",
    sortOption: true,
    isActive: true,
    label: "# of Items"
  },
  {
    id: "appliesTo",
    sortOption: true,
    isActive: true,
    label: "Applies to"
  },
  {
    id: "actions",
    // sortOption: true,
    isActive: true,
    label: "Actions"
  }
];
