import React from 'react'
import { ShowCardEmployee } from '../index'


export default function EmployeeCards(props) {

  const { data } = props;

  return (
    <div className='row'>
      {data.map((item, idx) => (
        <div key={idx} className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
          <ShowCardEmployee card={item} />
        </div>
      ))}
    </div>
  )
};