import React, { useState } from 'react';

import PlacesAutocomplete, {
  geocodeByAddress
} from 'react-places-autocomplete';
import { TextField } from '@material-ui/core';

export default function AddressAutocomplete(props) {

  const { onAddressSelected, currentAddress } = props;

  const [address, setAddress] = useState(currentAddress);

  const handleChange = address => {
    setAddress(address);
    onAddressSelected(address)
  };


  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        const selected = results[0];
        console.log(selected)
        setAddress(selected.formatted_address);
        onAddressSelected(selected.formatted_address)
      })
      .catch(error => console.error('Error', error));
  };

  const onError = (status, clearSuggestions) => {
    console.log('Google Maps API returned error with status: ', status)
    clearSuggestions()
  }
  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      onError={onError}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <TextField
            style={{ width: '100%' }}
            id="outlined-basic"
            label="Address"
            {...getInputProps({
              placeholder: 'Address...',
              className: 'location-search-input',
            })} />

          <div className="autocomplete-dropdown-container" style={{ marginTop: '2px' }}>
            {/* {loading && <div>Loading...</div>} */}
            {suggestions.map((suggestion, idx) => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div key={idx}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>

        </div>
      )}
    </PlacesAutocomplete>
  )
};