import React from 'react';
import { get, set } from "lodash";
import { Edit } from '@material-ui/icons';
import { editCompanyProfiles } from '../../services/profileServices'
import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
const pxToRem = (px, oneRemPx = 17) => `${px / oneRemPx}rem`;
const borderWidth = 2;
const width = pxToRem(56);
const height = pxToRem(34);
const size = pxToRem(22);
const gap = (34 - 22) / 2;
const LovelySwitch = withStyles((theme) => ({
  root: {
    width,
    height,
    padding: 0,
    margin: theme.spacing(1),
    overflow: 'unset',
  },
  switchBase: {
    padding: pxToRem(gap),
    '&$checked': {
      color: '#fff',
      transform: `translateX(calc(${width} - ${size} - ${pxToRem(2 * gap)}))`,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
      '& $thumb': {
        backgroundColor: '#fff',
      },
    },
  },
  track: {
    borderRadius: 40,
    border: `solid ${theme.palette.grey[400]}`,
    borderWidth,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    boxSizing: 'border-box',
  },
  thumb: {
    boxShadow: 'none',
    backgroundColor: theme.palette.grey[400],
    width: size,
    height: size,
  },
  checked: {},
}))(Switch);

export default function EditDefault(props) {

  const { profile, onLoading, onEditDefault, payment_profile_endpoint } = props;

  const onEdit = async () => {
    try {
      set(profile, 'default', !get(profile, 'default', false));
      onLoading(true);
      await editCompanyProfiles(get(profile, 'company_id'), get(profile, '_id'), { ...profile }, payment_profile_endpoint);
      onEditDefault()
    } catch (error) {

    }
  }


  return (
    <div>
      {/* {get(profile, 'default') ? "True" : "False"} */}
      <LovelySwitch color="primary" checked={get(profile, 'default', false)} onChange={onEdit} />
      {/* <Edit style={{ marginLeft: '10px', marginBottom: '3px', fontSize: '15px' }} onClick={onEdit} /> */}
    </div>
  )
};