import React, { useState } from "react"
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types"
import axios from "axios"


const WorkerDetails = ({ id, name, noImagePath, imageBasePath }) => {

  const [show, setShow] = useState(false);
  const [worker, setWorker] = useState({});


  const handleClose = () => setShow(false);
  const openModal = () => {
    loadWorker().then(response => {
      console.log({ response });

      setWorker(response.data)
    })


    setShow(true)
  }


  const loadWorker = () => {
    let url = `${window.location.protocol}//${window.location.host}/employees/${id}.json`
    return axios.get(url).catch(console.error)
  }

  let position = "N/A"
  let department = "N/A"
  let phoneNumber = "N/A"
  let imagePath = noImagePath

  if (worker.company_position) {
    position = worker.company_position.name
  }

  if (worker.department) {
    department = worker.department.name
  }
  
  if (worker.image_path) {
    imagePath = imageBasePath + worker.image_path
  }

  if (worker.phone_number) {
    phoneNumber = <a href={`tel:${worker.phone_number}`}>{worker.phone_number}</a>
  }

  return (
    <>
      <a onClick={openModal}>{name}</a>

      <Modal show={show} onHide={handleClose} size="md" animation={false} backdrop={true} backdropClassName="modal-backdrop fade in" >
        <Modal.Header>
          <Modal.Title >Worker Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="vertical-flexbox">
            
            <img src={imagePath} className="thumb-circle" alt=""/>

            <h2>
              {name}
            </h2>
            <h4>
              {phoneNumber}
            </h4>
            <strong>
              {position}
            </strong>
            <strong>
              {department}
            </strong>

            <table className="table" style={{ whiteSpace: "nowrap" }}>
              <thead>
                <tr>
                  <th scope="col">Emergency Name</th>
                  <th scope="col">Emergency Email</th>
                  <th scope="col">Emergency Phone</th>
                  <th scope="col">Emergency Relation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="col">{worker.emergency_name}</th>
                  <th scope="col">{worker.emergency_email}</th>
                  <th scope="col">{worker.emergency_phone_number}</th>
                  <th scope="col">{worker.emergency_relation}</th>
                </tr>
              </tbody>
            </table>

          </div>

        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  )
}

WorkerDetails.propTypes = {
  id: PropTypes.string
};
export default WorkerDetails
