import React, { useState, useEffect } from 'react';
import { Avatar, Grid, FormControl, MenuItem, InputLabel, makeStyles, Select, TextField, FormControlLabel, Radio, FormLabel, RadioGroup, Switch, Button, CircularProgress, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { get, isEmpty, size, has, isNull } from 'lodash';
import AddressAutocomplete from '../../../../addressAutocomplete/AddressAutocomplete';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { notificationCenter } from '../../../../helpers/notifications';
import moment from 'moment';
import { createWorkerCompany } from '../../../services/employeeService';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/styles';
import workerProfileIcon from '../../../../../../assets/images/worker_types/constructionWorkerIcon-2023.svg';
import AddIcon from '@material-ui/icons/Add';

import enIcon from '../../../../../../assets/images/en-checked.svg';
import enIconOff from '../../../../../../assets/images/en-unchecked.svg';
import esIcon from '../../../../../../assets/images/es-checked.svg';
import esIconOff from '../../../../../../assets/images/es-unchecked.svg';

const useStyles = makeStyles((theme) => ({
  mainContent: {
    backgroundColor: '#f3f3f4',
    height: "100%",
    width: "100%",
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  workerForm: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  inputStyle: {
    width: '250px'
  },
  inputDiv: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  formControlLabelPicker: {
    width: '100%',
    marginTop: '0px !important',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  requiredFields: {
    fontSize: '12px',
    color: "red"
  },
  accordion: {
    backgroundColor: '#f3f3f4'
  },
  avatar: {
    position: 'relative',
    zIndex: 2,
    width: 100,
    height: 100,
    marginRight: 16,
    '&:hover': {
      '& $clearProfileImageButton': {
        opacity: 1,
      },
    },
  },
  addIconBadge: {
    position: 'absolute',
    top: -8,
    right: -8,
    color: '#36C240',
    fontSize: '4rem'
  },
  workerBasicInfoTop: {
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#f3e3df',
    margin: -20,
    padding: 20,
    borderRadius: 0,
    borderBottom: '2px solid #f1673440',
    position: 'relative'
  },
  titleAndCompanyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 105
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
}));

const pxToRem = (px, oneRemPx = 17) => `${px / oneRemPx}rem`;
const borderWidth = 2;
const width = pxToRem(56);
const height = pxToRem(34);
const toggleSize = pxToRem(22);
const gap = (34 - 22) / 2;

const LovelySwitch = withStyles((theme) => ({
  root: {
    width,
    height,
    padding: 0,
    margin: theme.spacing(1),
    overflow: 'unset',
  },
  switchBase: {
    padding: pxToRem(gap),
    '&$checked': {
      color: '#fff',
      transform: `translateX(calc(${width} - ${toggleSize} - ${pxToRem(2 * gap)}))`,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
      '& $thumb': {
        backgroundColor: '#fff',
      },
    },
  },
  track: {
    borderRadius: 40,
    border: `solid ${theme.palette.grey[400]}`,
    borderWidth,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    boxSizing: 'border-box',
  },
  thumb: {
    boxShadow: 'none',
    backgroundColor: theme.palette.grey[400],
    width: toggleSize,
    height: toggleSize,
  },
  checked: {},
}))(Switch);


export default function CreateWorker(props) {

  const { company, companies, companyWorkersDomain, onCreateWorker, employee_settings_endpoint, projectEndpoint } = props;

  const [isValid, setIsValid] = useState(true)
  const [showMessage, setShowMessage] = useState({
    status: false,
    message: ""
  });

  const [state, setState] = useState({
    currentCompany: company,
    showHireRate: false,
    editing: false,
    worker: {
      language: 'en',
      company_id: get(company, 'value'),
      mobileAppSettings: {
        enableMobileApp: false

      }
    }
  });

  const onChangeBasic = (key, value) => {

    switch (key) {
      case 'company_id':
        setIsValid(!isEmpty(value))
        break;
      case 'phone_number':
        setIsValid(!isEmpty(value))
        break;
      case 'first_name':
        setIsValid(!isEmpty(value))
        break;
      case 'last_name':
        setIsValid(!isEmpty(value))
        break;
      case 'confirm_phone_number':
        setIsValid(!isEmpty(value) && state.worker['phone_number'] === value)
        break;
      default:
        break;
    }

    setState(prevState => ({
      ...prevState,
      worker: {
        ...prevState.worker,
        [key]: value
      }
    }))
  }

  const onAddressSelected = address => {
    setState(prevState => ({
      ...prevState,
      worker: {
        ...prevState.worker,
        address
      }
    }))
  }

  const onCompanyChange = e => {
    const tempCompany = companies.find(item => get(item, 'value') === e)

    setState(prevState => ({
      ...prevState,
      currentCompany: tempCompany
    }))

    onChangeBasic('company_id', e);
  }

  const onChangeDOB = (e) => {
    if (moment().diff(moment(e).format('MM/DD/YYYY'), 'years') >= 16) {
      onChangeBasic('dob', e)
    } else {
      notificationCenter('warning', "Age should be more than 16 years.Please enter a valid Date of Birth")
    }

  }

  const onCreate = async () => {

    try {

      if (!isValid) {
        setShowMessage({
          status: true,
          message: "Some fields are required"
        })
      } else {
        setShowMessage({
          status: false,
          message: "Some fields are required"
        });
        setState(prevState => ({
          ...prevState,
          editing: true
        }));

        setIsValid(false);

        const res = await createWorkerCompany(companyWorkersDomain, state.worker);



        setState(prevState => ({
          ...prevState,
          editing: false
        }))
        notificationCenter('success', get(res, 'data.message'))
        onCreateWorker(false, get(res, 'data.worker'));
      }

    } catch (error) {
      onCreateWorker(false);
      notificationCenter('error', get(error.response, 'data.error'));

    }
  }

  const handleToggle = (name) => {
    setState(prevState => ({
      ...prevState,
      worker: {
        ...prevState.worker,
        mobileAppSettings: {
          ...prevState.worker.mobileAppSettings,
          [name]: !prevState.worker.mobileAppSettings[name]
        }
      }
    }))
  }

  const classes = useStyles();


  return (
    <div className={classes.mainContent}>
      <div className={classes.workerBasicInfoTop}>
        <div className={classes.avatar}>
          <Avatar classes={{ root: classes.avatar }} atl='Worker' src={workerProfileIcon} />
          <AddIcon className={classes.addIconBadge}/>
        </div>
        <div className={classes.titleAndCompanyWrapper}>
          <div className={classes.titleWrapper}>
            Create New Worker
          </div>
          <div className={classes.inputDiv}>
            {size(companies) > 0 ?
              <FormControl required className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Company</InputLabel>
                <Select
                  // className={classes.inputStyle}
                  displayEmpty={true}
                  value={get(state, 'currentCompany.value', '')}
                  onChange={(e) => onCompanyChange(e.target.value)}
                  >
                  <MenuItem style={{ height: '30px' }} key={null} value=""></MenuItem>
                  {companies.map(item => <MenuItem key={get(item, 'value')} value={get(item, 'value')}>{get(item, 'label')}</MenuItem>)}
                </Select>
              </FormControl> :
              <span>{get(worker, 'company.name')}</span>
            }
          </div>
        </div>
      </div>
      <hr style={{ width: '100%', color: '#d6d6d6' }} />
      <div className={classes.workerForm}>
        <div className="worker-form-information">
          <h3 style={{ marginTop: '0px', marginBottom: '5px' }}>Worker Information {get(showMessage, 'status') ? <i className={classes.requiredFields}>{get(showMessage, 'message')}</i> : ''}</h3>

          <div className=''>
            <div className={classes.inputDiv}>
              <TextField id="firstName" required onChange={(e) => onChangeBasic('first_name', e.target.value)} value={get(state, 'worker.first_name')} label="First Name" className={classes.inputStyle} />
              <TextField id="lastName" required onChange={(e) => onChangeBasic('last_name', e.target.value)} value={get(state, 'worker.last_name')} label="Last Name" className={classes.inputStyle} />
            </div>
            <div className={classes.inputDiv}>
              <TextField id="phoneNumber" required label="Phone Number" onChange={(e) => onChangeBasic('phone_number', e.target.value)} value={get(state, 'worker.phone_number')} className={classes.inputStyle} />
              <TextField id="confirmPhoneNumber" required onChange={(e) => onChangeBasic('confirm_phone_number', e.target.value)} value={get(state, 'worker.confirm_phone_number')} label="Confirm Phone Number" className={classes.inputStyle} />
            </div>

            <div className={classes.inputDiv}>
              <TextField id="email" onChange={(e) => onChangeBasic('email', e.target.value)} value={get(state, 'worker.email')} label="Email" className={classes.inputStyle} />
            </div>

            <div className={classes.inputDiv}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Department</InputLabel>
                <Select
                  className={classes.inputStyle}
                  displayEmpty={true}
                  id="demo-simple-select"
                  value={get(state, 'worker.department_id', '')}
                  onChange={(e) => onChangeBasic('department_id', e.target.value)}
                >
                  <MenuItem style={{ height: '30px' }} key={null} value=""></MenuItem>
                  {get(state, 'currentCompany.departments', []).map(department => <MenuItem key={get(department, 'value')} value={get(department, 'value')}>{get(department, 'label')}</MenuItem>)}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Position</InputLabel>
                <Select
                  displayEmpty={true}
                  className={classes.inputStyle}
                  id="demo-simple-select"
                  value={get(state, 'worker.company_position_id', '')}
                  onChange={(e) => onChangeBasic('company_position_id', e.target.value)}
                >
                  <MenuItem style={{ height: '30px' }} key={null} value=""></MenuItem>
                  {get(state, 'currentCompany.positions', []).map(position => <MenuItem key={get(position, 'value')} value={get(position, 'value')}>{get(position, 'label')}</MenuItem>)}

                </Select>
              </FormControl>

            </div>
            <Grid container style={{ paddingTop: 16 }}>
              <Grid item xs={6}>

                <FormControl>
                  <FormLabel>Select language</FormLabel>

                  <RadioGroup
                    name="language_code"
                    row
                    value={get(state, 'worker.language_code', 'en')}
                    onChange={(e) => onChangeBasic('language_code', e.target.value)}
                    style={{ paddingLeft: 4, minWidth: 140 }}>
                    <FormControlLabel
                      value="en"
                      control={
                        <Radio
                          icon={<img src={enIconOff} width='40' />}
                          checkedIcon={<img src={enIcon} width='40' />}
                          checked={get(state, 'worker.language_code', 'en') === 'en'} />
                      }
                      />
                    <FormControlLabel
                      value="es"
                      control={
                        <Radio
                          icon={<img src={esIconOff} width='40' />}
                          checkedIcon={<img src={esIcon} width='40' />}
                          checked={get(state, 'worker.language_code') === 'es'} />
                      }
                      />

                  </RadioGroup>

                </FormControl>
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: 20 }}>
                <FormControl style={{ display: 'flex', flexDirection: 'column' }} fullWidth>
                  <FormControlLabel
                    control={<LovelySwitch color="primary" onChange={(e) => onChangeBasic("active", e.target.checked)} checked={get(state, 'worker.active', false)} />}
                    label="Active"
                    />
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    control={<LovelySwitch color="primary" onChange={(e) => onChangeBasic("use_salary", e.target.checked)} checked={get(state, 'worker.use_salary', false)} />}
                    label="Use Salary"
                    />
                </FormControl>
              </Grid>
            </Grid>
            <div>
              <AddressAutocomplete onAddressSelected={onAddressSelected} currentAddress={get(state, 'worker.address')} />
            </div>
            <div>
              <FormControl style={{ width: '100%' }} classes={{ root: classes.formControlPicker }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <KeyboardDatePicker
                      disableFuture={true}
                      views={["year", "month", "date"]}

                      className={classes.formControlLabelPicker}
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Date of Birth"
                      value={get(state, 'worker.dob', null)}
                      onChange={(e) => onChangeDOB(e)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />

                  </Grid>
                </MuiPickersUtilsProvider>
              </FormControl>
            </div>


            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  Emergency Contact Information
                </AccordionSummary>
                <AccordionDetails>

                  <div style={{ width: '100%' }}>
                    <div className={classes.inputDiv}>
                      <TextField id="emergency_name" onChange={(e) => onChangeBasic('emergency_name', e.target.value)} value={get(state, 'worker.emergency_name', '')} label="Emergency Contact Name" className={classes.inputStyle} />


                      <TextField id="emergency_phone_number" onChange={(e) => onChangeBasic('emergency_phone_number', e.target.value)} value={get(state, 'worker.emergency_phone_number', '')} label="Phone Number" className={classes.inputStyle} />
                    </div>
                    <div className={classes.inputDiv}>
                      <TextField id="emergency_email" onChange={(e) => onChangeBasic('emergency_email', e.target.value)} value={get(state, 'worker.emergency_email', '')} label="Email" className={classes.inputStyle} />

                      <TextField id="emergency_relation" onChange={(e) => onChangeBasic('emergency_relation', e.target.value)} value={get(state, 'worker.emergency_relation', '')} label="Relationship" className={classes.inputStyle} />
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  Mobile App Settings
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ display: "flex", flexDirection: 'column' }}>
                    <FormControlLabel
                      control={<LovelySwitch color="primary" checked={get(state, 'worker.mobileAppSettings.enableMobileApp', true)} onChange={() => handleToggle('enableMobileApp')} name='enableMobileApp' />}
                      label='Enable mobile app'
                    />
                    <FormControlLabel
                      control={<LovelySwitch color="primary" checked={get(state, 'worker.mobileAppSettings.kioskModeEnabled', false)} onChange={() => handleToggle('kioskModeEnabled')} name='kioskModeEnabled' />}
                      label='Enable kiosk mode'
                    />
                    <FormControlLabel
                      control={<LovelySwitch color="primary" checked={get(state, 'worker.mobileAppSettings.disableGeofence', false)} onChange={() => handleToggle('disableGeofence')} name='disableGeofence' />}
                      label='Disable geofence'
                    />
                    <FormControlLabel
                      control={<LovelySwitch color="primary" checked={get(state, 'worker.mobileAppSettings.selfProjectsOnly', false)} onChange={() => handleToggle('selfProjectsOnly')} name='selfProjectsOnly' />}
                      label='Restrict to user projects'
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
        <div>
          <Button
            variant="outlined"
            onClick={() => onCreateWorker(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="outlined"
            disabled={!isValid}
            style={{ marginLeft: '5px' }}
            onClick={onCreate}>
            Save
          </Button>
        </div>

      </div>


    </div>
  )
};
