import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, CircularProgress, TableCell, TableRow, TextField } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { get, toLower } from 'lodash';
import moment from 'moment';

export const DepartmentsPositionItem = ({ row, elements, onSave, onDelete }) => {
    const [dirty, setDirty] = useState(false);
    const [errors, setErrors] = useState({ name: false, number: false });
    const [loading, setLoading] = useState({ save: false, delete: false });
    const [items, setItems] = useState(elements);

    useEffect(() => setItems(elements));

    const handleChangeName = ({ target }) => {
        row.name = target.value;
        setDirty(true);
        validateName();
    };
    const handleChangeNumber = ({ target }) => {
        row.number = target.value;
        setDirty(true);
        validateNumber();
    };
    const handleChangeDescription = ({ target }) => {
        row.description = target.value;
        setDirty(true);
    };

    const onClickSave = async () => {
        setLoading({ ...loading, save: true });

        if (onSave) {
            const payload = ({ _id: row._id, name: row.name, number: row.number, description: row.description });
            const data = await onSave(payload);

            row._id = data._id;
            row.created_at = data.created_at;
            row.updated_at = data.updated_at;
        }

        setLoading({ ...loading, save: false });
    };
    const onClickDelete = async () => {
        setLoading({ ...loading, delete: true });

        if (onDelete) {
            await onDelete(row._id);
        }

        setLoading({ ...loading, delete: false });
    };

    const validateName = () => {
        const isDuplicated = items.filter(item => toLower(get(item, 'name')) === toLower(get(row, 'name'))).length > 1;

        setErrors({ ...errors, name: isDuplicated });
    };
    const validateNumber = () => {
        const isDuplicated = items.filter(item => item.number === row.number).length > 1;

        setErrors({ ...errors, number: isDuplicated });
    };

    const canSave = () => (dirty && (!errors.name && !errors.number));
    const canDelete = () => (!errors.name && !errors.number);
    const isItemLoading = () => (loading.save || loading.delete);

    return <TableRow>
        <TableCell component="th" scope="row">
            <TextField
                variant="outlined"
                size="small"
                disabled={isItemLoading()}
                defaultValue={row.name}
                error={errors.name}
                helperText={errors.name ? "Name Duplicated" : ''}
                onChange={handleChangeName}
            />
        </TableCell>
        <TableCell>
            <TextField
                variant="outlined"
                size="small"
                disabled={isItemLoading()}
                defaultValue={row.number}
                error={errors.number}
                onChange={handleChangeNumber}
            />
        </TableCell>
        <TableCell>
            <TextField
                variant="outlined"
                size="small"
                disabled={isItemLoading()}
                defaultValue={row.description}
                onChange={handleChangeDescription}
            />
        </TableCell>
        <TableCell>
            <strong>{row._id && moment(row.createdAt).format('L')}</strong>
        </TableCell>
        <TableCell align="right">
            <strong>{row._id && row.updated_at && moment(row.updatedAt).format('L')}</strong>
        </TableCell>
        <TableCell align={isItemLoading() ? 'center' : 'right'}>
            {isItemLoading()
                ? <CircularProgress size={18} color="primary" />
                : <ButtonGroup variant="contained" size="small">
                    <Button disabled={!canSave() || loading.save} onClick={onClickSave}>
                        <Save />
                    </Button>
                    <Button disabled={!canDelete() || loading.delete} onClick={onClickDelete}>
                        <DeleteIcon color="primary" />
                    </Button>
                </ButtonGroup>}
        </TableCell>
    </TableRow>;
};
