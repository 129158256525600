import React, { useContext } from 'react';
import SectionContent from '../SectionContent';
import Grid from '@material-ui/core/Grid';
import DialogRemoveItem from './DialogRemoveItem';
import DialogCreateItem from './DialogCreateItem';
import { ProjectContext } from '../../ProjectMetrics';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DialogEditItem from './DialogEditItem';
import { GalleryCarousel, GalleryCarouselItem } from '../../../../../reusableComponents/GalleryCarousel';

const WorkSummaryContent = ({ items }) => {
  const { config, user, loaders: { fetchWorkSummary } } = useContext(ProjectContext);

  return (
      <SectionContent
          type="work_summary"
          items={items}
          renderItem={({ item }) => <RenderItem item={item} user={user} config={config} onReload={fetchWorkSummary} />}
          renderToolbar={(company) => <DialogCreateItem company={company} onCreate={fetchWorkSummary} />}
          contentBorderColor='#006e90'
          contentBackgroundColor='#e9f0f3'
          isFirstTab={true}
      >
        <Grid item>
          <span>Submit your work summary by sending a text message to </span>
          <a href="tel:7865894674" className={'text-deep-orange'}>786 589 4674</a>
        </Grid>
      </SectionContent>
  );
};

const RenderItem = ({ item, user, onReload }) => {
  const { employee, notes, pictures } = item;
  const isCurrentUser = () => employee._id === user._id.$oid;

  return (<>
    <Grid xs={true} item>
      <GalleryCarousel max={3}>
        {pictures.map((picture, index) => <GalleryCarouselItem key={index} src={picture} caption={picture} />)}
      </GalleryCarousel>
    </Grid>

    <Grid className="text-right" xs={'auto'} item>
      {isCurrentUser() && (
          <ButtonGroup>
            <DialogEditItem id={item._id} onChange={() => onReload()} value={notes} />
            <DialogRemoveItem id={item._id} onRemove={() => onReload()} />
          </ButtonGroup>)}
    </Grid>
  </>);
};

export default WorkSummaryContent;
