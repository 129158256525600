import React from 'react';
import Select from './Select';

const Contractors = () => {
    
    const handleChange = ev => {
        console.log({ ev });
    }
    
    const opts = [
        {id: 1, name: 'One'},
        {id: 2, name: 'Two'},
        {id: 3, name: 'Three'},
    ]
    
    return (
        <div className="panel">
            <div className="panel-heading"><span className="panel-title">Subcontractors</span></div>
            <div className="panel-body">
                <div className="row">
                    <div className="col-md-4">
                        <Select name="fsd" id="fsdfd" options={opts} onChange={handleChange}/>
                    </div>
                </div>
            </div>
        </div>
    );
};



export default Contractors;
