import React, { useContext, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { get } from 'lodash';
import { axiosConfig } from '../../../../../helpers';
import { ProjectContext } from '../../ProjectMetrics';
import CloseIcon from '@material-ui/icons/Close';

export const DialogAddComment = ({ item }) => {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [saving, setSaving] = useState(false);
  const { config: { project_workSummary_endpoint: endpoint }, project } = useContext(ProjectContext);

  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    setSaving(true);

    const uri = `${endpoint}/${item._id}`;
    const data = { type: 'incident', comment, projectId: get(project, '_id.$oid') };

    await axios.patch(uri, data, axiosConfig());

    setSaving(false);
    handleClose();
  };

  return <div>
    <IconButton onClick={handleClick}>
      <AddIcon />
    </IconButton>

    <Dialog open={open} maxWidth="xs" onClose={handleClose} fullWidth>
      <DialogTitle >
        <div style={{ display: 'flex', width: "100%", justifyContent: 'space-between', alignItems: 'center' }}>
          <b>Add new Comment</b>
          <CloseIcon style={{ cursor: 'pointer', color: '#a7a7a7' }} onClick={handleClose} />
        </div>

      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Comment"
          fullWidth
          multiline
          rows={2}
          variant="standard"
          onChange={({ target }) => setComment(target.value)} />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleSubmit}>{saving ? <CircularProgress size={20} /> : "Save"}</Button>
      </DialogActions>

    </Dialog>
  </div>;
};
