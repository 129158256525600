import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Avatar, Badge, IconButton, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { $axios } from '../../../helpers';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { formatUSNumber } from './broadcastDialog';
import workerProfileIcon from '../../../../../assets/images/worker_types/constructionWorkerIcon-2023.svg';
import { get } from 'lodash';

const useStyles = makeStyles(() => ({
  avatar: {
    width: 35,
    height: 35,
    borderRadius: '50%',
    minWidth: 35,
    minHeight: 35,
  },
  profileBox: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    lineHeight: 1,
  },
}));

export default function BroadcastTargetsDialog({ targets, endpoint, broadcast }) {
  const [loading, setLoading] = useState(false);
  const [workers, setWorkers] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const classes = useStyles();

  function fetchTargets() {
    setLoading(true);

    $axios.$get(`${endpoint}/${broadcast}/target`)
        .then(res => {
          setWorkers(res.targets);
          console.log('setWorkers', res.targets);
        })
        .finally(() => setLoading(false));
  }

  useEffect(() => {
    open && fetchTargets();
  }, [open]);

  return (
      <div>
        <IconButton onClick={handleClickOpen}>
          <Badge badgeContent={targets} color="primary">
            <SupervisorAccountIcon />
          </Badge>
        </IconButton>

        <Dialog open={open} maxWidth={'xs'} fullWidth onClose={handleClose}>
          <DialogTitle>{'Targeted Workers'}</DialogTitle>
          <DialogContent className="p-a-0">
            <List>
              {loading && Array(targets).fill(0).map((fill, idx) => (<>
                <ListItem button key={idx}>
                  <ListItemAvatar>
                    <Skeleton variant="circle" width={40} height={40} />
                  </ListItemAvatar>
                  <ListItemText
                      primary={<Skeleton width={170} variant="text" />}
                      secondary={<Skeleton width={110}  variant="text" />}
                  />
                </ListItem>
              </>))}
              {!loading && workers.map((worker, idx) => (
                  <ListItem button key={idx}>
                    <ListItemAvatar>
                      <Avatar src={get(worker, 'image_path', workerProfileIcon)} className={classes.avatar} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={`${worker.first_name} ${worker.last_name}`}
                        secondary={
                          <a href={`tel:+1${worker.phone_number}`} style={{ fontSize: 10, marginTop: 2 }}>{formatUSNumber(worker.phone_number)}</a>
                        }
                    />
                  </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              <span>Ok</span>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}
