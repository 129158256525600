import React, { useEffect, useState } from 'react';
import DevicesList from './components/devicesList';
import { CardDash } from '../../components';
import { $axios } from '../../../helpers';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import ReplayIcon from '@material-ui/icons/Autorenew';
import moment from 'moment';
import { IconButton } from '@material-ui/core';

export const ProjectDevices = ({ height, project, config }) => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);

  function fetchProjectDevices() {
    setLoading(true);
    $axios.$get(`${config.project_endpoint}/getLinkedDevices/${project._id.$oid}`)
        .then(setDevices)
        .finally(() => setLoading(false));
  }

  function onAliasChange(deviceID, alias) {
    const projectId = project._id.$oid;
    const payload = { alias };

    return $axios.$patch(`${config.project_endpoint}/updateDeviceCustomizations/${projectId}/${deviceID}/`, payload);
  }

  function hasOfflineDevices() {
    return devices.some(device => {
      const lastSeen = moment(device.seen.timestamp * 1000);
      const diff = moment().diff(lastSeen, 'minutes');

      return diff >= 75;
    });
  }

  useEffect(fetchProjectDevices, []);

  return (
      <CardDash
          accordionDetailStyles={{ padding: 0 }}
          title="Devices"
          height={height}
          actions={<>
            <Grid alignItems={'center'} spacing={1} container>
              <Grid item>
                <Chip
                    className={hasOfflineDevices() ? 'bg-danger' : 'bg-success'}
                    size={'small'}
                    label={devices.length || 0} />
              </Grid>
              <Grid item>
                <IconButton
                    disabled={loading}
                    onClick={fetchProjectDevices}>
                  <ReplayIcon className={loading ? 'fa-spin' : ''} />
                </IconButton>
              </Grid>
            </Grid>
          </>}>

        <DevicesList items={devices} onAliasChange={onAliasChange} />
      </CardDash>
  );
};
