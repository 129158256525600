import React from 'react';
import AddPtoTimeModal from './AddPtoTimeModal';

const TimesheetsMenu = (props) => {
  
  const { badge_class, shifts_length, target_modal, project_id, has_actions, company_id, ptoTypes, employees } = props

  return (
    <>
      <span className={`badge font-size-16 ${badge_class}`}>
        {shifts_length}
      </span>
      <span className={`${has_actions ? 'enabled' : 'disabled'} three-dots`} data-toggle="dropdown"></span>
      {(() => {
        if (has_actions) {
          return (
          <ul className="dropdown-menu" style={{left: 'auto', right:0}}>
            <li>
              <a data-target={target_modal} data-toggle="modal" data-project_id={project_id}>Add Punch</a>
            </li>
            {/* <li>
              <AddPtoTimeModal companyId={company_id} ptoTypes={ptoTypes} employees={employees} projectId={project_id}/>
            </li> */}
          </ul>
          )
        }
      })()}
    </>
  );
}

export default TimesheetsMenu;
