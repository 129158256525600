import React from 'react';
import SectionContent from '../SectionContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { CloudDownload } from '@material-ui/icons';
import DialogEmployeeList from './DialogEmployeeList';
import { GalleryCarousel, GalleryCarouselItem } from '../../../../../reusableComponents/GalleryCarousel';

const ToolboxTalkContent = ({ items }) => (
    <SectionContent
        items={items}
        renderItem={({ item }) => <RenderItem item={item} />}
        contentBorderColor='#99c24d'
        contentBackgroundColor='#f4f9ee'
    />
);

const RenderItem = ({ item }) => {
  const { attendance, pictures } = item;

  return (<>
    <Grid xs={1} item>
      <DialogEmployeeList employees={attendance} />
    </Grid>

    <Grid xs={true} item>
      <GalleryCarousel max={3}>
        {pictures.map((picture, index) => <GalleryCarouselItem src={picture} caption={index} />)}
      </GalleryCarousel>
    </Grid>

    <Grid className="text-right" xs={true} item>
      <IconButton component={'span'}>
        <CloudDownload />
      </IconButton>
    </Grid>
  </>);
};

export default ToolboxTalkContent;
