import React, { useState, useEffect } from 'react'
import { get, isEqual, isEmpty } from 'lodash';
import CreateEditProject from './CreateEditProject'
import {
  ThemeProvider,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
  CircularProgress,
  Button,
  Tooltip,
  TableContainer,
  Paper,
  IconButton,
  InputBase,
  Box
} from "@material-ui/core";
import ProjectByUserWizard from './projectByUserWizard/ProjectByUserWizard'
import { makeStyles } from "@material-ui/core/styles";
import { TableChart } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { myTheme, axiosConfig } from './helpers';
import Axios from "axios";
import clsx from 'clsx';

export default function ProjectsTable(props) {

  const { user, isSuperAdmin, projectEndpoint, googleTimezoneEndpoint, googleTimezoneApiKey, availableStates, availableStatesCanada, googleMapsApiKey, companiesEndpoint } = props;
  const classes = useStyles();

  const defaultPagination = { projects: [], totalCount: 0 }
  const [projectsPagination, setProjectsPagination] = useState(defaultPagination);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [innerLoading, setInnerLoading] = useState(true);
  const [listError, setListError] = useState(false);
  const [searchTerm, setSearch] = useState("");
  const [showNewProjectWizard, setShowNewProjectWizard] = useState(false);
  const [projectToEdit, setProjectToEdit] = useState(null);

  const handleCloseWizard = (e, reload) => {
    setShowNewProjectWizard(false);
    setProjectToEdit(null);

    if (reload) {
      setInnerLoading(true)
      getProjects();
    }
  }

  const handleChangePage = (ev, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (ev, val) => {
    setPage(0);
    setPerPage(val.props.value);
  }

  const handleSearchSubmit = (event) => {
    setInnerLoading(true);
    setPage(0);
    getProjects();
  }

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  }

  useEffect(() => {
    setInnerLoading(true);
    getProjects();
  }, [page, perPage]);



  const paginationParams = () => ({ page: parseInt(page) + 1, limit: perPage })

  const getProjects = () => {
    setInnerLoading(true);

    const paginationString = new URLSearchParams(paginationParams())
    let apiURL = `${projectEndpoint}?${paginationString.toString()}`

    if ( !isEqual( searchTerm, '')) {
      apiURL += `&search=${searchTerm}`
    }

    Axios.get(apiURL, axiosConfig()).then(response => {
      setProjectsPagination(response.data || defaultPagination)
      setLoading(false)
      setInnerLoading(false)
    }).catch(error => {
      setLoading(false)
      setInnerLoading(false)
      setListError(true)
      toastr.error(get(error, 'response.data.error', 'Error listing projects.'));
    })
  }

  const renderCreateProjectButton = () => (
    <CreateEditProject
      isSuperAdmin={isSuperAdmin}
      projectEndpoint={projectEndpoint}
      googleTimezoneEndpoint={googleTimezoneEndpoint}
      googleTimezoneApiKey={googleTimezoneApiKey}
      availableStates={availableStates}
      availableStatesCanada={availableStatesCanada}
      googleMapsApiKey={googleMapsApiKey}
      reloadList={getProjects}
    />
  )

  const renderCreateProjectByUserButton = () => (
    <Button color="primary" onClick={() => setShowNewProjectWizard(true)} size="small">
        <i className="fa fa-plus-circle" style={{ marginRight: 6 }} aria-hidden="true"></i> Create Project
    </Button>
  )

  const loadingSection = () => (
    <Box className={classes.dialogLoading}>
      <CircularProgress color="primary" />
    </Box>
  )

  return (
    <ThemeProvider theme={myTheme}>
      { loading && loadingSection() }
      { !loading && !listError &&
        <Box>
          <Box className={classes.createAndSearchWrapper}>
            { isSuperAdmin ?
              renderCreateProjectButton() : get(user, 'user_type') === 'admin' && !isEmpty(get(user , 'company_ids', [])) ?
              renderCreateProjectByUserButton() : <Box></Box>
            }
            <Box className={classes.search}>
              <InputBase
                placeholder="Search"
                onChange={handleSearchChange}
                value={searchTerm}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    handleSearchSubmit()
                  }
                }}
                className={classes.inputBase}
                classes={{
                  input: classes.inputInput
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
              <IconButton onClick={handleSearchSubmit} size="small" color="primary" aria-label="Search" component="span">
                <SearchIcon color="primary" />
              </IconButton>
            </Box>
          </Box>
          <Box className={classes.root}>
            { innerLoading &&
              <Box className={classes.innerLoading}>
                <CircularProgress color="primary" />
              </Box>
            }
            <Paper className={classes.paper}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TablePagination
                        count={projectsPagination.totalCount}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={perPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.headerStyle}>Name</TableCell>
                      <TableCell className={classes.headerStyle} align="left">Address</TableCell>
                      <TableCell className={classes.headerStyle} align="left">Company Name</TableCell>
                      <TableCell className={classes.headerStyle} align="left">Timezone</TableCell>
                      <TableCell className={classes.headerStyle} align="left">Devices</TableCell>
                      <TableCell className={classes.headerStyle} align="left">Integrations</TableCell>
                      <TableCell colSpan="1" align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!loading && projectsPagination.projects.map( oneProject => {
                      return (
                        <TableRow className={clsx({ [classes.inactiveRow]: !get(oneProject, 'companyProjectSettings.active') })} hover={get(oneProject, 'companyProjectSettings.active')} key={oneProject._id}>
                          <TableCell className={classes.cellStyle} align="left">
                            <div className={classes.projectNameWrapper}>
                              <a href={`/projects/${oneProject._id}`}>
                                {oneProject.name}
                              </a>
                              { !get(oneProject, 'companyProjectSettings.active') && <i className="fa fa-window-close" style={{ marginLeft: 6, fontSize: 14, color: '#316498' }} aria-hidden="true"></i> }
                            </div>
                          </TableCell>
                          <TableCell className={clsx(classes.cellStyle, { [classes.inActiveCell]: !get(oneProject, 'companyProjectSettings.active') })} align="left">
                            {get(oneProject, "address", '')}
                          </TableCell>
                          <TableCell className={clsx(classes.cellStyle, { [classes.inActiveCell]: !get(oneProject, 'companyProjectSettings.active') })} align="left">
                            {get(oneProject, "company.name", '')}
                          </TableCell>
                          <TableCell className={clsx(classes.cellStyle, { [classes.inActiveCell]: !get(oneProject, 'companyProjectSettings.active') })} align="left">
                            { get(oneProject, "timezone") ?
                              get(oneProject, "timezone") :
                              <div className={classes.missingTimezoneWrapper}><Tooltip arrow title='Missing Timezone'><ErrorOutlineIcon className={classes.missingTimezoneIcon} /></Tooltip></div>
                            }
                          </TableCell>
                          <TableCell className={clsx(classes.cellStyle, { [classes.inActiveCell]: !get(oneProject, 'companyProjectSettings.active') })} align="left">
                            {oneProject.devices.filter(oneDevice => !oneDevice.virtual).length}
                          </TableCell>
                          <TableCell className={clsx(classes.cellStyle, { [classes.inActiveCell]: !get(oneProject, 'companyProjectSettings.active') })} align="left">
                            {oneProject.integrations.map( iconUrl => {
                              return <img key={iconUrl} src={iconUrl} alt="" style={{width: "24px", marginRight: "4px"}}/>
                            })}
                          </TableCell>
                          <TableCell className={clsx(classes.cellStyle, { [classes.inActiveCell]: !get(oneProject, 'companyProjectSettings.active') })} align="right">
                            { isSuperAdmin &&
                              <Tooltip title="Chart" arrow>
                                <IconButton
                                  aria-label="chart"
                                  color="default"
                                  onClick={() => window.open(window.location + `/${oneProject._id}/chart`)}
                                  size="small"
                                >
                                  <TableChart />
                                </IconButton>
                              </Tooltip>
                            }
                            { isSuperAdmin &&
                              <CreateEditProject
                                isSuperAdmin={isSuperAdmin}
                                projectEndpoint={projectEndpoint}
                                googleTimezoneEndpoint={googleTimezoneEndpoint}
                                googleTimezoneApiKey={googleTimezoneApiKey}
                                availableStates={availableStates}
                                availableStatesCanada={availableStatesCanada}
                                googleMapsApiKey={googleMapsApiKey}
                                editMode={true}
                                projectToEdit={oneProject}
                                reloadList={getProjects}
                              />
                            }
                            { !isSuperAdmin &&
                              <Tooltip title="Edit" arrow>
                                <IconButton
                                    aria-label="edit"
                                    color="primary"
                                    onClick={() => { setProjectToEdit(oneProject); setShowNewProjectWizard(true) }}
                                    size="small"
                                >
                                    <EditIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        count={projectsPagination.totalCount}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={perPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Box>
      }
      { showNewProjectWizard  &&
        <ProjectByUserWizard
          project={projectToEdit}
          handleClose={handleCloseWizard}
          availableStates={availableStates}
          availableStatesCanada={availableStatesCanada}
          googleTimezoneEndpoint={googleTimezoneEndpoint}
          googleTimezoneApiKey={googleTimezoneApiKey}
          projectEndpoint={projectEndpoint}
          companiesEndpoint={companiesEndpoint}
          companyId={get(user, 'company_ids[0].$oid')} />
      }
    </ThemeProvider>
  )
}

const useStyles = makeStyles((theme) => ({
  createAndSearchWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    // height: '31px',
    marginBottom: '10px'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #e0e0e0',
    backgroundColor: "#fff",
    marginLeft: 0,
    width: "300px",
    height: "30px",
  },
  inputBase: {
    height: '100%',
    width: '90%'
  },
  inputInput: {
    padding: theme.spacing(2.3, 2, 3, 1),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  dialogLoading: {
    width: "100%",
    height: "300px",
    display: "flex",
    backgroundColor: '#fafafa',
    zIndex: '9999999',
    justifyContent: "center",
    alignItems: "center"
  },
  root: {
    position: 'relative',
    width: "100%",
    "& .MuiTableCell-root": {
      padding: '11px'
    }
  },
  paper: {
    width: "100%",
    // marginBottom: theme.spacing(2),
  },
  headerStyle: {
    fontSize: '13px',
    color: '#444',
  },
  cellStyle: {
    fontSize: '12px',
    color: '#444',
    fontFamily: "Open Sans"
  },
  projectNameWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  missingTimezoneWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  missingTimezoneIcon: {
    color: '#dc3545',
  },
  innerLoading: {
    position: 'absolute',
    width: "100%",
    height: "100%",
    display: "flex",
    backgroundColor: 'rgb(250,250,250, 0.5)',
    zIndex: '9999999',
    justifyContent: "center",
    alignItems: "center",
  },
  inactiveRow: {
    backgroundColor: '#ececec',
  },
  inActiveCell: {
    color: '#868e96'
  }
}));
