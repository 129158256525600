import React from "react"

const CcDropDown = ({ options }) => {
  return (
    options.map(ct => {
      let text = `${ct.code}`
      if (ct.name && ct.name.length > 0) {
        text += `-${ct.name}`
      }
      return <option key={ct._id.$oid} value={ct._id.$oid}>{text}</option>
    })
  )
}

export default CcDropDown
