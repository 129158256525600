import React from 'react';
import Avatar from '@material-ui/core/Avatar';


const MessageTableCell = ({ data, classes }) => {
    const { id, name, desc, icon, budget, growth } = data;
    const iconName = growth > 0 ? "up" : "down";
    const statusStyle = growth > 0 ? " text-success" : "text-danger";
    const color = 'bg-light-blue'
    return (
        <tr
            tabIndex={-1}
            key={id}
        >
            <td>
                <div className="user-profile py-2 d-flex flex-row align-items-center">
                    <Avatar className={`size-40 align-self-start mr-3 ${color}`}>
                        <i className="far fa-comment-dots"></i>
                    </Avatar>
                </div>
            </td>
            <td className={classes.td}>
                Companies
      </td>
            <td className={`${classes.td} text-right`}>
                Reached 12 people
      </td>
        </tr>

    );
};

export default MessageTableCell;
