import React, { useState } from "react"
import PropTypes from "prop-types"
import Position from "./Position"

const Positions = ({ data, companyId }) => {

  const [positions, setPositions] = useState(data);


  const handleDelete = removed => {
    const index = positions.indexOf(removed)

    positions.splice(index, 1)
    setPositions([...positions])
  }

  const addNew = ev => {
    const newElement = {
      _id: {},
      name: "",
      company_id: { "$oid": companyId },
      number: "",
      description: "",
      created_at: Date.now(),
      updated_at: Date.now()
    };
    setPositions([...positions, newElement])
  }


  return (
    <div className="panel">
      <div className="panel-heading">
        <span className="panel-title">
          Positions
        </span>
      </div>
      <div className="panel-body">
        <div className="table-responsive">
          <table className="table table-hover table-condensed-x table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Number</th>
                <th>Description</th>
                <th>Created</th>
                <th>Updated</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {positions.map(position => {
                return <Position key={position._id.$oid} data={position} isNew={position._id.$oid == undefined} onDelete={handleDelete} />
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="panel-footer">
        <button className="btn btn-success btn-xs btn-outline btn-3d" onClick={addNew}>Add New</button>
      </div>
    </div>
  )
}

Positions.propTypes = {
  data: PropTypes.array,
  companyId: PropTypes.string
};

export default Positions
