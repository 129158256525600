import React, { useLayoutEffect } from 'react';

const Timepicker = ({name, value, onChange}) => {

  let mref = React.createRef()
  
  useLayoutEffect(() => {
    
    let init = true;

    $(mref.current).timepicker({
      icons: {
        time: "far fa-clock",
        date: "far fa-calendar",
        up: "fas fa-arrow-up",
        down: "fas fa-arrow-down",
        previous: "fas fa-arrow-left",
        next: "fas fa-arrow-right"
      },
      allowInputToggle: false,
    })

    $(mref.current).on('changeTime.timepicker', ev => {
      let value = ev.time.value;

      let toEmit = {name: ev.target.name, value: value}
      if (!init) {
        onChange(toEmit)
      } else {
        init = false
      }
    })
  }, [])


  useLayoutEffect(() => {
    $(mref.current).timepicker('setTime', `${value}`);
    return () => { };
  }, [value])

  
  return (
    <input ref={mref} name={name} type="text" className="form-control" />
  );
}


export default Timepicker;