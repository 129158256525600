import React, { useEffect, useState } from 'react'
import axios from "axios";
import CertificationList from './CertificationList';
import { Card, Alert } from 'react-bootstrap';
import MultipleSelect from './MultipleSelect';

export default function CertificationsDashboard({ user, company, baseUrl, projectId }) {

    const [data, setData] = useState([]);
    const [filters, setFilters] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([]);

    console.log({ user, company, data, projectId });

    const axiosConfig = () => {
        const token = document.querySelector("input[name='aws_token'").value

        return {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    }

    const transform = (responseData) => {
        const result = {}
        responseData.forEach(element => {
            const employees = element.employee
            if (employees.length > 0) {
                const employee = employees[0]

                result[employee._id] = result[employee._id] ? result[employee._id] : { employee: employee, certifications: [] }
                result[employee._id]["certifications"].push(element)
            }
        });

        let arr = Object.values(result)
        console.log({ arr });
        return arr.sort((a, b) => {
            return a.employee.last_name < b.employee.last_name ? -1 : 1
        })
    }

    const countExpired = () => {
        let result = 0;
        data.forEach((record) => {
            const { employee, certifications } = record
            certifications.forEach(c => {
                if (c.expiration_date) {
                    const expires = moment(c.expiration_date)
                    const expired = expires.isBefore(moment.now())

                    if (expired)
                        result++;
                }
            })
        })

        return result;
    }

    useEffect(() => {
        loadData()
        loadDocumentTypes()
        return () => { };
    }, []);


    const loadDocumentTypes = () => {
        const url = `${baseUrl}/companies/${company._id.$oid}/certifications/types`

        axios.get(url, axiosConfig()).then(response => {
            const formatted = response.data.map(t => {
                t.id = t._id
                return t
            })
            setDocumentTypes([...formatted])
        }).catch(console.error)
    }

    const loadData = () => {
        let url = `${baseUrl}/certifications`
        let queryFilters = {}
        if (projectId.length > 0) {
            queryFilters["project_id"] = projectId
        }
        if (filters.length > 0) {
            const searchString = encodeURI(filters.join("||"))
            queryFilters["search"] = searchString
        }
        if (Object.keys(queryFilters).length > 0) {
            const fmt = Object.keys(queryFilters).map(key => `${key}=${queryFilters[key]}`).join("&")
            console.log({ fmt });
            url += `?${fmt}`
        }
        axios.get(url, axiosConfig()).catch(console.error).then(response => {
            const transformed = transform(response.data)
            setData(transformed)
        })
    }

    const handleFiltersChange = (newFilters) => {
        console.log({ newFilters });
        setFilters([...newFilters])
    }

    return (
        <div>

            {
                (() => {
                    const expired = countExpired()
                    if (expired > 0)
                        return (
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="alert alert-danger">
                                        {expired} Documents Expired
                                    </div>
                                </div>
                            </div>
                        )
                }
                )()
            }

            <div className="row">
                <div className="col-md-6">
                </div>
                <div className="col-md-6">
                    <div className="input-group">
                        <MultipleSelect value={filters} options={documentTypes} allOption={false} pluginOpts={{ tags: false }} onChange={handleFiltersChange} />
                        <span className="input-group-btn">
                            <button className="btn btn-default" onClick={loadData}>
                                <i className="fas fa-search"></i>
                            </button>
                        </span>
                    </div>
                </div>
                <hr />
            </div>

            {
                (() => {
                    if (data.length == 0) {
                        return (
                            <div className="row">
                                <div className="col-md-12">
                                    <Card>
                                        <Card.Body>
                                            <h2 style={{ "textAlign": "center" }}>No Documents</h2>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        )
                    }
                })()
            }


            {data.map(record => {
                const { employee, certifications } = record
                return (
                    <div key={employee._id} className="row">

                        <div className="col-md-12">
                            <Card>
                                <Card.Body>
                                    <Card.Title>{`${employee.first_name} ${employee.last_name}`}</Card.Title>
                                    <CertificationList certs={certifications} baseUrl={baseUrl} dbid={employee.dbid} onDelete={loadData} />
                                </Card.Body>
                            </Card>
                            <hr />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
