import React, { useState } from 'react';
import { Modal, Form } from "react-bootstrap";
import axios from "axios";

const DeviceRetire = ({ device }) => {

  const retired = device.current_status == 'retired';

  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleOpen = () => {
    setShow(true)
  }
  const handleClose = () => {
    setShow(false)
  }

  const save = (ev) => {
    setSaving(true)
    const url = `${location.origin}/devices/${device._id.$oid}`;
    const token = document.querySelector("meta[name='csrf-token']").content
    axios.patch(url, {
      authenticity_token: token,
      device: {
        current_status: retired ? 'stock' : 'retired'
      }
    }).then(response => {
      window.location.reload();
    }).catch(errorSavingStatus => {
      console.error({ errorSavingStatus })
    })
  }

  const buildConfirmMessage = (ret) => {
    return `Are you sure you want to ${ret} ${device.name} - ${device.device_id}`
  }

  const label = retired ? 'Revive' : 'Retire';
  const confirm = retired ? buildConfirmMessage('revive') : buildConfirmMessage('retire')
  const getClasses = () => {
    let cs = "btn btn-xs btn-outline btn-3d";
    if (retired) cs += " btn-success";
    else cs += " btn-danger"

    return cs
  }

  return (
    <>

      <a onClick={handleOpen} className={getClasses()}>
        {label}
      </a>
      <Modal show={show} onHide={handleClose} size="md" animation={false} backdrop={true} backdropClassName="modal-backdrop fade in">
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>{confirm}</strong>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger btn-sm" disabled={saving} onClick={save}>Yes</button>
          <button className="btn btn-default btn-sm" disabled={saving} onClick={handleClose}>No</button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeviceRetire;
