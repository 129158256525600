import React, { useContext, useState } from 'react'
import { Button, Link, Menu, MenuItem } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import { get } from 'lodash';
import { SettingsContext } from '../../../contexts/SettingsContext';
import { MenuOutlined } from '@material-ui/icons';

const IconsList = ({ company, settingsContext, classes, handleClose, onShowBulkUploadProjectWorkers, permissions }) => {
  return (
    //  <div className={`${settingsContext.isMobile ? classes.iconListMobile : classes.iconList}`}>
    <div className={classes.iconList}>

      <Link className={classes.link} href='/certifications'>
        <img className={classes.img} src='https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/project-employees/documents.png' />
      </Link>
      { get(permissions, 'canCreate') &&
        <div className={classes.link} onClick={() => {
          settingsContext.onAddWorker('global');
          handleClose()
        }}>
          <img className={classes.img} src='https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/project-employees/add-worker.png' />
        </div>
      }

      { get(permissions, 'canCreate') &&
        <Link className={classes.link} onClick={() => onShowBulkUploadProjectWorkers(true)}>
          <img className={classes.img} src={require('../../../../../../assets/images/workers/add-worker-3-people.png')} />
        </Link>
      }

      <Link className={classes.link} href={`/companies/${get(company, '_id.$oid')}/costcodes`}>
        <img className={classes.img} src='https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/project-employees/costcodes.png' />
      </Link>

      <Link className={classes.link} href={`/companies/${get(company, '_id.$oid')}/settings`}>
        <img className={classes.img} src='https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/project-employees/settings.png' />
      </Link>
    </div>
  )
}

export default function TopPageMenu(props) {

  const { company, project, onShowCharts, onOnboardWorker, onProjectSettings, onShowBulkUploadProjectWorkers, permissions } = props;

  const [anchorMenu, setAnchorMenu] = useState(null);

  const handleClick = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorMenu(null);
  };

  const settingsContext = useContext(SettingsContext);

  const topMenuStyles = makeStyles((theme) => ({
    img: {
      width: '35px !important',
      height: '35px !important'
    },
    link: {
      marginLeft: '5px',
      cursor: 'pointer',
      marginTop: `${settingsContext.isMobile ? '10px' : '0px'}`
    },
    iconList: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    iconListMobile: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    menu: {
      width: '76px',
      top: '121px !important'
    }
  }));

  const classes = topMenuStyles();
  return (
    // settingsContext.isMobile ?
    //   <div>
    //     <MenuOutlined onClick={handleClick} />
    //
    //     <Menu
    //       classes={{ paper: classes.menu }}
    //       id="simple-menu"
    //       anchorEl={anchorMenu}
    //       keepMounted
    //       open={Boolean(anchorMenu)}
    //       onClose={handleClose}
    //     >
    //       <IconsList company={company} settingsContext={settingsContext} classes={classes} handleClose={handleClose} />
    //     </Menu>
    //   </div>
    //   :
    <IconsList company={company} onProjectSettings={onProjectSettings} settingsContext={settingsContext} classes={classes} handleClose={handleClose} onShowBulkUploadProjectWorkers={onShowBulkUploadProjectWorkers} permissions={permissions} />
  )
};
