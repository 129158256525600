import React, { useState, useEffect, useContext } from 'react'
import { get, size, isEmpty, has, isNull, padStart } from 'lodash'
import { DateTime } from 'luxon'
import { makeStyles } from '@material-ui/core/styles'
import Axios from 'axios'

import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import InputLabel from '@material-ui/core/InputLabel'

import useAutocomplete from '@material-ui/lab/useAutocomplete'
import ToggleButton from '@material-ui/lab/ToggleButton'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DatePicker from "react-multi-date-picker"
import { Calendar } from "react-multi-date-picker"

import LuxonUtils from '@date-io/luxon'

import Close from '@material-ui/icons/Close'
import ClockIcon from '@material-ui/icons/AccessTime'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import workerProfileIcon from '../../../../../../../assets/images/worker_types/constructionWorkerIcon-2023.svg'

import { axiosConfig } from '../../../../../helpers/index'
import { notificationCenter } from '../../../../../helpers/notifications'
import { SettingsContext } from '../../../../contexts/SettingsContext'
import { SBSwitch } from '../../../../../reusableComponents/index'

import "../../../../../../../assets/stylesheets/smartBarrelCalendarTheme.css"

export default function NewWorkerShift(props) {

  const settingsContext = useContext(SettingsContext)
  const selectedDate = DateTime.fromISO(settingsContext.timesheetDate)

  const { classes, config, companyData, project, showAddNewWorkerShift, setShowAddNewWorkerShift, handleActionDialogPopover, isTransferShift, setIsTransferShift, selectedCrew, user } = props

  const newObject = {
    in: {
      date: selectedDate,
      time: selectedDate,
      active: false
    },
    out: {
      date: selectedDate,
      time: selectedDate,
      active: false
    }
  }

  const [newShiftObject, setNewShiftObject] = useState(newObject)
  const [note, setNote] = useState('')
  const [projectWorkers, setProjectWorkers] = useState([])
  const [loadingProjectWorkers, setLoadingProjectWorkers] = useState(true)
  const [companyProjects, setCompanyProjects] = useState([])
  const [loadingCompanyProjects, setLoadingCompanyProjects] = useState(true)
  const [isPto, setIsPto] = useState(false)
  const [ptoHours, setPtoHours] = useState(1)
  const [ptoReason, setPtoReason] = useState(null)
  const [ptoIsPaid, setPtoIsPaid] = useState(false)
  const [ptoReasons, setPtoReasons] = useState([])
  const [ptoCalendarValue, setPtoCalendarValue] = useState([])
  const [ptoStartTime, setPtoStartTime] = useState(selectedDate)
  const [loadingPtoReasons, setLoadingPtoReasons] = useState(true)
  const [applyBreakTimeRules, setApplyBreakTimeRules] = useState(false)
  const [breakTime, setBreakTime] = useState(0)
  const [allCompanyWorkers, setAllCompanyWorkers] = useState(false)

  const disableBreakTime = !(get(newShiftObject, 'in.active', false) && get(newShiftObject, 'out.active', false))

  useEffect(() => {
    setNewShiftObject(prevNewShiftObject => ({
      ...prevNewShiftObject,
      in: {
        ...prevNewShiftObject.in,
        active: get(props, 'isTransferShift')
      },
      out: {
        ...prevNewShiftObject.out,
        active: get(props, 'isTransferShift')
      }
    }))

    return () => { }
  }, [isTransferShift])

  useEffect(() => {

    getCompanyProjects()
    getPtoReasons()
    return () => { }
  }, [])


  useEffect(() => {
    getProjectWorkers()
  }, [allCompanyWorkers])


  const getProjectWorkers = async () => {
    try {
      let payload = {
        filters: [
          {
            key: "company",
            value: get(companyData, '_id')
          },
        ],
        viewType: "compact",
        skipPagination: true
      }

      if (selectedCrew) {
        payload.filters.push({ key: 'crew', value: get(selectedCrew, '_id') })
      }

      if (allCompanyWorkers) {
        payload.filters.push({ key: 'companyWorkers', value: true })
      }

      const res = await Axios.post(`${get(config, 'workersEndpoint')}/project/${get(project, '_id.$oid')}/workers/list`, payload, axiosConfig());

      setProjectWorkers(get(res, 'data', []))
      setLoadingProjectWorkers(false)
    } catch (error) {
      setLoadingProjectWorkers(false)
      notificationCenter("error", `Error getting workers for ${get(companyData, 'name')}. ${get(error, 'response.data.error')}`)
    }
  }

  const getCompanyProjects = async () => {
    try {
      const res = await Axios.get(`${get(config, 'project_endpoint')}/getCompanyProjects/${get(companyData, '_id')}?active=true&skipPagination=true&format=compact&reference=${get(project, '_id.$oid')}`, axiosConfig());

      setCompanyProjects(get(res, 'data', []))
      setLoadingCompanyProjects(false)
    } catch (error) {
      setLoadingCompanyProjects(false)
      notificationCenter("error", `Error getting workers for ${get(companyData, 'name')}. ${get(error, 'response.data.error')}`)
    }
  }

  const getPtoReasons = () => {
    return Axios.post(`${get(config, 'classifiersEndpoint')}/${get(settingsContext, 'uiConfig.data.company._id')}/list`, { appliesTo: 'shift[type=pto]', active: true }, axiosConfig())
      .catch(error => {
        notificationCenter("error", `Error getting PTO reasons for ${get(companyData, 'name')}. ${get(error, 'response.data.error')}`)
        setLoadingPtoReasons(false)
      })
      .then(resp => {
        setPtoReasons(get(resp, 'data[0].items', []))
        setLoadingPtoReasons(false)
      })
  }

  const handlePtoCalendarChange = (value) => {
    setPtoCalendarValue(value)
  }

  const handlePtoStartTimeChange = (e) => {
    setPtoStartTime(DateTime.fromFormat(e.target.value, 'HH:mm'))
  }

  const handleNewShiftDateChange = (event, punchType) => {
    setNewShiftObject(prevNewShiftObject => ({
      ...prevNewShiftObject,
      [punchType]: {
        ...prevNewShiftObject[punchType],
        date: event
      }
    }))
  }

  const handleNewShiftTimeChange = (e, punchType) => {
    setNewShiftObject(prevNewShiftObject => ({
      ...prevNewShiftObject,
      [punchType]: {
        ...prevNewShiftObject[punchType],
        time: DateTime.fromFormat(e.target.value, 'HH:mm')
      }
    }))
  }

  const handleBreakTimeChange = (event) => {

    // allow only number and empty string
    const numbersRegex = new RegExp(/^$|^[0-9]/, 'g')

    if (numbersRegex.test(event.target.value)) {
      if (isEmpty(event.target.value)) {
        setBreakTime(event.target.value)
      } else {
        setBreakTime(Number(event.target.value) < 121 ? event.target.value : '120')
      }
    }
  };


  const handleApplyBreakTimeRulesCheckbox = (event) => {
    setApplyBreakTimeRules(!applyBreakTimeRules)
  }

  const handleNewShiftNoteChange = (event) => {
    setNote(event.target.value)
  }

  const toggleNewShiftPunch = (punchType) => {
    setNewShiftObject(prevNewShiftObject => ({
      ...prevNewShiftObject,
      [punchType]: {
        ...prevNewShiftObject[punchType],
        active: !get(get(prevNewShiftObject, punchType), 'active', false)
      }
    }))
  }

  const handleCloseAddNewWorkerShift = () => {
    setShowAddNewWorkerShift(false)
    setIsTransferShift(false)
    setNewShiftObject(newObject)
  }

  const handleConfirmAddNewWorkerShift = () => {
    let payload = {
      command: {
        date: selectedDate.toFormat('yLLdd')
      },
      payload: {
        employee_id: get(get(newShiftObject, 'employee'), '_id'),
        note: note
      }
    }

    if (get(get(newShiftObject, 'in'), 'active')) {
      payload.payload.punch_in_time = {
        date: get(get(newShiftObject, 'in'), 'date').toFormat('yLLdd'),
        time: get(get(newShiftObject, 'in'), 'time').toFormat('T')
      }
    }
    if (get(get(newShiftObject, 'out'), 'active')) {
      payload.payload.punch_out_time = {
        date: get(get(newShiftObject, 'out'), 'date').toFormat('yLLdd'),
        time: get(get(newShiftObject, 'out'), 'time').toFormat('T')
      }
    }

    if (isTransferShift) {
      payload.command.action = 'transfer'
      payload.payload.project_id = get(get(newShiftObject, 'project'), 'value')
    }

    if (isPto) {
      payload.command.action = 'pto'
      payload.payload.ptoReason = ptoReason
      payload.payload.hours = ptoHours
      payload.payload.dates = ptoCalendarValue.map(oneDate => `${get(oneDate, 'year')}${padStart(get(oneDate, 'month.number'), '2', 0)}${padStart(get(oneDate, 'day'), '2', 0)}`)
      payload.payload.startTime = ptoStartTime.toFormat('T')
      payload.payload.isPaid = ptoIsPaid
    }

    if (applyBreakTimeRules) {
      payload.payload.applyBreakTimeRules = applyBreakTimeRules
    } else {
      if (!disableBreakTime) {
        payload.payload.break_time = breakTime
      }
    }

    handleActionDialogPopover('addNewShift', payload, companyData._id);
    setNewShiftObject(newObject);
    setShowAddNewWorkerShift(false);
    setIsTransferShift(false);
  }

  const handleToggleTransferShift = () => {
    setIsPto(false)
    setIsTransferShift(!isTransferShift)
    setNewShiftObject(prevNewShiftObject => ({
      ...prevNewShiftObject,
      in: {
        ...prevNewShiftObject['in'],
        active: !isTransferShift
      },
      out: {
        ...prevNewShiftObject['out'],
        active: !isTransferShift
      }
    }))
  }

  const handleTogglePTO = () => {
    setIsTransferShift(false)
    setIsPto(!isPto)
  }

  const handlePtoHoursChange = (event) => {
    setPtoHours(event.target.value)
  }

  const handlePtoReasonChange = (event, value) => {
    setPtoReason(value)
  }

  const handlePaidCheckboxClick = (event) => {
    setPtoIsPaid(!ptoIsPaid)
  }

  const renderWorkerSelect = () => {
    const {
      getRootProps,
      getInputProps,
      getListboxProps,
      getOptionProps,
      getClearProps,
      groupedOptions,
      value,
      focused,
      setAnchorEl,
    } = useAutocomplete({
      id: 'selectWorkerDropdown',
      defaultValue: [],
      multiple: false,
      options: projectWorkers,
      getOptionLabel: (option) => get(option, 'first_name', '') + get(option, 'last_name', ''),
      onChange: (event, value) => setNewShiftObject(prevNewShiftObject => ({ ...prevNewShiftObject, employee: value }))
    })


    const handleDeselectWorker = (event) => {
      getClearProps().onClick()
      setNewShiftObject(prevNewShiftObject => ({
        ...prevNewShiftObject,
        employee: null
      }))
    }

    return (!has(get(newShiftObject, 'employee'), '_id') ?
      <FormControl>
        <div>
          <div {...getRootProps()}>
            <div ref={setAnchorEl} className={focused ? 'focused' : ''}>
              <TextField
                {...getInputProps()}
                variant="outlined"
                disabled={loadingProjectWorkers}
                label="Select worker"
                style={{ width: 252 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>{loadingProjectWorkers && <CircularProgress size={15} color='primary' />}</InputAdornment>
                  )
                }}
              />

            </div>

          </div>
          {groupedOptions.length > 0 ? (
            <ul {...getListboxProps()} className={classes.workersDropDownList}>
              {groupedOptions.map((option, index) => (
                <li {...getOptionProps({ option, index })}>
                  <Avatar style={{ width: 45, height: 45, marginRight: 12 }} src={get(option, 'image_path', workerProfileIcon)} />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant='subtitle2'>{`${get(option, 'first_name')} ${get(option, 'last_name')}`}</Typography>
                    <Typography variant='caption' style={{ color: 'grey' }}>{get(option, 'position', '')}</Typography>
                    <Typography variant='caption' style={{ color: 'grey' }}>{get(option, 'phone_number', '')}</Typography>
                  </div>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </FormControl> :
      <Chip
        variant="outlined"
        style={{ height: 56, width: 252, borderRadius: 100, fontSize: 18, display: 'flex', justifyContent: 'space-between', padding: 4 }}
        label={get(get(newShiftObject, 'employee'), 'first_name') + ' ' + get(get(newShiftObject, 'employee'), 'last_name')}
        onDelete={handleDeselectWorker}
        avatar={<Avatar style={{ width: 45, height: 45 }} src={get(newShiftObject, 'employee.image_path', workerProfileIcon)} />}
      />
    )
  }

  const renderProjectSelect = () => {
    const {
      getRootProps,
      getInputProps,
      getListboxProps,
      getOptionProps,
      getClearProps,
      groupedOptions,
      value,
      focused,
      setAnchorEl,
    } = useAutocomplete({
      id: 'selectProjectDropdown',
      defaultValue: [],
      multiple: false,
      options: companyProjects,
      getOptionLabel: (option) => get(option, 'label', ''),
      onChange: (event, value) => setNewShiftObject(prevNewShiftObject => ({ ...prevNewShiftObject, project: value }))
    })

    return (
      <div style={{ overflow: isTransferShift ? 'visible' : 'hidden', opacity: isTransferShift ? 1 : 0, maxHeight: isTransferShift ? 200 : 0, transition: 'opacity 0.6s ease-out, max-height 0.3s ease-out' }}>
        <FormControl style={{ padding: 16 }} fullWidth>
          <div>
            <div {...getRootProps()}>
              <div ref={setAnchorEl} className={focused ? 'focused' : ''}>
                <TextField
                  {...getInputProps()}
                  variant="outlined"
                  label="Select project "
                  style={{ width: '100%' }}
                  disabled={!isTransferShift && loadingCompanyProjects}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>{loadingCompanyProjects && <CircularProgress size={15} color='primary' />}</InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            {groupedOptions.length > 0 ? (
              <ul {...getListboxProps()} className={classes.projectsDropDownList}>
                {groupedOptions.map((option, index) => (
                  <li {...getOptionProps({ option, index })}>
                    <Typography variant='subtitle2'>{get(option, 'label')}</Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <DirectionsCarIcon style={{ color: 'grey', marginRight: 8 }} />
                      <Typography variant='caption' style={{ color: 'grey', width: 60 }}>{Math.round(get(option, 'distance'))} mi.</Typography>
                    </div>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </FormControl>
      </div>
    )
  }

  const renderPunchRow = (punchType) => {
    return (
      <div style={{ padding: 16, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <ToggleButton
          disabled={isTransferShift}
          onChange={() => { toggleNewShiftPunch(punchType) }}
          selected={get(get(newShiftObject, punchType, {}), 'active', false)}
          aria-label="punch-out-toggle"
          style={{
            width: '60px',
            backgroundColor: get(get(newShiftObject, punchType, {}), 'active', false) ? '#f15922' : null,
            color: get(get(newShiftObject, punchType, {}), 'active', false) ? 'white' : null
          }}>
          {punchType.toUpperCase()}
        </ToggleButton>
        <KeyboardDatePicker
          autoOk
          disabled={!get(get(newShiftObject, punchType, {}), 'active', false)}
          minDate={punchType === 'in' ? new Date(1, 1, 1) : get(newShiftObject, 'in.date')}
          maxDate={punchType === 'in' ? get(newShiftObject, 'out.date') : DateTime.now()}
          disableToolbar
          disableFuture
          inputVariant="outlined"
          variant="inline"
          format="DD"
          id="newWorkerShiftDatePicker"
          label="Date"
          value={get(get(newShiftObject, punchType), 'date', selectedDate)}
          onChange={(event) => handleNewShiftDateChange(event, punchType)}
          KeyboardButtonProps={{
            'aria-label': 'select date',
          }}
          style={{ width: 215 }}
        />
        <TextField
          id="newWorkerShiftTimePicker"
          label='Time'
          disabled={!get(get(newShiftObject, punchType, {}), 'active', false)}
          type='time'
          variant='outlined'
          value={get(get(newShiftObject, punchType), 'time').toFormat('HH:mm')}
          onChange={(event) => handleNewShiftTimeChange(event, punchType)}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          style={{ width: 215 }}
        />
      </div>
    )
  }

  const disableConfirmNewShift = () => {
    return isEmpty(note.trim()) ||
      (!isPto && !get(newShiftObject, 'in.active') && !get(newShiftObject, 'out.active')) ||
      !get(newShiftObject, 'employee') ||
      (isTransferShift && !get(newShiftObject, 'project')) ||
      (isPto && (isNull(ptoReason) || size(Object.keys(ptoCalendarValue)) === 0))
  }

  return (
    <Dialog maxWidth='sm' open={showAddNewWorkerShift} onClose={handleCloseAddNewWorkerShift} fullWidth>
      <DialogTitle disableTypography={true} className={classes.newWorkerPunchDialogTitle} >
        <Typography variant="h6">Add Shift</Typography>
        <IconButton onClick={handleCloseAddNewWorkerShift} size='small' className={classes.closeModalButton}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.newWorkerPunchDialogBody} style={{ position: 'relative' }}>
        <div style={{ width: '100%', height: 40, zIndex: 0, position: 'absolute', top: 0, left: 0, backgroundColor: "#f6f5f7" }} />
        <Paper style={{ zIndex: 2 }}>
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: "2px solid #f6f5f7", padding: `16px ${get(user, 'user_type') === 'admin' ? '0' : '16px'} 16px 16px` }}>
              {renderWorkerSelect()}

              {get(user, 'user_type') === 'admin' &&
                <div >
                  <Button variant="contained" color={allCompanyWorkers ? 'primary' : 'default'} onClick={() => setAllCompanyWorkers(!allCompanyWorkers)}>
                    {'All Workers'}
                  </Button>
                </div>}

              <Button color={isTransferShift ? 'primary' : 'default'} onClick={handleToggleTransferShift}>
                {'TRANSFER'}
              </Button>
              <Button color={'default'} onClick={handleTogglePTO} style={{ color: isPto ? '#429ad8' : '' }}>
                {'PTO'}
              </Button>
            </div>

            <div style={{ maxHeight: !isPto ? 800 : 0, transition: 'max-height 0.3s ease-out', overflow: 'hidden' }}>
              <MuiPickersUtilsProvider utils={LuxonUtils}>
                {renderPunchRow('in')}
                {renderProjectSelect()}
                {renderPunchRow('out')}
              </MuiPickersUtilsProvider>
              <div style={{ maxHeight: disableBreakTime ? 0 : 90, transition: 'padding 0.3s ease-out, max-height 0.3s ease-out', overflow: 'hidden' }}>
                <div style={{ padding: '16px 16px 16px 16px', display: "flex", alignItems: "center", justifyContent: 'flex-start' }}>
                  <TextField
                    label='Break Time'
                    id='break_time'
                    variant="outlined"
                    disabled={applyBreakTimeRules || disableBreakTime}
                    value={breakTime}
                    onChange={handleBreakTimeChange}
                  />
                  <FormControlLabel value="end" labelPlacement="end" disabled={disableBreakTime} control={<SBSwitch checked={applyBreakTimeRules} handleToggle={handleApplyBreakTimeRulesCheckbox} />} label='Apply break time rules' style={{ marginBottom: 0, marginLeft: 16 }} />
                </div>
              </div>
            </div>
            <div style={{ maxHeight: !isPto ? 0 : 800, transition: 'max-height 0.3s ease-out', overflow: 'hidden' }}>
              <div style={{ display: 'flex', width: '100%', padding: 16 }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <Calendar
                      shadow={false}
                      className="orange"
                      multiple
                      value={ptoCalendarValue}
                      onChange={handlePtoCalendarChange}
                      style={{ fontSize: 24, width: '100%' }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', paddingTop: 12 }}>
                      <div style={{ display: 'flex', width: '100%' }}>
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                          <TextField
                            id="ptoShiftStartTimePicker"
                            label="Start Time"
                            type='time'
                            variant='outlined'
                            value={ptoStartTime.toFormat('HH:mm')}
                            onChange={handlePtoStartTimeChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                            style={{ width: 140, margin: 'auto', marginTop: 0, marginBottom: 0 }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div>
                        <FormControl style={{ width: 140, marginTop: 40 }}>
                          <InputLabel shrink id="pto-hours-select-label">Hours per day</InputLabel>
                          <Select
                            labelId="pto-hours-select-label"
                            classes={{ root: classes.select }}
                            id="pto-hours-select"
                            value={ptoHours}
                            onChange={handlePtoHoursChange}>
                            {
                              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(oneHour =>
                                <MenuItem value={oneHour}>{oneHour}</MenuItem>
                              )
                            }
                          </Select>
                        </FormControl>
                      </div>
                      <div style={{ marginTop: 40, display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel value="end" labelPlacement="end" control={<SBSwitch checked={ptoIsPaid} handleToggle={handlePaidCheckboxClick} />} label='Paid leave' style={{ marginBottom: 0 }} />
                        {ptoIsPaid ? <AttachMoneyIcon style={{ color: '#78bd5d' }} /> : <MoneyOffIcon style={{ color: '#dc3545' }} />}
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: 8 }}>
                    <div className={classes.ptoSummary}>
                      <em>The above will be applied to {size(ptoCalendarValue) === 1 ? `${size(ptoCalendarValue)} day` : `${size(ptoCalendarValue)} days`} with a duration of {ptoHours === 1 ? `${ptoHours} hour` : `${ptoHours} hours`} per day. <br /> <strong>A total of {size(ptoCalendarValue) * ptoHours} {ptoIsPaid ? 'paid' : 'unpaid'} hours.</strong></em>
                    </div>
                  </div>
                  <div style={{ width: '100%', display: 'flex', paddingLeft: 4, paddingRight: 4 }}>
                    {/*<div style={{ fontWeight: 600, marginRight: 8, marginTop: 3, fontSize: 14 }}>Reason</div>*/}
                    <div style={{ width: '100%' }}>
                      <Autocomplete
                        options={ptoReasons}
                        value={ptoReason}
                        onChange={handlePtoReasonChange}
                        autoHighlight
                        getOptionLabel={(option) => get(option, 'name', '')}
                        getOptionSelected={(option, value) => get(option, 'code') === get(value, 'code')}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: '100%' }}
                            label="Reason"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ padding: '16px 16px 16px 16px', display: "flex", alignItems: "center" }}>
              <TextField
                onChange={handleNewShiftNoteChange}
                rows={2}
                style={{ width: '100%' }}
                id="outlined-basic"
                variant="outlined"
                label="Note"
                value={note}
                multiline
              />
            </div>
          </div>
        </Paper>
      </DialogContent>
      <DialogActions className={classes.newWorkerPunchDialogActions}>
        <Button variant="outlined" onClick={handleCloseAddNewWorkerShift}>
          Cancel
        </Button>
        <Button disabled={disableConfirmNewShift()} variant="outlined" onClick={handleConfirmAddNewWorkerShift} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
