import React, { useState } from 'react'
import { Restore } from '@material-ui/icons'
import { Fab, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button } from '@material-ui/core'
import axios from "axios";

export default function RoundupTime({ punch, onUpdate }) {

  const [open, setOpen] = useState(false);
  
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  
  const buildPayload = () => {
    var csrfToken = $('meta[name="csrf-token"]').attr("content");

    let fd = new FormData()
    fd.append("authenticity_token", csrfToken)
    fd.append("commit", "Revert")
    fd.append(`shifts[remove_rounding_punch[${punch._id.$oid}]]`, true)
    return fd
  }
  const handleSave = () => {
    const endpoint = `${location.origin}/projects/${punch.project_id.$oid}/edit_timesheet/${punch.employee_id.$oid}`
    console.log({ endpoint });
    axios.post(endpoint, buildPayload()).then(response => {
      punch.punch_time = punch.raw.punch_time
      onUpdate(punch)
      handleClose()
    }).catch(errorSavingPunchtime => {
      console.error({ errorSavingPunchtime })
      handleClose()
    })
  }

  const formatTime = () => {
    let punchTimeParsed = new Date(punch.raw.punch_time * 1000)
    let fmt = "MM/DD/YYYY hh:mm A"
    return moment(punchTimeParsed).format(fmt)
  }

  return (
    <>
      {(() => {
        if (punch.raw && punch.raw["punch_time"] && punch.raw["punch_time"] != punch.punch_time) {
          return (
            <>
              <Fab size="small" onClick={handleOpen}>
                <Restore />
              </Fab>
              <Dialog
                open={open}
                onClose={handleClose}
              >
                <DialogTitle >Revert punch time</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Revert punch time to its original time {formatTime()}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleSave} color="primary" autoFocus>
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )
        }
      })()}
    </>
  )
}
