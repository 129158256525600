import { Avatar } from '@material-ui/core';
import React from 'react';
import { get } from 'lodash';

export default function PunchDetails({ worker, projectDomain, punchData }) {

  return (
    <div className="callout-inner">
      <div className="callout-content">
        <img className='punch-image' src={get(punchData, 'image' , '') || 'https://static-files.connectedbarrel.com/project-employees/no-profile-image.png'} />
        <div className="fields">
            <div> {get(punchData, 'full_name' , 'Employee-Name')} </div>
            <div> {get(punchData, 'phone_number' , 'Employee-Phone')}</div>
            <strong style={{ marginTop: '13px' }}>{get(punchData, 'lastSeen' ) || 'No activity'}</strong>
        </div>
      </div>
    </div>
  )
};