import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';

const CustomLinearProgress = withStyles((theme) => ({
  root: {
    height: 2,
  },
  colorPrimary: {
    // backgroundColor: "rgb(241,89,34, 0.4)",
  },
  bar: {
    // borderRadius: 5,
    backgroundColor: 'rgb(241,89,34, 0.6)',
  },
}))(LinearProgress);

export const AccordionItem = (props) => {
  const { title, children, renderToolbar, loading, detailsPadding, onClick, onAccodionItemChange, style = {} } = props

  const [expanded, setExpanded] = useState(false);

  const useStylesTimesheet = makeStyles((theme) => ({
    accordionRoot: {
      backgroundColor: '#fafafa',
      '&.Mui-expanded': {
        minHeight: 49
      }
    },
    content: {
      marginTop: 0,
      marginBottom: 0,
      '&.Mui-expanded': {
        marginTop: 0,
        marginBottom: 0
      }
    },
  }));

  const onChange = () => {
    setExpanded(!expanded)
    onAccodionItemChange()
  }

  const classes = useStylesTimesheet();

  return (<>
    <Accordion style={style} expanded={expanded} disabled={loading} onChange={onChange} onClick={onClick}>
      <AccordionSummary
          classes={{ root: classes.accordionRoot, content: classes.content }}
      >
        <Grid alignItems="center" container>
          <Grid xs={true} item>
            <Grid alignItems={'center'} container>
              <Grid xs={'auto'} className="m-r-1" item>
                <IconButton size="small" component={'span'}>
                  {expanded
                      ? <KeyboardArrowUp fontSize={'large'} style={{ fontSize: '20px' }} />
                      : <KeyboardArrowDown fontSize={'large'} style={{ fontSize: '20px' }} />
                  }
                </IconButton>
              </Grid>
              <Grid xs={true} item>
                <Typography variant="subtitle2" className="m-b-0">{title}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={'auto'} item>
            {renderToolbar}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={detailsPadding !== undefined ? `p-a-${detailsPadding}` : ''}>
        {children}
      </AccordionDetails>
    </Accordion>
    {loading && <CustomLinearProgress />}
  </>);
};
