import React from 'react'
import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { get } from 'lodash';

export default function SBSwitch(props) {
  const { checked, handleToggle, name, disabled, noMargin } = props;

  const propWidth = get(props, 'width', 56)
  const propHeight = get(props, 'height', 34)
  const propSize = get(props, 'size', 22)

  const pxToRem = (px, oneRemPx = 17) => `${px / oneRemPx}rem`;
  const borderWidth = 2;
  const width = pxToRem(propWidth);
  const height = pxToRem(propHeight);
  const size = pxToRem(propSize);
  const gap = (propHeight - propSize) / 2;

  const LovelySwitch = withStyles((theme) => ({
    root: {
      width,
      height,
      padding: 0,
      margin: noMargin ? 0 : theme.spacing(1),
      overflow: 'unset',
    },
    switchBase: {
      padding: pxToRem(gap),
      '&$checked': {
        color: '#fff',
        transform: `translateX(calc(${width} - ${size} - ${pxToRem(2 * gap)}))`,
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 'none',
        },
        '& $thumb': {
          backgroundColor: '#fff',
        },
      },
    },
    track: {
      borderRadius: 40,
      border: `solid ${theme.palette.grey[400]}`,
      borderWidth,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
      boxSizing: 'border-box',
    },
    thumb: {
      boxShadow: 'none',
      backgroundColor: theme.palette.grey[400],
      width: size,
      height: size,
    },
    checked: {},
  }))(Switch);

  return (
    <LovelySwitch color="primary" checked={checked} onChange={handleToggle} name={name} disabled={disabled} />
  )
};
