import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { CardDash } from '../../../projectDashboard/components';
import { Box, CircularProgress, Typography } from '@material-ui/core';

export function definePercent(currentVolt = 0, min = 0, max = 100) {
  const spread = max - min;

  if (currentVolt > max) {
    return 100;
  }

  if (currentVolt < min) {
    return 0;
  }

  const diff = currentVolt - min;

  const percent = Number(diff * 100 / spread).toFixed();

  return Number(percent);
}

export const DeviceWidgets = ({ device }) => {
  const [snapshot] = device.snapshot;

  const [voltagePercent] = useState(definePercent(snapshot.power.volt, 12, 13));
  const [temperaturePercent] = useState(definePercent(snapshot.temp, 10, 100));

  useEffect(() => {
    const mapElement = document.getElementById('map-widget');
    const { gps } = snapshot;
    console.log('snapshot', snapshot);
    new google.maps.Map(mapElement, {
      center: { lat: gps.latitude, lng: gps.longitude },
      zoom: 12,
    });
  }, []);

  return (
      <Grid spacing={3} container>
        <Grid xs={4} item>
          <CardDash
              title={
                <Grid alignItems="center" spacing={2} container>
                  <span>Location</span>
                </Grid>
              }>
            <div id="map-widget" style={{ height: 200 }} />
          </CardDash>
        </Grid>

        <Grid xs={3} item>
          <CardDash
              title={
                <Grid alignItems="center" spacing={2} container>
                  <span>Battery</span>
                </Grid>
              }>
            <span className="text-muted text-center">
              <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                 <Box position="relative" display="inline-flex">
                   <CircularProgress
                       variant="determinate"
                       value={voltagePercent}
                       style={{ height: 120, width: 120 }}
                   />
                   <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center"
                        justifyContent="center">
                     <Typography variant="h4" component="div" color="textSecondary">
                       <span>{voltagePercent}%</span>
                     </Typography>
                   </Box>
                 </Box>
              </div>
            </span>
          </CardDash>
        </Grid>

        <Grid xs={3} item>
          <CardDash
              title={
                <Grid alignItems="center" spacing={2} container>
                  <span>Temperature</span>
                </Grid>
              }>
            <span className="text-muted text-center">
              <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box position="relative" display="inline-flex">
                   <CircularProgress
                       variant="determinate"
                       value={temperaturePercent}
                       style={{ height: 120, width: 120 }}
                   />
                   <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center"
                        justifyContent="center">
                     <Typography variant="h4" component="div" color="textSecondary">
                       <span>{snapshot.temp}C</span>
                     </Typography>
                   </Box>
                 </Box>

              </div>
            </span>
          </CardDash>
        </Grid>

        <Grid xs={2} item>
          <CardDash
              title={<Grid alignItems="center" spacing={2} container>
                <span>Details</span>
              </Grid>}
              expanded>
            <div className="p-x-2">
              <table style={{ height: 200, width: '100%' }}>
                <tbody>
                <tr>
                  <td>Version</td>
                  <td align="right">{device.attributes.version}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td align="right">{device.current_status}</td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td align="right">{device.device_type}</td>
                </tr>
                <tr>
                  <td>Project</td>
                  <td align="right">
                    {device.project && (<a href={`/projects/${device.project._id}`}>{device.project.name}</a>)}
                  </td>
                </tr>
                <tr>
                  <td>Company</td>
                  <td align="right">
                    {device.company && (<a href={`/companies/${device.company._id}`}>{device.company.name}</a>)}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </CardDash>
        </Grid>
      </Grid>
  );
};
