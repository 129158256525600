import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import { $axios } from '../../../helpers';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Add from '@material-ui/icons/Add';
import { TransferList } from '../../../crews/components';
import { BroadcastConfirm } from './broadcastConfirm';
import { SettingsContext } from '../../contexts/SettingsContext';
import { get } from 'lodash';

const BroadcastDialog = ({ title = 'Add', config, project, onBroadcast }) => {
  const settingsContext = useContext(SettingsContext);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({ es: '', en: '' });
  const [source, setSource] = useState('company');
  const [loading, setLoading] = useState(false);
  const [workers, setWorkers] = useState([]);
  const [selectedWorkers, setSelectedWorkers] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedCrew, setSelectedCrew] = useState('');

  const transLocale = {
    es: 'Spanish',
    en: 'English',
  }

  function fetchWorkers() {
    setLoading(true);

    const filters = [];

    if (selectedActivity) {
      filters.push({ key: 'lastSeen', value: selectedActivity });
    }

    if (selectedCompany) {
      filters.push({ key: 'company', value: [selectedCompany] });
    }

    if (selectedCrew) {
      filters.push({ key: 'crew', value: [selectedCrew] });
    }

    const params = { filters, skipPagination: true, viewType: "compact" };

    const url = `${config.workersEndpoint}/project/${project}/workers/list`;

    $axios.$post(url, params)
        .then((data) => setWorkers(data))
        .finally(() => setLoading(false));
  }

  function handleSource(event, newSource) {
    return !newSource || setSource(newSource);
  }

  function handleComparyOrCrew({ target }) {
    const value = target.value;

    if (source === 'company') {
      setSelectedCrew('');
      setSelectedCompany(value);
    }

    if (source === 'crew') {
      setSelectedCompany('');
      setSelectedCrew(value);
    }
  }

  function handleCompanyChange(value) {
      setSelectedCrew('');
      setSelectedCompany(value);
  }

  function handleMessage(locale, value) {
    return setMessage({ ...message, [locale]: value });
  }

  function handleCloseDialog() {
    setWorkers([]);

    setSelectedWorkers([]);
    setSelectedActivity('');
    setSelectedCompany('');
    setSelectedCrew('');

    setOpen(false);
  }

  function isFormValid() {
    if (message.en === '' && message.es === '') {
      return false;
    }

    return selectedWorkers.length !== 0;
  }

  useEffect(fetchWorkers, [selectedActivity, selectedCompany, selectedCrew]);

  const canCreate = get(settingsContext, 'uiConfig.ui.modules.broadcast.permissions.canCreate')

  return <>
    <Button disabled={!canCreate} variant='contained' style={{ color: canCreate ? 'white' : '#757473', backgroundColor: canCreate ? '#78bd5d' : '#dfdddc' }} size={'small'} onClick={() => setOpen(true)}>
      <Add />
      <span>{title}</span>
    </Button>

    { open &&
      <Dialog maxWidth={'md'} open={open} onClose={handleCloseDialog}  fullWidth>
        <DialogTitle >
          <div style={{ display: 'flex', width: "100%", justifyContent: 'space-between', alignItems: 'center' }}>
            <b>Configure broadcast</b>
            <CloseIcon style={{ cursor: 'pointer', color: '#a7a7a7' }} onClick={handleCloseDialog} />
          </div>
        </DialogTitle>

        <DialogContent>
          <Grid className="m-b-3" spacing={2} container>
            {Object.keys(message).map(locale => (
                <Grid key={locale} xs={true} item>
                  <Grid container>
                    <Grid xs={true} item>
                      <FormLabel>{transLocale[locale]}</FormLabel>
                    </Grid>
                    <Grid xs={'auto'} item>
                      <FormLabel>{message[locale].length}/160</FormLabel>
                    </Grid>
                  </Grid>
                  <TextField
                      className="w-100"
                      defaultValue={message[locale]}
                      minRows={5}
                      variant={'outlined'}
                      multiline
                      inputProps={{ maxLength: 160 }}
                      onChange={({ target: { value } }) => handleMessage(locale, value)}
                  />
                </Grid>
            ))}
          </Grid>

          <Grid className="m-b-3" spacing={2} container>
            <Grid xs={4} item>
              <FormControl className="w-100" size="small">
                <FormLabel>Source</FormLabel>
                <ToggleButtonGroup value={source} variant="contained" size={'small'} exclusive onChange={handleSource}>
                  <ToggleButton className="w-100" value="company">Companies</ToggleButton>
                  <ToggleButton className="w-100" value="crew">Crews</ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
            </Grid>

            <Grid xs={4} item>
                <FormControl size="small" fullWidth>
                    <FormLabel className="text-capitalize">{source}</FormLabel>

                    {source === 'crew' &&
                        <Select variant="outlined" value={selectedCrew} autoWidth onChange={handleComparyOrCrew}>
                            <MenuItem value="">Select All</MenuItem>
                            {get(settingsContext, 'uiConfig.data.crews', []).map(({ _id, name }) => <MenuItem key={_id} value={_id}>{name}</MenuItem>)}
                        </Select>
                    }

                    {source === 'company' &&
                        <Select variant="outlined" value={selectedCompany} autoWidth onChange={handleComparyOrCrew}>
                            <MenuItem value="">Select All</MenuItem>
                            {get(settingsContext, 'uiConfig.data.contractors', []).map(({ value, label }) => <MenuItem key={value} value={value}>{label}</MenuItem>)}
                        </Select>
                    }
                </FormControl>
            </Grid>

            <Grid xs={4} item>
              <FormControl size="small" fullWidth>
                <FormLabel>Activity</FormLabel>
                <Select
                    value={selectedActivity}
                    variant="outlined"
                    autoWidth
                    onChange={({ target }) => setSelectedActivity(target.value)}>
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="lastSeven">Last 7 days</MenuItem>
                  <MenuItem value="anytime">Anytime</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <hr />

          <Grid className="m-b-3" spacing={2} container>
            <Grid xs={12} item>
              <TransferList
                  loadingWorkers={loading}
                  workers={workers}
                  crew={selectedCrew}
                  currentCompany={selectedCompany}
                  onAddWorkersCrew={setSelectedWorkers}
                  selectTitle="Broadcast targeted workers"
                  companies={get(settingsContext, 'uiConfig.data.contractors', [])}
                  onChangeCompany={handleCompanyChange}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className="widget-chat-text">
          <BroadcastConfirm
              workers={selectedWorkers}
              project={project}
              config={config}
              source={source}
              message={message}
              disabled={!isFormValid()}
              onBroadcast={(broadcast) => {
                onBroadcast(broadcast);
                return handleCloseDialog();
              }} />
        </DialogActions>
      </Dialog>
    }
  </>;
};

export function formatUSNumber(entry) {
  const match = entry
      .replace(/\D+/g, '').replace(/^1/, '')
      .match(/([^\d]*\d[^\d]*){1,10}$/)[0]
  const part1 = match.length > 2 ? `(${match.substring(0,3)})` : match
  const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
  const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''
  return `${part1}${part2}${part3}`
}

export default BroadcastDialog;
