import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ThemeProvider } from '@material-ui/styles';
import { myTheme } from './helpers';
import { makeStyles } from '@material-ui/core';

class IdleTimer {

  constructor({ timeout, onTimeout, onExpired }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;

    const expiredTime = parseInt(localStorage.getItem("_expiredTime") || 0, 10);
    if (expiredTime > 0 && expiredTime < Date.now()) {
      onExpired();
      return;
    }

    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval() {

    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = parseInt(
        localStorage.getItem("_expiredTime") || 0,
        10
      );

      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanUp();
        }
      }
    }, 1000);
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem("_expiredTime", Date.now() + this.timeout * 1000);
    }, 300);
  }

  tracker() {
    window.addEventListener("mousemove", this.eventHandler);
    window.addEventListener("scroll", this.eventHandler);
    window.addEventListener("keydown", this.eventHandler);
  }

  cleanUp() {
    // localStorage.removeItem("_expiredTime");
    clearInterval(this.interval);
    window.removeEventListener("mousemove", this.eventHandler);
    window.removeEventListener("scroll", this.eventHandler);
    window.removeEventListener("keydown", this.eventHandler);
  }
}


const useStyles = makeStyles((theme) => ({
  dialog: {
    width: "100%",
    height: "300px"
  },
  paper: {
    width: '470px'
  },
  root: {
    backgroundColor: "rgb(1 1 1 / 49 %)",
    "backdropFilter": "blur(8px)"
  },
  content: {
    "height": "100%",
    "display": "flex",
    "flexDirection": "column",
    "alignItems": "center",
    "justifyContent": "space-around"
  }
}));

export default function InactiveDashboard(props) {

  const classes = useStyles();

  const [isTimeout, setIsTimeout] = useState(false);

  useEffect(() => {

    const timer = new IdleTimer({
      timeout: 86400, // expire after 24 hours
      onTimeout: () => {
        setIsTimeout(false);
      },
      onExpired: () => {
        // do something if expired on load
        setIsTimeout(true);
        localStorage.setItem("_expiredTime", 0);

      }
    });

    return () => {
      timer.cleanUp();
    };
  }, []);


  const onRefresh = () => {
    window.location.reload()
  }

  return (
    <ThemeProvider theme={myTheme}>
      <div>
        <Dialog
          maxWidth="lg"
          classes={{ root: classes.root, paper: classes.paper }}
          open={isTimeout}

          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">Idle time detected</DialogTitle> */}
          <DialogContent classes={{ root: classes.dialog }}
          >
            <div className={classes.content}>

              <img src="https://s3.us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/dashboard-nav/inactiveDashboard.svg" height='70' width="70" />

              <strong>You have been inactive since a long time, are you still connected?</strong>

              <Button onClick={onRefresh} style={{ backgroundColor: '#f15922', color: '#ffffff' }}>
                Refresh
              </Button>


            </div>
          </DialogContent>
        </Dialog>
      </div>
    </ThemeProvider>
  );

};
