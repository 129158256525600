import BusinessIcon from '@material-ui/icons/Business'
import Avatar from '@material-ui/core/Avatar'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import React from 'react'
import Tree from 'react-d3-tree'

function defineNode(project) {
  project.children = ((project && project.contractors) || [])
  project.children.map(defineNode)

  return project
}

function DefineIcon(type) {
  switch (type.type) {
    case 'project':
      return <BusinessIcon color="primary" />
    case 'client':
      return <BusinessIcon color="warning" />
    default:
      return <BusinessIcon color="error" />
  }
}

function CustomNode({ nodeDatum, toggleNode }) {
  return (
    <foreignObject x={-120} y={-50} width={250} height={75} onClick={toggleNode}>
      <Card
        variant="outlined"
        style={{
          display: 'flex',
          alignItems: 'center',
          borderRadius: '5px',
          height: '100%',
          width: '100%',
          backgroundColor: '#ffeedc'
        }}
      >
        <Avatar style={{ stroke: 'none', margin: '8px' }}>
          <DefineIcon type={nodeDatum.type} />
        </Avatar>
        <CardContent style={{ width: '100%', padding: 'unset' }}>
          <Typography variant={'h6'} title={nodeDatum.name} style={{ display: 'grid' }}>
            <span className="text-truncate" style={{ display: 'block', width: '100%' }}>
              {nodeDatum.name}
            </span>
          </Typography>
        </CardContent>
      </Card>
    </foreignObject>
  )
}

export function SChartOrganization(props) {
  const {
    project,
    orientation = 'vertical',
    pathFunc = 'step',
    draggable = true,
    collapsible = true,
    nodeSize = { x: 300, y: 300 },
    translate = { x: 650, y: 100 }
  } = props

  return (
    <Tree
      data={defineNode(project)}
      draggable={draggable}
      collapsible={collapsible}
      pathFunc={pathFunc}
      nodeSize={nodeSize}
      orientation={orientation}
      translate={translate}
      renderCustomNodeElement={CustomNode}
      zoom={0.75}
    />
  )
}
