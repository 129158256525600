import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, ThemeProvider } from '@material-ui/core';
import { myTheme } from './helpers';
import axios from 'axios';
import { axiosConfig } from './helpers/index';
import { get } from 'lodash';

export default function WorkedLunchBreak(props) {

  const { workedLunchBreak, shiftId, projectId, workedBreakEndPoint, approved, status } = props;

  console.log(approved, status)

  const [workedLunch, setWorkedLunch] = useState(workedLunchBreak);
  const [checkBoxStatus, setCheckboxStatus] = useState(workedLunchBreak);

  const onWorkedChange = async (e) => {
    try {
      setWorkedLunch(e);
      await axios.patch(`${workedBreakEndPoint}/${get(shiftId, '$oid')}/${get(projectId, '$oid')}`, { worked_lunch_break: e }, axiosConfig())
    } catch (error) {
      console.log(error)
    }

  };

  // Shift open - disabled
  // Closed shift - enabled
  // Closed and approved - disabled

  return (
    <ThemeProvider theme={myTheme}>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={workedLunch}
              disabled={approved}
              onChange={(e) => onWorkedChange(e.target.checked)}
              name="checkedA" />
          }
        />
      </div>
    </ThemeProvider>
  )
};