import React, { useState, useEffect } from 'react';
import { Avatar, MenuItem, makeStyles, Select, Switch, Chip, IconButton } from '@material-ui/core';
import { get, size, has, isNull } from 'lodash';
import { deleteWorkerImageProfile } from '../../../services/employeeService';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import workerProfileIcon from '../../../../../../assets/images/worker_types/constructionWorkerIcon-2023.svg';

const useStyles = makeStyles((theme) => ({
  avatar: {
    position: 'relative',
    zIndex: 2,
    width: 100,
    height: 100,
    marginRight: 16,
    '&:hover': {
      '& $clearProfileImageButton': {
        opacity: 1,
      },
    },
  },
  workerBasicInfoTop: {
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#f3e3df',
    margin: -20,
    padding: 20,
    borderRadius: 0,
    borderBottom: '2px solid #f1673440',
    position: 'relative'
  },
  workerInfo: {
    display: 'flex',
    flexDirection: 'column'
  },
  dbidChip: {
    background: theme.palette.primary.main,
    marginLeft: 8
  },
  dbidChipLabel: {
    color: 'white',
    fontWeight: 600,
    paddingTop: 2,
    fontSize: 14,
    // textShadow: "1px 1px 1px #682d17",
  },
  clearProfileImageButton: {
    opacity: 0,
    position: 'absolute',
    right: -2,
    top: -5,
    color: '#ff1900'
  },
  contactInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    color: '#505050'
  },
  contactInfoIcon: {
    marginRight: 4,
    fontSize: 18,
  },
  crewWrapper: {
    fontSize: '1.2rem',
    fontWeight: 400,
    color: '#978d8b',
    marginTop: 4
  }
}));

export default function WorkerInfoSection(props) {

  const { worker, company, companies, employee_settings_endpoint, onChangeBasic, disableCompanySelect, backgroundIcon } = props;

  const [state, setState] = useState({
    currentCompany: company,
    showHireRate: false,
    editing: false,
    workerEdited: {
      ...worker,
      company_id: get(company, 'value'),
      confirm_phone_number: get(worker, 'phone_number'),
      mobileAppSettings: {
        enableMobileApp: get(worker, 'mobileAppSettings.enableMobileApp', true),
        kioskModeEnabled: get(worker, 'mobileAppSettings.kioskModeEnabled', false),
        checkWorkerLogged: get(worker, 'mobileAppSettings.checkWorkerLogged', false)
      }
    }
  });

  const onCompanyChange = e => {
    const tempCompany = companies.find(item => get(item, 'value') === e)

    setState(prevState => ({
      ...prevState,
      currentCompany: tempCompany
    }))

    onChangeBasic('company_id', e);
    onChangeBasic('company', {
      name: get(tempCompany, 'label'),
      _id: get(tempCompany, 'value')
    });
  }

  const onClearImageProfile = async () => {
    try {

      setState(prevState => ({
        ...prevState,
        workerEdited: {
          ...prevState.workerEdited,
          image_path: null
        }

      }));

      const res = await deleteWorkerImageProfile(employee_settings_endpoint, get(worker, 'dbid'))

    } catch (error) {

    }
  }

  useEffect(() => {
    if (!get(state, 'currentCompany')) {
      setState(prevState => ({
        ...prevState,
        currentCompany: get(props, 'company')
      }))
    }
  }, [props.company]);

  const classes = useStyles();

  return (
    <div className={classes.workerBasicInfoTop}>
      <div className={classes.avatar}>
        <Avatar classes={{ root: classes.avatar }} atl='Worker' src={has(state.workerEdited, 'image_path') && !isNull(get(state.workerEdited, 'image_path')) ? `${get(state.workerEdited, 'image_path')}` : workerProfileIcon} />
        {has(state.workerEdited, 'image_path') && <IconButton size='small' className={classes.clearProfileImageButton} onClick={onClearImageProfile}><RemoveCircleOutlineIcon style={{ color: '#ff1900'  }} /></IconButton> }
      </div>
      <div className={classes.workerInfo}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{`${get(state.workerEdited, 'first_name')} ${get(state.workerEdited, 'last_name')}`}</span>
          <Chip
            classes={{ root: classes.dbidChip, labelSmall: classes.dbidChipLabel }}
            size="small"
            label={get(worker, 'dbid')}
            />
        </div>
        <div className={classes.contactInfoWrapper}>
          <div style={{ display: 'flex', alignItems: 'center' }}><PhoneEnabledIcon className={classes.contactInfoIcon} /> {get(state.workerEdited, 'phone_number')}</div>
          <div style={{ display: 'flex', alignItems: 'center' }}><MailOutlineIcon className={classes.contactInfoIcon} /> {get(state.workerEdited, 'email')}</div>
        </div>
        { size(companies) > 0 ?
          <Select value={get(state, 'currentCompany.value', '')} onChange={(e) => onCompanyChange(e.target.value)} disabled={disableCompanySelect}>
            { companies.map(item =>
              <MenuItem key={get(item, 'value')} value={get(item, 'value')}>
                {get(item, 'label')}
              </MenuItem>
            )
          }
        </Select> :
        <span>{get(worker, 'company.name')}</span>
        }
        { has(worker, 'crew') ? <span className={classes.crewWrapper}>Crew: <i>{get(worker, 'crew.name')}</i></span> : null }
    </div>
    { backgroundIcon && backgroundIcon }
  </div>
)
};
