import React, { useContext, useState } from 'react';
import EventNote from '@material-ui/icons/Receipt';
import IconButton from '@material-ui/core/IconButton';
import axios from 'axios';
import { axiosConfig } from '../../../../../helpers';
import { Popover } from '@material-ui/core';
import { ProjectContext } from '../../ProjectMetrics';
import EventLogs from './EventLogs';
import { makeStyles } from '@material-ui/core/styles';

export const DialogEventLogs = ({ item }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { config: { project_workSummary_endpoint: endpoint }, project } = useContext(ProjectContext);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const obId = open ? 'simple-popover' : undefined;

  addEventListener('scroll', handleClose);

  const useStyles = makeStyles((theme) => ({
    logsPopover: {
      height: 150,
      borderRadius: 10,
      marginLeft: -3,
      marginTop: -3,
      overflowX: 'unset',
      overflowY: 'unset',
      '&::before': {
        zIndex: 9999,
        content: '""',
        border: '1px solid rgba(241, 89, 34)',
        borderLeft: '1px solid rgba(241, 89, 34, 0.1)',
        backgroundColor: 'rgba(241, 89, 34)',
        position: 'absolute',
        marginRight: '-0.72em',
        top: 'calc(50% - 12px)',
        right: -6,
        width: 16,
        height: 16,
        boxShadow: theme.shadows[1],
        transform: 'translate(-50%, 50%) rotate(45deg)',
        clipPath: 'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))',
      },
    },
  }));

  const classes = useStyles();

  function fetchLogs() {
    const url = `${endpoint}/logs/${project._id.$oid}/${item._id}/incident`;

    return axios.get(url, axiosConfig())
      .then(({ data }) => data);
  }

  return <div>
    <IconButton onClick={handleClick}>
      <EventNote />
    </IconButton>

    <Popover
      id={obId}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      classes={{ paper: classes.logsPopover }}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'right' }}
    >
      <EventLogs fetch={fetchLogs} />
    </Popover>
  </div>;
};
