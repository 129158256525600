import React, { useContext, useState } from 'react';
import { get } from 'lodash';
import { IconButton, Link, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';
import { DatePickerComponent } from './index';
import { SettingsContext } from '../contexts/SettingsContext';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Hidden from '@material-ui/core/Hidden';
import SettingsIcon from '@material-ui/icons/Settings';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles(() => ({
  img: {
    width: '39px !important',
    height: '39px !important',
  },
  link: {
    marginLeft: '5px',
    cursor: 'pointer',
  },
  disabledLink: {
    color: 'grey',
    opacity: 0.5,
    cursor: 'auto',
    pointerEvents: 'none'
  },
  iconList: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  iconListMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  menu: {
    width: '76px',
    top: '121px !important',
  },
  missingTimezoneWrapper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#faf0e1',
    border: '1px solid #F6A200',
    borderRadius: 4,
    padding: 8
  }
}));

export default function TopHeaderPage(props) {

  const { project, company, onShowSettings, onShowTimeRules, clearTimesheetData } = props;

  const settingsContext = useContext(SettingsContext);

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const S3Image = name => `https://s3.us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/dashboard-nav/${name}`;

  return (
      <Grid className="m-b-3" alignItems="center" justifyContent="space-between" container>
        <Grid xs={10} lg={true} item>
          <Breadcrumbs className="text-truncate" separator="">
            <Hidden only={['xs', 'sm', 'md']}>
              <Typography variant={'h5'} color="inherit" href="/">
                {get(company, 'name')}
              </Typography>
            </Hidden>

            <Hidden only={['xs', 'sm', 'md']}>
              <span>/</span>
            </Hidden>

            <Typography variant={'h5'} color="inherit">
              <strong>{get(project, 'name')}</strong>
            </Typography>

            <Hidden only={['xs', 'sm', 'md']}>
              <span>/</span>
            </Hidden>

            <Hidden only={['xs', 'sm', 'md']}>
              <Typography variant={'h5'} color="textPrimary">
                <strong>Project</strong>
              </Typography>
            </Hidden>
            { (!get(project, 'timezone')) &&
              <div className={classes.missingTimezoneWrapper}>
                <WarningIcon style={{ color: '#F6A200', marginRight: 4 }} /> There is no timezone set for this project
              </div>
            }
          </Breadcrumbs>
        </Grid>

        <Grid xs={2} lg={'auto'} item>
          <Grid justifyContent="flex-end" container>
            <Hidden only={['lg', 'xl']}>
              <IconButton color="primary" onClick={handleClick}>
                <SettingsIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleClose}>
                  <DatePickerComponent
                      clearTimesheetData={clearTimesheetData}
                      inputProps={{ style: { textAlign: 'center' } }}
                      format="dd LLL y"
                      style={{ width: 125 }}
                  />
                </MenuItem>
                <MenuItem disabled={!get(settingsContext, 'uiConfig.ui.actions.productivity')}>
                  <Link className={clsx(classes.link, { [classes.disabledLink]: !get(settingsContext, 'uiConfig.ui.actions.productivity') })} href={`/projects/${get(project, '_id.$oid')}/costcode_settings`}>
                    <span>CostCode Settings</span>
                  </Link>
                </MenuItem>
                <MenuItem disabled={!get(settingsContext, 'uiConfig.ui.actions.projectWorker')}>
                  <Link className={clsx(classes.link, { [classes.disabledLink]: !get(settingsContext, 'uiConfig.ui.actions.projectWorker') })} href={`/projects/${get(project, '_id.$oid')}/employees`}>
                    <span>Project Workers</span>
                  </Link>
                </MenuItem>
                <MenuItem disabled={!get(settingsContext, 'uiConfig.ui.modules.projectSettings.permissions.canEdit')} onClick={() => onShowSettings(true)}>
                  <Link className={clsx(classes.link, { [classes.disabledLink]: !get(settingsContext, 'uiConfig.ui.modules.projectSettings.permissions.canEdit') })}  href="#">Edit Project</Link>
                </MenuItem>
                <MenuItem disabled={!get(settingsContext, 'uiConfig.ui.modules.timeRules.permissions.canEdit')} onClick={() => onShowTimeRules(true)}>
                  <Link className={clsx(classes.link, { [classes.disabledLink]: !get(settingsContext, 'uiConfig.ui.modules.timeRules.permissions.canEdit') })}  href="#">Time Rules</Link>
                </MenuItem>
              </Menu>
            </Hidden>

            <Hidden only={['xs', 'sm', 'md']}>
              <Grid item>
                <DatePickerComponent
                    clearTimesheetData={clearTimesheetData}
                    inputProps={{ style: { textAlign: 'center' } }}
                    format="dd LLL y"
                    style={{ width: 125 }}
                />
              </Grid>
              <Grid item>
                <Link className={clsx(classes.link, { [classes.disabledLink]: !get(settingsContext, 'uiConfig.ui.actions.productivity') })} href={`/projects/${get(project, '_id.$oid')}/costcode_settings`}>
                  <img className={classes.img} src={S3Image('setup-budgets.svg')} />
                </Link>
              </Grid>
              <Grid item>
                <Link className={classes.link} href={`/projects/${get(project, '_id.$oid')}?autoreload=true`}>
                  <img className={classes.img} src={S3Image('auto-reload-off.svg')} />
                </Link>
              </Grid>
              <Grid item>
                <Link className={clsx(classes.link, { [classes.disabledLink]: !get(settingsContext, 'uiConfig.ui.actions.projectWorker') })} href={`/projects/${get(project, '_id.$oid')}/employees`}>
                  <img className={classes.img} src={S3Image('project-workers.svg')} />
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" className={clsx(classes.link, { [classes.disabledLink]: !get(settingsContext, 'uiConfig.ui.modules.projectSettings.permissions.canEdit') })} onClick={() => onShowSettings(true)}>
                  <img className={classes.img} src={S3Image('edit-project.svg')} />
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" className={clsx(classes.link, { [classes.disabledLink]: !get(settingsContext, 'uiConfig.ui.modules.timeRules.permissions.canEdit') })} onClick={() => onShowTimeRules(true)}>
                  <img className={classes.img} src={S3Image('timeRulesIcon.svg')} />
                </Link>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
  );
};
