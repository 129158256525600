import axios from 'axios';
import { axiosConfig } from '../helpers/index';

export const gettingCompanyEmployeeSettings = (companyWorkersDomain) => {
  return axios.get(`${companyWorkersDomain}/workers/listSettings`, axiosConfig())
}

export const gettingCompanyProfiles = (companyId, payload, paymentProfileDomain) => {
  return axios.post(`${paymentProfileDomain}/${companyId}/list/compact`, payload, axiosConfig())
};

export const createCrew = (companyId, payload, crewEndpoint) => {
  return axios.post(`${crewEndpoint}/${companyId}`, payload, axiosConfig())
}

export const editCrew = (companyId, payload, crewEndpoint, crewId) => {
  return axios.patch(`${crewEndpoint}/${companyId}/${crewId}`, payload, axiosConfig())
}

export const deletingCrew = (companyId, crewEndpoint, crewId) => {
  return axios.delete(`${crewEndpoint}/${companyId}/${crewId}`, axiosConfig())
}

export const gettingCompanyCrews = (companyId, payload, crewEndpoint) => {
  return axios.post(`${crewEndpoint}/${companyId}/list`, payload, axiosConfig())
}
export const gettingWorkersByCompany = (company_id, payload, companyWorkersDomain) => {
  return axios.post(`${companyWorkersDomain}/${company_id}/workers/list`, payload, axiosConfig())
}

export const gettingCrewDetails = (company_id, crew_id, crewEndpoint) => {
  return axios.get(`${crewEndpoint}/${company_id}/${crew_id}`, axiosConfig())
}