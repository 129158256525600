export const allColumns = [
  {
    id: "image_path",
    isActive: true,
    isImage: true,
    sortOption: false,
    label: "Image"
  },
  {
    id: "worker_name",
    sortOption: true,
    isActive: true,
    label: "Name"
  },
  {
    id: "phone_number",
    sortOption: true,
    isActive: true,
    label: "Phone"
  },
  {
    id: "mobile_auth",
    sortOption: true,
    isActive: true,
    label: "Mobile Auth"
  },
  {
    id: "payroll_id",
    sortOption: false,
    isActive: true,
    label: "Payroll ID"
  },
  {
    id: "email",
    sortOption: true,
    isActive: true,
    label: "Email"
  },
  {
    id: "rate",
    sortOption: true,
    label: "Rate",
    isActive: true
  },
  {
    id: "per_diem",
    sortOption: true,
    label: "Per Diem",
    isActive: false
  },
  {
    id: "idCard",
    sortOption: false,
    label: "ID Card",
    isActive: true
  },
  {
    id: "rfid",
    sortOption: false,
    label: "RFID",
    isActive: true
  },
  {
    id: "language_code",
    sortOption: false,
    label: "Language",
    isActive: false
  },
  {
    id: "address",
    sortOption: false,
    label: "Address",
    isActive: false
  },
  {
    id: "zipcode",
    sortOption: false,
    label: "Zip Code",
    isActive: false
  },
  {
    id: "department",
    sortOption: false,
    label: "Department",
    isActive: false
  },
  {
    id: "position",
    sortOption: false,
    label: "Position",
    isActive: false
  },
  {
    id: "actions",
    sortOption: false,
    label: "Actions",
    isActive: true
  }
];

const defaults = [
  "image_path",
  "worker_name",
  "phone_number",
  "mobile_auth",
  "payroll_id",
  "email",
  "rate",
  "idCard",
  "rfid",
  "actions"
]

export const defaultColumns = allColumns.filter( c => defaults.indexOf( c.id) > -1);
