import React from 'react';
import { CustomTab } from '../SectionTab';

const ToolboxTalkTab = ({ items, loading }) => (<CustomTab
    id="toolbox_talk"
    title="Toolbox Talk"
    items={items}
    loading={loading}
    fillColor="#99c24d"
    fillBackgroundColor="#d1e2b0"
/>);

export default ToolboxTalkTab;
