import NotificationManager from 'react-notifications/lib/NotificationManager';

export const notificationCenter = (type, message) => {
  switch (type) {
    case 'info':
      NotificationManager.info(message);
      break;
    case 'success':
      NotificationManager.success(message);
      break;
    case 'warning':
      NotificationManager.warning(message);
      break;
    case 'error':
      NotificationManager.error(message);
      break;
  }
};