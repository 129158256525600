import React, { useState } from "react"
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stepper, Step, StepLabel, ThemeProvider, FormControlLabel, FormGroup, Checkbox, StepContent } from "@material-ui/core";
import { myTheme, axiosConfig } from "./helpers";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Axios from "axios";

const HealthAssesmentExport = (props) => {

  const { project, healthAssesmentExportEndpoint } = props

  const [wizardOpen, setWizardOpen] = useState(false);
  const [step, setStep] = useState(0);
  const initialState = {
    start: new Date(),
    end: new Date()
  }
  const headersInitialState = () => {
    let result = {}
    csvHeadersKeys().forEach(key => {
      result[key] = true
    })

    return result
  }
  const [range, setRange] = useState(initialState);
  const [headers, setHeaders] = useState(headersInitialState());
  const [downloadUrl, setDownloadUrl] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChangeBld = (prop) => {
    return (ev) => {
      let rng = range
      rng[prop] = ev
      console.log({ range });
      setRange({ ...rng })
    }
  }

  const handleHeadersCheck = (prop) => {
    return (ev, checked) => {
      headers[prop] = checked
      setHeaders({ ...headers })
    }
  }

  const handleStartChange = handleChangeBld('start')
  const handleEndChange = handleChangeBld('end')

  const handleBack = () => {
    setStep(step - 1)
  }

  const handleNext = () => {
    if (step == 1) {
      downloadData()
    }
    setStep(step + 1)
  }

  const downloadData = () => {
    const startM = moment(range.start)
    const endM = moment(range.end)
    const fmt = "YYYYMMDD"
    const rangeFmt = `${startM.format(fmt)}-${endM.format(fmt)}`
    let questions = []
    csvHeadersKeys().forEach(key => {
      if (headers[key]) {
        questions.push(key)
      }
    })
    const questionsFmt = questions.join(",")
    const url = `${healthAssesmentExportEndpoint}/export/${project._id.$oid}/${rangeFmt}/${questionsFmt}`
    console.log({ url });
    setLoading(true)
    Axios.get(url, axiosConfig()).then(response => {
      setLoading(false)
      console.log(response.data)
      const location = response.data.location
      setDownloadUrl(location)
    }).catch(errorLoadingExportContent => {
      setLoading(false)
      setError("An Error Ocurred")
      console.error(errorLoadingExportContent)
    })
  }

  const openWizard = () => {
    setWizardOpen(true)
    setRange(initialState)
    setStep(0)
  }

  const stepOne = () => (
    <div>
      <KeyboardDatePicker
        margin="normal"
        id="start-date-picker-dialog"
        label="Starting Date"
        format="MM/dd/yyyy"
        value={range.start}
        name="start"
        onChange={handleStartChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
      <KeyboardDatePicker
        className="float-right"
        margin="normal"
        id="end-date-picker-dialog"
        label="Ending Date"
        format="MM/dd/yyyy"
        value={range.end}
        name="end"
        onChange={handleEndChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </div>
  )

  const stepTwo = () => (
    <div>
      <form>
        <FormGroup>

          {csvHeadersKeys().map(key => (
            <FormControlLabel
              key={key}
              control={
                <Checkbox color="primary"
                  checked={eval(`headers.${key}`)}
                  onChange={handleHeadersCheck(key)}
                  disabled={csvHeaders()[key]['locked']}
                  value="checkedA"
                />
              }
              label={csvHeaders()[key]['title']}
            />
          ))}

        </FormGroup>
      </form>
    </div>
  )

  const stepThree = () => (
    <div>
      {(() => {
        if (loading) {
          return (
            <p>Loading</p>
          )
        } else {
          if (downloadUrl.length > 0) {
            return (
              <p>Download <a href={downloadUrl}>here</a></p>
            )
          } else if (error.length > 0) {
            return (
              <span>
                <p className="text-danger">*{error}</p>

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={downloadData}
                >Try Again</Button>
              </span>
            )
          } else {
            return (
              <p>Not ready</p>
            )
          }
        }

      })()}
    </div>
  )

  let stepJsx = ''

  switch (step) {
    case 0:
      stepJsx = stepOne()
      break;
    case 1:
      stepJsx = stepTwo()
      break;
    case 2:
      stepJsx = stepThree()
      break;

    default:
      break;
  }

  const buttons = () => (
    <div>
      <Button
        onClick={handleBack}
        disabled={step == 0}
      >Back</Button>

      <Button
        variant="contained"
        disabled={step == (stepsDefinition().length - 1)}
        color="primary"
        onClick={handleNext}
      >Next</Button>
    </div>
  )


  return (
    <>
      <a className="btn btn-secondary pull-right" onClick={openWizard}>
        <i className="fa fa-save" />
      </a>

      <ThemeProvider theme={myTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Dialog open={wizardOpen} maxWidth="md" onClose={() => setWizardOpen(close)} id="jumbo-styles-container">
            <DialogTitle>
              Export Health Assesment Questionaire Data for <strong>{project.name}</strong>
            </DialogTitle>
            <DialogContent>
              <Stepper activeStep={step} className="horizontal-stepper-linear" orientation="vertical">
                {stepsDefinition().map((label, index) => (
                  <Step key={index} className={`horizontal-stepper`} >
                    <StepLabel className="stepperlabel" >{label}</StepLabel>
                    <StepContent className="pb-3">
                      {stepJsx}
                      <br />
                      {buttons()}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setWizardOpen(false)} color="secondary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </MuiPickersUtilsProvider>
      </ThemeProvider>

    </>
  );
}

const stepsDefinition = () => ["Select Range of Dates", "Select Headers to Export", "Download File"]

const csvHeaders = () => ({
  name: {
    index: 0,
    title: "Name",
    locked: true
  },
  company: {
    index: 1,
    title: "Company",
    locked: true
  },
  date: {
    index: 2,
    title: "Date",
    locked: true
  },
  recentContact: {
    index: 3,
    title: "Recent Contact",
    locked: false
  },
  symptoms: {
    index: 4,
    title: "Symptoms",
    locked: false
  },
  howYouFeel: {
    index: 5,
    title: "How You Feel",
    locked: false
  },
  temperature: {
    index: 6,
    title: "Temperature",
    locked: false
  }
})

const csvHeadersKeys = () => Object.keys(csvHeaders())

export default HealthAssesmentExport;
