import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { $axios, myTheme } from '../../../helpers';
import { DeviceListItem } from './DeviceListItem';
import { CardDash } from '../../../projectDashboard/components';
import { Button, CircularProgress, ThemeProvider } from '@material-ui/core';
import { DeviceEditDialog } from './DeviceEditDialog';
import { DeviceRetireDialog } from './DeviceRetireDialog';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';

export function DeviceList({ endpoint = '' }) {
  const [items, setItems] = useState([]);
  const [settings, setSettings] = useState({ projects: [], clients: [] });
  const [filters, setFilters] = useState({ project: '', client: '', query: '' });
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isEditDialog, showEditDialog] = useState(false);
  const [isRetireDialog, showRetireDialog] = useState(false);
  const [currentDevice, setCurrentDevice] = useState(undefined);

  useEffect(() => changePage(page), []);
  useEffect(() => fetchSettings(), []);

  function fetchSettings() {
    const settingsUrl = endpoint
        .split('/')
        .slice(0, -2)
        .concat(['dashboard', 'v1', 'uiSettings', 'devices'])
        .join('/');

    $axios.$get(settingsUrl)
        .then(({ projects = [], clients = [] }) => setSettings({ projects, clients }))
        .catch(() => setSettings({ projects: [], clients: [] }));
  }

  function changePage(newPage, newFilters = filters) {
    const url = new URL(document.location);
    url.searchParams.set('page', newPage);

    setLoading(true);
    setPage(newPage);

    history.pushState(url.toString(), '', url.toString());

    $axios.$post(`${endpoint}/list/${newPage}`, newFilters)
        .then((result) => setItems(result))
        .catch(() => setItems([]))
        .finally(() => (setLoading(false)));
  }

  function handleChangeFilters(key, value) {
    const newFilters = ({ ...filters, [key]: value });
    setFilters({ ...filters, [key]: value });

    return changePage(1, newFilters);
  }

  function forceDbSync(device) {
    setDeviceSyncing(device, true);

    $axios.$delete(`${endpoint}/forceDbSync/${_.get(device, '_id')}`)
        .then((result) => {
            if (toastr) toastr.success('Full DB Sync will occur on next reboot.');
            setDeviceSyncing(device, false);
        })
        .catch(() => {
            if (toastr) toastr.error('Error forcing DB sync.');
            setDeviceSyncing(device, false)
        });
  }

  function setDeviceSyncing(device, syncing) {
    setItems(prevItems => prevItems.map(item => item._id === device._id ? { ...item, syncing } : item));
  }

  const handlPressEnter = ({ target, key }) => key === 'Enter' && handleChangeFilters('query', target.value);

  const canGoNext = () => !loading && items.length > 0;
  const canGoPrev = () => !loading && page > 1;
  const hasNoDevices = () => !items.length;

  return (
      <ThemeProvider theme={myTheme}>
        <CardDash title="Devices" expanded actions={<>
          <Grid spacing={2} alignItems={'center'} container>
            <Grid item>
              <TextField defaultValue={filters.query} label="Quick Search..." onKeyUp={handlPressEnter} type=""/>
            </Grid>

            <Grid item>
              <Autocomplete
                  options={settings.projects}
                  getOptionLabel={({ name }) => name}
                  style={{ width: '15em' }}
                  onChange={(e, value) => handleChangeFilters('project', value ? value._id : '')}
                  renderInput={(params) => <TextField label="Project"  {...params} />}
              />
            </Grid>

            <Grid item>
              <Autocomplete
                  options={settings.clients}
                  getOptionLabel={({ name }) => name}
                  style={{ width: '15em' }}
                  onChange={(e, value) => handleChangeFilters('client', value ? value.client_id : '')}
                  renderInput={(params) => <TextField label="Client"  {...params} />}
              />
            </Grid>

            <Grid item>
              <Button color="primary" disabled={!canGoPrev()} onClick={() => changePage(page - 1)}>
                <span className="font-size-12">Prev</span>
              </Button>
            </Grid>

            <Grid item>
              <Button color="primary" disabled={!canGoNext()} onClick={() => changePage(page + 1)}>
                <span className="font-size-12">Next</span>
              </Button>
            </Grid>
          </Grid>
        </>}>
          {currentDevice && <DeviceEditDialog
              open={isEditDialog}
              device={currentDevice}
              endpoint={endpoint}
              onClose={() => showEditDialog(false)}
          />}

          {currentDevice && <DeviceRetireDialog
              open={isRetireDialog}
              device={currentDevice}
              endpoint={endpoint}
              onClose={() => showRetireDialog(false)}
          />}

          <Table className="table-hover table-striped">
            <TableHead style={{ backgroundColor: '#ff57220d' }}>
              <TableRow classes={{ head: 'font-size-13' }} hover>
                <TableCell>Serial Number</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Auth</TableCell>
                <TableCell>Connection</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Power</TableCell>
                <TableCell>Version</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>HEX</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Wake Up</TableCell>
                <TableCell>Stay Awake</TableCell>
                <TableCell>Current Project</TableCell>
                <TableCell>Company</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (<TableRow>
                <TableCell align="center" colSpan={99}>
                  <CircularProgress />
                </TableCell>
              </TableRow>)}

              {!loading && hasNoDevices() && (<TableRow>
                <TableCell align="center" colSpan={99}>
                  <span>No devices to show</span>
                </TableCell>
              </TableRow>)}

              {!loading && items.map((device, key) => (
                  <DeviceListItem
                      key={key}
                      device={device}
                      onEdit={(device) => {
                        showEditDialog(true);
                        setCurrentDevice(device);
                      }}
                      onRetire={(device) => {
                        showRetireDialog(true);
                        setCurrentDevice(device);
                      }}
                      forceDbSync={forceDbSync}
                  />
              ))}

            </TableBody>
          </Table>
        </CardDash>
      </ThemeProvider>
  );
}
