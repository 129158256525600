import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/styles";
import moment from 'moment';
import _ from 'lodash';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { syncProcoreTimesheets, getProcoreProjectDetails, getDashboardTimesheetStatus } from '../services';
import { notificationCenter } from '../../helpers/notifications';

const useStyles = makeStyles(theme => ({
  button: {
    padding: "3px 10px",
    marginRight: '10px',
    textTransform: 'capitalize'
  }
}))
export default function SyncTimeSheetsProcore(props) {

  const {
    integrations_endpoint,
    project,
    timesheet_date,
    onSetSyncTimesheetStatus,
    timesheetSyncStatus,
    hasTokenExpired,
    hasResponseError,
    integrationDisabled,
    onSyncDailyLog,
    syncDailyLogs
  } = props;


  const [syncDialog, setSyncDialog] = useState(false);
  const [dailyLogSyncing, setDailyLogSyncing] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [timesheetPermission, setTimesheetPermission] = useState(false);

  const projectDetails = async () => {
    try {
      const res = await getProcoreProjectDetails(integrations_endpoint, _.get(project, 'integrations.procore'));
      const timesheets = _.find(_.get(res, 'data.value'), item => _.get(item, 'engine_name') === "timesheets")

      setTimesheetPermission(!_.isUndefined(timesheets) ? _.get(timesheets, 'is_active') : false)
    } catch (error) {

    }
  }

  const checkStatus = async () => {
    try {
      let payload = {
        timesheet_date
      }
      const res = await getDashboardTimesheetStatus(integrations_endpoint, payload);
      if (_.get(res, 'data.status', 'SUCCEEDED') === "SUCCEEDED")
        onSetSyncTimesheetStatus(false);
    } catch (error) {

    }
  }

  useEffect(() => {
    checkStatus()
    // openWebSocketConnection()
    projectDetails();
  }, []);


  const classes = useStyles();

  const onSyncTimesheets = async () => {
    try {
      setSyncing(true);

      let payload = {
        project: {
          _id: _.get(project, '_id.$oid'),
          timezone: _.get(project, 'timezone')
        },
        timesheet_date
      };
      await syncProcoreTimesheets(integrations_endpoint, payload);
      setSyncing(false);
      setSyncDialog(false);

    } catch (error) {
      setSyncing(false);
      notificationCenter('error', _.get(error, 'response.data.error', "Error"))
    }
  }

  const onSyncDialog = () => {
    if (!timesheetPermission) {
      notificationCenter('error', "Procore Timesheet module was not detected. Contact us!")
    } else {
      onSetSyncTimesheetStatus(true);
      setSyncDialog(true)
    }

  }

  const onCloseSyncDialog = () => {
    onSetSyncTimesheetStatus(false);
    setSyncDialog(false)
  }

  return (
    <>
      <Button
        variant="contained"
        color="default"
        onClick={() => onSyncDialog()}
        style={{ fontSize: '12px', marginRight: '10px' }}
        disabled={timesheetSyncStatus || hasTokenExpired || hasResponseError || integrationDisabled}
        className={classes.button}
        startIcon={<img width="18px" src='https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/project-employees/procoreLogo.png' />}
      >
        Sync Timesheet
      </Button>

      <Button
        variant="contained"
        color="default"
        onClick={() => onSyncDailyLog()}
        style={{ fontSize: '12px', marginRight: '10px' }}
        disabled={syncDailyLogs || hasTokenExpired || hasResponseError || integrationDisabled}
        className={classes.button}
        startIcon={syncing ? <CircularProgress size={10} color="primary" /> : <img width="18px" src='https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/project-employees/procoreLogo.png' />}
      >
        Sync Daily Log
      </Button>

      {
        syncDialog &&
        <Dialog
          open={syncDialog}
          onClose={() => setSyncDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Pushing all the timecard records for ${_.get(project, 'name')} on the ${moment(timesheet_date).format('MM-DD-YYYY')}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onCloseSyncDialog()} color="primary">
              Cancel
            </Button>
            <Button onClick={onSyncTimesheets} color="primary" disabled={syncing || hasTokenExpired || hasResponseError} >
              {syncing ? <CircularProgress size={15} color="primary" /> : "Yes"}
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  )
};