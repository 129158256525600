import React, { useState, useEffect, useContext } from 'react';
import { SettingsContext } from '../../../../contexts/SettingsContext';
import { get } from 'lodash';
import Select from 'react-select';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};


export default function PositionFilter(props) {

  const { filter, onFilter, selectStyles } = props;
  const settingsContext = useContext(SettingsContext);

  const [state, setState] = useState({
    value: get(filter, 'value', [])
  });

  const onLocalChange = (value) => {
    settingsContext.onCleaningFilter(false)
    setState({ value });

    onFilter(get(filter, 'key'), value);

  };

  useEffect(() => {
    if (settingsContext.clearFilter) {
      setState({
        value: []
      })
    }

  }, [settingsContext.clearFilter])

  return (
    <div className='d-flex justify-content-center align-items-center'>
      <Select
        onChange={onLocalChange}
        isMulti
        options={get(filter, 'data')}
        placeholder='Select position...'
        // formatGroupLabel={formatGroupLabel(get(filter, 'data.options'))}
        value={state.value}
        styles={selectStyles}
      />
    </div>
  )

};