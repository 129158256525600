import React from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const CovidChart = ({ data, projectName }) => {

  const buildCategories = () => {
    let result = []
    for (let i = 30; i >= 0; i --) {
      result.push(`${i} days`)
    }

    return result
  }

  const plotData = {
    chart: {
        type: 'column'
    },
    title: {
      text: projectName
    },
    subtitle: {
      text: 'COVID-19 Questionnaire'
    },
    xAxis: {
      categories: buildCategories()
    },
    yAxis: {
      title: {
        text: 'Flagged Answers'
      }
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false
        }
      }
      // areaspline: {
      //   dataLabels: {
      //       enabled: false
      //   },
      //   marker: {
      //     enabled: false
      //   },
      //   enableMouseTracking: true
      // }
    },
    series: data
    // series: [{
    //   name: 'Tokyo',
    //   data: [7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6]
    // }, {
    //   name: 'London',
    //   data: [3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8]
    // }]
  }

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={plotData} />
    </div>
  );
}

export default CovidChart;
