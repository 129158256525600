import React from 'react';
import { TextField } from "@material-ui/core";
import { formatPhoneNumber } from "../helpers/formatters";
import { get } from 'lodash';

export default function PhoneField(props) {

  const handleChange = (ev) => {

    const pattern = /^[0-9]$/;
    const val = ev.target.value;

    if (!pattern.test(val)) {
      ev.target.value = ev.target.value.replace(/\D/g, '');
      props.onChange(ev)
    }
    else {
      ev.target.value = ev.target.value.replaceAll(/(\(|\)|\-)/g, "");
      props.onChange(ev);
    }


  }


  const format = (val) => {
    if (val && val.length == 10) return formatPhoneNumber(val)

    return val;
  }
  return (
    <TextField
      required={props.required}
      disabled={get(props, 'disabled', false)}
      InputProps={{
        readOnly: get(props, 'readOnly', false),
      }}
      type="tel"
      label={props.label}
      name={props.name}
      // disabled={props.disabled}
      className={props.className}
      onChange={handleChange}
      value={format(props.value)}
    />
  )
}