import React, { useState } from "react"
import CcDropDown from "./CcDropDown";


const IndividualForm = ({ costcode, number, onSave, onChange, onDelete, options, readOnly }) => {

  const [state, setState] = useState(0);

  if (costcode.shift_id.$oid) {
    costcode.shift_id = costcode.shift_id.$oid;
    costcode.company_id = costcode.company_id.$oid;
    costcode.costcode_id = costcode.costcode_id.$oid;
    console.log(costcode);
  }

  const handleCodeChange = e => {
    costcode.costcode_id = e.target.value
    costcode.auto = false
    onChange(costcode)
    setState(state + 1)
  }
  const handleHoursChange = e => {
    costcode.hours = e.target.value
    onChange(costcode)
    costcode.auto = false
    setState(state + 1)
  }
  const handleSave = e => {
    costcode.auto = false;
    onSave(costcode)
  }
  const handleDelete = e => {
    onDelete(costcode)
  }

  const validationClass = () => {
    if (costcode.hours == 0)
      return "field_with_errors"

    return ""
  }

  const numberInput = (
    <input min="0" type="number" value={costcode.hours} className={"form-control costcode-number " + validationClass()}
      onChange={handleHoursChange} disabled={readOnly} />
  )

  const minusButton = (
    <button className="empty-button" onClick={handleDelete}>
      <i className="fa fa-minus"></i>
    </button>
  )
  const numberInterp = number ? numberInput : ""
  const minusInterp = number && !readOnly ? minusButton : ""
  return (
    <span className="sb-inline-form"
          style={{
              border: costcode.auto ? `2px solid #43A611`: `2px solid transparent`,
              borderRadius: costcode.auto ? `5px` : 0,
              padding: '3px',
              backgroundColor: costcode.auto ? '#F2FDED' : 'rgba(0,0,0,0)'
          }}
    >
      <select value={costcode.costcode_id} className={`form-control ${number ? 'costcode-select-small' : 'costcode-select'}`} style={{ marginRight: '2px'}} disabled={readOnly}
        onChange={handleCodeChange}>
        <option value={undefined}></option>
        <CcDropDown options={options} />
      </select>

      {numberInterp}
      {minusInterp}
      {/* <button className="empty-button" onClick={handleSave}>
        <i className="fa fa-check"></i>
      </button> */}
    </span>
  )
}

export default IndividualForm
