import React, { useState } from 'react';
import { TableRow, TableCell, Avatar, Link, Badge, Dialog, DialogContent, DialogContentText, DialogActions, Button, IconButton, Tooltip, FormControlLabel } from '@material-ui/core';
import { isNull, get, has, set, upperCase, isEmpty, trim } from 'lodash';
import { Edit, Error } from '@material-ui/icons';
import { EditWorker, PunchDetails, HasAccess } from '../index';
import { withStyles, makeStyles } from '@material-ui/styles';
import { statusColors } from '../../../constants/workerStatusColor';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { gettingPunchDetails, deleteWorkerImageProfile, deleteWorkerRfid } from '../../../services/employeeService';
import { SBSwitch } from '../../../../reusableComponents';
import workerProfileIcon from '../../../../../../assets/images/worker_types/constructionWorkerIcon-2023.svg';
import clsx from 'clsx';

const RenderingImageProfile = (props) => {

  const { worker, images_path, classes, projectDomain, employee_settings_endpoint } = props;
  const [punchData, setPunchData] = useState(null);
  const [clearImage, setClearImage] = useState(false)
  const [showClearImage, setShowClearImage] = useState(false)

  const avatarStyles = makeStyles((theme) => ({
    root: {
      border: `3px solid ${get(statusColors, get(worker, 'status.activity', 0))}`,
      width: 45,
      height: 45
    }
  }));


  const SmallAvatar = withStyles((theme) => ({
    root: {
      width: 20,
      height: 20,
      border: `1px solid ${theme.palette.background.paper}`,
      backgroundColor: "white"
    }
  }))(Avatar);

  const getPunchData = async () => {
    const res = await gettingPunchDetails(projectDomain, get(worker, '_id'));
    setPunchData(get(res, 'data'));
  }

  const avatarClasses = avatarStyles();

  // Do not add domain if already a complete url
  let profileImage = workerProfileIcon;
  let workerImage = trim(get(worker, 'image_path', ''));
  if(workerImage != "") {
      const urlPattern = /^(http:\/\/|https:\/\/)/i;
      if(urlPattern.test(workerImage)) {
          profileImage = workerImage;
      }else{
          profileImage = `${images_path}${workerImage}`;
      }
  }

  const onClearImageProfile = async (status) => {

    try {
      setClearImage(false);

      if (status) {
        set(worker, 'image_path', null);
        const res = await deleteWorkerImageProfile(employee_settings_endpoint, get(worker, 'dbid'))
      }

    } catch (error) {

    }


  }

  return (
    <>
      <TableCell style={{ position: 'relative', paddingTop: 5, paddingBottom: 5 }} classes={{ root: classes.root }} align="left">
        <OverlayTrigger
          trigger={"click"}
          rootClose
          onEnter={getPunchData}
          key={get(worker, '_id')}
          placement='right'
          overlay={
            !isNull(punchData) && !clearImage ?
              <Popover className='callout' id={`popover-${get(worker, '_id')}`}>
                <Popover.Content>
                  <PunchDetails punchData={punchData} worker={worker} projectDomain={projectDomain} />
                </Popover.Content>
              </Popover> : <span></span>
          }
        >
          <div style={{ position: 'relative', width: 45 }} onMouseEnter={() => setShowClearImage(true)} onMouseLeave={() => setShowClearImage(false)}>
            {isNull(get(worker, 'user_type')) ?

              <Avatar
                classes={{ root: avatarClasses.root }} alt="WorkerProfile" src={profileImage} />
              :
              <Badge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={<SmallAvatar alt={`${upperCase(get(worker, 'user_type', 'worker'))}`} src={`https://static-files.connectedbarrel.com/icons/project_workers/${get(worker, 'user_type', 'worker')}.svg`} />}
              >
                <Avatar classes={{ root: avatarClasses.root }} src={profileImage} />
              </Badge>
            }
            {has(worker, 'image_path') && !isNull(get(worker, 'image_path')) && showClearImage ? <img onClick={() => setClearImage(!clearImage)} src="https://s3.us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/project_workers/closedIcon.svg" style={{ position: 'absolute', left: '67%', top: '3%', width: '20px', color: '#f16734', cursor: 'pointer' }} /> : null}
          </div>
        </OverlayTrigger>
      </TableCell >
      <Dialog
        open={clearImage}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => onClearImageProfile(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle id="alert-dialog-slide-title">{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Are you sure you want to delete the image profile for ${get(worker, 'first_name')} ${get(worker, 'last_name')}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClearImageProfile(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onClearImageProfile(true)} color="primary">
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const RenderingRfidTag = (props) => {

  const { worker, classes, employee_settings_endpoint } = props;
  const [clearRfid, setClearRfid] = useState(false)
  const [workerRfid, setWorkerRfid] = useState(get(worker, 'rfid', null))

  const onClearRfid = async (status) => {

    try {
      setClearRfid(false);

      if (status) {

        const res = await deleteWorkerRfid(employee_settings_endpoint, get(worker, 'dbid'))

        if (get(res, 'data.success')) setWorkerRfid(null)
      }
    } catch (error) {
      notificationCenter('error', get(error, 'response.data.error', 'Error clearing RFID tag.'));
    }
  }

  return (
    <>
      <TableCell classes={{ root: classes.root }} align="left">
        { workerRfid &&
          <div style={{ position: 'relative' }}>
            <img src='https://static-files.connectedbarrel.com/icons/project_workers/rfid.svg' width='25' />
            <img className={classes.clearRfidIcon} onClick={() => setClearRfid(!clearRfid)} src="https://s3.us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/project_workers/closedIcon.svg" style={{ position: 'absolute', left: 30, top: -10, width: '20px', color: '#f16734', cursor: 'pointer' }} />
          </div>
        }
      </TableCell >
      <Dialog
        open={clearRfid}
        keepMounted
        onClose={() => onClearRfid(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Are you sure you want to remove the RFID tag for ${get(worker, 'first_name')} ${get(worker, 'last_name')}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClearRfid(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onClearRfid(true)} color="primary">
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const tableRowStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      '& $clearRfidIcon': {
        opacity: 1
      },
    },
    "& .MuiButtonBase-root": {
      padding: 4,
    },
  },
  clearRfidIcon: {
    opacity: 0
  },
  inactiveRow: {
    backgroundColor: '#e0e0e0'
  },
  inActiveCell: {
    color: '#868e96'
  }
}));

export default function RenderingTableRow(props) {
  const { workers, columns, images_path, project, projectDomain, onWorkerEdited, onWorkerToOnBoard, onChangeWorkerAccess, employee_settings_endpoint, permissions } = props;

  const rowClasses = tableRowStyles();

  const [openEditWorker, setOpenEditWorker] = useState(false);
  const [currentWorker, setCurrentWorker] = useState(null);
  const [showPunchDetails, setShowPunchDetails] = useState(false);
  const [changeAccess, setChangeAccess] = useState(false);
  const [workerAccess, setWorkerAccess] = useState(true);

  const onCloseEditWorker = (wasEdited = false) => {
    setOpenEditWorker(!openEditWorker);
    if (wasEdited) onWorkerEdited();
  }

  const onOpenEditWorker = worker => {
    setCurrentWorker(worker);
    setOpenEditWorker(true);
  }

  const isRowActive = (key) => {
    return columns.indexOf(key) > -1
  }

  const onShowPunchDetails = (ev, worker) => {
    setShowPunchDetails(true)
    console.log(ev.clientY)
  }

  const onChangeAccess = (e, worker) => {
    setCurrentWorker(worker);
    setWorkerAccess(e.target.checked)
    setChangeAccess(true);
  }

  const onUpdateWorker = async () => {
    try {
      console.log(currentWorker);
      // const res = await updatingWorker(currentWorker, projectDomain);
    } catch (error) {
      notificationCenter('error', get(error, 'response.data.error', 'Error'));
    }
  }

  const onHandleAccess = flag => {

    setChangeAccess(false)
    if (flag) {

      let aux = {
        _id: get(currentWorker, '_id'),
        hasAccess: workerAccess
      }
      onChangeWorkerAccess(aux)
    }
  }

  const hasPermission = (type) => get(permissions, type, false)

  return (
    <>
      {workers.map(row => {
        return (
          <TableRow key={get(row, '_id')} className={clsx({ [rowClasses.inactiveRow]: !row.hasAccess })}>
            {isRowActive("image_path") ? <RenderingImageProfile employee_settings_endpoint={employee_settings_endpoint} onShowPunchDetails={onShowPunchDetails} classes={rowClasses} worker={row} images_path={images_path} projectDomain={projectDomain} /> : null}
            {isRowActive("worker_name") ? <TableCell classes={{ root: clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.hasAccess })  }} align="left">{`${get(row, "first_name", '')} ${get(row, "last_name", '')}`}</TableCell> : null}
            {isRowActive("phone_number") ?
              <TableCell classes={{ root: rowClasses.root }} align="left">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <a href={`tel:+${get(row, "phone_number", "")}`}>{get(row, "phone_number", "")}</a>
                  { get(row, 'synthetic_phone', false) && <Tooltip title={<div>Auto-generated phone number <br/> SMS services unavailable</div>} arrow><Error style={{ color: '#F6A200', fontSize: 16, backgroundColor: 'white', borderRadius: '100%', marginLeft: 2 }}/></Tooltip> }
                </div>
              </TableCell> : null}
            {isRowActive("decal_number") ? <TableCell classes={{ root: clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.hasAccess })  }} align="left">{get(row, "onboarding.decal", "")}</TableCell> : null}
            {isRowActive("company") ? <TableCell classes={{ root: clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.hasAccess })  }} align="left">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {get(row, "company.name", "")}<i style={{ color: '#908f8f' }}>{get(row, "company.ein", "")}</i>
              </div>
            </TableCell> : null}
            {isRowActive("rate") ? <TableCell classes={{ root: clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.hasAccess })  }} align="left">{get(row, "settings.hire_rate", "")}</TableCell> : null}
            {isRowActive("per_diem") ? <TableCell classes={{ root: clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.hasAccess })  }} align="left">{get(row, "settings.per_diem_rate.value", "")}</TableCell> : null}

            {isRowActive("has_access") ? <TableCell style={{ paddingTop: 0, paddingBottom: 0 }} align="left">

            <FormControlLabel style={{ marginLeft: -1 }} control={<SBSwitch height={24} width={40} size={14} color="primary" handleToggle={(e) => onChangeAccess(e, row)} checked={get(row, 'hasAccess', true)} />} />

            </TableCell> : null}
            {isRowActive("last_seen") ? <TableCell classes={{ root: clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.hasAccess })  }} align="left">{get(row, "last_seen", "")}</TableCell> : null}
            {isRowActive("idCard") ? <TableCell classes={{ root: clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.hasAccess })  }} align="left">{has(row, 'idCard') ?
              <Link color="inherit" href={get(row, 'idCard')} target="_blank" >
                <img style={{ marginLeft: '9px' }} width='20' src='https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/project-employees/idCard.svg' />
              </Link>
              : null}
            </TableCell> : null}
            {isRowActive("rfid") ? <RenderingRfidTag employee_settings_endpoint={employee_settings_endpoint} classes={rowClasses} worker={row} /> : null}

            {isRowActive("onboarded") ? <TableCell classes={{ root: clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.hasAccess })  }} align="left">{get(row, 'onboarded', false) ?

              <IconButton component={React.forwardRef(
                ({children, ...props}, ref) => (
                  <span {...props} ref={ref}><Link color="inherit" href={`/pr/od/${get(row, 'onboarding.dbid', Math.random())}`} target="_blank">{children}</Link></span>
                )
              )}>
                <img width='20' src='https://static-files.connectedbarrel.com/icons/project_workers/safety_form_icon.png' />
              </IconButton>
              : ''}
            </TableCell> : null}
            {isRowActive("documents") ? <TableCell classes={{ root: clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.hasAccess })  }} align="left">{get(row, "documents", "")}</TableCell> : null}
            {isRowActive("actions") ? <TableCell classes={{ root: clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.hasAccess })  }} align="left">
              <IconButton onClick={() => onOpenEditWorker(row)} disabled={!hasPermission('canEdit')} small style={{ color: '#F45C25' }}>
                <Tooltip arrow title='Edit'>
                  <Edit style={{ color: hasPermission('canEdit') ? '#F45C25' : '#707070' }} />
                </Tooltip>
              </IconButton>
              <IconButton onClick={() => {  onWorkerToOnBoard(row) }} disabled={!hasPermission('canOnboard')} small>
                <Tooltip arrow title='Onboard'>
                  <img style={{ filter: hasPermission('canOnboard') ? 'grayscale(0)' : 'grayscale(1)' }} width='20px' src='https://static-files.connectedbarrel.com/icons/project_workers/safety_form_add.svg' />
                </Tooltip>
              </IconButton>

            </TableCell> : null}

          </TableRow>
        );
      })}

      {openEditWorker && <EditWorker projectDomain={projectDomain} openEditWorker={openEditWorker} onCloseEditWorker={onCloseEditWorker} images_path={images_path} worker={currentWorker} employee_settings_endpoint={employee_settings_endpoint} />}

      {changeAccess && <HasAccess onHandleAccess={onHandleAccess} hasAccess={changeAccess} worker={currentWorker} />}
    </>
  )

};
