import React from 'react';
import { ListItem, List, ListItemSecondaryAction, makeStyles, ListItemText } from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    maxHeight: 280,
    border: "1px solid #9c9c9c2e",
    overflow: 'scroll',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function HolidaysPickerList(props) {

  const { days, handleDayClick } = props;
  const classes = useStyles();

  // console.log(days)
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <List dense className={classes.root}>

        {days.map((value, idx) => (
          <ListItem key={idx} button>
            <ListItemText primary={value.toLocaleDateString()} />
            <ListItemSecondaryAction>
              <RemoveCircleIcon onClick={() => handleDayClick(value, { selected: true })} style={{ color: "#d0021c", cursor: 'pointer' }} />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  )
};