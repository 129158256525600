import React from 'react';
import { CustomTab } from '../SectionTab';

const WorkSummaryTab = ({ items, loading }) => (<CustomTab
    id="work_summary"
    title="Work Summary"
    items={items}
    loading={loading}
    fillColor="#006e90"
    fillBackgroundColor="#98bccc"
/>);

export default WorkSummaryTab;
