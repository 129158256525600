
import React, { useState, useRef, useEffect, useContext } from 'react'
import { addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range'
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Button, InputLabel, TextField, FormControl } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import moment from 'moment';
import { get } from 'lodash';
import { SettingsContext } from '../../../../contexts/SettingsContext';

import "react-date-range/src/styles.scss"; // main css file
import "react-date-range/src/theme/default.scss"; // theme css file


export default function DateRangePickerFilter(props) {
  let { filter, onFilter } = props;

  const settingsContext = useContext(SettingsContext);

  const useStyles = makeStyles((theme) => ({
    rangePickerContainer: {
      position: "absolute",
      zIndex: 2,
      top: "57px",
      backgroundColor: "#e3e3e3",
      padding: "5px",
      left: 0,
      display: 'flex',
      flexDirection: 'column'
    },
    textFieldStyle: {
      width: `${settingsContext.isMobile ? '300px !important' : '200px'}`
    }
  }));

  const classes = useStyles();
  const [showPicker, setShowPicker] = useState(false);
  const wrapperRef = useRef(null);
  const [textRangeValue, setTextRangeValue] = useState('Select Date Range');
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  useEffect(() => {
    if (settingsContext.clearFilter) {
      setState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      ])
      setTextRangeValue('Select Date Range')
    }

  }, [settingsContext.clearFilter])

  const onPickerChange = (elem) => {
    setState([elem.selection])
    setTextRangeValue(`${moment(elem.selection.startDate).format('MM/DD/YYYY')} - ${moment(elem.selection.endDate).format('MM/DD/YYYY')}`);
    onFilter(get(filter, 'key', 'rangeDatePicker'), {
      start: elem.selection.startDate,
      end: elem.selection.endDate
    })
  }

  return (
    <FormControl style={{ width: '200px' }}>
      <TextField
        id="picker-filter"
        classes={{ root: classes.textFieldStyle }}
        value={textRangeValue}
        InputProps={{
          readOnly: true,
          startAdornment: <CalendarToday onClick={() => setShowPicker(!showPicker)} style={{ marginRight: '5px', paddingBottom: '2px', cursor: 'pointer' }} />
        }}

      />
      {showPicker &&
        <div className={classes.rangePickerContainer} >
          <DateRangePicker
            ref={wrapperRef}
            onChange={item => onPickerChange(item)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            maxDate={new Date()}
            direction="horizontal"
          />
          <Button onClick={() => setShowPicker(!showPicker)} variant="contained" color="primary">
            Apply
          </Button>
        </div>
      }
    </FormControl>
  )
};