import React, { useState, useEffect } from 'react'
import Countries from 'i18n-iso-countries';
import { get, toPairs, isEqual, isEmpty, has } from 'lodash';
import { from, of } from 'rxjs';
import { map, toArray, switchMap, catchError } from 'rxjs/operators';
import { fromFetch } from 'rxjs/fetch';
import {
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Tooltip,
  InputLabel,
  Box,
  ThemeProvider,
  FormControl,
  Button,
  IconButton
} from "@material-ui/core";

import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Edit } from '@material-ui/icons';
import Close from '@material-ui/icons/Close';
import MobileFriendly from '@material-ui/icons/MobileFriendly';
import MobileOff from '@material-ui/icons/MobileOff';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { myTheme } from './helpers';

import Axios from "axios";
import { axiosConfig } from "./helpers"

Countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default function CreateEditProject({ isSuperAdmin, projectEndpoint, googleTimezoneEndpoint, googleTimezoneApiKey, availableStates, availableStatesCanada, googleMapsApiKey, editMode, projectToEdit, reloadList }) {
  // this callback function will be called once google script is loaded
  // from the {initAll} function that injects maps api into page script
  // window.initMapAfterScript = function () {
  //   setShow(true);
  // }

  // const initAll = () => {
  //     const script = document.createElement('script');
  //     const style = document.createElement('style');
  //
  //     script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places&callback=initMapAfterScript`;
  //     //add this -> &callback=initMapAfterScript to fire callback function once script is loaded
  //     script.async = true;
  //
  //     style.textContent = '.pac-container { z-index: 9999 }'
  //
  //     document.head.appendChild(script);
  //     document.head.append(style);
  // }
  const classes = useStyles();

  const countriesObjectToPairs = toPairs(Countries.getNames("en", { select: "official" }));
  const [show, setShow] = useState(false)
  const [countriesArray, setCountriesArray] = useState(null)
  const [statesArray, setStatesArray] = useState(null)
  const [canadaStatesArray, setCanadaStatesArray] = useState(null)
  const [showDeactivateDialog, setShowDeactivateDialog] = useState(false)
  const [disableCreateEditButton, setDisable] = useState(true)
  const [availableDevices, setAvailableDevices] = useState([]);

  const [address, setAddress] = useState({
    street_number: '',
    route: '',
    locality: '',
    administrative_area_level_1: '',
    country: null,
    postal_code: '',
    formatted_address: '',
    addressLineTwo: '',
    latLng: {},
    streetAddress: '',
  })

  const [timezone, setTimezone] = useState({
    iana: '',
    offset: 0
  })

  const [projectName, setProjectName] = useState('')
  const handleProjectNameChange = (ev) => {
    setProjectName(ev.target.value)
  }

  const [projectDevices, setProjectDevices] = useState([])
  const handleDevicesSelection = (event, value) => {
    setProjectDevices(value)
  }

  const [projectHealthAssessment, setProjectHealthAssessment] = useState(false)
  const handleToggleHealth = (ev) => {
    setProjectHealthAssessment(ev.target.checked)
  }

  const [projectClaimsEnabled, setProjectClaimsEnabled] = useState(false)
  const handleToggleClaimsEnabled = (ev) => {
    setProjectClaimsEnabled(ev.target.checked)
  }

  const [projectActive, setProjectActive] = useState(true);

  const handleToggleProjectActive = (ev) => {
    setProjectActive(ev.target.checked)
  }

  const handleClose = (reload = false) => {
    clearFields()
    setProjectName("")
    setProjectDevices([])
    setProjectHealthAssessment(false)
    setProjectClaimsEnabled(false)
    setProjectActive(true)
    setShow(false)

  }

  const handleDialogConfirmation = (confirmed) => {
    setShowDeactivateDialog(false)

    let url = `${projectEndpoint}/update/${projectToEdit._id}`;

    Axios.patch(url, { ...getPayload(), active: !confirmed }, axiosConfig()).then(response => {
      handleClose(true);
      reloadList();
      toastr.success("Project updated successfully.")
    }).catch(error => {
      toastr.error(get(error, 'response.data.error', 'Error updating project.'))
    })
  }

  const handleCreate = () => {
    let url = `${projectEndpoint}/create`;

    setDisable(true);

    Axios.post(url, getPayload(), axiosConfig()).then(response => {
      handleClose(true);
      reloadList();
      toastr.success("Project created successfully.")
    }).catch(error => {
      setDisable(false);
      toastr.error(get(error, 'response.data.error', 'Error creating project.'));
    })
  }

  const handleUpdate = () => {
    if (isEmpty(projectDevices) && projectToEdit.active && !isEmpty(projectToEdit.devices)) {
      setShowDeactivateDialog(true)
    } else {
      let url = `${projectEndpoint}/update/${projectToEdit._id}`;

      setDisable(true);

      Axios.patch(url, getPayload(), axiosConfig()).then(response => {
        handleClose(true);
        reloadList();
        toastr.success("Project updated successfully.")
      }).catch(error => {

        setDisable(false);
        toastr.error(get(error, 'response.data.error', 'Error updating project.'))
      })
    }
  }

  const getPayload = () => {
    let payload = {
      name: projectName,
      address: {
        fullAddress: address.formatted_address,
        address: address.streetAddress,
        city: address.locality,
        state: ['US', 'CA'].indexOf(get(address, 'country.value', '')) !== -1 ? projectState.value : address.administrative_area_level_1,
        zip: address.postal_code,
        addressLineTwo: address.addressLineTwo,
        country: address.country.value
      },
      timezone: timezone.iana,
      latLng: address.latLng,
      claimsEnabled: projectClaimsEnabled,
      // devices: projectDevices.map( device => device._id).concat( projectToEdit.devices.filter( device => device.virtual).map( device => device._id)),
      devices: projectDevices.map(device => device._id),
      healthAssessmentNeeded: projectHealthAssessment,
      active: projectActive
    }

    if (editMode && projectToEdit) {
      payload.devices = {
        originalArray: projectToEdit.devices.map(oneDevice => oneDevice._id),
        updatedArray: projectDevices.concat(projectToEdit.devices.filter(oneDevice => oneDevice.virtual)).map(oneDevice => oneDevice._id)
      }
    }

    return payload
  }

  const handleOpen = () => {
    // initAll();

    // inject styling for google autocomplete field into document
    const style = document.createElement('style');
    style.textContent = '.pac-container { z-index: 9999 }'
    document.head.append(style);
    getAvailableDevices();
    // check if editing and fill in data
    if (projectToEdit) {
      fillInEditProjectData();
    }

    setShow(true);
  }

  const getAvailableDevices = () => {

    let apiURL = `${projectEndpoint}/listAvailableDevices`;
    Axios.get(apiURL, axiosConfig()).then(response => {
      setAvailableDevices(response.data)
      // setLoading(false)
    }).catch(error => {
      //setLoading(false)
      toastr.error(get(error, 'response.data.error', 'Error listing available devices.'));
    })
  }

  const handleChange = (ev) => {
    address[ev.target.name] = ev.target.value
    setAddress({ ...address })
  }

  const handleNewAddress = (newAddress) => {
    setAddress({ ...address, ...newAddress })
  }

  const handleCountrySelection = (event, value) => {
    address['country'] = value
    setAddress({ ...address })
  }

  const [projectState, setProjectState] = useState(null)
  const handleStateSelection = (event, value) => {
    setProjectState(value)
  }

  const clearFields = () => {
    setAddress({
      street_number: '',
      route: '',
      locality: '',
      administrative_area_level_1: '',
      country: null,
      postal_code: '',
      formatted_address: '',
      addressLineTwo: '',
      latLng: {},
      streetAddress: ''
    })

    setProjectState(null)

    setTimezone({
      iana: '',
      offset: 0
    })
  }

  const getTimezone = window.getTimezone = (location) => {
    let timestampInSeconds = Math.floor(Date.now() / 1000);
    let timezoneURL = `${googleTimezoneEndpoint}?location=${location.lat},${location.lng}&timestamp=${timestampInSeconds}&key=${googleTimezoneApiKey}`

    fromFetch(`${timezoneURL}`)
      .pipe(
        switchMap(response => {
          if (response.ok) {
            return response.json();
          } else {
            toastr.error("No timezone detected. Please choose another address.")
            return of({ error: true, message: `Error ${response.status}` });
          }
        }),
        catchError(err => {
          toastr.error("No timezone detected. Please choose another address.")
          return of({ error: true, message: err.message });
        })
      )
      .subscribe(result => {
        if (!result.timeZoneId) {
          toastr.error("No timezone detected. Please choose another address.")
        }

        setTimezone({
          iana: result.timeZoneId || '',
          offset: result.rawOffset / 60 / 60 || 0
        })
      });
  }

  const getFormattedTimezone = () => {
    if (timezone.iana !== '') return `${timezone.iana} (UTC${timezone.offset >= 0 ? '+' + timezone.offset : timezone.offset})`
    return ''
  }

  const checkDisabled = () => {
    const isUSorCA = ['US', 'CA'].includes(get(address, 'country.value', ''));
    const isStateSelected = isUSorCA && has(projectState, 'value');

    const isProjectNameValid = !isEqual(projectName, '');
    const isTimezoneValid = !isEqual(timezone.iana, '');
    const isStreetAddressValid = !isEqual(address.streetAddress, '');
    const isPostalCodeValid = !isEqual(address.postal_code, '');
    const isCountryValid = has(address.country, 'value');

    const isAddressValid = validateState(isUSorCA, isStateSelected);

    setDisable(!isProjectNameValid || !isTimezoneValid || !isStreetAddressValid || !isPostalCodeValid || !isCountryValid || !isAddressValid);
  }

  const validateState = (isUSorCA, isStateSelected) => {
    if (isUSorCA) {
      return isStateSelected;
    } else {
      return !isEqual(address.administrative_area_level_1, '') && !isEmpty(address.administrative_area_level_1);
    }
  };

  const fillInEditProjectData = () => {

    let projectToEditLocation = {
      lat: projectToEdit.location ? projectToEdit.location.coordinates[0] : projectToEdit.latitude ? projectToEdit.latitude : 0,
      lng: projectToEdit.location ? projectToEdit.location.coordinates[1] : projectToEdit.longitude ? projectToEdit.longitude : 0
    }

    let projectToEditCountry
    if (!projectToEdit.country || ['USA'].includes(get(projectToEdit, 'country'))) {
        projectToEditCountry = { label: "United States of America", value: "US" }
    }
    countriesArray.forEach((oneCountry, i) => {
        // assuming 2 digit country
        if (projectToEdit.country === oneCountry.value) {
            projectToEditCountry = oneCountry
        }
    });

    let projectToEditState
    if (projectToEditCountry && ['US', 'USA'].includes(get(projectToEditCountry, 'value'))) {
        statesArray.forEach((oneState) => {
            if (projectToEdit.state === oneState.value) {
                projectToEditState = oneState
            }
        });
        setProjectState(projectToEditState)
    } else if (projectToEditCountry && ['CA'].includes(get(projectToEditCountry, 'value'))) {
        canadaStatesArray.forEach((oneState) => {
            if (projectToEdit.state === oneState.value) {
                projectToEditState = oneState
            }
        });
        setProjectState(projectToEditState)
    } else {
        projectToEditState = projectToEdit.state
    }

    setProjectName(projectToEdit.name)
    setProjectDevices(get(projectToEdit, 'devices', []).filter(oneDevice => !oneDevice.virtual))
    setAddress({
      streetAddress: projectToEdit.address || '',
      locality: projectToEdit.city || '',
      administrative_area_level_1: !isEmpty(get(projectToEditState, 'value', '')) ? projectToEditState.value : projectToEditState,
      country: projectToEditCountry,
      postal_code: projectToEdit.zip || '',
      formatted_address: projectToEdit.fullAddress || `${projectToEdit.address || ''} ${projectToEdit.city || ''} ${projectToEdit.state || ''} ${projectToEdit.zip || ''} ${projectToEdit.country || ''}`,
      addressLineTwo: projectToEdit.addressLineTwo || '',
      latLng: projectToEditLocation,
    })
    getTimezone(projectToEditLocation)
    setProjectHealthAssessment(projectToEdit.healthAssessmentNeeded)
    setProjectClaimsEnabled(get(projectToEdit, 'claimsEnabled', false))
    setProjectActive(projectToEdit.active)
  }

  const countryToFlag = (isoCode) => {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  }

  useEffect(() => {
    checkDisabled();

    return () => { };
  }, [projectName, address, projectHealthAssessment, projectDevices, timezone, projectState])

  useEffect(() => {
    from(countriesObjectToPairs)
      .pipe(
        map(keyValue => {
          return {
            label: keyValue[1],
            value: keyValue[0]
          }
        }),
        toArray()
      )
      .subscribe(result => setCountriesArray(result));

    from(availableStates)
      .pipe(
        map(keyValue => {
          return {
            label: keyValue[0],
            value: keyValue[1]
          }
        }),
        toArray()
      )
      .subscribe(result => setStatesArray(result));

    from(availableStatesCanada)
      .pipe(
        map(keyValue => {
          return {
            label: keyValue[0],
            value: keyValue[1]
          }
        }),
        toArray()
      )
      .subscribe(result => setCanadaStatesArray(result));


    return () => { };
  }, [])

  return (
    <>
      {editMode ?
        <Tooltip title="Edit" arrow>
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={handleOpen}
            size="small"
          >
            <Edit />
          </IconButton>
        </Tooltip>
        : <Button color="primary" onClick={handleOpen} size="small">
            <i className="fa fa-plus-circle" style={{ marginRight: 6 }} aria-hidden="true"></i> Create Project
          </Button>
      }
      <ThemeProvider theme={myTheme}>
        <Dialog open={show} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle disableTypography={true} className={classes.dialogTitle} >
            <Box component="span">{editMode ? 'Edit Project' : 'New Project'}</Box>
            <Close style={{ cursor: 'pointer' }} size='20' color='primary' onClick={handleClose} />
          </DialogTitle>
          <DialogContent>
            <form className={classes.root}>
              <Grid container spacing={3}>
                <Grid item container xs={12} sm={5} direction="column" justifyContent="space-between" alignItems="stretch">
                  <Grid item>
                    <FormControl fullWidth>
                      <TextField
                        id="projectName"
                        name="projectName"
                        required
                        label="Name"
                        value={projectName}
                        color="primary"
                        onChange={handleProjectNameChange}
                        disabled={!isSuperAdmin}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <Autocomplete
                        multiple
                        id="projectDevices"
                        options={availableDevices}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option._id === value._id}
                        value={projectDevices}
                        onChange={handleDevicesSelection}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Devices"
                          />
                        )}
                        disabled={!isSuperAdmin}
                      />
                    </FormControl>
                    {
                      editMode &&
                      <Box mt={1} mb={2}>
                        {
                          !isEmpty(projectToEdit.devices.filter(device => device.virtual)) ?
                            <Box display="flex" flexDirection="row">
                              <Tooltip title="Virtual device" arrow>
                                <MobileFriendly color="primary" style={{ marginRight: '4px' }} />
                              </Tooltip>{projectToEdit.devices.filter(device => device.virtual).map(device => device.name)}
                            </Box>
                            : <Tooltip title="No virtual device" arrow><MobileOff color="default" /></Tooltip>
                        }
                      </Box>
                    }
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={<LovelySwitch color="primary" checked={projectHealthAssessment} onChange={handleToggleHealth} name="projectHealthAssessment" disabled={!isSuperAdmin} />}
                        label="Health Assesment Needed"
                        labelPlacement="end"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={<LovelySwitch color="primary" checked={projectClaimsEnabled} onChange={handleToggleClaimsEnabled} name="projectClaimsEnabled" disabled={!isSuperAdmin} />}
                        label="Claims Collection Enabled"
                        labelPlacement="end"
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <FormControlLabel
                        control={<LovelySwitch color="primary" checked={projectActive} disabled={!isSuperAdmin} onChange={handleToggleProjectActive} name="projectActive" />}
                        label="Active"
                        labelPlacement="end"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <FormControl fullWidth style={{ height: '250px' }}>
                    <InputLabel>Address<em> (click on the map to pin project location)</em></InputLabel>
                    <GoogleMap onNewAddress={handleNewAddress} clearFields={clearFields} projectToEdit={projectToEdit} setProjectState={setProjectState} isSuperAdmin={isSuperAdmin} />
                  </FormControl>
                  <FormControl fullWidth style={{ marginTop: '8px' }}>
                    <GoogleAutocomplete isSuperAdmin={isSuperAdmin} onNewAddress={handleNewAddress} clearFields={clearFields} projectToEdit={projectToEdit} setProjectState={setProjectState} formattedAddress={get(address, 'formatted_address')} />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      id="streetAddress"
                      name="streetAddress"
                      label="Street Address"
                      value={get(address, 'streetAddress', '')}
                      disabled={true}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      id="addressLineTwo"
                      name="addressLineTwo"
                      label="Address Line Two"
                      value={address.addressLineTwo}
                      color="primary"
                      onChange={handleChange}
                      disabled={!isSuperAdmin}
                    />
                  </FormControl>
                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <FormControl>
                      <TextField
                        id="locality"
                        name="locality"
                        label="City"
                        value={address.locality}
                        color="primary"
                        onChange={handleChange}
                        fullWidth
                        disabled={!isSuperAdmin}
                      />
                    </FormControl>
                    <FormControl style={{ width: '174px' }}>
                      {
                        ['US', 'CA'].indexOf(get(address.country, 'value', '')) !== -1 ?
                          <Autocomplete
                            id="projectState"
                            fullWidth
                            options={get(address.country, 'value', '') === 'US' ? statesArray : canadaStatesArray}
                            value={projectState}
                            onChange={handleStateSelection}
                            getOptionLabel={(option) => option.label}
                            getOptionSelected={(option, value) => option.value === value.value}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="State"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                              />
                            )}
                            disabled={!isSuperAdmin}
                          />
                          : <TextField
                            id="administrative_area_level_1"
                            name="administrative_area_level_1"
                            label="State/Province"
                            value={address.administrative_area_level_1}
                            color="primary"
                            onChange={handleChange}
                            fullWidth
                            disabled={!isSuperAdmin}
                          />
                      }
                    </FormControl>
                    <FormControl>
                      <TextField
                        id="postal_code"
                        name="postal_code"
                        label="Zip Code/Postal Code"
                        value={address.postal_code}
                        color="primary"
                        onChange={handleChange}
                        fullWidth
                        disabled={!isSuperAdmin}
                      />
                    </FormControl>
                  </Box>
                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Box width="58.5%">
                      <FormControl fullWidth>
                        <Autocomplete
                          id="projectCountry"
                          fullWidth
                          options={countriesArray}
                          value={address.country}
                          onChange={handleCountrySelection}
                          classes={{
                            option: classes.option,
                          }}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          getOptionSelected={(option, value) => option.value === value.value}
                          renderOption={(option) => (
                            <React.Fragment>
                              <span>{countryToFlag(option.value)}</span>
                              {option.label} ({option.value})
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Country"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                              }}
                            />
                          )}
                          disabled={!isSuperAdmin}
                        />
                      </FormControl>
                    </Box>
                    <Box width="38%">
                      <FormControl fullWidth>
                        <TextField
                          id="timezone"
                          name="timezone"
                          label="Timezone"
                          value={getFormattedTimezone()}
                          color="primary"
                          disabled
                          fullWidth
                          required
                        />
                      </FormControl>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

            </form>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={editMode ? handleUpdate : handleCreate}
              disabled={disableCreateEditButton || !isSuperAdmin}
            >
              {editMode ? "Update Project" : "Create New Project"}
            </Button>
          </DialogActions>
          <Dialog open={showDeactivateDialog} aria-labelledby="form-dialog-title">
            <DialogTitle>Update Project</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You have not selected any devices for this project. <br /> Would you like to deactivate the project as well?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => { handleDialogConfirmation(false) }} color="default" variant="outlined">
                No
              </Button>
              <Button onClick={() => { handleDialogConfirmation(true) }} color="primary" variant="contained" disableElevation>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Dialog>
      </ThemeProvider>
    </>
  )
}

const GoogleMap = ({ onNewAddress, clearFields, projectToEdit, setProjectState, isSuperAdmin }) => {
  let map, infoWindow, geocoder;
  let marker;

  let componentForm = {
    street_number: 'long_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };

  const mapOptions = {
    center: { lat: 39.5, lng: -98.35 },
    zoom: 4,
    mapId: '423d0c04368b7c23',
  };

  const initMap = () => {
    map = window.map = new google.maps.Map(document.getElementById("map"), mapOptions);

    geocoder = new google.maps.Geocoder();

    infoWindow = window.infoWindow = new google.maps.InfoWindow();

    marker = window.marker = new google.maps.Marker({
      anchorPoint: new google.maps.Point(0, -29),
      map: map,
    });

    marker.addListener("click", () => {
      map.setZoom(8);
      map.setCenter(marker.getPosition());
    });

    if (isSuperAdmin) {
      google.maps.event.addListener(map, "click", (e) => {
        marker.setVisible(false);
        infoWindow.close();
        marker.setPosition(e.latLng);
        marker.setVisible(true);

        geocodeLatLng(geocoder, map, infoWindow, e.latLng);
      });
    }

    if (projectToEdit && ((projectToEdit.latitude && projectToEdit.longitude) || (projectToEdit.location && projectToEdit.location.coordinates))) {
      let latLng = {
        lat: projectToEdit.latitude || projectToEdit.location.coordinates[0],
        lng: projectToEdit.longitude || projectToEdit.location.coordinates[1]
      }

      marker.setPosition(latLng);
      map.setZoom(8);
      map.setCenter(latLng);
    }
  }

  const geocodeLatLng = (geocoder, map, infoWindow, latLng) => {
    geocoder.geocode({ location: latLng }, (results, status) => {

      if (status === "OK") {
        if (results[0]) {
          infoWindow.setContent(results[0].formatted_address);
          infoWindow.open(map, marker);

          clearFields();

          window.getTimezone({ lat: latLng.lat(), lng: latLng.lng() });

          fillInAddress({
            formatted_address: results[0].formatted_address,
            address_components: results[0].address_components,
            latLng,
          });
        } else {
          toastr.error("No result found. Pin another location on the map.")
          clearFields();
        }
      } else {
        toastr.error("Google Geocode failed. Please try again.")
        clearFields();
      }
    });
  }

  function fillInAddress(address) {
    let eventToSend = {
      formatted_address: address.formatted_address,
      latLng: address.latLng
    }

    for (var i = 0; i < address.address_components.length; i++) {
      var addressType = address.address_components[i].types[0];
      if (componentForm[addressType]) {
        var val = address.address_components[i][componentForm[addressType]];

        if (addressType === "country") {
          val = {
            label: address.address_components[i].long_name,
            value: address.address_components[i].short_name,
          }
        }

        if (addressType === "administrative_area_level_1") {
          setProjectState({
            label: address.address_components[i].long_name,
            value: address.address_components[i].short_name,
          })
        }

        eventToSend[addressType] = val;
      }
    }

    let streetAddress = get(eventToSend, 'street_number', '')

    if (get(eventToSend, 'route')) streetAddress += ` ${get(eventToSend, 'route')}`

    onNewAddress({ ...eventToSend, streetAddress})
  }

  useEffect(() => {
    initMap();
    return () => { };
  }, [])

  return (
    <div id="map" className="form-control" style={{ height: "430px" }} />
  );
}

const GoogleAutocomplete = ({ isSuperAdmin, onNewAddress, clearFields, projectToEdit, setProjectState, formattedAddress }) => {
  let componentForm = {
    street_number: 'long_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };

  const [address, setAddress] = useState(formattedAddress || '')

  const handleAddressChange = (ev) => {
    setAddress(ev.target.value)
  }

  function fillInAddress() {
    clearFields();
    // Get the place details from the autocomplete object.
    var place = window.autocomplete.getPlace();

    setAddress(place.formatted_address)

    let eventToSend = {
      formatted_address: place.formatted_address,
      timezone: place.utc_offset_minutes,
    }

    if (place.geometry && place.geometry.location) {

      window.getTimezone({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });

      eventToSend.latLng = place.geometry.location
      // close infoWindow + hide marker
      window.infoWindow.close()
      window.marker.setVisible(false);
      // update map/marker location and infowindow content
      window.map.setCenter(place.geometry.location);
      window.map.setZoom(12);
      window.infoWindow.setContent(place.formatted_address);
      window.marker.setPosition(place.geometry.location);
      // open infoWindow + show marker
      window.marker.setVisible(true);
      window.infoWindow.open(map, marker);
    }

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];

      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];

        if (addressType === "country") {
          val = {
            label: place.address_components[i].long_name,
            value: place.address_components[i].short_name,
          }
        }

        if (addressType === "administrative_area_level_1") {
          setProjectState({
            label: place.address_components[i].long_name,
            value: place.address_components[i].short_name,
          })
        }

        eventToSend[addressType] = val;
      }
    }

    let streetAddress = get(eventToSend, 'street_number', '')

    if (get(eventToSend, 'route')) streetAddress += ` ${get(eventToSend, 'route')}`

    onNewAddress({ ...eventToSend, streetAddress })
  }

  const initAutocomplete = () => {
    window.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('google_maps_autocomplete'), { types: ['geocode'] });

    window.autocomplete.setFields(['address_component', 'formatted_address', 'geometry', 'utc_offset_minutes', 'place_id']);

    window.autocomplete.addListener('place_changed', fillInAddress);

    document.getElementById('google_maps_autocomplete').addEventListener('change', (ev) => {
      if (isEqual(ev.target.value, '')) {
        clearFields();
        window.getTimezone({ lat: 0, lng: 0 });
      }
    })

    if (projectToEdit) {
      setAddress(get(projectToEdit, 'fullAddress', get(projectToEdit, 'address')))
      // document.getElementById('google_maps_autocomplete').value = projectToEdit.address
    }
  }

  const geolocate = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        var circle = new google.maps.Circle(
          { center: geolocation, radius: position.coords.accuracy });

        let bounds = circle.getBounds()
        window.autocomplete.setBounds(bounds);
      });
    }
  }

  useEffect(() => {
    initAutocomplete();
    return () => { };
  }, [])

  useEffect(() => {
    if (!!formattedAddress) {
      setAddress(formattedAddress)
    }
    return () => { };
  }, [formattedAddress])

  return (
    <TextField
      required
      id="google_maps_autocomplete"
      onFocus={geolocate}
      label="Address"
      placeholder="Start typing for autocomplete"
      disabled={!isSuperAdmin}
      value={address || ''}
      onChange={handleAddressChange}
    />
  );
}

const pxToRem = (px, oneRemPx = 17) => `${px / oneRemPx}rem`;
const borderWidth = 2;
const width = pxToRem(56);
const height = pxToRem(34);
const size = pxToRem(22);
const gap = (34 - 22) / 2;

const LovelySwitch = withStyles((theme) => ({
  root: {
    width,
    height,
    padding: 0,
    margin: theme.spacing(1),
    overflow: 'unset',
  },
  switchBase: {
    padding: pxToRem(gap),
    '&$checked': {
      color: '#fff',
      transform: `translateX(calc(${width} - ${size} - ${pxToRem(2 * gap)}))`,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
      '& $thumb': {
        backgroundColor: '#fff',
      },
    },
  },
  track: {
    borderRadius: 40,
    border: `solid ${theme.palette.grey[400]}`,
    borderWidth,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    boxSizing: 'border-box',
  },
  thumb: {
    boxShadow: 'none',
    backgroundColor: theme.palette.grey[400],
    width: size,
    height: size,
  },
  checked: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(1),
    },
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: 'center',
    fontSize: '17px'
  },
  dialogActions: {
    justifyContent: 'space-between'
  },
  paper: {
    backgroundColor: 'transparent',
    marginTop: '4px', //6px
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));
