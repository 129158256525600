import React, { useState, useEffect } from 'react'
import DateFnsUtils from '@date-io/date-fns';
import { Select, ThemeProvider, FormControl, FormGroup, InputLabel, MenuItem, Button } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { myTheme, noop } from "./helpers";
import ShiftsTable from "./ShiftsTable";
import axios from "axios";

export default function CompaniesActivity(props) {

  const { companies, config } = props

  const [filter, setFilter] = useState({
    date: new Date(),
    company: '',
  })

  const defaultPagination = { data: [], count: 0 }

  const [shiftsPagination, setShiftsPagination] = useState(defaultPagination);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  const handleCompanyChange = (ev, ref) => {
    filter["company"] = ev.target.value

    setFilter({ ...filter })
  }

  const handleDateChange = (newDate, ev) => {
    filter["date"] = newDate

    setFilter({ ...filter })
  }

  const buildParams = () => {
    const params = {
      page: page + 1,
      date: moment(filter.date).format("MM/DD/YYYY"),
      limit: 10
    }

    const searchParams = new URLSearchParams(params)
    return searchParams.toString()
  }
  const handleChangePage = (ev, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (ev, val) => {
    setPerPage(val.props.value)
  }

  const load = () => {
    if (page != 0) {
      setPage(0)
    } else {
      loadShifts()
    }
  }

  const loadShifts = () => {
    if (filter.company.length > 0) {
      const url = `${location.origin}/companies/${filter.company}/shifts.json?${buildParams()}`
      setLoading(true)
      axios.get(url).then(response => {
        // console.log({ response });
        setShiftsPagination(response.data.pagination || defaultPagination)
        setLoading(false)
      }).catch(errorLoadingShifts => {
        // console.log({ errorLoadingShifts });
        setLoading(false)
      })
    }
  }

  const handleShiftUpdated = (shift, index) => {
    console.log("handleShiftUpdated", { shift, index })
    shiftsPagination.data[index] = shift
    setShiftsPagination({ ...shiftsPagination })
  }

  useEffect(() => {
    loadShifts()
    return noop
  }, [page, perPage])

  return (
    <ThemeProvider theme={myTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>

        <FormControl>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="select"
            style={{ marginTop: "0" }}
            format="MM/dd/yyyy"
            value={filter.date}
            name="date"
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </FormControl>
        <FormControl>
          <InputLabel id="select-company-label-id">Select a Company</InputLabel>
          <Select
            style={{ minWidth: "300px" }}
            labelId="select-company-label-id"
            onChange={handleCompanyChange}
            value={filter.company}
            id="seelct-company-id" >
            {companies.map(company => (

              <MenuItem key={company._id.$oid} value={company._id.$oid}>
                {company.name}
              </MenuItem>

            ))}
          </Select>
        </FormControl>


        <Button variant="contained" color="primary" onClick={load} style={{position: "relative", bottom: "-12px"}}>
          GO
        </Button>


        <ShiftsTable config={config}
                      loading={loading}
                      onShiftUpdate={handleShiftUpdated}
                      shiftsPagination={shiftsPagination}
                      page={page}
                      perPage={perPage}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage} />

      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}
