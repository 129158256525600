import React, { useState } from 'react'
import { Button, Modal, Form } from "react-bootstrap";
import axios from "axios"
import Dropzone, { useDropzone } from 'react-dropzone';
import DatePicker from "./DatePicker";

export default function CertificationNew({ baseUrl, employeeId, dbid, onCreate, onCancel, type, axiosConfig }) {

  const [disabled, setDisabled] = useState(false);
  const defaultState = {
    name: '',
    description: '',
  }
  const defaultFileState = {
    blob: null, url: ''
  }
  const [expirationDate, setExpirationDate] = useState('')
  const [issueDate, setIssueDate] = useState('')
  const [file, setFile] = useState(defaultFileState)
  const [isPublic, setIsPublic] = useState(false)

  const [newCertification, setNewCertification] = useState({
    ...defaultState
  })

  const upload = (ev) => {
    console.log(ev)
    ev.preventDefault()
    uploadPhoto(newCertification.file)
  }

  const handleIsPublicChange = ev => {
    console.log({ ev });

    const val = ev.target.value == "true"
    setIsPublic(val);
  }

  const handleIssueDateChange = newDate => {
    const fmt = newDate.format("MM/DD/YYYY")
    setIssueDate(fmt)
  }

  const handleExpirationDateChange = newExpiration => {
    console.log({ newExpiration, newCertification });
    const fmt = newExpiration.format("MM/DD/YYYY")
    setExpirationDate(fmt)
  }

  const validateDate = (dateStr) => {
    var date = new Date(dateStr)
    if (date.getTime() == NaN) {
      return null
    }

    return date
  }


    const save = () => {
        const fileUrl = `${baseUrl}/certifications/get-upload-url`
        const url = `${baseUrl}/workers/${dbid}/certifications`
        let docUrl = ''

        setDisabled(true)
        axios.post(fileUrl, { contentType: file.blob.type, dbid: dbid }, axiosConfig()).then(response1 => {
            setDisabled(false)
            console.log({ response1 });
            const { documentUrl, s3PutObjectUrl } = response1.data;
            docUrl = documentUrl

            setDisabled(true)
            return axios.put(s3PutObjectUrl, file.blob, { headers: { contentType: file.blob.type } })

        }).then(response2 => {
            setDisabled(false)
            console.log({ response2 });
            const payload = {
              "employee_id": employeeId,
              "type_id": type._id,
              "issue_date": validateDate(issueDate),
              "expiration_date": validateDate(expirationDate),
              "is_public": isPublic,
              "file_url": docUrl
            }
            setDisabled(true)
            return axios.post(url, payload, axiosConfig())
        }).then(response3 => {
            setDisabled(false)
            console.log({ response3 });
            onCreate(response3.data[0])
        }).catch(err => {
          setDisabled(false)
          console.log({ err });
        })

    }

  const onDrop = files => {
    const file = files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      setFile({ url: reader.result, blob: file })
    }
  }

  return (


    <>
      <Modal.Header closeButton>
        <Modal.Title>New {type.name}</Modal.Title>
        <span>{type.description}</span>
      </Modal.Header>
      <Modal.Body>

        <img src={type.logo_url} alt="" style={{width: "200px", position: "relative", "left": "40%"}}/>

        <form>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps({ className: 'dropzone-fieldset' })}>
                  <input {...getInputProps()} accept="image/jpeg, image/png, application/pdf" />
                  <span className="h2">Drag or click to select file</span>
                  <span>(jpeg, png, pdf)</span>
                </div>
              </section>
            )}
          </Dropzone>

          {
            (() => {
              if (file.url.length > 0) {
                return (
                  <div>
                    <iframe src={file.url} style={{ width: "100%", height: "400px" }} frameBorder="0"></iframe>
                  </div>
                )
              }
            })()
          }

          <fieldset className="form-group form-group-lg">
            <label>Issue Date</label>
            <DatePicker value={issueDate} onChange={handleIssueDateChange} />
          </fieldset>
          {(() => {
            if (type.expires) {
              return (
                <fieldset className="form-group form-group-lg">
                  <label>Expiration Date</label>
                  <DatePicker value={expirationDate} onChange={handleExpirationDateChange} />
                </fieldset>
              )
            }
          })()}
          <Form.Group>
            <Form.Label>Is Public</Form.Label>
            <Form.Check inline checked={isPublic} value={true} id="public_true" label="Public" type="radio" onChange={handleIsPublicChange} />
            <Form.Check inline checked={!isPublic} value={false} id="public_false" label="Private" type="radio" onChange={handleIsPublicChange} />
          </Form.Group>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button disabled={disabled} className="btn btn-sm btn-danger" onClick={save}>Save</button>
        <button className="btn btn-sm btn-default" onClick={onCancel}>Cancel</button>
      </Modal.Footer>
    </>


  )
}
