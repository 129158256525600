import React from 'react'
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

export default function WorkersTodayPie(props) {
  
  const { title, data } = props
  
  const sortedData = data.sort((a,b) => a.y - b.y)

  const plotData = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    title: {
      text: title
    },
    tooltip: {
      pointFormat: '<b>{point.percentage:.1f}% ({point.y})</b>'
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f}% ({point.y})'
        }
      }
    },
    series: [{
      name: 'Brands',
      colorByPoint: true,
      data: sortedData
    }]
  }
  

  return (
    <HighchartsReact highcharts={Highcharts} options={plotData} />
  )
}
