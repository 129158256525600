import React, { useState, useEffect } from 'react'
import { noop } from './helpers';
import { ThemeProvider, Table, TableHead, TableCell, TableRow, TableBody, TableFooter, TablePagination, LinearProgress } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { myTheme, axiosConfig } from "./helpers";
import { Punch, Worker, Shift } from "./helpers/formatters";
import axios from "axios";
import PunchImages from "./PunchImages";
import ShiftcostcodeOutline from "./ShiftcostcodeOutline";
import ShiftsTable from "./ShiftsTable";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';


export default function WorkerActivity(props) {

  const { worker, config } = props

  const defaultPagination = { data: [], count: 0 }
  const [shiftsPagination, setShiftsPagination] = useState(defaultPagination);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(true);

  const paginationParams = () => ({ page: parseInt(page) + 1, limit: perPage })

  const axiosClient = () => {
    return axios.create(axiosConfig())
  }

  const handleChangePage = (ev, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (ev, val) => {
    setPerPage(val.props.value)
  }

  const handleShiftUpdated = (shift, index) => {
    shiftsPagination.data[index] = shift
    setShiftsPagination({ ...shiftsPagination })
  }


  const loadShifts = () => {
    const paginationString = new URLSearchParams(paginationParams())
    const url = `${location.origin}/employees/${worker._id.$oid}/shifts.json?${paginationString.toString()}`
    setLoading(true)
    axiosClient().get(url).then(response => {
      // console.log({ response });
      setShiftsPagination(response.data.pagination || defaultPagination)
      setLoading(false)
    }).catch(errorLoadingShifts => {
      // console.error({ errorLoadingShifts })
      setLoading(false)
    })
  }

  useEffect(() => {
    loadShifts()
    return noop
  }, [page, perPage])

  return (
    <ThemeProvider theme={myTheme}>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>

        <ShiftsTable config={config}
                      loading={loading}
                      onShiftUpdate={handleShiftUpdated}
                      shiftsPagination={shiftsPagination}
                      page={page} perPage={perPage}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage} />
      </MuiPickersUtilsProvider>

    </ThemeProvider>
  )
}
