import React from 'react';
import { DeviceStats } from './components/DeviceStats';
import { DeviceList } from './components/DeviceList';

export default function ListPage({ endpoint }) {
  return (
      <section>
        <DeviceStats endpoint={endpoint} />
        <hr />
        <DeviceList endpoint={endpoint} />
      </section>
  );
};
