import React, { useEffect, useState, useContext } from 'react';
// import { gettingCompanyProfiles, deleteCompanyProfiles } from '../../services/profileServices';
import _ from 'lodash';
import { WorkerTablePagination } from '../../../employees/projectEmployees/components';
import { Button, Dialog, DialogActions, DialogContent, Drawer, IconButton, lighten, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Toolbar, Typography, Tooltip } from '@material-ui/core';
import { GroupAdd, Edit, Done, Close, Delete } from '@material-ui/icons';
import { allColumns } from '../../constants/tableHeaders'
import { CreateCrew } from '../index'
import { SettingsContext } from '../../../employees/contexts/SettingsContext';
import { gettingCompanyCrews, deletingCrew, gettingCrewDetails } from '../../services'

const EnhancedTableToolbar = (props) => {

  const { onCrew, company } = props;

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    highlight:
      theme.palette.type === "light"
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
    title: {
      flex: "1 1 100%"
    }
  }));

  const classes = useToolbarStyles();

  return (
    <Toolbar>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Profiles
      </Typography>
      <Tooltip arrow title={`Add crew for ${_.get(company, 'name')}`}>
        <IconButton aria-label="delete" onClick={() => onCrew()} >
          <GroupAdd />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort, columns } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align="left"
            padding={column.disablePadding ? "none" : "default"}
            sortDirection={orderBy === column.id ? order : false}
          >
            {column.sortOption ?
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : "asc"}
                onClick={createSortHandler(column.id)}
              >
                {column.label}
                {orderBy === column.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel> :
              column.label
            }

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};


export default function TableComponent(props) {

  const settingsContext = useContext(SettingsContext)

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%"
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 750
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1
    },
    drawerRoot: {
      width: `${settingsContext.isMobile ? '75%' : '35%'}`
    }
  }));

  const { company, onLoading, companies, currentCompany, companyWorkersDomain, paymentProfiles, crewEndpoint } = props;


  const classes = useStyles();

  const [state, setState] = useState({
    crews: [],
    order: "asc",
    orderBy: '',
    addCrew: {
      status: false,
      crew: null
    },
    filters: null,
    pagination: {
      page: 0,
      rowsPerPage: 25
    },
    currentCrew: null
  });

  const [deleteCrew, setDeleteCrew] = useState(false);


  const getCrews = async (payload, companyId = _.get(company, '_id.$oid')) => {
    try {
      onLoading()

      const res = await gettingCompanyCrews(companyId, payload, crewEndpoint);
      setState(prevState => ({
        ...prevState,
        crews: _.get(res, 'data', [])
      }))

      onLoading(false)
    } catch (error) {

    }
  }

  useEffect(() => {
    let payload = {
      pagination: {
        ...state.pagination
      }
    }
    getCrews(payload, _.get(currentCompany, 'value'));
  }, [currentCompany])


  const onSearch = () => {

    let payload = {
      pagination: {
        ...state.pagination
      },
      filters: [{ key: "search", value: settingsContext.searchValue }]
    }

    getCrews(payload, _.get(currentCompany, 'value'))
  }


  useEffect(() => {

    if (settingsContext.search) onSearch();

    if (settingsContext.searchValue.length > 1) {
      setState(prevState => ({
        ...prevState,
        filters: [{ key: "search", value: settingsContext.searchValue }]
      }))
    } else if (settingsContext.searchValue.length === 0 && settingsContext.searchEnable) {

      let payload = {
        pagination: {
          ...state.pagination
        }
      }
      getCrews(payload, _.get(currentCompany, 'value'))
    }

  }, [settingsContext.search, settingsContext.searchValue])




  const handleChangePage = (step) => {

    let page = 0;
    if (step === 'next') {
      page = pagination.page + 1;
    } else {
      page = pagination.page - 1;
    }

    setPagination(prevState => ({
      ...prevState,
      page
    }))

    let payload = {
      filters: sendingFilters,
      pagination: {
        rowsPerPage: pagination.rowsPerPage,
        page
      }
    }

    // getWorkers(payload)
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";

    setState(prevState => ({
      ...prevState,
      order: isAsc ? "desc" : "asc",
      orderBy: property
    }))

    let payload = {
      sort: {
        property,
        isAsc
      }
    }

    // getWorkers(payload);
  };

  const handleChangeRowsPerPage = (e) => {

    setState(prevState => ({
      ...prevState,
      page: 0,
      rowsPerPage: e.target.value
    }))



    // let payload = {
    //   filters: sendingFilters,
    //   pagination: {
    //     rowsPerPage: e.target.value,
    //     page: 0
    //   }
    // }
    // getWorkers(payload);
  };

  const onCrew = async (crew = null) => {

    if (_.isNull(crew)) {
      setState(prevState => ({
        ...prevState,
        addCrew: {
          status: true,
          crew
        }
      }))

    } else {
      onLoading(true)

      const res = await gettingCrewDetails(_.get(currentCompany, 'value'), _.get(crew, '_id'), crewEndpoint);

      setState(prevState => ({
        ...prevState,
        addCrew: {
          status: true,
          crew: _.get(res, 'data')
        }
      }))

      onLoading(false)
    }


  }

  const onCloseProfile = (flag) => {
    setState(prevState => ({
      ...prevState,
      addCrew: {
        status: false,
        profile: null
      }
    }))

    if (_.isBoolean(flag) && flag) {
      let payload = {
        pagination: {
          ...state.pagination
        }
      }
      getCrews(payload, _.get(currentCompany, 'value'));
    }

  }

  const onDeleteCrew = async (flag, row = null) => {

    if (_.isNull(flag)) {
      setState(prevState => ({
        ...prevState,
        currentCrew: row
      }));
      setDeleteCrew(true)
    } else {
      try {

        if (flag) {
          onLoading();
          await deletingCrew(_.get(state.currentCrew, 'company._id'), crewEndpoint, _.get(state.currentCrew, '_id'))
          setDeleteCrew(false)
          let payload = {
            pagination: {
              ...state.pagination
            }
          }

          getCrews(payload, _.get(currentCompany, 'value'));
        } else {
          setDeleteCrew(false)
        }

      } catch (error) {

      }
    }

  }



  return (
    <>
      {/* <TableFilters filters={filters} onFilter={onFilter} onSendingFilters={onSendingFilters} onClearFilters={onClearFilters} /> */}
      <WorkerTablePagination
        pagination={state.pagination}
        onChangePage={handleChangePage}
        hasNext={_.size(state.profiles) === state.pagination.rowsPerPage}
        hasWorkers={_.size(state.profiles) > 0}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Paper className={classes.paper}>

        <EnhancedTableToolbar onCrew={onCrew} company={company} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            style={{ position: 'relative' }}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={state.order}
              orderBy={state.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={state.crews.length}
              columns={allColumns}
            />
            <TableBody>
              {state.crews.map((row, index) => {
                return (
                  <TableRow key={_.get(row, '_id')}>

                    <TableCell align="left">{_.get(row, 'name', '')}</TableCell>
                    <TableCell align="left">{_.get(row, 'paymentProfile.name', '')}</TableCell>
                    <TableCell align="left">{_.get(row, 'members', 0)}</TableCell>
                    <TableCell align="left">
                      <Edit onClick={() => onCrew(row)} className='mr-2' style={{ cursor: 'pointer' }} />
                      <Delete onClick={() => onDeleteCrew(null, row)} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <WorkerTablePagination
          pagination={state.pagination}
          onChangePage={handleChangePage}
          hasNext={_.size(state.crews) === state.pagination.rowsPerPage}
          hasWorkers={_.size(state.crews) > 0}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Drawer disableBackdropClick={true} classes={{ paper: classes.drawerRoot }} anchor={'right'} open={state.addCrew.status} onClose={onCloseProfile}>
        <CreateCrew
          crew={state.addCrew.crew}
          onCloseProfile={onCloseProfile}
          companies={companies}
          company={company}
          companyWorkersDomain={companyWorkersDomain}
          paymentProfiles={paymentProfiles}
          crewEndpoint={crewEndpoint}
          selectedCompany={currentCompany}
        />
      </Drawer>

      <Dialog
        open={deleteCrew}
        onClose={() => onDeleteCrew(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {`Are you sure you want to delete this crew ${_.get(state.currentCrew, 'name')}?`}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onDeleteCrew(false)} color="primary">
            Disagree
          </Button>
          <Button onClick={() => onDeleteCrew(true)} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>



    </>
  )
};
