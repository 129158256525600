import React from 'react';

import AnimatedNumber from 'animated-number-react';
import { get, isEmpty, has } from 'lodash';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import PropTypes from 'prop-types';

import { RadialBar, RadialBarChart, ResponsiveContainer } from 'recharts';

export default function GaugeMeter( props ) {
  // props
  const title = get( props, 'title', '');
  const iconUrl = get( props, 'iconUrl', '');
  const values = get( props, 'values', { current: 0, total: 0 });
  const size = get( props, 'size', 150);
  const animationDuration = get( props, 'animationDuration', 1500);
  const customStyling = get( props, 'customStyling', {})
  const disableBackground = get( props, 'disableBackground', has( props, 'disableBackground'))
  const thickMeter = get( props, 'thickMeter', has( props, 'thickMeter'))
  const noActivity = get( props, 'noActivity', has( props, 'noActivity'))
  const activityCompleted = get( props, 'activityCompleted', has( props, 'activityCompleted'))
  const fullGaugeFontSize = get( props, 'fullGaugeFontSize', has( props, 'fullGaugeFontSize'))
  const fetchingData = get( props, 'fetchingData', has( props, 'fetchingData'))
  const failedToFetch = get( props, 'failedToFetch', has( props, 'failedToFetch'))
  const reload = get( props, 'reload', has( props, 'reload'))

  const currentValue = values.current;
  const formatValue = (currentValue) => currentValue.toFixed(0);

  // styling
  const backgroundColor = get( customStyling, 'backgroundColor', disableBackground ? 'transparent' : '#e8e8e8');
  const meterFillColor = get( customStyling, 'meterFillColor', '#36C240');
  let meterFillBackgroundColor = get( customStyling, 'meterFillBackgroundColor', '#cce0ca');
  const valueBackgroundColor = get( customStyling, 'valueBackgroundColor', '#fff');
  const titleColor = get( customStyling, 'titleColor', '#404040');
  const currentValueColor = get( customStyling, 'currentValueColor', '#3f3f3f');
  const totalValueColor = get( customStyling, 'totalValueColor', '#747474');

  if (noActivity) meterFillBackgroundColor = '#de9c95'

  const useStyles = makeStyles((theme) => ({
    mainWrapper: {
      backgroundColor,
      width: '100%',
      borderRadius: 13,
      paddingLeft: !isEmpty(title) && size * 0.08,
      paddingRight: !isEmpty(title) && size * 0.08,
    },
    currentValueWrapper: {
      height: size * 0.01,
      textAlign: 'center',
      fontSize: size * 0.33,
      color: currentValueColor,
      lineHeight: 0.8,
    },
    totalValueWrapper: {
      height: size * 0.1,
      textAlign: 'center',
      fontSize: size * 0.15,
      color: totalValueColor,
    },
    noActivity: {
      position: 'relative',
      color: '#C93A05',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      fontSize: fullGaugeFontSize ? fullGaugeFontSize : currentValue < 100 ? '1.5em' : currentValue < 1000 ? '1.3em' : '0.9em',
      fontWeight: 600,
      justifyContent: 'center',
    },
    activityCompleted: {
      color: '#36C240',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      fontSize: fullGaugeFontSize ? fullGaugeFontSize : currentValue < 100 ? '1.5em' : currentValue < 1000 ? '1.3em' : '0.9em',
      fontWeight: 600,
      justifyContent: 'center',
    },
    spinnerWrapper: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center'
    },
    errorWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#f44336'
    },
    smartBarrelWorker: {
      width: 15,
      position: 'absolute',
      right: 4,
      bottom: 6
    }
  }));

  const classes = useStyles();

  const renderGaugeContent = () => {
    // return spinner if fetching data
    if (fetchingData) {
      return (
        <Box width={size} height={size}>
          <div className={classes.spinnerWrapper}><CircularProgress fontSize={30} color="primary" /></div>
        </Box>
      )
    }

    if (failedToFetch) {
        // no reload function provided -> return plain error
        if (!reload) {
            return (
              <Box width={size} height={size}>
                <div className={classes.errorWrapper}>
                    <ErrorOutlineIcon color='error' /> Load error
                </div>
              </Box>
            )
        }

        // show reload button and trigger function on click
        return (
            <Box width={size} height={size}>
              <div className={classes.spinnerWrapper}>
                <Tooltip arrow title='Reload'>
                  <IconButton color='primary' onClick={() => reload()}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Box>
          )
    }

    const returnGaugeContent = () => {
      if (activityCompleted) {
        return (
          <Typography variant='caption' className={classes.activityCompleted} display='block'>
            {values.total}
          </Typography>
        )
      } else if (noActivity) {
        return (
          <div className={classes.noActivity}>0</div>
        )
      } else {
        return (
          <Box display='flex' flexDirection='column' width='100%' height='100%' borderRadius='100%' style={{ background: valueBackgroundColor, fontWeight: 500 }}>
            <Box display='flex' flexDirection='column' justifyContent='flex-end' flexGrow={3} className={classes.currentValueWrapper}>
              <AnimatedNumber value={currentValue} duration={animationDuration+500} formatValue={formatValue} />
            </Box>
            <Box flexGrow={1} className={classes.totalValueWrapper}>
              /{values.total}
            </Box>
          </Box>
        )
      }
    }

    return (
      <Box width={size} height={size} position='relative' style={{ transform: 'rotate(270deg) scaleX(-1)'}}>
        <ResponsiveContainer width='100%'>
          <RadialBarChart startAngle={45} endAngle={315} innerRadius='120%' outerRadius='90%' barSize={size*0.05} data={[{ name: 'value', uv: (noActivity && values.current === 0) || activityCompleted ? 100 : values.current, pv: (noActivity && values.total === 0) || activityCompleted ? 100 : values.total }]}>
            <RadialBar minAngle={0} dataKey='pv' fill={backgroundColor} isAnimationActive={false} />
            <RadialBar minAngle={0} background={{ fill: meterFillBackgroundColor }} dataKey='uv' fill={noActivity ? '#de9c95' : meterFillColor} animationDuration={animationDuration} animationEasing='ease' />
          </RadialBarChart>
        </ResponsiveContainer>
        <Box position='absolute' width='100%' height='100%' top={0} padding='1px'>
          <Box width='100%' height='100%' style={{ padding: thickMeter ? size*0.14 : size*0.11, transform: 'rotate(270deg) scaleX(-1)' }}>
            {returnGaugeContent()}
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box display='inline-block' className={classes.mainWrapper}>
      <Box display="flex" flexDirection="column" alignItems="center" style={{ margin: size * 0.033 }}>
            { !isEmpty(title) &&
              <Box display='flex' justifyContent='center' color={titleColor} fontSize={size*0.15} fontWeight={500}>
                  <Typography variant='inherit' align='center' noWrap>{title}</Typography>
                  { !isEmpty(iconUrl) &&
                    <Box ml="5%" mt="5%">
                        {<Avatar src={iconUrl} style={{ width: size/8.57, height: size/8.57 }} />}
                        {/*<img src={iconUrl} style={{ width: size/8.57, height: size/8.57, backgroundColor: 'red'}} />*/}
                    </Box>
                  }
              </Box>
            }
            { renderGaugeContent() }
        </Box>
    </Box>
  )
}

GaugeMeter.propTypes = {
  title: PropTypes.string,
  iconUrl: PropTypes.string,
  values: PropTypes.shape({
    current: PropTypes.number,
    total: PropTypes.number
  }),
  size: PropTypes.number,
  animationDuration: PropTypes.number,
  customStyling: PropTypes.object,
}
