import React, { useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import _ from 'lodash';


export default function CoordinatePopoverContent({ onSubmit, onClose, classes, coordinates }) {
    const [lng, setLng] = useState(_.get(coordinates, 'lng', ''));
    const [lat, setLat] = useState(_.get(coordinates, 'lat', ''));

    const handleInputChange = (inputName, value) => {
        // Regex for float number validation
        const floatRegex = /^[+-]?(\d+(\.\d*)?|\.\d+)$/;

        if (!floatRegex.test(value) && value !== '') return;

        if (inputName === 'lng') setLng(value);

        if (inputName === 'lat') setLat(value);
    };

    const handleSubmit = () => {
      onSubmit({ lng, lat });
      setLng('');
      setLat('');
      onClose();
    };

    return (
      <div className={classes.coordinatesPopoverContent}>
        <TextField
          label="Latitude (DD)"
          value={lat}
          onChange={(e) => handleInputChange('lat', e.target.value)}
          style={{ marginBottom: '8px' }}
        />
        <TextField
          label="Longitude (DD)"
          value={lng}
          onChange={(e) => handleInputChange('lng', e.target.value)}
          style={{ marginBottom: '8px' }}
        />
        <Button variant="outlined" color="primary" onClick={handleSubmit}>
          Get Address
        </Button>
      </div>
    );
  };
