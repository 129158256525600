import React from "react";

import { Auxiliary } from "../../../../helpers";

const LineIndicator = ({ title, width, value, color }) => {

  return (
    <Auxiliary>
      <p>{title}</p>
      <div className="jr-line-indi-info">
        <div className={`jr-line-indi bg-${color}`} style={{
          width: Number.parseInt(width) * 4
        }} />

        <span className="jr-line-indi-count ml-2">{value}</span>
      </div>
    </Auxiliary>

  );
};

export default LineIndicator;