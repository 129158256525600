import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { Typography } from '@material-ui/core';
import { $axios } from '../../../helpers';

export function DeviceStats({endpoint}) {
  const [stats, setStats] = useState([]);

  function fetchList() {
    $axios.$get(`${endpoint}/stats`)
        .then((result) => setStats(result.map(stat => ({ id: stat.id, current: 0, total: stat.count }))))
        .catch(console.warn);
  }

  useEffect(fetchList, []);

  return (
      <Grid className="text-center" spacing={3} container>
        {renderCard(stats)}
      </Grid>
  );
}

const renderCard = function (stats = []) {
  return stats.map(stat => (
      <Grid key={stat.id} xs={4} md={true} item>
        <Card className="p-y-2">
          <Typography className="p-y-1" variant="h5">{stat.id}</Typography>
          <div style={{ display: 'flex', alignItems: 'self-end', justifyContent: 'center', gap: '.5em' }}>
            <Typography variant="h5" className="text-danger">{stat.current}</Typography>
            <Typography variant="h6" component="small">{stat.total}</Typography>
          </div>
        </Card>
      </Grid>
  ));
};
