import React, { useState, useEffect } from 'react';
import { Select, Button, CircularProgress, FormControl, InputLabel, makeStyles, TextField, MenuItem } from '@material-ui/core';
import { Cancel, Send, ExpandMore } from '@material-ui/icons';
import _ from 'lodash';
import { TransferList } from '../index';
import { gettingWorkersByCompany, createCrew, editCrew } from '../../services';
import { notificationCenter } from '../../../helpers/notifications';


const useStyles = makeStyles((theme) => ({
  input: {
    width: '150px'
  },
  inputName: {
    width: '280px'
  },
  inputSwitch: {
    width: '100px'
  },
  select: {
    width: '250px'
  },
  formControl: {
    margin: "0px 0px 0px 19px"
  },
  accordion: {
    width: "100%"
  },
  fieldSet: {
    border: "1px groove #ddd !important",
    padding: "0 1.4em 1.4em 1.4em!important",
    margin: "0 0 1.5em 0 !important",
    boxShadow: "0px 0px 0px 0px #000",
    width: "80%"
  },
  legend: {
    border: 'none',
    paddingLeft: '6px',
    paddingRight: '6px',

    width: 'auto'
  },
}));



export default function CreateCrew(props) {

  const { crew, onCloseProfile, companies, company, companyWorkersDomain, paymentProfiles, crewEndpoint, selectedCompany } = props;
  const classes = useStyles();

  const [currentCompany, setCurrentCompany] = useState(_.isNull(crew) ? _.get(selectedCompany, 'value') : _.get(crew, 'company._id'))
  const [saving, setSaving] = useState(false)
  const [state, setState] = useState({
    name: _.get(crew, 'name', ''),
    workers: [],
    payment_profile_id: _.get(crew, 'paymentProfile._id', ''),
    crewWorkers: _.get(crew, 'crewEmployees', [])
  });

  const [loadingWorkers, setLoadingWorkers] = useState(true);

  const getWorkers = async () => {

    let payload = {
      skipPagination: true,
      viewType: "compact"
    }
    setLoadingWorkers(true);

    const res = await gettingWorkersByCompany(currentCompany, payload, companyWorkersDomain);

    const aux = _.get(res, 'data').filter(item => _.isNull(_.get(item, 'crew_id')) || !_.has(item, 'crew_id'));

    setState(prevState => ({
      ...prevState,
      workers: aux
    }))
    setLoadingWorkers(false);
  }

  useEffect(() => {
    getWorkers()
  }, [currentCompany])


  const onBasicChange = (field, value) => {
    setState(prevState => ({
      ...prevState,
      [field]: value
    }))
  }

  const onChangeCompany = (value) => {
    setCurrentCompany(value)
  }

  const onAddWorkersCrew = (workers) => {
    setState(prevState => ({
      ...prevState,
      crewWorkers: workers
    }))
  }

  const onCreateCrew = async (flag) => {

    if (flag) {
      if (_.isEmpty(_.get(state, 'name')) || _.isEmpty(_.get(state, 'payment_profile_id')) || _.isEmpty(state, 'crewWorkers')) {
        notificationCenter('error', 'You are missing some required fields!')
      } else {
        try {

          setSaving(true)
          let payload = {
            name: _.get(state, 'name', ''),
            payment_profile_id: state.payment_profile_id,
            company_id: currentCompany,
            crew_employees: state.crewWorkers.map(item => {
              return {
                _id: _.get(item, '_id'),
                company_id: _.get(item, 'company._id')
              }
            })
          }

          if (!_.isNull(crew)) {
            await editCrew(currentCompany, payload, crewEndpoint, _.get(crew, '_id'));
          } else {
            await createCrew(currentCompany, payload, crewEndpoint)
          }

          setSaving(false);
          onCloseProfile(true)
        } catch (error) {
          notificationCenter('error', _.get(error, 'response.data.error', 'Error'))

        }
      }
    } else {
      onCloseProfile()
    }

  }


  return (
    <div style={{ padding: '30px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Add Crew</span>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField label="Crew Name" required onChange={(e) => onBasicChange('name', e.target.value)} className={classes.inputName} value={_.get(state, 'name', '')} />

          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Company</InputLabel>
            <Select
              required
              classes={{ root: classes.select }}
              id="demo-simple-select"
              value={currentCompany}
              onChange={(e) => onChangeCompany(e.target.value)}
            >
              {companies.map(item => (
                <MenuItem key={_.get(item, 'value')} value={_.get(item, 'value')}>{_.get(item, 'label')}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ marginTop: '10px' }}>

          <FormControl required>
            <InputLabel id="demo-simple-select-label">Payment Profile</InputLabel>
            <Select
              required
              classes={{ root: classes.select }}
              id="demo-simple-select"
              value={state.payment_profile_id}
              onChange={(e) => onBasicChange('payment_profile_id', e.target.value)}
            >
              {paymentProfiles.map(item => (
                <MenuItem key={_.get(item, '_id')} value={_.get(item, '_id')}>{_.get(item, 'name')}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <TransferList
          selectTitle={`Crew Workers ${_.size(_.get(crew, 'crewEmployees'))}`}
          loadingWorkers={loadingWorkers}
          workers={state.workers}
          crew={crew}
          justifyContent={'center'}
          currentCompany={currentCompany}
          onAddWorkersCrew={onAddWorkersCrew} />
        <div>

        </div>

      </div>

      <div style={{ marginTop: '20px' }}>
        <Button
          variant="outlined"
          onClick={() => onCreateCrew(false)}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          className='ml-1'
          disabled={saving || loadingWorkers}
          style={{ marginLeft: '5px', color: '#009687' }}
          onClick={() => onCreateCrew(true)}
          startIcon={saving && <CircularProgress size={10} color="primary" />}>
          Save
        </Button>

      </div>
    </div>

  )
};
