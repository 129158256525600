import React, { useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export const AccordionPlaceholder = ({ rows = 5 }) => {
  const lines = Array(rows).fill(null);

  return (<>
    {lines.map((value, key) => (
        <Skeleton key={key} animation="wave" className="m-b-2" variant="rect" height={50} />),
    )}
  </>);
};
