import React, { useEffect, useState } from 'react'
import { SChartOrganization } from './component'
import { $axios } from '../../../helpers'
import { CardDash } from '../../components'

export function OrganizationChart(props) {
  const [project, setProject] = useState()
  const projectId = props.project._id.$oid
  const endpoint = props.config.project_endpoint

  useEffect(() => {
    $axios.$get(`${endpoint}/getChart/${projectId}`)
      .then((res) => setProject(res.project))
  }, [])

  return (
    <CardDash title="Companies" height="500px">
      {project && <SChartOrganization project={project} />}
    </CardDash>
  )
}
