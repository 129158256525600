import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { get } from 'lodash';
import { formatPhoneNumber } from '../../../../helpers/formatters';
import { AccordionItem } from '../../../../reusableComponents/AccordionItem';

const SectionAccordion = ({ name: title, items, renderToolbar, renderItem = () => ({}), isLastItem }) => {
  const hasItems = () => !!items.length;
  const baseUrl = 'https://static-files.connectedbarrel.com/icons';

  return (
      <AccordionItem
          title={title}
          renderToolbar={<>
            {renderToolbar}
            <Chip
                label={items.length}
                className={hasItems() ? 'bg-success' : 'bg-danger'}
                size="small" />
          </>}
          style={{ marginBottom: !isLastItem ? 16 : 0, borderTop: 'none' }}
      >
        <Grid container>
          {!hasItems()
              ? <Grid justifyContent={'center'} className="p-a-2" container>No items to display</Grid>
              : items.map((item) => (
                  <Grid key={item._id} className="p-y-1 border-bottom" container>
                    <Grid xs="auto" className="p-x-1" item>
                      <Avatar src={item.employee.profilePic} />
                      <div style={{ gap: '.5em', display: 'flex' }}>
                        {item.classifiers.language && (<img
                            src={`${baseUrl}/lenguageIcons/${item.classifiers.language}-checked.svg`}
                            style={{ width: '18px', height: 'auto' }}
                            alt={''}
                        />)}

                        {item.classifiers.source && (<img
                            src={`${baseUrl}/integrations/${item.classifiers.source}.png`}
                            style={{ width: '18px', height: 'auto' }}
                            alt={''}
                        />)}
                      </div>
                    </Grid>

                    <Grid xs={3} item>
                      <div style={{ gap: '.5em', display: 'flex' }}>
                        <Typography variant={'subtitle2'}>
                          {item.employee.first_name} {item.employee.last_name}
                        </Typography>
                      </div>
                      <div style={{ gap: '.5em', display: 'flex' }}>
                        <strong style={{ width: '6em' }}>Phone: </strong>
                        <span>{formatPhoneNumber(get(item, 'employee.phone_number'))}</span>
                      </div>
                      <div style={{ gap: '.5em', display: 'flex' }}>
                        <strong style={{ width: '6em' }}>Company: </strong>
                        <span>{get(item, 'employeeCompany.name')}</span>
                      </div>
                    </Grid>

                    <Grid className="text-truncate" xs={6} item>
                      <div title={item.notes} className="flex-fill">{item.notes}</div>
                      <Typography component="small">
                        <span>{item.sent_at}</span>
                      </Typography>
                    </Grid>

                    {renderItem({ item })}
                  </Grid>
              ))}
        </Grid>
      </AccordionItem>
  );
};

export default SectionAccordion;
