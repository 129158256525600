import React from 'react'
import HighchartsReact from "highcharts-react-official";
// import Highcharts from "highcharts";
import Highcharts from "highcharts/highstock";

export default function ChartMetrics({ rows }) {


  const getChartData = (rows) => {
    let actualData = [];
    let budgetData = [];
    let labels = [];
    let rowValues = Object.values(rows);
    rowValues.forEach(row => {
      actualData.push(row.actual);
      budgetData.push(row.budget);
      labels.push(`${row.code} - ${row.name}`);
    })

    return {
      chart: {
        type: 'column'
      },
      title: {
        text: "Labor Productivity"
      },
      plotOptions: {
        column: {
          grouping: false,
          shadow: false,
          borderWidth: 0
        }
      },
      xAxis: {
        categories: labels,
        title: {
          text: "Cost Codes"
        },
        min: 0,
        max: 5,
        scrollbar: {
          enabled: true
        },
        tickLength: 0
      },
      yAxis: {
        title: {
          text: "Hours"
        }
      },
      plotOptions: {
        column: {
          grouping: false,
          shadow: false,
          borderWidth: 0
        }
      },
      series: [{
        name: "Budget",
        pointPadding: 0.3,
        pointPlacement: -0.2,
        data: budgetData
      }, {
        name: "Actual",
        pointPadding: 0.4,
        pointPlacement: -0.2,
        data: actualData,
      }]
    }
  }
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={getChartData(rows)} />
    </div>
  )
}
