import React, { useState, useEffect, useRef } from 'react';
import { FormControl, MenuItem, InputLabel, makeStyles, Select, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link, Tooltip } from '@material-ui/core';
import { isEqual, get, isEmpty, size, has, isNull } from 'lodash';
import { Send, Cancel, Backup, Done } from '@material-ui/icons';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { notificationCenter } from '../../../../helpers/notifications';
import { getUploadUrl, uploadEmployeesCsv, createWorkerCompany, downloadSessionResults } from '../../../services/employeeService';
import Dropzone from 'react-dropzone';

const W3CWebSocket = require('websocket').w3cwebsocket;

const useStyles = makeStyles((theme) => ({
  mainContent: {
    backgroundColor: '#f3f3f4',
    height: "100%",
    width: "100%",
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  workerForm: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexShrink: 1
  },
  customTableContainer: {
    overflowX: "initial"
  },
  requiredFields: {
    fontSize: '12px',
    color: "red"
  }
}));

let client = null

export default function BulkUploadWorkers(props) {

  const classes = useStyles();
  const { currentEmployee, company, companies, companyWorkersDomain, bulkUploadWorkersWebsocketUrl, fileUploadEndpoint, sessionResultsEndpoint, onBulkUploadWorkers } = props;
  const defaultFileState = {
    blob: null, url: ''
  }

  const [state, setState] = useState({
    currentCompany: {},
    uploading: false,
    processing: false,
    doneProcessing: false,
    uploadUrl: '',
    workers: [],
    progress: 0,
    timer: 0,
  });

  const workersRef = useRef(state.workers)
  workersRef.current = state.workers

  const [file, setFile] = useState(defaultFileState)

  const onCompanyChange = e => {
    const tempCompany = companies.find(item => get(item, 'value') === e)

    setState(prevState => ({
      ...prevState,
      currentCompany: tempCompany,
      uploadUrl: '',
      sessionId: '',
      triggetInitUploadUrl: !prevState.triggetInitUploadUrl
    }))
  }

  const onDrop = (acceptedFiles, rejectedFiles) => {
      if (!isEmpty(acceptedFiles)) {
        const file = get( acceptedFiles, 0)
        const reader = new FileReader();

        reader.readAsDataURL(file)
        reader.onloadend = () => {
          setFile({ url: reader.result, blob: file })

          setState(prevState => ({
            ...prevState,
            doneProcessing: false,
            workers: []
          }))
        }
      }
  }

  const initUploadUrl = async () => {
    try {
       const res = await getUploadUrl(fileUploadEndpoint, size(companies) < 2 ? get(company, 'value') : get(state, 'currentCompany.value'));

       setState(prevState => ({
         ...prevState,
         uploadUrl: get(res, 'data.url'),
         sessionId: get(res, 'data.sessionId')
       }))
     } catch (error) {
       // console.log('xx get upload url catch error: ', error);
       setState(prevState => ({
         ...prevState,
         uploadUrl: '',
         sessionId: ''
       }))

       notificationCenter('error', get(error.response, 'data.message'));
       notificationCenter('error', 'Failed to generate upload URL. Please close and try again.');
     }
   };

  const onUpload =  async () => {
    setState(prevState => ({
      ...prevState,
      uploading: true,
      workers: [],
      disableInputs: true
    }))

    try {
      let options = {
        headers: { 'Content-Type': file.blob.type }
      }

      const res = await uploadEmployeesCsv(state.uploadUrl, file.blob, options);

      setState(prevState => ({
        ...prevState,
        uploading: false,
        processing: true
      }))

      notificationCenter('success', 'File uploaded successfully.')
    } catch (error) {
      // console.log('xx uploadEmployeesCsv catch error: ', error);

      setState(prevState => ({
        ...prevState,
        uploading: false,
        processing: false,
      }))

      notificationCenter('error', get(error.response, 'data.message'));
    }
  }

  const openWebSocketConnection = () => {
    client = new W3CWebSocket(`${bulkUploadWorkersWebsocketUrl}?userId=${get(currentEmployee, '_id.$oid')}&companyId=${get(company, 'value')}&token=${document.querySelector("input[name='aws_token']").value}`);
    // client = new W3CWebSocket(`${bulkUploadWorkersWebsocketUrl}?userId=${get(currentEmployee, '_id.$oid')}&companyId=${get(company, 'value')}&token=fde63d70524b7cf83b28a0a936f44dcce0f00750212e102d36a281864648536134b4fd6c364e4bae1a7d55fcb0266fea85f5568ae086e44987f1d4c12a6b9ef9`);

    client.onopen = (e) => {
      // console.log("xx OPEN WEBSOCKET", e);
      initUploadUrl()
    };

    client.onclose = (e) => {
      // console.log("xx ONCLOSE WEBSOCKET", e);
      client = null;
    };

    client.onmessage = (e) => {
      // console.log("xx ON MESSAGE WEBSOCKET", e)
      const res = JSON.parse(get(e, "data"));

      switch (get(res, 'event')) {

        case 'importEmployeeError':

          setState(prevState => ({
            ...prevState,
            uploading: false,
            processing: false,
            doneProcessing: true
          }))

          notificationCenter('error', get( get( res, 'data', {}), 'message', 'Error importing employees.'))

          client.close()

          break;

        case 'importEmployeeStarted':

          setState(prevState => ({
            ...prevState,
            batchSize: get(res, 'data.expected', prevState.batchSize)
          }))

          break;

        case 'importEmployeeResult':

          let auxObj = {
            ...get(res, 'data.employee'),
            reason: get(res, 'data.reason', ''),
            processed: get(res, 'data.processed', false),
          }

          setState(prevState => {
            let temp = [...prevState.workers]

            temp[get(res, 'data.index')] = auxObj

            return ({
              ...prevState,
              workers: temp,
              progress: prevState.progress + 1,
            })
          })

          break;

        default:
          break;
      }
    };
  };

  useEffect(() => {
    if(get(state, 'currentCompany.value', null) || size(companies) < 2) {
      openWebSocketConnection()
    }
  }, [state.currentCompany])

  useEffect(() => {
    if (state.triggetInitUploadUrl !== undefined) {
      initUploadUrl()
    }
  }, [state.triggetInitUploadUrl])

  useEffect(() => {
    if (isEqual(size(state.workers), get(state, 'batchSize'))) {
      setState(prevState => ({
        ...prevState,
        processing: false,
        doneProcessing: true
      }))
    }
  }, [state.workers])

  useEffect(() => {
    setTimeout(() => {
      if(state.processing && isEmpty(workersRef.current)) {
        setState(prevState => ({
          ...prevState,
          processing: false,
          doneProcessing: true
        }))
        client.close()
        notificationCenter('error', 'Websocket messages timeout. Please close and try again.')
      }
    }, 15000);
  }, [state.processing])

  const downloadData = async () => {
    try {
      const res = await downloadSessionResults(sessionResultsEndpoint, get(state, 'sessionId'));
      const resData = get(res, 'data')

      // download logic
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/csv,' + encodeURIComponent(resData));
      element.setAttribute('download', 'session_results.csv');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      // console.log('xx downloadSessionResults catch error: ', error);
      notificationCenter('error', get(error.response, 'data.message'));
    }
  }

  const handleCloseDrawer = () => {
    if (!isNull(client) && client.close()) client.close()

    onBulkUploadWorkers(false);
  }

  const getMaxHeight = () => {
    return Math.floor( get( document.getElementById('mainContentDiv'), 'offsetHeight', 0) - 350);
  }

  return (
    <div id="mainContentDiv" className={classes.mainContent}>
      <div className={classes.workerForm}>
        <div className="worker-form-information" style={{ marginBottom: 20, flexShrink: 1 }}>
          <div style={{ display:"flex", flex: 1, justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px'}}>
            <h3 style={{ margin: 0, display:'inline-block' }}>Bulk Upload Workers   </h3>
            <h6 style={{ margin: 0, display:'inline-block' }}>
              <Link style={{ textAlign: 'center' }} underline='none' href="https://static-files.connectedbarrel.com/templates/WorkersUploadTemplate.csv"><div>Download <br/> Template</div></Link>
            </h6>
          </div>

          { size(companies) > 1 &&
              <FormControl fullWidth>
                <InputLabel id="company-select-label">Company</InputLabel>
                <Select
                  disabled={get(state, 'disableInputs', false)}
                  labelId="company-select-label"
                  displayEmpty={true}
                  value={get(state, 'currentCompany.value', '')}
                  onChange={(e) => onCompanyChange(e.target.value)}>
                    {companies.map(item => <MenuItem key={get(item, 'value')} value={get(item, 'value')}>{get(item, 'label')}</MenuItem>)}
                </Select>
              </FormControl>
          }

          <div style={{ marginTop: 20, flexShrink: 1}}>
            <Dropzone accept="application/csvm+json,text/csv" onDrop={onDrop} disabled={get(state, 'processing') || get(state, 'doneProcessing') || (!get(state, 'currentCompany.value') && size(companies) > 1)}>
              {({ getRootProps, getInputProps }) => (
                  <section>
                      <div {...getRootProps({ className: (get(state, 'currentCompany.value') || size(companies) < 2) && !get(state, 'disableInputs') ? 'csvDropzoneActive' : 'csvDropzoneDisabled' })}>
                          <input {...getInputProps()} accept="application/csvm+json,text/csv,.csv" />
                          { !isEmpty( get( file.blob, 'path', '')) ?
                              <div><strong>File name:</strong> {get(file.blob, 'path', '')} </div> :
                                !get(state, 'currentCompany.value') && size(companies) > 1 ? <strong>Select a company</strong> :
                                  <div style={{ textAlign: 'center' }}>
                                    { size(companies) < 2 && <strong>{get(company, 'label')} <br/></strong> }
                                    Drag or click to select .csv file
                                  </div>
                          }
                      </div>
                  </section>
              )}
            </Dropzone>
          </div>

          { !isEmpty(state.workers) &&
            <div style={{ marginTop: 20, flexShrink: 1, maxHeight: getMaxHeight(), overflow: 'scroll' }}>
              <TableContainer classes={{ root: classes.customTableContainer}}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="center">Phone Number</TableCell>
                      <TableCell align="center">Processed</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.workers.map((row, idx) => {
                      if (get(row, 'first_name')) {
                        return (
                          <TableRow key={idx}>
                            <TableCell component="th" scope="row">
                              {`${get(row, 'first_name')} ${get(row, 'last_name')}`}
                            </TableCell>
                            <TableCell align="center">{get(row, 'phone_number')}</TableCell>
                            <TableCell align="center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{get(row, 'processed') ? <Done style={{ color: "#52b788" }} /> : <Tooltip title={get(row, 'reason', '')}><ErrorOutlineIcon style={{ color: "#d62828" }} /></Tooltip>}</TableCell>
                          </TableRow>
                        )
                      }
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          }
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
          <Button color={'default'} variant="outlined" onClick={handleCloseDrawer}>
            Close
          </Button>
          <Button
            variant="outlined"
            disabled={isEmpty( get( state, 'uploadUrl')) || isNull( get( file, 'blob')) || get(state, 'processing') || get(state, 'uploading')}
            style={{ color: isEmpty( get( file.blob, 'path', '')) ? '#b4b4b5' : '#36C240', borderColor: isEmpty( get( file.blob, 'path', '')) ? '#b4b4b5' : '#36C240', width: 160, marginLeft: 8 }}
            onClick={get(state, 'doneProcessing') ? downloadData : onUpload}
            // startIcon={isEmpty( get( file.blob, 'path', '')) ? null : state.uploading || state.processing ? <CircularProgress style={{ color: '#009687' }} size={12} /> : <Backup />}
            startIcon={state.uploading || state.processing ? <CircularProgress size={12} style={{ color: '#36C240' }} /> : null}>
            {get(state, 'uploading') ? 'Uploading' : get(state, 'processing') ? `${get(state, 'batchSize') ? `${get(state, 'progress')}/${get(state, 'batchSize')}` : `Processing`}` : get(state, 'doneProcessing') ? 'Download Results' : 'Upload'}
          </Button>
        </div>
      </div>


    </div>
  )
};
