import React, { useEffect, useState, useContext } from 'react';
import { gettingCompanyProfiles, deleteCompanyProfiles } from '../../services/profileServices';
import * as _ from 'lodash';
import { WorkerTablePagination } from '../../../employees/projectEmployees/components';
import { Drawer, IconButton, lighten, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Toolbar, Typography } from '@material-ui/core';
import { Add, Edit, Done, Close, Delete } from '@material-ui/icons';
import { allColumns } from '../../constants/tableHeaders'
import { ProfileComponent, EditDefault } from '../index'
import { SettingsContext } from '../../../employees/contexts/SettingsContext';

const EnhancedTableToolbar = (props) => {

  const { onProfile } = props;

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    highlight:
      theme.palette.type === "light"
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
    title: {
      flex: "1 1 100%"
    }
  }));

  const classes = useToolbarStyles();

  return (
    <Toolbar>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Profiles
      </Typography>
      <IconButton aria-label="delete" onClick={() => onProfile()} >
        <Add />
      </IconButton>
    </Toolbar>
  );
};

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort, columns } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align="left"
            padding={column.disablePadding ? "none" : "default"}
            sortDirection={orderBy === column.id ? order : false}
          >
            {column.sortOption ?
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : "asc"}
                onClick={createSortHandler(column.id)}
              >
                {column.label}
                {orderBy === column.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel> :
              column.label
            }

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};


export default function TableComponent(props) {

  const settingsContext = useContext(SettingsContext)

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%"
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 750
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1
    },
    drawerRoot: {
      width: `${settingsContext.isMobile ? '75%' : '40%'}`
    }
  }));

  const { company, onLoading, companies, currentCompany, payment_profile_endpoint } = props;

  const classes = useStyles();

  const [state, setState] = useState({
    profiles: [],
    order: "asc",
    orderBy: '',
    addProfile: {
      status: false,
      profile: null
    },
    pagination: {
      page: 0,
      rowsPerPage: 25
    }
  });

  useEffect(() => {
    let payload = {
      pagination: {
        ...state.pagination
      }
    }
    getProfiles(payload, _.get(currentCompany, 'value'));
  }, [currentCompany])



  const getProfiles = async (payload, companyId = _.get(company, '_id.$oid')) => {
    try {
      onLoading()

      const res = await gettingCompanyProfiles(companyId, payload, payment_profile_endpoint);
      setState(prevState => ({
        ...prevState,
        profiles: _.get(res, 'data', [])
      }))

      onLoading(false)
    } catch (error) {

    }
  }

  useEffect(() => {

    let payload = {
      pagination: {
        ...state.pagination
      }
    }
    getProfiles(payload);
  }, []);

  const handleChangePage = (step) => {

    let page = 0;
    if (step === 'next') {
      page = pagination.page + 1;
    } else {
      page = pagination.page - 1;
    }

    setPagination(prevState => ({
      ...prevState,
      page
    }))

    let payload = {
      filters: sendingFilters,
      pagination: {
        rowsPerPage: pagination.rowsPerPage,
        page
      }
    }

    // getWorkers(payload)
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";

    setState(prevState => ({
      ...prevState,
      order: isAsc ? "desc" : "asc",
      orderBy: property
    }))

    let payload = {
      sort: {
        property,
        isAsc
      }
    }

    // getWorkers(payload);
  };

  const handleChangeRowsPerPage = (e) => {

    setState(prevState => ({
      ...prevState,
      page: 0,
      rowsPerPage: e.target.value
    }))

  };

  const onProfile = (profile = null) => {
    setState(prevState => ({
      ...prevState,
      addProfile: {
        status: true,
        profile
      }
    }))
  }

  const onCloseProfile = (flag) => {
    setState(prevState => ({
      ...prevState,
      addProfile: {
        status: false,
        profile: null
      }
    }))

    if (_.isBoolean(flag) && flag) {
      let payload = {
        pagination: {
          ...state.pagination
        }
      }
      getProfiles(payload, _.get(currentCompany, 'value'));
    }

  }

  const onEditDefault = () => {
    let payload = {
      pagination: {
        ...state.pagination
      }
    }
    getProfiles(payload);
  }

  const onDeleteProfile = async (profile) => {
    try {
      onLoading();
      await deleteCompanyProfiles(_.get(profile, 'company_id'), _.get(profile, '_id'), payment_profile_endpoint)
      let payload = {
        pagination: {
          ...state.pagination
        }
      }
      getProfiles(payload, _.get(currentCompany, 'value'));
    } catch (error) {

    }
  }

  return (
    <>
      {/* <TableFilters filters={filters} onFilter={onFilter} onSendingFilters={onSendingFilters} onClearFilters={onClearFilters} /> */}
      <WorkerTablePagination
        pagination={state.pagination}
        onChangePage={handleChangePage}
        hasNext={_.size(state.profiles) === state.pagination.rowsPerPage}
        hasWorkers={_.size(state.profiles) > 0}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Paper className={classes.paper}>

        <EnhancedTableToolbar onProfile={onProfile} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            style={{ position: 'relative' }}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={state.order}
              orderBy={state.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={state.profiles.length}
              columns={allColumns}
            />
            <TableBody>
              {state.profiles.map((row, index) => {
                return (
                  <TableRow key={_.get(row, '_id')}>
                    <TableCell align="left">
                      <EditDefault profile={row} onLoading={onLoading} onEditDefault={onEditDefault} payment_profile_endpoint={payment_profile_endpoint} />
                    </TableCell>
                    <TableCell align="left">{_.get(row, 'name', '')}</TableCell>
                    <TableCell align="left">{_.get(row, 'CompanyInfo.name', '')}</TableCell>
                    <TableCell align="left">{_.get(row, 'breakout', '')}</TableCell>
                    <TableCell align="left">
                      {_.isNumber(_.get(row, 'regular')) ? _.get(row, 'regular', 'N/A') : _.get(row, 'regular', false) ? <Done /> : <Close />}
                    </TableCell>
                    <TableCell align="left">
                      {_.isNumber(_.get(row, 'overtime')) ? _.get(row, 'overtime') : _.get(row, 'overtime', false) ? <Done /> : <Close />}
                    </TableCell>
                    <TableCell align="left">
                      {_.isNumber(_.get(row, 'doubletime')) ? _.get(row, 'doubletime', 'N/A') : _.get(row, 'doubletime', false) ? <Done /> : <Close />}
                    </TableCell>
                    <TableCell align="left">
                      <Edit onClick={() => onProfile(row)} className='mr-2' style={{ cursor: 'pointer' }} />
                      <Delete onClick={() => onDeleteProfile(row)} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <WorkerTablePagination
          pagination={state.pagination}
          onChangePage={handleChangePage}
          hasNext={_.size(state.profiles) === state.pagination.rowsPerPage}
          hasWorkers={_.size(state.profiles) > 0}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Drawer disableBackdropClick={true} classes={{ paper: classes.drawerRoot }} anchor={'right'} open={state.addProfile.status} onClose={onCloseProfile}>
        <ProfileComponent
          profile={state.addProfile.profile}
          onCloseProfile={onCloseProfile}
          companies={companies}
          company={company}
          payment_profile_endpoint={payment_profile_endpoint}
        />
      </Drawer>

    </>
  )
};