import React from 'react';
import { get } from 'lodash';
import { rowsPerPage } from '../../../constants/globalConstants'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { SkipNext, SkipPrevious } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  formControlRows: {
    width: '100%'
  },
  inputLabelRows: {
    marginRight: '10px',
    color: 'black'
  },
  disabledStep: {
    color: '#bbbbbb',
    pointerEvents: 'none'
  },
  steps: {
    fontSize: '30px',
    marginRight: '10px',
    cursor: 'pointer'
  }
}));

export default function WorkerTablePagination(props) {

  const { pagination, onChangePage, onChangeRowsPerPage, hasWorkers, hasNext } = props;

  const paginationClasses = useStyles();

  const pageCounter = pagination.page + 1;

  return (
    <div style={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '20px' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
        <InputLabel className={paginationClasses.inputLabelRows}>Rows per page</InputLabel>
        <FormControl>
          <Select
            id="demo-simple-select"
            value={get(pagination, 'rowsPerPage')}
            onChange={onChangeRowsPerPage}
          >
            {rowsPerPage.map((item, idx) => (
              <MenuItem key={idx} value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SkipPrevious onClick={() => onChangePage('previous')} className={`${paginationClasses.steps} ${get(pagination, 'page', 0) === 0 ? paginationClasses.disabledStep : ''}`} />
        <span style={{ marginRight: '10px' }}>{pageCounter}</span>
        <SkipNext onClick={() => onChangePage('next')} className={`${paginationClasses.steps} ${!hasNext ? paginationClasses.disabledStep : ''}`} />
      </div>
    </div>
  )
};
