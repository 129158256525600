import axios from "axios";
import { axiosConfig } from "../../helpers";

export const onBoardWorker = (workerState, config) => {
  const url = `${config.onboarding_webhook_url}/create`;

  const payload = {
    first_name: workerState.first_name,
    last_name: workerState.last_name,
    synthetic_phone: workerState.synthetic_phone,
    phone_number: workerState.phone_number,
    decal: workerState.decal,
    language_code: workerState.language_code,
    email: workerState.email,
    // needs_safety: workerState.needs_safety,
    safety_process: workerState.safety_process,
    company_ids: workerState.company_ids,
    project_id: workerState.project_id,
    worker_level: workerState.worker_level
  }
  if (payload.synthetic_phone) {
    delete payload.phone_number;
  } else {
    delete payload.synthetic_phone;
  }

  // if (!workerState.needs_safety) {
  //   payload.safety_process = null;
  // }

  return axios.post(url, payload, axiosConfig());
}

export const patchSafetyInfo = (workerState, id, config) => {
  const url = config.onboarding_webhook_url + "/update/" + id;
  const payload = {
    address: workerState.address,
    emergency_name: workerState.emergency_name,
    emergency_phone_number: workerState.emergency_phone_number,
    emergency_email: workerState.emergency_email,
    car_make: workerState.car_make,
    car_model: workerState.car_model,
    car_color: workerState.car_color,
    car_tag: workerState.car_tag,
    safety_process: workerState.safety_process,
    worker_level: workerState.worker_level
  }
  if (payload.synthetic_phone) {
    delete payload.phone_number;
  } else {
    delete payload.synthetic_phone;
  }

  // if (!workerState.safety_process) {
  //   payload.safety_process = null;
  // }

  return axios.patch(url, payload, axiosConfig());
}
