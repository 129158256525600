import React, { useState } from 'react'
import Axios from 'axios'
import { Button, Modal, Form } from "react-bootstrap";
import DatePicker from "./DatePicker";

export default function CertificationActions({ baseUrl, dbid, cert, onDelete }) {

    const DATE_FORMAT = "MM/DD/YYYY"
    const [showModal, setShowModal] = useState(false)
    const [certState, setCertState] = useState(cert);
    const [expirationDate, setExpirationDate] = useState(cert.expiration_date ? moment(cert.expiration_date).format(DATE_FORMAT) : '')
    const [issueDate, setIssueDate] = useState(cert.issue_date ? moment(cert.issue_date).format(DATE_FORMAT) : '')
    const [isPublic, setIsPublic] = useState(cert.is_public)

    const remove = (ev) => {
        ev.preventDefault()
        const url = `${baseUrl}/workers/${dbid}/certifications/${cert._id}`
        const confirmed = confirm("Are you sure?")

        if (confirmed) {
            Axios.delete(url, axiosConfig()).then(response => {
                onDelete(true)
            })
        }

    }

    const openModal = (ev) => {
        ev.preventDefault()
        setShowModal(true)
    }

    const handleClose = () => setShowModal(false)

    const handleChange = ev => {
        cert[ev.target.name] = ev.target.value
        setCertState({ ...cert })
    }

    const handleTagsChange = newTags => {
        console.log({ newTags });
        setTags([...newTags])
    }
    const handleIsPublicChange = ev => {
        console.log({ ev });

        const val = ev.target.value == "true"
        setIsPublic(val);
    }

    const handleIssueChange = newDate => {
        const fmt = newDate.format(DATE_FORMAT)
        setIssueDate(fmt)
    }

    const handleExpirationChange = newExpiration => {
        const fmt = newExpiration.format("MM/DD/YYYY")
        setExpirationDate(fmt)
    }

    const axiosConfig = () => {
        const token = document.querySelector("input[name='aws_token'").value
        return { headers: { Authorization: `Bearer ${token}` } }
    }

    const save = () => {
        const confirmed = confirm("Are you sure?")
        const url = `${baseUrl}/workers/${dbid}/certifications/${cert._id}`
        const data = {
            "is_public": isPublic,
            "expiration_date": expirationDate,
            "issue_date": issueDate
        }

        if (confirmed) {
            Axios.put(url, data, axiosConfig()).then(response => {
                onDelete(true)
                handleClose()
            }).catch(console.error)
        }
    }

    const editModal = (
        <>
            <Modal show={showModal} onHide={handleClose} size="lg" animation={false} backdrop={true} backdropClassName="modal-backdrop fade in">
                <Modal.Header closeButton>
                    <Modal.Title>Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <fieldset className="form-group form-group-lg">
                            <label>Issue Date</label>
                            <DatePicker value={issueDate} onChange={handleIssueChange} />
                        </fieldset>
                        <fieldset className="form-group form-group-lg">
                            <label>Expiration Date</label>
                            <DatePicker value={expirationDate} onChange={handleExpirationChange} />
                        </fieldset>
                        <Form.Group>
                            <Form.Label>Is Public</Form.Label>
                            <Form.Check inline checked={isPublic} value={true} id="public_true" label="Public" type="radio" onChange={handleIsPublicChange} />
                            <Form.Check inline checked={!isPublic} value={false} id="public_false" label="Private" type="radio" onChange={handleIsPublicChange} />
                        </Form.Group>
                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm btn-danger" onClick={save}>Save</button>
                    <button className="btn btn-sm btn-default" onClick={handleClose}>Cancel</button>
                </Modal.Footer>
            </Modal>

        </>

    )

    let modalInterpl = <span />

    if (showModal) {
        modalInterpl = editModal
    }

    return (
        <>
            <td>
                <a href={cert.file_url} className="fas fa-download"> </a>
            </td>

            <td>
                {modalInterpl}
                <a onClick={openModal} className="fas fa-edit"></a>
            </td>

            <td>
                <a onClick={remove} className="fas fa-trash"> </a>
            </td>
        </>
    )
}
