import React from "react";
import { Card, CardTitle } from "reactstrap";

export default function Widget({ children, styleName, title, icon, logo, titleStyle }) {
    return (
        <Card className={`jr-card jr-card-widget ${styleName}`}>
            {title ? <CardTitle className={titleStyle}> {logo ? <img width='200' src={logo} alt="logo" /> : null} {icon ? <i className={`fa ${icon} m`} /> : null} {title}</CardTitle> : null}
            {children}
        </Card>
    )
};