import React, { useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { get } from 'lodash';
import { SearchBox, TopPageMenu } from '../index';
import { SettingsContext } from '../../../contexts/SettingsContext';

export default function TopHeaderPage(props) {

  const { company, project, onShowCharts, onOnboardWorker, onShowBulkUploadProjectWorkers, permissions } = props;
  const settingsContext = useContext(SettingsContext);

  const useStyles = makeStyles((theme) => ({
    activeBreadcrumbs: {
      marginBottom: '0px !important'
    },
    rightHeaderWrapper: {
      display: 'flex',
      alignItems: 'center'
    }
  }));

  const classes = useStyles();

  return (
    <div className='MuiPaper-root makeStyles-paper MuiPaper-elevation1 MuiPaper-rounded' style={{ padding: 12, marginBottom: 16}}>
      <div className="headerWidget">
        <Breadcrumbs maxItems={get(settingsContext, 'isMobile') ? 0 : 8} className="pageInfoBreadcrumbs" aria-label="breadcrumb">
          <Link color="inherit" href={`/companies/${get(company, '_id.$oid', '').toString()}/dashboard`} >
            <span>{get(company, 'name')}</span>
          </Link>
          <Link color="inherit" href={`/projects/${get(project, '_id.$oid', '')}`}>
            <span>{get(project, 'name')}</span>
          </Link>
          <Typography className={classes.activeBreadcrumbs} color="textPrimary">Project Workers</Typography>
        </Breadcrumbs>
        <div className={classes.rightHeaderWrapper}>
          <SearchBox onSearchChange={settingsContext.onSearchChange} value={settingsContext.searchValue} onSearch={settingsContext.onSearch} />
          <TopPageMenu project={project} company={company} onShowCharts={onShowCharts} onShowBulkUploadProjectWorkers={onShowBulkUploadProjectWorkers} permissions={permissions}/>
        </div>
      </div>
    </div>
  )
};
