import React from 'react';
import { makeStyles } from '@material-ui/core';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
export default function HolidaysPicker(props) {

  const { pickerTitle, selectedDays, handleDayClick } = props;

  const useStyles = makeStyles((theme) => ({
    dayPicker: {
      fontSize: '1.3rem'
    }
  }));

  const modifiersStyles = {
    selected: {
      backgroundColor: '#f15922',
    },
  };

  const classes = useStyles();


  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <strong>{pickerTitle}</strong>
      <DayPicker
        format="M/D/YYYY"
        selectedDays={selectedDays}
        className={classes.dayPicker}
        modifiersStyles={modifiersStyles}
        canChangeMonth={true}
        onDayClick={handleDayClick}
      />
    </div>
  )
};