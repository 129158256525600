import React, { useState, useEffect, useContext, useRef } from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { SettingsContext } from '../../../contexts/SettingsContext';
import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Toolbar,
  Paper,
  IconButton,
  Typography,
} from "@material-ui/core"
import { Add, Edit } from '@material-ui/icons';
import { allColumns, defaultColumns } from '../../../constants/workerTableColumns'
import { editWorkerRate, gettingFilters, gettingWorkers, updatingWorker } from '../../../services/employeeService';
import { get, uniqBy, has, isNull, isArray, size, cloneDeep, set } from 'lodash';
import { AddingColumns, PunchDetails } from '../index'
import {
  EnhancedTableHead,
  TableFilters,
  RenderingTableRow,
  WorkerTablePagination
} from '../index';

import Wizard from '../../../../workers/onboard/Wizard';
import { notificationCenter } from '../../../../helpers/notifications'

const W3CWebSocket = require('websocket').w3cwebsocket;

let client = null

const EnhancedTableToolbar = (props) => {

  const { onChangeAddColumns } = props;

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    highlight:
      theme.palette.type === "light"
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
    title: {
      flex: "1 1 100%"
    }
  }));

  const classes = useToolbarStyles();

  return (
    <Toolbar>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Workers
      </Typography>
      <IconButton aria-label="delete" onClick={onChangeAddColumns}>
        <Add />
      </IconButton>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

export default function TableWorkers(props) {

  const { projectDomain, currentEmployee, bulkUploadWorkersWebsocketUrl, companyWorkersDomain, images_path, project, onLoading, company, config, filters, allColumns, defaultColumns, employee_settings_endpoint, permissions } = props;

  let settingsContext = useContext(SettingsContext);

  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 25
  });
  const [columns, setColumns] = useState(defaultColumns);
  const [totalColumns, setTotalColumns] = useState(allColumns);
  const [workers, setWorkers] = useState([]);
  const [addColumns, setAddColumns] = useState(false);
  const [sendingFilters, setSendingFilters] = useState([]);
  const [addWorkerCompanies, setAddWorkerCompanies] = useState([]);
  const [addWorker, setAddWorker] = useState(false);
  const [workerToOnBoard, setWorkerToOnBoard] = useState(null);
  const [downloadButtonText, setDownloadButtonText] = useState('Download')

  const getWorkers = async (payload, blockPage = true) => {

    try {
      if (blockPage) onLoading();
      const res = await gettingWorkers(payload, projectDomain);
      onLoading(false)
      setWorkers(get(res, 'data', []));
    } catch (error) {
      onLoading(false)
      notificationCenter("error", get(error, 'response.data.error', 'Error'))
    }

  }

  const onSearch = () => {
    let payload = {
      pagination: {
        ...pagination
      },
      filters: sendingFilters,
      sort: {
        property: orderBy,
        isAsc: order === "asc"
      }
    }
    getWorkers(payload, false)
  }

  useEffect(() => {

    let payload = {
      pagination
    }
    getWorkers(payload);


  }, [])

  const onDownloadingData = () => {
    let payload = {
      requestType: "FILE_DOWNLOAD",
      filters: sendingFilters,
      sort: {
        property: orderBy,
        isAsc: order === "asc"
      }
    }

    setDownloadButtonText('Connecting')
    openWebSocketConnection(payload)
  }

  const openWebSocketConnection = (payload) => {

    client = new W3CWebSocket(`${bulkUploadWorkersWebsocketUrl}?userId=${get(currentEmployee, '_id.$oid')}&companyId=${get(company, 'value')}&token=${document.querySelector("input[name='aws_token']").value}`);
    // client = new W3CWebSocket(`${bulkUploadWorkersWebsocketUrl}?userId=${get(currentEmployee, '_id.$oid')}&companyId=${get(company, 'value')}&token=4fc9ab24b5555c8b4a99008da711ebdd6ebfbdb6727a29e12f1ac54c1820718ed48adea1d3525fb28eee0bc5d29d3225406c23bcff73c4f82b74993ac0c1e635`);

    client.onerror = (e) => {
      // console.log('websocket error: ', e);
      notificationCenter('error', "Websocket error.");

      setDownloadButtonText('Download');
    }

    client.onopen = (e) => {
      // notificationCenter('success', "Websocket connected.");
      // console.log("OPEN WEBSOCKET", e);

      setDownloadButtonText('Requesting');

      // NOTE: here we can have switch for different download requests
      requestDownload(payload);
    };

    client.onclose = (e) => {
      // console.log("ONCLOSE WEBSOCKET", e);
      client = null;
      // notificationCenter('success', 'Websocket connection closed.')
    };

    client.onmessage = (e) => {
      // console.log("ON MESSAGE WEBSOCKET", e)
      const res = JSON.parse(get(e, "data"));

      switch (get(res, 'event')) {

        case 'ExportWorkers':
          notificationCenter('success', 'File ready for download.')

          // download logic
          var element = document.createElement('a');
          element.setAttribute('href', res.data.downloadUrl);
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);

          // close client and reset button text
          client.close()
          setDownloadButtonText('Download')

          break;

        case 'ExportWorkersError':

          // notificationCenter('error', 'Error generating file.')
          notificationCenter('error', res.data.error)

          // close client and reset button text
          client.close()
          setDownloadButtonText('Download')

          break;

        default:
          break;
      }
    };
  };

  const requestDownload = async (payload) => {
    try {
      const res = await gettingWorkers(payload, projectDomain);
      // console.log('res: ', res);
      setDownloadButtonText('Processing');
      // notificationCenter('success', 'Processing export.');
    } catch (error) {
      // console.log('request download error: ', error);
      setDownloadButtonText('Download');

      notificationCenter('error', _.get(error.response, 'data.error', 'Error requesting download.'));
      client.close();
    }
  }

  useEffect(() => {

    if (settingsContext.search) onSearch();

    if (settingsContext.searchValue.length > 1) {
      onFilter('search', settingsContext.searchValue);
    } else if (settingsContext.searchValue.length === 0 && settingsContext.searchEnable) {

      setSendingFilters(sendingFilters.filter(item => _.get(item, 'key') !== 'search'))


      let payload = {
        pagination: {
          ...pagination
        },
        filters: sendingFilters.filter(item => _.get(item, 'key') !== 'search'),
        sort: {
          property: orderBy,
          isAsc: order === "asc"
        }
      }

      getWorkers(payload, false)
    }

  }, [settingsContext.search, settingsContext.searchValue])


  useEffect(() => {
    if (settingsContext.addWorker.status && settingsContext.addWorker.resource === 'global') {
      let companyFilter = filters.find(item => get(item, 'key') === 'company');
      setAddWorkerCompanies(get(companyFilter, 'data', []));
      setAddWorker(!addWorker);
    } else {
      setAddWorker(false);
      setAddWorkerCompanies([]);
    }

  }, [settingsContext.addWorker.status]);

  const handleRequestSort = (event, property) => {

    setOrder(order === "asc" ? "desc" : "asc");
    setOrderBy(property)


    let payload = {
      pagination: {
        ...pagination
      },
      filters: sendingFilters,
      sort: {
        property,
        isAsc: order === "asc" ? false : true
      }
    }

    getWorkers(payload);
  };


  const handleChangePage = (step) => {

    let page = 0;
    if (step === 'next') {
      page = pagination.page + 1;
    } else {
      page = pagination.page - 1;
    }

    setPagination(prevState => ({
      ...prevState,
      page
    }))

    let payload = {
      filters: sendingFilters,
      pagination: {
        rowsPerPage: pagination.rowsPerPage,
        page
      },
      sort: {
        property: orderBy,
        isAsc: order === "asc"
      }
    }

    getWorkers(payload)
  };

  const handleChangeRowsPerPage = (e) => {

    // console.log(e.target.value)
    setPagination({
      page: 0,
      rowsPerPage: e.target.value
    });

    let payload = {
      filters: sendingFilters,
      pagination: {
        rowsPerPage: e.target.value,
        page: 0
      }
    }
    getWorkers(payload);
  };

  const onChangeAddColumns = () => {
    setAddColumns(!addColumns);
  }

  const onUpdateColumns = (tempColumns) => {
    // console.log(tempColumns)
    setTotalColumns(tempColumns);
    setColumns(tempColumns.filter(item => get(item, 'isActive')));
    setAddColumns(false);
  }

  const onFilter = (key, data) => {

    let aux = [
      {
        key,
        value: isArray(data) ? data.map(item => get(item, 'value')) : data
      },
      ...sendingFilters
    ];

    setSendingFilters(uniqBy(aux, 'key'))
  }

  const onSendingFilters = () => {
    let payload = {
      filters: sendingFilters,
      pagination: {
        rowsPerPage: pagination.rowsPerPage,
        page: 0
      }
    }

    setPagination(prevState => ({
      ...prevState,
      page: 0
    }));

    onLoading();
    getWorkers(payload)
  }

  const onClearFilters = () => {
    setSendingFilters([]);
    setPagination(prevState => ({
      ...prevState,
      page: 0
    }));

    let payload = {
      pagination: {
        rowsPerPage: pagination.rowsPerPage,
        page: 0
      }
    }
    getWorkers(payload);
  }

  const onWorkerEdited = () => {
    let payload = {
      pagination: {
        rowsPerPage: pagination.rowsPerPage,
        page: pagination.page
      }
    }

    getWorkers(payload);
  }

  const onHandleRevokeClose = () => {
    setAddWorkerCompanies([])
    setWorkerToOnBoard(null);
    settingsContext.onAddWorker();
    let payload = {
      pagination: {
        rowsPerPage: pagination.rowsPerPage,
        page: 0
      }
    };

    setPagination(prevState => ({
      ...prevState,
      page: 0
    }));

    getWorkers(payload);

  }

  const onWorkerToOnBoard = (worker) => {
    let tempWorker = cloneDeep(worker);
    tempWorker['company'] = {
      ...worker.company,
      _id: {
        $oid: worker.company._id
      }
    }

    setWorkerToOnBoard(tempWorker);
    setAddWorker(!addWorker);

  }

  const onChangeWorkerAccess = async (worker) => {
    try {
      onLoading(true);
      const res = await updatingWorker(worker, projectDomain);
      onWorkerEdited();
    } catch (error) {
      notificationCenter('error', get(error, 'response.data.error', 'Error'));

    }
  }

  const onEditRate = async (hire_rate, workerId) => {
    try {
      onLoading(true);
      // console.log(companyWorkersDomain, get(project, '_id.$oid'))
      const res = await editWorkerRate(companyWorkersDomain, { hire_rate }, workerId, get(project, '_id.$oid'));
      let payload = {
        pagination
      }
      getWorkers(payload);
    } catch (error) {
      // console.log(error.response)
      onLoading(false)
    }

  };

  const onEditPerDiemRate = async (per_diem_rate, workerId) => {
    try {
      onLoading(true);
      // console.log(companyWorkersDomain, get(project, '_id.$oid'))
      const res = await editWorkerRate(companyWorkersDomain, { per_diem_rate }, workerId, get(project, '_id.$oid'));
      let payload = {
        pagination
      }
      getWorkers(payload);
    } catch (error) {
      // console.log(error.response)
      onLoading(false)
    }

  }

  return (
    <div className={classes.root}>
      <TableFilters filters={filters} onFilter={onFilter} onSendingFilters={onSendingFilters} onClearFilters={onClearFilters} downloadButtonText={downloadButtonText} onDownloadData={onDownloadingData} />
      <WorkerTablePagination
        pagination={pagination}
        onChangePage={handleChangePage}
        hasNext={size(workers) === pagination.rowsPerPage}
        hasWorkers={size(workers) > 0}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Paper className={classes.paper}>

        <EnhancedTableToolbar onChangeAddColumns={onChangeAddColumns} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            style={{ position: 'relative' }}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={workers.length}
              columns={columns}
            />
            <TableBody>
              <RenderingTableRow
                onWorkerToOnBoard={onWorkerToOnBoard}
                onChangeWorkerAccess={onChangeWorkerAccess}
                onWorkerEdited={onWorkerEdited}
                projectDomain={projectDomain}
                onEditRate={onEditRate}
                onEditPerDiemRate={onEditPerDiemRate}
                workers={workers}
                project={project}
                images_path={images_path}
                employee_settings_endpoint={employee_settings_endpoint}
                columns={columns.map(item => get(item, 'id'))}
                permissions={permissions}
              />
            </TableBody>
          </Table>
        </TableContainer>

        <WorkerTablePagination
          pagination={pagination}
          onChangePage={handleChangePage}
          hasNext={size(workers) === pagination.rowsPerPage}
          hasWorkers={size(workers) > 0}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      {addColumns && <AddingColumns addColumns={addColumns} totalColumns={totalColumns} onUpdateColumns={onUpdateColumns} onChangeAddColumns={onChangeAddColumns} />}

      {addWorker && <Wizard worker={workerToOnBoard} company={isNull(workerToOnBoard) ? company : workerToOnBoard.company} addWorkerCompanies={addWorkerCompanies} project={project} config={config} defaultOpen={addWorker} onHandleRevokeClose={onHandleRevokeClose} />}
    </div>
  );
}
