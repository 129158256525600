import React, { useContext } from 'react'
import { IconButton, InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { SettingsContext } from '../../../contexts/SettingsContext';


export const styles = makeStyles(theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#a8afba",
    marginLeft: 0,
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: 9,
    transition: theme.transitions.create('width'),
    width: 200
  },
}));

export default function SearchBox(props) {

  const { onSearchChange, onSearch, value } = props;

  const settingsContext = useContext(SettingsContext);

  const classes = styles();

  return (
    <>
      <div className={classes.search}>

        <InputBase
          placeholder="Search by..."
          onChange={(e) => onSearchChange(e.target.value)}
          value={value}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              onSearch()
            }
          }}
          classes={{
            input: classes.inputInput
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>
      {settingsContext.isMobile ? null :
        <div className={classes.searchIcon} >
          <IconButton onClick={() => onSearch()} color="primary" aria-label="Search" component="span">
            <SearchIcon />
          </IconButton>
        </div>}

    </>
  )
};
