import React, { useState, useEffect } from 'react'
import { Button, Modal, Form } from "react-bootstrap";
import axios from "axios"
import SingleSelect from './SingleSelect';
import CertificationNew from './CertificationNew';
import Dropzone from 'react-dropzone';

export default function CertificationWizard({ company, baseUrl, employeeId, dbid, onNewCertification }) {

    const [show, setShow] = useState(false);

    const defaultState = {
        name: "select_type"
    }
    const [step, setStep] = useState(defaultState);

    const openModal = () => {
        setShow(true)
        setStep(defaultState)
    }

    const handleClose = () => {
        setShow(false)
    }

    let currentJsx = ""

    const axiosConfig = () => {
        const token = document.querySelector("input[name='aws_token'").value
        return {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    }

    const typeSelected = (ev) => {
        console.log("TypeSelected", { ev });
        setStep({
            name: "document_create",
            type: ev
        })
    }

    const newTypeRequested = (ev) => {
        console.log({ ev });

        setStep({
            name: "document_type_create"
        })
    }

    const handleCreate = () => {
        onNewCertification()
        handleClose()
    }


    switch (step.name) {
        case "select_type":
            currentJsx = <CertificationTypeSelect onSelected={typeSelected} onRequestNewOne={newTypeRequested} company={company} baseUrl={baseUrl} axiosConfig={axiosConfig} />
            break;

        case "document_create":
            currentJsx = <CertificationNew baseUrl={baseUrl} dbid={dbid} employeeId={employeeId} type={step.type} onCreate={handleCreate} onCancel={handleClose} axiosConfig={axiosConfig} />
            break;

        case "document_type_create":
            currentJsx = <CertificationNewType baseUrl={baseUrl} dbid={dbid} onCreate={typeSelected} onCancel={handleClose} employeeId={employeeId} axiosConfig={axiosConfig} company={company} />
            break;

        default:
            break;
    }

    return (
        <>
            <Button variant="secondary" onClick={openModal} className="btn-sm">
                <i className="fa fa-plus"></i>
            </Button>

            <Modal show={show} onHide={handleClose} size="lg" animation={false} backdrop={true} backdropClassName="modal-backdrop fade in">
                {currentJsx}
            </Modal>
        </>
    )
}


const CertificationTypeSelect = ({ baseUrl, axiosConfig, company, onRequestNewOne, onSelected }) => {

    const [documentTypes, setDocumentTypes] = useState([]);
    const [selectedType, setSelectedType] = useState("");

    const loadDocumentTypes = () => {
        const url = `${baseUrl}/companies/${company._id.$oid}/certifications/types`

        axios.get(url, axiosConfig()).then(response => {
            const formatted = response.data.map(t => {
                t.id = t._id
                return t
            })
            setDocumentTypes([...formatted])
        }).catch(console.error)
    }

    useEffect(() => {
        loadDocumentTypes()
        return () => { }
    }, [])

    const handleTypeChange = (newType) => {
        console.log({ newType, documentTypes });
        setSelectedType(newType)
    }

    const useSelected = (ev) => {
        onSelected(getFullType(selectedType))
    }

    const getFullType = (id) => {
        const nt = documentTypes.find(tt => tt.id == id)
        return nt || {}
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Document Type</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form>
                    <Form.Group>
                        <Form.Label>Select Document Type</Form.Label>
                        <SingleSelect options={documentTypes} onChange={handleTypeChange} />
                        <Button variant="secondary" onClick={onRequestNewOne} className="btn-sm">
                            <i className="fa fa-plus"></i>
                        </Button>
                        {(() => {
                            console.log({ selectedType });
                            if (selectedType.length > 0) {
                                return (
                                    <Button variant="secondary" onClick={useSelected} className="btn-sm btn-danger">
                                        <strong>
                                            Upload {getFullType(selectedType).name}
                                        </strong>
                                    </Button>
                                )
                            }
                        })()}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </>
    );
}



const CertificationNewType = ({ baseUrl, axiosConfig, company, onCreate, onCancel }) => {
    const [expires, setExpires] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const defaultFileState = {
        blob: null, url: ''
    }
    const [file, setFile] = useState(defaultFileState)
    const [newDocType, setNewDocType] = useState({
        name: "",
        description: ""
    });
    const handleExpiresChange = ev => {
        console.log({ ev });

        const val = ev.target.value == "true"
        setExpires(val);
    }
    const handleTextChange = ev => {
        const name = ev.target.name
        const value = ev.target.value
        newDocType[name] = value
        setNewDocType({ ...newDocType })
    }
    const onDrop = files => {
        const file = files[0];

        const reader = new FileReader();
        reader.readAsDataURL(file)
        reader.onloadend = () => {
            setFile({ url: reader.result, blob: file })
        }
    }
    const save = () => {
        const fileUrl = `${baseUrl}/companies/${company._id.$oid}/certifications/types/get-upload-url`
        const url = `${baseUrl}/companies/${company._id.$oid}/certifications/types`
        let logoUrl = ''

        setDisabled(true)

        axios.post(fileUrl, { contentType: file.blob.type }, axiosConfig()).then(response1 => {
            console.log({ response1 });
            const { documentUrl, s3PutObjectUrl } = response1.data;
            logoUrl = documentUrl

            return axios.put(s3PutObjectUrl, file.blob, { headers: { contentType: file.blob.type } })

        }).then(response2 => {
            console.log({ response2 });
            const payload = {
                name: newDocType.name,
                description: newDocType.description,
                logo_url: logoUrl,
                expires: expires
            }
            return axios.post(url, payload, axiosConfig())
        }).then(response3 => {
            console.log({ response3 });
            setDisabled(false)
            onCreate(response3.data[0])
        }).catch(err => {
            setDisabled(false)
            console.error(err)
        })

    }
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Create New Type</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form>
                    <Form.Group>
                        <Form.Label>Logo</Form.Label>

                        <Dropzone onDrop={onDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps({ className: 'dropzone-fieldset' })}>
                                        <input {...getInputProps()} accept="image/jpeg, image/png" />
                                        <span className="h2">Drag or click to select file</span>
                                        <span>(jpeg, png)</span>
                                    </div>
                                </section>
                            )}
                        </Dropzone>

                        {
                            (() => {
                                if (file.url.length > 0) {
                                    return (
                                        <div>
                                            <iframe src={file.url} style={{ width: "100%", height: "400px" }} frameBorder="0"></iframe>
                                        </div>
                                    )
                                }
                            })()
                        }

                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <input type="text" name="name" className="form-control" onChange={handleTextChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <textarea name="description" className="form-control" onChange={handleTextChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Expires</Form.Label>
                        <Form.Check inline checked={expires} value={true} id="expires_true" label="Expires" type="radio" onChange={handleExpiresChange} />
                        <Form.Check inline checked={!expires} value={false} id="expires_false" label="Doesn't Expire" type="radio" onChange={handleExpiresChange} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <button disabled={disabled} className="btn btn-sm btn-danger" onClick={save}>Save</button>
                <button className="btn btn-sm btn-default" onClick={onCancel}>Cancel</button>
            </Modal.Footer>
        </>
    );
}
