import React, { useState } from 'react';
import { Modal, Form } from "react-bootstrap";
import axios from "axios"
import DatePicker from './DatePicker';
import MultipleSelect from './MultipleSelect';
import SingleSelect from './SingleSelect';

const AddPtoTimeModal = ({ companyId, projectId, employees, ptoTypes }) => {

  console.log({ companyId, projectId, employees, ptoTypes })

  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);

  const [selected, setSelected] = useState([]);
  const [type, setType] = useState(ptoTypes[0]["id"]);
  const [isPaid, setIsPaid] = useState(false);
  const [date, setDate] = useState(moment().format("MM/DD/YYYY"));
  const [comments, setComments] = useState("");

  const handleCommentsChange = ev => {
    setComments(ev.target.value)
  }

  const handleTypeChange = (newType) => {
    console.log({ newType });
    setType(newType)
  }

  const handleSelectedChange = (ids) => {
    console.log({ ids });
    setSelected([...ids])
  }

  const handleDateChange = (newDate) => {
    console.log({ newDate });
    setDate(newDate.format("MM/DD/YYYY"))
  }

  const handleIsPaidChange = (ev) => {
    console.log(ev.target.value)
    const val = ev.target.value == "true"
    console.log({ ev, val, isPaid });
    setIsPaid(val)
  }

  const handleClose = () => setShow(false);

  const save = () => {

    setSaving(true)
    const token = document.querySelector("meta[name='csrf-token']").content
    const url = `${window.location.protocol}//${window.location.host}/shifts/create_timeoff`

    let promises = []

    selected.forEach(employeeId => {
      promises.push(createPromise(employeeId, url, token))
    })

    if (promises.length > 0) {
      Promise.all(promises).then(response => {
        console.log("Finished all");
        location.reload()
      }).catch(err => {
        console.log("Error while creating time off", { err });
        setSaving(false)
      })
    } else {
      setSaving(false)
    }

  }

  const createPromise = (employeeId, url, token) => {
    const payload = buildPayload(employeeId)
    return axios.post(url, {
      authenticity_token: token,
      ...payload
    }).catch(err => {
      console.log("An error occurred", { err });
    })
  }

  const buildPayload = (employeeId) => {
    return {
      employee_id: employeeId,
      project_id: projectId,
      company_id: companyId,
      date: date,
      type: type,
      comments: comments,
      is_paid: isPaid
    }
  }

  const openModal = () => {
    setShow(true)
  }

  // console.log("Selected in parent", {selected})

  return (
    <>
      <a onClick={openModal}>
        Add Time Off
      </a>
      <Modal show={show} onHide={handleClose} size="md" animation={false} backdrop={true} backdropClassName="modal-backdrop fade in">
        <Modal.Header closeButton>
          <Modal.Title>Add Time Off</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {JSON.stringify(selected)} */}
          <Form>
            <Form.Group>
              <Form.Label>Workers</Form.Label>
              <MultipleSelect options={employees} value={selected} onChange={handleSelectedChange} allOption={true} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Type</Form.Label>
              <SingleSelect options={ptoTypes} value={type} onChange={handleTypeChange} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Date</Form.Label>
              <DatePicker value={date} onChange={handleDateChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Is Paid</Form.Label>
              <Form.Check inline checked={isPaid} value={true} id="paid_true" label="Paid" type="radio" onChange={handleIsPaidChange} />
              <Form.Check inline checked={!isPaid} value={false} id="paid_false" label="Not Paid" type="radio" onChange={handleIsPaidChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Comments</Form.Label>
              <Form.Control as="textarea" rows="3" value={comments} onChange={handleCommentsChange}></Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger btn-sm" disabled={saving} onClick={save}>Save</button>
          <button className="btn btn-default btn-sm" onClick={handleClose}>Close</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddPtoTimeModal;
