import React, { useState, useEffect } from 'react';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import { FormControl, TextField, FormControlLabel, Button, ThemeProvider, Dialog, DialogTitle, DialogContent, DialogActions, Switch } from '@material-ui/core';
import { axiosConfig, myTheme } from './helpers';
import { withStyles } from '@material-ui/styles';
import axios from 'axios';
import { get } from 'lodash';

const pxToRem = (px, oneRemPx = 17) => `${px / oneRemPx}rem`;
const borderWidth = 2;
const width = pxToRem(56);
const height = pxToRem(34);
const size = pxToRem(22);
const gap = (34 - 22) / 2;

const LovelySwitch = withStyles((theme) => ({
  root: {
    width,
    height,
    padding: 0,
    margin: theme.spacing(1),
    overflow: 'unset',
  },
  switchBase: {
    padding: pxToRem(gap),
    '&$checked': {
      color: '#fff',
      transform: `translateX(calc(${width} - ${size} - ${pxToRem(2 * gap)}))`,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
      '& $thumb': {
        backgroundColor: '#fff',
      },
    },
  },
  track: {
    borderRadius: 40,
    border: `solid ${theme.palette.grey[400]}`,
    borderWidth,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    boxSizing: 'border-box',
  },
  thumb: {
    boxShadow: 'none',
    backgroundColor: theme.palette.grey[400],
    width: size,
    height: size,
  },
  checked: {},
}))(Switch);

export default function MobileAppSettingsHeader(props) {

  const { isAdmin, companiesEndpoint, company } = props;
  const [open, setOpen] = useState(false);

  const [companySettingsApp, setCompanySettingsApp] = useState({
    geofence: false,
    geofenceRadius: 0,
    appEnabled: false,
    disabledIfDeviceInProject: false,
    enableFacialVerification: false
  });


  const getCompanyDetails = async () => {
    try {

      const res = await axios.get(`${companiesEndpoint}/get/${company._id.$oid}`, axiosConfig());
      setCompanySettingsApp({
        geofence: get(res, 'data.mobileAppSettings.geofence', false),
        geofenceRadius: get(res, 'data.mobileAppSettings.geofenceRadius', 0),
        appEnabled: get(res, 'data.mobileAppSettings.appEnabled', false),
        disabledIfDeviceInProject: get(res, 'data.mobileAppSettings.disabledIfDeviceInProject', false),
        enableFacialVerification: get(res, 'data.mobileAppSettings.enableFacialVerification', false),
      })

    } catch (error) {

    }
  }

  useEffect(() => {
    getCompanyDetails()
  }, [])

  const handleClick = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }


  const handleSave = () => {
    const url = `${companiesEndpoint}/update/${company._id.$oid}`;

    const payload = {
      mobileAppSettings: {
        ...companySettingsApp
      }
    }
    axios.patch(url, payload, axiosConfig()).then(response => {
      handleClose(true);
      toastr.success("Mobile app settings updated successfully.");
    }).catch(error => {
      toastr.error(get(error, 'response.data.error', 'Error updating settings.'))
    })
  }

  const handleToggle = (event) => {

    companySettingsApp[event.target.name] = event.target.checked;
    setCompanySettingsApp({ ...companySettingsApp });
  }

  const handleGeoFenceRadius = (event) => {

    companySettingsApp[event.target.name] = event.target.value < 0 ? 0 : event.target.value;
    setCompanySettingsApp({ ...companySettingsApp });
  }


  let optionsArray = [
    { value: 'appEnabled', label: 'App Enabled' },
    { value: 'disabledIfDeviceInProject', label: 'Disabled if Device in Project' },
    { value: 'enableFacialVerification', label: "Enable Facial Verification at Punch" },
    { value: 'geofence', label: 'Geofence' }
  ]

  return (
    <ThemeProvider theme={myTheme}>
      <div id="jumbo-styles-container">
        <PhoneIphone onClick={handleClick} style={{ fontSize: "23px", marginTop: " 13px", color: '#616b7a', cursor: "pointer" }} />

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Mobile App Settings</DialogTitle>
          <DialogContent>
            <div style={{ display: "flex", flexDirection: 'column' }}>
              {
                optionsArray.map(oneOption => {
                  return (
                    <FormControlLabel
                      key={oneOption.value}
                      control={<LovelySwitch color="primary" checked={companySettingsApp[oneOption.value]} onChange={handleToggle} name={oneOption.value} />}
                      label={oneOption.label}
                      disabled={oneOption.disabled}
                    />
                  )
                })
              }

              <FormControl fullWidth>
                <TextField
                  disabled={!companySettingsApp.geofence}
                  name="geofenceRadius"
                  type="number"
                  id="settingAppGeofenceRadius"
                  label="Geofence Radius (miles)"
                  color="primary"
                  value={companySettingsApp.geofenceRadius}
                  onChange={handleGeoFenceRadius}
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    </ThemeProvider >
  );
}
