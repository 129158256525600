import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

const customStyles = {
  overlay: {
    zIndex: 2000,
  },
};

export function GalleryCarouselItem({ src, caption }) {
  return <></>;
}

export function GalleryCarousel({ children = [], max = 10  }) {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [pictures] = useState(children.map(({ props: { src, caption } }) => ({ src, caption })));

  return (<>
    <AvatarGroup max={max} style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
      {pictures.map((picture, index) => <Avatar
          key={index}
          alt={picture.caption}
          src={picture.src} />)}
    </AvatarGroup>

    {isOpen && <Lightbox
        imageCaption={pictures[photoIndex].caption}
        mainSrc={pictures[photoIndex].src}
        nextSrc={pictures[(photoIndex + 1) % pictures.length].src}
        prevSrc={pictures[(photoIndex + pictures.length - 1) % pictures.length].src}
        reactModalStyle={customStyles}
        onMovePrevRequest={() => setPhotoIndex((photoIndex + pictures.length - 1) % pictures.length)}
        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % pictures.length)}
        onCloseRequest={() => setOpen(false)}
    />}
  </>);
}
