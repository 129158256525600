import React, { useState } from "react"
import axios from "axios"

const ShiftTimeRule = ({ data, isNew, onDelete, readOnly }) => {

  // so the id and the modifies doesnt get changed by this widget
  const [rule, setRule] = useState(data.rule);
  const [needsUpdate, setNeedsUpdate] = useState(isNew);

  const handleChange = e => {
    rule[e.target.name] = e.target.value;
    data.rule = rule
    setRule({ ...rule })
    setNeedsUpdate(true)
  }

  const handleDelete = ev => {
    // Asking wether its delete a new one or one which already exists in the server
    if (data._id.$oid) {
      axiosClient().delete(`/projects/${data.project_id.$oid}/time_rules/${data._id.$oid}.json`).then(response => {
        onDelete(data)
      }).catch(err => {
        toastr.error("An Error Ocurred", "")
      })
    } else {
      onDelete(data)
    }
  }

  const axiosClient = () => {
    return axios.create({
      withCredentials: true
    })
  }

  const buildPayload = () => {
    return {
      time_rule: {
        rule: rule,
        modifies: data.modifies,
        company_id: data.company_id,
        project_id: data.project_id,
        _id: data._id,
      }
    }
  }

  const handleCreate = e => {
    axiosClient().post(`/projects/${data.project_id.$oid}/time_rules.json`, buildPayload()).then(response => {
      data._id = response.data._id
      toastr.success("Successfully Saved", "")
      setNeedsUpdate(false)
    }).catch(err => {
      toastr.error("", "An Error Ocurred")
      toastr.error(err.response.data.base.join(" "), "Validation Error")
      console.error(err)
    })
  }

  const handleSave = e => {
    // new one
    if (data._id.$oid == undefined) {
      return handleCreate(e)
    } else {
      axiosClient().put(`/projects/${data.project_id.$oid}/time_rules/${data._id.$oid}.json`, buildPayload()).then(response => {
        toastr.success("Successfully Saved", "")
        setNeedsUpdate(false)
      }).catch(err => {
        toastr.error("", "An Error Ocurred")
        toastr.error(err.response.data.base.join(" "), "Validation Error")
        console.error(err)
      })
    }
  }

  return (
    <tr>
      <td>
        <input type="number" min="0" name="from" value={rule.from} className="form-control" onChange={handleChange} />
      </td>
      <td>
        <input type="number" min="0" name="to" value={rule.to} className="form-control" onChange={handleChange} />
      </td>
      <td>
        <input type="number" min="0" name="break_time" value={rule.break_time} className="form-control" onChange={handleChange} />
      </td>
      <td>
        {(() => {
          if (needsUpdate && !readOnly) {
            return (
              <a className="btn btn-success btn-xs btn-outline btn-3d" onClick={handleSave}>Save</a>
            )
          }
        })()}
      </td>
      <td>
        {(() => {
          if (!readOnly) {
            return (
              <a className="btn btn-danger btn-xs btn-outline btn-3d" onClick={handleDelete}>Delete</a>
            )
          }
        })()}
      </td>
    </tr>
  );
}

export default ShiftTimeRule;