import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardActions,
    CardContent,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DepartmentsTable } from './components/departmentsPositions';
import { Cancel } from '@material-ui/icons';
import axios from 'axios';
import { axiosConfig } from '../../../../helpers';
import { Autocomplete } from '@material-ui/lab';
import CardHeader from '@material-ui/core/CardHeader';
import { SkeletonTypography } from '../../../../reusableComponents';
import { get } from 'lodash';


const useStyles = makeStyles((theme) => ({
    mainContent: {
        backgroundColor: '#f3f3f4',
        height: '100%',
        width: '100%',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    companySettings: {
        'flex': 'auto',
        'display': 'flex',
        'flexDirection': 'column',
        'justifyContent': 'space-between',
    },
    accordion: {
        'backgroundColor': '#fff',
        // "borderBottom": '#0000004d 2px solid'
    },
    accordionDetails: {
        padding: 'unset',
    },
}));

const RenderTable = ({ type, items, classes, endpoint, onChange, onReloadDepartmentsPositions }) => (
    <Accordion key={type} classes={{ root: classes.accordion }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className="text-capitalize">{type}</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.accordionDetails }}>
            <DepartmentsTable type={type} items={items} endpoint={endpoint} onChange={onChange} onReloadDepartmentsPositions={onReloadDepartmentsPositions} />
        </AccordionDetails>
    </Accordion>
);


export default function CompanySettings({ endpoint, onCancel, company }) {

    const classes = useStyles();


    console.log("ASDSDSD")
    const [loading, setLoading] = useState(true);
    const [departmentsPositions, setDepartmentsPositions] = useState({
        departments: [],
        positions: [],
    });

    const [subcontractors, setSubcontractors] = useState([]);
    const [selectedSubcontractors, setSelectedSubcontractors] = useState([]);

    useEffect(() => setSelectedSubcontractors(subcontractors.filter(option => option.selected)), [subcontractors]);

    const fetchDepartmentsPositions = () => axios.get(`${endpoint}/departments-positions`, axiosConfig())
        .then(({ data }) => {
            setDepartmentsPositions(data);
            setLoading(false)
        })
        .catch(console.log);


    useEffect(() => {
        fetchDepartmentsPositions();
    }, []);

    const onReloadDepartmentsPositions = () => {
        fetchDepartmentsPositions()
    }
    return (
        <div className={`${classes.mainContent}  p-a-3`}>
            <div>
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                    <img
                        src="https://s3.us-west-2.amazonaws.com/static-files.connectedbarrel.com/project-employees/settings.png"
                        width="45" height="45" />
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                        <strong style={{ fontSize: '16px' }}>{get(company, 'name', '')}</strong>
                        <span>{get(company, 'address')}</span>
                    </div>
                </div>

                <hr style={{ width: '100%', color: '#d6d6d6' }} />

                {loading ? <SkeletonTypography /> :
                    <div>
                        {/* <Card variant="outlined" style={{ backgroundColor: '#fff' }}>
                            <CardHeader
                                action={<Button size="small" onClick={onClearSubcontractors}
                                                style={{ color: '#dc3545' }}>Clear all</Button>}
                                subheader="Settings"
                            />
                            <CardContent>
                                <Autocomplete
                                    multiple
                                    limitTags={3}
                                    options={subcontractors}
                                    defaultValue={selectedSubcontractors}
                                    value={selectedSubcontractors}
                                    onChange={(e, v) => onChangeSubcontractors(v)}
                                    getOptionLabel={({ label }) => label}
                                    renderInput={(params) => (
                                        <TextField{...params} variant="standard" label="Select Subcontractors"
                                                  placeholder="Select..." />
                                    )}
                                />
                            </CardContent>
                            <CardActions>
                                <Button variant="contained" onClick={() => onCopySubcontractors()} size="small">Make
                                    copies in subcontractors</Button>
                            </CardActions>
                        </Card> */}
                        <div style={{ marginTop: '10px' }}>
                            <RenderTable
                                type="Departments"
                                onReloadDepartmentsPositions={onReloadDepartmentsPositions}
                                items={departmentsPositions.departments}
                                classes={classes}
                                onChange={departments => setDepartmentsPositions({
                                    ...departmentsPositions,
                                    departments,
                                })}
                                endpoint={endpoint} />
                            <RenderTable
                                type="Positions"
                                onReloadDepartmentsPositions={onReloadDepartmentsPositions}
                                items={departmentsPositions.positions}
                                onChange={positions => setDepartmentsPositions({
                                    ...departmentsPositions,
                                    positions,
                                })}
                                classes={classes}
                                endpoint={endpoint} />
                        </div>
                    </div>
                }
            </div>
            <div style={{ marginTop: '10px' }}>
                <Button
                    variant="contained"
                    onClick={onCancel}
                    startIcon={<Cancel />}>
                    Cancel
                </Button>
            </div>

        </div>
    );
}
