import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Drawer, makeStyles, TableFooter } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { renderStatus } from './DeviceListItem';
import { $axios } from '../../../helpers';
import LinearProgress from '@material-ui/core/LinearProgress';
import TabPanel from '@material-ui/lab/TabPanel';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import { TabList } from '@material-ui/lab';
import { DeviceComments } from './DeviceComments';

const useStyles = makeStyles(theme => ({
  main: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    background: '#f7f7f7',
  },
  content: {
    overflow: 'overlay',
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiTableCell-root': {
      paddingLeft: 0,
    },
  },
  container: {
    width: '35vw',
  },
  footer: {
    background: '#f7f7f7',
    justifyContent: 'start',
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const DeviceEditDialog = ({ endpoint, open = false, device = {}, onClose = () => ({}) }) => {
  const classes = useStyles();

  const [saving, setSaving] = useState(false);
  const [tab, setTab] = useState('attributes');

  const [attrs, setAttrs] = useState([]);
  const [name, setName] = useState('');
  const [status, setStatus] = useState('');
  const [type, setType] = useState('');
  const [authorized, setAuthorized] = useState(false);
  const [awake, setAwake] = useState(false);
  const [maintenance, setMaintenance] = useState(false);
  const [notes, setNotes] = useState('');

  function addAttr() {
    setAttrs([...attrs, ...[{ key: '', value: '' }]]);
  }

  function updateAttr(index, key, value) {
    attrs[index] = { key, value };

    setAttrs([...attrs]);
  }

  function removeAttr(index) {
    if (index < 0) {
      return;
    }

    const array = [...attrs];
    delete array[index];

    setAttrs(array.filter(val => !!val));
  }

  function onUpdate() {
    setSaving(true);

    const payload = {
      'name': name,
      'attributes': Object.fromEntries(attrs
          .filter((attr) => !!attr['key'])
          .map((attr) => [attr['key'], attr['value']]),
      ),
      'authorized': authorized,
      'stay_awake': awake,
      'current_status': status,
      'device_type': type,
      'maintenance': maintenance,
      'notes': notes,
    };

    return $axios.$patch(`${endpoint}/update/${device._id}`, payload)
        .then(onClose)
        .finally(() => setSaving(false));
  }

  useEffect(() => {
    setName(device.name);
    setStatus(device.current_status);
    setType(device.device_type);
    setAuthorized(device.authorized || false);
    setAwake(device.stay_awake || false);
    setMaintenance(device.maintenance || false);
    setNotes(device.notes);

    setAttrs(Object.entries(device.attributes || {})
        .map(([key, value]) => ({ key, value })));
  }, [device]);

  useEffect(() => {
    document.querySelector('html').style.overflow = open ? 'hidden' : 'visible';
  }, [open]);

  return (<>
    <Drawer anchor={'right'} open={open} onClose={onClose}>
      {device && (<form className={classes.main}>
        <DialogTitle className={classes.header}>
          <span className={`font-size-12 m-l-1 label label-${renderStatus(device)}`}>
            {device.name}
          </span>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Grid className={classes.container} spacing={4} container>
            <Grid xs={6} lg={6} item>
              <FormControl component="fieldset">
                <FormLabel>Device ID</FormLabel>
                <TextField variant="outlined" defaultValue={device.device_id} disabled />
              </FormControl>
            </Grid>
            <Grid xs={6} lg={6} item>
              <FormControl component="fieldset">
                <FormLabel>Name</FormLabel>
                <TextField
                    variant="outlined"
                    defaultValue={name}
                    disabled={saving}
                    onChange={({ target }) => setName(target.value)} />
              </FormControl>
            </Grid>

            <Grid xs={6} lg={6} item>
              <FormControl component="fieldset">
                <FormLabel>Status</FormLabel>
                <Select
                    variant="outlined"
                    value={status}
                    disabled={saving}
                    onChange={({ target }) => setStatus(target.value)}
                >
                  <MenuItem value={'in_stock'}>In Stock</MenuItem>
                  <MenuItem value={'on_hold'}>On Hold</MenuItem>
                  <MenuItem value={'deployed'}>Deployed</MenuItem>
                  <MenuItem value={'shipped'}>Shipped</MenuItem>
                  <MenuItem value={'inspection'}>Inspection</MenuItem>
                  <MenuItem value={'retired'}>Retired</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={6} lg={6} item>
              <FormControl component="fieldset">
                <FormLabel>Device Type</FormLabel>
                <Select
                    variant="outlined"
                    value={type}
                    disabled={saving}
                    onChange={({ target }) => setType(target.value)}>
                  <MenuItem value={'wall_mount'}>Wall Mount</MenuItem>
                  <MenuItem value={'field'}>Field</MenuItem>
                  <MenuItem value={'embedded'}>Embedded</MenuItem>
                  <MenuItem value={'other'}>Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid xs={12} item>
              <FormControl component="fieldset">
                <FormLabel>Display</FormLabel>
                <Grid container>
                  <Grid xs={true} item>
                    <FormControlLabel
                        label="Authorized"
                        disabled={saving}
                        control={<Switch
                            color="primary"
                            checked={authorized}
                            onChange={({ target }) => setAuthorized(target.checked)} />} />
                  </Grid>
                  <Grid xs={true} item>
                    <FormControlLabel
                        label="Stay Awake"
                        disabled={saving}
                        control={<Switch
                            color="primary"
                            checked={awake}
                            onChange={({ target }) => setAwake(target.checked)} />} />
                  </Grid>
                  <Grid xs={true} item>
                    <FormControlLabel
                        label="Maintenance"
                        disabled={saving}
                        control={<Switch
                            color="primary"
                            checked={maintenance}
                            onChange={({ target }) => setMaintenance(target.checked)} />} />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid xs={12} item>
              <FormControl component="fieldset">
                <FormLabel>Notes</FormLabel>
                <TextField
                    variant="outlined"
                    defaultValue={notes}
                    multiline
                    maxRows={5}
                    minRows={4}
                    disabled={saving}
                    onChange={({ target }) => setNotes(target.value)}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} item>
              <TabContext value={tab}>
                <TabList variant="fullWidth" color="primary" centered onChange={(e, newTab) => setTab(newTab)}>
                  <Tab label="Attributes" value="attributes" />
                  <Tab label="Comments" value="comments" />
                </TabList>
                <TabPanel value="attributes" className="p-x-0">
                  <Table>
                    <TableBody>
                      {!attrs.length && (
                          <TableRow>
                            <TableCell align={'center'} colSpan={99} className="p-y-5">
                              <span>No attributes to show</span>
                            </TableCell>
                          </TableRow>
                      )}

                      {attrs.map(({ key, value }, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row" style={{ border: 0 }}>
                              <TextField
                                  className="w-100"
                                  defaultValue={key}
                                  placeholder={`${key}`}
                                  disabled={saving}
                                  onChange={({ target }) => updateAttr(index, target.value, value)}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row" style={{ border: 0 }}>
                              <TextField
                                  className="w-100"
                                  defaultValue={value}
                                  placeholder={`${value}`}
                                  disabled={saving}
                                  onChange={({ target }) => updateAttr(index, key, target.value)}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row" style={{ border: 0 }} align="center">
                              <IconButton size={'small'} className="bg-danger" onClick={() => removeAttr(index)}>
                                <DeleteIcon fontSize={'small'} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell align={'center'} colSpan={99}>
                          <Button className="w-100" onClick={addAttr}>New</Button>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TabPanel>
                <TabPanel value="comments" className="p-x-0">
                  <DeviceComments device={device} />
                </TabPanel>
              </TabContext>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Button color="default" disabled={saving} onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" disabled={saving} onClick={onUpdate}>
            <span>Update</span>
          </Button>
        </DialogActions>
        {saving && <LinearProgress />}
      </form>)}
    </Drawer>
  </>);
};
