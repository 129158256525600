import IconButton from '@material-ui/core/IconButton';
import ReceiptIcon from '@material-ui/icons/Receipt';
import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { ProjectContext } from '../../ProjectMetrics';
import { $axios } from '../../../../../helpers';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Avatar from '@material-ui/core/Avatar';
import { formatPhoneNumber } from '../../../../../helpers/formatters';
import moment from 'moment/moment';

export const DialogIncidentList = () => {
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const {
    project: { name, _id: { $oid: id } },
    config: { project_workSummary_endpoint: endpoint },
  } = useContext(ProjectContext);

  function fetchLogs() {
    setLoading(true);
    setItems([]);

    const data = {
      page: page,
      status: status,
    };

    $axios.$post(`${endpoint}/history/${id}`, data)
        .then(setItems)
        .catch(console.log)
        .finally(() => setLoading(false));
  }

  function handleOnChange({ target }) {
    setStatus(target.value);
  }

  function handleChangePage(num) {
    setPage(page + num);
  }

  const getStatus = (status) => {
    if (status === 'claim_opened') {
      return 'Claim Opened';
    }

    return status;
  };

  useEffect(() => {
    isOpen && fetchLogs();
  }, [isOpen, status, page]);

  return (<>
    <IconButton onClick={handleOpen} size="small">
      <ReceiptIcon />
    </IconButton>

    <Dialog fullWidth maxWidth={'lg'} open={isOpen} onClose={handleClose}>
      <Grid className="p-x-3 p-y-2" alignItems={'center'} container>
        <Grid xs={true} item>
          <Typography variant={'h6'}>
            <span>Incident Logs: </span>
            <em>{name}</em>
          </Typography>
        </Grid>
        <Grid xs={'auto'} item>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
            <div>Status:</div>
            <Select
                value={status}
                label="Status"
                variant="standard"
                placeholder={'Select status'}
                displayEmpty
                disabled={loading}
                onChange={handleOnChange}>
              <MenuItem>
                <em>All Status</em>
              </MenuItem>
              <MenuItem value={'open'}>Open</MenuItem>
              <MenuItem value={'claim_opened'}>Claim Opened</MenuItem>
              <MenuItem value={'closed'}>Closed</MenuItem>
            </Select>
          </div>
        </Grid>
      </Grid>

      <DialogContent>
        {loading && (
            <Grid container>
              <Grid className="text-center" xs={12} item>
                <CircularProgress />
              </Grid>
            </Grid>
        )}

        {!loading && !items.length && (
            <Grid container>
              <Grid xs={12} justifyContent="center" item>
                <strong>No incidents to show</strong>
              </Grid>
            </Grid>
        )}

        {!loading && items.map((incident) => (
            <Grid key={incident._id} className="p-y-1" container>
              <Grid xs="auto" className="p-x-1" item>
                <Avatar src={incident.employee.profilePic} />
              </Grid>

              <Grid xs={3} item>
                <Typography variant={'subtitle2'}>
                  {incident.employee.first_name} {incident.employee.last_name}
                </Typography>
                <div style={{ gap: '.5em', display: 'flex' }}>
                  <span>{formatPhoneNumber(incident.employee.phone_number)}</span>
                  {incident.classifiers.language && (<img
                      src={`https://static-files.connectedbarrel.com/icons/lenguageIcons/${incident.classifiers.language}-checked.svg`}
                      style={{ width: '18px', height: 'auto' }}
                      alt={''}
                  />)}

                  {incident.classifiers.source && (<img
                      src={`https://static-files.connectedbarrel.com/icons/integrations/${incident.classifiers.source}.png`}
                      style={{ width: '18px', height: 'auto' }}
                      alt={''}
                  />)}
                </div>
              </Grid>

              <Grid className="text-truncate" xs={true} item>
                <div title={incident.notes} className="flex-fill">{incident.notes}</div>
                <Typography component="small">
                  <span>{incident.sent_at}</span>
                </Typography>
              </Grid>

              <Grid xs={'auto'} item>
                <span className="text-capitalize">{getStatus(incident.status)}</span>
              </Grid>
            </Grid>
        ))}
      </DialogContent>

      <DialogActions>
        <Grid justifyContent={'flex-end'} container>
          <Grid item>
            <IconButton disabled={loading || page < 2} onClick={() => handleChangePage(-1)}>
              <ArrowLeftIcon />
            </IconButton>
            <IconButton className="font-size-16" disabled>
              <span>{page}</span>
            </IconButton>
            <IconButton disabled={loading || items.length < 21 || !items.length} onClick={() => handleChangePage(1)}>
              <ArrowRightIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  </>);
};
