export const allColumns = [
  {
    id: "crew_name",
    isActive: true,
    isImage: true,
    sortOption: true,
    label: "Name"
  },
  {
    id: "profile_name",
    sortOption: true,
    isActive: true,
    label: "Profile Name"
  },
  {
    id: "employees",
    sortOption: true,
    isActive: true,
    label: "# of Employees"
  },
  {
    id: "actions",
    isActive: true,
    label: "Actions"
  }
];
