import React, { useContext } from 'react';
import { get } from 'lodash';
import { Widget } from '../index';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { FilterList, DeleteForever, GetApp } from '@material-ui/icons';
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { SettingsContext } from '../../../contexts/SettingsContext';


import {
  CompanyFilter,
  SafetyForm,
  ActiveWorkers,
  RFID,
  NotSeenWorkers,
  DateRangePickerFilter,
  ProfilePicture
} from './Filters/index'


export default function TableFilters(props) {

  const { filters, onFilter, onSendingFilters, onClearFilters, downloadButtonText, onDownloadData } = props;


  const settingsContext = useContext(SettingsContext);

  const useStyles = makeStyles((theme) => ({

    buttonGenerate: {
      backgroundColor: '#009687 !important',
      color: 'white !important',
      fontWeight: 'bold',
      borderRadius: '5px !important',
      marginRight: '10px !important',
    },
    buttonClearFilter: {
      fontWeight: 'bold',
      borderRadius: '5px !important'
    },
    filters: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    },
    filtersMobile: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
    },
    title: {
      fontSize: '1.6rem',
      fontWeight: 500,
      paddingTop: 6,
      marginRight: 24
    }
  }));

  const classes = useStyles();

  const onClearFiltersLocal = () => {
    settingsContext.onCleaningFilter(true);
    onClearFilters();
  }



  const selectStyles = {
    container: (provided, state) => ({
      ...provided,
      width: '200px'
    })
  }

  return (
    <>
      {get(settingsContext, 'isMobile') ? null :
        <div className='MuiPaper-root makeStyles-paper MuiPaper-elevation1 MuiPaper-rounded' style={{ padding: 12 }}>
          <div style={{ width: '100%', display: 'flex' }}>
            <div className={classes.title}>Filters</div>
            <div className={get(settingsContext, 'isMobile') ? classes.filtersMobile : classes.filters}>
              <Grid container spacing={1}>
                {filters.map((item, idx) => (
                  <Grid item key={idx}>
                    {get(item, 'key') === 'rangeDatePicker' ? <DateRangePickerFilter onFilter={onFilter} filter={item} /> : null}
                    {get(item, 'key') === 'company' ? <CompanyFilter onFilter={onFilter} filter={item} selectStyles={selectStyles} /> : null}
                    {get(item, 'key') === 'safetyForm' ? <SafetyForm onFilter={onFilter} filter={item} selectStyles={selectStyles} /> : null}
                    {get(item, 'key') === 'activeWorkers' ? <ActiveWorkers onFilter={onFilter} filter={item} selectStyles={selectStyles} /> : null}
                    {get(item, 'key') === 'rfid' ? <RFID onFilter={onFilter} filter={item} selectStyles={selectStyles} /> : null}
                    {get(item, 'key') === 'profilePicture' ? <ProfilePicture onFilter={onFilter} filter={item} selectStyles={selectStyles} /> : null}
                    {get(item, 'key') === 'notSeenWorkers' ? <NotSeenWorkers onFilter={onFilter} filter={item} selectStyles={selectStyles} /> : null}
                  </Grid>
                ))}
              </Grid>
              <div className="filter-buttons" style={{ width: `${get(settingsContext, 'isMobile') ? '100%' : 330}`, display: "flex", alignItems: 'flex-start', justifyContent: `${get(settingsContext, 'isMobile') ? 'center' : 'flex-end'}`, marginTop: `${get(settingsContext, 'isMobile') ? '20px' : '0px'}`, height: '100%' }}>
                <Button
                  disabled={downloadButtonText !== 'Download'}
                  style={{ marginLeft: '5px' }}
                  variant="contained"
                  className={classes.buttonGenerate}
                  onClick={() => onDownloadData()}
                  startIcon={downloadButtonText === 'Download' ? <GetApp /> : <CircularProgress size={20} color="primary" />}>
                  {downloadButtonText}
                </Button>
                <Button
                  variant="contained"
                  className={classes.buttonGenerate}
                  onClick={() => onSendingFilters()}
                  startIcon={<FilterList />}>
                  Filter
                </Button>
                <Button
                  variant="contained"
                  className={classes.buttonClearFilter}
                  onClick={() => onClearFiltersLocal()}
                  startIcon={<DeleteForever />}>
                  Clear
                </Button>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
};
