import React from 'react'
import { Avatar, Fab } from "@material-ui/core";
import { Worker } from "./helpers/formatters";
import CardLayout from "../jumbo-components/CardLayout";

export default function PunchPPE(props) {

    const { punch, worker, config } = props

    const workerFormatter = Worker(worker, config)

    console.log("PunchPPE", props);

    const ppe = punch.ppe || {}

    const matchAny = (ps, dict) => {
        console.log({ ps, dict });
        for (let i = 0; i < ps.length; i++) {
            if (dict[ps[i]])
                return true
        }
        return false
    }

    const getIconClasses = (ppeProps) => {
        return `ts-icon big-ppe-img ${matchAny(ppeProps, ppe) ? 'ppe-on' : 'ppe-off'}`
    }

    return (
        <div className="">
            <img className={getIconClasses(['hardhat', 'helmet'])} title="Hard Hat not Detected" src="/assets/ppe/lg/hardhat_gray-5aa74137e39caf4c51c2553879d2096c6cac6c7447593fd20fab829c42ce79a6.png" />
            <img className={getIconClasses(['sunglasses', 'eyeglasses'])} title="Safety Glasses not Detected" src="/assets/ppe/lg/goggles_gray-1a46a12621b07063db2af77ae09fdf97db8d5f135521de9851285095cd5d7e2a.png" />
            <img className={getIconClasses(['vest'])} title="Safety Vest not Detected" src="/assets/ppe/lg/vest_gray-867d7586edfe0da111e44d8afc94f219220ff664a5c979ce972892ac3b0b2f03.png" />
            <img className={getIconClasses(['mask'])} title="Mask not Detected" src="/assets/ppe/lg/mask_gray-45bdc761ae96a6ac4199f0dc92ba38f6a3ea36416218414840cc50c290869576.svg" />
        </div>
    )

}
