import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import _ from 'lodash';
import { CrewWorkers } from '../index';
import { Avatar, CircularProgress, TextField } from '@material-ui/core';
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import workerProfileIcon from '../../../../../assets/images/worker_types/constructionWorkerIcon-2023.svg'
import { List } from 'react-virtualized'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: 0,
  },
  list: {
    width: "100%",
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  listItem: {
    padding: 0
  },
  listItemIcon: {
    minWidth: 0
  }
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const CustomList = React.memo((props) => {

  const { classes, title, items, loadingWorkers, checked, setChecked } = props

  const [filterTerm, setFilterTerm] = useState('');

  const onFilter = (value) => {
    setFilterTerm(value)
  }

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const WorkerList = (props) => {

    const { classes, items, filterTermm, checked } = props;

    let filteredItems = items.filter(item => _.lowerCase(_.get(item, 'first_name')).includes(filterTerm) || _.lowerCase(_.get(item, 'last_name')).includes(filterTerm) || (!_.isNull(_.get(item, 'phone_number', '')) && _.get(item, 'phone_number', '').replace(/\D/g, '').includes(filterTerm)) || _.lowerCase(`${_.get(item, 'first_name')} ${_.get(item, 'last_name')}`).includes(filterTerm))

    const rowRenderer = ({
      key, // Unique key within array of rows
      index, // Index of row within collection
      isScrolling, // The List is currently being scrolled
      isVisible, // This row is visible within the List (eg it is not an overscanned row)
      style, // Style object to be applied to row (to position it)
    }) => {
      const item = _.get(filteredItems, index)

      return (
        <div key={key} style={style}>
          <ListItem key={index} role="listitem" button onClick={handleToggle(item)} className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}>
              <Checkbox
                size='small'
                checked={checked.indexOf(item) !== -1}
                color={'primary'}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText>
              <div style={{ display: 'flex' }}>
                <div>
                  <Avatar src={_.has(item, 'image_path') ? _.get(item, 'image_path') : workerProfileIcon} />
                </div>
                <div style={{ marginLeft: '4px', display: "flex", flexDirection: 'column' }}>
                  <span style={{ fontWeight: 'bold' }}>{`${_.get(item, 'first_name')} ${_.get(item, 'last_name')}`}</span>
                  <span style={{ fontSize: '12px', color: '#575757', marginTop: '5px' }}>{_.get(item, 'phone_number')}</span>
                </div>

              </div>
            </ListItemText>
          </ListItem>
        </div>
      );
    }

    return (
      <List
        width={250}
        height={220}
        rowCount={filteredItems.length}
        rowHeight={55}
        rowRenderer={rowRenderer}
      />
    )
  }

  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            color={'primary'}
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />

      <div style={{ display: "flex", justifyContent: 'center', marginBottom: '17px' }}>
        <TextField style={{ width: '95%' }} onChange={e => onFilter(_.lowerCase(e.target.value))} id="standard-basic" label="Search" />
      </div>

      <Divider />

      { loadingWorkers ?
        <div style={{ display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center', height: 220, padding: 16 }}>
          <CircularProgress size={20} style={{ margin: 'auto' }} color="primary" />
        </div> : _.isEmpty(items) ?
          <span style={{ display: 'flex', justifyContent: 'center', fontSize: '20px', color: 'gray', height: 220, paddingTop: 16 }}>
            No Workers
          </span> : <WorkerList classes={classes} items={items} filterTerm={filterTerm} checked={checked} />
      }
    </Card>
  )
})

export default function TransferList(props) {

  const { workers, currentCompany, onAddWorkersCrew, crew, loadingWorkers, justifyContent = 'flex-start', selectTitle } = props;

  const classes = useStyles();
  const [leftChecked, setLeftChecked] = useState([]);
  const [rightChecked, setRightChecked] = useState([]);
  const [left, setLeft] = useState(workers);
  const [copyLeft, setCopyLeft] = useState(workers);
  const [right, setRight] = useState(_.get(crew, 'crewEmployees', []));
  const [copyRight, setCopyRight] = useState(_.get(crew, 'crewEmployees', []));
  const [finalCrew, setFinalCrew] = useState(_.get(crew, 'crewEmployees', []));
  const [rightClicked, setRightClicked] = useState(false);
  const [leftFilterTerm, setLeftFilterTerm] = useState('');
  const [rightFilterTerm, setRightFilterTerm] = useState('');

  useEffect(() => {

    if (rightClicked) {


      if (_.isEmpty(right)) {
        setFinalCrew(finalCrew.filter(item => _.get(item, 'company._id') !== currentCompany))
        onAddWorkersCrew(finalCrew.filter(item => _.get(item, 'company._id') !== currentCompany))
      } else {
        const res = _.differenceBy(copyRight, right, '_id')
        const elem = _.differenceBy(finalCrew, res, "_id");
        setFinalCrew(_.isEmpty(elem) ? [...finalCrew] : [...elem]);
        setCopyLeft([...copyLeft, ...res])
        setCopyRight(right)
        onAddWorkersCrew(_.isEmpty(elem) ? [...finalCrew] : [...elem])
        setRightClicked(false)
      }


    }


  }, [rightClicked])

  useEffect(() => {

    setCopyLeft(workers)

    // const currentSelected = intersectionBy(finalCrew, workers, 'company._id')
    const currentSelected = finalCrew.filter(item => _.get(item, 'company._id') === currentCompany);
    // const aux = _.differenceBy(workers, currentSelected, 'company._id')
    setLeft(_.differenceBy(workers, currentSelected, '_id'));

    setRight(finalCrew.filter(item => _.get(item, 'company._id') === currentCompany));
    setCopyRight(finalCrew.filter(item => _.get(item, 'company._id') === currentCompany));
  }, [workers])

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setCopyRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked));
    setLeftChecked([]);
    setFinalCrew([...finalCrew, ...leftChecked])
    onAddWorkersCrew([...finalCrew, ...leftChecked])
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRightClicked(true);
    setRight(not(right, rightChecked));
    setRightChecked([]);
  };

  const onDeleteFinalCrew = (worker, company_id) => {
    setFinalCrew(finalCrew.filter(item => _.get(item, '_id') !== _.get(worker, '_id')));
    onAddWorkersCrew(finalCrew.filter(item => _.get(item, '_id') !== _.get(worker, '_id')));
    setRight(right.filter(item => _.get(item, '_id') !== _.get(worker, '_id')))
    setCopyRight(copyRight.filter(item => _.get(item, '_id') !== _.get(worker, '_id')))
    setLeft([worker, ...left]);
    setCopyLeft([worker, ...copyLeft]);
  }

  return (
    <div style={{ marginTop: "20px" }}>

      <Grid
        container
        spacing={2}
        justifyContent={justifyContent}
        alignItems="center"
        className={classes.root}
      >
        <Grid xs={true} item><CustomList classes={classes} title='Choices' items={left} loadingWorkers={loadingWorkers} checked={leftChecked} setChecked={setLeftChecked} /></Grid>
        <Grid xs={'auto'} item>
          <Grid container direction="column" alignItems="center">
            <IconButton
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right">
              <ChevronRight />
            </IconButton>
            <IconButton
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left">
              <ChevronLeft />
            </IconButton>
          </Grid>
        </Grid>
        <Grid xs={true} item><CustomList classes={classes} title='Chosen' items={right} loadingWorkers={loadingWorkers} checked={rightChecked} setChecked={setRightChecked} /></Grid>
      </Grid>
      {_.size(finalCrew) > 0 && (<Grid spacing={2} container>
        <Grid xs={12} item>
          <CrewWorkers
            title={selectTitle}
            workers={finalCrew}
            currentCompany={currentCompany}
            onDeleteFinalCrew={onDeleteFinalCrew} />
        </Grid>
      </Grid>)
      }
    </div>
  );
}
