import React, { useState } from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import { CardDash } from '../../../projectDashboard/components';

export default function DeviceVoltageChart({ snapshots = [] }) {
  const [snap] = useState(snapshots.map(shot => ({
    power: shot.power.volt,
    date: moment.unix(shot.timestamp).format('LT'),
  })));

  return (
      <CardDash title="Voltage" expanded>
        <div style={{ height: 200 }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart width={500} height={400} data={snap} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="power" stroke="#8884d8" fill="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </CardDash>
  );
}
