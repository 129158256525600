import React, { useState } from 'react'
import axios from 'axios'

export default function WorkerActions(props) {
  
  const { worker, isAdmin, isSuperAdmin, isProjectPage, recoverPath, editPath, deletePath, activityPath } = props

  const defaultLabels = {
    delete: "Delete",
    recover: "Recover",
  }

  const [deleteLabel, setDeleteLabel] = useState(defaultLabels.delete);
  const [recoverLabel, setRecoverLabel] = useState(defaultLabels.recover);

  const setLabelsToDefault = () => {
    setDeleteLabel(defaultLabels.delete)
    setRecoverLabel(defaultLabels.recover)
  }

  const makeConfirmationEvent = (msg, axiosFn) => {
    return (ev) => {
      ev.preventDefault()
      const confirmed = confirm(msg)
      if (confirmed) {
        axiosFn().then(response => {
          location.reload()
          // setLabelsToDefault()
        }).catch(networkError => {
          toastr.error("An Error Ocurred")
          console.log({ networkError })
          setLabelsToDefault()
        })
      }
    }
  }

  const makeConfirmButton = (label, msg, callback) => {
    return <a onClick={makeConfirmationEvent(msg, callback)}>{label}</a>
  }

  const payload = () => {
    let token = document.querySelector("meta[name='csrf-token']").content
    return {
      authenticity_token: token,
    }
  }

  const jsonFyUrl = (url) => url + ".json"

  const axiosClient = () => {
    return axios.create({
      withCredentials: true
    })
  }

  const recoverCallback = () => {
    setRecoverLabel("Recovering..")
    return axiosClient().post(jsonFyUrl(recoverPath), payload())
  }
  const deleteCallback = () => {
    setDeleteLabel("Deleting..")
    return axiosClient().delete(jsonFyUrl(deletePath), { data: payload() })
  }

  return (
    <>
      <span className={`enabled three-dots x2`} data-toggle="dropdown">

      </span>
      <ul className="dropdown-menu">
        {(() => {
          if (isSuperAdmin && worker.deleted_at) {
            return (
              <li>
                {makeConfirmButton(recoverLabel, `Are you sure you want to recover ${worker.first_name} ${worker.last_name}?`, recoverCallback)}
              </li>
            )
          }
        })()}
        {(() => {
          if (isSuperAdmin) {
            return (
              <>
                <li>
                  <a href={activityPath}>Activity</a>
                </li>
              </>
            )
          }
        })()}
        {(() => {
          if (isAdmin) {
            return (
              <>
                <li>
                  <a href={editPath}>Edit</a>
                </li>
              </>
            )
          }
        })()}
        {(() => {
          if (isAdmin && !isProjectPage && !worker.deleted_at) {
            return (
              <li>
                {makeConfirmButton(deleteLabel, `Are you sure you want to delete ${worker.first_name} ${worker.last_name}?`, deleteCallback)}
              </li>
            )
          }
        })()}
      </ul>
    </>
  )
}
