import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { get } from 'lodash';
import { Done, Edit } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  rateTableEditWrapper: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      '& $rateIcons': {
        opacity: 1
      }
    }
  },
  rateValue: {
    fontSize: 14,
    fontWeight: 500
  },
  rateIcons: {
    opacity: 0,
    fontSize: '16px',
    marginLeft: '15px',
    marginBottom: '4px',
    cursor: "pointer"
  }
}));

export default function RateTableEdit(props) {

  const { worker, onEditRate, permissions } = props;

  const classes = useStyles();

  const [state, setState] = useState({
    edit: false,
    rate: get(worker, 'settings.hire_rate', 0)
  })

  const onEdit = () => {
    setState(prevState => ({
      ...prevState,
      edit: true
    }))
  }

  const onSave = () => {
    setState(prevState => ({
      ...prevState,
      edit: false
    }))

    onEditRate(state.rate, get(worker, '_id'))
  }

  const onChangeRate = rate => {
    setState(prevState => ({
      ...prevState,
      rate
    }))
  }

  useEffect(() => {
    setState({
      edit: false,
      rate: get(worker, 'settings.hire_rate', 0)
    })
  }, [worker])

  return (
    <div className={classes.rateTableEditWrapper}>
      {state.edit ? <TextField onChange={(e) => onChangeRate(e.target.value)} style={{ width: '50px' }} type="number" value={get(state, 'rate', 0)} /> : <span className={classes.rateValue}>{get(state, 'rate', 0)}</span>}
      {state.edit ? <Done onClick={() => onSave()} style={{ color: '#009687' }} className={classes.rateIcons} /> : get(permissions, 'canEditRate') ? <Edit onClick={() => onEdit()} className={classes.rateIcons} /> : null}
    </div>
  )
};
