import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Tab, AppBar, Box, Tabs, Typography, FormLabel, RadioGroup, FormControlLabel, Radio, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  RoundUpTimeRulesPunch,
  RoundUpTimeRulesShift
} from './index';
import { get } from 'lodash';

export default function RoundUpTimeRules(props) {

  const { rules, onEnableEditRule, onSaveRule, currentRoundUpType, onDeleteRule } = props;

  const useStyles = makeStyles((theme) => ({
    radioGroup: {
      display: 'flex',
      flexDirection: 'row'
    }
  }));

  const classes = useStyles();

  const [state, setState] = useState({
    ruleType: 'punch'
  });

  useEffect(() => {

    setState(prevState => ({
      ...prevState,
      ruleType: currentRoundUpType
    }))

  }, [currentRoundUpType])

  const onChangeTypeRule = e => {

    const value = e.target.value;

    setState(prevState => ({
      ...prevState,
      ruleType: value
    }))
  }


  return (
    <>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style={{ display: "flex" }}>
            <strong>Round Up Rules</strong>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <RoundUpTimeRulesPunch rules={get(rules, 'punch', [])} onEnableEditRule={onEnableEditRule} onSaveRule={onSaveRule} onDeleteRule={onDeleteRule} />
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style={{ display: "flex" }}>
            <strong>Break Time Rules</strong>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <RoundUpTimeRulesShift rules={get(rules, 'shift', [])} onEnableEditRule={onEnableEditRule} onSaveRule={onSaveRule} onDeleteRule={onDeleteRule} />
          </div>
        </AccordionDetails>
      </Accordion>
    </>

  )
};