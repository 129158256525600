import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { get } from 'lodash'


export default function DeleteWorker(props) {

  const { open, worker, onCloseDeleteWorker } = props;

  return (
    <Dialog
      open={open}
      onClose={onCloseDeleteWorker}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Delete Worker</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete {`${get(worker, 'first_name')} ${get(worker, 'last_name')}`}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCloseDeleteWorker(false)} color="primary">
          Disagree
        </Button>
        <Button onClick={() => onCloseDeleteWorker(true)} color="primary">
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  )
};