import React from 'react'
import { ArrowForward, ArrowBack } from '@material-ui/icons'
import { Fab } from "@material-ui/core";
import axios from "axios";

export default function PunchFlip({ shift, onUpdate }) {

  if ((shift.punch_in && shift.punch_out) || (!shift.punch_in && !shift.punch_out)) {
    return ""
  }

  const isOpen = !!shift.punch_in

  const buildPayload = () => {
    var csrfToken = $('meta[name="csrf-token"]').attr("content");

    let fd = new FormData()
    fd.append("authenticity_token", csrfToken)
    const formLabel = isOpen ? 'inverse_flip_shift' : 'flip_shift'
    fd.append(`shifts[${formLabel}[${shift._id.$oid}]]`, true)
    return fd
  }
  
  const correctShift = () => {
    if (isOpen) {
      shift.punch_out = shift.punch_in
      shift.punch_in = null
    } else {
      shift.punch_in = shift.punch_out
      shift.punch_out = null
    }
  }

  const handleSave = () => {
    const endpoint = `${location.origin}/projects/${shift.project_id.$oid}/edit_timesheet/${shift.employee_id.$oid}`
    console.log({ endpoint });
    axios.post(endpoint, buildPayload()).then(response => {
      correctShift()
      onUpdate(shift)
    }).catch(errorFlippingShift => {
      console.error({ errorFlippingShift })
    })
  }

  return (
    <Fab size="small" onClick={handleSave}>
      {(() => {
        if (isOpen) {
          return <ArrowForward />
        } else {
          return <ArrowBack />
        }
      })()}
    </Fab>
  )
}
