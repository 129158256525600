import axios from 'axios';
import { axiosConfig } from '../helpers/index';
import { get, isNull } from 'lodash';

export const updateProjectSettings = (project_endpoint, project_id, data, company_id = null,) => {
  return axios.patch(`${project_endpoint}/update-project-setting/${project_id}${isNull(company_id) ? '' : `/${company_id}`}`, { data }, axiosConfig())
}

export const updateProjectRules = (project_endpoint, project_id, data) => {
  return axios.patch(`${project_endpoint}/updateProjectTimeRules/${project_id}`, { data }, axiosConfig())
}

export const deleteProjectTimeRule = (project_endpoint, rule_id) => {
  return axios.delete(`${project_endpoint}/delete-time-rule/${rule_id}`, axiosConfig())
}


export const getProjectVendors = (project_endpoint, project_id) => {
  return axios.get(`${project_endpoint}/getVendors/${project_id}`, axiosConfig())
}

export const getProjectTimeRules = (project_endpoint, project_id, company_id) => {
  return axios.get(`${project_endpoint}/getProjectTimeRules/${project_id}/${company_id}`, axiosConfig())
}

// Procore

export const getErrorVendors = (domain, project, company) => {
  console.log("el domain ", domain)
  return axios.get(`${domain}/procore-vendors-list/${get(company, '_id.$oid')}/${get(project, '_id.$oid')}`, axiosConfig())
}

export const importingCostCodes = (domain, payload) => {
  console.log("el domain ", domain)
  return axios.post(`${domain}/procore-import-costcodes`, { ...payload }, axiosConfig())
}

export const updateErrorVendor = (domain, project, company, payload) => {
  return axios.post(`${domain}/procore-update-error-vendor/${get(company, '_id.$oid')}/${get(project, '_id.$oid')}`, payload, axiosConfig())
}

export const syncingVendors = (domain, proc_proj_id) => {
  return axios.get(`${domain}/procore-sync/${proc_proj_id}`, axiosConfig())
}

export const syncProcoreVendor = (domain, project, company, payload) => {

  return axios.post(`${domain}/sync-procore-vendor/${get(company, '_id.$oid')}/${get(project, '_id.$oid')}`, payload, axiosConfig())
}

export const syncProcoreTimesheets = (domain, payload) => {
  return axios.post(`${domain}/timesheet-procore-dashboard-sync`, payload, axiosConfig())
}

export const getProcoreProjectDetails = (domain, procoreId) => {
  return axios.get(`${domain}/procore-project-details/${procoreId}`, axiosConfig())
}

export const getDashboardTimesheetStatus = (domain, payload) => {
  return axios.post(`${domain}/timesheet-procore-dashboard-status`, payload, axiosConfig())
}

export const syncWorkersCMIC = (domain) => {
  return axios.get(`${domain}/cmic-workers`, axiosConfig())
}

export const getRevokeCMICWorkerURL = domain => {
  return axios.get(`${domain}/revoke-cmic-workers`, axiosConfig())
}

export const getUiConfiguration = (dashboardEndpoint, projectId) => {
  return axios.get(`${dashboardEndpoint}/uiSettings/projectdashboard/${projectId}`, axiosConfig())
}

export const getRevokeList = (dashboardEndpoint) => {
  return axios.get(`${dashboardEndpoint}`, axiosConfig())
}

export const clearSetting = (project_endpoint, entryName, id) => {
  return axios.delete(`${project_endpoint}/delete-setting-entry/${entryName}/${id}`, axiosConfig())
}

export const syncingProcoreDailyLogs = (domain, project_id, date) => {
  return axios.get(`${domain}/sync-procore-dailylog/${project_id}/${date}`, axiosConfig())
}
