import React, { useState, useEffect } from 'react'
import { get, isEmpty, isEqual, cloneDeep, size } from 'lodash'

import Tooltip from '@material-ui/core/Tooltip'
import Popover from '@material-ui/core/Popover'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import ListItemText from '@material-ui/core/ListItemText'

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import { makeStyles } from '@material-ui/core/styles'

import { myTheme } from '../../../../../helpers'

export default function ShiftClassifiers(props) {

  const { classifiers, companyData, workerData, editMode, shift, handleActionDialogPopover } = props;

  const useStyles = makeStyles((theme) => ({
    classifiersPopoverPaper: {
        marginTop: 4,
        marginLeft: -2,
        overflowX: "unset",
        overflowY: "unset",
        border: "2px solid #f15922",
        "&::before": {
            content: '""',
            position: "absolute",
            marginRight: "-0.72em",
            top: -10,
            right: '50%',
            width: 10,
            height: 10,
            border: "2px solid #f15922",
            backgroundColor: "#f15922",
            boxShadow: theme.shadows[1],
            transform: "translate(-50%, 50%) rotate(315deg)",
            clipPath: "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
        },
    },
    select: {
        width: '250px'
    },
    classifierThumbnail: {
      width: 13,
      height: 13,
      border: '2px solid lightgrey',
      borderRadius: 4,
      padding: '2px',
      marginRight: 2,
      // marginBottom: 2
    },
    shiftClassifiersButton: {
      display: 'flex',
      width: 60,
      flexWrap: 'wrap',
      justifyContent: 'flext-start',
      alignItems: 'center',
      cursor: 'pointer'
    }
  }));

  const classes = useStyles();

  const [state, setState] = useState({
    activityLogs: [],
    isOpen: false
  })

  const handleClassifiersClick = (event) => {
    setState(prevState => ({
      ...prevState,
      isOpen: !prevState.isOpen,
      classifiersAnchorEl: event.currentTarget,
      selectedClassifiers: {},
      triggerReset: !prevState.triggerReset
    }))
  }

  const handleClearAllClassifiers = (event) => {
    setState(prevState => ({
      ...prevState,
      selectedClassifiers: {}
    }))
  }

  const handleApplyClassifiers = () => {
      let payload = { classifiers: [] }

      Object.keys(get( state, 'selectedClassifiers')).map(oneClassifierName => payload.classifiers.push(get( state, 'selectedClassifiers')[oneClassifierName]))

      setState(prevState => ({
        ...prevState,
        classifiersAnchorEl: null,
        isOpen: !prevState.isOpen,
        selectedClassifiers: {}
      }))

      handleActionDialogPopover('applyClassifiers', payload, { shift, workerId: workerData._id, companyId: companyData._id })
  }

  const handleClassifierSelect = (event, shiftId, classifier) => {
      let tempItem = {}

      get(classifier, 'items', []).map(oneItem => {
          if (isEqual(get(oneItem, 'code'), get(event.target, 'value'))) {
              tempItem = oneItem
          }
      })

      setState(prevState => {
        let selectedClassifiers = { ...prevState.selectedClassifiers }

        if (get(tempItem, 'code')) {
          selectedClassifiers[get(classifier, 'name')] = {
              classifierId: get(classifier, '_id', ''),
              classifierName: get(classifier, 'name', ''),
              itemCode: get(tempItem, 'code', ''),
              itemColor: get(tempItem, 'color', '')
          }
        } else {
          delete selectedClassifiers[get(classifier, 'name')]
        }

        return ({
          ...prevState,
          selectedClassifiers
        })
      })

  }

  useEffect(() => {
    let selectedClassifiers = {}

    get(shift, 'appliedClassifiers.classifiers', []).map(oneShiftClassifier => selectedClassifiers[oneShiftClassifier.classifierName] = oneShiftClassifier)

    setState(prevState => ({
      ...prevState,
      selectedClassifiers,
    }))

    return () => {}
  }, [state.triggerReset, shift.appliedClassifiers])

  const renderClassifiersButton = () => {
    let array = cloneDeep(classifiers)

    if (size( array) > 4) {
      array = array.concat(Array(8 - size(array)).fill({}))
    }


    if (size( array) < 4) {
      array = array.concat(Array(4 - size(array)).fill({}))
    }

    return (
      <div className={classes.shiftClassifiersButton}>
        { array.map((oneCompanyClassifier, oneCompanyClassifierIndex) =>
            <div
              key={get(oneCompanyClassifier, '_id', '') + oneCompanyClassifierIndex}
              className={classes.classifierThumbnail}
              style={{ marginTop: oneCompanyClassifierIndex > 3 ? 2 : 0, backgroundColor: get( get( get( state, 'selectedClassifiers'), get(oneCompanyClassifier, 'name')), 'itemColor', 'transparent') }}
            />
          )
        }
      </div>
    )
  }

  return (
    <div style={{ display: 'flex' }}>
      <Tooltip title="Classifiers" arrow>
        <Button
          onClick={handleClassifiersClick} size='small'>
          { renderClassifiersButton() }
        </Button>
      </Tooltip>
      { get( state, 'isOpen') &&
        <Popover
          classes={{ paper: classes.classifiersPopoverPaper }}
          open={get( state, 'isOpen')}
          onClose={handleClassifiersClick}
          anchorEl={get( state, 'classifiersAnchorEl')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <div style={{
              padding: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {
                !isEmpty(classifiers) && classifiers.map((oneClassifier) =>
                  <div key={oneClassifier._id} style={{ display: 'flex' }}>
                    <FormControl style={{ width: 200, marginBottom: 12 }}>
                      <InputLabel
                        id={`${oneClassifier.name}-select-label`}>{oneClassifier.name}</InputLabel>
                      <Select
                        disabled={workerData.workerRowLoading || !editMode || shift.approved}
                        required
                        classes={{ root: classes.select }}
                        id={`${oneClassifier.name}-select`}
                        value={get( get( get( state, 'selectedClassifiers'), get(oneClassifier, 'name')), 'itemCode', '')}
                        onChange={(event) => { handleClassifierSelect(event, shift._id, oneClassifier) }}>
                        { !isEmpty(get( get( get( state, 'selectedClassifiers'), get(oneClassifier, 'name')), 'itemCode', '')) &&
                          <MenuItem key={'000'} value="" style={{ color: 'grey' }}>None</MenuItem>
                        }
                        {
                          oneClassifier.items.map(item =>
                            <MenuItem key={get(item, 'code')} value={get(item, 'code')}>
                              <div style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}>
                                <ListItemText primary={get(item, 'name')} />
                                {item.color &&
                                  <FiberManualRecordIcon style={{ color: item.color }} />}
                                  </div>
                                </MenuItem>
                          )
                        }
                      </Select>
                    </FormControl>
                  </div>
                )
              }
              {
                !isEmpty(classifiers) && editMode && !shift.approved && !shift.deleted &&
                  <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button onClick={handleClearAllClassifiers} color="default">
                      Clear
                    </Button>
                    <Button onClick={handleApplyClassifiers} color="primary">
                      Apply
                    </Button>
                  </div>
              }
              {
                isEmpty(classifiers) &&
                <div>No active classifiers to select from.</div>
              }
            </div>
          </div>
        </Popover>
      }
    </div>
  )
}
