import React, { useState } from 'react'
import { Button, Modal } from "react-bootstrap";
import axios from "axios"

const TransferShift = ({ shiftId }) => {

  const [show, setShow] = useState(false);
  const [punches, setPunches] = useState([]);
  const [shift, setShift] = useState({});

  const handleClose = () => setShow(false);
  const openModal = () => {
    loadPunches().then(response => {
      console.log({ response });

      setPunches(response.data)
    })
    
    loadShift().then(response => {
      setShift(response.data)
    })

    setShow(true)
  }

  const loadPunches = () => {
    let url = `${window.location.protocol}//${window.location.host}/shifts/${shiftId}/punches_after`
    return axios.get(url).catch(console.error)
  }
  
  const loadShift = () => {
    let url = `${window.location.protocol}//${window.location.host}/shifts/${shiftId}`
    return axios.get(url).catch(console.error)
  }
  
  const createTransfer = (_outShift, _in) => {
    const conf = confirm(`
      Are you sure you want to create a shift with from
      ${_outShift.punch_out_time} to ${_in.punch_time} for ${_outShift.employee.first_name} ${_outShift.employee.last_name}`)

    if (conf) {
      let token = document.querySelector("meta[name='csrf-token']").content
      let url = `${window.location.protocol}//${window.location.host}/shifts`
      // Reverse order because it's creating a transfer
      axios.post(url, {
        authenticity_token: token,
        transfer: {
          punch_out_id: _outShift.punch_out_id.$oid,
          punch_in_id: _in.id,
        }
      }).then(response => {
        setShow(false)
        location.reload()
      }).catch(error => {
        toastr.error("An Error Ocurred")
      })
    }
  }

  let employeeLine = ""
  if (shift.employee) {
    employeeLine = `${shift.employee.first_name} ${shift.employee.last_name} on ${shift.punch_out_time}`
  }

  return (
    <>
      <Button variant="secondary" onClick={openModal} className="btn-sm">
        <i className="fa fa-arrow-right"></i>
      </Button>

      <Modal show={show} onHide={handleClose} size="lg" animation={false} backdrop={true} backdropClassName="modal-backdrop fade in">
        <Modal.Header closeButton>
          <Modal.Title>Create Transfer Shift for <strong>{employeeLine}</strong> </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <table className="table">
            <thead>
              <tr>
                <th scope="col">Punch In Time</th>
                <th scope="col">Type</th>
                <th scope="col">Employee</th>
                <th scope="col">Project</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {
                punches.map(punch => {
                  return (
                    <tr key={punch.id}>
                      <td>{punch.punch_time}</td>
                      <td>{punch.type}</td>
                      <td>{`${punch.employee.first_name} ${punch.employee.last_name}`}</td>
                      <td>{punch.project.name}</td>
                      <td>
                        <Button onClick={() => createTransfer(shift, punch)} className="btn-sm">Transfer</Button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>


        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TransferShift;