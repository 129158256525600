import React, { useEffect, useState, useContext } from 'react';
import { Drawer, makeStyles, Button, CircularProgress, FormControlLabel, TextField, FormControl, InputLabel, MenuItem, FormLabel, RadioGroup, Radio, Tooltip } from '@material-ui/core';
import Select from 'react-select';
import { getProjectTimeRules, updateProjectRules, deleteProjectTimeRule, updateProjectSettings } from '../services';
import { get, size, has, set, update } from 'lodash'
import { Cancel, Send } from '@material-ui/icons';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { notificationCenter } from '../../helpers/notifications';
import { RoundUpTimeRules } from './index'
import { SettingsContext } from '../contexts/SettingsContext';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { SBSwitch } from '../../reusableComponents/index';
import Skeleton from '@material-ui/lab/Skeleton';

import { useMediaQuery } from 'react-responsive';

const drawerSize = () => {

  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 480 });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 768 });
  const isLaptop = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1025 });

  switch (true) {
    case isMobile:
      return '100%'
    case isTablet:
      return '90%'
    case isLaptop:
      return '75%';
    case isDesktop:
      return '30%';
    default:
      return "80%";
  }



}

export default function TimeRules(props) {

  const { openRules, onShowTimeRules, company, project, project_endpoint, user } = props;

  const settingsContext = useContext(SettingsContext);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%"
    },
    drawerRoot: {
      width: drawerSize()
    },
    mainContent: {
      backgroundColor: '#f3f3f4',
      height: "100%",
      width: "100%",
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    avatarLarge: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    deviceList: {
      padding: "4px",
      borderRadius: "2px",
      fontWeight: "600",
      backgroundColor: "#818a91",
      color: "white",
      marginRight: "5px",
      marginBottom: '5px'
    },
    accordionTextField: {
      marginRight: '15px',
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: "100%",
      marginBottom: '20px'
    },
    radioGroup: {
      display: 'flex',
      flexDirection: 'row'
    },
    oneMainToggleWRapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 600,
      color: '#7a7a7a',
      textTransform: 'capitalize',
      marginBottom: 8,
      paddingLeft: 4,
      marginTop: 12
    },
    toggleHelpIcon: {
      fontSize: 14,
      marginLeft: 4,
      color: '#7a7a7a'
    },
    projectInfoWrapper: {
      display: "flex",
      padding: 12,
      borderBottom: `2px solid ${theme.palette.primary.main}`
    }
  }));

  const classes = useStyles();

  const [state, setState] = useState({
    project: project,
    editing: false,
    currentRoundUpType: 'punch',
    openDrawer: false,
    projectSettings: null,
    loading: false,
    rules: {},
    companies: null,
    currentCompany: {
      value: get(company, '_id.$oid'),
      label: get(company, 'name')
    },
    round_up_rule: "0"
  });

  const gettingProjectTimeRules = async (activeTap = 'punch') => {
    try {


      const res = await getProjectTimeRules(project_endpoint, get(project, '_id.$oid'), get(state.currentCompany, 'value'));

      setState(prevState => ({
        ...prevState,
        openDrawer: true,
        loading: false,
        companies: get(res, 'data.companies', []),
        rules: get(res, 'data.rules', {}),
        disableSettingsInheritance: get(res, 'data.disableSettingsInheritance', false),
        round_up_rule: get(res, 'data.round_up_rule').toString(),
        currentRoundUpType: activeTap
      }))
    } catch (error) {
      notificationCenter("error", get(error, 'response.data.error', 'Error'))
    }
  }

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      loading: true
    }))
    gettingProjectTimeRules();
  }, [state.currentCompany]);


  const onUpdateProjectSettings = async (name, value) => {
    try {

      const res = await updateProjectSettings(project_endpoint, get(project, '_id.$oid'), { [name]: value }, get(state.currentCompany, 'value'));
    } catch (error) {
      notificationCenter("error", get(error, 'response.data.error', 'Error'))
    }
  }

  const onChangeCompany = value => {
    setState(prevState => ({
      ...prevState,
      currentCompany: value
    }))
  }

  const onChangeDefaultRule = e => {
    const value = get(e.target, 'value');

    setState(prevState => ({
      ...prevState,
      round_up_rule: value,
      projectSettings: {
        ...prevState.projectSettings,
        round_up_rule: value
      }
    }));

    onUpdateProjectSettings('round_up_rule', parseInt(value));


  }

  const handleToggle = (event) => {
    const value = get(event.target, 'checked');

    setState(prevState => ({
      ...prevState,
      disableSettingsInheritance: value
    }));

    onUpdateProjectSettings('disableSettingsInheritance', value);
  }

  const onEnableEditRule = (type, id) => {

    setState(prevState => ({
      ...prevState,
      rules: {
        ...prevState.rules,
        [type]: get(prevState, `rules.${type}`, []).map(item => {
          if (get(item, '_id') === id) {
            set(item, 'editStatus', true)
          }

          return item;
        })
      }
    }))

  }

  const onSaveRule = async (rule, type = 'punch') => {
    try {
      let payload = {
        rule: { ...rule },
        round_up_rule: state.round_up_rule,
        onEditRule: true
      }
      if (get(rule, 'status', '') === 'new') {
        payload = {
          ...payload,
          onEditRule: false,
          rule: {
            ...rule,
            company_id: get(state.currentCompany, 'value'),
            project_id: get(project, '_id.$oid'),
          }
        }
      }

      const res = await updateProjectRules(project_endpoint, get(project, '_id.$oid'), payload);

      setState(prevState => ({
        ...prevState,
        rules: {
          ...prevState.rules,
          [type]: payload.onEditRule ? get(state.rules, type, []).map(item => {
            if (get(item, '_id') === get(res, 'data._id')) {
              return get(res, 'data')
            }

            return item;
          }) : [...get(prevState.rules, type, []), get(res, 'data')]
        }
      }))

      // gettingProjectTimeRules(type)
    } catch (error) {
      console.log(error)
    }
  }

  const onDeleteRule = async (id, type = "punch") => {

    try {
      const res = await deleteProjectTimeRule(project_endpoint, id);
      gettingProjectTimeRules(type);
    } catch (error) {
      console.log(error)
    }

  }

  const renderRoundUpRulesSkeleton = () => {
    return(
      <div>
        <Skeleton variant="text" animation='wave' height={40} width={150} style={{ marginTop: 6 }} />
        <Skeleton variant="text" animation='wave' height={40} width={280} style={{ marginTop: -6 }} />
        <Skeleton variant="text" animation='wave' height={80} style={{ marginTop: -6 }} />
        <Skeleton variant="text" animation='wave' height={80} style={{ marginTop: -30 }} />
      </div>
    )
  }

  const renderWholePageSkeleton = () => {
    return(
      <div>
        <Skeleton variant="text" animation='wave' height={60} style={{ marginTop: 6, marginBottom: 6 }} />
        { renderRoundUpRulesSkeleton() }
      </div>
    )
  }

  return (
    <Drawer disableBackdropClick={true} classes={{ paper: classes.drawerRoot }} anchor={'right'} open={openRules} onClose={onShowTimeRules}>
      <div className={classes.projectInfoWrapper}>
        <ApartmentIcon style={{ fontSize: "50px", color: '#ed5723' }} />
        <div style={{ display: "flex", flexDirection: 'column', marginLeft: '10px' }}>
          <strong style={{ fontSize: "16px" }}>{get(state.project, 'name', '')}</strong>
          <span>{`${get(state.project, 'address', '')} , ${get(state.project, 'city', '')} ${get(state.project, 'state', '')} ${get(state.project, 'zip',
          '')}`}</span>
        </div>
      </div>

      <div className={classes.mainContent} >
        <div className="worker-form-information">

          {!state.openDrawer ? renderWholePageSkeleton() :
            <>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                placeholder="Select Company..."
                value={state.currentCompany}
                name="color"
                onChange={onChangeCompany}
                options={state.companies}
              />

              {
                state.loading ? <Skeleton variant="text" animation='wave' height={40} style={{ marginTop: 6 }} /> :
                !get(state, 'currentCompany.is_smartbarrel_client') ?
                <div className={classes.oneMainToggleWRapper} style={{ color: get(state, 'disableSettingsInheritance', false) ? '#f15922' : '#7a7a7a' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>Disable Settings Inheritance</span>
                    <Tooltip arrow title='Turn this on to disable inehriting the time rules from the company.'><HelpOutlineIcon className={classes.toggleHelpIcon}/></Tooltip>
                  </div>
                  <SBSwitch checked={get(state, 'disableSettingsInheritance', false)} handleToggle={handleToggle} noMargin name='disableSettingsInheritance' />
                </div> : null
              }

              {state.loading ? renderRoundUpRulesSkeleton() :

                <>
                  <div style={{ marginTop: '20px', paddingLeft: 4 }}>
                    <FormLabel>Default Round Up Rules</FormLabel>
                    <RadioGroup className={classes.radioGroup} aria-label="gender" name="round_up_rule" value={state.round_up_rule} onChange={onChangeDefaultRule}>
                      <FormControlLabel value="0" control={<Radio color='primary' />} label="None" />
                      <FormControlLabel value="1" control={<Radio color='primary' />} label="7 Minutes" />
                      <FormControlLabel value="2" control={<Radio color='primary' />} label="15 Minutes" />
                    </RadioGroup>
                  </div>

                  <RoundUpTimeRules rules={state.rules} onEnableEditRule={onEnableEditRule} onSaveRule={onSaveRule} currentRoundUpType={state.currentRoundUpType} onDeleteRule={onDeleteRule} />

                </>



              }

            </>
          }
        </div>
        <div>
          <Button
            variant='outlined'
            onClick={() => onShowTimeRules(false)}>
            Close
          </Button>
        </div>
      </div>





    </Drawer>
  )
};
