import React from 'react'
import { TextField, Select, MenuItem, FormControl, FormControlLabel, Switch, Radio, RadioGroup, FormLabel, InputLabel, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons'
import PhoneField from "../PhoneField";
import { get, isEmpty, isUndefined, isNull } from 'lodash';

import enIcon from '../../../../assets/images/en-checked.svg';
import enIconOff from '../../../../assets/images/en-unchecked.svg';
import esIcon from '../../../../assets/images/es-checked.svg';
import esIconOff from '../../../../assets/images/es-unchecked.svg';

const useStyles = makeStyles((theme) => ({

  workerPhone: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: '30px',
    marginTop: '10px'
  },
  selectCompany: {
    marginTop: '10px'
  },
  formControlCompany: {
    width: '100%'
  },
  inputMore: {
    width: ""
  }
}));


export default function WorkerInfo(props) {

  const { state, onChange, classes, addWorkerCompanies, worker, onChangeCompanyTitle } = props;

  const workerInfoClasses = useStyles();

  const handleChange = (ev) => {

    if (ev) {
      const name = ev.target.name;
      let value = '';



      if (["synthetic_phone", "needs_safety"].indexOf(name) != -1) {
        value = ev.target.checked;
      } else {
        value = ev.target.value;
      }

      onChange({ prop: name, val: value })
    }

  }

  const onHandleCompanies = (ev) => {
    let currentCompany = addWorkerCompanies.find(item => get(item, 'value') === ev.target.value);
    onChangeCompanyTitle(get(currentCompany, 'label'))
    onChange({ prop: 'company_ids', val: [ev.target.value] })
  }

  const onHandleWorkerLevel = ev => {
    onChange({ prop: 'worker_level', val: ev.target.value })
  }

  const isDisabled = () => !isNull(worker) && !isUndefined(worker)

  return (

    <form>
      <div >
        <TextField
          required
          InputProps={{
            readOnly: isDisabled(),
          }}
          className={classes.input}
          type="text"
          label="First Name"
          name="first_name"
          onChange={handleChange}
          value={state.first_name} />

      </div>
      <div>
        <TextField
          required
          InputProps={{
            readOnly: isDisabled(),
          }}
          className={classes.input}
          type="text"
          label="Last Name"
          name="last_name"
          onChange={handleChange}
          value={state.last_name}
        />
      </div>


      {!isUndefined(addWorkerCompanies) && !isEmpty(addWorkerCompanies) ?
        <div className={workerInfoClasses.selectCompany}>
          <FormControl className={workerInfoClasses.formControlCompany}>
            <InputLabel>Company <span>*</span></InputLabel>
            <Select
              value={state.company_ids[0]}
              onChange={onHandleCompanies}
            >
              {addWorkerCompanies.map((company, idx) => (
                <MenuItem key={idx} value={get(company, 'value')}>{get(company, 'label')}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div> : null
      }

      <div style={{ marginBottom: '10px' }}>
        <PhoneField
          disabled={state.synthetic_phone}
          readOnly={isDisabled()}
          required={true}
          label="Phone Number"
          name="phone_number"
          onChange={handleChange}
          className={classes.input}
          value={state.phone_number} />
      </div>

      <div style={{ marginBottom: '30px' }}>
        <FormControlLabel
          control={
            <Switch color='primary' disabled={isDisabled()} checked={state.synthetic_phone} onChange={handleChange} name="synthetic_phone" />
          }
          label="Generate new phone number"
        />
      </div>

      <div className='d-flex justify-content-center'>
        <FormControl >
          <FormLabel>Select language</FormLabel>

          <RadioGroup
            name="language_code"
            row
            value={state.language_code}
            onChange={handleChange}>
            <FormControlLabel
              value="en"
              control={
                <Radio
                  icon={<img src={enIconOff} width='35' />}
                  checkedIcon={<img src={enIcon} width='35' />}
                  checked={state.language_code === 'en'} />
              }
            />
            <FormControlLabel
              value="es"
              control={
                <Radio
                  icon={<img src={esIconOff} width='35' />}
                  checkedIcon={<img src={esIcon} width='35' />}
                  checked={state.language_code == 'es'} />
              }
            />
          </RadioGroup>

        </FormControl>
      </div>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <span>Additional Information</span>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <TextField
              type="text"
              label="Email"
              name="email"
              style={{ marginBottom: "15px" }}
              // className={classes.input}
              onChange={handleChange}
              value={get(state, 'email', '')} />


            <TextField
              type="number"
              label="Decal"
              name="decal"
              // className={classes.input}
              style={{ marginBottom: "15px" }}
              onChange={handleChange}
              value={get(state, 'decal', '')} />



            <FormControl >
              <InputLabel>Worker Level</InputLabel>
              <Select
                value={state.worker_level}
                onChange={onHandleWorkerLevel}
              >
                <MenuItem key='journeyman' value='journeyman'>Journeyman</MenuItem>
                <MenuItem key='foreman' value='foreman'>Foreman</MenuItem>
                <MenuItem key='apprentice' value='apprentice'>Apprentice</MenuItem>
                <MenuItem key='other' value='other'>Other</MenuItem>
              </Select>
            </FormControl>
          </div>
        </AccordionDetails>
      </Accordion>

    </form>

  )
}
