import React, { useState } from 'react'
import axios from "axios";

export default function DeviceComment({ comment, deviceId, onSaved, isEditing }) {

    const [text, setText] = useState(comment.text);
    const [editing, setEditing] = useState(isEditing);

    const displaySection = () => {
        return (
            <tr>
                <td><strong>{comment.created_by.first_name} {' '} {comment.created_by.first_name}</strong></td>
                <td><strong>{moment(comment.updated_at).format("MM/DD/YYYY hh:mm A")}</strong></td>
                <td style={{whiteSpace: "break-spaces"}}>{comment.text}</td>
                <td>
                    <button className="btn btn-sm btn-warning btn-outline" onClick={toggleEditing}>Edit</button>
                    <button className="btn btn-sm btn-danger btn-outline" onClick={deleteComment}>Delete</button>
                </td>
            </tr>
        )
    }

    const editingSection = () => {
        return (
            <tr>
                <td colSpan="4">
                    <textarea style={{whiteSpace: "break-spaces"}} className="form-control" value={text} rows="3" onChange={handleChange}></textarea>
                    <button className="btn btn-sm btn-warning btn-outline" disabled={text.length == 0} onClick={saveEdit}>Save</button>
                    <button className="btn btn-sm btn-danger btn-outline" onClick={toggleEditing}>Cancel</button>
                </td>
            </tr>
        )
    }

    const handleChange = (ev) => {
        setText(ev.target.value)
    }

    const handleUpdate = ev => {
        ev.preventDefault()
        const url = `${location.protocol}//${location.host}/devices/${deviceId}/device_comments/${comment.id.$oid}.json`
        const token = document.querySelector("meta[name='csrf-token']").content
        axios.put(url, {
            authenticity_token: token,
            device_comment: {
                text: text
            }
        }).then(response => {
            console.log("Updated response", { response });
            onSaved()
            setEditing(false)
        })
    }

    const handleCreate = ev => {
        ev.preventDefault()
        const url = `${location.protocol}//${location.host}/devices/${deviceId}/device_comments.json`
        const token = document.querySelector("meta[name='csrf-token']").content
        axios.post(url, {
            authenticity_token: token,
            device_comment: {
                text: text
            }
        }).then(response => {
            console.log("Updated response", { response });
            onSaved()
            // setEditing(false)
        })
    }

    const saveEdit = (ev) => {
        ev.preventDefault()
        if (comment.id) {
            return handleUpdate(ev)
        } else {
            return handleCreate(ev)
        }
    }

    const deleteComment = ev => {
        ev.preventDefault()
        const conf = confirm("Are you sure?")
        if (conf) {
            const url = `${location.protocol}//${location.host}/devices/${deviceId}/device_comments/${comment.id.$oid}.json`
            const token = document.querySelector("meta[name='csrf-token']").content
            axios.delete(url, {
                data: {
                    authenticity_token: token,
                }
            }).then(response => {
                console.log("Updated response", { response });
                onSaved()
                setEditing(false)
            })
        }
    }

    const toggleEditing = (ev) => {
        ev.preventDefault()
        const p = !editing
        if (!comment.id) {
            // Provoking reload
            onSaved()
        } else {
            setEditing(p)
        }
    }

    if (editing) {
        return editingSection()
    } else {
        return displaySection()
    }

}
