import React, { useState } from 'react';
import axios from "axios";
import IndividualForm from "./IndividualForm"
import SingleCompanyCostcode from './SingleCompanyCostcode';


const CompanyCostcodes = ({ shift, company, costcodesTemplate, costcodes, endpoint, readOnly }) => {

  //console.log({ shift, costcodesTemplate, costcodes });
  const [counter, setCounter] = useState(0);
  const [needsUpdate, setNeedsUpdate] = useState(false);

  const createAxios = () => {
    return axios.create({
      withCredentials: true
    })
  }

  const reload = e => {
    console.log(e);

    createAxios().get(`/shifts/${shift._id.$oid}/${company._id.$oid}/company_costcodes.json`).then(resp => {
      console.log(resp);

      // costcodes = resp.data
      costcodes.splice(0)
      const new_records = resp.data
      costcodes.unshift(...new_records)
      if (needsUpdate) {
        setNeedsUpdate(false)
      } else {
        setCounter(counter + 1)
      }
    }).catch(console.error)
  }

  // External situations that requires to re render this widget
  window.addEventListener("costcodes-refresh", ev => {
    reload(ev)
  })

  const handleSave = (cc) => {
    console.log("About to save ", cc);
    // Doesnt have an id
    if (cc._id.$oid) {
      createAxios().patch(`${endpoint}/${cc._id.$oid}.json`, { company_costcode: cc }).then(rs => {
        console.log({ rs });
      }).catch(err => {
        console.log({ err });
        const index = costcodes.indexOf(cc);
        costcodes[index].costcode_id = err.response.data.costcode_id.$oid
        costcodes[index].hours = err.response.data.hours
        setCounter(counter + 1)
      })
    } else {
      createAxios().post(`${endpoint}.json`, { company_costcode: cc }).then(rs => {
        const index = costcodes.indexOf(cc);
        costcodes[index]._id.$oid = rs.data._id.$oid
        setCounter(counter + 1)
      }).catch(err => {
        console.log({ err });
        const index = costcodes.indexOf(cc);
        costcodes.splice(index, 1)
        setCounter(counter + 1)
      })
    }
  }
  const handleDelete = (cc) => {
    console.log("About to delete ", cc);

    axios.delete(`${endpoint}/${cc._id.$oid}`).then(rs => {
      // const index = costcodes.indexOf(cc);
      // costcodes.splice(index, 1)
      // setCounter(counter + 1)
      reload()
    }).catch(console.error)
  }

  const handleChange = cc => {

    const index = costcodes.indexOf(cc)
    console.log({ cc, index });
    costcodes[index].unsaved = true;
    costcodes[index].auto = false;
    setNeedsUpdate(true)
    setCounter(counter + 1)
  }

  const addNew = (e) => {
    if (!readOnly) {
      costcodes.push({
        _id: {},
        costcode_id: {},
        shift_id: shift._id.$oid,
        company_id: company._id.$oid,
        hours: 0
      })
      setCounter(counter + 1)
    }
  }

  let saving = false

  const saveAll = e => {
    if (!saving) {
      saving = true
      createAxios().post(`/shifts/${shift._id.$oid}/bulk_company_costcodes`, {company_costcodes: costcodes}).then(res => {
        reload()
        saving = false
      }).catch(err => {
        toastr.error(`An Error Occurred ${err.response.data.error || ""}`)
        saving = false
        console.log(err);
      })
    }
  }

  if (costcodes.length == 0) {
    addNew();
  }
  if (costcodes.length == 1) {
    return (
      <>
      <SingleCompanyCostcode readOnly={readOnly} shift={shift} company={company}
      costcodesTemplate={costcodesTemplate} costcodes={costcodes} endpoint={endpoint} />
        <div style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
          <button className="empty-button" onClick={addNew} hidden={readOnly}>
            <i className="fa fa-plus"></i>
          </button>
        </div>
      </>
    );
  } else
  return (
    <>
      {costcodes.map(cc => {
        const unsaved = cc.unsaved ? (
            <span style={{position:"absolute",left:0, top:'10px'}}>
              <i className="fa fa-circle"></i>
            </span>
        ) : ""

        return (
          <div className="costcode-container" style={{position:"relative"}}>
            {unsaved}
            <IndividualForm key={cc._id.$oid} options={costcodesTemplate} costcode={cc} number={true} onChange={handleChange} onSave={handleSave} onDelete={handleDelete} readOnly={readOnly}></IndividualForm>
          </div>
        )
      })}

      <div className="costcode-container" style={{
        justifyContent:  needsUpdate ? 'space-between': 'flex-end',
        marginTop: '5px',
        paddingRight: '5px',
        position: 'relative'
      }}>
        {(()=> {
          if (needsUpdate) {
            return (
                <button className="empty-button" onClick={saveAll}>
                  <i className="fa fa-check"></i>
                </button>
            )
          }
        })()}
        <button className="empty-button" onClick={addNew} hidden={readOnly}>
          <i className="fa fa-plus"></i>
        </button>
      </div>
    </>
  );
}

export default CompanyCostcodes
