import React from 'react'
import WorkersBar from './WorkersBar'
import WorkersTodayPie from './WorkersTodayPie'

export default function SuperadminAnalytics(props) {

  const { todayPerCompany, todayPerProject, last30DaysCreated, last30DaysUniqueWorkers } = props


  return (
    <div className="panel">
      <div className="panel-body">
        <div className="row">
          <div className="col-md-6">
            <WorkersTodayPie title="Workers Today by Company" data={todayPerCompany}/>
          </div>
          <div className="col-md-6">
            <WorkersTodayPie title="Workers Today by Project" data={todayPerProject}/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <WorkersBar title="Workers Created Last 30 Days" data={last30DaysCreated}/>
          </div>
          <div className="col-md-6">
            <WorkersBar title="Unique Workers Last 30 Days" data={last30DaysUniqueWorkers}/>
          </div>
        </div>
      </div>
    </div>
  )
}
