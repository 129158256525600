import React, { useState } from "react"
import axios from "axios";
import IndividualForm from "./IndividualForm"

const SingleCompanyCostcode = ({ shift, company, costcodesTemplate, costcodes, endpoint, readOnly }) => {
  const newDefault = {
      _id: {},
      costcode_id: {},
      shift_id: shift._id.$oid,
      company_id: company._id.$oid,
      hours: 0
  }
  const costcode = costcodes.length > 0 ? costcodes[0] : newDefault

  const [costcodeState, setCostcodeState] = useState(costcode);

  const createAxios = () => {
    return axios.create({
      withCredentials: true
    })
  }
  
  const handleSave = cc => {
    // console.log("About to save ", cc);
  }
  const handleDelete = cc => {
      createAxios().delete(`${endpoint}/${cc._id.$oid}.json`, { company_costcode: cc }).then(rs => {
        console.log({ rs });
        // setCostcodeState(newDefault)
        setCostcodeState({ ...newDefault })
      }).catch(err => {
        console.log({ err });
        toastr.error(`An Error Occurred ${err.response.data.error || ""}`)
      })
  }
  const handleChange = cc => {
    console.log("About to save ", cc);
    // This means it's a delete request: The users selected empty item in dropdown
    if (cc._id.$oid && Object.keys(cc.costcode_id).length == 0) {
      return handleDelete(cc)
    }
    if (cc._id.$oid) {
      createAxios().patch(`${endpoint}/${cc._id.$oid}.json`, { company_costcode: cc }).then(rs => {
        console.log({ rs });
      }).catch(err => {
        console.log({ err });
        toastr.error(`An Error Occurred ${err.response.data.error || ""}`)
      })
    } else {
      createAxios().post(`${endpoint}.json`, { company_costcode: cc }).then(rs => {
        console.log("response", rs);
        console.log("response", rs.data);

        costcodeState._id = rs.data._id
        setCostcodeState({ ...costcodeState })
      }).catch(err => {
        console.log({ err });
        toastr.error(`An Error Occurred ${err.response.data.error || ""}`)
      })
    }
  }
  return (
    <div>
      <IndividualForm costcode={costcodeState} onChange={handleChange} number={false} onSave={handleSave} options={costcodesTemplate} readOnly={readOnly} />
    </div>
  );
}

export default SingleCompanyCostcode
