import React from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

export default function VendorsTodayChart({ data }) {

  const sortedData = data.sort((a, b) => a.shifts - b.shifts);

  return (<>
    <div className="text-uppercase">VENDORS TODAY</div>
    <ResponsiveContainer width="100%" height={140}>
      <BarChart data={sortedData} margin={{ top: 10, right: 0, left: -20, bottom: 0 }}>
        <CartesianGrid strokeDasharray="2 4" />
        <XAxis dataKey="company" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="shifts" fill="#f15922" />
      </BarChart>
    </ResponsiveContainer>
  </>);
}
