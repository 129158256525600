import React, { useState, useEffect } from 'react';
import { Button, FormControl, MenuItem, Dialog, Select, DialogActions, DialogContent, DialogContentText, DialogTitle, InputLabel, TextField, makeStyles } from '@material-ui/core';
import { get, has } from 'lodash';
import { getPaymentProfiles } from '../../../services/employeeService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { WorkerInfoSection } from '../index';
import { notificationCenter } from '../../../../helpers/notifications';

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 170,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paymentFromControl: {
    margin: theme.spacing(1),
    width: "100%"
  },
  // payment_profile_id: { label: "Carpenters Union", value: "60d1f2ab46eb78000984d637" }
  mainContent: {
    backgroundColor: '#f3f3f4',
    height: "100%",
    width: "100%",
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden'
  },
  workerForm: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  backgroundIcon: {
    zIndex: -1,
    position: 'absolute',
    right: '-5.4rem',
    fontSize: '11rem',
    color: '#c8c8c8'
  },
  drawerActionButtonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    alignItems: 'center'
  },
  perDiemBreakoutSelect: {
    paddingBottom: 6
  }
}));

const breakoutPerDiem = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' }
]

export default function SetHireRate(props) {

  const { open, onHandleClose, worker, onEditRate, onEditPerDiemRate, company, payment_profile_endpoint, employee_settings_endpoint, disableCompanySelect } = props;

  const classes = useStyles();

  const [profiles, setProfiles] = useState([]);

  const [state, setState] = useState({
    hire_rate: get(worker, 'settings.hire_rate', 0),
    payroll_id: get(worker, 'settings.payroll_id', 0),
    per_diem_rate: {
      value: get(worker, 'settings.per_diem_rate.value', 0),
      breakout: get(worker, 'settings.per_diem_rate.breakout', "daily")
    },
    payment_profile_id: { value: '', label: '' },
    comments: get(worker, 'settings.comments', ""),
    // department_id: get(worker, 'department_id', 0),
    // company_position_id: get(worker, 'company_position_id', 0),
  });

  const getProfiles = async () => {
    try {

      const res = await getPaymentProfiles(get(company, 'value'), payment_profile_endpoint);

      setProfiles(get(res, 'data'));
      setState(prevState => ({
        ...prevState,
        payment_profile_id: get(res, 'data').find(item => get(item, 'value') === get(worker, 'settings.payment_profile_id'))
      }))
    } catch (error) {
      console.log(error);
      notificationCenter('error', get(error, 'response.data.error', 'Error fetching payment profiles.'));
    }
  }

  useEffect(() => {
    getProfiles();
  }, []);

  const onUpdateHireRate = () => {
    let payload = {
      hire_rate: get(state, 'hire_rate', 0),
      payroll_id: get(state, 'payroll_id', 0),
      per_diem_rate: {
        value: get(state, 'per_diem_rate.value', 0),
        breakout: get(state, 'per_diem_rate.breakout', "daily")
      },
      payment_profile_id: get(state.payment_profile_id, 'value'),
      // department_id: get(state, 'department_id'),
      // company_position_id: get(state, 'company_position_id'),
      comments: get(state, 'comments', '')
    };

    onEditRate(payload, get(worker, '_id'));
  }
  const onChange = (type, value) => {
    setState(prevState => ({
      ...prevState,
      [type]: value
    }))
  }

  const onChangePerDiem = (type, value) => {
    setState(prevState => ({
      ...prevState,
      per_diem_rate: {
        ...prevState.per_diem_rate,
        [type]: value
      }
    }))
  }

  return(
    <div className={classes.mainContent}>
      <WorkerInfoSection {...props} disableCompanySelect={true} backgroundIcon={<MonetizationOnIcon className={classes.backgroundIcon} />} />
      <hr />
      <div className={classes.workerForm}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <FormControl fullWidth>
              <TextField style={{ paddingRight: 16 }} onChange={(e) => onChange('hire_rate', e.target.value)} type='number' value={state.hire_rate} label="Hire Rate" />
            </FormControl>
            <FormControl fullWidth>
              <TextField onChange={(e) => onChange('payroll_id', e.target.value)} type='text' value={state.payroll_id} label="Payroll ID" />
            </FormControl>
          </div>


          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <FormControl fullWidth>
              <TextField
                style={{ paddingRight: 16 }}
                onChange={(e) => onChangePerDiem('value', e.target.value)}
                type='number'
                value={state.per_diem_rate.value}
                label="Per Diem Rate" />
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Per Diem Breakout</InputLabel>
              <Select value={state.per_diem_rate.breakout} onChange={(e) => onChangePerDiem('breakout', e.target.value)} classes={{ root: classes.perDiemBreakoutSelect }}>
                <MenuItem value='daily'>Daily</MenuItem>
                <MenuItem value='weekly'>Weekly</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div style={{ display: 'flex', alignItems: 'baseline', flexDirection: 'column' }}>
            <FormControl fullWidth>
              <Autocomplete
                // disabled={selectProfile}
                id="combo-box-demo"
                value={get(state, 'payment_profile_id')}
                options={profiles}
                size="small"
                getOptionLabel={(option) => option.label}
                onChange={(e, t) => onChange('payment_profile_id', t)}
                style={{ width: "100%" }}
                renderInput={(params) => <TextField {...params} label="Payment Profile" />}
              />
            {/*has(worker, 'crew') ? <span>Belong to Crew <i>{get(worker, 'crew.name')}</i></span> : null*/}
            </FormControl>
          </div>

          {/*<div style={{ display: 'flex', alignItems: 'baseline', flexDirection: 'column', paddingTop: 12 }}>
            <FormControl fullWidth>
              <InputLabel>Department</InputLabel>
              <Select
                className={classes.inputStyle}
                displayEmpty={true}
                value={get(state, 'department_id')}
                onChange={(e) => onChange('department_id', e.target.value)}
              >
                <MenuItem style={{ height: '30px' }} key={null} value=""></MenuItem>
                {get(company, 'departments', []).map(department => <MenuItem key={get(department, 'value')} value={get(department, 'value')}>{get(department, 'label')}</MenuItem>)}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Position</InputLabel>
              <Select
                displayEmpty={true}
                className={classes.inputStyle}
                value={get(state, 'company_position_id')}
                onChange={(e) => onChange('company_position_id', e.target.value)}
              >
                <MenuItem style={{ height: '30px' }} key={null} value=""></MenuItem>
                {get(company, 'positions', []).map(position => <MenuItem key={get(position, 'value')} value={get(position, 'value')}>{get(position, 'label')}</MenuItem>)}

              </Select>
            </FormControl>
          </div>*/}

          <div style={{ display: 'flex', alignItems: 'baseline', width: '100%' }}>
            <FormControl fullWidth>
              <TextField
                style={{ width: '100%' }}
                id="standard-multiline-flexible"
                label="Comments"
                multiline
                maxRows={4}
                value={get(state, 'comments', '')}
                onChange={(e) => onChange('comments', e.target.value)}
              />
            </FormControl>
          </div>

        </div>
        <div className={classes.drawerActionButtonsWrapper}>
          <Button
            variant="outlined"
            onClick={onHandleClose}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginLeft: 5 }}
            onClick={onUpdateHireRate}>
            Save
          </Button>
        </div>
      </div>
    </div>
  )
};
