import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { get } from 'lodash';

export default function VendorProcoreConfirmation({ syncVendorConfirmation, data, onCloseVendorConfirmation }) {

  const [status, setStatus] = useState(false);


  const onVendorConfirmation = () => {

    // setStatus(true);
    onCloseVendorConfirmation('yes');
  }

  return (
    <Dialog
      open={syncVendorConfirmation}
      onClose={onCloseVendorConfirmation}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure this with EIN {get(data, 'vendorInfo.ein')} and Name {get(data, 'vendorInfo.name')} is {get(data, 'vendorVerified.EIN')}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCloseVendorConfirmation('no')} color="primary">
          No
        </Button>
        <Button disabled={!syncVendorConfirmation} onClick={() => onVendorConfirmation()} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
};

