import React, { useState, useEffect } from 'react'
import { get, size, isEqual, isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import Axios from "axios"

import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

import { makeStyles } from '@material-ui/core/styles'

import Tooltip from '@material-ui/core/Tooltip'
import Popover from '@material-ui/core/Popover'
import IconButton from '@material-ui/core/IconButton'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineDot from '@material-ui/lab/TimelineDot'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineConnector from '@material-ui/lab/TimelineConnector'

import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded'
import AssignmentIcon from '@material-ui/icons/Assignment'
import FastfoodIcon from '@material-ui/icons/Fastfood'
import ClockIcon from '@material-ui/icons/AccessTime'
import workerProfileIcon from '../../../../../../../assets/images/worker_types/constructionWorkerIcon-2023.svg'

import { myTheme, axiosConfig } from '../../../../../helpers'

export default function ActivityLog(props) {

  const { shift, timesheetEndpoint, disabled } = props;

  const useStyles = makeStyles((theme) => ({
    widget: {
      width: 500,
      height: 150,
      overflow: 'scroll',
      border: '2px solid rgba(241, 89, 34)',
      borderRadius: 10,
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 0,
      paddingRight: 0,
      fontWeight: 'bold'
    },
    activityLogsPopoverPaper: {
        height: 150,
        borderRadius: 10,
        marginLeft: -3,
        marginTop: -3,
        // minWidth: 1000,
        // minHeight: 300,
        overflowX: "unset",
        overflowY: "unset",
        "&::before": {
            zIndex: 9999,
            content: '""',
            border: '1px solid rgba(241, 89, 34)',
            borderLeft: '1px solid rgba(241, 89, 34, 0.1)',
            backgroundColor: 'rgba(241, 89, 34)',
            position: "absolute",
            marginRight: "-0.72em",
            top: 'calc(50% - 8px)',
            right: 0,
            width: 10,
            height: 10,
            boxShadow: theme.shadows[1],
            transform: "translate(-50%, 50%) rotate(45deg)",
            clipPath: "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
        },
    },
    avatar: {
      width: '40px !important'
    },
    reloadButton: {
      marginTop: 10,
      color: '#f15922',
      borderColor: '#f15922',
      fontSize: 12,
      width: 70,
      height: 30
    },
    activityLogAvatar: {
      width: 30,
      height: 30
    },
    filtersWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    noFilteredLogs: {
      textAlign: 'center',
      marginTop: 36,
      color: 'grey'
    },
    primaryLogUser: {
      borderColor: '#46aef3'
    },
    secondaryLogUser: {
      borderColor: '#1B3B6F'
    }
  }));

  const classes = useStyles();

  const [state, setState] = useState({
    activityLogs: [],
    loading: true,
    error: false,
    isOpen: false,
    filtered: false,
    filteredBy: ''
  })

  const getActivityLogs = () => {
    Axios.get(`${timesheetEndpoint}/logs/shift/${get( shift, '_id')}`, axiosConfig()).then(response => {
      setState(prevState => ({
        ...prevState,
        activityLogs: get(response, 'data', []),
        loading: false
      }))
    }).catch(error => {
      setState(prevState => ({
        ...prevState,
        loading: false,
        error,
      }))
      // TODO: enable notificationcenter
      // toastr.error( get( error, 'response.data.error', 'Error fetching project gauge data.'));
    })
  };

  const handleActivityLogsClick = (event) => {
    setState(prevState => ({
      ...prevState,
      activityLogsAnchorEl: event.currentTarget,
      isOpen: !prevState.isOpen,
      loading: true,
      activityLogs: []
    }))
  }

  const handleReloadClick = () => {
    setState(prevState => ({
      ...prevState,
      loading: true,
      error: null,
    }))

    getActivityLogs()
  };

  const togglePunchTimeFilter = () => {
    if (get(state, 'filtered') && get(state, 'filteredBy') === 'punch_time') {
      setState(prevState => ({
        ...prevState,
        filtered: false,
        filteredBy: '',
        filteredActivityLogs: []
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        filtered: true,
        filteredBy: 'punch_time',
        filteredActivityLogs: prevState.activityLogs.filter(oneLog => get(oneLog, 'field', '') === 'punch_time')
      }))
    }
  };
  const toggleBreakTimeFilter = () => {
    if (get(state, 'filtered') && get(state, 'filteredBy') === 'break_time') {
      setState(prevState => ({
        ...prevState,
        filtered: false,
        filteredBy: '',
        filteredActivityLogs: []
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        filtered: true,
        filteredBy: 'break_time',
        filteredActivityLogs: prevState.activityLogs.filter(oneLog => get(oneLog, 'field', '') === 'break_time')
      }))
    }
  };

  const renderActivityLogs = () => {

    if (size(get(state, 'activityLogs')) > 0) {
      return (
        <div>
          <div className={classes.filtersWrapper}>
            <Tooltip title="Punch time changes" arrow>
              <span>
                <IconButton onClick={togglePunchTimeFilter} size='small'>
                  <ClockIcon color={get(state, 'filteredBy') === 'punch_time' ? 'primary' : 'inherit'}/>
                </IconButton>
              </span>
            </Tooltip>
            <div style={{ width: 16 }}></div>
            <Tooltip title="Break time changes" arrow>
              <span>
                <IconButton onClick={toggleBreakTimeFilter} size='small'>
                  <FastfoodIcon color={get(state, 'filteredBy') === 'break_time' ? 'primary' : 'inherit'} />
                </IconButton>
              </span>
            </Tooltip>
          </div>
          <Timeline align="left" style={{ marginBottom: 0, padding: 0, paddingLeft: 0 }}>
            {
              get(state, 'filtered') && !isEmpty(get(state, 'filteredActivityLogs', [])) && get(state, 'filteredActivityLogs', []).map((log, index) =>
                <ActivityLogRow
                  log={log}
                  classes={classes}
                  isLast={index === size(get(state, 'filteredActivityLogs', [])) - 1}
                  />
              )
            }
            {
              get(state, 'filtered') && isEmpty(get(state, 'filteredActivityLogs', [])) &&
              <div className={classes.noFilteredLogs}> No {get(state, 'filteredBy') === 'punch_time' ? 'punch' : 'break'} time change logs </div>
            }
            {
              !get(state, 'filtered') && get(state, 'activityLogs', []).map((log, index) =>
                <ActivityLogRow
                  log={log}
                  classes={classes}
                  isLast={index === size(get(state, 'activityLogs', [])) - 1}
                  />
              )
            }
        </Timeline>
        </div>
      )
    } else {
      return (
        <div style={{ height: 124, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span style={{ color: 'grey', fontWeight: 'normal' }}>There are no actions logged for this shift</span>
        </div>
      )
    }
  }

  useEffect(() => {
    if (state.isOpen) {
      getActivityLogs();
    }
  }, [state.isOpen]);

  return (
    <>
      <Tooltip title="Activity Logs" arrow>
          <IconButton
              disabled={disabled}
              color={'primary'}
              onClick={handleActivityLogsClick}
              size='small'
          >
              <AssignmentIcon />
          </IconButton>
      </Tooltip>
      { get( state, 'isOpen') &&
        <Popover
          classes={{ paper: classes.activityLogsPopoverPaper }}
          open={get( state, 'isOpen')}
          onClose={handleActivityLogsClick}
          anchorEl={get( state, 'activityLogsAnchorEl')}
          anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
          transformOrigin={{ vertical: 'center', horizontal: 'right' }}
        >
            <div className={classes.widget} style={{ backgroundColor: !get(state, 'loading') && !get(state, 'error') ? 'rgba(241, 89, 34, 0.1)' : '' }}>
              {get(state, 'loading') &&
                <div style={{ display: 'flex', justifyContent: 'center', height: 120, alignItems: 'center' }}>
                  <CircularProgress color='primary' />
                </div>
              }
              {!get(state, 'loading') && get(state, 'error') &&
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: 124, alignItems: 'center' }}>
                  <ReportProblemRoundedIcon color="error" />
                  <span style={{ color: '#f44336', fontWeight: 'normal' }}>Could not load activity logs</span>
                  <Button size="small" onClick={handleReloadClick} className={classes.reloadButton}>
                    {'RELOAD'}
                  </Button>
                </div>
              }
              <div style={{ maxWidth: 500 }}>
                {!get(state, 'loading') && !get(state, 'error') && renderActivityLogs()}
              </div>
            </div>
        </Popover>
      }
    </>
  )
}

const ActivityLogRow = ({ classes, log, isLast }) =>
  <TimelineItem>
    <TimelineOppositeContent style={{ maxWidth: 160 }}>
      <Typography variant="h6" component="h1" style={{ fontSize: 12 }}>
        {get(log, 'user.first_name') + ' ' + get(log, 'user.last_name')}
      </Typography>
      <Typography variant="body2" color="textSecondary" style={{ fontSize: 8 }}>
        {DateTime.fromISO(get(log, 'created_at')).toFormat('ccc d LLL y')}
      </Typography>
      <Typography variant="body2" color="textSecondary" style={{ fontSize: 8 }}>
        {DateTime.fromISO(get(log, 'created_at')).toFormat('hh:mm a')}
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator>
      <TimelineDot className={get(log, 'user.isForeign') ? classes.secondaryLogUser : get(log, 'user.isLocal') ? classes.primaryLogUser : {}} color='grey' variant='outlined'>
        <Avatar className={classes.activityLogAvatar} src={get(log, 'user.profilePic', workerProfileIcon)} />
      </TimelineDot>
      {!isLast && <TimelineConnector sx={{ color: 'red' }} />}
    </TimelineSeparator>
    <TimelineContent style={{ fontSize: 10, fontWeight: 'normal', paddingTop: 8, color: 'rgba(0, 0, 0, 0.8)' }}>
      {get(log, 'description')}
    </TimelineContent>
  </TimelineItem>
