import React, { useEffect, useState } from 'react';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { get, groupBy, isNull, size, toLower } from 'lodash';
import { DepartmentsPositionItem } from './departmentsPositionItem';
import axios from 'axios';
import { axiosConfig } from '../../../../../helpers';
import { notificationCenter } from '../../../../../helpers/notifications';

export const DepartmentsTable = ({ items, type, endpoint, onChange, onReloadDepartmentsPositions }) => {
    const [elements, setElements] = useState([]);

    useEffect(() => setElements((items || [])
        .map(item => ({ ...item, _loading: false }))), [items]);

    const addNewElement = () => setElements(oldElements => [...oldElements, {
        _loading: false,
        _id: null,
        name: '',
        number: '',
        description: '',
        created_at: null,
        updated_at: null,
    }]);

    const removeElement = async (_id, type, index) => {
        let temporaryElements = elements;
        temporaryElements.splice(index, 1);

        if (_id) {
            temporaryElements = temporaryElements.filter(elem => elem._id !== _id);
            axios.delete(`${endpoint}/delete-department-position/${_id}/${type}`, axiosConfig());
        }

        setElements(temporaryElements);

        return onChange(elements);
    };

    const saveElement = async (item, type) => {
        let result;



        if (item._id) {
            result = await axios.patch(`${endpoint}/update-department-position/${item._id}/${type}`, item, axiosConfig())
                .then(res => (item = res.data));
        }

        if (!item._id) {
            result = await axios.post(`${endpoint}/insert-department-position/${type}`, item, axiosConfig())
                .then(res => (item = res.data));
        }


        onReloadDepartmentsPositions();
        onChange(elements);
        return result;




    };

    const canAddNew = () => {
        if (elements.length === 0) {
            return true;
        }

        const index = elements.length - 1;
        const last = elements[index];

        return last && last._id;
    };

    return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" style={{ backgroundColor: '#fff' }}>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Number</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell align="left">Created At</TableCell>
                    <TableCell align="left">Updated At</TableCell>
                    <TableCell align="left" />
                </TableRow>
            </TableHead>
            <TableBody>{elements.map((row, index) => (
                <DepartmentsPositionItem
                    key={row._id + index}
                    row={row}
                    type={type}
                    elements={elements}
                    onDelete={(_id) => removeElement(_id, type, index)}
                    onSave={(item) => saveElement(item, type)}
                />
            ))}
            </TableBody>
            <TableFooter className="m-a-2">
                <TableRow>
                    <TableCell>
                        <Button
                            size="small"
                            variant="contained"
                            className="text-success"
                            disabled={!canAddNew()}
                            onClick={addNewElement}>
                            <AddIcon /> Add new
                        </Button>
                    </TableCell>
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>;
};
