export const allColumns = [
  {
    id: "default",
    isActive: true,
    isImage: true,
    sortOption: true,
    label: "Default"
  },
  {
    id: "profile_name",
    isActive: true,
    isImage: true,
    sortOption: true,
    label: "Name"
  },
  {
    id: "company",
    sortOption: true,
    isActive: true,
    label: "Company Name"
  },
  {
    id: "breakout",
    sortOption: true,
    isActive: true,
    label: "Breakout"
  },
  {
    id: "regular",
    sortOption: true,
    isActive: true,
    label: "Regular"
  },
  {
    id: "overtime",
    sortOption: true,
    isActive: true,
    label: "Overtime"
  },
  {
    id: "double_time",
    sortOption: true,
    isActive: true,
    label: "DoubleTime"
  },
  {
    id: "actions",
    // sortOption: true,
    isActive: true,
    label: "Actions"
  }
];