import React, { useState, useEffect } from 'react'
import { get, isEmpty, isEqual } from 'lodash'

import Tooltip from '@material-ui/core/Tooltip'
import Popover from '@material-ui/core/Popover'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton';

import { makeStyles } from '@material-ui/core/styles'

import { myTheme } from '../../../../../helpers'

export default function PerdiemSelectorPopover(props) {

  const { shift, workerData, companyData, handleActionDialogPopover, disabled, editMode } = props;

  const useStyles = makeStyles((theme) => ({
    perdiemPopoverPaper: {
        marginTop: 4,
        overflowX: "unset",
        overflowY: "unset",
        // border: "2px solid #f15922",
        "&::before": {
            content: '""',
            position: "absolute",
            marginRight: "-0.72em",
            top: -10,
            right: '50%',
            width: 10,
            height: 10,
            // border: "2px solid #f15922",
            backgroundColor: "#f15922",
            boxShadow: theme.shadows[1],
            transform: "translate(-50%, 50%) rotate(315deg)",
            clipPath: "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
        },
    },
    perdiemButton: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // width: 25,
      paddingLeft: 3,
      paddingRight: 3,
      borderRadius: 5,
      border: '2px solid'
    },
    perdiemSubscript: {
      position: 'absolute',
      bottom: -2,
      right: -1,
      fontSize: 10,
      fontWeight: 800
    },
    toggleButton: {
      border: '1px solid #f15922',
      '&.Mui-selected': {
        backgroundColor: '#f15922',
        color: 'white'
      },
      '&.Mui-selected:hover': {
        backgroundColor: '#f15922',
        color: 'white'
      },
    }
  }));

  const classes = useStyles();

  const [state, setState] = useState({
    perdiem: get( shift, 'perdiem', 0),
    isOpen: false
  })

  const handlePerdiemClick = (event) => {
    setState(prevState => ({
      ...prevState,
      isOpen: !prevState.isOpen,
      perdiemAnchorEl: event.currentTarget,
    }))
  }

  const handlePerdiemBlur = (event) => {
    setState(prevState => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }))

    if (!isEqual( get( state, 'perdiem'), get( shift, 'perdiem'))) {
      handleActionDialogPopover('applyPerdiem', get( state, 'perdiem'), { shift, workerId: workerData._id, companyId: companyData._id })
    }
  }

  const handlePerdiemToggle = (event, perdiem) => {
      setState(prevState => ({
        ...prevState,
        perdiem,
      }))
  }

  return (
      <div style={{ display: 'flex' , marginLeft: 8 }}>
        <Tooltip title="Per Diem" arrow>
          <span>
            <IconButton
              size='small'
              value={get( state, 'perdiem')}
              disabled={workerData.workerRowLoading || !editMode || shift.approved}
              onClick={handlePerdiemClick}>
              { shift.perdiemLoading ?
                <div style={{ display: 'flex', width: 20, alignItems: 'center', justifyContent: 'center' }}>
                  <CircularProgress size={16} color='primary' />
                </div> :
                <div className={classes.perdiemButton} style={{ borderColor: get( state, 'perdiem') ? '#81cd74' : 'lightgrey', color: get( state, 'perdiem') ? '#81cd74' : 'lightgrey', opacity: disabled && perdiem ? 0.38 : 1 }}>
                  <div style={{ position: 'relative' }}>
                    <span>P</span>
                    <span className={classes.perdiemSubscript}>{get( state, 'perdiem')}</span>
                  </div>
                  { get( state, 'perdiem') > 1 && false &&
                    <div style={{ position: 'absolute', top: -4, right: -4, backgroundColor: 'red', width: 7, height: 7, borderRadius: 100, opacity: disabled ? 0.38 : 1 }}>
                      <span>{get( state, 'perdiem')}</span>
                    </div>
                  }
                </div>
              }
            </IconButton>
          </span>
        </Tooltip>
        { get( state, 'isOpen') &&
          <Popover
            classes={{ paper: classes.perdiemPopoverPaper }}
            open={get( state, 'isOpen')}
            onClose={handlePerdiemBlur}
            anchorEl={get( state, 'perdiemAnchorEl')}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <div style={{ }}>
              <ToggleButtonGroup
                value={get( state, 'perdiem')}
                exclusive
                onChange={handlePerdiemToggle}
                aria-label="perdiem"
              >
                <ToggleButton className={classes.toggleButton} value={1} aria-label="1">
                  1
                </ToggleButton>
                <ToggleButton className={classes.toggleButton} value={2} aria-label="2">
                  2
                </ToggleButton>
                <ToggleButton className={classes.toggleButton} value={3} aria-label="3">
                  3
                </ToggleButton>
                <ToggleButton className={classes.toggleButton} value={4} aria-label="4">
                  4
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Popover>
        }
      </div>
  )
}
