import React, { useState, createContext, useEffect } from 'react';
import { isNull, get } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { DateTime } from 'luxon';


export const SettingsContext = createContext();

export default function SettingsContextProvider(props) {

  const [state, setState] = useState({
    isMobile: useMediaQuery({ query: `(max-width: 770px)` }),
    isIpad: useMediaQuery({ query: `(max-width: 770x)` }),
    openProjectSettings: false,
    timesheetDate: isNull(sessionStorage.getItem(get(props, 'projectId'))) ? DateTime.now().toISO() : sessionStorage.getItem(get(props, 'projectId')),
    fetchingData: false,
    stats: { workers: { current: 0, total: 0 }, companies: { current: 0, total: 0 } },
    showRedrawCounters: false,
    uiConfig: get(props, 'uiConfig', {}),
    revokeList: get(props, 'revokeList', {})
  });

  const openProjectSettings = (flag = false) => {
    setState(prevState => ({
      ...prevState,
      openProjectSettings: flag
    }))
  }

  const onChangeTimeSheetDate = date => {
    sessionStorage.setItem(get(props, 'projectId'), date.toISO());
    setState(prevState => ({
      ...prevState,
      timesheetDate: date.toISO(),
    }))
  }

  const setRevokeList = revokeList => {
    setState(prevState => ({
      ...prevState,
      revokeList
    }))
  }

  const onGetTimeSheetDateFormat = (format = 'yLLdd') => {
    return DateTime.fromISO(state.timesheetDate).toFormat(format)
  }

  const onFetchingData = (fetchingData) => {
    setState(prevState => ({
      ...prevState,
      fetchingData
    }))
  }

  const updateStats = stats => {
    setState(prevState => ({
      ...prevState,
      stats,
    }))
  }

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      uiConfig: get(props, 'uiConfig')
    }))
  }, [props.uiConfig]);

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      revokeList: get(props, 'revokeList')
    }))
  }, [props.revokeList]);

  return (
    <SettingsContext.Provider
      value={{
        ...state,
        openProjectSettings,
        onChangeTimeSheetDate,
        onGetTimeSheetDateFormat,
        updateStats,
        onFetchingData,
        setRevokeList,
      }}>
      {props.children}
    </SettingsContext.Provider>
  )
};
