import React, { useState } from 'react'
import { InfoOutlined } from '@material-ui/icons'
import { Shift } from './helpers/formatters'
import { Avatar, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableHead, TableBody, TableFooter, TableCell, TableRow } from '@material-ui/core'


export default function ShiftcostcodeOutline(props) {
  const { shift } = props
  const [show, setShow] = useState(false);
  const shiftFormatter = Shift(shift)

  const generateLabel = () => {
    const companiesHash = {}

    shift.company_costcodes.forEach(cc => companiesHash[cc.company._id.$oid] = 0)
    shift.company_costcodes.forEach(cc => companiesHash[cc.company._id.$oid]++)

    const companiesCount = Object.keys(companiesHash).length

    return `${shift.company_costcodes.length} in ${companiesCount} companies`
  }

  const splitCodesPerCompany = (costcodes) => {
    let companiesHash = {}
    costcodes.forEach(cc => companiesHash[cc.company.name] = [])
    costcodes.forEach(cc => companiesHash[cc.company.name].push(cc))

    return companiesHash
  }

  const splitCodes = splitCodesPerCompany(shift.company_costcodes)

  const handleOpen = () => setShow(true)
  const handleClose = () => setShow(false)

  // console.log({ splitCodes });

  const presentCompanies = Object.keys(splitCodes)

  return (
    <>
      {/* {generateLabel()} */}
      <span style={{cursor: 'pointer'}} onClick={handleOpen}>
        <Avatar>
          {shiftFormatter.totalHours()}
        </Avatar>
        {/* <strong>
          {shiftFormatter.totalHours()} hours
        </strong> */}
      </span>

      {show && (

        <Dialog open={show} onClose={handleClose}>
          <DialogTitle onClose={handleClose}>Costcodes Summary</DialogTitle>
          <DialogContent>
            {presentCompanies.map((company, id) => (
              <Table key={id}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan="3">
                      {company}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Code</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Hours</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {splitCodes[company].map((companyCode, nid) => (
                    <TableRow key={nid}>
                      <TableCell>{companyCode.costcode.code}</TableCell>
                      <TableCell>{companyCode.costcode.name}</TableCell>
                      <TableCell>{companyCode.hours}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ))}



            {presentCompanies.length == 0 && (
              <h4>No Costcodes</h4>
            )}
          </DialogContent>
        </Dialog>
      )}

    </>
  )
}
