import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles, withStyles, ThemeProvider } from "@material-ui/core/styles";
import { myTheme } from './helpers';
import { notificationCenter } from './helpers/notifications'

import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Close from '@material-ui/icons/Close';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { toPairs, get, isEmpty, has } from 'lodash';

import { from } from 'rxjs';
import { map, toArray } from 'rxjs/operators';

import Axios from "axios";
import { axiosConfig } from "./helpers"

import Countries from 'i18n-iso-countries';
Countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default function CreateEditCompany({ companiesEndpoint, reloadTable, availableStates, availableCompanies, editMode, companyToEdit }) {

  const classes = useStyles();

  const countriesObjectToPairs = toPairs(Countries.getNames("en", { select: "official" }));
  const [show, setShow] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = React.useState('optionsPanel');
  const [collapsed, setCollapsed] = useState(false);

  const [step, setStep] = useState(0);

  const [countriesArray, setCountriesArray] = useState([])
  const [statesArray, setstatesArray] = useState([])

  const [einVerified, setEinVerified] = useState(false)
  const [lastCheckedEin, setLastCheckedEin] = useState("")

  const [companyInfo, setCompanyInfo] = useState({
    companyEin: "",
    companyName: "",
    companyType: null,
    companyWebsite: "",
    companyLogoUrl: "",
    isCustomer: false,
    clientId: ''
  });

  const [companyAddress, setCompanyAddress] = useState({
    street_number: '',
    route: '',
    locality: '', //city
    administrative_area_level_1: '', //state
    country: null,
    postal_code: '', //zip
    formatted_address: '',
    addressLineTwo: '',
  });

  const [companyOptions, setCompanyOptions] = useState({
    useCostCode: false,
    useCostCodeInDashboard: false,
    canAddSubcontractorWorkers: false,
    onboardingForm: false,
    payrollMode: false,

  })

  const [companySettingsApp, setCompanySettingsApp] = useState({
    geofence: false,
    geofenceRadius: 0,
    appEnabled: false,
    disabledIfDeviceInProject: false,
    enableFacialVerification: false
  })

  const [companySubcontractors, setCompanySubcontractors] = useState([])

  const [companyState, setCompanyState] = useState(null)

  const clearFields = () => {
    setCompanyAddress({
      street_number: '',
      route: '',
      locality: '',
      administrative_area_level_1: '',
      country: null,
      postal_code: '',
      formatted_address: '',
      addressLineTwo: '',
    })
    setCompanyState(null)
    setCompanyInfo({
      companyEin: "",
      companyName: "",
      companyType: null,
      companyWebsite: "",
      companyLogoUrl: "",
      isCustomer: false,
      clientId: '',
    })
    setCompanyOptions({
      useCostCode: false,
      useCostCodeInDashboard: false,
      canAddSubcontractorWorkers: false,
      onboardingForm: false,
      payrollMode: false,
    })

    setCompanySettingsApp({
      geofence: false,
      geofenceRadius: 0,
      appEnabled: false,
      disabledIfDeviceInProject: false,
      enableFacialVerification: false
    })
    setEinVerified(false)
  }

  const handleAccordionExpansion = (panel) => (event, newExpanded) => {
    setExpandedAccordion(newExpanded ? panel : false);
  };

  const openModal = () => {
    // inject styling for google autocomplete field into documentt
    const style = document.createElement('style');
    style.textContent = '.pac-container { z-index: 9999 }'
    document.head.append(style);

    setShow(true)
    setStep(0)
  }

  const handleMouseOverCreate = () => {
    setCollapsed(true)
  }

  const handleMouseOutCreate = () => {
    setCollapsed(false)
  }

  const handleClose = (reload) => {
    if (editMode && companyToEdit) {
      fillInCompanyToEditData();
    } else {
      clearFields();
    }

    if (reload) reloadTable(true)

    setShow(false);
  }

  let currentJsx = ""

  const handleCreate = () => {

    const url = `${companiesEndpoint}/create`

    Axios.post(url, getPayload(), axiosConfig()).then(response => {
      handleClose(true);
      toastr.success("Company created successfully.");
    }).catch(error => {
      toastr.error(get(error, 'response.data.error', 'Error creating company.'))
    })
  }

  const handleUpdate = () => {

    const url = `${companiesEndpoint}/update/${companyToEdit._id}`

    Axios.patch(url, getPayload(), axiosConfig()).then(response => {
      handleClose(true);
      toastr.success("Company updated successfully.");
    }).catch(error => {
      toastr.error(get(error, 'response.data.error', 'Error updating company.'))
    })
  }

  const fillInCompanyToEditData = () => {
    let companyToEditCountry = null
    let companyToEditType = null
    let companyToEditState = null

    if (companyToEdit.country) {
      let countryTemp = countriesArray.filter(oneCountry => oneCountry.value === companyToEdit.country)

      if (!isEmpty(countryTemp)) {
        companyToEditCountry = countryTemp[0]

        if (companyToEditCountry.value === 'US') {
          let statesTemp = statesArray.filter(oneState => oneState.value === companyToEdit.state)

          if (!isEmpty(statesTemp)) {
            companyToEditState = statesTemp[0]
          }
        }
      }
    }

    if (companyToEdit.type) {
      let typeTemp = companyTypes.filter(oneType => oneType.value === companyToEdit.type)

      if (!isEmpty(typeTemp)) {
        companyToEditType = typeTemp[0]
      }
    }

    setCompanyInfo({
      companyEin: get(companyToEdit, 'ein', ''),
      companyName: get(companyToEdit, 'name', ''),
      companyType: companyToEditType,
      companyWebsite: get(companyToEdit, 'website', ''),
      companyLogoUrl: get(companyToEdit, 'logo_url', ''),
      isCustomer: get(companyToEdit, 'is_smartbarrel_client', false),
      clientId: get(companyToEdit, 'client_id', ''),
    });

    setCompanyAddress({
      country: companyToEditCountry,
      locality: get(companyToEdit, 'city', ''),
      administrative_area_level_1: get(companyToEdit, 'state', ''),
      postal_code: get(companyToEdit, 'zip', ''), //zip
      formatted_address: get(companyToEdit, 'address', ''),
      addressLineTwo: get(companyToEdit, 'addressLineTwo', ''),
    });

    setCompanySettingsApp({
      geofence: get(companyToEdit, 'mobileAppSettings.geofence', false),
      geofenceRadius: get(companyToEdit, 'mobileAppSettings.geofenceRadius', 0),
      appEnabled: get(companyToEdit, 'mobileAppSettings.appEnabled', 0),
      disabledIfDeviceInProject: get(companyToEdit, 'mobileAppSettings.disabledIfDeviceInProject', false),
      enableFacialVerification: get(companyToEdit, 'mobileAppSettings.enableFacialVerification', false),
    })

    setCompanyOptions({
      useCostCode: get(companyToEdit, 'cost_code', false),
      useCostCodeInDashboard: get(companyToEdit, 'cost_code_in_dashboard', false),
      canAddSubcontractorWorkers: get(companyToEdit, 'can_manage_subcontractors', false),
      onboardingForm: get(companyToEdit, 'onboarding_form', false),
      payrollMode: get(companyToEdit, 'payroll_mode', false),

    });

    setEinVerified(true)

    setCompanyState(companyToEditState)

    setCompanySubcontractors(get(companyToEdit, 'subCompanies', []))
  }

  const getPayload = () => {
    let payload = {
      companyInfo: {
        ...companyInfo,
        companyType: get(companyInfo.companyType, 'value', '')
      },
      companyAddress: {
        address: companyAddress.formatted_address,
        addressLineTwo: companyAddress.addressLineTwo,
        city: companyAddress.locality,
        state: get(companyAddress.country, 'value', '') === 'US' ? companyState.value : companyAddress.administrative_area_level_1,
        zip: companyAddress.postal_code,
        country: get(companyAddress.country, 'value', '')
      },
      mobileAppSettings: {
        ...companySettingsApp
      },
      companyOptions
    }

    if (editMode && companyToEdit) {
      payload.companySubcontractors = {
        originalArray: companyToEdit.subCompanies.map(oneComp => oneComp._id),
        updatedArray: companySubcontractors.map(oneComp => oneComp._id)
      }
    }

    return payload
  }

  const handleNext = () => {
    let stp = step

    if (step >= (getSteps().length - 1)) {
      handleCreate()
      // editMode && companyToEdit ? handleUpdate() : handleCreate()
    } else {
      stp++
    }

    setStep(stp)
  }

  const handleBack = () => {
    if (step != 0) {
      setStep(step - 1)
    }
  }

  switch (step) {
    case 0:
      currentJsx = <CompanyInformation
        companyInfo={companyInfo}
        setCompanyInfo={setCompanyInfo}
        setCompanyAddress={setCompanyAddress}
        setCompanyState={setCompanyState}
        einVerified={einVerified}
        setEinVerified={setEinVerified}
        lastCheckedEin={lastCheckedEin}
        setLastCheckedEin={setLastCheckedEin}
        editMode={editMode}
        companiesEndpoint={companiesEndpoint}
      />
      break;

    case 1:
      currentJsx = <CompanyAddress
        companyAddress={companyAddress}
        setCompanyAddress={setCompanyAddress}
        companyState={companyState}
        setCompanyState={setCompanyState}
        countriesArray={countriesArray}
        statesArray={statesArray}
      />
      break;

    case 2:
      currentJsx = <CompanyOptions
        companyOptions={companyOptions}
        setCompanyOptions={setCompanyOptions}
        editMode={editMode}
        companyToEdit={companyToEdit}
      />
      break;

    case 2:
      currentJsx = <CompanySettingsApp
        companyOptions={companyOptions}
        setCompanyOptions={setCompanyOptions}
        editMode={editMode}
        companyToEdit={companyToEdit}
      />
      break;

    default:
      break;
  }

  const handleSubcontractorSelection = (event, value) => {
    setCompanySubcontractors(value)
  }

  const renderEditAccordions = () => (
    <Box>
      {companyToEdit.parentCompany &&
        <Box mb={2}>
          <strong>Managed by: </strong>
          <Box component="span" onClick={() => window.open(window.origin + `/companies/${companyToEdit.parentCompany._id}/dashboard`)} style={{ color: 'blue', cursor: 'pointer' }}>{companyToEdit.parentCompany.name}</Box>
        </Box>
      }
      <Box>
        <Accordion expanded={expandedAccordion === 'infoPanel'} onChange={handleAccordionExpansion('infoPanel')}>
          <AccordionSummary aria-controls="companyInfoPanel" id="companyInfoPanel" expandIcon={<ExpandMoreIcon />}>
            <Typography>Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CompanyInformation
              companyInfo={companyInfo}
              setCompanyInfo={setCompanyInfo}
              setCompanyAddress={setCompanyAddress}
              setCompanyState={setCompanyState}
              einVerified={einVerified}
              setEinVerified={setEinVerified}
              lastCheckedEin={lastCheckedEin}
              setLastCheckedEin={setLastCheckedEin}
              editMode={editMode}
              companiesEndpoint={companiesEndpoint}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expandedAccordion === 'addressPanel'} onChange={handleAccordionExpansion('addressPanel')}>
          <AccordionSummary aria-controls="companyAddressPanel" id="companyAddressPanel" expandIcon={<ExpandMoreIcon />}>
            <Typography>Address</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CompanyAddress
              companyAddress={companyAddress}
              setCompanyAddress={setCompanyAddress}
              companyState={companyState}
              setCompanyState={setCompanyState}
              countriesArray={countriesArray}
              statesArray={statesArray}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expandedAccordion === 'optionsPanel'} onChange={handleAccordionExpansion('optionsPanel')}>
          <AccordionSummary aria-controls="companyOptionsPanel" id="companyOptionsPanel" expandIcon={<ExpandMoreIcon />}>
            <Typography>Options</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CompanyOptions
              companyOptions={companyOptions}
              setCompanyOptions={setCompanyOptions}
              editMode={editMode}
              companyToEdit={companyToEdit}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expandedAccordion === 'settingsAppPanel'} onChange={handleAccordionExpansion('settingsAppPanel')}>
          <AccordionSummary aria-controls="settingsAppPanel" id="settingsAppPanel" expandIcon={<ExpandMoreIcon />}>
            <Typography>Mobile App Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CompanySettingsApp
              companySettingsApp={companySettingsApp}
              setCompanySettingsApp={setCompanySettingsApp}
              editMode={editMode}
              companyToEdit={companyToEdit}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion disabled={!companyInfo.isCustomer} expanded={expandedAccordion === 'subContractorPanel'} onChange={handleAccordionExpansion('subContractorPanel')}>
          <AccordionSummary aria-controls="companySubContractorPanel" id="companySubContractorPanel" expandIcon={<ExpandMoreIcon />}>
            <Typography>Manage Subcontractors</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                id="companySubcontractors"
                options={availableCompanies}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option._id === value._id}
                value={companySubcontractors}
                onChange={handleSubcontractorSelection}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Subcontractors"
                  />
                )}
              />
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Accordion disabled={!companyInfo.isCustomer} expanded={expandedAccordion === 'apiAccessPanel'} onChange={handleAccordionExpansion('apiAccessPanel')}>
          <AccordionSummary aria-controls="apiAccessPanel" id="apiAccessPanel" expandIcon={<ExpandMoreIcon />}>
            <Typography>API Access Setup</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ApiAccessPanel companyToEdit={companyToEdit} companiesEndpoint={companiesEndpoint} />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  )

  const renderStepper = () => (
    <Stepper activeStep={step}>
      {getSteps().map((label, index) => {
        return (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  )

  useEffect(() => {
    return () => { };
  }, [companyInfo])

  useEffect(() => {
    if (editMode && companyToEdit) {
      fillInCompanyToEditData()
    }

    return () => { };
  }, [countriesArray, companyToEdit])

  useEffect(() => {
    // initAll();

    from(countriesObjectToPairs)
      .pipe(
        map(keyValue => {
          return {
            label: keyValue[1],
            value: keyValue[0]
          }
        }),
        toArray()
      )
      .subscribe(result => setCountriesArray(result));

    from(availableStates)
      .pipe(
        map(keyValue => {
          return {
            label: keyValue[0],
            value: keyValue[1]
          }
        }),
        toArray()
      )
      .subscribe(result => setstatesArray(result));

    return () => { };
  }, [])

  return (
    <>
      {editMode ?
        <Tooltip title="Edit" arrow>
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={openModal}
            size="small"
          >
            <Edit />
          </IconButton>
        </Tooltip>
        :
        <Box display="flex" flexDirection="row">
          <IconButton
            aria-label="create"
            onMouseOver={handleMouseOverCreate}
            onMouseOut={handleMouseOutCreate}
            color="primary"
            onClick={openModal}
            size="small"
          >
            <Add />
          </IconButton>
          <Collapse orientation="horizontal" in={collapsed}>
            <Typography className={classes.createNewCompanyLabel}> Create Company </Typography>
          </Collapse>
        </Box>
      }
      <ThemeProvider theme={myTheme}>
        <Dialog maxWidth='sm' open={show} onClose={handleClose} fullWidth>
          <DialogTitle disableTypography={true} className={classes.dialogTitle} >
            <Box component="span">{editMode ? 'Edit Company' : 'New Company'}</Box>
            <Close style={{ cursor: 'pointer' }} size='20' color='primary' onClick={handleClose} />
          </DialogTitle>
          <DialogContent className={classes.modalBody}>
            {
              editMode ? renderEditAccordions() :
                <Box className={classes.root}>
                  {currentJsx}
                  {!editMode && renderStepper()}
                </Box>
            }
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            {step !== 0 ?
              <Button onClick={handleBack} disabled={step === 0}>
                Back
              </Button> : <Box></Box>
            }
            <Button disabled={!einVerified} onClick={editMode ? handleUpdate : handleNext} color="primary">
              {step == (getSteps().length - 1) ? "Create Company" : editMode ? "Update Company" : "Next"}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  )
}

const CompanyInformation = (props) => {
  let { companyInfo, setCompanyInfo, setCompanyAddress, setCompanyState, einVerified, setEinVerified, lastCheckedEin, setLastCheckedEin, editMode, companiesEndpoint } = props

  const classes = useStyles();

  const handleChange = (event) => {
    companyInfo[event.target.name] = event.target.value;
    setCompanyInfo({ ...companyInfo });
  }

  const handleEinChange = (event) => {
    // allow only number input
    // const numbersRegex = new RegExp(/^[0-9]/, 'g')
    //
    // if (numbersRegex.test(event.target.value)) {
    setEinVerified(false)

    companyInfo[event.target.name] = event.target.value;

    if (event.target.value !== lastCheckedEin) {
      setFailure(false)
    }

    setCompanyInfo({ ...companyInfo });
    // }
  }

  const handleCompanyType = (event, value) => {
    companyInfo['companyType'] = value;
    setCompanyInfo({ ...companyInfo });
  }


  const handleToggle = (event) => {
    companyInfo[event.target.name] = event.target.checked;
    setCompanyInfo({ ...companyInfo });
  }

  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);
  const timer = React.useRef();

  const verifyButton = clsx({
    [classes.buttonSuccess]: einVerified,
    [classes.buttonFailure]: failure,
    [classes.verifyButton]: true,
  });

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = () => {
    if (!loading && !einVerified /*&& (lastCheckedEin !== companyInfo.companyEin)*/) {
      setEinVerified(false);
      setFailure(false);
      setLoading(true);
      verifyEin(editMode ? false : true);
    }
  };

  const verifyEin = (loadDataIntoForm) => {

    let url = `${companiesEndpoint}/checkEIN/${companyInfo.companyEin}`;

    Axios.get(url, axiosConfig()).then(response => {
      let einResponse = response.data

      setEinVerified(true);
      setLoading(false);
      setLastCheckedEin(companyInfo.companyEin);

      if (loadDataIntoForm) {
        setCompanyInfo({
          ...companyInfo,
          companyName: get(einResponse, 'CompanyName', ''),
          // companyEin: einResponse.EIN.replace(/\-/g, ''),
        });

        setCompanyAddress({
          formatted_address: get(einResponse, 'Address', ''),
          locality: get(einResponse, 'City', ''),
          administrative_area_level_1: get(einResponse, 'State', ''),
          postal_code: get(einResponse, 'ZipCode', ''),
          country: { label: "United States of America", value: "US" }
        });

        setCompanyState({ value: get(einResponse, 'State', ''), label: get(einResponse, 'State', '') });
      }
    }).catch(error => {
      setLastCheckedEin(companyInfo.companyEin);
      setFailure(true);
      setEinVerified(false);
      setLoading(false);
      toastr.error(get(error, 'response.data.error', 'Error verifying EIN.'))
    })
  }

  return (
    <Box>
      <FormControl fullWidth>
        <TextField
          id="companyEin"
          name="companyEin"
          required
          label="EIN"
          color="primary"
          value={get(companyInfo, 'companyEin', '')}
          onChange={handleEinChange}
          inputProps={{ maxLength: 9 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Button
                  disableRipple
                  disableFocusRipple
                  variant="text"
                  color="primary"
                  className={verifyButton}
                  disabled={loading || get(companyInfo, 'companyEin.length', '') !== 9 || lastCheckedEin === get(companyInfo, 'companyEin', '')}
                  onClick={handleButtonClick}
                >
                  {einVerified ? "Verified" : failure ? "Failed" : loading ? '' : "Verify"}
                  {loading && <CircularProgress size={24} color="primary" className={classes.buttonProgress} />}
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          name="companyName"
          id="companyName"
          required
          label="Name"
          color="primary"
          value={companyInfo.companyName}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl fullWidth>
        <Autocomplete
          id="companyType"
          fullWidth
          options={companyTypes}
          value={companyInfo.companyType}
          onChange={handleCompanyType}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => option.value === value.value}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Type"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="companyWebsite"
          name="companyWebsite"
          value={companyInfo.companyWebsite}
          onChange={handleChange}
          label="Website"
          color="primary"
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="companyLogoUrl"
          name="companyLogoUrl"
          value={companyInfo.companyLogoUrl}
          onChange={handleChange}
          label="Logo URL"
          color="primary"
        />
      </FormControl>
      <FormControlLabel
        control={<LovelySwitch color="primary" checked={companyInfo.isCustomer} onChange={handleToggle} name='isCustomer' />}
        label='Is Customer'
        style={{ marginTop: '10px' }}
      // disabled={oneOption.disabled}
      />
      <FormControl fullWidth>
        <TextField
          name="clientId"
          id="clientId"
          label="Client Id"
          color="primary"
          onChange={handleChange}
          value={companyInfo.clientId}
          disabled={!companyInfo.isCustomer}
        />
      </FormControl>
    </Box>
  );
}

const CompanyAddress = (props) => {
  let { companyAddress, setCompanyAddress, companyState, setCompanyState, countriesArray, statesArray } = props

  const classes = useStyles();

  const countryToFlag = (isoCode) => {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  }

  const handleChange = (event) => {
    companyAddress[event.target.name] = event.target.value;
    setCompanyAddress({ ...companyAddress });
  }

  const handleStateSelection = (event, value) => {
    setCompanyState(value)
  }

  const handleCountrySelection = (event, value) => {
    companyAddress['country'] = value
    setCompanyAddress({ ...companyAddress })
  }

  useEffect(() => {
    document.getElementById('google_maps_autocomplete').value = companyAddress.formatted_address;
    return () => { };
  }, [])

  return (
    <Box>
      <FormControl fullWidth mt={1}>
        <GoogleAutocomplete companyAddress={companyAddress} setCompanyAddress={setCompanyAddress} setCompanyState={setCompanyState} />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="addressLineTwo"
          name="addressLineTwo"
          label="Address Line Two"
          value={companyAddress.addressLineTwo}
          color="primary"
          onChange={handleChange}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="locality"
          name="locality"
          label="City"
          value={companyAddress.locality}
          color="primary"
          onChange={handleChange}
          fullWidth
        />
      </FormControl>
      <FormControl fullWidth>
        {
          get(companyAddress.country, 'value', '') === 'US' ?
            <Autocomplete
              id="companyState"
              fullWidth
              options={statesArray}
              value={companyState}
              onChange={handleStateSelection}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) => option.value === value.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
            : <TextField
              id="administrative_area_level_1"
              name="administrative_area_level_1"
              label="State/Province"
              value={companyAddress.administrative_area_level_1}
              color="primary"
              onChange={handleChange}
              fullWidth
            />
        }
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="postal_code"
          name="postal_code"
          label="Zip Code/Postal Code"
          value={companyAddress.postal_code}
          color="primary"
          onChange={handleChange}
          fullWidth
        />
      </FormControl>
      <FormControl fullWidth>
        <Autocomplete
          id="companyCountry"
          fullWidth
          options={countriesArray}
          value={companyAddress.country}
          onChange={handleCountrySelection}
          classes={{
            option: classes.option,
          }}
          autoHighlight
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => option.value === value.value}
          renderOption={(option) => (
            <React.Fragment>
              <span>{countryToFlag(option.value)}</span>
              {option.label} ({option.value})
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      </FormControl>
    </Box>
  );
}

const CompanyOptions = (props) => {

  let { companyOptions, setCompanyOptions, editMode, companyToEdit } = props

  const classes = useStyles();

  const handleToggle = (event) => {
    companyOptions[event.target.name] = event.target.checked;
    setCompanyOptions({ ...companyOptions });
  }

  let optionsArray = [
    { value: 'useCostCode', label: 'Use CostCode' },
    { value: 'useCostCodeInDashboard', label: 'Use CostCode in Dashboard' },
    { value: 'canAddSubcontractorWorkers', label: 'Can Add Subcontractor Workers' },
    { value: 'onboardingForm', label: 'On-Boarding Form' },
    { value: 'payrollMode', label: 'Payroll Mode' },
    // { value: 'isCustomer', label: 'Is Customer' },

  ]


  return (
    <Box display="flex" flexDirection="column">
      {
        optionsArray.map(oneOption => {
          return (
            <FormControlLabel
              key={oneOption.value}
              control={<LovelySwitch color="primary" checked={companyOptions[oneOption.value]} onChange={handleToggle} name={oneOption.value} />}
              label={oneOption.label}
              disabled={oneOption.disabled}
            />
          )
        })
      }
    </Box>
  );
}

const CompanySettingsApp = (props) => {

  let { companySettingsApp, setCompanySettingsApp, editMode, companyToEdit } = props

  const classes = useStyles();

  const handleToggle = (event) => {

    companySettingsApp[event.target.name] = event.target.checked;
    setCompanySettingsApp({ ...companySettingsApp });
  }

  const handleGeoFenceRadius = (event) => {

    companySettingsApp[event.target.name] = event.target.value < 0 ? 0 : event.target.value;
    setCompanySettingsApp({ ...companySettingsApp });
  }

  let optionsArray = [
    { value: 'appEnabled', label: 'App Enabled' },
    { value: 'disabledIfDeviceInProject', label: 'Disable MobileApp if project has SmartBarrel device' },
    { value: 'enableFacialVerification', label: "Enable Facial Verification at Punch" },
    { value: 'geofence', label: 'Geofence' },
  ]

  return (
    <Box display="flex" flexDirection="column">
      {
        optionsArray.map(oneOption => {
          return (
            <FormControlLabel
              key={oneOption.value}
              control={<LovelySwitch color="primary" checked={companySettingsApp[oneOption.value]} onChange={handleToggle} name={oneOption.value} />}
              label={oneOption.label}
              disabled={oneOption.disabled}
            />
          )
        })
      }

      <FormControl fullWidth>
        <TextField
          disabled={!companySettingsApp.geofence}
          name="geofenceRadius"
          type="number"
          id="settingAppGeofenceRadius"
          label="Geofence Radius (miles)"
          color="primary"
          value={companySettingsApp.geofenceRadius}
          onChange={handleGeoFenceRadius}
        />
      </FormControl>
    </Box>
  );
}

const ApiAccessPanel = (props) => {

  let { companyToEdit, companiesEndpoint } = props

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
    },
    apiKeyWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%'
    },
    apiKeyIcon: {
      color: theme.palette.primary.main,
      fontSize: 22,
      transform: 'rotate(90deg)',
      marginRight: 8
    },
    apiKeyText: {
      ...theme.typography.caption,
      cursor: 'copy',
    }
  }))

  const classes = useStyles()

  const [requestLoading, setRequestLoading] = useState(false);
  const [apiKey, setApiKey] = useState(get( companyToEdit, 'publicApiAccess', null));

  const handleRequestClick = () => {
    setRequestLoading(true)

    Axios.post(`${companiesEndpoint}/publicAccessSetup/${get( companyToEdit, '_id')}`, {}, axiosConfig()).then(response => {
      setApiKey(get( response, 'data'))
      setRequestLoading(false)
    }).catch(error => {
      setRequestLoading(false)
      toastr.error(get(error, 'response.data.error', 'Error requesting API access key.'))
    })
  }

  const copyToClipboard = () => {
    // console.log('xx entered copy')
    try {
      navigator.clipboard.writeText(apiKey);
      toastr.success('API Key copied');
    } catch (err) {
      console.log('copy error: ', err)
      toastr.error('Failed to copy API Key');
    }
  }

  return (
    <div className={classes.root}>
      {
        apiKey === null &&
        <Button onClick={handleRequestClick} color='primary'>
          {
            requestLoading ?
            <CircularProgress size={24} /> : 'Request API Key'
          }
        </Button>
      }
      {
        !requestLoading && apiKey !== null &&
        <div className={classes.apiKeyWrapper}>
          <VpnKeyIcon className={classes.apiKeyIcon} />
          <div onClick={copyToClipboard} className={classes.apiKeyText}>{apiKey.apiKey}</div>
        </div>
      }
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(1),
    },
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: 'center',
    fontSize: '17px'
  },
  dialogActions: {
    justifyContent: 'space-between'
  },
  verifyButton: {
    position: 'relative',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  createNewCompanyLabel: {
    color: '#f15922',
    marginTop: '5px',
    fontSize: '15px', //12px
    fontWeight: 700,
    height: '100%'
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  modalBody: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  buttonSuccess: {
    color: theme.palette.success.main
  },
  buttonFailure: {
    color: theme.palette.error.main
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const getSteps = () => ["Information", "Address", "Options"]

const companyTypes = [
  { value: 'Shell Contractor', label: 'Shell Contractor' },
  { value: 'Concrete Structures', label: 'Concrete Structures' },
  { value: 'Painting', label: 'Painting' }
]

const GoogleAutocomplete = ({ companyAddress, setCompanyAddress, setCompanyState }) => {
  let componentForm = {
    street_number: 'short_name',
    route: 'short_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };

  function fillInAddress() {
    setCompanyAddress({
      street_number: '',
      route: '',
      locality: '',
      administrative_area_level_1: '',
      country: null,
      postal_code: '',
      formatted_address: '',
      addressLineTwo: '',
    })
    setCompanyState(null)

    // Get the place details from the autocomplete object.
    var place = window.autocomplete.getPlace();

    let eventToSend = {
      formatted_address: place.formatted_address,
      timezone: place.utc_offset_minutes,
    }


    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];

      if (componentForm[addressType]) {
        var val = place.address_components[i][componentForm[addressType]];

        if (addressType === "country") {
          val = {
            label: place.address_components[i].long_name,
            value: place.address_components[i].short_name,
          }
        }

        if (addressType === "administrative_area_level_1") {
          setCompanyState({
            label: place.address_components[i].long_name,
            value: place.address_components[i].short_name,
          })
        }

        eventToSend[addressType] = val;
      }
    }

    setCompanyAddress({ ...companyAddress, ...eventToSend })
  }

  const initAutocomplete = () => {
    window.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('google_maps_autocomplete'), { types: ['geocode'] });

    window.autocomplete.setFields(['address_component', 'formatted_address', 'geometry', 'utc_offset_minutes', 'place_id']);

    window.autocomplete.addListener('place_changed', fillInAddress);
  }

  const geolocate = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        var circle = new google.maps.Circle(
          { center: geolocation, radius: position.coords.accuracy });

        let bounds = circle.getBounds()
        window.autocomplete.setBounds(bounds);
      });
    }
  }

  useEffect(() => {
    initAutocomplete()
    return () => { };
  }, [])

  return (
    <TextField
      id="google_maps_autocomplete"
      onFocus={geolocate}
      label="Address"
      placeholder="Start typing for autocomplete"
    />
  );
}

const pxToRem = (px, oneRemPx = 17) => `${px / oneRemPx}rem`;
const borderWidth = 2;
const width = pxToRem(56);
const height = pxToRem(34);
const size = pxToRem(22);
const gap = (34 - 22) / 2;

const LovelySwitch = withStyles((theme) => ({
  root: {
    width,
    height,
    padding: 0,
    margin: theme.spacing(1),
    overflow: 'unset',
  },
  switchBase: {
    padding: pxToRem(gap),
    '&$checked': {
      color: '#fff',
      transform: `translateX(calc(${width} - ${size} - ${pxToRem(2 * gap)}))`,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
      '& $thumb': {
        backgroundColor: '#fff',
      },
    },
  },
  track: {
    borderRadius: 40,
    border: `solid ${theme.palette.grey[400]}`,
    borderWidth,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    boxSizing: 'border-box',
  },
  thumb: {
    boxShadow: 'none',
    backgroundColor: theme.palette.grey[400],
    width: size,
    height: size,
  },
  checked: {},
}))(Switch);
