import React from 'react';
import SectionContent from '../SectionContent';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { DialogChangeStatus } from './DialogChangeStatus';
import { DialogEventLogs } from './DialogEventLogs';
import { DialogAddComment } from './DialogAddComment';
import { GalleryCarousel, GalleryCarouselItem } from '../../../../../reusableComponents/GalleryCarousel';
import { DialogIncidentList } from './DialogIncidentList';

const IncidentSummaryContent = ({ items }) => (
    <SectionContent
        items={items}
        renderItem={({ item }) => <RenderItem item={item} />}
        renderActions={(<DialogIncidentList />)}
        contentBorderColor='#f15922'
        contentBackgroundColor='#ffeeea'
    />
);

const RenderItem = ({ item }) => {

  const { pictures } = item;

  return <>
    <Grid xs={true} item>
      <GalleryCarousel max={3}>
        {pictures.map((picture, index) => <GalleryCarouselItem src={picture} caption={index} />)}
      </GalleryCarousel>
    </Grid>

    <Grid className="text-right" xs={true} item>
      <FormControl size="small" style={{ flexDirection: 'row' }}>
        <DialogEventLogs item={item} />
        <DialogChangeStatus item={item} />
        <DialogAddComment item={item} />
      </FormControl>
    </Grid>
  </>;
};

export default IncidentSummaryContent;
