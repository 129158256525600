import React, { useState, useContext, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { myTheme } from '../helpers';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';

import SettingsContextProvider from '../employees/contexts/SettingsContext';
import { get } from 'lodash';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import { HeaderComponent, TableComponent } from './components/index';
import { gettingCompanyEmployeeSettings, getBuiltInClassifiers } from './services';

const useStyles = makeStyles((theme) => ({
  dialogLoading: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    backgroundColor: '#ffffffa6',
    zIndex: '9999999',
    justifyContent: "center",
    alignItems: "center"
  }
}));

export default function Classifiers(props) {

  const { company, config } = props;
  const companyWorkersDomain = get(config, 'companyWorkersDomain');
  const classifiersEndpoint = get(config, 'classifiersEndpoint');
  const dashboardEndpoint = get(config, 'dashboardEndpoint');

  const classes = useStyles();

  const [state, setState] = useState({
    loading: false,
    currentCompany: {
      value: get(company, '_id.$oid'),
      label: get(company, 'name')
    },
    companies: [],
    applications: [],
    classifierTypes: []
  });
  const [builtInClassifiers, setBuiltInClassifiers] = useState([]);

  const onLoading = (flag = true) => {
    setState(prevState => ({
      ...prevState,
      loading: flag
    }));
  }

  const getApplications = async () => {
    // NOTE: provisioning in case types will be fetched using api
    try {
      let payload = {
        pagination: {
          page: 0,
          rowsPerPage: 25
        }
      }

      // const res = await getApplicationsService(state.currentCompany.value, payload, endpoint);
      const res = {
        data: [ { "_id":"613a661019dummy008a51bb1", "name": "shifts", "label": "Shifts"}, { "_id":"613a661019dummy008a51bb2", "name": "shift[type=pto]", "label": "PTO"},  { "_id":"613a661019dummy008a51bb3", "name": "shift[type=equipment]", "label": "Equipment"}]
      }

      setState(prevState => ({
        ...prevState,
        applications: get(res, 'data', [])
      }))
    } catch (error) {

    }
  }

  const getClassifierTypes = async () => {
    // NOTE: provisioning in case types will be fetched using api
    try {
      let payload = {
        pagination: {
          page: 0,
          rowsPerPage: 25
        }
      }

      // const res = await getClassifierTypesService(state.currentCompany.value, payload, endpoint);
      const res = {
        data: [
          { "_id":"60e471de009a18dummy77ae1", "name": "tag", "label": "Tag"},
          { "_id":"60e471de009a18dummy77ae2", "name": "productivity", "label": "Productivity"},
          { "_id":"60e471de009a18dummy77ae3", "name": "material", "label": "Material"}
        ]
      }
      setState(prevState => ({
        ...prevState,
        classifierTypes: get(res, 'data', [])
      }))
    } catch (error) {

    }
  }

  const getSettings = async () => {
    try {
      onLoading()

      const res = await gettingCompanyEmployeeSettings(companyWorkersDomain);

      setState(prevState => ({
        ...prevState,
        companies: get(res, 'data.companies'),
        currentCompany: get(res, 'data.companies').find(item => get(item, 'value') === get(company, '_id.$oid'))
      }))

      onLoading(false)

    } catch (error) {

      console.log(error)

    }
  }

  const loadBuiltInClassifiers = async () => {
    try {
      onLoading()

      const res = await getBuiltInClassifiers(dashboardEndpoint);

      setBuiltInClassifiers(get(res, 'data.data'))
      onLoading(false)

    } catch (error) {

      console.log(error)

    }
  }

  useEffect(() => {
    getSettings()
    loadBuiltInClassifiers()
    getApplications()
    getClassifierTypes()
  }, [])

  const onCompanyFilterChange = value => {
    setState(prevState => ({
      ...prevState,
      currentCompany: value
    }))
  }

  return (
    <ThemeProvider theme={myTheme}>
      <SettingsContextProvider>
        {
          state.loading &&
          <div className={classes.dialogLoading}>
            <CircularProgress color="primary" />
          </div>
        }
        <div id="jumbo-styles-container">
          <HeaderComponent
            companies={state.companies}
            userCompany={company}
            currentCompany={state.currentCompany}
            onCompanyFilterChange={onCompanyFilterChange}
          />
          <TableComponent
            company={company}
            currentCompany={state.currentCompany}
            onLoading={onLoading}
            applications={state.applications}
            classifierTypes={state.classifierTypes}
            classifiersEndpoint={classifiersEndpoint}
            builtInClassifiers={builtInClassifiers}
          />
        </div>
      </SettingsContextProvider>
      <NotificationContainer />
    </ThemeProvider >
  )
};
