import React, { useState } from 'react';
import { TableRow, TableCell, Avatar, Link, Badge, Dialog, DialogContent, DialogContentText, DialogActions, Button, Tooltip, IconButton, CircularProgress, Popover } from '@material-ui/core';
import { isNull, get, has, set, upperCase, trim, cloneDeep } from 'lodash';
import { Edit, DeleteForever, Error, Security, MonetizationOn } from '@material-ui/icons';
import { withStyles, makeStyles } from '@material-ui/styles';
import { statusColors } from '../../../constants/workerStatusColor';
import RateTableEdit from '../../../../rateTableEdit/RateTableEdit';
import { deleteWorkerImageProfile, generateMobileAuthKey, deleteWorkerRfid, recoverWorker } from "../../../services/employeeService";
import { notificationCenter } from '../../../../helpers/notifications';
import workerProfileIcon from '../../../../../../assets/images/worker_types/constructionWorkerIcon-2023.svg';
import clsx from 'clsx';

import enIcon from '../../../../../../assets/images/en-checked.svg';
import enIconOff from '../../../../../../assets/images/en-unchecked.svg';
import esIcon from '../../../../../../assets/images/es-checked.svg';
import esIconOff from '../../../../../../assets/images/es-unchecked.svg';

const RenderingImageProfile = (props) => {

  const { worker, images_path, classes, projectDomain, employee_settings_endpoint } = props;

  const [clearImage, setClearImage] = useState(false)
  const [showClearImage, setShowClearImage] = useState(false)

  const avatarStyles = makeStyles((theme) => ({
    root: {
      border: `3px solid ${get(statusColors, get(worker, 'status.activity', 0))}`,
      width: 45,
      height: 45
    }
  }));

  const onClearImageProfile = async (status) => {
    try {
      setClearImage(false);

      if (status) {
        set(worker, 'image_path', null);
        const res = await deleteWorkerImageProfile(employee_settings_endpoint, get(worker, 'dbid'))
      }
    } catch (error) {

    }
  }

  const SmallAvatar = withStyles((theme) => ({
    root: {
      width: 20,
      height: 20,
      border: `1px solid ${theme.palette.background.paper}`,
      backgroundColor: "white"
    }
  }))(Avatar);

  const avatarClasses = avatarStyles();

  // Do not add domain if already a complete url
  let profileImage = workerProfileIcon;
  let workerImage = trim(get(worker, 'image_path', ''));
  if(workerImage != "") {
    const urlPattern = /^(http:\/\/|https:\/\/)/i;
    if(urlPattern.test(workerImage)) {
        profileImage = workerImage;
    }else{
        profileImage = `${images_path}${workerImage}`;
    }
  }

  return (
    <>
      <TableCell style={{ position: 'relative', paddingTop: 5, paddingBottom: 5 }} classes={{ root: classes.root }} align="left">
        {isNull(get(worker, 'user_type')) ?
          <div style={{ position: 'relative' }} onMouseEnter={() => setShowClearImage(true)} onMouseLeave={() => setShowClearImage(false)}>
            <Avatar classes={{ root: avatarClasses.root }} alt="WorkerProfile" src={profileImage} />
            {has(worker, 'image_path') && !isNull(get(worker, 'image_path')) && showClearImage ? <img onClick={() => setClearImage(!clearImage)} src="https://s3.us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/project_workers/closedIcon.svg" style={{ position: 'absolute', left: '67%', top: '3%', width: '20px', color: '#f16734', cursor: 'pointer' }} /> : null}
          </div> :
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={<SmallAvatar style={{ border: '1px solid #b1b1b1' }} alt={`${upperCase(get(worker, 'user_type', 'worker'))}`} src={`https://static-files.connectedbarrel.com/icons/project_workers/${get(worker, 'user_type', 'worker')}.svg`} />}
          >
            <div style={{ position: 'relative' }} onMouseEnter={() => setShowClearImage(true)} onMouseLeave={() => setShowClearImage(false)}>
              <Avatar classes={{ root: avatarClasses.root }} alt="WorkerProfile" src={profileImage} />
              {has(worker, 'image_path') && !isNull(get(worker, 'image_path')) && showClearImage ? <img onClick={() => setClearImage(!clearImage)} src="https://s3.us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/project_workers/closedIcon.svg" style={{ position: 'absolute', left: '67%', top: '3%', width: '20px', color: '#f16734', cursor: 'pointer' }} /> : null}
            </div>

          </Badge>
        }
      </TableCell >
      <Dialog
        open={clearImage}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => onClearImageProfile(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Are you sure you want to delete the image profile for ${get(worker, 'first_name')} ${get(worker, 'last_name')}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClearImageProfile(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onClearImageProfile(true)} color="primary">
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const RenderingRfidTag = (props) => {

  const { worker, classes, employee_settings_endpoint } = props;
  const [clearRfid, setClearRfid] = useState(false)
  const [workerRfid, setWorkerRfid] = useState(get(worker, 'rfid', null))

  const onClearRfid = async (status) => {

    try {
      setClearRfid(false);

      if (status) {
        const res = await deleteWorkerRfid(employee_settings_endpoint, get(worker, 'dbid'))

        if (get(res, 'data.success')) setWorkerRfid(null)
      }
    } catch (error) {
      notificationCenter('error', get(error, 'response.data.error', 'Error clearing RFID tag.'));
    }
  }

  return (
    <>
      <TableCell classes={{ root: classes.root }} align="left">
        { workerRfid &&
          <div style={{ position: 'relative' }}>
            <img src='https://static-files.connectedbarrel.com/icons/project_workers/rfid.svg' width='30' />
            <img className={classes.clearRfidIcon} onClick={() => setClearRfid(!clearRfid)} src="https://s3.us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/project_workers/closedIcon.svg" style={{ position: 'absolute', left: 30, top: -10, width: '20px', color: '#f16734', cursor: 'pointer' }} />
          </div>
        }
      </TableCell >
      <Dialog
        open={clearRfid}
        keepMounted
        onClose={() => onClearRfid(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Are you sure you want to remove the RFID tag for ${get(worker, 'first_name')} ${get(worker, 'last_name')}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClearRfid(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onClearRfid(true)} color="primary">
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const RenderMobileAuth = (props) => {

  const { companyWorkersDomain, worker, rowClasses } = props

  const useStyles = makeStyles((theme) => ({
    mobileAuthKeyPaper: {
        // marginLeft: 8,
        overflowX: "unset",
        overflowY: "unset",
        border: '2px solid rgba(241, 89, 34)',
        backgroundColor: 'rgba(241, 89, 34, 0.1)',
        padding: 3,
        paddingLeft: 8,
        fontSize: 18,
        fontWeight: 600,
        color: 'darkslategray',
        letterSpacing: 6,
        "&::before": {
            content: '""',
            position: "absolute",
            marginRight: "-0.72em",
            bottom: '50%',
            left: 0,
            width: 10,
            height: 10,
            border: '2px solid rgba(241, 89, 34)',
            backgroundColor: 'rgba(241, 89, 34)',
            // backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[1],
            transform: "translate(-50%, 50%) rotate(225deg)",
            clipPath: "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
        },
    },
  }));

  const classes = useStyles();

  const [loading, setLoading] = useState(false)
  const [authCode, setAuthCode] = useState('')
  const [authCodeAnchorEl, setAuthCodeAnchorEl] = useState(null)

  const handleGenerateMobileAuthClick = async (event) => {
    setLoading(true);

    let anchorTarget = event.currentTarget

    try {
      const res = await generateMobileAuthKey(companyWorkersDomain, get(worker, '_id'))

      setLoading(false)
      setAuthCode(get(res, 'data.mobileAuthorization.code'))
      setAuthCodeAnchorEl(anchorTarget)
    } catch (error) {
      setLoading(false)
    }
  }

  const closeMobileAuthPopover = () => {
    setAuthCodeAnchorEl(null)
    setAuthCode('')
  }

  return(
    <TableCell classes={{ root: rowClasses.root }}  align="left">
      <Tooltip title='Generate mobile authorization key' arrow>
        <IconButton size="small" onClick={handleGenerateMobileAuthClick} disabled={get(worker, 'deleted_at')}>
          { loading ?
            <CircularProgress color="primary" size={18} style={{ marginLeft: 8 }} /> :
              <img style={{ height: 34, width: 34 }} src={require('../../../../../../assets/images/mobileAuthIcon.svg')} />
          }
        </IconButton>
      </Tooltip>
      { authCodeAnchorEl !== null &&
        <Popover
          classes={{ paper: classes.mobileAuthKeyPaper }}
          open={authCodeAnchorEl !== null}
          onClose={closeMobileAuthPopover}
          anchorEl={authCodeAnchorEl}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          transformOrigin={{ vertical: 'center', horizontal: 'left' }}>
            <div>
              <span>{authCode.substr(0, 3)} {authCode.substr(3, 5)}</span>
            </div>
        </Popover>
      }
    </TableCell>
  )
}

const RecoverButton = (props) => {

  const { worker, employee_settings_endpoint, onRecoverWorker, hasPermission } = props

  const useStyles = makeStyles((theme) => ({
    recoverButton: {
      marginLeft: '-8px !important',
      color: '#36C240'
    }
  }));

  const classes = useStyles();

  const [loading, setLoading] = useState(false)

  const handleRecoverWorkerClick = async (event) => {
    setLoading(true);

    try {
      const res = await recoverWorker(employee_settings_endpoint, [ get(worker, '_id') ])

      if (get(res, 'data.success')) {
        notificationCenter('success', 'Worker recovered.');
        onRecoverWorker();
      }
    } catch (error) {
      notificationCenter('error', get(error, 'response.data.error', 'Error recovering worker.'));
      setLoading(false);
    }
  }

  return (
    <Button disabled={!hasPermission('canRecover')} className={classes.recoverButton} onClick={handleRecoverWorkerClick}>{ loading ? <CircularProgress size={16} style={{ color: '#36C240' }} /> : 'RECOVER'}</Button>
  )
}

const tableRowStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      '& $clearRfidIcon': {
        opacity: 1
      },
    },
    "& .MuiButtonBase-root": {
      padding: 4,
    },
  },
  clearRfidIcon: {
    opacity: 0
  },
  setSettingsButton: {
    color: '#3b3b3b',
    cursor: 'pointer'
  },
  inactiveRow: {
    backgroundColor: '#e0e0e0',
    pointerEvents: 'none'
  },
  inActiveCell: {
    color: '#868e96'
  }
}));

export default function RenderingCompanyEmployeeRows(props) {

  const { currentEmployee, workers, permissions, columns, images_path, onDeleteWorker, onEdit, onEditRate, onPromoteWorker, onDemoteWorker, employee_settings_endpoint, companyWorkersDomain, onShowHireRate, onRecoverWorker } = props;


  const rowClasses = tableRowStyles();

  const isRowActive = (key) => {
    return columns.indexOf(key) > -1
  }

  const hasPermission = (type) => get(permissions, type, false)

  return (
    <>
      {workers.map(row => {
        return (
          <TableRow key={get(row, '_id')} className={clsx({ [rowClasses.inactiveRow]: !row.active })}>
            {isRowActive("image_path") ? <RenderingImageProfile employee_settings_endpoint={employee_settings_endpoint} classes={rowClasses} worker={row} images_path={images_path} /> : null}
            {isRowActive("worker_name") ? <TableCell classes={{ root: clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.active }) }}><div style={{ display: 'flex', justifyContent: 'space-between'}}>{`${get(row, "first_name", '')} ${get(row, "last_name", '')}`} {!row.active && <i style={{ marginLeft: 8 }} className='fa fa-user-times'></i>}</div></TableCell> : null}
            {isRowActive("phone_number") ?
              <TableCell classes={{ root: rowClasses.root }}  align="left">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <a href={`tel:+${get(row, "phone_number", "")}`}>{get(row, "phone_number", "")}</a>
                  { get(row, 'synthetic_phone', false) && <Tooltip title={<div>Auto-generated phone number <br/> SMS services unavailable</div>} arrow><Error style={{ color: '#F6A200', fontSize: 16, backgroundColor: 'white', borderRadius: '100%' }}/></Tooltip> }
                </div>
              </TableCell> : null }
            {isRowActive("mobile_auth") ? <RenderMobileAuth worker={row} rowClasses={rowClasses} companyWorkersDomain={companyWorkersDomain} /> : null}
            {isRowActive("payroll_id") ? <TableCell classes={{ root:  clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.active }) }}  align="left">
              {get(row, "settings.payroll_id", "")}
            </TableCell> : null}
            {isRowActive("email") ? <TableCell classes={{ root:  clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.active }) }}  align="left">{`${get(row, "email", '')}`}</TableCell> : null}
            {isRowActive("decal_number") ? <TableCell classes={{ root:  clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.active }) }}  align="left">{get(row, "onboarding.decal", "")}</TableCell> : null}
            {isRowActive("company") ? <TableCell classes={{ root:  clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.active }) }}  align="left">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {get(row, "company.name", "")}<i style={{ color: '#908f8f' }}>{get(row, "company.ein", "")}</i>
              </div>
            </TableCell> : null}

            {isRowActive("rate") && hasPermission('canSeeMoney') ? <TableCell classes={{ root:  clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.active }) }}  align="left">
              {hasPermission('canSeeMoney') &&
                <RateTableEdit worker={cloneDeep(row)} onEditRate={onEditRate} permissions={permissions} />
              }
            </TableCell> : null}


            {isRowActive("per_diem") ? <TableCell classes={{ root:  clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.active }) }}  align="left">
              {get(row, "settings.per_diem_rate.value", "")}
            </TableCell> : null}
            {isRowActive("last_seen") ? <TableCell classes={{ root:  clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.active }) }}  align="left">{get(row, "last_seen", "")}</TableCell> : null}
            {isRowActive("idCard") ? <TableCell classes={{ root:  clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.active }) }}  align="left">{has(row, 'idCard') ?
              <Link color="inherit" href={get(row, 'idCard')} target="_blank" >
                <img style={{ marginLeft: '9px' }} width='25' src='https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/project-employees/idCard.svg' />
              </Link>
              : null}
            </TableCell> : null}
            {isRowActive("rfid") ? <RenderingRfidTag employee_settings_endpoint={employee_settings_endpoint} classes={rowClasses} worker={row} /> : null}
            {isRowActive("language_code") ? <TableCell classes={{ root:  clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.active }) }} align="left">{get(row, "language_code", '') === 'en' ? <img src={row.active ? enIcon : enIconOff} width='30' /> : get(row, "language_code", '') === 'es' ? <img src={row.active ? esIcon : esIconOff} width='30' /> : null}</TableCell> : null}
            {isRowActive("address") ? <TableCell classes={{ root:  clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.active }) }}  align="left">{`${get(row, "address", '')}`}</TableCell> : null}
            {isRowActive("zipcode") ? <TableCell classes={{ root:  clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.active }) }}  align="left">{`${get(row, "zipcode", '')}`}</TableCell> : null}
            {isRowActive("department") ? <TableCell classes={{ root:  clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.active }) }}  align="left">{`${get(row, "department.name", '')}`}</TableCell> : null}

            {isRowActive("position") ? <TableCell classes={{ root:  clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.active }) }}  align="left">{`${get(row, "position.name", '')}`}</TableCell> : null}

            {isRowActive("documents") ? <TableCell classes={{ root:  clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.active }) }}  align="left">{get(row, "documents", "")}</TableCell> : null}
            {isRowActive("actions") ? <TableCell classes={{ root:  clsx(rowClasses.root, { [rowClasses.inActiveCell]: !row.active }) }} style={{ pointerEvents: 'auto' }} align="left">
              { get(row, 'deleted_at') &&
                <RecoverButton worker={row} employee_settings_endpoint={employee_settings_endpoint} onRecoverWorker={onRecoverWorker} hasPermission={hasPermission} />
              }

              { !get(row, 'deleted_at') &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton onClick={() => onEdit(row)} disabled={!hasPermission('canEdit')} size='small' style={{ color: '#F45C25' }}>
                    <Tooltip arrow title='Edit'>
                      <Edit style={{ color: hasPermission('canEdit') ? '#F45C25' : '#707070' }} />
                    </Tooltip>
                  </IconButton>
                  <IconButton onClick={() => onShowHireRate(row)} disabled={!hasPermission('canEditSettings') || !row.active} size='small' style={{ color: '#36C240' }}>
                    <Tooltip arrow title='Payroll Settings'>
                      <MonetizationOn style={{ color: hasPermission('canEditSettings') && row.active ? '#36C240' : '#707070' }} />
                    </Tooltip>
                  </IconButton>
                  <IconButton onClick={() => onEdit(row, true)} disabled={!get(row, 'isUser') || !hasPermission('canEditUser') || !row.active} size='small' style={{ color: '#3998FC' }}>
                    <Tooltip arrow title={'User Settings & Permissions'}>
                      <Security style={{ color: get(row, 'isUser') && hasPermission('canEditUser') && row.active ? '#3998FC' : '#707070' }} />
                    </Tooltip>
                  </IconButton>
                  { get(row, 'isUser', false) &&
                    <IconButton onClick={() => {  onDemoteWorker(row, false, true) }} disabled={!hasPermission('canDemote') || (get(row, '_id') === get(currentEmployee, '_id.$oid')) || !row.active} size='small'>
                      <Tooltip arrow title='Demote'>
                        <img style={{ filter: hasPermission('canDemote') && !(get(row, '_id') === get(currentEmployee, '_id.$oid')) && row.active ? 'grayscale(0)' : 'grayscale(1)' }} width='20px' src="https://static-files.connectedbarrel.com/icons/smartbarrel-v1/demoteWorker.svg" />
                      </Tooltip>
                    </IconButton>
                  }
                  { !get(row, 'isUser', false) &&
                    <IconButton onClick={() => {  onPromoteWorker(row) }} disabled={!hasPermission('canPromote') || (get(row, '_id') === get(currentEmployee, '_id.$oid')) || !row.active} size='small'>
                      <Tooltip arrow title='Promote'>
                        <img style={{ filter: hasPermission('canPromote') && !(get(row, '_id') === get(currentEmployee, '_id.$oid')) && row.active ? 'grayscale(0)' : 'grayscale(1)' }} width='20px' src='https://static-files.connectedbarrel.com/icons/smartbarrel-v1/promoteWorker.svg' />
                      </Tooltip>
                    </IconButton>
                  }
                  <IconButton onClick={() => onDeleteWorker(row)} disabled={!hasPermission('canDelete') || (get(row, '_id') === get(currentEmployee, '_id.$oid'))} size='small' style={{ color: '#C93A05' }}>
                    <Tooltip arrow title='Delete'>
                      <DeleteForever style={{ color: hasPermission('canDelete') && !(get(row, '_id') === get(currentEmployee, '_id.$oid')) ? '#C93A05' : '#707070' }} />
                    </Tooltip>
                  </IconButton>
                </div>
              }

            </TableCell> : null}

          </TableRow>
        );
      })}
    </>
  )

};
