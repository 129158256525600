import React, { useState, useEffect } from 'react';
import { FormControl, TextField, InputAdornment } from '@material-ui/core';
import { CoordinatesButtonAndInput } from '../index';
import _ from 'lodash';

export default function GoogleAutocomplete({ address, setAddress, setTimezone, classes, setNearbyProjects }) {

    let componentForm = {
      locality: 'long_name',
      route: 'long_name',
      street_number: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'long_name',
      postal_code: 'short_name',
      sublocality_level_1: 'short_name',
    };

    const [formattedAddress, setFormattedAddress] = useState(address.formatted_address);

    const handleGoogleAddressChange = (ev) => {
      setFormattedAddress(ev.target.value)

      if (_.isEmpty(ev.target.value)) {
        setAddress({
          formatted_address: '',
          postal_code: '',
          streetAddress: '',
          locality: '',
          administrative_area_level_1: '',
          country: null,
          latLng: {},
        })
        setTimezone({
          iana: '',
          offset: 0
        })
        setNearbyProjects([])

        window.marker.setVisible(false);
        window.infoWindow.close();
      }
    }

    const fillInAddress = (place) => {
      setAddress({
        formatted_address: '',
        postal_code: '',
        streetAddress: '',
        locality: '',
        administrative_area_level_1: '',
        country: null,
        latLng: {},
      })

      if (!place) {
        // Get the place details from the autocomplete object.
        place = window.autocomplete.getPlace();
      }

      let eventToSend = {
        formatted_address: place.formatted_address,
      }

      if (place.geometry && place.geometry.location) {

        window.getTimezone({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });

        eventToSend.latLng = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }
        // close infoWindow + hide marker
        window.infoWindow.close()
        window.marker.setVisible(false);
        // update map/marker location and infowindow content
        window.map.setCenter(place.geometry.location);
        window.map.setZoom(12);
        window.infoWindow.setContent(place.formatted_address);
        window.marker.setPosition(place.geometry.location);
        // open infoWindow + show marker
        window.marker.setVisible(true);
        window.infoWindow.open(map, marker);
      }


      for (var i = 0; i < _.get(place, 'address_components', []).length; i++) {
        var addressType = place.address_components[i].types[0];

        if (componentForm[addressType]) {
          var val = place.address_components[i][componentForm[addressType]];

          if (addressType === "country") {
            val = {
              label: place.address_components[i].long_name,
              value: place.address_components[i].short_name,
            }
          }

          if (addressType === "administrative_area_level_1") {
            val = {
              label: place.address_components[i].long_name,
              value: place.address_components[i].short_name,
            }
          }

          eventToSend[addressType] = val;
        }
      }


      let streetAddress = _.get(eventToSend, 'street_number', '')

      if (_.get(eventToSend, 'route')) streetAddress += ` ${_.get(eventToSend, 'route')}`

      if (!_.get(eventToSend, 'locality')) _.set(eventToSend, 'locality', _.get(eventToSend, 'sublocality_level_1', ''))

      setAddress({ ...address, ...eventToSend, streetAddress })
      setFormattedAddress(place.formatted_address)
    }

    const initAutocomplete = () => {
      // inject styling for google autocomplete field into document
      const style = document.createElement('style');
      style.textContent = '.pac-container { z-index: 9999 }'
      document.head.append(style);

      window.autocomplete = new google.maps.places.Autocomplete(document.getElementById('google_maps_autocomplete'), { types: ['geocode'] });

      window.autocomplete.setFields(['address_component', 'formatted_address', 'geometry', 'utc_offset_minutes', 'place_id']);

      window.autocomplete.addListener('place_changed', fillInAddress);
    }

    const geolocate = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          var geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          var circle = new google.maps.Circle(
            { center: geolocation, radius: position.coords.accuracy });

          let bounds = circle.getBounds()
          window.autocomplete.setBounds(bounds);
        });
      }
    }

    useEffect(() => {
      initAutocomplete();
      return () => { };
    }, [])

    useEffect(() => {
      setFormattedAddress(address.formatted_address);
      return () => { };
    }, [address])

    return (
      <FormControl fullWidth>
        <TextField
          id="google_maps_autocomplete"
          onFocus={geolocate}
          label="Full Address"
          value={formattedAddress}
          onChange={handleGoogleAddressChange}
          placeholder="Start typing for autocomplete"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CoordinatesButtonAndInput classes={classes} setFormattedAddress={setFormattedAddress} fillInAddress={fillInAddress} address={address} />
              </InputAdornment>
             ),
          }}
        />
      </FormControl>
    );
  }
