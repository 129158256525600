import React from 'react';
import { CustomTab } from '../SectionTab';

const IncidentSummaryTab = ({ items, loading }) => (<CustomTab
    id="incident_summary"
    title="Incident Summary"
    items={items}
    loading={loading}
    fillColor="#f15922"
    fillBackgroundColor="#fab49f"
/>);

export default IncidentSummaryTab;
