import React, { useState, useContext, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { myTheme } from '../helpers';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';

import SettingsContextProvider from '../employees/contexts/SettingsContext';
import { get } from 'lodash';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import { HeaderComponent, TableComponent } from './components/index';
import { gettingCompanyEmployeeSettings, gettingCompanyProfiles } from './services';

const useStyles = makeStyles((theme) => ({
  dialogLoading: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    backgroundColor: '#ffffffa6',
    zIndex: '9999999',
    justifyContent: "center",
    alignItems: "center"
  }


}));

export default function Crews(props) {

  const { company, config } = props;
  const companyWorkersDomain = get(config, 'companyWorkersDomain');
  const paymentProfileDomain = get(config, 'paymentProfileDomain');
  const crewEndpoint = get(config, 'crewEndpoint');


  const classes = useStyles();

  const [state, setState] = useState({
    loading: false,
    currentCompany: {
      value: get(company, '_id.$oid'),
      label: get(company, 'name')
    },
    companies: [],
    profiles: []
  });

  const onLoading = (flag = true) => {
    setState(prevState => ({
      ...prevState,
      loading: flag
    }));
  }

  const getPaymentProfiles = async () => {
    try {
      let payload = {
        pagination: {
          page: 0,
          rowsPerPage: 25
        }
      }

      const res = await gettingCompanyProfiles(state.currentCompany.value, payload, paymentProfileDomain);
      setState(prevState => ({
        ...prevState,
        profiles: get(res, 'data', [])
      }))
    } catch (error) {

    }
  }

  const getSettings = async () => {
    try {
      onLoading()
      const res = await gettingCompanyEmployeeSettings(companyWorkersDomain);
      setState(prevState => ({
        ...prevState,
        companies: get(res, 'data.companies'),
      }))

      onLoading(false)

    } catch (error) {

      console.log(error)

    }
  }

  useEffect(() => {
    getSettings()
  }, [])

  useEffect(() => {
    getPaymentProfiles()
  }, [state.currentCompany])




  return (
    <ThemeProvider theme={myTheme}>
      <SettingsContextProvider>
        {state.loading &&
          <div className={classes.dialogLoading}>
            <CircularProgress color="primary" />
          </div>}
        <div id="jumbo-styles-container">
          <HeaderComponent userCompany={company} currentCompany={state.currentCompany} />
          <TableComponent companyWorkersDomain={companyWorkersDomain} company={company} currentCompany={state.currentCompany} onLoading={onLoading} companies={state.companies} paymentProfiles={state.profiles} crewEndpoint={crewEndpoint} />
        </div>
      </SettingsContextProvider>
      <NotificationContainer />
    </ThemeProvider >
  )
};
