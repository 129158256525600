import React from 'react'
import LineIndicator from './LineIndicator'

export default function CovidStats({ data }) {
    
    const { covid_sick, covid_not_sick, covid_incomplete } = data
    
    let total = covid_sick + covid_not_sick + covid_incomplete
    if (total == 0) {
        total ++
    }
    
    const sick = covid_sick / total * 100
    const notSick = covid_not_sick / total * 100
    const incomplete = covid_incomplete / total * 100
    
    return (
        <div className="pt-xl-2">
            <a className="mb-3 jr-font-weight-bold text-red jr-fs-xxl" href={data.covid_link}>
                COVID-19 Stats
            </a>
            <ul className="jr-line-indicator">
                <li>
                    <LineIndicator width={`${sick}%`} title="Sick" color="red" value={covid_sick} />
                </li>
                <li>
                    <LineIndicator width={`${notSick}%`} title="Not Sick" color="green" value={covid_not_sick} />
                </li>
                <li>
                    <LineIndicator width={`${incomplete}%`} title="Incomplete" color="orange" value={covid_incomplete} />
                </li>
            </ul>
        </div>
    )
}
