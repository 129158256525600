import React, { useState } from 'react';
import axios from "axios";
import { axiosConfig } from "../helpers/index";
import { myTheme } from "../helpers/index";
import { makeStyles } from "@material-ui/core/styles"
import { ThemeProvider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress } from '@material-ui/core';

export default function RevokeDelete(props) {

  const { config, onDelete, record } = props;

  const useStyles = makeStyles((theme) => ({
    deleteButton: {
      marginLeft: '5px',
      color: '#dc3545',
      borderColor: '#dc3545',
      fontSize: 12,
      width: 70,
      height: 30,
      borderRadius: 4
    },
  }));

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false)

  const handleClose = () => {
    setOpen(false)
    setDeleting(false)
  }

  const handleOpen = (ev) => {
    ev.preventDefault();
    setOpen(true);
    setDeleting(false)
  }

  const handleDelete = () => {
    const url = `${config.revoke_workers_endpoint}/${record.phoneNumber}`;
    setDeleting(true)
    axios.delete(url, axiosConfig()).then(response => {
      setOpen(false);
      setDeleting(false)
      onDelete();
    }).catch(errorDeletingRevoke => {
      console.log({ errorDeletingRevoke });
      setDeleting(false);
    });
  }

  return (
    <>
      <Button className={classes.deleteButton} onClick={handleOpen} style={{ borderRadius: 4, marginTop: 2 }}>
        { deleting ?
          <div style={{ display: 'flex', width: 20, alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress size={16} color='danger' />
          </div> : 'Delete'
        }
      </Button>
      {open && (
        <ThemeProvider theme={myTheme}>
          <Dialog open={open} onClose={handleClose} >
            <DialogTitle>Confirm</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete record with number {record.phoneNumber}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button disabled={deleting} onClick={handleDelete} size="small">
                Delete
              </Button>
              <Button disabled={deleting} onClick={handleClose} size="small">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      )}
    </>
  );
}
