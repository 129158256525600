import React, { useState, useEffect } from 'react';
import { Select, Button, IconButton, CircularProgress, FormControl, InputLabel, makeStyles, TextField, MenuItem, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, InputAdornment, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { Cancel, Send, Add, Edit, Stop, Colorize } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { GithubPicker } from 'react-color';
import _ from 'lodash';
import { createClassifier, editClassifier } from '../../services';
import { notificationCenter } from '../../../helpers/notifications';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '260px'
  },
  newItemInput: {
    width: '260px'
  },
  colorPickerIcon: {
    marginRight: theme.spacing(3),
  },
  select: {
    width: '250px'
  },
  addUpdateButtonWrapp: {
    display: 'flex',
    width: '50%',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end'
  },
  itemsList: {
    padding: 0,
    marginTop: 20,
    width: '100%',
    minHeight: 295,
    maxHeight: 295,
    border: "1px solid #9c9c9c2e",
    overflow: 'scroll',
    backgroundColor: theme.palette.background.paper,
    '& li:nth-child(even)': {
      backgroundColor: '#e0e0e0'
    }
  },
  noColorItem: {
    borderBottom: '3px solid rgb(255, 0, 0)',
    width: '100%',
    transform: 'rotate(45deg)',
    transformOrigin: '-1px 7px'
  },
  chooseNewWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    width: '50%',
    margin: 'auto',
    marginTop: '5%'
  },
  classifierItemFieldHeader: {
    color: 'grey'
  },
  classifierItemFieldSection: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '1em',
    minWidth: '8em',
    maxWidth: '8em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}));

const colorsConfig = ['#0075f2', '#FF7F11', '#B47AEA', '#42BFDD', '#00CC66', '#BA1200', '#00F2F2', '#FEEA00', '#DBFE87', '#FFD131', '#f24236', '#F391A0', '#096B72', '#E54B4B', '#69DC9E', '#EC0868', '#F17300', '#3DDC97', '#7E52A0', '#FF66B3', '#FAFF00', '#C04ABC', '#1982C4', '#DB5461', '#95C623', '#00A6A6', '#20BF55']

export default function CreateClassifier(props) {

  const { classifier, applications, classifierTypes, onCloseClassifier, company, classifiersEndpoint, builtInClassifiers, selectedPreset } = props;
  const classes = useStyles();

  const [currentCompany, setCurrentCompany] = useState(_.isNull(classifier) ? _.get(company, '_id.$oid') : _.get(classifier, 'company._id'))
  const [saving, setSaving] = useState(false)
  const [newClassifierType, setNewClassifierType] = useState(selectedPreset ? 'selectFromPreset' : 'createNew')
  const [showAddItemFields, setShowAddItemFields] = useState(false)
  const [openConfirmChangePreset, setOpenConfirmChangePreset] = useState(null)
  const [state, setState] = useState({
    name: _.get(classifier, 'name', ''),
    code: _.get(classifier, 'code', ''),
    appliesTo: _.get(classifier, 'appliesTo', ''),
    classifierItems: _.get(classifier, 'items', []),
    type: _.get(classifier, 'type', ''),
    newItem: {},
    error: {},
    displayColorPicker: false,
    colors: _.clone(colorsConfig),
  });
  const [selectedPresetClassifier, setSelectedPresetClassifier] = useState({});

  const tagNameRef = React.useRef();

  const onBasicChange = (field, value) => {
    setState(prevState => ({
      ...prevState,
      [field]: value,
      // error: {
      //   ...prevState.error,
      //   [field] : ''
      // }
    }))
  }

  const onPresetNameChange = (event) => {
    if (!_.isEmpty(_.get(state, 'classifierItems'))) {
      setOpenConfirmChangePreset(event)
    } else {
      setSelectedPresetClassifier(_.get(event, 'target.value'))

      setState(prevState => ({
        ...prevState,
        appliesTo: _.get(event, 'target.value.settings.defaults.appliesTo'),
        type: _.get(event, 'target.value.settings.defaults.type'),
        code: _.get(event, 'target.value.settings.defaults.code'),
        classifierItems: []
      }))
    }
  }

  const handleCloseConfirmChangePreset = () => {
    setOpenConfirmChangePreset(null)
  }

  const confirmChangePreset = () => {
    setOpenConfirmChangePreset(null)
    setSelectedPresetClassifier(_.get(openConfirmChangePreset, 'target.value'))

    setState(prevState => ({
      ...prevState,
      appliesTo: _.get(openConfirmChangePreset, 'target.value.settings.defaults.appliesTo'),
      type: _.get(openConfirmChangePreset, 'target.value.settings.defaults.type'),
      code: _.get(openConfirmChangePreset, 'target.value.settings.defaults.code'),
      classifierItems: []
    }))
  }

  const onNewItemChange = (field, value) => {
    setState(prevState => ({
      ...prevState,
      newItem: {
        ...prevState.newItem,
        [field]: value
      }
    }))
  }

  const onColorPickerClick = () => {
    setState(prevState => ({
      ...prevState,
      displayColorPicker: !prevState.displayColorPicker
    }))
  }

  const handleColorChange = (color) => {
    onColorPickerClick()

    setState(prevState => ({
      ...prevState,
      newItem: {
        ...prevState.newItem,
        color: color.hex === '#ffffff' ? null : color.hex
      }
    }))
  }

  const onCreateClassifier = async (flag) => {
    if (flag) {
      if (_.isEmpty(_.get(state, 'name')) || _.isEmpty(_.get(state, 'code')) || _.isEmpty(state, 'type') || _.isEmpty(state, 'appliesTo')) {
        notificationCenter('error', 'You are missing some required fields!')
      } else {
        try {

          setSaving(true)
          let payload = {
            name: _.get(state, 'name', ''),
            code: _.get(state, 'code', ''),
            type: _.get(state, 'type', ''),
            appliesTo: _.get(state, 'appliesTo', ''),
            builtIn: newClassifierType === 'selectFromPreset' ? true : false,
            items: state.classifierItems.map(item => {
              let newItem = {
                name: _.get(item, 'name'),
                code: _.get(item, 'code'),
              }

              if (item.color) {
                newItem.color = _.get(item, 'color')
              }

              Object.keys(_.get(selectedPresetClassifier, 'settings.itemFields', {})).map(oneKey => {
                newItem[oneKey] = _.get(item, oneKey)
              })

              return(newItem)
            }),
          }

          if (!_.isNull(classifier)) {
            const res = await editClassifier(_.get(company, '_id.$oid'), payload, classifiersEndpoint, _.get(classifier, '_id'));
          } else {
            const res = await createClassifier(_.get(company, '_id.$oid'), payload, classifiersEndpoint);
          }

          setSaving(false);
          onCloseClassifier(true)
        } catch (error) {
          notificationCenter('error', 'Could not save. Please try again.')
          setSaving(false);
          console.log("ERROR ", error)
        }
      }
    } else {
      onCloseClassifier()
    }
  }

  const handleItemClick = (item, { selected, selectedIndex, deleteItem, updateItem }) => {

    let newItem = {}
    let showUpdateButton = false
    let colors = state.colors.concat()
    const selectedItems = state.classifierItems.concat()

    if (selected) {
      // const selectedIndex = selectedItems.findIndex(selectedItem => {
      //   return _.isEqual(selectedItem.code, item.code)
      // });

      if (deleteItem) {
        selectedItems.splice(selectedIndex, 1);
        if (item.color) colors.push(item.color)
      } else if (updateItem) {
        if (selectedItems[selectedIndex].color) colors.push(selectedItems[selectedIndex].color)
        selectedItems[selectedIndex] = { ...item }
      } else {
        newItem = state.classifierItems[selectedIndex]
        showUpdateButton = true
      }
    } else {
      selectedItems.push(item);
    }

    setState(prevState => ({
      ...prevState,
      classifierItems: selectedItems,
      newItem,
      showUpdateButton,
      displayColorPicker: false,
      colors,
      selectedIndex,
    }))

    tagNameRef.current.focus()
  }

  const handleNewItemKeyPress = (e) => {

    if(_.isEqual(e.key, 'Enter') && !_.isEmpty(state.newItem.name)){
      handleItemClick({ ...state.newItem }, { selected: state.showUpdateButton, updateItem: state.showUpdateButton })
    }
  }

  const renderPresetItemFields = () => {
    return Object.keys(_.get(selectedPresetClassifier, 'settings.itemFields', {})).map(oneKey => ({ name: oneKey, ..._.get(selectedPresetClassifier, `settings.itemFields.${oneKey}`, {})})).map(oneItem => {
      if (oneItem.type === 'String') {
          return (<TextField label={oneItem.label} required onChange={(e) => onNewItemChange(oneItem.name, e.target.value)} className={classes.input} value={_.get(state, `newItem[${oneItem.name}]`, '')} />)
      } else if (oneItem.type === 'Dropdown') {
        return (
          <FormControl required style={{ width: 260 }}>
            <InputLabel id="classifierType-select-label">{oneItem.label ? oneItem.label : oneItem.name}</InputLabel>
            <Select
              required
              classes={{ root: classes.select }}
              value={_.get(state, `newItem[${oneItem.name}]`, "")}
              onChange={(e) => onNewItemChange(oneItem.name, e.target.value)}>
              {
                oneItem.values.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)
              }
            </Select>
          </FormControl>
        )
      }
    })
  }

  const disableAddUpdateItemButton = () => {
    if (!_.isEmpty(_.get(state, 'newItem.name')) && !_.isEmpty(_.get(state, 'newItem.code'))) {
      let disable = false

      Object.keys(_.get(selectedPresetClassifier, 'settings.itemFields', {})).map(oneKey => {
        if (!_.get(state, `newItem[${oneKey}]`)) {
          disable = true
        }
      })

      return disable
    } else {
      return true
    }
  }

  useEffect(() => {
    let colors = state.colors.concat()

    state.classifierItems.map(oneItem => {
      _.remove(colors, (oneColor) => { return oneColor === oneItem.color })
    })

    setState(prevState => ({
      ...prevState,
      colors,
    }))
  }, [state.classifierItems])

  useEffect(() => {
    if (!_.isNull(selectedPreset)) {
      setSelectedPresetClassifier(selectedPreset)

      setState(prevState => ({
        ...prevState,
        appliesTo: _.get(selectedPreset, 'settings.defaults.appliesTo'),
        type: _.get(selectedPreset, 'settings.defaults.type'),
        name: _.get(selectedPreset, 'name'),
        code: _.get(selectedPreset, 'settings.defaults.code'),
      }))
    }
  }, [])

  useEffect(() => {
    if (_.get(classifier, 'name')) {
      const preset = builtInClassifiers.filter(oneClassifier => oneClassifier.name === classifier.name)

      if (!_.isEmpty(preset)) {
        setSelectedPresetClassifier(preset[0])
      }
    }
  }, [])

  return (
    <div style={{ padding: '30px', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Add Classifier</span>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: 30 }}>
            { newClassifierType === 'createNew' ?
              <TextField label="Name" required onChange={(e) => onBasicChange('name', e.target.value)} className={classes.input} value={_.get(state, 'name', '')} />
              :
              <FormControl required style={{ width: 260 }}>
                <InputLabel id="preset-select-label">Name</InputLabel>
                <Select
                  disabled={selectedPreset && classifier}
                  style={{ textTransform: 'capitalize'}}
                  required
                  classes={{ root: classes.select }}
                  id="preset-select"
                  value={selectedPresetClassifier}
                  onChange={onPresetNameChange}>
                  {
                    builtInClassifiers.map(item => <MenuItem key={_.get(item, 'name')} value={item}>{_.get(item, 'name')}</MenuItem>)
                  }
                </Select>
              </FormControl>
            }
            <TextField disabled={newClassifierType === 'selectFromPreset'} label="Code" required onChange={(e) => onBasicChange('code', e.target.value)} className={classes.input} value={_.get(state, 'code', '')} />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControl required style={{ width: 260 }}>
              <InputLabel id="appliesTo-select-label">Applies to</InputLabel>
              <Select
                style={{ textTransform: 'capitalize'}}
                required
                disabled={newClassifierType === 'selectFromPreset'}
                classes={{ root: classes.select }}
                id="appliesTo-select"
                value={_.get(state, 'appliesTo', '').toLowerCase()}
                onChange={(e) => onBasicChange('appliesTo', e.target.value)}>
                {
                  applications.map(item => <MenuItem key={_.get(item, '_id')} value={_.get(item, 'name')}>{_.get(item, 'label')}</MenuItem>)
                }
              </Select>
            </FormControl>
            <FormControl required style={{ width: 260 }}>
              <InputLabel id="classifierType-select-label">Type</InputLabel>
              <Select
                style={{ textTransform: 'capitalize'}}
                required
                disabled={newClassifierType === 'selectFromPreset'}
                classes={{ root: classes.select }}
                id="classifierType-select"
                value={state.type}
                onChange={(e) => onBasicChange('type', e.target.value)}>
                {
                  classifierTypes.map(item => <MenuItem key={_.get(item, '_id')} value={_.get(item, 'name')}>{_.get(item, 'label')}</MenuItem>)
                }
              </Select>
            </FormControl>
          </div>
        </div>

        <span style={{ fontSize: '18px', fontWeight: 'bold', marginTop: 20 }}>Items</span>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex' }}>
              <TextField inputRef={tagNameRef} label="Name" required onKeyPress={handleNewItemKeyPress} onChange={(e) => onNewItemChange('name', e.target.value)} className={classes.newItemInput} value={_.get(state.newItem, 'name', '')} style={{ marginRight: 30 }}/>
              <TextField
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="choose classifier item color"
                      onClick={onColorPickerClick}
                    >
                    <Colorize style={{ color: state.newItem.color }}/>
                    </IconButton>
                    { state.displayColorPicker ? <div style={{ position: 'absolute', zIndex: 2, right: 15, top: 50 }}>
                      <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }} onClick={onColorPickerClick}/>
                      <GithubPicker width={187} colors={_.get(state, 'colors', []).concat(['#ffffff'])} triangle="top-right" onChange={handleColorChange} />
                    </div> : null }
                  </InputAdornment>
                }}
                label="Code" required onKeyPress={handleNewItemKeyPress} onChange={(e) => onNewItemChange('code', e.target.value)} className={classes.newItemInput} value={_.get(state.newItem, 'code', '')} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {renderPresetItemFields()}
            </div>
            <div className={classes.addUpdateButtonWrapp}>
              <Button
                style={{ marginTop: 20, width: 164 }}
                disabled={disableAddUpdateItemButton()}
                variant="contained"
                onClick={() => handleItemClick({ ...state.newItem }, { selected: state.showUpdateButton, updateItem: state.showUpdateButton, selectedIndex: state.selectedIndex })}>
                { state.showUpdateButton ? 'Update ' : 'Add '} Item
              </Button>
            </div>
            <List dense className={classes.itemsList}>
              {
                state.classifierItems.map((item, index) =>
                  <ListItem key={index} button divider onClick={() => handleItemClick(item, { selected: true, selectedIndex: index })} style={{ paddingLeft: 2 }}>
                    <ListItemIcon style={{ minWidth: 46, minHeight: 46, padding: 10 }}><div style={{ backgroundColor: item.color ? item.color : 'white', width: '100%', borderRadius: 5, border: '1px solid slategrey' }}>{ !item.color && <div className={classes.noColorItem}></div>}</div> </ListItemIcon>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexBasis: '30em', textOverflow: 'ellipsis' }}>
                      <div className={classes.classifierItemFieldSection}>
                        <div className={classes.classifierItemFieldHeader}>Name</div>
                        <div>{item.name}</div>
                      </div>
                      <div className={classes.classifierItemFieldSection} style={{ marginRight: _.size(_.get(selectedPresetClassifier, 'settings.itemFields', {})) !== 0 ? '1em' : 0 }}>
                        <div className={classes.classifierItemFieldHeader}>Code</div>
                        <div>{item.code}</div>
                      </div>
                      { Object.keys(_.get(selectedPresetClassifier, 'settings.itemFields', {})).map((oneKey, index) =>
                          <div className={classes.classifierItemFieldSection} style={{ marginRight: index < _.size(_.get(selectedPresetClassifier, 'settings.itemFields', {})) - 1 ? '1em' : 0 }}>
                            <div className={classes.classifierItemFieldHeader}>{selectedPresetClassifier.settings.itemFields[oneKey].label}</div>
                            <div>{item[oneKey]}</div>
                          </div>
                        )
                      }
                    </div>
                    <ListItemSecondaryAction>
                      <DeleteIcon onClick={() => handleItemClick(item, { selected: true, selectedIndex: index, deleteItem: true })} style={{ cursor: 'pointer' }} />
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              }
            </List>
          </div>
        </div>
      </div>

      <div style={{ marginTop: '20px' }}>
        <Button
          variant="contained"
          onClick={() => onCreateClassifier(false)}
          startIcon={<Cancel />}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className='ml-1'
          disabled={saving}
          style={{ marginLeft: '5px', color: '#009687' }}
          onClick={() => onCreateClassifier(true)}
          startIcon={saving ? <CircularProgress size={10} color="primary" /> : <Send />}>
          Save
        </Button>
      </div>

      <Dialog open={!_.isNull(openConfirmChangePreset)} onClose={handleCloseConfirmChangePreset}>
        <DialogTitle>Change classifier</DialogTitle>
          <DialogContent>
            <DialogContentText>
              If you change the classifier your items will be lost.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmChangePreset}>Cancel</Button>
            <Button color='primary' onClick={confirmChangePreset}>Confirm</Button>
          </DialogActions>
      </Dialog>
    </div>

  )
};
