import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DeviceCommentEditDialog({ message, children, onEdit = (text) => ({}) }) {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState(message);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);

    return onEdit(text);
  };

  return (
      <>
        <Button color="primary" onClick={handleClickOpen}>
          {children}
        </Button>
        <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
          <DialogTitle>Edit Comment</DialogTitle>
          <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                label="Comment"
                defaultValue={message}
                placeholder={message}
                fullWidth
                onChange={({ target }) => setText(target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleClose} color="primary">
              Edit
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
}
