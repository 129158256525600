import axios from 'axios';
import { axiosConfig } from '../../helpers/index';
import { get } from 'lodash';


export const syncingCMICCostCodes = (domain, cmic_id) => {
  return axios.get(`${domain}/costcodes-cmic-dashboard-sync/${cmic_id}`, axiosConfig())
}

export const getDashboardCMICTimesheetStatus = (domain, payload) => {
  return axios.post(`${domain}/timesheet-cmic-dashboard-status`, payload, axiosConfig())
}


export const syncCMICTimesheets = (domain, payload) => {
  return axios.post(`${domain}/timesheet-cmic-dashboard-sync`, payload, axiosConfig())
}