import React from 'react'
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

export default function WorkersBar(props) {

  console.log("WorkersBar", props);

  const { title, data } = props


  const buildCategories = () => {
    return data.map(e => e.name)
  }

  const plotData = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'column'
    },
    title: {
      text: title
    },
    xAxis: {
      categories: buildCategories(),
    },
    yAxis: {
      min: 0,
      title: {
        text: title
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
        }
      }
    },
    series: [{
      name: 'Workers',
      colorByPoint: true,
      data: data
    }]
  }


  return (
    <HighchartsReact highcharts={Highcharts} options={plotData} />
  )
}
