import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { $axios } from '../../../helpers';

export const BroadcastConfirm = ({ workers, project, config, message, disabled, onBroadcast }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const total = () => workers.length;

  function handleSendBroadcast() {
    setLoading(true);

    const data = {
      employee_id: workers.map(worker => worker._id),
      message,
      project,
    };

    $axios.$post(`${config.broadcastEndpoint}/create/`, data)
        .then((data) => onBroadcast && onBroadcast(data))
        .finally(() => {
          setLoading(false);
          return handleClose();
        });
  }

  return <>
    <Button variant="contained" color="primary" disabled={disabled} onClick={handleClickOpen}>Schedule</Button>

    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className="bg-danger">
        <Grid container>
          <Grid xs={true} item>
            <WarningIcon />
          </Grid>
          <Grid xs={'auto'} item style={{ display: 'flex', alignSelf: 'center', width: '40px' }}>
            <Button className="text-white" disabled={loading} onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to release broadcast to {total()} workers?
        </DialogContentText>
      </DialogContent>
      <DialogActions className="widget-chat-text">
        <Button variant="outlined" disabled={loading} onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" disabled={loading} onClick={handleSendBroadcast} autoFocus>
          {loading ? 'Broadcasting...' : 'Broadcast'}
        </Button>
      </DialogActions>
    </Dialog>
  </>;
};
