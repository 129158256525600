import React from 'react';
import { Dialog, Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { get } from 'lodash';

export default function HasAccess(props) {

  const { hasAccess, onHandleAccess, worker } = props;

  console.log(worker)

  return (
    <>
      <Dialog
        open={hasAccess}
        onClose={() => onHandleAccess(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Has Access"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to {get(worker, 'hasAccess', true) ? 'deny' : 'grant'} access to {`${get(worker, 'first_name')} ${get(worker, 'last_name')}`}?
         </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onHandleAccess(true)} color="primary">
            Yes
          </Button>
          <Button onClick={() => onHandleAccess(false)} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
};