import React, { createContext, useContext, useEffect, useState } from 'react';
import { get } from 'lodash';
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { SettingsContext } from '../../contexts/SettingsContext';
import IncidentSummaryContent from './components/incidentSummary/Content';
import ToolboxTalkContent from './components/toolboxTalk/Content';
import WorkSummaryContent from './components/workSummary/Content';
import SectionTab from './components/SectionTab';
import WorkSummaryTab from './components/workSummary/Tab';
import ToolboxTalkTab from './components/toolboxTalk/Tab';
import IncidentSummaryTab from './components/incidentSummary/Tab';
import axios from 'axios';
import { axiosConfig } from '../../../helpers';

export const ProjectContext = createContext({});

export default function ProjectMetrics({ project, company, user, config }) {
  const settingsContext = useContext(SettingsContext);
  const [tab, setTab] = useState('');
  const [loading, setLoading] = useState(true);
  const [workSummary, setWorkSummary] = useState([]);
  const [incidentSummary, setIncidentSummary] = useState([]);
  const [toolboxTalk, setToolboxTalk] = useState([]);

  const formattingDate = settingsContext.onGetTimeSheetDateFormat;

  const handleChange = (event, newValue) => tab === newValue
    ? setTab('')
    : setTab(newValue);

  const workStatementUrl = (type) =>
    `${config.project_workSummary_endpoint}/list/${project._id.$oid}/${company._id.$oid}/${formattingDate()}/${type}`;

  const fetchWorkStatement = (type) => axios.get(workStatementUrl(type), axiosConfig());

  const fetchWorkSummary = () => fetchWorkStatement('work_summary')
    .then(({ data }) => setWorkSummary(data));

  const fetchIncidentSummary = () => fetchWorkStatement('incident')
    .then(({ data }) => setIncidentSummary(data));

  const fetchToolboxTalk = () => fetchWorkStatement('tool_box_talk')
    .then(({ data }) => setToolboxTalk(data));

  useEffect(() => {
    setLoading(true);

    Promise.all([fetchWorkSummary(), fetchIncidentSummary(), fetchToolboxTalk()])
      .finally(() => setLoading(false));

  }, [settingsContext.timesheetDate]);

  const providerValue = () => ({
    tab,
    setTab,
    config,
    user,
    project,
    company,
    date: formattingDate(),
    loaders: { fetchWorkSummary, fetchIncidentSummary, fetcToolboxTalk: fetchToolboxTalk },
  });

  return (
    <ProjectContext.Provider value={providerValue()}>
      <div className="MuiPaper-root MuiAccordion-root Mui-expanded MuiAccordion-rounded MuiPaper-elevation1 MuiPaper-rounded">
        <TabContext value={tab}>
          <Tabs TabIndicatorProps={{ style: { display: 'none'} }} classes={{ root: 'bg-white' }} onChange={handleChange} value={tab} indicatorColor="primary" style={{ borderRadius: tab !== '' ? '4px 4px 0px 0px' : 4 }}>
            <Tab value={''} style={{ display: 'none' }} />

            { get(settingsContext, 'uiConfig.ui.modules.workSummary.permissions.canEdit') &&
              <SectionTab value="work_summary" isActive={tab === 'work_summary'} isLeftMostTab={tab === ''} borderColor='#006e90' tabBackgroundColor="#e9f0f3">
                <WorkSummaryTab loading={loading} items={workSummary} />
              </SectionTab>
            }

            { get(settingsContext, 'uiConfig.ui.modules.incidents.permissions.canEdit') &&
              <SectionTab value="incident_summary" isActive={tab === 'incident_summary'} borderColor='#f15922' tabBackgroundColor="#ffeeea">
                <IncidentSummaryTab loading={loading} items={incidentSummary} />
              </SectionTab>
            }

            { get(settingsContext, 'uiConfig.ui.modules.toolBoxTalk.permissions.canEdit') &&
              <SectionTab value="toolbox_talk" isActive={tab === 'toolbox_talk'} borderColor='#99c24d' tabBackgroundColor="#f4f9ee">
                <ToolboxTalkTab loading={loading} items={toolboxTalk} />
              </SectionTab>
            }
          </Tabs>

          { get(settingsContext, 'uiConfig.ui.modules.workSummary.permissions.canEdit') &&
            <TabPanel value="work_summary" className="p-a-0" style={{ borderRadius: 0 }}>
              <WorkSummaryContent items={workSummary} />
            </TabPanel>
          }

          { get(settingsContext, 'uiConfig.ui.modules.incidents.permissions.canEdit') &&
            <TabPanel value="incident_summary" className="p-a-0" style={{ borderRadius: 0 }}>
              <IncidentSummaryContent items={incidentSummary} />
            </TabPanel>
          }

          { get(settingsContext, 'uiConfig.ui.modules.toolBoxTalk.permissions.canEdit') &&
            <TabPanel value="toolbox_talk" className="p-a-0" style={{ borderRadius: 0 }}>
              <ToolboxTalkContent items={toolboxTalk} />
            </TabPanel>
          }
        </TabContext>
      </div>
    </ProjectContext.Provider>
  );
};
