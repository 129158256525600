import React, { useState, useLayoutEffect } from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import axios from "axios";

const GlobalCovidChart = () => {

    const buildCategories = () => {
        let result = []
        for (let i = 30; i >= 0; i--) {
            result.push(`${i} days`)
        }

        return result
    }
    
    const [chartData, setChartData] = useState(null);

    const plotData = series => {
        const today = moment().format("MM/DD/YYYY")
        return {
            chart: {
                type: 'areaspline'
            },
            title: {
                text: 'United States'
            },
            subtitle: {
                text: `Covid 19 Stats as of ${today}`
            },
            xAxis: {
                categories: buildCategories()
            },
            yAxis: {
                title: {
                    text: 'Cases'
                }
            },
            plotOptions: {
                areaspline: {
                    dataLabels: {
                    enabled: false
                    },
                    marker: {
                    enabled: false
                    },
                    enableMouseTracking: true
                }
            },
            series: series
            // series: [{
            //   name: 'Tokyo',
            //   data: [7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6]
            // }, {
            //   name: 'London',
            //   data: [3.9, 4.2, 5.7, 8.5, 11.9, 15.2, 17.0, 16.6, 14.2, 10.3, 6.6, 4.8]
            // }]
        }
    }

    const transformResponse = data => {
        const len = data.length
        const relevant = data.splice(len - 31, 30)
        let confirmed = {
            name: "Confirmed",
            data: []
        }
        let deaths = {
            name: "Deaths",
            data: []
        }
        let recovered = {
            name: "Recovered",
            data: []
        }
        let active = {
            name: "Active",
            data: []
        }
        
        
        relevant.forEach(day => {
            confirmed['data'].push(day['Confirmed'])
            deaths['data'].push(day['Deaths'])
            recovered['data'].push(day['Recovered'])
            active['data'].push(day['Active'])
        });
        
        let series = [confirmed, deaths, recovered, active]
        
        return series
    }
    const fetchData = () => {
        axios.get('https://api.covid19api.com/total/country/united-states').then(response => {
            console.log({ response });
            const data = transformResponse(response.data)
            console.log({ data });
            setChartData(plotData(data))
        })
    }
    
    useLayoutEffect(() => {
        if (chartData == null)
            fetchData()

        return () => { };
    }, [])

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={chartData} />
        </div>
    );
}

export default GlobalCovidChart;
