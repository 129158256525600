import React from 'react';
import { DeviceWidgets } from './components/DeviceWidgets';
import { DeviceSnapshotList } from './components/DeviceSnapshotList';
import DeviceVoltageChart from './components/DeviceVoltageChart';

export default function StatusPage(device) {
  return (
      <section>
        <DeviceWidgets device={device} />
        <br />
        <DeviceVoltageChart snapshots={device.snapshot} />
        <br />
        <DeviceSnapshotList snapshots={device.snapshot} />
      </section>
  );
}
