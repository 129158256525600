import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { get } from 'lodash';
import { Done, Edit } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  rateIcons: {
    fontSize: '16px',
    marginLeft: '15px',
    marginBottom: '4px',
    cursor: "pointer"
  }
}));

export default function PerDiemTableEdit(props) {

  const { worker, onEditPerDiemRate } = props;

  const classes = useStyles();

  const [state, setState] = useState({
    edit: false,
    perDiemRate: get(worker, 'settings.per_diem_rate', 0)
  })

  const onEdit = () => {
    setState(prevState => ({
      ...prevState,
      edit: true
    }))
  }

  const onSave = () => {
    setState(prevState => ({
      ...prevState,
      edit: false
    }))

    onEditPerDiemRate(state.perDiemRate, get(worker, '_id'))
  }

  const onChangeRate = perDiemRate => {
    setState(prevState => ({
      ...prevState,
      perDiemRate
    }))
  }

  return (
    <>
      {state.edit ? <TextField onChange={(e) => onChangeRate(e.target.value)} style={{ width: '50px' }} type="number" value={get(state, 'perDiemRate', 0)} /> : <span>{get(state, 'perDiemRate', 0)}</span>}
      {state.edit ? <Done onClick={() => onSave()} style={{ color: '#009687' }} className={classes.rateIcons} /> : <Edit onClick={() => onEdit()} className={classes.rateIcons} />}
    </>
  )
};