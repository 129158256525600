import React, { useState } from 'react'
import { Fab } from "@material-ui/core";
import { CheckCircle, Cancel } from "@material-ui/icons";
import axios from "axios";

export default function Approved({ shift }) {

  const [approved, setApproved] = useState(shift.approved);

  const buildListener = (shouldApprove) => {
    return () => {
      const endpoint = `${location.origin}/projects/${shift.project_id.$oid}/shift_approval/${shift.employee_id.$oid}`
      axios.post(endpoint, buildPayload(shouldApprove)).then(response => {
        setApproved(shouldApprove)
      }).catch(errorSavingBreaktime => {
        console.error({ errorSavingBreaktime })
      })
    }
  }

  const buildPayload = (shouldApprove) => {
    var csrfToken = $('meta[name="csrf-token"]').attr("content");

    let fd = new FormData()
    fd.append("authenticity_token", csrfToken)
    fd.append(`shifts[approve_shift[${shift._id.$oid}]]`, shouldApprove ? "1" : "0")
    return fd
  }

  const approve = buildListener(true)
  const disapprove = buildListener(false)

  if (approved) {
    return (
      <Fab size="small" onClick={disapprove}>
        <CheckCircle style={{color: "#54DB56"}} />
      </Fab>
    )
  } else {
    return (
      <Fab size="small" onClick={approve}>
        <Cancel color="error"/>
      </Fab>
    )
  }

}
