import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import React, { useEffect, useRef, useState } from 'react';
import { $axios } from '../../../helpers';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import DeviceCommentEditDialog from './DeviceCommentEditDialog';

export function DeviceComments({ device }) {
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);

  useEffect(fetchComments, [device]);

  const input = useRef();

  function fetchComments() {
    $axios.$get(`/devices/${device._id}/device_comments.json`)
        .then(comments => setComments(comments))
        .catch(console.log);

  }

  function addComment(message) {
    const token = document.querySelector('meta[name="csrf-token"]').content;

    const payload = {
      text: message,
      authenticity_token: token,
    };

    $axios.$post(`/devices/${device._id}/device_comments.json`, payload)
        .then(() => fetchComments())
        .catch(console.log)
        .finally(() => {
          setComment('');
          input.current.value = null;
        });
  }

  function removeComment(id) {
    const token = document.querySelector('meta[name="csrf-token"]').content;

    const payload = { authenticity_token: token };

    $axios.$delete(`/devices/${device._id}/device_comments/${id}.json`, payload)
        .then(() => fetchComments())
        .catch(console.log);
  }

  function editComment(id, text) {
    const token = document.querySelector('meta[name="csrf-token"]').content;

    const payload = {
      authenticity_token: token,
      device_comment: {
        text: text,
      },
    };

    $axios.$put(`/devices/${device._id}/device_comments/${id}.json`, payload)
        .then(() => fetchComments())
        .catch(console.log);
  }

  return (
      <Grid spacing={3} container>
        <Grid className="w-100" item>
          <Table>
            <TableBody>
              {!comments.length && (
                  <TableRow>
                    <TableCell align={'center'} colSpan={99} className="p-y-5">
                      <span>No comments to show</span>
                    </TableCell>
                  </TableRow>
              )}

              {comments.map((comment) => (
                  <TableRow key={comment.id.$oid}>
                    <TableCell component="th" scope="row">
                      {`${comment.created_by.first_name} ${comment.created_by.last_name}`}
                    </TableCell>
                    <TableCell>{moment(comment.updated_at).calendar()}</TableCell>
                    <TableCell>{comment.text}</TableCell>
                    <TableCell align="right">
                      <ButtonGroup size={'small'} className="m-y-1" variant="contained" color="primary">
                        <DeviceCommentEditDialog
                            message={comment.text}
                            onEdit={(text) => editComment(comment.id.$oid, text)}>
                          <span>Edit</span>
                        </DeviceCommentEditDialog>
                        <Button onClick={() => removeComment(comment.id.$oid)}>Delete</Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <hr />
        <Grid className="w-100" item>
          <Grid spacing={2} alignItems={'center'} container>
            <Grid xs={true} item>
              <TextField
                  defaultValue={comment}
                  variant="outlined"
                  inputRef={input}
                  multiline
                  minRows={1}
                  maxRows={3}
                  onChange={({ target }) => setComment(target.value)}
              />
            </Grid>
            <Grid xs={'auto'} item>
              <Button disabled={!comment} onClick={() => addComment(comment)}>Add</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  );
}
