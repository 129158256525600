import React from 'react';
import Select from 'react-select';

const options = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' }
]
export default function PaymentProfileList(props) {

  const selectStyles = {
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      marginRight: "7px",
      marginTop: "3px",
      zIndex: 9999999999
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 9999999999
    }),
    menuList: (provided, state) => ({
      ...provided,
      zIndex: 9999999999
    }),
  }

  return (
    <div style={{ display: 'flex', alignItems: 'baseline' }}>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isSearchable={true}
        placeholder="Select Payment Profile"
        // onChange={onCompanyFilterChange}
        name="profiles"
        // value={currentCompany}
        styles={selectStyles}
        options={options}
      />
    </div>
  )
};