import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import SectionSearch from './SectionSearch';
import SectionAccordion from './SectionAccordion';

const SectionContent = ({ children, items, renderToolbar, renderItem, renderActions, contentBorderColor, isFirstTab, contentBackgroundColor }) => {
  const [hideEmpty, setHideEmpty] = useState(false);
  const [search, setSearch] = useState('');

  const visibleItems = () => {
    if (!hideEmpty) {
      return items;
    }

    return items.filter(item => item.list.length);
  };

  const searchedList = (list) => {
    const lowerSearch = search.toLowerCase();

    if (!search) {
      return list;
    }

    return list.filter(item =>
        item.notes.toLowerCase().includes(lowerSearch) ||
        item.employee.first_name.toLowerCase().includes(lowerSearch) ||
        item.employee.last_name.toLowerCase().includes(lowerSearch));
  };

  return (
      <Card style={{ borderRadius: isFirstTab ? '0px 4px 4px 4px' : 4, border: `2px solid ${contentBorderColor}`, boxShadow: 'none', marginTop: -2, background: `linear-gradient(${contentBackgroundColor} 1%, white 10%)` }}>
        <Box padding="1em">
          <SectionSearch
              renderActions={renderActions}
              onHideEmpty={state => setHideEmpty(state)}
              onSearch={setSearch}
          >
            {children}
          </SectionSearch>
        </Box>

        <Box padding="1em">
          {!visibleItems().length && (
              <div className="text-center p-a-2">No companies to display</div>
          )}

          {visibleItems().map((company, index) => (
              <SectionAccordion
                  key={company._id}
                  name={company.name}
                  items={searchedList(company.list)}
                  renderItem={renderItem}
                  renderToolbar={renderToolbar && renderToolbar(company)}
                  isLastItem={index === visibleItems().length - 1}
              />
          ))}
        </Box>
      </Card>
  );
};

export default SectionContent;
