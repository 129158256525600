import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Search } from '@material-ui/icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SBSwitch } from '../../../../reusableComponents';

const gridStyle = { display: 'flex', alignItems: 'center', justifyContent: 'space-between' };

const SectionSearch = ({ children, renderActions, onHideEmpty = () => ({}), onSearch = () => ({}) }) => {

  return (
      <Grid container spacing={2} style={gridStyle} className="p-x-1">
        <Grid item>
          <TextField
              variant="outlined"
              placeholder="Search"
              size="small"
              className={'bg-white'}
              style={{ width: '350px' }}
              onChange={({ target }) => onSearch(target.value)}
              InputProps={{
                startAdornment: <InputAdornment className="p-l-1" position="start"><Search /></InputAdornment>,
                className: 'p-l-0',
              }}
          />
        </Grid>

        {children}

        <Grid item>
          <Grid alignItems={'center'} container>
            {renderActions && <Grid item>{renderActions}</Grid>}
            <Grid item>
              <FormControlLabel
                  control={<SBSwitch handleToggle={(event, state) => onHideEmpty(state)} />}
                  className="d-flex flex-row-reverse"
                  label="Hide empty companies"
                  style={{ margin: 'unset' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  );
};

export default SectionSearch;
