import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import { axiosConfig, myTheme } from '../helpers';
import _ from 'lodash'
import { notificationCenter } from '../helpers/notifications';
import { CircularProgress, ThemeProvider, Dialog, DialogActions, DialogTitle, DialogContent, Button, Badge, Accordion, AccordionSummary, Typography, AccordionDetails, TextField } from "@material-ui/core";
import { Worker, formatPhoneNumber } from "../helpers/formatters";
import RevokeCode from "./RevokeCode";
import RevokeDelete from './RevokeDelete';
import SafetyInfo from '../workers/onboard/SafetyInfo'
import { Close } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SettingsContext } from '../projectDashboard/contexts/SettingsContext';
import workerProfileIcon from '../../../assets/images/worker_types/constructionWorkerIcon-2023.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    // marginRight: theme.spacing(1),
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: 'center',
    fontSize: '17px'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "center"
  },
  ml: {
    marginLeft: "10px"
  },
  mb: {
    marginBottom: "10px"
  },
  input: {
    width: "100%"
  },
  inputGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  smallAvatar: {
      width: 30,
      height: 30,
      borderRadius: '50%',
      minWidth: 30,
      minHeight: 30,
      marginRight: 10
  },
  circularProgressWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
    backgroundColor: '#ffffffcc'
  }
}));

const defaultSafetyInfo = {
  acknowledged: false,
  language: 'en',
  address: "",
  emergency_name: "",
  emergency_phone_number: "",
  emergency_email: "",
  car_make: "",
  car_model: "",
  car_color: "",
  car_tag: ""
}

export default function RevokeList(props) {

  const { config, project, company, revokeList } = props;
  const classes = useStyles();

  const settingsContext = useContext(SettingsContext);

  const [records, setRecords] = useState(revokeList)
  const [revokeResponse, setRevokeResponse] = useState([])
  const [safetyFrom, setSafetyFrom] = useState(false)
  const [loadingRecords, setLoadingRecords] = useState(false)
  const [safetyInfo, setSafetyInfo] = useState(defaultSafetyInfo);
  const [safetyAgree, setSafetyAgree] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);


  const loadRecords = () => {
    setLoadingRecords(true)
    axios.get(config.revoke_workers_endpoint, axiosConfig()).then(response => {
      setLoadingRecords(false)
      setRecords(response.data);
      settingsContext.setRevokeList(response.data)
    }).catch(errorLoadingRevokes => {
      setLoadingRecords(false)
      console.error({ errorLoadingRevokes });
    })
  };

  const onSafetyAgree = () => {
    setSafetyAgree(!safetyAgree);
  }

  const onVerify = (record) => {
    // console.log("El record ", record)
    // if (record.sourceData.safety_process === 'form') {
    //   setCurrentRecord(record)
    //   // setSafetyFrom(true);
    // }
  }

  const onCompleteSafety = record => {
    if (record.safety_process === 'form') {
      setRevokeResponse(record)
      setSafetyFrom(true);
    }
  }

  const onDisclaimer = (disclaimer) => {
    setDisclaimer(disclaimer);
  }

  if (records.length == 0) {
    return <span></span>
  }

  const onSetLoading = (flag) => {
    // console.log(flag)
  }

  const onCloseSafetyForm = () => {
    setSafetyFrom(false);
    loadRecords();
  }

  const onSafetyChange = ({ prop, val }) => {
    safetyInfo[prop] = val;
    setSafetyInfo({ ...safetyInfo })
  }

  const onPatchSafetyForm = async () => {

    const url = config.onboarding_webhook_url + "/update/" + revokeResponse.onboard._id;
    setSafetyFrom(false);
    axios.patch(url, { payload: safetyInfo }, axiosConfig()).then(response => {
      notificationCenter('success', response.data.msg)
      loadRecords();
    }).catch(err => {
      console.log("error onboard ", err);
    });


  }


  return (
    <ThemeProvider theme={myTheme}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <span style={{ color: "rgb(103, 106, 108)", fontWeight: "bold", display: "flex", alignItems: "center" }}>Revoke Workers List <Badge style={{ marginLeft: '16px' }} badgeContent={_.size(records)} color="primary" /></span>
        </AccordionSummary>
        <AccordionDetails  style={{ position: 'relative' }}>
          { loadingRecords &&
            <div className={classes.circularProgressWrapper}>
              <CircularProgress color='primary' />
            </div>
          }
          <table className="table table-hover table-alternate-color">
            <thead>
              <tr>
                <th>Worker</th>
                <th>Company</th>
                <th>Phone</th>
                <th colSpan={2}></th>
              </tr>
            </thead>
            <tbody>
              {records.map((record, idx) => (
                <tr key={idx}>
                  <td>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div>
                        <img src={_.get( _.get( record, 'worker'), 'image_path', workerProfileIcon)} className={classes.smallAvatar} />
                      </div>
                      {Worker(record.worker).fullName}
                    </div>
                  </td>
                  <td>
                    <div style={{ display: 'flex', alignItems: 'center', height: 30 }}>
                      {record.company.name}
                    </div>
                  </td>
                  <td>
                    <div style={{ display: 'flex', alignItems: 'center', height: 30 }}>
                      {formatPhoneNumber(record.phoneNumber)}
                    </div>
                  </td>
                  <td>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <form className="sb-inline-form">
                        <RevokeCode {...props} loadRecords={loadRecords} onDisclaimer={onDisclaimer} record={record} onCompleteSafety={onCompleteSafety} />
                        <RevokeDelete {...props} onDelete={loadRecords} record={record} />
                      </form>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </AccordionDetails>
      </Accordion>

      {/* <div className="panel panel-hover">
        <div className="panel-heading" data-toggle="collapse" data-target="#revokePanel">
          <span className="panel-title">
            <div className="font-size-16 pull-left">
              Revoke Workers List
            </div>
            <div className="pull-right">
              <div className="badge font-size-16">
                {records.length}
              </div>
            </div>
          </span>
        </div>
        <div className="panel-body collapse" id="revokePanel">
          <table className="table table-hover table-alternate-color">
            <thead>
              <tr>
                <th>Worker</th>
                <th>Company</th>
                <th>Phone</th>
                <th colSpan={2}></th>
              </tr>
            </thead>
            <tbody>
              {records.map((record, idx) => (
                <tr key={idx}>
                  <td>{Worker(record.worker).fullName}</td>
                  <td>{record.company.name}</td>
                  <td>{formatPhoneNumber(record.phoneNumber)}</td>
                  <td>
                    <form className="sb-inline-form">
                      <RevokeCode {...props} onDisclaimer={onDisclaimer} onSetLoading={onSetLoading} onVerify={onVerify} record={record} onCompleteSafety={onCompleteSafety} />
                      <RevokeDelete {...props} onDelete={loadRecords} record={record} />
                    </form>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div> */}
      <Dialog open={safetyFrom} onClose={onCloseSafetyForm} fullWidth={true} maxWidth='md' >
        <DialogTitle disableTypography={true} classes={{ root: classes.dialogTitle }}>
          <span>Safety Info</span>
          <Close style={{ cursor: 'pointer' }} size='20' color='primary' onClick={onCloseSafetyForm} />
        </DialogTitle>
        <DialogContent>
          <SafetyInfo classes={classes} disclaimer={disclaimer} userCompany={company} project={project} state={safetyInfo} type='form' onChange={onSafetyChange} onSafetyAgree={onSafetyAgree} />
        </DialogContent>
        <DialogActions>
          <Button disabled={!safetyAgree} onClick={onPatchSafetyForm} variant="outlined" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}
