import React, { useContext, useState } from 'react';
import { TextField, FormControl, InputLabel, Select, Input, MenuItem, Chip, Button, Stepper, Step, StepLabel, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import axios from "axios";
import { get, isNull } from 'lodash';
import { axiosConfig } from '../../../../helpers';
import Autocomplete from '@material-ui/lab/Autocomplete'
import moment from 'moment';
import { SettingsContext } from '../../../contexts/SettingsContext';

const getSteps = () => ["Select Targets", "Confirm", "Sent"];



const formatOptionLabel = (props) => {
    const { first_name, last_name, profile, _id } = props;
    return (
        <ListItem button key={_id}>
            <ListItemAvatar>
                <Avatar alt="Remy Sharp" src={profile} />
            </ListItemAvatar>
            <ListItemText className="br-break" primary={`${first_name} ${last_name}`} />
        </ListItem>
    )
};

const MultiValueContainer = ({ children, ...props }) => {

    const handleDelete = (i) => {
        props.selectProps.onChange(get(props, 'selectProps.value').filter(item => get(item, '_id') !== get(props, 'data._id')), { action: 'remove-value' });
    }

    return (
        <components.MultiValueContainer {...props}>
            <Chip
                avatar={
                    <Avatar className="user-avatar size-20" src={get(props, 'data.profile')} />
                }
                label={`${get(props, 'data.first_name')} ${get(props, 'data.last_name')} `}
                onDelete={handleDelete}
            />
        </components.MultiValueContainer>
    );
};

const BroadcastMessageWizard = (props) => {

    const { project, companies, classes, onDone, broadcastEndpoint, workersEndpoint } = props

    const [company, setCompany] = useState('')
    const [workers, setWorkers] = useState([])
    const [availableWorkers, setAvailableWorkers] = useState([])
    const [msg, setMsg] = useState("")
    const [step, setStep] = useState(0)
    const [disabledNext, setDisabledNext] = useState(false)
    const [error, setError] = useState('');

    const settingContext = useContext(SettingsContext);


    const formattingDate = settingContext.onGetTimeSheetDateFormat;

    const handleCompanyChange = (ev) => {
        const value = ev.target.value
        setCompany(value)
        setWorkers([])
        loadWorkers(value)
    }

    const loadWorkers = (cmp) => {

        let data = { "filters": [{ "key": "company", "value": get(cmp, '_id.$oid') }], "viewType": "compact", "skipPagination": true }
        const url = `${workersEndpoint}/project/${project._id.$oid}/workers/list`

        axios.post(url, data, axiosConfig()).then(response => {
            setAvailableWorkers(response.data)
        })
    }


    const handleWorkersChange = (ev, value) => {
        setWorkers(value)
    }

    const handleMsgChange = (ev) => {
        const newText = ev.target.value
        if (newText.length <= 160) {
            setMsg(newText)
        }
    }

    const handleNext = () => {
        if (step == 1) return sendRequest()
        let stp = step
        if (step >= (getSteps().length - 1)) {
            stp = 0
            setMsg("")
        } else {
            stp++
        }
        setStep(stp)
    }

    const buildPayload = () => {
        return {
            "project_id": project._id.$oid,
            "company_id": company._id.$oid,
            "employee_id": workers.map(w => w._id),
            "message": msg,
            "date": formattingDate()
        }
    }

    const sendRequest = () => {
        const url = `${broadcastEndpoint}/create`
        setDisabledNext(true)
        setError("")
        return axios.post(url, buildPayload(), axiosConfig()).then(response => {
            setStep(2)
            setDisabledNext(false)
        }).catch(sendMessageRequestError => {
            console.error("The error ",)
            setDisabledNext(false)
            setError(get(sendMessageRequestError.response, 'data.error', "An Error Ocurred"))
        })
    }

    const handleBack = () => {
        if (step != 0) {
            setStep(step - 1)
        }
    }

    const actionButtons = () => (
        <div>
            <Button style={{ borderRadius: '4px' }} type="sm" onClick={handleBack} disabled={step === 0} >Back</Button>

            <Button style={{ borderRadius: '4px' }} type="sm" disabled={msg.length == 0 || company == '' || disabledNext} color="primary" variant="contained" onClick={handleNext}>{step == (getSteps().length - 1) ? "Reset" : "Next"}</Button>

            <Button style={{ borderRadius: '4px' }} type="sm" color="primary" className="float-right" variant="contained" onClick={onDone}>Close</Button>
        </div>
    )

    const stepOne = () => (
        <div className={classes.container}>
            <FormControl fullWidth={true} className={classes.formControl}>
                <InputLabel id="target-companies-label" className={classes.labels}>Target Company</InputLabel>
                <Select
                    labelId="target-company-label"
                    id="target-company-chip"
                    value={company}
                    onChange={handleCompanyChange}
                    input={<Input id="select-single-company" />} >
                    {companies.map((company) => (
                        <MenuItem key={company._id.$oid} value={company} className={classes.chip}>
                            {company.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth={true} className={classes.formControl}>
                <Autocomplete
                    multiple
                    options={availableWorkers}
                    getOptionLabel={(option) => `${get(option, 'first_name')} ${get(option, 'last_name')}`}
                    getOptionSelected={(option, value) => get(option, '_id') === get(value, '_id')}
                    value={workers}
                    onChange={handleWorkersChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            margin="normal"
                            label="Leave blank to target all workers Select worker"
                            style={{ width: "100%", padding: '15px 0' }}
                        />
                    )}
                />
            </FormControl>

            <FormControl fullWidth={true} className={classes.formControl}>
                <TextField
                    id="text-body"
                    label={<InputLabel className={classes.labels}>Text Body</InputLabel>}
                    multiline
                    rows="2"
                    value={msg}
                    onChange={handleMsgChange}
                    rowsMax="2"
                    margin="normal"
                    fullWidth
                />
            </FormControl>
            {actionButtons()}
        </div>
    )

    const stepTwo = () => (
        <div className={classes.container}>
            {(() => {
                if (error.length > 0) {
                    return <span className="text-danger">*{error}</span>
                }
            })()}
            <div style={{ margin: '0px 0px 25px 16px' }}>
                You are going to target <strong>{`${workers.length == 0 ? 'All' : workers.length}`}</strong> workers from <strong>{company.name}</strong> with
                <span style={{ marginLeft: '7px' }}>
                    {msg}
                </span>
            </div>
            {actionButtons()}
        </div>
    )

    const stepThree = () => (
        <div className={classes.container}>
            <div className="text-center jr-fs-2xl">
                <i className="fas fa-check"></i>
                <strong>Request Sent</strong>
            </div>
            {actionButtons()}
        </div>
    )


    return (

        <>

            <Stepper activeStep={step} className="horizontal-stepper-linear">
                {getSteps().map((label, index) => {
                    return (
                        <Step key={label} className={`horizontal-stepper ${index === step ? 'active' : ''}`} >
                            <StepLabel className="stepperlabel">{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            {
                (() => {
                    switch (step) {
                        case 0:
                            return stepOne()
                        case 1:
                            return stepTwo()
                        case 2:
                            return stepThree()
                        default:
                            break;
                    }
                })()
            }

        </>

    );
}


export default BroadcastMessageWizard;