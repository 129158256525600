import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';

const MultipleSelect = ({ value, onChange, options, allOption, pluginOpts = {} }) => {

  const ref = useRef(null)

  const [selected, setSelected] = useState(!!value ? value : [])

  const checkAll = () => {
    console.log("Check all", { options });
    selected.splice(0, selected.length)
    options.forEach(o => {
      selected.push(o.id)
    });

    $(ref.current).val(selected)
    $(ref.current).trigger('change')
    onChange(selected)
    // setSelected([...selected])
  }

  useLayoutEffect(() => {
    $(ref.current).select2(pluginOpts)
    console.log("Setting plugin");
    if (!!value) {
      $(ref.current).val(value)
      $(ref.current).trigger('change.select2');
    }
    $(ref.current).on('select2:select', function (e) {
      const data = e.params.data.id;
      console.log("select", { data, e, selected })
      selected.push(data)
      onChange(selected)
      // setSelected([...selected])
    });
    $(ref.current).on('select2:unselect', function (e) {
      const data = e.params.data.id;
      console.log("unselect", { data, e, selected })
      selected.splice(selected.indexOf(data), 1)
      onChange(selected)
      // setSelected([...selected])
    });
    return () => { };
  }, [])

  const selectSection = (
    <select ref={ref} multiple={true}>
      {
        options.map(opt => {
          return <option key={opt.id} value={opt.id}>{opt.name}</option>
        })
      }
    </select>
  )

  let result = ''

  if (allOption) {
    result = (
      <div className="input-group">
        {/* {JSON.stringify(selected)} */}
        {selectSection}
        <span className="input-group-addon"><span onClick={checkAll} className="fas fa-check-double"></span></span>
      </div>
    )
  } else {
    result = selectSection
  }

  return result;
}

export default MultipleSelect;
